import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'js-debounce';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BuildTable, ActionButtons, ConfirmDelete, Link } from 'components';
import { Row, Col, Button, Input, Form } from 'reactstrap';
import { englishLanguageFirst, sortItems } from '../../../../helpers';

import styles from './styles.css';
import './styles.css';
import formStyles from '../../../../components/BuildTable/styles.css';
import '../../../../components/BuildTable/styles.css';

import { deleteAssessment, fetchAssessments } from 'actions/assessments';

@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    assessments: state.assessments.hasIn(['assessments', 'english'])
      ? state.assessments.getIn(['assessments', 'english']).toArray()
      : [],
    assessmentsFiltered: state.assessments.getIn(['assessmentsFiltered']) || 0
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ fetchAssessments, transitionTo }, dispatch);
  }
)
export default class Preview extends Component {
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    assessments: PropTypes.array.isRequired,
    assessmentsFiltered: PropTypes.number.isRequired,
    fetchAssessments: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  state = {
    query: ''
  };

  getAssessmentsTable = () => {
    const { addModal, removeModal, intl } = this.context;
    const {
      user,
      assessments,
      assessmentsFiltered,
      fetchAssessments,
      transitionTo
    } = this.props;

    const fields = [
      {
        key: 'assessment',
        sortable: true,
        renderField: (item) => item.get('name')
      },
      {
        key: 'based_on_simulation',
        sortable: true,
        width: 20,
        renderField: (item) => item.getIn(['simulation', 'name'])
      },

      {
        key: 'language',
        sortable: false,
        renderField: (item) =>
          englishLanguageFirst(
            sortItems(item.get('languages'), {
              key: 'language',
              asc: true
            })
          ).map((lang) => {
            if (lang.get('published')) {
              return (
                <div>
                  <strong>
                    <FormattedMessage
                      id={`LANGUAGE_VALUES.${lang.get('language')}`}
                    />
                  </strong>
                </div>
              );
            }

            return (
              <div>
                <FormattedMessage
                  id={`LANGUAGE_VALUES.${lang.get('language')}`}
                />{' '}
                (
                <FormattedMessage id="UNPUBLISHED" />)
              </div>
            );
          })
      }
    ];

    if (
      user.get('isPartner') &&
      user.get('partner').get('modules').includes('RemovePsychometrics')
    ) {
      fields.push({
        key: 'has_psychometrics',
        sortable: false,
        renderField: (item) => {
          return (
            <span
              className={
                item.get('has_psychometrics')
                  ? formStyles['checkmark-yes']
                  : formStyles['checkmark-no']
              }
              dangerouslySetInnerHTML={{
                __html: item.get('has_psychometrics') ? '&#10003;' : '&#10005;'
              }}
            />
          );
        }
      });
    }

    if (
      user.get('isClient') &&
      user.get('client').get('modules').includes('RemovePsychometrics')
    ) {
      fields.push({
        key: 'has_psychometrics',
        sortable: false,
        renderField: (item) => {
          return (
            <span
              className={
                item.get('has_psychometrics')
                  ? formStyles['checkmark-yes']
                  : formStyles['checkmark-no']
              }
              dangerouslySetInnerHTML={{
                __html: item.get('has_psychometrics') ? '&#10003;' : '&#10005;'
              }}
            />
          );
        }
      });
    }

    if (!user.get('isPartner') && !user.get('isClient')) {
      fields.push({
        key: 'partner',
        sortable: true,
        renderField: (item) =>
          item.has('partner') ? item.getIn(['partner', 'name']) : 'Pinsight'
      });
    }

    if (!user.get('isClient')) {
      fields.push({
        key: 'client',
        sortable: true,
        renderField: (item) =>
          item.has('client') ? item.getIn(['client', 'name']) : 'N/A'
      });
    }

    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          editLink={`/customize/assessment/edit/${item.get('id')}`}
          editDisabled={
            !item.getIn(['permissions', 'actions']).contains('edit') &&
            !item.getIn(['permissions', 'actions']).contains('view')
          }
          editKey={
            item.getIn(['permissions', 'actions']).contains('edit')
              ? 'EDIT'
              : 'VIEW'
          }
          copy={() => {
            transitionTo('/customize/assessment/create', {
              copy: item.get('id')
            });
          }}
          copyDisabled={
            !item.getIn(['permissions', 'actions']).contains('copy')
          }
          title={
            item.getIn(['permissions', 'actions']).contains('copy')
              ? null
              : intl.formatMessage({
                  id: 'ERRORS.ASSESSMENT_BASE_SIM_NOT_ALLOWED_COPY'
                })
          }
          delete={() => {
            addModal(
              <ConfirmDelete
                action={deleteAssessment}
                data={{
                  id: item.get('id')
                }}
                header={<FormattedMessage id="DELETE_ASSESSMENT" />}
                message={<FormattedMessage id="CONFIRM_ASSESSMENT_DELETE" />}
                onClose={removeModal}
              />
            );
          }}
          deleteDisabled={
            !item.getIn(['permissions', 'actions']).contains('delete')
          }
        />
      )
    });

    return {
      key: 'ASSESSMENTS',
      counter: true,
      data: assessments,
      customClassName: 'customize-table',
      helpers: {
        total: assessmentsFiltered,
        loadMore: fetchAssessments,
        itemsCount: 15
      },
      fields
    };
  };

  findItems = (e) => {
    const { value } = e.target;

    if (e.target.value.length > 2) {
      this.setState({ query: value });
      debounce('search', 500, () => this.fetchData(value));
    } else {
      this.setState({ query: '' });
      debounce('search', 500, () => this.fetchData(value));
    }
  };

  fetchData = (value) => {
    const action = this.props.fetchAssessments;
    action(value, null, 0, 15);
  };

  render() {
    const { query } = this.state;
    const { intl } = this.context;

    return (
      <Row>
        <Col xs={12} className={styles['assessment-heading']}>
          <h2>
            <FormattedMessage id="CUSTOMIZE" />
          </h2>
          <Link to="/customize/assessment/create" id="add_new_assessment_link">
            <Button
              id="add_new_assessment_button"
              name="add_new_assessment_button"
              className="float-xs-right"
              color="primary"
            >
              + <FormattedMessage id="ADD_NEW_ASSESSMENT" />
            </Button>
          </Link>
        </Col>

        <Col xs={12}>
          <Form
            onSubmit={(e) => e.preventDefault()}
            className={[
              formStyles['search-input'],
              formStyles['simulations-search']
            ]}
          >
            <Input
              id="search"
              name="search"
              type="text"
              placeholder={intl.formatMessage({ id: 'SEARCH_ASSESSMENT' })}
              onChange={this.findItems}
              getRef={(input) => (this.input = input)}
            />
            <button
              id="submit_search"
              name="submit_search"
              type="button"
              className={classNames(
                formStyles['search-input__button'],
                query && formStyles.close
              )}
              onClick={
                !query
                  ? () => this.input.focus()
                  : () => {
                      this.findItems({ target: { value: '' } });
                      this.input.value = '';
                    }
              }
            />
          </Form>

          <div className={styles.preview}>
            <BuildTable table={this.getAssessmentsTable()} />
          </div>
        </Col>
      </Row>
    );
  }
}
