import {
  FETCH_ASSESSOR_DATA_REQUEST,
  FETCH_ASSESSOR_DATA_SUCCESS,
  FETCH_ASSESSOR_DATA_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get assessor data action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function getAssessorData(userId, assignmentId, data) {
  let types = [
    FETCH_ASSESSOR_DATA_REQUEST,
    FETCH_ASSESSOR_DATA_SUCCESS,
    FETCH_ASSESSOR_DATA_FAILURE
  ];

  if (data != null && data.backgroundRefresh) {
    types = ['r', FETCH_ASSESSOR_DATA_SUCCESS, 'f'];
  }

  return {
    types,
    promise: api().get(
      `/api/v1/users/${userId}/simulation/${assignmentId}/data/`
    ),
    userId
  };
}
