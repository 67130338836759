// Const
import { menuItems } from 'constants/menuItems';

export default function checkRoutePermissions(user, pathname, permissions) {
  const getMenuItems = () => {
    const items = [];

    permissions.map((permission) => {
      switch (permission) {
        case 'top_menu_development_view':
          items.push(menuItems[0]);
          break;
        case 'top_menu_analytics_view':
          items.push(menuItems[1]);
          break;
        case 'top_menu_assignments_view':
          items.push(menuItems[2]);
          break;
        case 'top_menu_simulations_view':
          items.push(menuItems[3]);
          break;
        case 'top_menu_users_view':
          items.push(menuItems[4]);
          break;
        case 'top_menu_customize_view':
          items.push(menuItems[5]);
          break;
        default:
          break;
      }

      return false;
    });

    return items;
  };

  const allRoutes = [
    'analytics',
    'assignments',
    'development',
    'simulations',
    'users',
    'customize'
  ];
  const allowedRoutes = getMenuItems();
  const disallowed = allRoutes.filter(
    (r) => !allowedRoutes.find((ar) => ar.title === r)
  );

  return !disallowed.find((route) => pathname.indexOf(route) > 0);
}
