import api from 'api';
import moment from 'moment';

import {
  FETCH_ANALYTICS_REQUEST,
  FETCH_ANALYTICS_SUCCESS,
  FETCH_ANALYTICS_FAILURE
} from 'constants/actionTypes';
import { getTagsSearchString } from 'helpers';

export default function fetchAnalytics(data) {
  const string = `?assessments=${data.assessments || ''}&is_exported=1&from=${
    data.from ? moment(data.from).format('YYYY-MM-DD') : ''
  }&to=${data.to ? moment(data.to).format('YYYY-MM-DD') : ''}&q=${
    data.search && data.search !== ' ' ? encodeURIComponent(data.search) : ''
  }&reports=${data.report_id || ''}&tags=${
    data.tags ? getTagsSearchString(data.tags) : ''
  }&partners=${data.partners || ''}&clients=${data.clients || ''}`; // eslint-disable-line
  return {
    types: [
      FETCH_ANALYTICS_REQUEST,
      FETCH_ANALYTICS_SUCCESS,
      FETCH_ANALYTICS_FAILURE
    ],
    promise: api().get(`/api/v1/analytics/meta/${string}`),
    data
  };
}
