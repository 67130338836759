import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Link from '../../../../components/Link';

import styles from './styles.css';
import './styles.css';

@connect(
  (state) => ({
    currentFocus: state.development.get('currentFocus'),
    locale: state.app.get('locale')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class MoreInfo extends Component {
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    currentFocus: PropTypes.object.isRequired,
    locale: PropTypes.string
  };

  static contextTypes = {
    changeHeading: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.context.changeHeading(
        this.context.intl.formatMessage({ id: 'MORE_INFO_ABOUT_THIS_HABIT' })
      );
    }, 50);
  }

  render() {
    const { currentFocus } = this.props;

    return (
      <div className={styles['more-info']} key="more-info">
        <div
          dangerouslySetInnerHTML={{
            __html: currentFocus.getIn(['indicator', 'more_info'])
          }}
        />
        <Link
          to="/development/app"
          query={{ view: 0, scroll: 0 }}
          style={{
            display: 'block',
            textAlign: 'center',
            fontSize: 14,
            marginTop: 20,
            color: '#001c50'
          }}
        >
          <FormattedMessage id="BACK_TO_MY_DAILY_EXERCISE" />
        </Link>
      </div>
    );
  }
}
