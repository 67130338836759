import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { InputGroup, Label } from 'reactstrap';
import { validate } from './validate';

import styles from '../../styles.css';

import { renderInput, renderDateInput } from '../../actions';
import ToolTip from '../../../components/ToolTip';

const form = 'eventForm';

@reduxForm({
  form,
  validate,
  initialValues: {
    roleplay: true
  }
})
@connect(
  (state) => ({
    contacts: state.simulations.hasIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id),
      'contacts'
    ])
      ? state.simulations
          .getIn([
            'simulations',
            'english',
            Number(state.router.route.vars.id),
            'contacts'
          ])
          .toArray()
      : [],
    exercises: state.simulations.hasIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id),
      'exercises'
    ])
      ? state.simulations
          .getIn([
            'simulations',
            'english',
            Number(state.router.route.vars.id),
            'exercises'
          ])
          .toArray()
      : [],
    rolePlay: formValueSelector(form)(state, 'roleplay'),
    hidden: formValueSelector(form)(state, 'hidden')
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
class Event extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    contacts: PropTypes.object,
    exercises: PropTypes.object,
    rolePlay: PropTypes.bool,
    hidden: PropTypes.bool,
    readOnly: PropTypes.bool,
    version: PropTypes.object,
    isNew: PropTypes.bool
  };

  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'name', edit.get('name'));
      change(form, 'date', edit.get('date'));
      change(form, 'duration', edit.get('duration'));
      change(form, 'contact_id', edit.get('contact_id'));
      change(form, 'excercise_id', edit.get('excercise_id'));
      change(form, 'roleplay', edit.get('roleplay'));
      change(form, 'hidden', edit.get('hidden'));
      change(form, 'prework', edit.get('prework'));
    } else {
      change(form, 'date', '000 00:00');
      change(form, 'duration', '000 00:00');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change } = this.props;

    if (this.props.rolePlay !== nextProps.rolePlay) {
      if (nextProps.rolePlay) {
        change(form, 'prework', false);
      }
    }
  }

  render() {
    const {
      handleSubmit,
      contacts,
      exercises,
      rolePlay,
      readOnly,
      version
    } = this.props;

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published')) {
        return true;
      }

      if (version.get('was_published') && !this.props.isNew) {
        switch (field) {
          case 'name':
            return false;
          default:
            return true;
        }
      }

      return false;
    };

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.event_name" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="name"
              component={renderInput}
              type="text"
              disabled={readOnly || isFieldDisabled('name')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.event_date" /> (
              <FormattedMessage id="EVENT_DATE_HINT" />) (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="date"
              component={renderDateInput}
              d
              h
              m
              disabled={readOnly || isFieldDisabled('date')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.duration" /> (
              <FormattedMessage id="EVENT_DURATION_HINT" />) (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="duration"
              component={renderDateInput}
              d
              h
              m
              disabled={readOnly || isFieldDisabled('duration')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']} s>
            <h6 style={{ visibility: 'hidden' }}>
              <Label
                htmlFor="roleplay"
                className={styles['modal-checkbox-list-item']}
              >
                <Field
                  name="roleplay"
                  component={renderInput}
                  type="checkbox"
                  disabled={readOnly || isFieldDisabled('roleplay')}
                />
                <FormattedMessage id="FORM_FIELDS.role-play" />
              </Label>
            </h6>
            <div>
              <InputGroup>
                <label htmlFor="contact_id">
                  <FormattedMessage id="FORM_FIELDS.contact_name" />
                  <ToolTip
                    id="contacts-name-jit"
                    hint={this.props.intl.formatMessage({
                      id: 'CONTACTS_NAME_GENERIC_ASSESSOR',
                      defaultMessage:
                        'Select Assessor in the drop-down to assign an assessor to the video call without using an assessment contact. The assessor will see the video call event name as their assignment.'
                    })}
                  />
                </label>
                <Field
                  name="contact_id"
                  component={renderInput}
                  type="select"
                  valueKey="id"
                  optionKey="name"
                  options={contacts}
                  placeholder="PLEASE_SELECT_ROLE_PLAY_CONTACT"
                  disabled={
                    !rolePlay || readOnly || isFieldDisabled('contact_id')
                  }
                  translatePlaceholder
                />
                <label htmlFor="contact_id">
                  <FormattedMessage id="FORM_FIELDS.exercise" />
                </label>
                <Field
                  name="excercise_id"
                  component={renderInput}
                  type="select"
                  valueKey="id"
                  optionKey="name"
                  options={exercises}
                  placeholder="PLEASE_SELECT_ROLE_PLAY_EXERCISE"
                  disabled={
                    !rolePlay || readOnly || isFieldDisabled('excercise_id')
                  }
                  translatePlaceholder
                />
              </InputGroup>
            </div>
          </InputGroup>
        </form>
      </div>
    );
  }
}

export default injectIntl(Event);
