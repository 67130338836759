import React from 'react';
import PropTypes from 'prop-types';

export default class CalendarHeader extends React.Component {
  displayName = 'DatePickerHeader';

  static propTypes = {
    displayDate: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    monthLabels: PropTypes.array.isRequired,
    previousButtonElement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]).isRequired,
    nextButtonElement: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired
  };

  handleClickPrevious = () => {
    const newDisplayDate = new Date(this.props.displayDate);
    newDisplayDate.setMonth(newDisplayDate.getMonth() - 1);
    this.props.onChange(newDisplayDate);
  };

  handleClickNext = () => {
    const newDisplayDate = new Date(this.props.displayDate);
    newDisplayDate.setMonth(newDisplayDate.getMonth() + 1);
    this.props.onChange(newDisplayDate);
  };

  render() {
    return (
      <div className="text-center">
        <div
          className="text-muted pull-left"
          onClick={this.handleClickPrevious}
          style={{ cursor: 'pointer' }}
        >
          {this.props.previousButtonElement}
        </div>
        <span>
          {this.props.monthLabels[this.props.displayDate.getMonth()]}{' '}
          {this.props.displayDate.getFullYear()}
        </span>
        <div
          className="text-muted pull-right"
          onClick={this.handleClickNext}
          style={{ cursor: 'pointer' }}
        >
          {this.props.nextButtonElement}
        </div>
      </div>
    );
  }
}
