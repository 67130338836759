import {
  FETCH_ASSESSMENT_REQUEST,
  FETCH_ASSESSMENT_SUCCESS,
  FETCH_ASSESSMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch assessment action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchAssessment(id, language) {
  return {
    types: [
      FETCH_ASSESSMENT_REQUEST,
      FETCH_ASSESSMENT_SUCCESS,
      FETCH_ASSESSMENT_FAILURE
    ],
    promise: api().get(
      `/api/v1/assessment/${id}/?language=${language || 'english'}`
    ),
    id,
    language: language || 'english'
  };
}
