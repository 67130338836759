import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { Field, change, formValueSelector } from 'redux-form';

import { Row, Col, InputGroup, Label } from 'reactstrap';
import { ShareResultsAccess } from './index';
import { ToolTip } from '../../components';

import { renderInput } from '../actions';

import styles from '../styles.css';
import '../styles.css';

@connect(
  (state, ownProps) => ({
    currentUser: state.users.hasIn([
      'allUsers',
      Number(state.router.route.vars.id)
    ])
      ? state.users.getIn(['allUsers', Number(state.router.route.vars.id)])
      : Map(),
    selectedPartner: Number(
      formValueSelector(ownProps.form)(state, 'partner_id')
    ),
    selectedClient: Number(
      formValueSelector(ownProps.form)(state, 'client_id')
    ),
    modules: Number(formValueSelector(ownProps.form)(state, 'modules[1]')),
    partners: state.partners.get('allPartners'),
    clients: state.clients.get('allClients')
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Access extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    currentUser: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    selectedPartner: PropTypes.number,
    selectedClient: PropTypes.number,
    partners: PropTypes.object.isRequired,
    clients: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    modules: PropTypes.any,
    editedParticipant: PropTypes.object
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { form, change } = this.props;

    if (
      this.props.modules === 1 &&
      (this.props.selectedClient !== nextProps.selectedClient ||
        this.props.selectedPartner !== nextProps.selectedPartner)
    ) {
      change(form, 'modules[1]', false);
    }
  }

  render() {
    const {
      readOnly,
      clients,
      partners,
      selectedClient,
      selectedPartner,
      editedParticipant,
      form
    } = this.props;
    let { disabled } = this.props;
    let allowedModules = [];

    if (selectedClient && clients.hasIn([selectedClient, 'modules'])) {
      allowedModules = clients
        .getIn([selectedClient, 'modules'])
        .toArray()
        .map((m) => m.get('name'));
    } else if (
      selectedPartner &&
      partners.hasIn([selectedPartner, 'modules'])
    ) {
      allowedModules = partners
        .getIn([selectedPartner, 'modules'])
        .toArray()
        .map((m) => m.get('name'));
    } else {
      allowedModules = ['Development'];
    }

    if (
      editedParticipant.has('active_simulation') &&
      editedParticipant.get('active_simulation').get('is_psychometrics_only')
    ) {
      disabled = true;
    }

    return (
      <Row>
        {allowedModules.indexOf('Development') > -1 && (
          <React.Fragment>
            <Col xs={12}>
              <h5>
                <FormattedMessage id="ACCESS" />
              </h5>
            </Col>
            <Col xs={12}>
              <InputGroup className={styles['checkbox-block']}>
                <Label check>
                  <Field
                    disabled={disabled}
                    name="modules[1]"
                    component={renderInput}
                    readOnly={readOnly}
                    type="checkbox"
                  />
                  <span>
                    <FormattedMessage id="MODULE_VALUES.development" />
                  </span>
                </Label>
              </InputGroup>
            </Col>
          </React.Fragment>
        )}
        {editedParticipant && (
          <Col xs={12}>
            <h5>
              <FormattedMessage id="SHARE_RESULTS_ACCESS" />
              <ToolTip
                id="share-results-access-hint"
                index="share-results-access-hint"
                hint={<FormattedMessage id="SHARE_REPORTS_ACCESS.JIT" />}
              />
            </h5>

            <ShareResultsAccess
              form={form}
              editedParticipant={editedParticipant}
            />
          </Col>
        )}
      </Row>
    );
  }
}
