// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import { InputGroup } from 'reactstrap';
import { validate } from './validate';

// Styles
import styles from '../../styles.css';

// Components

// Actions
import { renderInput } from '../../actions';

// Const
const form = 'indicatorsForm';

/**
 * Relationship
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    formErrors: state.app.get('formErrors') || {}
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Indicators extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    this.state = {
      development_suggestion: RichTextEditor.createEmptyValue(),
      more_info: RichTextEditor.createEmptyValue()
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'name', edit.get('name'));
      change(
        form,
        'BARS',
        edit.has('BARS')
          ? edit.get('BARS').replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ')
          : ''
      ); // eslint-disable-line
      change(
        form,
        'development_suggestion',
        edit.get('development_suggestion')
      );
      change(form, 'more_info', edit.get('more_info'));

      // eslint-disable-next-line
      this.setState({
        development_suggestion: RichTextEditor.createValueFromString(
          edit.get('development_suggestion'),
          'html'
        ),
        more_info: RichTextEditor.createValueFromString(
          edit.get('more_info'),
          'html'
        )
      });
    }
  }

  /**
   * On change
   * @param value
   * @param target
   */
  onChange = (value, target) => {
    const { change } = this.props;

    this.setState({
      [target]: value
    });

    change(
      form,
      target,
      value.toString('html') === '<p><br></p>' ? ' ' : value.toString('html')
    );
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { development_suggestion, more_info } = this.state;
    const { handleSubmit, formErrors } = this.props;

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.indicator_name" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field name="name" component={renderInput} type="text" />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.bars" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field name="BARS" component={renderInput} type="textarea" />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.development_suggestion" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <div id="development_suggestion_wrapper">
              <RichTextEditor
                value={development_suggestion}
                onChange={(e) => this.onChange(e, 'development_suggestion')}
                className={classNames(
                  styles.rte,
                  formErrors.development_suggestion && styles.error
                )}
              />
            </div>
            {formErrors.development_suggestion && (
              <div className={styles.form__error}>
                {formErrors.development_suggestion.errors.map((e) => (
                  <div>
                    <FormattedMessage id={e} />
                  </div>
                ))}
              </div>
            )}
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.more_info" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <div id="more_info_wrapper">
              <RichTextEditor
                value={more_info}
                onChange={(e) => this.onChange(e, 'more_info')}
                className={classNames(
                  styles.rte,
                  formErrors.more_info && styles.error
                )}
              />
            </div>
            {formErrors.more_info && (
              <div className={styles.form__error}>
                {formErrors.more_info.errors.map((e) => (
                  <div>
                    <FormattedMessage id={e} />
                  </div>
                ))}
              </div>
            )}
          </InputGroup>
        </form>
      </div>
    );
  }
}
