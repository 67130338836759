import {
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

import compareUserData from './compareUserData';

/**
 * Edit client action
 *
 * @param{String} id
 * @param{Object} data
 * @param{Object} previousData
 * @returns {{types: *[], promise: Request }}
 */
export default function editClient(id, data, previousData) {
  const reduced = {};
  Object.keys(data).map((item) => (reduced[item] = data[item]));

  if (reduced.partner_id === 'null' || reduced.partner_id === ' ') {
    reduced.partner_id = null;
  }

  Object.keys(reduced).map((key) => {
    if (
      key !== 'partner_id' &&
      (!reduced[key] || compareUserData(reduced, previousData, key))
    ) {
      return delete reduced[key];
    }

    return false;
  });

  return {
    types: [EDIT_CLIENT_REQUEST, EDIT_CLIENT_SUCCESS, EDIT_CLIENT_FAILURE],
    promise: api().put(`/api/v1/clients/${id}/`, reduced),
    id
  };
}
