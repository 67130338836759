import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Field, formValueSelector, change } from 'redux-form';
import { Map } from 'immutable';

import { Col, InputGroup, Row } from 'reactstrap';

import { fetchPartners, fetchClients } from 'actions/users';
import { renderInput } from '../actions';

const partnersInput = 'partner_id';
const clientsInput = 'client_id';

@connect(
  (state, ownProps) => ({
    user: state.auth.get('loggedUser'),
    currentUser: state.users.hasIn([
      'allUsers',
      Number(state.router.route.vars.id)
    ])
      ? state.users.getIn(['allUsers', Number(state.router.route.vars.id)])
      : Map(),
    partners: state.partners.has('allPartners')
      ? state.partners.get('allPartners').toArray()
      : [],
    partnersTotal: state.partners.get('partnersTotal'),
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    clients: state.clients.has('allClients')
      ? state.clients.get('allClients').toArray()
      : [],
    clientsTotal: state.clients.get('clientsTotal'),
    selectedClient: Number(
      formValueSelector(ownProps.form)(state, 'client_id')
    ),
    selectedPartner: Number(
      formValueSelector(ownProps.form)(state, 'partner_id')
    ),
    selectedPartners: formValueSelector(ownProps.form)(state, 'partners') || [],
    route: state.router.route,
    scheduledAt: formValueSelector(ownProps.form)(state, 'scheduled_at')
  }),
  (dispatch) =>
    bindActionCreators({ fetchPartners, fetchClients, change }, dispatch)
)
export default class PartnersAndClients extends Component {
  static propTypes = {
    partners: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    assignment: PropTypes.array,
    selectedPartner: PropTypes.number,
    selectedClient: PropTypes.number,
    user: PropTypes.object,
    blockKey: PropTypes.number,
    fieldKey: PropTypes.object,
    selectedPartners: PropTypes.array,
    change: PropTypes.func.isRequired,
    permissions: PropTypes.array,
    disabled: PropTypes.any,
    disabledPartner: PropTypes.bool,
    disabledClient: PropTypes.bool,
    partnersTotal: PropTypes.number,
    clientsTotal: PropTypes.number,
    fetchPartners: PropTypes.func.isRequired,
    fetchClients: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    filtered: PropTypes.object,
    hidden: PropTypes.array,
    scheduledAt: PropTypes.object,
    ssoEnabled: PropTypes.bool
  };

  componentDidMount() {
    const {
      partnersTotal,
      clientsTotal,
      partners,
      clients,
      fetchPartners,
      fetchClients
    } = this.props;

    if (!partnersTotal || partners.length < partnersTotal) {
      fetchPartners(0, partnersTotal);
    }

    if (!clientsTotal || clients.length < clientsTotal) {
      fetchClients(0, clientsTotal);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !isNaN(this.props.selectedClient) &&
      (this.props.selectedPartner || nextProps.selectedPartner) &&
      this.props.selectedPartner !== nextProps.selectedPartner
    ) {
      this.props.change(this.props.form, 'client_id', null);
    }
  }

  getOptions = () => {
    const {
      blockKey,
      selectedPartner,
      selectedPartners,
      user,
      filtered,
      disabled
    } = this.props;
    const clients = filtered ? filtered.clients : this.props.clients;
    let clientOptions = [];

    if (user.get('isPartner') || disabled) {
      clientOptions = clients;
    } else if ((blockKey || blockKey === 0) && selectedPartners[blockKey]) {
      clientOptions = clients.filter(
        (client) =>
          client.get('partner_id') ===
          Number(selectedPartners[blockKey][partnersInput])
      );
    } else if (selectedPartner && selectedPartner !== 'null') {
      clientOptions = clients.filter(
        (client) => client.get('partner_id') === selectedPartner
      );
    } else {
      clientOptions = clients.filter((client) => !client.get('partner_id'));
    }

    return clientOptions;
  };

  render() {
    const {
      fieldKey,
      user,
      currentUser,
      disabled,
      disabledPartner,
      disabledClient,
      assignment,
      permissions,
      route,
      filtered,
      hidden,
      scheduledAt,
      ssoEnabled
    } = this.props;
    const clientOptions = this.getOptions();
    let role;
    let type;

    if (route.attrs.role) {
      role = route.attrs.role;
      type = 'add';
    } else {
      if (currentUser.has('roles') && currentUser.get('roles').size > 0) {
        role = currentUser.get('roles').first();
      } else {
        role = 'stakeholder';
      }
      type = 'edit';
    }

    return (
      <Row>
        {!fieldKey && (
          <Col xs={12}>
            <h5>
              <FormattedMessage id="PARTNERS_AND_CLIENTS" />
            </h5>
          </Col>
        )}
        <Col xs={fieldKey ? 12 : 5}>
          {(!hidden || hidden.indexOf('partner') < 0) && (
            <InputGroup>
              <label htmlFor={partnersInput}>
                <FormattedMessage id={`FORM_FIELDS.${partnersInput}`} />
              </label>
              <Field
                name={fieldKey ? `${fieldKey}.${partnersInput}` : partnersInput}
                component={renderInput}
                type="select"
                valueKey="id"
                optionKey="name"
                options={filtered ? filtered.partners : this.props.partners}
                disabled={
                  user.get('isPartner') ||
                  assignment.indexOf('partner') < 0 ||
                  disabled ||
                  disabledPartner ||
                  scheduledAt != null
                }
                placeholder={
                  user.get('isPartner')
                    ? user.getIn(['partner', 'name'])
                    : 'Pinsight'
                }
                nullValue="null"
                onChange={(e) => {
                  if (this.props.onPartnerChangeHandler) {
                    this.props.onPartnerChangeHandler(e.target?.value);
                  }
                }}
              />
            </InputGroup>
          )}
          {assignment.indexOf('client') > -1 &&
            (!hidden || hidden.indexOf('client') < 0) && (
              <InputGroup>
                <label htmlFor={clientsInput}>
                  <FormattedMessage id={`FORM_FIELDS.${clientsInput}`} />
                </label>
                <Field
                  name={fieldKey ? `${fieldKey}.${clientsInput}` : clientsInput}
                  component={renderInput}
                  type="select"
                  valueKey="id"
                  optionKey="name"
                  options={clientOptions}
                  disabled={disabled || disabledClient || scheduledAt != null}
                  placeholder={
                    user.get('isClient')
                      ? user.getIn(['client', 'name'])
                      : 'None'
                  }
                  nullValue="null"
                  noEmpty={permissions.indexOf(`user_${role}_${type}_own`) < 0}
                  onChange={(e) => {
                    if (this.props.onClientChangeHandler) {
                      this.props.onClientChangeHandler(e.target?.value);
                    }
                  }}
                />
              </InputGroup>
            )}
          {ssoEnabled === true && (
            <div>
              <input type="checkbox" id="sso-enabled" disabled checked />
              <label htmlFor="sso-enabled" style={{ display: 'inline' }}>
                SSO enabled
              </label>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
