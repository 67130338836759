import {
  SET_WEEKLY_FOCUS_REQUEST,
  SET_WEEKLY_FOCUS_SUCCESS,
  SET_WEEKLY_FOCUS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Set weekly focus action
 *
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function setWeeklyFocus(data) {
  return {
    types: [
      SET_WEEKLY_FOCUS_REQUEST,
      SET_WEEKLY_FOCUS_SUCCESS,
      SET_WEEKLY_FOCUS_FAILURE
    ],
    promise: api().post(`/api/v1/development/focus/current/`, data),
    data
  };
}
