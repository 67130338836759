import {
  SCHEDULE_REQUEST,
  SCHEDULE_SUCCESS,
  SCHEDULE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Schedule sessions action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function schedule(id, data, reschedule, cancel) {
  let req;
  let getting = false;
  let canceling = false;

  if (reschedule) {
    req = api().get(`/api/v1/users/${id}/reschedule/`);
  } else if (cancel) {
    canceling = true;
    req = api().del(`/api/v1/users/${id}/schedule/`);
  } else if (data) {
    req = api().post(`/api/v1/users/${id}/schedule/`, data);
  } else {
    getting = true;
    req = api().get(`/api/v1/users/${id}/schedule/`);
  }

  return {
    types: [SCHEDULE_REQUEST, SCHEDULE_SUCCESS, SCHEDULE_FAILURE],
    promise: req,
    id,
    getting,
    canceling
  };
}
