import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change, focus, formValueSelector } from 'redux-form';
import { Map, fromJS } from 'immutable';
import isEqual from 'lodash.isequal';

import {
  Button,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  Label
} from 'reactstrap';
import { DatePicker, ReactTags } from 'components';

import { fetchFilterData } from 'actions/global';

import api from 'api';
import table from '../../../components/BuildTable/styles.css';
import { AND, OR } from 'constants/operators';
import { FETCH_FILTER_DATA_FAILURE } from 'constants/actionTypes';
import { renderInput } from '../../actions/index';

import styles from '../../styles.css';

const form = 'filter';
const OPERATORS = [AND, OR];

@reduxForm({
  form
})
@connect(
  (state) => ({
    analyticsLoading: state.analytics.get('analyticsLoading'),
    analyticsDetailLoading: state.analytics.get('analyticsDetailLoading'),
    user: state.auth.get('loggedUser'),
    filterValues: {
      assessments: formValueSelector(form)(state, 'assessments') || [],
      partners: formValueSelector(form)(state, 'partners') || [],
      clients: formValueSelector(form)(state, 'clients') || [],
      search: formValueSelector(form)(state, 'search'),
      tags: formValueSelector(form)(state, 'tags') || [],
      from: formValueSelector(form)(state, 'from'),
      to: formValueSelector(form)(state, 'to'),
      stakeholders: formValueSelector(form)(state, 'stakeholders')
    },
    route: state.router.route,
    allUsers: state.users.get('allUsers')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchFilterData,
        focus,
        change,
        dispatch
      },
      dispatch
    )
)
export default class Filter extends Component {
  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  static defaultProps = {
    fetchOnResetClick: false,
    participantsForm: false
  };

  constructor(props) {
    super(props);

    this.fetchFilterData(this.props);

    this.state = {
      loading: false,
      tags: [],
      suggestions: [],
      searchValue: '',
      stakeholdersValue: '',
      filterData: null
    };
  }

  componentDidMount() {
    const { user, change, onlySearch } = this.props;

    if (!onlySearch) {
      change(form, 'from', '2017-01-01T10:00:00.000Z');
      change(form, 'to', moment().zone(user.get('timezone_offset')).format());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { filterValues, onlySearch, route } = nextProps;
    const { partners, clients, assessments } = filterValues;

    if (
      !isEqual(this.props.filterValues?.partners, partners) ||
      !isEqual(this.props.filterValues?.clients, clients) ||
      !isEqual(this.props.filterValues?.assessments, assessments)
    ) {
      this.fetchFilterData(nextProps);
    }

    if (!onlySearch && typeof this.props.onChange === 'function') {
      if (!isEqual(this.props.filterValues, filterValues)) {
        this.props.onChange();
      }
    }

    // In users section reset filter on tab change
    if (
      this.props.route &&
      this.props.route.pathname === '/users' &&
      this.props.route.query.tab !== route.query.tab
    ) {
      this.resetFilter();
    }
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    } else {
      this.props.onResetFilter();
    }
  }

  checkValues = (props, filterData) => {
    const { change, filterValues } = props;
    const { partners, clients, assessments, tags } = filterValues;

    if (partners !== ' ') {
      partners?.map((p) => {
        if (
          !filterData?.partners?.find(
            (_p) => _p.key?.toString() === p?.toString()
          )
        ) {
          change(form, 'partners', []);
        }
      });
    }

    if (clients !== ' ') {
      clients?.map((c) => {
        if (
          !filterData?.clients?.find(
            (_c) => _c.key?.toString() === c?.toString()
          )
        ) {
          change(form, 'clients', []);
        }
      });
    }

    if (assessments !== ' ') {
      assessments?.map((a) => {
        if (
          !filterData?.assessments?.find(
            (_a) => _a.key.toString() === a.toString()
          )
        ) {
          change(form, 'assessments', []);
        }
      });
    }

    tags?.map((t) => {
      // Operators (styled tags) are ignored
      if (t.style != null) {
        return false;
      }

      if (filterData.tags?.indexOf(t.name) < 0) {
        return change(form, 'tags', []);
      }

      return true;
    });
  };

  fetchFilterData = async (props) => {
    api.pendingRequests
      .filter((r) => r.url.indexOf('filters') > -1)
      .map((r) => {
        props.dispatch({ type: FETCH_FILTER_DATA_FAILURE, error: 'Aborted' });

        return r.abort();
      });

    try {
      const { result } = await this.props.fetchFilterData(
        props.filterValues,
        props.route.pathname === '/analytics'
      );

      this.setState({
        filterData: result.body
      });

      this.checkValues(props, result.body);
    } catch (error) {
      throw error;
    }
  };

  handleDelete = (i) => {
    const tags = this.props.filterValues?.tags?.slice(0);
    tags?.splice(i, 1);
    this.props.change(form, 'tags', tags);
  };

  handleAddition = (tag, index) => {
    const tags = this.props.filterValues?.tags;
    tags?.splice(index, 0, tag);
    this.props.change(form, 'tags', tags);
  };

  filterData = () => {
    const { onFilter } = this.props;

    this.reactTags?.resetInput();
    onFilter();
  };

  resetFilter = (withReset = false) => {
    const { user, change, onlySearch } = this.props;

    change(form, 'partners', []);
    change(form, 'clients', []);
    change(form, 'assessments', []);
    change(form, 'tags', null);
    change(form, 'search', null);
    change(form, 'stakeholders', null);
    this.setState({ searchValue: '' });
    this.setState({ stakeholdersValue: '' });

    if (!onlySearch) {
      change(form, 'from', '2017-01-01T10:00:00.000Z');
      change(form, 'to', moment().zone(user.get('timezone_offset')).format());
    } else {
      change(form, 'from', null);
      change(form, 'to', null);
    }

    if (
      this.reactTags != null &&
      typeof this.reactTags.resetInput === 'function'
    ) {
      this.reactTags.resetInput();
    }

    this.props.onResetFilter(withReset);
  };

  debounce = (action, delay) => {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    this.typingTimeout = setTimeout(() => {
      action();
    }, delay);
  };

  clearInput = (field, value) => {
    const { change } = this.props;
    change(form, field, value);
  };

  render() {
    const {
      disabled,
      change,
      user,
      onlySearch,
      fetchOnResetClick,
      filterValues,
      participantsForm
    } = this.props;
    const { filterData, loading, searchValue, stakeholdersValue } = this.state;

    const submitForm = () => {
      change(form, 'search', searchValue);

      if (participantsForm) {
        change(form, 'stakeholders', stakeholdersValue);
      }

      this.filterData();
    };

    const inputGroups = {
      partner: (
        <InputGroup>
          <Label>
            <FormattedMessage id="PARTNER" />
          </Label>
          <Field
            name="partners"
            noEmpty
            component={renderInput}
            multiple
            type="select"
            valueKey="key"
            optionKey="value"
            options={Map(
              filterData?.partners?.map((partner) => [
                partner.key,
                fromJS(partner)
              ])
            ).toArray()}
            onFocus={(e) => e.preventDefault()}
            disabled={disabled}
            translatePlaceholder
          />
          <span
            key="clear-button"
            className={styles['clear-button']}
            onClick={() => this.clearInput('partners', [])}
          >
            <FormattedMessage id="CLEAR" />
          </span>
        </InputGroup>
      ),
      client: (
        <InputGroup>
          <Label>
            <FormattedMessage id="CLIENT" />
          </Label>
          <Field
            loading={loading}
            name="clients"
            noEmpty
            component={renderInput}
            multiple
            type="select"
            valueKey="key"
            optionKey="value"
            options={Map(
              filterData?.clients?.map((client) => [client.key, fromJS(client)])
            ).toArray()}
            onFocus={(e) => e.preventDefault()}
            disabled={disabled}
            translatePlaceholder
          />
          <span
            key="clear-button"
            className={styles['clear-button']}
            onClick={() => this.clearInput('clients', [])}
          >
            <FormattedMessage id="CLEAR" />
          </span>
        </InputGroup>
      ),
      assessment: (
        <InputGroup>
          <Label>
            <FormattedMessage id="FORM_FIELDS.assessment" />
          </Label>
          <Field
            loading={loading}
            name="assessments"
            noEmpty
            component={renderInput}
            multiple
            type="select"
            valueKey="key"
            optionKey="value"
            options={Map(
              filterData?.assessments?.map((assessment) => [
                assessment.key,
                fromJS(assessment)
              ])
            ).toArray()}
            onFocus={(e) => e.preventDefault()}
            disabled={disabled}
            translatePlaceholder
          />
          <span
            key="clear-button"
            className={styles['clear-button']}
            onClick={() => this.clearInput('assessments', [])}
          >
            <FormattedMessage id="CLEAR" />
          </span>
        </InputGroup>
      ),
      fromTo: (
        <Row style={{ marginBottom: 10 }}>
          <Col xs={6}>
            <InputGroup>
              <Label>
                <FormattedMessage id="FORM_FIELDS.from" />
              </Label>
              <DatePicker
                id="from"
                value={filterValues.from}
                onChange={(e) => change(form, 'from', e)}
                disabled={disabled}
              />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <Label>
                <FormattedMessage id="FORM_FIELDS.to" />
              </Label>
              <DatePicker
                id="to"
                value={filterValues.to}
                onChange={(e) => change(form, 'to', e)}
                disabled={disabled}
              />
            </InputGroup>
          </Col>
        </Row>
      ),
      tag: (
        <InputGroup>
          <Label>
            <FormattedMessage id="FORM_FIELDS.tag" />
          </Label>
          <div>
            <ReactTags
              ref={(t) => (this.reactTags = t)}
              placeholder="SELECT_TAGS"
              autofocus={false}
              tags={filterValues.tags}
              suggestions={OPERATORS.concat(
                this.state.filterData?.tags?.map((t) => ({
                  name: t
                })) || []
              )}
              handleDelete={this.handleDelete}
              handleAddition={this.handleAddition}
              minQueryLength={0}
              withClearButton
              filterTagsInput
              onClear={() => change(form, 'tags', null)}
            />
          </div>
        </InputGroup>
      ),
      stakeholders: (
        <InputGroup style={{ marginBottom: 11 }}>
          <Label>
            <FormattedMessage id="FORM_FIELDS.stakeholder_search" />
          </Label>
          <div>
            <Input
              id="stakeholders"
              ref={(node) => (this.stakeholdersInput = node)}
              type="text"
              value={stakeholdersValue}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({ stakeholdersValue: value });
              }}
              onPaste={(e) => {
                const { value } = e.target;
                this.setState({ stakeholdersValue: value });
              }}
            />
            <button
              id="search-zoom-icon"
              type="button"
              className={classNames(
                table['search-input__button'],
                table.filter,
                filterValues.stakeholders && table.close
              )}
              onClick={
                !filterValues.stakeholders
                  ? () => ReactDOM.findDOMNode(this.stakeholdersInput).focus()
                  : () => {
                      this.setState({ stakeholdersValue: '' });
                      change(form, 'stakeholders', '');
                    }
              }
            />
          </div>
        </InputGroup>
      ),
      search: (
        <InputGroup>
          <Label>
            <FormattedMessage
              id={
                participantsForm
                  ? 'FORM_FIELDS.participant_search'
                  : 'FORM_FIELDS.search'
              }
            />
          </Label>
          <div>
            <Input
              id="search"
              ref={(node) => (this.participantSearchInput = node)}
              type="text"
              value={searchValue}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({ searchValue: value });
              }}
              onPaste={(e) => {
                const { value } = e.target;
                this.setState({ searchValue: value });
              }}
            />
            <button
              id="search-zoom-icon"
              type="button"
              className={classNames(
                table['search-input__button'],
                table.filter,
                filterValues.search && table.close
              )}
              onClick={
                !filterValues.search
                  ? () =>
                      ReactDOM.findDOMNode(this.participantSearchInput).focus()
                  : () => {
                      this.setState({ searchValue: '' });
                      change(form, 'search', '');
                    }
              }
            />
          </div>
        </InputGroup>
      )
    };

    const isPartnerAssessor =
      user.get('entity') === 'partner' &&
      user.get('active_role') === 'assessor';

    return (
      <form
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            submitForm();
          }
        }}
        className={classNames(!onlySearch && styles.form)}
      >
        {this.props.onlySearch ? (
          <div
            className={classNames(table['search-input'], table['with-button'])}
          >
            <Input
              ref={(node) => (this.searchInput = node)}
              id="search"
              type="text"
              value={searchValue}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({ searchValue: value });
              }}
              onPaste={(e) => {
                const { value } = e.target;
                this.setState({ searchValue: value });
              }}
            />
            <button
              id="submit_filter"
              name="submit_filter"
              type="button"
              className={classNames(
                table['search-input__button'],
                table.filter,
                onlySearch && table['only-search'],
                filterValues.search && table.close
              )}
              onClick={
                !filterValues.search
                  ? () => ReactDOM.findDOMNode(this.searchInput).focus()
                  : () => {
                      change(form, 'search', '');
                      this.setState({ searchValue: '' });
                      this.props.onFilter();
                    }
              }
            />
            <Button
              id="submit_filter"
              name="submit_filter"
              className={table['only-search-go-button']}
              color="primary"
              onClick={() => {
                change(form, 'search', searchValue);
                this.props.onFilter();
              }}
              type="submit"
            >
              <FormattedMessage id="go" />
            </Button>
          </div>
        ) : (
          <Container>
            <Row
              style={{
                position: 'relative',
                marginBottom: participantsForm && user.get('isClient') ? 90 : 30
              }}
            >
              <div>
                <Col xs={6}>
                  {user.get('isPartner') ||
                  user.get('isClient') ||
                  user.get('active_role') === 'stakeholder'
                    ? null
                    : inputGroups.partner}
                  {isPartnerAssessor && inputGroups.partner}

                  {!user.get('isClient') &&
                    user.get('active_role') !== 'stakeholder' &&
                    inputGroups.client}

                  {inputGroups.assessment}

                  {(user.get('isPartner') ||
                    user.get('isClient') ||
                    (user.get('entity') === 'pinsight' &&
                      user.get('active_role') === 'stakeholder')) &&
                    !isPartnerAssessor &&
                    inputGroups.fromTo}
                </Col>
                <Col xs={6}>
                  {((!user.get('isPartner') && !user.get('isClient')) ||
                    isPartnerAssessor) &&
                    user.get('active_role') !== 'stakeholder' &&
                    inputGroups.fromTo}

                  {inputGroups.tag}

                  {participantsForm && inputGroups.stakeholders}
                  {inputGroups.search}
                </Col>
              </div>

              <div
                style={{
                  position: 'absolute',
                  right: 15,
                  bottom: participantsForm && user.get('isClient') ? -50 : 9
                }}
              >
                <Button
                  id="reset_filter"
                  name="reset_filter"
                  color="link"
                  style={{ width: 150, color: '#c53e3e' }}
                  onClick={() => this.resetFilter(fetchOnResetClick)}
                  type="button"
                  disabled={this.props.analyticsLoading}
                >
                  <FormattedMessage id="clear_filter" />
                </Button>

                <Button
                  id="submit_filter"
                  name="submit_filter"
                  color="primary"
                  style={{ width: 150 }}
                  onClick={submitForm}
                  type="submit"
                  disabled={
                    this.props.analyticsLoading ||
                    this.props.analyticsDetailLoading.find(
                      (loading) => loading === true
                    )
                  }
                >
                  <FormattedMessage id="go" />
                </Button>
              </div>
            </Row>
          </Container>
        )}
      </form>
    );
  }
}
