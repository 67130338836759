// Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';
export const GET_ME = 'GET_ME';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const LIST_CODELISTS_REQUEST = 'LIST_CODELISTS_REQUEST';
export const LIST_CODELISTS_SUCCESS = 'LIST_CODELISTS_SUCCESS';
export const LIST_CODELISTS_FAILURE = 'LIST_CODELISTS_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const LIST_QUESTIONS_REQUEST = 'LIST_QUESTIONS_REQUEST';
export const LIST_QUESTIONS_SUCCESS = 'LIST_QUESTIONS_SUCCESS';
export const LIST_QUESTIONS_FAILURE = 'LIST_QUESTIONS_FAILURE';

export const UPDATE_ANSWER_REQUEST = 'UPDATE_ANSWER_R';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_S';
export const UPDATE_ANSWER_FAILURE = 'UPDATE_ANSWER_F';

export const GET_TEST_REQUEST = 'GET_TEST_REQUEST';
export const GET_TEST_SUCCESS = 'GET_TEST_SUCCESS';
export const GET_TEST_FAILURE = 'GET_TEST_FAILURE';

export const FETCH_LAT_REQUEST = 'FETCH_LAT_REQUEST';
export const FETCH_LAT_SUCCESS = 'FETCH_LAT_SUCCESS';
export const FETCH_LAT_FAILURE = 'FETCH_LAT_FAILURE';

export const SAVE_LAT_ANSWER_REQUEST = 'SAVE_LAT_ANSWER_REQUEST';
export const SAVE_LAT_ANSWER_SUCCESS = 'SAVE_LAT_ANSWER_SUCCESS';
export const SAVE_LAT_ANSWER_FAILURE = 'SAVE_LAT_ANSWER_FAILURE';

// Users
export const RESET_USERS_DATA = 'RESET_USERS_DATA';

export const GET_USER_VIEW_REQUEST = 'GET_USER_VIEW_REQUEST';
export const GET_USER_VIEW_SUCCESS = 'GET_USER_VIEW_SUCCESS';
export const GET_USER_VIEW_FAILURE = 'GET_USER_VIEW_FAILURE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const FETCH_TIMESLOTS_REQUEST = 'FETCH_TIMESLOTS_REQUEST';
export const FETCH_TIMESLOTS_SUCCESS = 'FETCH_TIMESLOTS_SUCCESS';
export const FETCH_TIMESLOTS_FAILURE = 'FETCH_TIMESLOTS_FAILURE';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const INVITE_USERS_REQUEST = 'INVITE_USERS_REQUEST';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const INVITE_USERS_FAILURE = 'INVITE_USERS_FAILURE';

export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE';

export const UPDATE_STAGE_REQUEST = 'UPDATE_STAGE_REQUEST';
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS';
export const UPDATE_STAGE_FAILURE = 'UPDATE_STAGE_FAILURE';

export const FETCH_STAGE_HISTORY_REQUEST = 'FETCH_STAGE_HISTORY_REQUEST';
export const FETCH_STAGE_HISTORY_SUCCESS = 'FETCH_STAGE_HISTORY_SUCCESS';
export const FETCH_STAGE_HISTORY_FAILURE = 'FETCH_STAGE_HISTORY_FAILURE';

export const ALLOWED_SIMULATIONS_REQUEST = 'ALLOWED_SIMULATIONS_REQUEST';
export const ALLOWED_SIMULATIONS_SUCCESS = 'ALLOWED_SIMULATIONS_SUCCESS';
export const ALLOWED_SIMULATIONS_FAILURE = 'ALLOWED_SIMULATIONS_FAILURE';

export const EXTEND_TIME_REQUEST = 'EXTEND_TIME_REQUEST';
export const EXTEND_TIME_SUCCESS = 'EXTEND_TIME_SUCCESS';
export const EXTEND_TIME_FAILURE = 'EXTEND_TIME_FAILURE';

export const SCHEDULE_ASSESSOR_REQUEST = 'SCHEDULE_ASSESSOR_REQUEST';
export const SCHEDULE_ASSESSOR_SUCCESS = 'SCHEDULE_ASSESSOR_SUCCESS';
export const SCHEDULE_ASSESSOR_FAILURE = 'SCHEDULE_ASSESSOR_FAILURE';

export const SET_USERS_DATA_FROM_CACHE = 'SET_USERS_DATA_FROM_CACHE';

export const FETCH_FILTER_DATA_REQUEST = 'FETCH_FILTER_DATA_REQUEST';
export const FETCH_FILTER_DATA_SUCCESS = 'FETCH_FILTER_DATA_SUCCESS';
export const FETCH_FILTER_DATA_FAILURE = 'FETCH_FILTER_DATA_FAILURE';

export const MASS_RESEND_INVITATION_REQUEST = 'MASS_RESEND_INVITATION_REQUEST';
export const MASS_RESEND_INVITATION_SUCCESS = 'MASS_RESEND_INVITATION_SUCCESS';
export const MASS_RESEND_INVITATION_FAILURE = 'MASS_RESEND_INVITATION_FAILURE';

export const MASS_DEBRIEF_INVITATION_REQUEST =
  'MASS_DEBRIEF_INVITATION_REQUEST';
export const MASS_DEBRIEF_INVITATION_SUCCESS =
  'MASS_DEBRIEF_INVITATION_SUCCESS';
export const MASS_DEBRIEF_INVITATION_FAILURE =
  'MASS_DEBRIEF_INVITATION_FAILURE';

export const MASS_TAGS_UPDATE_REQUEST = 'MASS_TAGS_UPDATE_REQUEST';
export const MASS_TAGS_UPDATE_SUCCESS = 'MASS_TAGS_UPDATE_SUCCESS';
export const MASS_TAGS_UPDATE_FAILURE = 'MASS_TAGS_UPDATE_FAILURE';

export const FETCH_MASS_ACTION_TAGS_REQUEST = 'FETCH_MASS_ACTION_TAGS_REQUEST';
export const FETCH_MASS_ACTION_TAGS_SUCCESS = 'FETCH_MASS_ACTION_TAGS_SUCCESS';
export const FETCH_MASS_ACTION_TAGS_FAILURE = 'FETCH_MASS_ACTION_TAGS_FAILURE';

export const MASS_TPA_REQUEST = 'MASS_TPA_REQUEST';
export const MASS_TPA_SUCCESS = 'MASS_TPA_SUCCESS';
export const MASS_TPA_FAILURE = 'MASS_TPA_FAILURE';

export const MASS_REPORTS_ACCESS_REQUEST = 'MASS_REPORTS_ACCESS_REQUEST';
export const MASS_REPORTS_ACCESS_SUCCESS = 'MASS_REPORTS_ACCESS_SUCCESS';
export const MASS_REPORTS_ACCESS_FAILURE = 'MASS_REPORTS_ACCESS_FAILURE';

// Partners
export const FETCH_PARTNERS_REQUEST = 'FETCH_PARTNERS_REQUEST';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE';

export const CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_FAILURE = 'CREATE_PARTNER_FAILURE';

export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_FAILURE = 'GET_PARTNER_FAILURE';

export const EDIT_PARTNER_REQUEST = 'EDIT_PARTNER_REQUEST';
export const EDIT_PARTNER_SUCCESS = 'EDIT_PARTNER_SUCCESS';
export const EDIT_PARTNER_FAILURE = 'EDIT_PARTNER_FAILURE';
// Clients
export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';

export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

// Simulations
export const FETCH_SIMULATIONS_REQUEST = 'FETCH_SIMULATIONS_REQUEST';
export const FETCH_SIMULATIONS_SUCCESS = 'FETCH_SIMULATIONS_SUCCESS';
export const FETCH_SIMULATIONS_FAILURE = 'FETCH_SIMULATIONS_FAILURE';

export const FETCH_SIMULATION_REQUEST = 'FETCH_SIMULATION_REQUEST';
export const FETCH_SIMULATION_SUCCESS = 'FETCH_SIMULATION_SUCCESS';
export const FETCH_SIMULATION_FAILURE = 'FETCH_SIMULATION_FAILURE';

export const FETCH_SIMULATION_CONTACTS_REQUEST =
  'FETCH_SIMULATION_CONTACTS_REQUEST';
export const FETCH_SIMULATION_CONTACTS_SUCCESS =
  'FETCH_SIMULATION_CONTACTS_SUCCESS';
export const FETCH_SIMULATION_CONTACTS_FAILURE =
  'FETCH_SIMULATION_CONTACTS_FAILURE';

export const FETCH_SIMULATION_DOCUMENTS_REQUEST =
  'FETCH_SIMULATION_DOCUMENTS_REQUEST';
export const FETCH_SIMULATION_DOCUMENTS_SUCCESS =
  'FETCH_SIMULATION_DOCUMENTS_SUCCESS';
export const FETCH_SIMULATION_DOCUMENTS_FAILURE =
  'FETCH_SIMULATION_DOCUMENTS_FAILURE';

export const FETCH_SIMULATION_EVENTS_REQUEST =
  'FETCH_SIMULATION_EVENTS_REQUEST';
export const FETCH_SIMULATION_EVENTS_SUCCESS =
  'FETCH_SIMULATION_EVENTS_SUCCESS';
export const FETCH_SIMULATION_EVENTS_FAILURE =
  'FETCH_SIMULATION_EVENTS_FAILURE';

export const FETCH_SIMULATION_EXERCISES_REQUEST =
  'FETCH_SIMULATION_EXERCISES_REQUEST';
export const FETCH_SIMULATION_EXERCISES_SUCCESS =
  'FETCH_SIMULATION_EXERCISES_SUCCESS';
export const FETCH_SIMULATION_EXERCISES_FAILURE =
  'FETCH_SIMULATION_EXERCISES_FAILURE';

export const FETCH_SIMULATION_EMAILS_REQUEST =
  'FETCH_SIMULATION_EMAILS_REQUEST';
export const FETCH_SIMULATION_EMAILS_SUCCESS =
  'FETCH_SIMULATION_EMAILS_SUCCESS';
export const FETCH_SIMULATION_EMAILS_FAILURE =
  'FETCH_SIMULATION_EMAILS_FAILURE';

export const CREATE_SIMULATION_REQUEST = 'CREATE_SIMULATION_REQUEST';
export const CREATE_SIMULATION_SUCCESS = 'CREATE_SIMULATION_SUCCESS';
export const CREATE_SIMULATION_FAILURE = 'CREATE_SIMULATION_FAILURE';

export const EDIT_SIMULATION_REQUEST = 'EDIT_SIMULATION_REQUEST';
export const EDIT_SIMULATION_SUCCESS = 'EDIT_SIMULATION_SUCCESS';
export const EDIT_SIMULATION_FAILURE = 'EDIT_SIMULATION_FAILURE';

export const DELETE_SIMULATION_REQUEST = 'DELETE_SIMULATION_REQUEST';
export const DELETE_SIMULATION_SUCCESS = 'DELETE_SIMULATION_SUCCESS';
export const DELETE_SIMULATION_FAILURE = 'DELETE_SIMULATION_FAILURE';

export const CREATE_SIMULATION_INSTRUCTION_REQUEST =
  'CREATE_SIMULATION_INSTRUCTION_REQUEST';
export const CREATE_SIMULATION_INSTRUCTION_SUCCESS =
  'CREATE_SIMULATION_INSTRUCTION_SUCCESS';
export const CREATE_SIMULATION_INSTRUCTION_FAILURE =
  'CREATE_SIMULATION_INSTRUCTION_FAILURE';

export const EDIT_SIMULATION_INSTRUCTION_REQUEST =
  'EDIT_SIMULATION_INSTRUCTION_REQUEST';
export const EDIT_SIMULATION_INSTRUCTION_SUCCESS =
  'EDIT_SIMULATION_INSTRUCTION_SUCCESS';
export const EDIT_SIMULATION_INSTRUCTION_FAILURE =
  'EDIT_SIMULATION_INSTRUCTION_FAILURE';

export const CREATE_DEBRIEF_INSTRUCTION_REQUEST =
  'CREATE_DEBRIEF_INSTRUCTION_REQUEST';
export const CREATE_DEBRIEF_INSTRUCTION_SUCCESS =
  'CREATE_DEBRIEF_INSTRUCTION_SUCCESS';
export const CREATE_DEBRIEF_INSTRUCTION_FAILURE =
  'CREATE_DEBRIEF_INSTRUCTION_FAILURE';

export const EDIT_DEBRIEF_INSTRUCTION_REQUEST =
  'EDIT_DEBRIEF_INSTRUCTION_REQUEST';
export const EDIT_DEBRIEF_INSTRUCTION_SUCCESS =
  'EDIT_DEBRIEF_INSTRUCTION_SUCCESS';
export const EDIT_DEBRIEF_INSTRUCTION_FAILURE =
  'EDIT_DEBRIEF_INSTRUCTION_FAILURE';

export const DELETE_SIMULATION_INSTRUCTION_REQUEST =
  'DELETE_SIMULATION_INSTRUCTION_REQUEST';
export const DELETE_SIMULATION_INSTRUCTION_SUCCESS =
  'DELETE_SIMULATION_INSTRUCTION_SUCCESS';
export const DELETE_SIMULATION_INSTRUCTION_FAILURE =
  'DELETE_SIMULATION_INSTRUCTION_FAILURE';

export const DELETE_DEBRIEF_INSTRUCTION_REQUEST =
  'DELETE_DEBRIEF_INSTRUCTION_REQUEST';
export const DELETE_DEBRIEF_INSTRUCTION_SUCCESS =
  'DELETE_DEBRIEF_INSTRUCTION_SUCCESS';
export const DELETE_DEBRIEF_INSTRUCTION_FAILURE =
  'DELETE_DEBRIEF_INSTRUCTION_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';

export const EDIT_CONTACT_REQUEST = 'EDIT_CONTACT_REQUEST';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILURE = 'EDIT_CONTACT_FAILURE';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';

export const CREATE_EXERCISE_REQUEST = 'CREATE_EXERCISE_REQUEST';
export const CREATE_EXERCISE_SUCCESS = 'CREATE_EXERCISE_SUCCESS';
export const CREATE_EXERCISE_FAILURE = 'CREATE_EXERCISE_FAILURE';

export const EDIT_EXERCISE_REQUEST = 'EDIT_EXERCISE_REQUEST';
export const EDIT_EXERCISE_SUCCESS = 'EDIT_EXERCISE_SUCCESS';
export const EDIT_EXERCISE_FAILURE = 'EDIT_EXERCISE_FAILURE';

export const DELETE_EXERCISE_REQUEST = 'DELETE_EXERCISE_REQUEST';
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS';
export const DELETE_EXERCISE_FAILURE = 'DELETE_EXERCISE_FAILURE';

export const CREATE_EMAIL_REQUEST = 'CREATE_EMAIL_REQUEST';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const CREATE_EMAIL_FAILURE = 'CREATE_EMAIL_FAILURE';

export const EDIT_EMAIL_REQUEST = 'EDIT_EMAIL_REQUEST';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_FAILURE = 'EDIT_EMAIL_FAILURE';

export const DELETE_EMAIL_REQUEST = 'DELETE_EMAIL_REQUEST';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_FAILURE = 'DELETE_EMAIL_FAILURE';

export const FETCH_EMAIL_ATTACHMENT_REQUEST = 'FETCH_EMAIL_ATTACHMENT_REQUEST';
export const FETCH_EMAIL_ATTACHMENT_SUCCESS = 'FETCH_EMAIL_ATTACHMENT_SUCCESS';
export const FETCH_EMAIL_ATTACHMENT_FAILURE = 'FETCH_EMAIL_ATTACHMENT_FAILURE';

export const CREATE_EMAIL_ATTACHMENT_REQUEST =
  'CREATE_EMAIL_ATTACHMENT_REQUEST';
export const CREATE_EMAIL_ATTACHMENT_SUCCESS =
  'CREATE_EMAIL_ATTACHMENT_SUCCESS';
export const CREATE_EMAIL_ATTACHMENT_FAILURE =
  'CREATE_EMAIL_ATTACHMENT_FAILURE';

export const EDIT_EMAIL_ATTACHMENT_REQUEST = 'EDIT_EMAIL_ATTACHMENT_REQUEST';
export const EDIT_EMAIL_ATTACHMENT_SUCCESS = 'EDIT_EMAIL_ATTACHMENT_SUCCESS';
export const EDIT_EMAIL_ATTACHMENT_FAILURE = 'EDIT_EMAIL_ATTACHMENT_FAILURE';

export const DELETE_EMAIL_ATTACHMENT_REQUEST =
  'DELETE_EMAIL_ATTACHMENT_REQUEST';
export const DELETE_EMAIL_ATTACHMENT_SUCCESS =
  'DELETE_EMAIL_ATTACHMENT_SUCCESS';
export const DELETE_EMAIL_ATTACHMENT_FAILURE =
  'DELETE_EMAIL_ATTACHMENT_FAILURE';

export const SCHEDULE_REQUEST = 'SCHEDULE_REQUEST';
export const SCHEDULE_SUCCESS = 'SCHEDULE_SUCCESS';
export const SCHEDULE_FAILURE = 'SCHEDULE_FAILURE';

export const SURVEY_ACTION_REQUEST = 'SURVEY_ACTION_REQUEST';
export const SURVEY_ACTION_SUCCESS = 'SURVEY_ACTION_SUCCESS';
export const SURVEY_ACTION_FAILURE = 'SURVEY_ACTION_FAILURE';

export const LIST_TIMES_REQUEST = 'LIST_TIMES_REQUEST';
export const LIST_TIMES_SUCCESS = 'LIST_TIMES_SUCCESS';
export const LIST_TIMES_FAILURE = 'LIST_TIMES_FAILURE';

export const UPDATE_SIMULATION_LOCK_REQUEST = 'UPDATE_SIMULATION_LOCK_REQUEST';
export const UPDATE_SIMULATION_LOCK_SUCCESS = 'UPDATE_SIMULATION_LOCK_SUCCESS';
export const UPDATE_SIMULATION_LOCK_FAILURE = 'UPDATE_SIMULATION_LOCK_FAILURE';

export const RESET_USER_SEED = 'RESET_USER_SEED';

// Behaviors
export const FETCH_BEHAVIORS_REQUEST = 'FETCH_BEHAVIORS_REQUEST';
export const FETCH_BEHAVIORS_SUCCESS = 'FETCH_BEHAVIORS_SUCCESS';
export const FETCH_BEHAVIORS_FAILURE = 'FETCH_BEHAVIORS_FAILURE';

export const FETCH_BEHAVIOR_REQUEST = 'FETCH_BEHAVIOR_REQUEST';
export const FETCH_BEHAVIOR_SUCCESS = 'FETCH_BEHAVIOR_SUCCESS';
export const FETCH_BEHAVIOR_FAILURE = 'FETCH_BEHAVIOR_FAILURE';

export const CREATE_BEHAVIOR_REQUEST = 'CREATE_BEHAVIOR_REQUEST';
export const CREATE_BEHAVIOR_SUCCESS = 'CREATE_BEHAVIOR_SUCCESS';
export const CREATE_BEHAVIOR_FAILURE = 'CREATE_BEHAVIOR_FAILURE';

export const EDIT_BEHAVIOR_REQUEST = 'EDIT_BEHAVIOR_REQUEST';
export const EDIT_BEHAVIOR_SUCCESS = 'EDIT_BEHAVIOR_SUCCESS';
export const EDIT_BEHAVIOR_FAILURE = 'EDIT_BEHAVIOR_FAILURE';

export const DELETE_BEHAVIOR_REQUEST = 'DELETE_BEHAVIOR_REQUEST';
export const DELETE_BEHAVIOR_SUCCESS = 'DELETE_BEHAVIOR_SUCCESS';
export const DELETE_BEHAVIOR_FAILURE = 'DELETE_BEHAVIOR_FAILURE';

export const FETCH_INDICATOR_REQUEST = 'FETCH_INDICATOR_REQUEST';
export const FETCH_INDICATOR_SUCCESS = 'FETCH_INDICATOR_SUCCESS';
export const FETCH_INDICATOR_FAILURE = 'FETCH_INDICATOR_FAILURE';

export const FETCH_INDICATORS_REQUEST = 'FETCH_INDICATORS_REQUEST';
export const FETCH_INDICATORS_SUCCESS = 'FETCH_INDICATORS_SUCCESS';
export const FETCH_INDICATORS_FAILURE = 'FETCH_INDICATORS_FAILURE';

export const CREATE_INDICATOR_REQUEST = 'CREATE_INDICATOR_REQUEST';
export const CREATE_INDICATOR_SUCCESS = 'CREATE_INDICATOR_SUCCESS';
export const CREATE_INDICATOR_FAILURE = 'CREATE_INDICATOR_FAILURE';

export const EDIT_INDICATOR_REQUEST = 'EDIT_INDICATOR_REQUEST';
export const EDIT_INDICATOR_SUCCESS = 'EDIT_INDICATOR_SUCCESS';
export const EDIT_INDICATOR_FAILURE = 'EDIT_INDICATOR_FAILURE';

export const DELETE_INDICATOR_REQUEST = 'DELETE_INDICATOR_REQUEST';
export const DELETE_INDICATOR_SUCCESS = 'DELETE_INDICATOR_SUCCESS';
export const DELETE_INDICATOR_FAILURE = 'DELETE_INDICATOR_FAILURE';

export const CREATE_RELATIONSHIP_REQUEST = 'CREATE_RELATIONSHIP_REQUEST';
export const CREATE_RELATIONSHIP_SUCCESS = 'CREATE_RELATIONSHIP_SUCCESS';
export const CREATE_RELATIONSHIP_FAILURE = 'CREATE_RELATIONSHIP_FAILURE';

export const EDIT_RELATIONSHIP_REQUEST = 'EDIT_RELATIONSHIP_REQUEST';
export const EDIT_RELATIONSHIP_SUCCESS = 'EDIT_RELATIONSHIP_SUCCESS';
export const EDIT_RELATIONSHIP_FAILURE = 'EDIT_RELATIONSHIP_FAILURE';

export const DELETE_RELATIONSHIP_REQUEST = 'DELETE_RELATIONSHIP_REQUEST';
export const DELETE_RELATIONSHIP_SUCCESS = 'DELETE_RELATIONSHIP_SUCCESS';
export const DELETE_RELATIONSHIP_FAILURE = 'DELETE_RELATIONSHIP_FAILURE';

export const FETCH_FACETS_REQUEST = 'FETCH_FACETS_REQUEST';
export const FETCH_FACETS_SUCCESS = 'FETCH_FACETS_SUCCESS';
export const FETCH_FACETS_FAILURE = 'FETCH_FACETS_FAILURE';

// APP
export const RESET_FORM_ERRORS = 'RESET_FORM_ERRORS';

export const SET_PROGRESS = 'SET_PROGRESS';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const DELETE_ENTITY_REQUEST = 'DELETE_ENTITY_REQUEST';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAILURE = 'DELETE_ENTITY_FAILURE';

// ASSIGNMENTS

export const FETCH_ASSIGNMENTS_REQUEST = 'FETCH_ASSIGNMENTS_R';
export const FETCH_ASSIGNMENTS_SUCCESS = 'FETCH_ASSIGNMENTS_S';
export const FETCH_ASSIGNMENTS_FAILURE = 'FETCH_ASSIGNMENTS_F';

export const FETCH_ASSIGNMENT_REQUEST = 'FETCH_ASSIGNMENT_REQUEST';
export const FETCH_ASSIGNMENT_SUCCESS = 'FETCH_ASSIGNMENT_SUCCESS';
export const FETCH_ASSIGNMENT_FAILURE = 'FETCH_ASSIGNMENT_FAILURE';

export const FETCH_SCORED_ASSIGNMENTS_REQUEST =
  'FETCH_SCORED_ASSIGNMENTS_REQUEST';
export const FETCH_SCORED_ASSIGNMENTS_SUCCESS =
  'FETCH_SCORED_ASSIGNMENTS_SUCCESS';
export const FETCH_SCORED_ASSIGNMENTS_FAILURE =
  'FETCH_SCORED_ASSIGNMENTS_FAILURE';

export const SCORE_ASSIGNMENT_REQUEST = 'SCORE_ASSIGNMENT_REQUEST';
export const SCORE_ASSIGNMENT_SUCCESS = 'SCORE_ASSIGNMENT_SUCCESS';
export const SCORE_ASSIGNMENT_FAILURE = 'SCORE_ASSIGNMENT_FAILURE';

export const IS_IN_CALL_REQUEST = 'IS_IN_CALL_REQUEST';
export const IS_IN_CALL_SUCCESS = 'IS_IN_CALL_SUCCESS';
export const IS_IN_CALL_FAILURE = 'IS_IN_CALL_FAILURE';

export const FETCH_ASSESSOR_DATA_REQUEST = 'FETCH_ASSESSOR_DATA_REQUEST';
export const FETCH_ASSESSOR_DATA_SUCCESS = 'FETCH_ASSESSOR_DATA_SUCCESS';
export const FETCH_ASSESSOR_DATA_FAILURE = 'FETCH_ASSESSOR_DATA_FAILURE';

export const GET_ASSESSOR_DATA_REQUEST = 'GET_ASSESSOR_DATA_REQUEST';
export const GET_ASSESSOR_DATA_SUCCESS = 'GET_ASSESSOR_DATA_SUCCESS';
export const GET_ASSESSOR_DATA_FAILURE = 'GET_ASSESSOR_DATA_FAILURE';

export const TOKBOX_CONNECT_REQUEST = 'TOKBOX_CONNECT_REQUEST';
export const TOKBOX_CONNECT_SUCCESS = 'TOKBOX_CONNECT_SUCCESS';
export const TOKBOX_CONNECT_FAILURE = 'TOKBOX_CONNECT_FAILURE';

export const TWILIO_CONNECT_REQUEST = 'TWILIO_CONNECT_REQUEST';
export const TWILIO_CONNECT_SUCCESS = 'TWILIO_CONNECT_SUCCESS';
export const TWILIO_CONNECT_FAILURE = 'TWILIO_CONNECT_FAILURE';

export const START_VIDEO_CALL_REQUEST = 'START_VIDEO_CALL_REQUEST';
export const START_VIDEO_CALL_SUCCESS = 'START_VIDEO_CALL_SUCCESS';
export const START_VIDEO_CALL_FAILURE = 'START_VIDEO_CALL_FAILURE';

// ANALYTICS

export const FETCH_ANALYTICS_REQUEST = 'FETCH_ANALYTICS_REQUEST';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_FAILURE = 'FETCH_ANALYTICS_FAILURE';

export const FETCH_ANALYTICS_OVERVIEW_REQUEST =
  'FETCH_ANALYTICS_OVERVIEW_REQUEST';
export const FETCH_ANALYTICS_OVERVIEW_SUCCESS =
  'FETCH_ANALYTICS_OVERVIEW_SUCCESS';
export const FETCH_ANALYTICS_OVERVIEW_FAILURE =
  'FETCH_ANALYTICS_OVERVIEW_FAILURE';

export const FETCH_REPORT_USERS_REQUEST = 'FETCH_REPORT_USERS_REQUEST';
export const FETCH_REPORT_USERS_SUCCESS = 'FETCH_REPORT_USERS_SUCCESS';
export const FETCH_REPORT_USERS_FAILURE = 'FETCH_REPORT_USERS_FAILURE';

export const FETCH_ANALYTICS_DETAIL_REQUEST = 'FETCH_ANALYTICS_DETAIL_REQUEST';
export const FETCH_ANALYTICS_DETAIL_SUCCESS = 'FETCH_ANALYTICS_DETAIL_SUCCESS';
export const FETCH_ANALYTICS_DETAIL_FAILURE = 'FETCH_ANALYTICS_DETAIL_FAILURE';

export const SHARE_REPORT_REQUEST = 'SHARE_REPORT_REQUEST';
export const SHARE_REPORT_SUCCESS = 'SHARE_REPORT_SUCCESS';
export const SHARE_REPORT_FAILURE = 'SHARE_REPORT_FAILURE';

export const REMOVE_REPORT_REQUEST = 'REMOVE_REPORT_REQUEST';
export const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS';
export const REMOVE_REPORT_FAILURE = 'REMOVE_REPORT_FAILURE';

export const DOWNLOAD_PDF_REQUEST = 'DOWNLOAD_PDF_REQUEST';
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS';
export const DOWNLOAD_PDF_FAILURE = 'DOWNLOAD_PDF_FAILURE';

export const DOWNLOAD_EXCEL_REQUEST = 'DOWNLOAD_EXCEL_REQUEST';
export const DOWNLOAD_EXCEL_SUCCESS = 'DOWNLOAD_EXCEL_SUCCESS';
export const DOWNLOAD_EXCEL_FAILURE = 'DOWNLOAD_EXCEL_FAILURE';

export const DOWNLOAD_ASSIGNMENTS_REQUEST = 'DOWNLOAD_ASSIGNMENTS_REQUEST';
export const DOWNLOAD_ASSIGNMENTS_SUCCESS = 'DOWNLOAD_ASSIGNMENTS_SUCCESS';
export const DOWNLOAD_ASSIGNMENTS_FAILURE = 'DOWNLOAD_ASSIGNMENTS_FAILURE';

export const DOWNLOAD_USERS_TABLE_REQUEST = 'DOWNLOAD_USERS_TABLE_REQUEST';
export const DOWNLOAD_USERS_TABLE_SUCCESS = 'DOWNLOAD_USERS_TABLE_SUCCESS';
export const DOWNLOAD_USERS_TABLE_FAILURE = 'DOWNLOAD_USERS_TABLE_FAILURE';

export const UPDATE_SELECTED = 'UPDATE_SELECTED';

export const FILTER_ANALYTICS_DATA_REQUEST = 'FILTER_ANALYTICS_DATA_REQUEST';
export const FILTER_ANALYTICS_DATA_SUCCESS = 'FILTER_ANALYTICS_DATA_SUCCESS';
export const FILTER_ANALYTICS_DATA_FAILURE = 'FILTER_ANALYTICS_DATA_FAILURE';

export const RESET_FILTER_ANALYTICS_DATA = 'RESET_FILTER_ANALYTICS_DATA';

export const GET_NARRATIVE_REQUEST = 'GET_NARRATIVE_REQUEST';
export const GET_NARRATIVE_SUCCESS = 'GET_NARRATIVE_SUCCESS';
export const GET_NARRATIVE_FAILURE = 'GET_NARRATIVE_FAILURE';

export const GET_CLIMATE_REQUEST = 'GET_CLIMATE_REQUEST';
export const GET_CLIMATE_SUCCESS = 'GET_CLIMATE_SUCCESS';
export const GET_CLIMATE_FAILURE = 'GET_CLIMATE_FAILURE';
export const RESET_CLIMATE_DATA = 'RESET_CLIMATE_DATA';

export const UPDATE_NARRATIVE_REQUEST = 'UPDATE_NARRATIVE_REQUEST';
export const UPDATE_NARRATIVE_SUCCESS = 'UPDATE_NARRATIVE_SUCCESS';
export const UPDATE_NARRATIVE_FAILURE = 'UPDATE_NARRATIVE_FAILURE';

export const UPDATE_LOCK_NARRATIVE_REQUEST = 'UPDATE_LOCK_NARRATIVE_REQUEST';
export const UPDATE_LOCK_NARRATIVE_SUCCESS = 'UPDATE_LOCK_NARRATIVE_SUCCESS';
export const UPDATE_LOCK_NARRATIVE_FAILURE = 'UPDATE_LOCK_NARRATIVE_FAILURE';

export const GET_SHAREABLE_STAKEHOLDERS_REQUEST =
  'GET_SHAREABLE_STAKEHOLDERS_REQUEST';
export const GET_SHAREABLE_STAKEHOLDERS_SUCCESS =
  'GET_SHAREABLE_STAKEHOLDERS_SUCCESS';
export const GET_SHAREABLE_STAKEHOLDERS_FAILURE =
  'GET_SHAREABLE_STAKEHOLDERS_FAILURE';

export const GET_SELECTED_STAKEHOLDERS_REQUEST =
  'GET_SELECTED_STAKEHOLDERS_REQUEST';
export const GET_SELECTED_STAKEHOLDERS_SUCCESS =
  'GET_SELECTED_STAKEHOLDERS_SUCCESS';
export const GET_SELECTED_STAKEHOLDERS_FAILURE =
  'GET_SELECTED_STAKEHOLDERS_FAILURE';

// CUSTOMIZE/ASSESSMENTS

export const FETCH_ASSESSMENTS_REQUEST = 'FETCH_ASSESSMENTS_REQUEST';
export const FETCH_ASSESSMENTS_SUCCESS = 'FETCH_ASSESSMENTS_SUCCESS';
export const FETCH_ASSESSMENTS_FAILURE = 'FETCH_ASSESSMENTS_FAILURE';

export const FETCH_ASSESSMENT_REQUEST = 'FETCH_ASSESSMENT_REQUEST';
export const FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS';
export const FETCH_ASSESSMENT_FAILURE = 'FETCH_ASSESSMENT_FAILURE';

export const CREATE_ASSESSMENT_REQUEST = 'CREATE_ASSESSMENT_REQUEST';
export const CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS';
export const CREATE_ASSESSMENT_FAILURE = 'CREATE_ASSESSMENT_FAILURE';

export const EDIT_ASSESSMENT_REQUEST = 'EDIT_ASSESSMENT_REQUEST';
export const EDIT_ASSESSMENT_SUCCESS = 'EDIT_ASSESSMENT_SUCCESS';
export const EDIT_ASSESSMENT_FAILURE = 'EDIT_ASSESSMENT_FAILURE';

export const EDIT_ASSESSMENT_BEHAVIOR_REQUEST =
  'EDIT_ASSESSMENT_BEHAVIOR_REQUEST';
export const EDIT_ASSESSMENT_BEHAVIOR_SUCCESS =
  'EDIT_ASSESSMENT_BEHAVIOR_SUCCESS';
export const EDIT_ASSESSMENT_BEHAVIOR_FAILURE =
  'EDIT_ASSESSMENT_BEHAVIOR_FAILURE';

export const DELETE_ASSESSMENT_REQUEST = 'DELETE_ASSESSMENT_REQUEST';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAILURE = 'DELETE_ASSESSMENT_FAILURE';

export const LIST_FOCUSES_REQUEST = 'LIST_FOCUSES_REQUEST';
export const LIST_FOCUSES_SUCCESS = 'LIST_FOCUSES_SUCCESS';
export const LIST_FOCUSES_FAILURE = 'LIST_FOCUSES_FAILURE';

export const LIST_PRIORITIES_REQUEST = 'LIST_PRIORITIES_REQUEST';
export const LIST_PRIORITIES_SUCCESS = 'LIST_PRIORITIES_SUCCESS';
export const LIST_PRIORITIES_FAILURE = 'LIST_PRIORITIES_FAILURE';

export const UPDATE_PRIORITIES_REQUEST = 'UPDATE_PRIORITIES_REQUEST';
export const UPDATE_PRIORITIES_SUCCESS = 'UPDATE_PRIORITIES_SUCCESS';
export const UPDATE_PRIORITIES_FAILURE = 'UPDATE_PRIORITIES_FAILURE';

export const GET_CURRENT_FOCUS_REQUEST = 'GET_CURRENT_FOCUS_REQUEST';
export const GET_CURRENT_FOCUS_SUCCESS = 'GET_CURRENT_FOCUS_SUCCESS';
export const GET_CURRENT_FOCUS_FAILURE = 'GET_CURRENT_FOCUS_FAILURE';

export const SET_WEEKLY_FOCUS_REQUEST = 'SET_WEEKLY_FOCUS_REQUEST';
export const SET_WEEKLY_FOCUS_SUCCESS = 'SET_WEEKLY_FOCUS_SUCCESS';
export const SET_WEEKLY_FOCUS_FAILURE = 'SET_WEEKLY_FOCUS_FAILURE';

export const LIST_DAILY_EXERCISES_REQUEST = 'LIST_DAILY_EXERCISES_REQUEST';
export const LIST_DAILY_EXERCISES_SUCCESS = 'LIST_DAILY_EXERCISES_SUCCESS';
export const LIST_DAILY_EXERCISES_FAILURE = 'LIST_DAILY_EXERCISES_FAILURE';

export const UPDATE_DAILY_EXERCISE_REQUEST = 'UPDATE_DAILY_EXERCISE_REQUEST';
export const UPDATE_DAILY_EXERCISE_SUCCESS = 'UPDATE_DAILY_EXERCISE_SUCCESS';
export const UPDATE_DAILY_EXERCISE_FAILURE = 'UPDATE_DAILY_EXERCISE_FAILURE';

export const LIST_IMPROVEMENT_REQUEST = 'LIST_IMPROVEMENT_REQUEST';
export const LIST_IMPROVEMENT_SUCCESS = 'LIST_IMPROVEMENT_SUCCESS';
export const LIST_IMPROVEMENT_FAILURE = 'LIST_IMPROVEMENT_FAILURE';

export const FETCH_ASSESSMENT_STRATEGIES_REQUEST =
  'FETCH_ASSESSMENT_STRATEGIES_REQUEST';
export const FETCH_ASSESSMENT_STRATEGIES_SUCCESS =
  'FETCH_ASSESSMENT_STRATEGIES_SUCCESS';
export const FETCH_ASSESSMENT_STRATEGIES_FAILURE =
  'FETCH_ASSESSMENT_STRATEGIES_FAILURE';

export const CREATE_STRATEGY_REQUEST = 'CREATE_STRATEGY_REQUEST';
export const CREATE_STRATEGY_SUCCESS = 'CREATE_STRATEGY_SUCCESS';
export const CREATE_STRATEGY_FAILURE = 'CREATE_STRATEGY_FAILURE';

export const EDIT_STRATEGY_REQUEST = 'EDIT_STRATEGY_REQUEST';
export const EDIT_STRATEGY_SUCCESS = 'EDIT_STRATEGY_SUCCESS';
export const EDIT_STRATEGY_FAILURE = 'EDIT_STRATEGY_FAILURE';

export const DELETE_STRATEGY_REQUEST = 'DELETE_STRATEGY_REQUEST';
export const DELETE_STRATEGY_SUCCESS = 'DELETE_STRATEGY_SUCCESS';
export const DELETE_STRATEGY_FAILURE = 'DELETE_STRATEGY_FAILURE';

export const RESCHEDULE_USER_REQUEST = 'RESCHEDULE_USER_REQUEST';
export const RESCHEDULE_USER_SUCCESS = 'RESCHEDULE_USER_SUCCESS';
export const RESCHEDULE_USER_FAILURE = 'RESCHEDULE_USER_FAILURE';

export const POST_TICKET_REQUEST = 'POST_TICKET_REQUEST';
export const POST_TICKET_SUCCESS = 'POST_TICKET_SUCCESS';
export const POST_TICKET_FAILURE = 'POST_TICKET_FAILURE';

export const RESEND_DEVELOPMENT_INVITATION_REQUEST =
  'RESEND_DEVELOPMENT_INVITATION_REQUEST';
export const RESEND_DEVELOPMENT_INVITATION_SUCCESS =
  'RESEND_DEVELOPMENT_INVITATION_SUCCESS';
export const RESEND_DEVELOPMENT_INVITATION_FAILURE =
  'RESEND_DEVELOPMENT_INVITATION_FAILURE';

// App
export const SET_LOCALE = 'SET_LOCALE';
export const SET_DIRECTION = 'SET_DIRECTION';
export const SET_UPLOAD_REQUEST = 'SET_UPLOAD_REQUEST';
export const ABORT_UPLOAD_REQUEST = 'ABORT_UPLOAD_REQUEST';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';

// Debriefs
export const FETCH_DEBRIEFS_REQUEST = 'FETCH_DEBRIEFS_REQUEST';
export const FETCH_DEBRIEFS_SUCCESS = 'FETCH_DEBRIEFS_SUCCESS';
export const FETCH_DEBRIEFS_FAILURE = 'FETCH_DEBRIEFS_FAILURE';

export const CREATE_DEBRIEF_REQUEST = 'CREATE_DEBRIEF_REQUEST';
export const CREATE_DEBRIEF_SUCCESS = 'CREATE_DEBRIEF_SUCCESS';
export const CREATE_DEBRIEF_FAILURE = 'CREATE_DEBRIEF_FAILURE';

export const EDIT_DEBRIEF_REQUEST = 'EDIT_DEBRIEF_REQUEST';
export const EDIT_DEBRIEF_SUCCESS = 'EDIT_DEBRIEF_SUCCESS';
export const EDIT_DEBRIEF_FAILURE = 'EDIT_DEBRIEF_FAILURE';

export const DELETE_DEBRIEF_REQUEST = 'DELETE_DEBRIEF_REQUEST';
export const DELETE_DEBRIEF_SUCCESS = 'DELETE_DEBRIEF_SUCCESS';
export const DELETE_DEBRIEF_FAILURE = 'DELETE_DEBRIEF_FAILURE';

export const FETCH_DEBRIEF_REQUEST = 'FETCH_DEBRIEF_REQUEST';
export const FETCH_DEBRIEF_SUCCESS = 'FETCH_DEBRIEF_SUCCESS';
export const FETCH_DEBRIEF_FAILURE = 'FETCH_DEBRIEF_FAILURE';

export const FETCH_DEBRIEF_DOCUMENTS_REQUEST =
  'FETCH_DEBRIEF_DOCUMENTS_REQUEST';
export const FETCH_DEBRIEF_DOCUMENTS_SUCCESS =
  'FETCH_DEBRIEF_DOCUMENTS_SUCCESS';
export const FETCH_DEBRIEF_DOCUMENTS_FAILURE =
  'FETCH_DEBRIEF_DOCUMENTS_FAILURE';

// Virtual Navigation
export const SET_VIRTUAL_BUTTON = 'SET_VIRTUAL_BUTTON';
export const SET_SHOW_IFRAME = 'SET_SHOW_IFRAME';
export const SET_IFRAME_SRC = 'SET_IFRAME_SRC';
