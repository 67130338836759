// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Styles
import previewStyles from './styles.css';
import './styles.css';

// Components
import { UsersTable } from '../../components';
import { Dropdown } from 'components';

// Styles
import '../styles.css';

/**
 * Users
 * @constructor
 */
@connect((state) => ({
  permissions: state.app.get('permissions'),
  user: state.auth.get('loggedUser'),
  clients: state.clients.get('allClients')
}))
export default class Users extends Component {
  // Prop types
  static propTypes = {
    permissions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    clients: PropTypes.object.isRequired
  };

  /**
   * Get add actions
   * @returns {Array}
   */
  getAddActions = () => {
    const { permissions, user, clients } = this.props;
    const result = [];

    permissions.map((permission) => {
      switch (permission) {
        case 'users_add_participant_view_link':
          result.push({
            order: 1,
            title: 'ADD_MANUALLY_ITEMS.new_participant',
            path: '/users/participant/create'
          });
          break;
        case 'users_add_stakeholder_view_link':
          result.push({
            order: 2,
            title: 'ADD_MANUALLY_ITEMS.new_stakeholder',
            path: '/users/stakeholder/create'
          });
          break;
        case 'users_add_assessor_view_link':
          result.push({
            order: 3,
            title: 'ADD_MANUALLY_ITEMS.new_assessor',
            path: '/users/assessor/create'
          });
          break;
        case 'users_add_admin_view_link':
          if (
            user.get('active_role') === 'admin' &&
            user.getIn(['permissions_debug', 'priority_role']) !== 'owner' &&
            user.get('isPartner') &&
            clients.size === 0
          ) {
            break;
          }
          result.push({
            order: 4,
            title: 'ADD_MANUALLY_ITEMS.new_admin',
            path: '/users/admin/create'
          });
          break;
        case 'users_add_partner_view_link':
          result.push({
            order: 6,
            title: 'ADD_MANUALLY_ITEMS.new_partner',
            path: '/users/partner/create'
          });
          break;
        case 'users_add_client_view_link':
          result.push({
            order: 7,
            title: 'ADD_MANUALLY_ITEMS.new_client',
            path: '/users/client/create'
          });
          break;
        default:
          return false;
      }

      return false;
    });

    return result.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    ); // eslint-disable-line
  };

  /**
   * Get invite actions
   * @returns {Array}
   */
  getInviteActions = () => {
    const { permissions, user, clients } = this.props;
    const result = [];

    permissions.map((permission) => {
      switch (permission) {
        case 'users_invite_participant_view_link':
          result.push({
            order: 1,
            title: 'INVITE_USERS_ITEMS.invite_participant',
            path: '/users/participant/invite'
          });
          break;
        case 'users_invite_stakeholder_view_link':
          result.push({
            order: 2,
            title: 'INVITE_USERS_ITEMS.invite_stakeholder',
            path: '/users/stakeholder/invite'
          });
          break;
        case 'users_invite_admin_view_link':
          if (
            user.get('active_role') === 'admin' &&
            user.getIn(['permissions_debug', 'priority_role']) !== 'owner' &&
            user.get('isPartner') &&
            clients.size === 0
          ) {
            break;
          }
          result.push({
            order: 3,
            title: 'INVITE_USERS_ITEMS.invite_admin',
            path: '/users/admin/invite'
          });
          break;
        default:
          return false;
      }

      return false;
    });

    return result.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    ); // eslint-disable-line
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const addActions = this.getAddActions();
    const inviteActions = this.getInviteActions();

    return (
      <div>
        <div className={`clearfix ${previewStyles['users-preview-head']}`}>
          <h1 className="float-xs-left">
            <FormattedMessage id="USERS" />
          </h1>

          <div className={`float-xs-right ${previewStyles['create-buttons']}`}>
            {addActions.length > 0 && (
              <Dropdown
                plus
                title="ADD_MANUALLY"
                customClassName={previewStyles['users-preview-actions']}
                translate
                items={addActions}
              />
            )}
            {inviteActions.length > 0 && (
              <Dropdown
                plus
                title="INVITE_USERS"
                customClassName={previewStyles['users-preview-actions-blue']}
                translate
                items={inviteActions}
              />
            )}
          </div>
        </div>

        <UsersTable />
      </div>
    );
  }
}
