import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

import { Alert, Tooltip } from 'reactstrap';
import { BuildTable, ColorIndicator } from 'components';
import { getProgressValues } from 'helpers';

import styles from '../styles.css';

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

const getPerformance = (props, context) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getTable = (selected) => {
    const { data } = props;
    const report = data.find((r) => r.get('id') === Number(selected));

    if (!selected || !report) {
      return {
        data: [],
        fields: []
      };
    }

    const sorted = report
      .getIn(['simulation', 'default', 'behaviors'])
      .sort((a, b) => {
        if (a.get('tbis_perc') > b.get('tbis_perc')) {
          return -1;
        }
        if (a.get('tbis_perc') < b.get('tbis_perc')) {
          return 1;
        }

        return 0;
      });

    const fields = [
      {
        key: 'skill',
        sortable: false,
        renderField: (item) => <strong>{item.get('name')}</strong>
      },
      {
        key: 'definition',
        sortable: false,
        renderField: (item) => item.get('definition')
      },
      {
        key: 'simulation_score',
        sortable: false,
        ownData: true,
        centered: true,
        renderField: (item, index) => (
          <td
            key={index}
            style={{
              minWidth: 200,
              textAlign: 'center'
            }}
          >
            <ColorIndicator
              type="circle"
              color={getProgressValues(item.get('tbis_perc')).colorString}
            />
            <strong>{Math.round(item.get('tbis_perc'))}%</strong>
          </td>
        )
      }
    ];

    if (report.get('is_improvement_visible') === true) {
      fields.push({
        key: 'improvement',
        sortable: false,
        centered: true,
        renderField: (item) => (
          <span>{parseFloat(item.get('improvement') || 0).toFixed(2)}%</span>
        )
      });
    }

    return {
      data: sorted,
      fields
    };
  };

  const getConfig = (selected) => {
    const { data } = props;
    const report = data.find((r) => r.get('id') === Number(selected));

    if (!selected || !report) {
      return false;
    }

    const categories = [];
    const simulationData = [];
    const improvementData = [];

    report
      .getIn(['simulation', 'default', 'behaviors'])
      .sort((a, b) => {
        if (a.get('tbis_perc') > b.get('tbis_perc')) {
          return -1;
        }
        if (a.get('tbis_perc') < b.get('tbis_perc')) {
          return 1;
        }

        return 0;
      })
      .map((behavior) => {
        const value = Number(behavior.get('tbis_perc'));
        const improvementValue = Number(
          (behavior.get('improvement') || 0) + value
        );

        categories.push(behavior.get('name'));
        const rowData = getProgressValues(improvementValue);
        simulationData.push({
          name: behavior.get('name'),
          color: rowData.color,
          y: value,
          value: Math.round(value)
        });

        const rgb = hexToRgb(rowData.color);
        improvementData.push({
          name: `
              ${context.intl.formatMessage({
                id: 'TABLE_HEADING.today-after-improvement'
              })}
              : ${behavior.get('name')}
          `,
          color: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7)`,
          y: improvementValue,
          value: `+ ${parseFloat(behavior.get('improvement') || 0).toFixed(2)}`
        });

        return true;
      });

    const hasDM = report.get('is_improvement_visible') === true;
    const series = hasDM
      ? [
          {
            name: context.intl.formatMessage({
              id: 'TABLE_HEADING.today-after-improvement'
            }),
            data: improvementData,
            color: '#2FBC88',
            pointPadding: hasDM ? -0.3 : 0,
            pointPlacement: hasDM ? -0.09 : 0,
            showInLegend: false
          }
        ]
      : [
          {
            name: context.intl.formatMessage({
              id: 'TABLE_HEADING.simulation'
            }),
            data: simulationData,
            color: 'transparent',
            pointPadding: 0.15,
            pointPlacement: 0.21,
            showInLegend: false
          }
        ];

    if (hasDM) {
      series.push({
        name: context.intl.formatMessage({
          id: 'TABLE_HEADING.simulation'
        }),
        data: simulationData,
        color: 'transparent',
        pointPadding: 0.15,
        pointPlacement: 0.21,
        showInLegend: false
      });
    }

    return {
      chart: {
        type: 'bar',
        height: (simulationData.length + 1) * 50
      },
      title: {
        text: null
      },
      xAxis: {
        categories,
        labels: {
          style: {
            fontSize: 13
          }
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: null
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        },
        series: {
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat:
              '<span style="font-size: 12px; font-weight: bold">{point.key} <br /></span>',
            pointFormat:
              '<span>' +
              '<span style="color:{point.color}">\u25CF</span>' +
              '<span style="font-size: 12px"> {point.value}%</span>' +
              '</span>'
          }
        }
      },
      series,
      credits: {
        enabled: false
      }
    };
  };

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const getPreviewBlock = (selected) => {
    const { data } = props;
    const report = data.find((r) => r.get('id') === Number(selected));

    if (!selected || !report) {
      return false;
    }

    const rowData = getProgressValues(
      report.getIn(['simulation', 'default', 'operf']) * 100,
      66.5
    );

    return (
      <div
        className={styles['overall-block']}
        style={{
          border: `1px solid ${rowData.color}`,
          color: rowData.color
        }}
      >
        {report.get('is_improvement_visible') === true && (
          <span className={styles.tooltip}>
            <span className={styles.circle} id="improvement-tooltip">
              ?
            </span>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="improvement-tooltip"
              toggle={toggle}
              className={styles['tooltip-box']}
            >
              <FormattedHTMLMessage id="ANALYTICS_IMPROVEMENT_TOOLTIP" />
            </Tooltip>
          </span>
        )}
        <div className={styles.left}>
          <h6>
            <FormattedMessage id="OVERALL_PERFORMANCE" />
            <br />
            <small>
              <FormattedMessage id="COLOR_SCALE" />
            </small>
          </h6>
        </div>
        <div
          className={styles.right}
          style={{
            color: rowData.colorString === 'blue' ? 'white' : 'black',
            backgroundColor: rowData.color
          }}
        >
          <small>
            <FormattedMessage id="SIMULATION" />:
          </small>
          <br /> <FormattedMessage id={rowData.position} />:{' '}
          {Math.round(report.getIn(['simulation', 'default', 'operf']) * 100)}%
        </div>
        {report.get('is_improvement_visible') === true && (
          <div className={classNames(styles.right, styles.improvement)}>
            <small>
              <FormattedMessage id="IMPROVEMENT" />:
            </small>
            <br />{' '}
            {parseFloat(
              report.getIn(['simulation', 'default', 'improvement']) || 0
            ).toFixed(2)}
            %
          </div>
        )}
      </div>
    );
  };

  const { data, route } = props;
  const current = Number(route.query.current);
  const config = getConfig(current);
  const report = data.find((r) => r.get('id') === Number(current)) || Map();

  return (
    <div className={styles.performance}>
      <Alert color="info" isOpen className={styles['analytics-infobox']}>
        <FormattedMessage id="PERFORMANCE_INFO" />
      </Alert>

      {current ? (
        <div>
          {getPreviewBlock(current)}
          <div className={`${styles['performance-graph']}`} dir="ltr">
            <ReactHighcharts config={config} />
            <div className={`${styles['performance-legend']}`}>
              <div className={styles.block}>
                <FormattedMessage id="SIMULATION" />
                <div className={styles.legend}>
                  <span className={styles.blue} />
                  <span className={styles.orange} />
                  <span className={styles.green} />
                </div>
              </div>
              {report.get('is_improvement_visible') === true && (
                <div className={styles.block}>
                  <FormattedMessage id="IMPROVEMENT" />
                  <div
                    className={classNames(styles.legend, styles.improvement)}
                  >
                    <span className={styles.blue}>
                      <span className={styles.overlay} />
                    </span>
                    <span className={styles.orange}>
                      <span className={styles.overlay} />
                    </span>
                    <span className={styles.green}>
                      <span className={styles.overlay} />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <BuildTable table={getTable(current)} />
        </div>
      ) : null}
    </div>
  );
};

getPerformance.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route
}))(
  React.memo(
    getPerformance,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
