import {
  FETCH_SIMULATION_REQUEST,
  FETCH_SIMULATION_SUCCESS,
  FETCH_SIMULATION_FAILURE,
  FETCH_DEBRIEF_REQUEST,
  FETCH_DEBRIEF_SUCCESS,
  FETCH_DEBRIEF_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch simulation action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchSimulation(id, isDebrief = false, language) {
  const types = [];

  if (isDebrief) {
    types.push(
      FETCH_DEBRIEF_REQUEST,
      FETCH_DEBRIEF_SUCCESS,
      FETCH_DEBRIEF_FAILURE
    );
  } else {
    types.push(
      FETCH_SIMULATION_REQUEST,
      FETCH_SIMULATION_SUCCESS,
      FETCH_SIMULATION_FAILURE
    );
  }

  return {
    types,
    promise: api().get(
      `/api/v1/simulations/${id}/?language=${language || 'english'}`
    ),
    language: language || 'english',
    isDebrief
  };
}
