/* eslint-disable newline-per-chained-call */
// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import Calendar from 'react-calendar/dist/entry.nostyle';
import { Row, Col } from 'reactstrap';
import ProgressCircle from '../../../ProgressCircle';

// Actions
import { getProgressValues } from 'helpers';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * My progress
 * @param props
 * @returns {XML}
 * @constructor
 */
const MyProgress = (props) => {
  const { locale, currentFocus, exercises } = props;

  return (
    <div className={styles.progress}>
      <div
        className={classNames(
          styles['progress__behavior-box'],
          styles[
            getProgressValues(
              currentFocus.has('tbis_est_perc') &&
                currentFocus.get('tbis_est_perc') !== 0
                ? currentFocus.get('tbis_est_perc')
                : currentFocus.get('tbis_perc')
            ).position
          ]
        )}
      >
        <ProgressCircle
          value={
            currentFocus
              ? currentFocus.has('tbis_est_perc') &&
                currentFocus.get('tbis_est_perc') !== 0
                ? currentFocus.get('tbis_est_perc')
                : currentFocus.get('tbis_perc')
              : null
          }
          focus={currentFocus}
        />
        <h5>
          {currentFocus.getIn(['behavior', 'name'])} -{' '}
          <FormattedMessage id="EXERCISE" /> {currentFocus.get('focus_index')}
        </h5>
      </div>

      <Row>
        <Col xs="6">
          <h6>
            <FormattedMessage id="IMPROVEMENT" />
          </h6>
          <div className={styles.box}>
            <span className={styles.green}>
              +
              {currentFocus.has('i_sum_perc')
                ? parseFloat(currentFocus.get('i_sum_perc')).toFixed(2)
                : 0}
              %
            </span>
          </div>
        </Col>
        <Col xs="6">
          <h6>
            <FormattedMessage id="DAYS_LEFT" />
          </h6>
          <div className={styles.box}>
            {Math.round(currentFocus.get('dli'))}
          </div>
        </Col>
      </Row>

      <h6>
        <FormattedMessage id="CALENDAR" />
      </h6>
      <div id="development_calendar" className={styles.calendar}>
        <Calendar
          locale={locale}
          prevLabel={
            <span id="item_prew" className={styles.link}>
              {'‹'}
            </span>
          }
          nextLabel={
            <span id="item_next" className={styles.link}>
              {'›'}
            </span>
          }
          renderChildren={({ date }) => {
            const current = exercises.find(
              (e) =>
                moment(e.get('practiced_at'))
                  .utc()
                  .utcOffset(e.get('practiced_at'))
                  .format('YYYYDDMM') === moment(date).format('YYYYDDMM')
            );

            const currentCalendarDayFormat = Number(
              moment(date).format('MMDDYYYY')
            );
            const todayFormat = Number(
              moment()
                .utc()
                .utcOffset(currentFocus.get('date_start'))
                .format('MMDDYYYY')
            );
            const isToday = currentCalendarDayFormat === todayFormat;

            return current ? (
              <div
                id={`calendar_day_${moment(date).format('DD')}`}
                className={classNames(
                  current.get('is_tracked')
                    ? styles.success
                    : isToday
                    ? null
                    : styles.failure,
                  styles.dayDiv,
                  isToday && styles.bold
                )}
              >
                {moment(date).format('DD')}
              </div>
            ) : (
              <div
                id={`calendar_day_${moment(date).format('DDMMYYYY')}`}
                className={classNames(
                  styles.dayDiv,
                  isToday && styles.bold,
                  moment(date).isBefore(
                    moment().utc().utcOffset(currentFocus.get('date_start'))
                  ) &&
                    !current &&
                    !isToday &&
                    styles.grey
                )}
              >
                {moment(date).format('DD')}
              </div>
            );
          }}
          value={null}
        />
      </div>
    </div>
  );
};

// Prop types
MyProgress.propTypes = {
  locale: PropTypes.string,
  currentFocus: PropTypes.object.isRequired,
  exercises: PropTypes.object.isRequired
};

// Connect
export default connect((state) => ({
  locale: state.app.get('locale'),
  currentFocus: state.development.get('currentFocus'),
  exercises: state.development.get('exercises')
}))(MyProgress);
