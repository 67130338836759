import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, getFormValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { Document as DocumentForm } from 'forms';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';

import {
  fetchSimulation,
  instructions as instructionAction
} from 'actions/simulations';
import { showSimulationError } from '../../actions';
import { SIMULATION, DEBRIEF } from '../../../../../../constants/stringTypes';

const form = 'documentForm';

@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    formValues: getFormValues(form)(state) || {},
    isLoading:
      state.simulations.get('instructionLoading') ||
      state.debriefs.get('instructionLoading')
  }),
  (dispatch) =>
    bindActionCreators({ submit, instructionAction, fetchSimulation }, dispatch)
)
export default class Document extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    instructionAction: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    id: PropTypes.number,
    edit: PropTypes.object,
    formValues: PropTypes.object,
    isLoading: PropTypes.bool,
    wasPublished: PropTypes.bool,
    sessionType: PropTypes.string
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.func.isRequired
  };

  handleSubmit = (data) => {
    const {
      instructionAction,
      id,
      edit,
      fetchSimulation,
      sessionType
    } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    instructionAction(
      {
        type: edit ? 'edit' : 'create',
        simulationId: id,
        data,
        id: edit ? edit.get('id') : null,
        isDebrief: sessionType === DEBRIEF
      },
      'document'
    ).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
        });
        removeModal();
      } else if (action.error && action.error.body) {
        showSimulationError(action, addAlert, intl, () => {
          removeModal();
          if (sessionType === SIMULATION) fetchSimulation(id);
          if (sessionType === DEBRIEF) fetchSimulation(id, true);
        });
      }
    });
  };

  render() {
    const { onClose, submit, edit, isLoading, wasPublished } = this.props;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage
            id={`${edit && !wasPublished ? 'EDIT' : 'NEW'}_DOCUMENT_HEADING`}
          />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div>
            <DocumentForm
              edit={edit}
              wasPublished={wasPublished}
              onSubmit={this.handleSubmit}
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_document_modal"
                name="cancel_document_modal"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_document_modal"
                name="submit_document_modal"
                color="primary"
                onClick={() => submit(form)}
                className={modals['footer-button']}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
