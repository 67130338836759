import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (id, props, context) => {
  const { removeReport } = props;
  const { addAlert, removeModal } = context;

  removeReport(id).then((action) => {
    window.scrollTo(0, 0);
    removeModal();
    if (!action.error) {
      addAlert({
        color: 'success',
        content: <FormattedMessage id="SUCCESSFULLY_DELETED" />
      });
    }
  });
};
