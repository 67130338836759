import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button } from 'reactstrap';

import styles from './styles.css';
import './styles.css';

@connect((state) => ({
  loggedUser: state.auth.get('loggedUser')
}))
class UserBox extends Component {
  static defaultProps = {
    disabled: false
  };

  static propTypes = {
    loggedUser: PropTypes.object,
    disabled: PropTypes.bool,
    user: PropTypes.object,
    onSelect: PropTypes.func,
    onShare: PropTypes.func,
    onNarrativeUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    selected: PropTypes.bool,
    nomarginB: PropTypes.bool,
    nomarginR: PropTypes.bool,
    report: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      option: false
    };
  }

  toggle = () => {
    const { option } = this.state;
    this.setState({ option: !option });
    window.addEventListener('click', this.clickHandler, false);
  };

  clickHandler = (e) => {
    if (!this.refs.box.contains(e.target)) {
      this.setState({ option: false });
      window.removeEventListener('click', this.clickHandler, false);
    }
  };

  render() {
    const {
      loggedUser,
      user,
      selected,
      onSelect,
      onShare,
      onNarrativeUpdate,
      onDelete,
      onDownload,
      report,
      disabled
    } = this.props;
    const { option } = this.state;

    const profilePhotoLink = user.get('profile_photo')
      ? `${process.env.REACT_APP_ASSETSURL}${user.get('profile_photo')}`
      : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line
    const withButton = onShare || onDelete || onDownload;

    return (
      <div className={styles['user-box']} ref="box">
        {onSelect && (
          <input
            id={`select_user_${report.get('id')}`}
            name={`select_user_${report.get('id')}`}
            type="checkbox"
            checked={selected}
            onChange={() => onSelect(report)}
            className={styles.selected}
            disabled={disabled}
          />
        )}
        {report.get('is_shared') && (
          <span className={styles['shared-indicator']} />
        )}
        <div className={styles.image}>
          <div
            className={styles['img-pic']}
            style={{ backgroundImage: `url(${profilePhotoLink})` }}
          />
        </div>
        <div className={styles.about}>
          <span
            key="user-box-name"
            className={classNames(withButton && styles.short, styles.name)}
            title={`${user.get('firstname')} ${user.get('surname')}`}
          >
            <strong>
              {user.get('firstname')} {user.get('surname')}
            </strong>
          </span>

          <span
            className={styles.simulation}
            title={report.get('assessment_name')}
          >
            {moment(report.get('date')).format('DD MMM YYYY')}{' '}
            {!withButton && <small>{report.get('assessment_name')}</small>}
          </span>
        </div>

        <button
          id={`more_button_${report.get('id')}`}
          name={`more_button_${report.get('id')}`}
          className={classNames(styles.button, withButton && styles.visible)}
          onClick={this.toggle}
          disabled={disabled}
        >
          ...
        </button>

        <div className={classNames(styles.options, option && styles.open)}>
          <ul>
            <li>
              <Button
                id={`more_share_button_${report.get('id')}`}
                name={`more_share_button_${report.get('id')}`}
                htmltype="button"
                color="link"
                disabled={
                  loggedUser.get('active_role') !== 'admin' ||
                  (report && !report.getIn(['sharing', 'is_shareable']))
                }
                onClick={() => {
                  this.setState({ option: false });
                  onShare(report);
                }}
              >
                <FormattedMessage id="SHARE" />
              </Button>
            </li>
            <li>
              <Button
                id={`more_download_button_${report.get('id')}`}
                name={`more_download_button_${report.get('id')}`}
                htmltype="button"
                color="link"
                disabled={report && !report.has('files')}
                onClick={() => {
                  this.setState({ option: false });
                  onDownload(report.get('files'));
                }}
              >
                <FormattedMessage id="DOWNLOAD_REPORT" />
              </Button>
            </li>
            {(loggedUser.get('active_role') === 'admin' ||
              loggedUser.get('active_role') === 'assessor') && (
              <li>
                <Button
                  id={`narrative_button_${report.get('id')}`}
                  name={`narrative_button_${report.get('id')}`}
                  htmltype="button"
                  color="link"
                  disabled={report && !report.get('is_narrative_editable')}
                  onClick={() => {
                    this.setState({ option: false });
                    onNarrativeUpdate(report);
                  }}
                >
                  <FormattedMessage
                    id={
                      report.get('has_narrative')
                        ? 'EDIT_NARRATIVE'
                        : 'ADD_NARRATIVE'
                    }
                  />
                </Button>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default UserBox;
