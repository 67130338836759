import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

import { updateAssessmentBehavior } from 'actions/assessments';

@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainAssessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    assessment: state.assessments.hasIn([
      'assessments',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.assessments.getIn([
          'assessments',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.assessments.getIn([
          'assessments',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route,
    behaviors: state.behaviors.getIn(['behaviors', 'english'])
  }),
  (dispatch) => bindActionCreators({ updateAssessmentBehavior }, dispatch)
)
export default class BehaviorsTranslateTable extends Component {
  static propTypes = {
    id: PropTypes.number,
    assessment: PropTypes.object,
    mainAssessment: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    updateAssessmentBehavior: PropTypes.func.isRequired,
    behaviors: PropTypes.object.isRequired
  };

  getTable = (behavior) => {
    const {
      id,
      version,
      assessment,
      mainAssessment,
      hasError,
      disabled,
      behaviors,
      updateAssessmentBehavior
    } = this.props;
    let data = [];

    if (behavior) {
      data = [
        {
          key: 'label',
          value:
            behavior.has('label_customized') && version !== 'english'
              ? behavior.get('label_customized')
              : behavior.get('label')
        },
        {
          key: 'definition',
          value:
            behavior.has('definition_customized') && version !== 'english'
              ? behavior.get('definition_customized')
              : behavior.get('definition')
        }
      ];
    }

    return {
      data,
      customClassName: 'customize-behaviors-table',
      fields: [
        {
          key: 'empty',
          dark: true,
          sortable: false,
          renderField: (item) => (
            <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
          )
        },
        {
          key: version === 'english' ? 'original_name' : 'customized_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.value
        },
        {
          key: version === 'english' ? 'customization' : 'translation',
          sortable: false,
          ownData: true,
          renderField: (item, counter) => {
            let defaultValue = null;
            let translatedBehavior;
            let mainBehavior = mainAssessment
              .get('behaviors')
              .find(
                (b) => b.get('behavior_id') === behavior.get('behavior_id')
              );

            if (assessment.has('behaviors')) {
              translatedBehavior = assessment
                .get('behaviors')
                .find(
                  (b) => b.get('behavior_id') === behavior.get('behavior_id')
                );
              mainBehavior = mainAssessment
                .get('behaviors')
                .find(
                  (b) => b.get('behavior_id') === behavior.get('behavior_id')
                );

              if (
                translatedBehavior &&
                translatedBehavior.has(`${item.key}_customized`)
              ) {
                defaultValue =
                  translatedBehavior.get(`${item.key}_customized`) || null;
              }
            }
            const allowTranslation = behaviors
              .find((b) => b.get('id') === behavior.get('behavior_id'))
              .get('languages')
              .find((l) => l.get('language') === version);

            return (
              <td
                key={counter}
                className={classNames(
                  hasError &&
                    !defaultValue &&
                    version !== 'english' &&
                    mainBehavior.has(`${item.key}_customized`) &&
                    styles.error
                )}
              >
                <TranslationRow
                  id={item.value}
                  translationKey={
                    version === 'english'
                      ? item.key === 'label'
                        ? 'ADD_NEW_LABEL'
                        : 'ADD_NEW_DEFINITION'
                      : null
                  }
                  defaultValue={defaultValue}
                  onSubmit={(e) =>
                    this.handleSubmit(
                      e,
                      item,
                      translatedBehavior || mainBehavior
                    )
                  }
                  disabled={disabled || !allowTranslation}
                />
              </td>
            );
          }
        },
        {
          key: 'display',
          sortable: false,
          ownData: true,
          isHidden: version !== 'english',
          renderField: (item, counter) => {
            const isInStrategy =
              assessment.has('strategies') &&
              assessment
                .get('strategies')
                .find((s) =>
                  s
                    .get('behaviors')
                    .find((b) => Number(b) === behavior.get('behavior_id'))
                );

            const assessmentVersion = assessment
              .get('languages')
              .find((l) => l.get('language') === 'english');

            return counter === 1 && version === 'english' ? (
              <td
                key={counter}
                style={{
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  width: '200px'
                }}
                rowSpan="2"
              >
                <input
                  id={`display-input-${item.value}`}
                  type="checkbox"
                  checked={behavior.get('display_as_default')}
                  disabled={
                    disabled ||
                    isInStrategy ||
                    assessmentVersion.get('was_published')
                  }
                  onClick={() =>
                    updateAssessmentBehavior(id, behavior.get('behavior_id'), {
                      display_as_default: !behavior.get('display_as_default')
                    })
                  }
                />
              </td>
            ) : null;
          }
        }
      ]
    };
  };

  handleSubmit = (data, field, behavior) => {
    const { id, updateAssessmentBehavior, version } = this.props;

    const translated = {
      language: version,
      [`${field.key}_customized`]: data.translation
    };

    updateAssessmentBehavior(id, behavior.get('behavior_id'), translated);
  };

  render() {
    const { assessment, mainAssessment, version } = this.props;

    if (!assessment) {
      return <div />;
    }

    return (
      <div>
        {mainAssessment.has('behaviors') &&
        mainAssessment.get('behaviors').size > 0 ? (
          mainAssessment
            .get('behaviors')
            .toArray()
            .sort((a, b) => {
              // Check if there is any other language version than english selected
              // also, check if 'label_customized' exists on both skills to compare
              if (
                version !== 'english' &&
                a.has('label_customized') &&
                b.has('label_customized')
              ) {
                // Sort skills in alphabetical order by their customized labels ("Customized name")
                return String(a.get('label_customized')).localeCompare(
                  String(b.get('label_customized'))
                );
              }
              // Sort skills in alphabetical order by their english labels ("Original name")
              return String(a.get('label')).localeCompare(
                String(b.get('label'))
              );
            })
            .map((behavior, i) => (
              <BuildTable
                table={this.getTable(behavior)}
                tableKey={i + 1}
                key={i}
                total={mainAssessment.get('behaviors').size}
              />
            ))
        ) : (
          <BuildTable table={this.getTable(null)} tableKey={1} />
        )}
      </div>
    );
  }
}
