import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Field, change } from 'redux-form';
import { Col, InputGroup, Label, Row } from 'reactstrap';
import { ToolTip } from 'components';

import styles from '../styles.css';

import { renderInput } from '../actions';

const ownerFields = [
  { key: 'firstname', placeholder: 'John', required: true },
  { key: 'surname', placeholder: 'Done', required: true },
  { key: 'email', placeholder: 'john.done@email.com', required: true },
  {
    key: 'forwarding_email',
    placeholder: 'john.done2@email.com',
    required: false
  },
  {
    key: 'tel_mobile',
    placeholder: '+421905111111',
    required: true,
    forceDir: true
  },
  {
    key: 'tel_secondary',
    placeholder: '+421905222222',
    required: false,
    forceDir: true
  }
];
const identifyingNumberField = {
  key: 'eid',
  placeholder: '12345',
  required: false,
  toolTip: (
    <ToolTip
      id="IDENTIFYING_NUMBER_JIT"
      index="IDENTIFYING_NUMBER_JIT"
      hint={<FormattedMessage id="IDENTIFYING_NUMBER_JIT" />}
    />
  )
};
const photoField = 'profile_photo';

@connect(
  (state) => ({
    formErrors: state.app.get('formErrors') || {},
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-profile'])
      ? state.app.getIn(['codeList', 'allowed-ext-profile']).toArray()
      : []
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class AccountOwner extends Component {
  static propTypes = {
    allowedTypes: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    bio: PropTypes.bool,
    subOwner: PropTypes.bool,
    formErrors: PropTypes.bool,
    invitation: PropTypes.bool,
    disabled: PropTypes.bool,
    profileNotRequired: PropTypes.bool,
    withIdentifyingNumber: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      sizeError: {},
      formatError: false
    };
  }

  onDrop(files, field) {
    const { allowedTypes, change, form, subOwner } = this.props;
    const destField = subOwner ? `owner.${field}` : field;

    this.setState({
      sizeError: { [field]: false },
      formatError: false
    });

    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      this.setState({ formatError: true });
    } else if (files[0].size > 10000000) {
      this.setState({
        sizeError: { [field]: true }
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        change(form, destField, reader.result);
        this.setState({ [field]: files[0] });
        return true;
      };
    }
  }

  removePhoto = (field) => {
    const { change, form, subOwner } = this.props;
    const destField = subOwner ? `owner.${field}` : field;
    change(form, destField, ' ');
    this.setState({ [field]: 'removed' });
  };

  getFields = () => {
    if (this.props.withIdentifyingNumber) {
      return ownerFields.concat([identifyingNumberField]);
    }

    return ownerFields;
  };

  render() {
    const {
      allowedTypes,
      header,
      user,
      bio,
      subOwner,
      formErrors,
      invitation,
      disabled,
      profileNotRequired
    } = this.props;
    const { formatError, sizeError } = this.state;
    let profilePhoto;

    if (this.state[photoField]) {
      profilePhoto = this.state[photoField].preview;
    } else if (user && user.size > 0 && this.state[photoField] !== 'removed') {
      const selector = subOwner ? user.get('owner') : user;
      if (selector.has('profile_photo')) {
        profilePhoto = `${process.env.REACT_APP_ASSETSURL}${selector.get(
          'profile_photo'
        )}`;
      }
    }

    const fields = this.getFields();

    return (
      <Row>
        <Col xs={12}>
          <h5>
            <FormattedMessage id={header} />
          </h5>
        </Col>
        <Col xs={5}>
          {fields.map((field, i) => {
            const fieldKey = subOwner ? `owner.${field.key}` : field.key;

            return (
              <InputGroup key={i}>
                <label htmlFor={fieldKey}>
                  <FormattedMessage id={`FORM_FIELDS.${field.key}`} />
                  {field.required && (
                    <span>
                      {' '}
                      (
                      <FormattedMessage id="REQUIRED" />)
                    </span>
                  )}
                  {field.toolTip}
                </label>
                <Field
                  forceDir={field.forceDir}
                  name={fieldKey}
                  component={renderInput}
                  placeholder={field.placeholder}
                  type="text"
                  subOwner={subOwner}
                  disabled={(fieldKey === 'email' && invitation) || disabled}
                />
              </InputGroup>
            );
          })}
        </Col>
        <Col xs={1} />
        <Col xs={5}>
          <InputGroup>
            <Label htmlFor={photoField}>
              <FormattedMessage id={`FORM_FIELDS.${photoField}`} />{' '}
              {!profileNotRequired && (
                <span>
                  (
                  <FormattedMessage id="REQUIRED" />)
                </span>
              )}
            </Label>

            <div
              className={classNames(
                styles['pic-div'],
                disabled && styles.disabled,
                formErrors[photoField] && styles.error
              )}
            >
              <DropZone
                id="profile_photo_dropzone"
                disabled={disabled}
                className={classNames(
                  styles['drop-zone'],
                  styles['profile-photo'],
                  disabled && styles.disabled
                )}
                style={
                  profilePhoto
                    ? {
                        backgroundImage: `url(${profilePhoto})`,
                        left: 0,
                        right: 0,
                        margin: 'auto'
                      }
                    : {
                        left: 0,
                        right: 0,
                        margin: 'auto'
                      }
                }
                ref={(d) => (this.dropZone = d)}
                onDrop={
                  !disabled ? (e) => this.onDrop(e, photoField) : () => false
                }
                accept="image/png, image/jpeg"
                aria-hidden="true"
              />
              {/* profilePhoto &&
                             <button
                             disabled={disabled}
                             type="button"
                             onClick={() => this.removePhoto(photoField)}
                             className={styles['piclink-remove']}
                             >
                             <FormattedMessage id="DELETE_PHOTO" />
                             </button>
                             */}
              <button
                id="change_add_photo_button"
                name="change_add_photo_button"
                disabled={disabled}
                className={styles['piclink-add']}
                type="button"
                onClick={
                  user && user.get('status') === 'suspended'
                    ? () => false
                    : () => this.dropZone.open()
                }
              >
                <FormattedMessage
                  id={profilePhoto ? 'CHANGE_PHOTO' : 'ADD_PHOTO'}
                />
              </button>
            </div>

            <div style={{ maxWidth: 300 }}>
              {formErrors[photoField] &&
                formErrors[photoField].errors.map((error) => (
                  <div className={styles.form__error}>
                    {error.indexOf('ERRORS') > -1 ? (
                      <FormattedMessage
                        id={error}
                        values={{
                          allowed_types: allowedTypes
                            .map((t) => t.get('name'))
                            .join(', ')
                        }}
                      />
                    ) : (
                      error
                    )}
                  </div>
                ))}
              {formatError && (
                <div className={styles.form__error}>
                  <FormattedMessage
                    id="ERRORS.FILE_TYPE_NOT_ALLOWED"
                    values={{
                      allowed_types: allowedTypes
                        .map((t) => t.get('name'))
                        .join(', ')
                    }}
                  />
                </div>
              )}
              {sizeError[photoField] && (
                <div className={styles.form__error}>
                  <FormattedMessage
                    id="ERRORS.large_photo"
                    values={{ max: '10' }}
                  />
                </div>
              )}
            </div>
          </InputGroup>

          {bio && (
            <InputGroup>
              <label htmlFor="bio">
                <FormattedMessage id="FORM_FIELDS.bio" />{' '}
                <span style={{ fontWeight: '400' }}>
                  (
                  <FormattedMessage id="BIO_HINT" />)
                </span>
              </label>
              <Field
                name="bio"
                component={renderInput}
                type="textarea"
                disabled={disabled}
              />
            </InputGroup>
          )}
        </Col>
      </Row>
    );
  }
}
