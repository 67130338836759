// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import { Alert } from 'reactstrap';
import { Loader } from 'components';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Home
 * @constructor
 */
const Home = (props) => (
  <div>
    <Helmet title="Pinsight - Home" />

    {props.user.get('active_role') === 'participant' &&
      !props.user.has('active_simulation') && (
        <Alert color="info">
          <FormattedMessage id="WITHOUT_ASSESSMENT_INFO" />
        </Alert>
      )}
    <div className={styles['home-preview-head']}>
      {props.isLoading && <Loader />}
    </div>
  </div>
);

// Prop Types
Home.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object.isRequired
};

// Connect
export default connect((state) => ({
  isLoading:
    state.assignments.get('assignmentsLoading') ||
    state.assessments.get('assessmentsLoading') ||
    state.analytics.get('analyticsLoading') ||
    state.users.get('usersLoading') ||
    state.clients.get('clientsLoading') ||
    state.partners.get('partnersLoading') ||
    state.simulations.get('simulationsLoading') ||
    state.behaviors.get('behaviorsLoading') ||
    state.app.get('permissionsLoading') ||
    state.auth.get('logoutLoading') ||
    state.debriefs.get('debriefsLoading'),
  user: state.auth.get('loggedUser')
}))(Home);
