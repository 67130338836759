const style = {
  backgroundColor: '#b0b0b0',
  color: 'white',
  borderColor: '#858484'
};

export const AND = {
  name: 'AND',
  style
};
export const OR = {
  name: 'OR',
  style
};
