// Vendor
import React from 'react';

// Forms
import { CreateUserForm, CreatePartnerForm, CreateClientForm } from 'forms';

// Actions
import { createUser, createPartner, createClient } from 'actions/users';

/**
 * Get create user data
 * @param role
 * @param onSubmit
 * @returns {*}
 */
export default function getCreateUserData(role, onSubmit) {
  switch (role) {
    case 'participant': {
      return {
        component: (
          <CreateUserForm
            onSubmit={onSubmit}
            message="CREATE_NEW_PARTICIPANT"
          />
        ),
        heading: 'CREATE_NEW_PARTICIPANT',
        success_message: 'PARTICIPANT_CREATED_AND_INVITED',
        action: createUser,
        tab: '7'
      };
    }
    case 'stakeholder': {
      return {
        component: (
          <CreateUserForm
            onSubmit={onSubmit}
            message="CREATE_NEW_STAKEHOLDER"
          />
        ),
        heading: 'CREATE_NEW_STAKEHOLDER',
        success_message: 'USER_SUCCESSFULLY_CREATED',
        action: createUser,
        tab: '5'
      };
    }
    case 'assessor': {
      return {
        component: (
          <CreateUserForm onSubmit={onSubmit} message="CREATE_NEW_ASSESSOR" />
        ),
        heading: 'CREATE_NEW_ASSESSOR',
        success_message: 'ASSESSOR_SUCCESSFULLY_CREATED',
        action: createUser,
        tab: '4'
      };
    }
    case 'admin': {
      return {
        component: (
          <CreateUserForm onSubmit={onSubmit} message="CREATE_NEW_ADMIN" />
        ),
        heading: 'CREATE_NEW_ADMIN',
        success_message: 'USER_SUCCESSFULLY_CREATED',
        action: createUser,
        tab: '6'
      };
    }
    case 'partner': {
      return {
        component: (
          <CreatePartnerForm onSubmit={onSubmit} message="CREATE_NEW_PARTNER" />
        ),
        heading: 'CREATE_NEW_PARTNER',
        success_message: 'PARTNER_SUCCESSFULLY_CREATED',
        action: createPartner,
        tab: '2'
      };
    }
    case 'client': {
      return {
        component: (
          <CreateClientForm onSubmit={onSubmit} message="CREATE_NEW_CLIENT" />
        ),
        heading: 'CREATE_NEW_CLIENT',
        success_message: 'CLIENT_SUCCESSFULLY_CREATED',
        action: createClient,
        tab: '3'
      };
    }
    default:
      return {};
  }
}
