// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Label } from 'reactstrap';
import { ExpandRow, ToolTip } from 'components';
import ScoringInput from './ScoringInput';

// Styles
import styles from './styles.css';
import './styles.css';

// Actions
import { scoreAssignment, fetchScoredAssignments } from 'actions/assignments';

/**
 * Behaviors table
 * @constructor
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    assignment: state.assignments.getIn([
      'assignments',
      Number(state.router.route.vars.id)
    ]),
    scoredAssignment: state.assignments.get('scoredAssignment').toArray() || []
  }),
  (dispatch) =>
    bindActionCreators({ scoreAssignment, fetchScoredAssignments }, dispatch)
)
export default class BehaviorsTable extends Component {
  // Prop types
  static propTypes = {
    assignment: PropTypes.object.isRequired,
    scoreAssignment: PropTypes.func.isRequired,
    scoredAssignment: PropTypes.object.isRequired,
    fetchScoredAssignments: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      assignment: this.props.assignment
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this.fetchData();
    this.backgroundRefresh = setInterval(() => {
      this.fetchData(true);
    }, 15000);
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    clearInterval(this.backgroundRefresh);
  }

  /**
   * Fetch data #TODO get id from response (need backend fix)
   */
  fetchData = (bg) => {
    const { fetchScoredAssignments, assignment } = this.props;
    fetchScoredAssignments(
      assignment.getIn(['user', 'id']),
      assignment.get('id'),
      bg
    );
  };

  /**
   * Render row
   * @param e
   * @param b
   * @param i
   * @param finished
   * @param disabled
   * @returns {XML}
   */
  renderRow = (e, b, i, finished, disabled) => (
    <div className={styles['points-row']}>
      <Label>
        <ScoringInput
          action={this.props.scoreAssignment}
          data={{ e, b, i }}
          onSuccess={this.fetchData}
          type="number"
          min="0"
          max="3"
          finished={finished}
          disabled={disabled}
        />
        <span>
          {i.get('name')}
          {i.get('bars').length > 0 ? (
            <ToolTip
              index={`indicator-${e.get('id')}${b.get('id')}${i.get('id')}`}
              hint={
                <span dangerouslySetInnerHTML={{ __html: i.get('bars') }} />
              }
              tooltipPopupClass="wide-tooltip"
              tooltipPlacement="left"
            />
          ) : null}
        </span>
      </Label>
    </div>
  );

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { user, scoredAssignment } = this.props;
    const assignment = this.state.assignment;
    const exercises = assignment.has('excercises')
      ? assignment.get('excercises').toArray()
      : [];
    const disabled =
      assignment.getIn(['assessor', 'id']) !== user.get('id') ||
      user.get('active_role') !== 'assessor';

    return (
      <div className={styles['behaviors-table']}>
        {exercises.map((e) => {
          let indicatorsCount = 0;
          e.get('behaviors').map((b) =>
            b.get('indicators').map(() => indicatorsCount++)
          );
          const eSize = scoredAssignment.filter(
            (a) => a.get('excercise_id') === e.get('id')
          );
          const finished = eSize.length === indicatorsCount;

          return (
            <div id={`block_${e.get('id')}`}>
              <ExpandRow
                className={classNames(styles.row, finished && styles.success)}
                header={
                  <h5>
                    {e.get('name')}
                    <ToolTip
                      index={`exercise-${e.get('id')}`}
                      hint={e.get('description')}
                    />
                  </h5>
                }
                body={e
                  .get('behaviors')
                  .toArray()
                  .map((b) => (
                    <div
                      className={styles.block}
                      id={`behavior_row_${b.get('id')}`}
                    >
                      <h6>
                        {b.get('name')}
                        <ToolTip
                          index={`behavior-${e.get('id')}${b.get('id')}`}
                          hint={b.get('definition')}
                        />
                      </h6>
                      {b.has('indicators') &&
                        b
                          .get('indicators')
                          .toArray()
                          .map((i) =>
                            this.renderRow(e, b, i, finished, disabled)
                          )}
                    </div>
                  ))}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
