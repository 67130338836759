import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { ExpandRow } from 'components';
import { Button, Input, Alert } from 'reactstrap';

import styles from './styles.css';
import './styles.css';

import {
  downloadExcel,
  fetchAnalytics,
  fetchDetailAnalyticsData
} from 'actions/analytics';
import { doScrolling } from 'helpers';

import { FloatingButton, Overview, SearchResult } from './components';
import { ToolTip } from '../../../../components';
import {
  getSelected,
  handleBufferDownload,
  updateCheckboxes,
  updatePreviewData
} from './components/actions';

const exportRequiredPermissions = [
  'report_export_self',
  'report_export_own',
  'report_export_child',
  'report_export_other'
];

class Folders extends React.Component {
  state = {
    sort: 'down'
  };

  componentDidMount() {
    if (this.props?.route?.query?.selected) {
      this.updateData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { analyticsElement, fromHTLoading } = this.props;

    if (fromHTLoading === true && nextProps.fromHTLoading === false) {
      doScrolling(analyticsElement.offsetTop - 105, 300);
    }
  }

  updateData = async (props) => {
    await updatePreviewData(props, props.route?.query?.selected?.split(','));
    updateCheckboxes(this.props);
  };

  render() {
    const { intl } = this.context;
    const {
      analyticsData,
      downloadExcel,
      isLoading,
      route,
      permissions
    } = this.props;
    const { sort } = this.state;

    const selected = getSelected(route);
    const showSearch = route.query.filtered === 1;

    const filteredIDs = [];
    analyticsData.map((r) => filteredIDs.push(r.get('id')));

    const showExportButton =
      permissions &&
      permissions.find((p) => exportRequiredPermissions.indexOf(p) > -1);

    let disabledDownloadXlsxBtn =
      (filteredIDs.length === 0 && selected?.length === 0) || isLoading;

    if (filteredIDs.length > 100) {
      disabledDownloadXlsxBtn = true;
    }

    if (selected.length > 0 && selected.length <= 100) {
      disabledDownloadXlsxBtn = false;
    }

    return (
      <div
        className={styles.folders}
        ref={(node) => (this.foldersSection = node)}
      >
        <ExpandRow
          header={
            <div>
              <h5 className={styles['folders-heading']}>
                <FormattedMessage
                  id={showSearch ? 'SEARCH_RESULT' : 'ALL_PARTICIPANTS'}
                />
                {showSearch && !isLoading && (
                  <span> ({analyticsData.size})</span>
                )}
              </h5>
              {showSearch && showExportButton != null && (
                <Button
                  disabled={disabledDownloadXlsxBtn}
                  className="pull-right"
                  style={{ marginTop: -45, marginRight: 50 }}
                  translate
                  onClick={() =>
                    downloadExcel(
                      selected?.length === 0 ? filteredIDs : selected
                    ).then((action) => {
                      if (!action.error) {
                        handleBufferDownload(
                          action,
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        );
                      } else {
                        throw action.error;
                      }
                    })
                  }
                >
                  <FormattedMessage
                    id="DOWNLOAD_DATA_AS_EXCEL"
                    values={{
                      count: isLoading
                        ? '...'
                        : selected.length > 0
                        ? selected.length
                        : analyticsData.size
                    }}
                  />
                  {disabledDownloadXlsxBtn && (
                    <ToolTip
                      id="ANALYTICS_XLSX_JIT"
                      index="ANALYTICS_XLSX_JIT"
                      hint="To activate this button, please narrow down your search results to max. 100 reports."
                    />
                  )}
                </Button>
              )}
            </div>
          }
          body={
            <div className={styles['folders-content']}>
              <div className={styles['action-block']}>
                <div
                  className={classNames(
                    showSearch && styles.hidden,
                    styles.sorter
                  )}
                >
                  <FormattedMessage id="SORT_BY_NAME" />
                  <Input
                    id="sort-select"
                    name="sort-select"
                    type="select"
                    onChange={(e) => this.setState({ sort: e.target.value })}
                  >
                    <option value="down">
                      {intl.formatMessage({ id: 'NAME_A_Z' })}
                    </option>
                    <option value="up">
                      {intl.formatMessage({ id: 'NAME_Z_A' })}
                    </option>
                  </Input>
                </div>
              </div>
              {!showSearch ? (
                <div className={styles['folders-alert']}>
                  <Alert color="info">
                    <FormattedMessage id="ANALYTICS_EXPAND_NOTE" />{' '}
                    <FormattedMessage id="PLEASE_SELECT_ANALYTICS_DATA" />
                  </Alert>
                </div>
              ) : (
                <div className={styles['folders-alert']}>
                  <Alert color="info">
                    <FormattedMessage id="PLEASE_SELECT_ANALYTICS_DATA" />
                  </Alert>
                </div>
              )}
              {showSearch ? <SearchResult /> : <Overview sort={sort} />}
            </div>
          }
          noBorder
          rowWithTopBorder
          defaultOpen
        />

        <FloatingButton
          getAnalyticsElement={() => this.props.analyticsElement}
          getFoldersElement={() => this.foldersSection}
        />
      </div>
    );
  }
}

Folders.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    allData: state.analytics.get('allData'),
    analyticsData: state.analytics.get('analyticsData'),
    analyticsDetailLoading: state.analytics.get('analyticsDetailLoading'),
    isLoading: state.analytics.get('analyticsLoading'),
    permissions: state.app.get('permissions'),
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators(
      {
        downloadExcel,
        fetchAnalytics,
        fetchDetailAnalyticsData
      },
      dispatch
    )
)(Folders);
