// Components
import { Assignments } from 'views';

// Actions
import { fetchAssignment, fetchScoredAssignments } from 'actions/assignments';

const AssignmentsRoutes = [
  {
    path: '/',
    component: Assignments.Preview
  },
  {
    path: '/score/:id',
    component: Assignments.Score,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const { id } = nextRoute.vars;
      const state = getState();

      if (state.assignments.hasIn(['assignments', Number(id), 'excercises'])) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        dispatch(fetchAssignment(Number(id))).then((action) => {
          dispatch(
            fetchScoredAssignments(
              action.result.body.user.id,
              action.result.body.id
            )
          ).then(() => resolve());
        });
      });
    }
  }
];

export default AssignmentsRoutes;
