// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Get after simulation progress
 * @param user
 */
export default function getAfterSimulationProgress(user) {
  const hasPrework1 = user.getIn(['stages', 'pre-work1', 'completed']) === true;
  const hasPrework2 = user.getIn(['stages', 'pre-work2', 'completed']) === true;

  return [
    {
      title: <FormattedMessage id="LIVE_SESSION" />,
      bottom: null,
      active: false,
      finished: true
    },
    {
      title: <FormattedMessage id="QUESTIONNAIRE_HEADING" />,
      bottom: null,
      active: !hasPrework1 && !hasPrework2,
      finished: hasPrework1
    },
    {
      title: <FormattedMessage id="LEARNING_EFFICIENCY" />,
      bottom: false,
      active: hasPrework1,
      finished: hasPrework2
    }
  ];
}
