// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

// Components
import { Col, InputGroup, Row } from 'reactstrap';

// Actions
import { renderInput } from '../actions';

// Const
const statusField = 'status';

const Status = (props) => (
  <Row>
    <Col xs={12}>
      <h5>
        <FormattedMessage id="STATUS" />
      </h5>
    </Col>
    <Col xs={5}>
      <InputGroup>
        <Field
          name={props.subOwner ? `owner.${statusField}` : statusField}
          component={renderInput}
          noEmpty
          type="select"
          valueKey="name"
          optionKey="name"
          options={props.statuses}
          translationKey="STATUS_VALUES"
          disabled={props.disabled}
        />
      </InputGroup>
    </Col>
  </Row>
);

Status.propTypes = {
  statuses: PropTypes.object,
  subOwner: PropTypes.bool,
  disabled: PropTypes.bool
};

export default connect((state) => ({
  statuses: state.app.getIn(['codeList', 'status']).toArray()
}))(Status);
