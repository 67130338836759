import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import classNames from 'classnames';
import modals from '../../../../../../modals.css';

const ConfirmModal = (props) => {
  const { onSubmit, onCancel, intl } = props;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader style={{ textAlign: 'center' }}>
        <FormattedMessage id="LAT_CONFIRM_HEADING" />
      </ModalHeader>
      <ModalBody
        className={classNames(modals['modal-body'], modals['text-center'])}
      >
        <div>
          <FormattedHTMLMessage id="LAT_CONFIRM_BODY" />
        </div>
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={6} className={modals['modal-button-col']}>
            <Button
              title={intl.formatMessage({ id: 'LOGOUT_AND_COME_BACK_LATER' })}
              id="submit_modal"
              name="submit_modal"
              color="secondary"
              onClick={onCancel}
              className={modals['footer-button']}
            >
              <FormattedMessage id="LOGOUT_AND_COME_BACK_LATER" />
            </Button>
          </Col>
          <Col xs={6} className={modals['modal-button-col']}>
            <Button
              title={intl.formatMessage({ id: 'START_THE_TEST_NOW' })}
              id="submit_modal"
              name="submit_modal"
              color="primary-dark"
              onClick={onSubmit}
              className={modals['footer-button']}
            >
              <FormattedMessage id="START_THE_TEST_NOW" />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

export default ConfirmModal;
