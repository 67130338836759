// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

// Components
import { Row, Col } from 'reactstrap';
import { PreviewTable, BehaviorsTable } from './components';

// Styles
import styles from '../../styles.css';

/**
 * Score
 * @param props
 * @constructor
 */
const Score = (props) => {
  const { assignment, route } = props;
  const participant = assignment.get('user');
  const name = `${participant.get('firstname')} ${participant.get('surname')}`;
  const simulation = assignment.get('simulation');
  const isDebrief =
    simulation.has('is_debrief') && simulation.get('is_debrief');

  let profilePhoto = '/assets/img/user.png';
  if (participant.get('profile_photo')) {
    profilePhoto = `${process.env.REACT_APP_ASSETSURL}${participant.get(
      'profile_photo'
    )}`;
  }

  let link = (
    <Link
      id="back_link"
      to="/assignments"
      query={{ tab: route.query.fromTab || '1' }}
    >
      &larr; <FormattedMessage id="BACK_TO_ASSIGNMENTS" />
    </Link>
  );

  if (route.query.backLink) {
    link = (
      <Link id="back_link" to={route.query.backLink}>
        &larr; <FormattedMessage id="BACK" />
      </Link>
    );
  }

  return (
    <div className={styles.scoring}>
      <div className={styles['link-back']}>{link}</div>
      <h1>
        <FormattedMessage id="SCORE_SIMULATION" />
      </h1>

      <Row className={styles.flex}>
        <Col xs={6} className={styles.flex}>
          <div className={styles['scoring__user-info-preview']}>
            <div className={styles.top}>
              <div
                className={styles['profile-photo']}
                style={{
                  backgroundImage: `url(${profilePhoto})`
                }}
              />
              <div className={styles.info}>
                <strong>{name}</strong>
                <br />-
              </div>
            </div>
            <div className={styles.bottom}>
              <FormattedMessage id="ASSESSMENT" />:{' '}
              <strong>
                {simulation.get('name')} [{assignment.get('language')}]
              </strong>
              <br />
              <FormattedMessage id="SCORE_BY" />:{' '}
              {assignment.has('assigments_time')
                ? moment(assignment.get('assigments_time'))
                    .utcOffset(assignment.get('assigments_time'))
                    .format('DD MMM YYYY - hh:mm A')
                : '-'}
            </div>
          </div>
        </Col>
        {!isDebrief && (
          <Col xs={6} className={styles.flex}>
            <div className={styles['scoring__present-block']}>
              <h4>
                <FormattedMessage id="IS_THE_BEHAVIOR_PRESENT" />
              </h4>
              3 = <FormattedMessage id="BEHAVIOR_PRESENT.3" /> <br />2 ={' '}
              <FormattedMessage id="BEHAVIOR_PRESENT.2" /> <br />1 ={' '}
              <FormattedMessage id="BEHAVIOR_PRESENT.1" /> <br />0 ={' '}
              <FormattedMessage id="BEHAVIOR_PRESENT.0" />
            </div>
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={6}>
          <PreviewTable />
        </Col>
        {!isDebrief && (
          <Col xs={6}>
            <BehaviorsTable />
          </Col>
        )}
      </Row>
    </div>
  );
};

// Prop types
Score.propTypes = {
  assignment: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

export default connect((state) => ({
  assignment: state.assignments.getIn([
    'assignments',
    Number(state.router.route.vars.id)
  ]),
  route: state.router.route
}))(Score);
