// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { submit } from 'redux-form';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { AccessModalForm } from '../../../../../../forms';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';

/**
 * Access
 * @class
 */
@connect(
  (state) => ({
    users: state.users.get('allUsers')
  }),
  (dispatch) => bindActionCreators({ submit }, dispatch)
)
export default class Access extends Component {
  // Prop types
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    users: PropTypes.object,
    submit: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = () => {
    const { onClose } = this.props;
    const { addAlert } = this.context;

    addAlert({
      color: 'success',
      content: <FormattedMessage id="SAVED_SUCCESSFULLY" />
    });
    onClose();
  };

  /**
   * Submit form
   */
  submitForm = () => {
    const { submit } = this.props;
    submit('accessModalForm');
  };

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { onClose, users, userId } = this.props;
    const user = users.get(userId);
    const userImg = user.get('profile_photo')
      ? `${process.env.REACT_APP_ASSETSURL}${user.get('profile_photo')}`
      : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id="ACCESS" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div className={modals['user-info']}>
            <div
              className={modals['user-avatar']}
              style={{ backgroundImage: `url(${userImg})` }}
            />
            <div className={modals['user-name']}>
              <h6>{`${user.get('firstname')} ${user.get('surname')}`}</h6>
              <span className={modals['user-roles']}>{user.get('role')}</span>
            </div>
          </div>

          <div className={modals['modal-form']}>
            <AccessModalForm onSubmit={this.handleSubmit} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_access"
                name="cancel_access"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_access"
                name="submit_access"
                color="primary"
                onClick={this.submitForm}
                className={modals['footer-button']}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
