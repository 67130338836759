// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { change } from 'redux-form';

// Components
import { Button, Col, Row } from 'reactstrap';

// Styles
import styles from '../styles.css';

// Const
const headerLogoField = 'header_logo';

/**
 * Account owner
 * @class
 */
@connect(
  (state) => ({
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-logo'])
      ? state.app.getIn(['codeList', 'allowed-ext-logo']).toArray()
      : [],
    formErrors: state.app.get('formErrors') || {}
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Branding extends Component {
  // Prop types
  static propTypes = {
    form: PropTypes.string.isRequired,
    allowedTypes: PropTypes.array.isRequired,
    formErrors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      sizeError: {},
      formatError: false
    };
  }

  /**
   * On Drop
   * @param files
   * @param field
   */
  onDrop(files, field) {
    const { allowedTypes, change, form } = this.props;

    this.setState({
      sizeError: { [field]: false },
      formatError: false
    });

    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      this.setState({ formatError: true });
    } else if (files[0].size > 2000000) {
      this.setState({
        sizeError: { [field]: true }
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        change(form, field, reader.result);
        this.setState({ [field]: files[0] });
        return true;
      };
    }
  }

  /**
   * Remove photo
   * @param field
   */
  removePhoto = (field) => {
    const { change, form } = this.props;
    change(form, field, ' ');
    this.setState({ [field]: 'removed' });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { allowedTypes, formErrors, user } = this.props;
    const { formatError, sizeError } = this.state;
    let headerLogoBackground = null;

    if (this.state[headerLogoField]) {
      headerLogoBackground = this.state[headerLogoField].preview;
    } else if (user && this.state[headerLogoField] !== 'removed') {
      if (user.has('header_logo')) {
        headerLogoBackground = `${process.env.REACT_APP_ASSETSURL}${user.get(
          'header_logo'
        )}`;
      }
    }

    return (
      <Row>
        <Col xs={12}>
          <h5>
            <FormattedMessage id="BRANDING" />{' '}
            <span style={{ fontWeight: 400 }}>
              (
              <FormattedMessage id="HEADER_LOGO" />)
            </span>
          </h5>
        </Col>
        <Col xs={4}>
          <div className={styles['logo-div']}>
            <DropZone
              id="logo_branding_dropzone"
              className={classNames(
                styles['drop-zone-logo'],
                styles['header-logo']
              )}
              style={
                headerLogoBackground && {
                  backgroundImage: `url(${headerLogoBackground})`
                }
              }
              ref={(d) => (this.dropZone = d)}
              onDrop={(e) => this.onDrop(e, headerLogoField)}
              accept="image/png, image/jpeg"
            />
            {headerLogoBackground && (
              <Button
                id="delete_logo_button"
                name="delete_logo_button"
                size="sm"
                type="button"
                color="link"
                onClick={() => this.removePhoto(headerLogoField)}
                className={styles['piclink-remove']}
              >
                <FormattedMessage id="DELETE_LOGO" />
              </Button>
            )}
            <span
              id="change_logo_button"
              className={styles['piclink-add']}
              onClick={() => this.dropZone.open()}
            >
              <FormattedMessage
                id={
                  headerLogoBackground ? 'CHANGE_LOGO' : 'CHANGE_LOGO_IN_HEADER'
                }
              />
            </span>
          </div>
          {sizeError[headerLogoField] && (
            <div className={styles.form__error}>
              <FormattedMessage id="ERRORS.large_photo" values={{ max: '2' }} />
            </div>
          )}

          {formatError && (
            <div className={styles.form__error}>
              <FormattedMessage
                id="ERRORS.FILE_TYPE_NOT_ALLOWED"
                values={{
                  allowed_types: allowedTypes
                    .map((t) => t.get('name'))
                    .join(', ')
                }}
              />
            </div>
          )}

          {formErrors[headerLogoField] &&
            formErrors[headerLogoField].errors.map((error) => (
              <div className={styles.form__error}>
                {error.indexOf('ERRORS') > -1 ? (
                  <FormattedMessage
                    id={error}
                    values={{
                      allowed_types: allowedTypes
                        .map((t) => t.get('name'))
                        .join(', ')
                    }}
                  />
                ) : (
                  error
                )}
              </div>
            ))}
        </Col>
      </Row>
    );
  }
}
