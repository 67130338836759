// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';

// Styles
import styles from './styles.css';
import './styles.css';

// Const
const CARD = 'card';
const style = {
  marginBottom: 15,
  cursor: 'move',
  height: 65,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold'
};

const boxStyle = {
  HIGH: {
    color: '#000',
    backgroundColor: '#2FBC88'
  },
  MEDIUM: {
    color: '#000',
    backgroundColor: '#F9CB36'
  },
  LOW: {
    color: '#FFF',
    backgroundColor: '#001C50'
  }
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  }
};

/**
 * Card Target
 * @type {{hover(*, *, *=): (undefined)}}
 */
const cardTarget = {
  /**
   * Hover
   * @param props
   * @param monitor
   * @param component
   */
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex; // eslint-disable-line no-param-reassign
  }
};

/**
 * Card
 * @class
 */
@DropTarget(CARD, cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget()
}))
@DragSource(CARD, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
export default class Card extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    moveCard: PropTypes.func.isRequired,
    progress: PropTypes.string.isRequired
  };

  /**
   * Render
   */
  render() {
    const {
      text,
      isDragging,
      connectDragSource,
      connectDropTarget,
      progress
    } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      connectDropTarget(
        <div
          className={styles.box}
          style={{ ...style, opacity, ...boxStyle[progress] }}
        >
          {text}
        </div>
      )
    );
  }
}
