// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Subviews
import { Preview, Create, Edit, Invite } from './subviews';

/**
 * Users
 * @param props
 * @constructor
 */
const Users = (props) => (
  <div>
    <Helmet title="Pinsight - Users" />
    {props.children}
  </div>
);

// Prop Types
Users.propTypes = {
  children: PropTypes.any
};

Users.Preview = Preview;
Users.Create = Create;
Users.Edit = Edit;
Users.Invite = Invite;

export default Users;
