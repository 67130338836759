import {
  LIST_IMPROVEMENT_REQUEST,
  LIST_IMPROVEMENT_SUCCESS,
  LIST_IMPROVEMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List improvement action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function listImprovement() {
  return {
    types: [
      LIST_IMPROVEMENT_REQUEST,
      LIST_IMPROVEMENT_SUCCESS,
      LIST_IMPROVEMENT_FAILURE
    ],
    promise: api().get('/api/v1/development/improvement/')
  };
}
