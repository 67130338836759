import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch partners action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchPartners(
  offset,
  limit,
  filter = {},
  role,
  sortBy
) {
  const query =
    typeof filter === 'string'
      ? encodeURIComponent(filter)
      : filter.search && filter.search !== ' '
      ? encodeURIComponent(filter.search)
      : '';

  const string = `?q=${query}&offset=${offset || 0}&limit=${
    limit || 'all'
  }&fields=id,name,email,role,status,languages,modules,simulations,owner&sort=${encodeURIComponent(
    sortBy || '+name'
  )}`; // eslint-disable-line
  return {
    types: [
      FETCH_PARTNERS_REQUEST,
      FETCH_PARTNERS_SUCCESS,
      FETCH_PARTNERS_FAILURE
    ],
    promise: api().get(`/api/v1/partners/${string}`)
  };
}
