import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tag
} from 'reactstrap';
import { Dropdown, Link } from 'components';

import styles from '../../../../components/BuildTable/styles.css';
import '../../../../components/BuildTable/styles.css';
import stylesSims from '../../../Simulations/components/styles.css';

import { Main, Translate } from './versions';
import { editAssessment } from 'actions/assessments';

@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    route: state.router.route,
    assessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    languages: state.app.hasIn(['codeList', 'language_published'])
      ? state.app.getIn(['codeList', 'language_published']).toArray()
      : []
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ editAssessment, transitionTo }, dispatch);
  }
)
export default class EditAssessmentTable extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    route: PropTypes.object.isRequired,
    assessment: PropTypes.object.isRequired,
    languages: PropTypes.array.isRequired,
    editAssessment: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.tab || '1'
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.assessment) {
      this.props.transitionTo('/customize');
      setTimeout(() => {
        this.context.addAlert({
          color: 'danger',
          content: <FormattedMessage id="ASSESSMENT_NOT_FOUND" />
        });
      }, 1000);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.tab !== nextProps.route.query.tab) {
      this.toggle(nextProps.route.query.tab);
    }
  }

  getTabContent = (tab, version) => {
    if (version === 'english') {
      return <Main />;
    }

    return <Translate version={version} />;
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    if (!this.props.assessment) {
      return <div />;
    }

    const { id, assessment, editAssessment, transitionTo } = this.props;
    const { addAlert, intl } = this.context;
    const { activeTab } = this.state;
    const languageVersions = [];
    const sorted = assessment
      .get('languages')
      .sortBy((l) => (l.get('language') === 'english' ? -1 : 1));
    const englishPublished = assessment
      .get('languages')
      .find((l) => l.get('language') === 'english')
      .get('published');

    assessment.getIn(['simulation', 'languages']).map((lang) => {
      if (
        assessment
          .get('languages')
          .find((item) => item.get('language') === lang.get('language')) ||
        lang.get('language') === 'english' ||
        !lang.get('published')
      ) {
        return false;
      }

      return languageVersions.push({
        title: `LANGUAGE_VALUES.${lang.get('language')}`,
        action: () => {
          editAssessment(id, { language: lang.get('language') }).then(
            (action) => {
              if (!action.error) {
                transitionTo(`/customize/assessment/edit/${id}`, {
                  tab: (sorted.size + 1).toString(),
                  subTab: '1'
                });
              } else if (action.error.body) {
                if (typeof action.error.body === 'string') {
                  addAlert({
                    color: 'danger',
                    content: intl.formatMessage({ id: action.error.body })
                  });
                } else {
                  Object.keys(action.error.body).map((key) =>
                    addAlert({
                      color: 'danger',
                      content: action.error.body[key].errors
                        .map((error) => intl.formatMessage({ id: error }))
                        .join(', ')
                        .trim()
                    })
                  );
                }
              }
            }
          );
        }
      });
    });

    return (
      <div className={styles['edit-simulation-layout']}>
        <Nav tabs>
          {sorted.map((language, id) => {
            const tabId = (id + 1).toString();

            return (
              <NavItem key={tabId} style={{ ':hover': { cursor: 'pointer' } }}>
                <Link
                  id={`tab-${language.get('language')}`}
                  to={`/customize/assessment/edit/${assessment.get('id')}`}
                  query={{ tab: tabId.toString() }}
                  type="inherit"
                >
                  <NavLink
                    className={classNames(
                      { active: activeTab === tabId },
                      styles['nav-link']
                    )}
                  >
                    <FormattedMessage
                      id={`LANGUAGE_VALUES.${language.get('language')}`}
                    />
                    <Tag
                      className={[
                        styles['indicator-tag'],
                        language.get('published')
                          ? styles['indicator-on']
                          : styles['indicator-off']
                      ]}
                    />
                  </NavLink>
                </Link>
              </NavItem>
            );
          })}

          <Dropdown
            customClassName={stylesSims['add-language']}
            title="ADD_LANGUAGE_VERSION"
            translate
            items={languageVersions}
            disabled={languageVersions.length === 0 || !englishPublished}
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          {sorted.map((language, id) => {
            const tabId = (id + 1).toString();

            return (
              <TabPane tabId={tabId} key={tabId}>
                <Row>
                  <Col sm="12">
                    {tabId === activeTab &&
                      this.getTabContent(activeTab, language.get('language'))}
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    );
  }
}
