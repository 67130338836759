import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { Button } from 'reactstrap';
import { BuildTable, Loader } from 'components';
import tableStyles from '../../../../../../components/BuildTable/styles.css';
import styles from '../../styles.css';
import {
  getSelected,
  onDownload,
  onNarrativeUpdate,
  onSelect,
  onShare
} from '../actions';
import { defaultValue } from '../../../../../../constants/fieldsData';
import { orderMapByArrayValue } from 'helpers';

import {
  getNarrative,
  fetchAnalytics,
  fetchDetailAnalyticsData,
  shareReport,
  downloadPdf,
  downloadExcel,
  removeReport
} from 'actions/analytics';

const SearchResult = (props, context) => {
  const {
    allData,
    analyticsData,
    loggedUser,
    filterData,
    isLoading,
    permissions,
    route
  } = props;
  const selectedUsers = getSelected(route);
  const final = allData.filter((report) =>
    analyticsData.find((i) => i.get('id') === report.get('id'))
  );

  const filteredIds =
    final.size === selectedUsers.length
      ? []
      : final.map((r) => r.get('id')).toArray();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {final.size > 0 && (
        <BuildTable
          table={{
            data: final
              ? orderMapByArrayValue(final, ['user', 'surname']).toArray()
              : [],
            customClassName: 'analytics-filter-table',
            fields: [
              {
                key: final && final.size > 1 ? 'participants' : 'participant',
                component: (
                  <input
                    id="select-all-0"
                    name="select-all-filtered-button"
                    className={tableStyles['select-all']}
                    type="checkbox"
                    onClick={() => onSelect(filteredIds, 0, true, props)}
                  />
                ),
                sortable: true,
                ownData: true,
                renderField: (item, i) => {
                  const user = item.get('user');
                  let name = user.has('name')
                    ? user.get('name')
                    : `${user.get('firstname')} ${user.get('surname')}`;
                  let mail = user.get('email') || '';
                  const profilePhoto = user.get('profile_photo');
                  const query = filterData.search || '';

                  const index =
                    name != null
                      ? name.toLowerCase().indexOf(query.toLowerCase())
                      : -1;
                  const mailIndex =
                    mail != null
                      ? mail.toLowerCase().indexOf(query.toLowerCase())
                      : -1;
                  if (index >= 0) {
                    name =
                      // eslint-disable-next-line
                      name.substring(0, index) +
                      "<span style='color: #e89c30; font-size: inherit';>" +
                      name.substring(index, index + query.length) +
                      '</span>' +
                      name.substring(index + query.length, name.length);
                  }

                  if (mailIndex >= 0) {
                    mail =
                      // eslint-disable-next-line
                      mail.substring(0, mailIndex) +
                      "<span style='color: #e89c30; font-size: inherit';>" +
                      mail.substring(mailIndex, mailIndex + query.length) +
                      '</span>' +
                      mail.substring(mailIndex + query.length, mail.length);
                  }

                  const profilePhotoLink = profilePhoto
                    ? `${process.env.REACT_APP_ASSETSURL}${profilePhoto}`
                    : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line

                  return (
                    <td style={{ minWidth: '315px' }} key={`table-field-${i}`}>
                      <input
                        id={`filtered-user-${item.get('id')}`}
                        type="checkbox"
                        checked={selectedUsers.indexOf(item.get('id')) > -1}
                        onChange={() => onSelect(item, 0, false, props)}
                        className={styles['filter-checkbox']}
                      />
                      <div
                        style={{
                          backgroundImage: `url(${profilePhotoLink})`
                        }}
                        className={tableStyles['user-avatar']}
                      />
                      <div className={tableStyles['user-name-email']}>
                        <strong dangerouslySetInnerHTML={{ __html: name }} />
                        <br />
                        {mail ? (
                          <span dangerouslySetInnerHTML={{ __html: mail }} />
                        ) : (
                          'undefined'
                        )}
                      </div>
                      {permissions.find(
                        (p) => p.indexOf('user_profile_eid_view') > -1
                      ) &&
                        item.hasIn(['user', 'eid']) && (
                          <div className={tableStyles.tags}>
                            <span
                              className={classNames(
                                tableStyles['user-tag'],
                                tableStyles.red,
                                query === item.getIn(['user', 'eid']) &&
                                  tableStyles['with-filter']
                              )}
                            >
                              {item.getIn(['user', 'eid'])}
                            </span>
                          </div>
                        )}
                      <div className={tableStyles.tags}>
                        {item.hasIn(['user', 'tags']) &&
                          item
                            .getIn(['user', 'tags'])
                            .map((t) => (
                              <span
                                className={classNames(
                                  tableStyles['user-tag'],
                                  filterData.tags
                                    .map((t) => t.name)
                                    .indexOf(t) > -1 &&
                                    tableStyles['with-filter']
                                )}
                              >
                                {t}
                              </span>
                            ))}
                      </div>
                    </td>
                  );
                }
              },
              {
                key: 'assessment',
                sortable: true,
                renderField: (item) => {
                  const uuid = item.has('assessment_id')
                    ? `${item.get('simulation_id')}-${item.get(
                        'assessment_id'
                      )}`
                    : item.get('simulation_id').toString();

                  return (
                    <span
                      style={{
                        color:
                          filterData.assessments.indexOf(uuid) > -1
                            ? '#e89c30'
                            : 'inherit'
                      }}
                    >
                      {item.get('assessment_name')}
                    </span>
                  );
                }
              },
              {
                key: 'partner',
                sortable: true,
                width: 13,
                invisible:
                  loggedUser.has('partner') || loggedUser.has('client'),
                renderField: (item) => {
                  let result = '';
                  const query = filterData.search || '';
                  const id = item.hasIn(['user', 'partner', 'id'])
                    ? item.getIn(['user', 'partner', 'id'])
                    : null;

                  if (!item.hasIn(['user', 'partner', 'id'])) {
                    result = 'Pinsight';
                  } else {
                    result = item.getIn(['user', 'partner', 'name']);
                  }

                  const index =
                    result != null
                      ? result.toLowerCase().indexOf(query.toLowerCase())
                      : -1;

                  if (index >= 0) {
                    result =
                      // eslint-disable-next-line
                      result.substring(0, index) +
                      "<span style='color: #e89c30; font-size: inherit;'>" +
                      result.substring(index, index + query.length) +
                      '</span>' +
                      result.substring(index + query.length, result.length);
                  }

                  return (
                    <span
                      style={{
                        color:
                          filterData.partners?.indexOf(id?.toString()) > -1 ||
                          (filterData.partners?.indexOf(
                            defaultValue.toLowerCase()
                          ) > -1 &&
                            id == null)
                            ? '#e89c30'
                            : 'inherit'
                      }}
                      dangerouslySetInnerHTML={{ __html: result }}
                    />
                  );
                }
              },
              {
                key: 'client',
                sortable: true,
                width: 13,
                invisible: loggedUser.has('client'),
                renderField: (item) => {
                  let result = '';
                  const query = filterData.search || '';
                  const id = item.hasIn(['user', 'client', 'id'])
                    ? item.getIn(['user', 'client', 'id'])
                    : null;

                  if (!item.hasIn(['user', 'client', 'id'])) {
                    result = 'N/A';
                  } else {
                    result = item.getIn(['user', 'client', 'name']);
                  }

                  const index =
                    result != null
                      ? result.toLowerCase().indexOf(query.toLowerCase())
                      : -1;

                  if (index >= 0) {
                    result =
                      // eslint-disable-next-line
                      result.substring(0, index) +
                      "<span style='color: #e89c30; font-size: inherit;'>" +
                      result.substring(index, index + query.length) +
                      '</span>' +
                      result.substring(index + query.length, result.length);
                  }

                  return (
                    <span
                      style={{
                        color:
                          filterData.clients?.indexOf(id?.toString()) > -1 ||
                          (filterData.clients?.indexOf(
                            defaultValue.toLowerCase()
                          ) > -1 &&
                            id == null)
                            ? '#e89c30'
                            : 'inherit'
                      }}
                      dangerouslySetInnerHTML={{ __html: result }}
                    />
                  );
                }
              },
              {
                key: 'date',
                sortable: true,
                width: 10,
                renderField: (item) =>
                  moment(item.get('date')).format('DD MMM YYYY')
              },
              {
                key: 'actions',
                sortable: false,
                renderField: (item) => (
                  <div>
                    <Button
                      id={`share-button-${item.get('id')}`}
                      type="button"
                      color="link"
                      size="sm"
                      onClick={() => onShare(item, props, context)}
                      disabled={
                        loggedUser.get('active_role') !== 'admin' ||
                        (item && !item.getIn(['sharing', 'is_shareable']))
                      }
                    >
                      <FormattedMessage id="SHARE" />
                    </Button>
                    <br />
                    <Button
                      id={`download-button-${item.get('id')}`}
                      type="button"
                      color="link"
                      size="sm"
                      onClick={() =>
                        onDownload(item.get('files'), props, context)
                      }
                      disabled={item && !item.has('files')}
                    >
                      <FormattedMessage id="DOWNLOAD_REPORT" />
                    </Button>
                    <br />
                    {(loggedUser.get('active_role') === 'admin' ||
                      loggedUser.get('active_role') === 'assessor') && (
                      <Button
                        id={`narrative-button-${item.get('id')}`}
                        disabled={item && !item.get('is_narrative_editable')}
                        type="button"
                        color="link"
                        size="sm"
                        onClick={() => onNarrativeUpdate(item, props, context)}
                      >
                        <FormattedMessage
                          id={
                            item.get('has_narrative')
                              ? 'EDIT_NARRATIVE'
                              : 'ADD_NARRATIVE'
                          }
                        />
                      </Button>
                    )}
                  </div>
                )
              }
            ].filter((field) => !field.invisible)
          }}
        />
      )}
    </div>
  );
};

SearchResult.contextTypes = {
  addModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    allData: state.analytics.get('allData'),
    analyticsData: state.analytics.get('analyticsData'),
    analyticsDetailLoading: state.analytics.get('analyticsDetailLoading'),
    filterData: {
      assessments: formValueSelector('filter')(state, 'assessments') || [],
      search: formValueSelector('filter')(state, 'search'),
      tags: formValueSelector('filter')(state, 'tags') || [],
      partners: formValueSelector('filter')(state, 'partners') || [],
      clients: formValueSelector('filter')(state, 'clients') || []
    },
    isLoading: state.analytics.get('analyticsLoading'),
    loggedUser: state.auth.get('loggedUser'),
    overviewData: state.analytics.get('overviewData'),
    permissions: state.app.get('permissions'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        getNarrative,
        fetchAnalytics,
        fetchDetailAnalyticsData,
        shareReport,
        downloadPdf,
        downloadExcel,
        removeReport,
        transitionTo
      },
      dispatch
    );
  }
)(
  React.memo(SearchResult, (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps?.route?.query) ===
        JSON.stringify(nextProps?.route?.query) &&
      prevProps?.allData?.equals(nextProps?.allData) &&
      prevProps?.isLoading === nextProps?.isLoading &&
      prevProps?.analyticsDetailLoading?.equals(
        nextProps?.analyticsDetailLoading
      )
    );
  })
);
