import {
  shareReportAccessFormOption1,
  shareReportAccessFormOption2,
  shareReportAccessFormStakeholders
} from './index';

export const getSerializedFormValuesUsingSimulationIds = (user, formValues) => {
  const allSimulation = [user.get('active_simulation')].concat(
    user.get('past_simulations').toArray()
  );
  const values = {};
  const result = [];

  Object.keys(formValues).forEach((key) => {
    if (key === 'stakeholders') {
      return;
    }

    const valueKey = key.split('-')[0];
    const id = Number(key.split('-')[1]);

    if (values[id] == null) {
      values[id] = {};
    }

    values[id][valueKey] = formValues[key];
  });

  allSimulation.forEach((simulation) => {
    if (simulation === undefined) {
      return;
    }
    if (values[simulation.get('id')] == null) {
      return;
    }

    result.push({
      active_simulation: simulation.get('id'),
      [shareReportAccessFormOption1]:
        values[simulation.get('id')][shareReportAccessFormOption1] || false,
      [shareReportAccessFormOption2]:
        values[simulation.get('id')][shareReportAccessFormOption2] || false,
      [shareReportAccessFormStakeholders]:
        values[simulation.get('id')][shareReportAccessFormStakeholders]
          ?.filter((s) => s.id)
          ?.map((s) => s.id) || []
    });
  });

  return result;
};
