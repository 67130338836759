export const historyData = [
  {
    id: 1,
    date: '10. Aug 2016 09:30 AM',
    description: 'Invited to Manager Level, English'
  },
  {
    id: 2,
    date: '10. Aug 2016 08:00 PM',
    description:
      'Scheduled simulation for 15 Aug 2016  03:25 PM  (GMT +5:00) Eastern Time (US & Canada)'
  },
  {
    id: 3,
    date: '11. Aug 2016 10:00 PM',
    description: 'Invited to reschedule'
  },
  {
    id: 4,
    date: '13. Aug 2016 08:00 AM',
    description:
      'Rescheduled simulation for 18 Aug 2016 03:25 PM   (GMT +5:00) Eastern Time (US & Canada)'
  },
  {
    id: 5,
    date: '17 Aug 2016 02:00 PM',
    description: 'Profile completed'
  },
  {
    id: 6,
    date: '17 Aug 2016 02:30 PM',
    description: 'Personality questionnaire completed'
  },
  {
    id: 7,
    date: '18 Aug 2016 09:55 AM',
    description: 'Learning assessment completed'
  },
  {
    id: 8,
    date: '19 Aug 2016 10:00 AM',
    description: 'Pre-work accessed'
  },
  {
    id: 9,
    date: '19 Aug 2016 10:15 AM',
    description: 'Pre-work voicemail left'
  }
];
