/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

// Styles
import { InputGroup, Label } from 'reactstrap';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components

// Actions
import { renderInput } from '../../actions';
import { modules } from '../../../constants/fieldsData';

// Const
const form = 'accessModalForm';
const modulesField = 'modules';

/**
 * Access modal form
 * @class
 */
const AccessModalForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <InputGroup>
        <label htmlFor="assessment">
          <FormattedMessage id="MODULES" />
          (
          <FormattedMessage id="MODULES_HINT" />)
        </label>
        {modules.map((module, index) => (
          <InputGroup key={index}>
            <Label check key={index}>
              <Field
                name={`${modulesField}[${index}]`}
                component={renderInput}
                type="checkbox"
              />
              <FormattedMessage id={`MODULE_VALUES.${module.get('name')}`} />
            </Label>
          </InputGroup>
        ))}
      </InputGroup>
    </form>
  );
};

// Prop types
AccessModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

// Decorate with redux-form
export default reduxForm({
  form,
  validate
})(AccessModalForm);
