import {
  SAVE_LAT_ANSWER_REQUEST,
  SAVE_LAT_ANSWER_SUCCESS,
  SAVE_LAT_ANSWER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function saveLATAnswer(data) {
  return {
    types: [
      SAVE_LAT_ANSWER_REQUEST,
      SAVE_LAT_ANSWER_SUCCESS,
      SAVE_LAT_ANSWER_FAILURE
    ],
    promise: api().post(`/api/v1/users/lat/`, data),
    ...data
  };
}
