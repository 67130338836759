import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'components';
import { isMobile } from 'helpers';
import {
  AUTH_REFRESH_TOKEN,
  AUTH_TOKEN,
  MFA_REMEMBER
} from '../../../constants/cookies';
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Label
} from 'reactstrap';

import { validate } from './validate';
import api from 'api';

import styles from '../../styles.css';
import '../../styles.css';
import stylesLogin from './styles.css';
import './styles.css';

import { renderInput } from '../../actions';
import { getCookie, normalizeError, setCookie } from '../../../helpers';

const form = 'loginForm';

const LOGIN_STEP_EMAIL = 'LOGIN_STEP_EMAIL';
const LOGIN_STEP_PASSWORD = 'LOGIN_STEP_PASSWORD';
const LOGIN_STEP_SSO = 'LOGIN_STEP_SSO';

let Login = (props) => {
  const [loginStep, setLoginStep] = useState(LOGIN_STEP_EMAIL);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mfaOtp, setMfaOtp] = useState(null);
  const [ssoRedirectUrl, setSsoRedirectUrl] = useState('');
  const [error, setError] = useState(false);

  const {
    route,
    handleSubmit,
    isLoading,
    formErrors,
    formFields,
    loginError,
    mfaRequired,
    mfaProvidedInvalid
  } = props;

  if (loginStep === LOGIN_STEP_EMAIL) {
    setCookie(AUTH_TOKEN, '', 365);
    setCookie(AUTH_REFRESH_TOKEN, '', 365);
  }

  if (formFields.email) {
    if (formFields.email.touched && formErrors.email === 'wrong_email') {
      setError('wrong_email');
    }
  }

  const checkLoginEmail = async (e) => {
    e.preventDefault();

    const validationResult = validate({ email });
    if (validationResult.email) {
      setError(validationResult.email);
      return;
    }

    setError(false);

    const response = await api().get(`/api/v1/users/${email}/sign-in-method/`);

    if (response.body.method === 'sso') {
      setLoginStep(LOGIN_STEP_SSO);
      setSsoRedirectUrl(response.body.idp_redirect_url);
    } else {
      setLoginStep(LOGIN_STEP_PASSWORD);
    }
  };

  if (getCookie(MFA_REMEMBER) === '') {
    const generateUUID = () => {
      // Public Domain/MIT
      let d = new Date().getTime(); // Timestamp
      let d2 =
        (typeof performance !== 'undefined' &&
          performance.now &&
          performance.now() * 1000) ||
        0; // Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; // random number between 0 and 16
          if (d > 0) {
            // Use timestamp until depleted
            // eslint-disable-next-line no-bitwise
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            // Use microseconds since page-load if supported
            // eslint-disable-next-line no-bitwise
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          // eslint-disable-next-line no-bitwise
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    };
    setCookie(MFA_REMEMBER, generateUUID(), 365);
  }

  return (
    <Container className={isMobile() ? stylesLogin.mobileLogin : null}>
      <Row>
        <Col
          xs={{ size: 12 }}
          sm={{ size: 6, offset: 3 }}
          className={stylesLogin.loginFormContainer}
        >
          <div className={styles['auth-wrapper']}>
            {isMobile() && <div className={stylesLogin.mobileLogo} />}
            {route && route.query && route.query.error && (
              <Alert color="danger">
                <FormattedMessage id={route.query.error} />
              </Alert>
            )}
            {mfaRequired && (
              <Alert color="danger">
                A one-time passcode has been sent to your email(s) on file.
                Please check your inbox and spam folders and enter the code to
                sign in.
              </Alert>
            )}
            {mfaProvidedInvalid && (
              <Alert color="danger">
                Provided passcode not valid or expired. Click
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => window.location.reload()}
                >
                  {' '}
                  here{' '}
                </a>
                to login again.
              </Alert>
            )}
            {error && (
              <Alert color="danger">
                <FormattedMessage id={`ERRORS.${error}`} />
              </Alert>
            )}
            {loginError && (
              <Alert color="danger">
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.intl.formatMessage({
                      id: `ERRORS.${normalizeError(loginError)}`
                    })
                  }}
                />
              </Alert>
            )}
            <h2>
              <FormattedMessage id="LOGIN" />
            </h2>
            <div className={stylesLogin.loginForm}>
              <form onSubmit={props.handleSubmit} className={styles.form}>
                {loginStep === LOGIN_STEP_EMAIL && (
                  <>
                    <InputGroup>
                      <Label for="email">
                        <FormattedMessage id="FORM_FIELDS.email" /> (
                        <FormattedMessage id="REQUIRED" />)
                      </Label>
                      <Field
                        name="email"
                        component={renderInput}
                        noTextError
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target?.value)}
                      />
                    </InputGroup>
                    <Button
                      id="submit_login_email"
                      name="submit_login_email"
                      onClick={checkLoginEmail}
                      disabled={false}
                      color="primary-dark"
                      className={styles['submit-button']}
                      // type="button"
                    >
                      Next
                    </Button>
                  </>
                )}
                {loginStep === LOGIN_STEP_PASSWORD && (
                  <>
                    <p
                      className={
                        isMobile() ? stylesLogin.signingInMobile : null
                      }
                      style={{ marginBottom: '2rem' }}
                    >
                      Signing in as <strong>{email}</strong>.
                    </p>
                    <InputGroup>
                      <Label for="password">
                        <FormattedMessage id="FORM_FIELDS.password" /> (
                        <FormattedMessage id="REQUIRED" />)
                      </Label>
                      <Field
                        name="password"
                        component={renderInput}
                        noTextError
                        type="password"
                        value={password}
                        onChange={(e) =>
                          e.target ? setPassword(e.target.value) : null
                        }
                      />
                    </InputGroup>
                    {mfaRequired && (
                      <InputGroup>
                        <Label>
                          <FormattedMessage id="FORM_FIELDS.mfa-otp" /> (
                          <FormattedMessage id="REQUIRED" />)
                        </Label>
                        <Field
                          name="mfa_otp"
                          component={renderInput}
                          noTextError
                          type="text"
                          value={mfaOtp}
                          onChange={(e) => setMfaOtp(e.target.value)}
                        />
                        <Label htmlFor="share_assessment">
                          <Field
                            name="mfa_remember"
                            component={renderInput}
                            type="checkbox"
                          />
                          <FormattedMessage id="FORM_FIELDS.mfa-remember" />
                        </Label>
                      </InputGroup>
                    )}

                    <div style={{ textAlign: 'center' }}>
                      <Button
                        id="submit_login_form"
                        name="submit_login_form"
                        onClick={() =>
                          handleSubmit({ email, password, mfa_otp: mfaOtp })
                        }
                        disabled={!!(formErrors.email || formErrors.password)}
                        color="primary-dark"
                        className={styles['submit-button']}
                      >
                        <FormattedMessage
                          id={isLoading ? 'LOADING' : 'LOGIN'}
                        />
                      </Button>
                      <Link
                        id="forgot_password_link"
                        to="/auth/forgot-password"
                        query={
                          route && route.query && route.query.lang
                            ? { lang: route.query.lang }
                            : {}
                        }
                        className={stylesLogin.forgotLink}
                      >
                        <FormattedMessage id="FORGOT_PASSWORD" />
                      </Link>
                    </div>
                  </>
                )}
                {loginStep === LOGIN_STEP_SSO && (
                  <>
                    <p>
                      <a href={ssoRedirectUrl}>Click here</a> to log in.
                    </p>
                    <Button
                      onClick={() => setLoginStep(LOGIN_STEP_EMAIL)}
                      color="secondary"
                      className={styles['submit-button']}
                    >
                      Back
                    </Button>
                  </>
                )}
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  isLoading: PropTypes.bool,
  mfaRequired: PropTypes.bool,
  formErrors: PropTypes.object,
  formFields: PropTypes.object,
  loginError: PropTypes.object,
  route: PropTypes.object.isRequired
};

Login = connect((state) => ({
  route: state.router.route,
  isLoading: state.auth.get('loginLoading'),
  mfaRequired: state.auth.get('mfaRequired'),
  mfaProvidedInvalid: state.auth.get('mfaProvidedInvalid'),
  formErrors: state.form.loginForm.syncErrors || {},
  formFields: state.form.loginForm.fields || {},
  loginError: state.auth.get('error')
}))(Login);

export default reduxForm({
  form,
  validate
})(injectIntl(Login));
