import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import TagsForm from './TagsForm';

import modals from '../../../../../modals.css';
import { fetchFilterData } from 'actions/global';

const MassActionTagsModal = (props, context) => {
  const {
    actionData,
    isLoading,
    onSubmit,
    fetchFilterData,
    filterValues
  } = props;
  const { removeModal } = context;

  useEffect(() => {
    fetchFilterData(filterValues);
  }, []);

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id="ADD_AND_REMOVE_TAGS" />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <TagsForm actionData={actionData} />
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={12} className={modals['modal-button-col']}>
            <Button
              color="primary"
              onClick={onSubmit}
              className={modals['footer-button']}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

MassActionTagsModal.propTypes = {
  actionData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

MassActionTagsModal.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    filterValues: {
      assessments: formValueSelector('filter')(state, 'assessments') || [],
      partners: formValueSelector('filter')(state, 'partners') || [],
      clients: formValueSelector('filter')(state, 'clients') || []
    }
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchFilterData
      },
      dispatch
    )
)(MassActionTagsModal);
