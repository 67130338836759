import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

import { events as eventsAction, fetchSimulation } from 'actions/simulations';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainSimulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    simulation: state.simulations.hasIn([
      'simulations',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.simulations.getIn([
          'simulations',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.simulations.getIn([
          'simulations',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ eventsAction, fetchSimulation }, dispatch)
)
export default class EventsTranslateTable extends Component {
  static propTypes = {
    id: PropTypes.number,
    simulation: PropTypes.object,
    mainSimulation: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    eventsAction: PropTypes.func.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.func.isRequired
  };

  getTable = () => {
    const { simulation, mainSimulation, hasError, disabled } = this.props;

    return {
      data: mainSimulation.get('events').toArray(),
      customClassName: 'trans-2-table',
      fields: [
        {
          key: 'original_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.get('name')
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let event = mainSimulation
              .get('events')
              .find((e) => e.get('id') === item.get('id'));
            let defaultValue = null;

            if (simulation.has('events')) {
              event = simulation
                .get('events')
                .find((e) => e.get('id') === item.get('id'));

              if (event) {
                defaultValue = event.get('name') || null;
              }
            }

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={item.get('id')}
                  defaultValue={defaultValue}
                  onSubmit={(e) => this.handleSubmit(e, item.get('id'))}
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  handleSubmit = (data, eventId) => {
    const { id, eventsAction, version, fetchSimulation } = this.props;
    const { addAlert, intl } = this.context;

    const translated = {
      name: data.translation,
      language: version
    };

    eventsAction({
      type: 'edit',
      simulationId: id,
      id: eventId,
      data: translated
    }).then((action) => {
      if (
        action.error &&
        action.error.body &&
        typeof action.error.body === 'string'
      ) {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });

        fetchSimulation(id);
      }
    });
  };

  render() {
    if (!this.props.simulation) {
      return <div />;
    }

    return (
      <div>
        <BuildTable table={this.getTable()} />
      </div>
    );
  }
}
