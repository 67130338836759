import { Map, fromJS } from 'immutable';
import {
  CREATE_BEHAVIOR_REQUEST,
  CREATE_BEHAVIOR_SUCCESS,
  CREATE_BEHAVIOR_FAILURE,
  FETCH_BEHAVIORS_REQUEST,
  FETCH_BEHAVIORS_SUCCESS,
  FETCH_BEHAVIORS_FAILURE,
  FETCH_BEHAVIOR_REQUEST,
  FETCH_BEHAVIOR_SUCCESS,
  FETCH_BEHAVIOR_FAILURE,
  EDIT_BEHAVIOR_REQUEST,
  EDIT_BEHAVIOR_SUCCESS,
  EDIT_BEHAVIOR_FAILURE,
  FETCH_INDICATOR_REQUEST,
  FETCH_INDICATOR_SUCCESS,
  FETCH_INDICATOR_FAILURE,
  FETCH_INDICATORS_REQUEST,
  FETCH_INDICATORS_SUCCESS,
  FETCH_INDICATORS_FAILURE,
  CREATE_INDICATOR_REQUEST,
  CREATE_INDICATOR_SUCCESS,
  CREATE_INDICATOR_FAILURE,
  EDIT_INDICATOR_REQUEST,
  EDIT_INDICATOR_SUCCESS,
  EDIT_INDICATOR_FAILURE,
  DELETE_INDICATOR_REQUEST,
  DELETE_INDICATOR_SUCCESS,
  DELETE_INDICATOR_FAILURE,
  CREATE_RELATIONSHIP_REQUEST,
  CREATE_RELATIONSHIP_SUCCESS,
  CREATE_RELATIONSHIP_FAILURE,
  EDIT_RELATIONSHIP_REQUEST,
  EDIT_RELATIONSHIP_SUCCESS,
  EDIT_RELATIONSHIP_FAILURE,
  DELETE_RELATIONSHIP_REQUEST,
  DELETE_RELATIONSHIP_SUCCESS,
  DELETE_RELATIONSHIP_FAILURE,
  FETCH_FACETS_REQUEST,
  FETCH_FACETS_SUCCESS,
  FETCH_FACETS_FAILURE,
  DELETE_BEHAVIOR_SUCCESS,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  behaviors: Map(),
  filtered: Map(),
  facets: Map(),
  modalBehaviors: Map()
});

export default function behaviors(state = initialState, action) {
  switch (action.type) {
    case FETCH_BEHAVIORS_REQUEST:
      return state.set('behaviorsLoading', true);
    case FETCH_BEHAVIORS_SUCCESS: {
      const behaviors = action.result.body.data;
      const { language } = action;

      return state
        .set('behaviorsLoading', false)
        .set('behaviorsTotal', action.result.body.filtered)
        .set(
          'filtered',
          Map(behaviors.map((behavior) => [behavior.id, fromJS(behavior)]))
        )
        .mergeDeepIn(
          ['behaviors', language],
          Map(behaviors.map((behavior) => [behavior.id, fromJS(behavior)]))
        );
    }
    case FETCH_BEHAVIORS_FAILURE:
      return state.set('behaviorsLoading', false);

    case FETCH_BEHAVIOR_REQUEST:
      return state.set('behaviorsLoading', true);
    case FETCH_BEHAVIOR_SUCCESS: {
      const { language } = action;
      const behavior = action.result.body;

      return state.withMutations((newState) => {
        newState
          .set('behaviorsLoading', false)
          .mergeDeepIn(['behaviors', language, behavior.id], fromJS(behavior));
      });
    }
    case FETCH_BEHAVIOR_FAILURE:
      return state.set('behaviorsLoading', false);

    case CREATE_BEHAVIOR_REQUEST:
      return state.set('createBehaviorLoading', true);
    case CREATE_BEHAVIOR_SUCCESS: {
      const behavior = action.result.body;
      const { language } = action;

      return state
        .set('createBehaviorLoading', false)
        .set('behaviorsTotal', state.get('behaviorsTotal') + 1)
        .mergeIn(['behaviors', language, behavior.id], fromJS(behavior));
    }
    case CREATE_BEHAVIOR_FAILURE:
      return state.set('createBehaviorLoading', false);
    case EDIT_BEHAVIOR_REQUEST:
      return state.set('editBehaviorLoading', true);
    case EDIT_BEHAVIOR_SUCCESS: {
      const behavior = action.result.body;
      const { language } = action;
      const { id } = action;

      return state
        .set('editBehaviorLoading', false)
        .mergeIn(['behaviors', language, id], fromJS(behavior))
        .setIn(
          ['behaviors', 'english', id, 'languages'],
          fromJS(behavior.languages)
        );
    }
    case EDIT_BEHAVIOR_FAILURE:
      return state.set('editBehaviorLoading', false);

    case DELETE_BEHAVIOR_SUCCESS: {
      const id = Number(action.id);
      const language = action.language || 'english';

      return state
        .deleteIn(['behaviors', language, id])
        .deleteIn(['filtered', id])
        .set('behaviorsTotal', state.get('behaviorsTotal') - 1);
    }

    case FETCH_INDICATOR_REQUEST:
      return state.set('indicatorsLoading', true);
    case FETCH_INDICATOR_SUCCESS: {
      const { language } = action;
      const indicator = action.result.body;
      const { behaviorId } = action;

      return state.withMutations((newState) => {
        newState
          .set('indicatorsLoading', false)
          .setIn(
            ['behaviors', language, behaviorId, 'indicators', indicator.id],
            fromJS(indicator)
          );
      });
    }
    case FETCH_INDICATOR_FAILURE:
      return state.set('indicatorsLoading', false);
    case FETCH_INDICATORS_REQUEST:
      return state.set('indicatorsLoading', true);
    case FETCH_INDICATORS_SUCCESS: {
      const { language } = action;
      const indicators = action.result.body.data;
      const { behaviorId } = action;

      return state.withMutations((newState) => {
        newState
          .set('indicatorsLoading', false)
          .setIn(
            ['behaviors', language, behaviorId, 'indicators'],
            Map(indicators.map((ind) => [ind.id, fromJS(ind)]))
          );
      });
    }
    case FETCH_INDICATORS_FAILURE:
      return state.set('indicatorsLoading', false);
    case CREATE_INDICATOR_REQUEST:
      return state.set('indicatorsLoading', true);
    case CREATE_INDICATOR_SUCCESS: {
      const { language } = action;
      const indicator = action.result.body;
      const { behaviorId } = action;

      return state.withMutations((newState) => {
        newState
          .set('indicatorsLoading', false)
          .setIn(
            ['behaviors', language, behaviorId, 'indicators', indicator.id],
            fromJS(indicator)
          );
      });
    }
    case CREATE_INDICATOR_FAILURE:
      return state.set('indicatorsLoading', false);
    case EDIT_INDICATOR_REQUEST:
      return state.set('indicatorsLoading', true);
    case EDIT_INDICATOR_SUCCESS: {
      const { language } = action;
      const indicator = action.result.body;
      const { behaviorId } = action;
      const indicatorId = action.id;

      return state.withMutations((newState) => {
        newState
          .set('indicatorsLoading', false)
          .mergeDeepIn(
            ['behaviors', language, behaviorId, 'indicators', indicatorId],
            fromJS(indicator)
          );
      });
    }
    case EDIT_INDICATOR_FAILURE:
      return state.set('indicatorsLoading', false);
    case DELETE_INDICATOR_REQUEST:
      return state.set('indicatorsLoading', true);
    case DELETE_INDICATOR_SUCCESS: {
      const { language } = action;
      const { behaviorId } = action;
      const indicatorId = action.id;

      return state.withMutations((newState) => {
        newState
          .set('indicatorsLoading', false)
          .deleteIn([
            'behaviors',
            language,
            behaviorId,
            'indicators',
            indicatorId
          ]);
      });
    }
    case DELETE_INDICATOR_FAILURE:
      return state.set('indicatorsLoading', false);

    case CREATE_RELATIONSHIP_REQUEST:
      return state.set('relationshipsLoading', true);
    case CREATE_RELATIONSHIP_SUCCESS: {
      const behavior = action.result.body;
      const { behaviorId } = action;

      return state.withMutations((newState) => {
        newState
          .set('relationshipsLoading', false)
          .setIn(['behaviors', 'english', behaviorId], fromJS(behavior));
      });
    }
    case CREATE_RELATIONSHIP_FAILURE:
      return state.set('relationshipsLoading', false);
    case EDIT_RELATIONSHIP_REQUEST:
      return state.set('relationshipsLoading', true);
    case EDIT_RELATIONSHIP_SUCCESS: {
      const behavior = action.result.body;
      const { behaviorId } = action;

      return state.withMutations((newState) => {
        newState
          .set('relationshipsLoading', false)
          .mergeDeepIn(['behaviors', 'english', behaviorId], fromJS(behavior));
      });
    }
    case EDIT_RELATIONSHIP_FAILURE:
      return state.set('relationshipsLoading', false);
    case DELETE_RELATIONSHIP_REQUEST:
      return state.set('relationshipsLoading', true);
    case DELETE_RELATIONSHIP_SUCCESS: {
      const behavior = action.result.body;
      const behaviorId = action.result.body.id;

      return state.withMutations((newState) => {
        newState
          .set('indicatorsLoading', false)
          .setIn(['behaviors', 'english', behaviorId], fromJS(behavior));
      });
    }
    case DELETE_RELATIONSHIP_FAILURE:
      return state.set('indicatorsLoading', false);

    case FETCH_FACETS_REQUEST:
      return state.set('facetsLoading', true);
    case FETCH_FACETS_SUCCESS: {
      const { language } = action;
      const facets = action.result.body.data;

      return state.withMutations((newState) => {
        newState
          .set('facetsLoading', false)
          .mergeDeepIn(
            ['facets', language],
            Map(facets.map((facet) => [facet.id, fromJS(facet)]))
          );
      });
    }
    case FETCH_FACETS_FAILURE:
      return state.set('facetsLoading', false);

    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
