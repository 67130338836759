import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { InputGroup } from 'reactstrap';
import { ReactTags } from 'components';

import { fetchMassActionTags } from 'actions/users';

import styles from '../../../../../../forms/styles.css';
import '../../../../../../forms/styles.css';
import tagsModalStyles from './styles.css';
import './styles.css';

export const massActionTagsForm = 'massActionTagsForm';

const TagsForm = (props) => {
  const {
    actionData,
    addedTags,
    change,
    disabled,
    fetchMassActionTags,
    handleSubmit,
    removedTags,
    suggestions
  } = props;
  const [usedTags, setUsedTags] = useState([]);

  useEffect(() => {
    fetchMassActionTags(actionData).then((action) => {
      setUsedTags(action.result.body?.tags || []);
    });
  }, []);

  const handleDelete = (i, name) => {
    const tags = [].concat(addedTags);

    tags.slice(0);
    tags.splice(tags.indexOf(name), 1);
    change(massActionTagsForm, 'addedTags', tags);
  };

  const handleAddition = (tag, index) => {
    const tags = [].concat(addedTags);

    if (tags.indexOf(tag) < 0) {
      tags.splice(index, 0, tag);
      change(massActionTagsForm, 'addedTags', tags);
    }
  };

  const handleUsedTagDelete = (i, name) => {
    const updatedUsedTags = [].concat(usedTags);
    const tagsToRemove = [].concat(removedTags);

    updatedUsedTags.slice(0);
    updatedUsedTags.splice(updatedUsedTags.indexOf(name), 1);

    tagsToRemove.push(name);
    change(massActionTagsForm, 'removedTags', tagsToRemove);

    setUsedTags(updatedUsedTags);
  };

  return (
    <form
      key="mass-action-tags-form"
      className={classNames(styles.form, tagsModalStyles.form)}
      onSubmit={handleSubmit}
    >
      <div>
        <h6 style={{ borderBottom: 0 }}>
          <FormattedMessage id="MASS_ACTION_TAGS_MODAL.ADD_MESSAGE" />
        </h6>

        <InputGroup>
          <label htmlFor="tags">
            <FormattedMessage id="FORM_FIELDS.tags" />
          </label>
          <ReactTags
            autofocus={false}
            disabled={disabled}
            tags={addedTags.map((t) => ({ name: t }))}
            suggestions={suggestions
              .filter((t) => addedTags.indexOf(t) < 0)
              ?.map((t) => ({
                name: t
              }))}
            handleDelete={(index, tag) => handleDelete(index, tag.name)}
            handleAddition={(tag, index) => handleAddition(tag.name, index)}
            onCreateNew={(tag, index) => handleAddition(tag, index)}
            allowNew
            minQueryLength={0}
          />
        </InputGroup>
      </div>
      <div className={tagsModalStyles['remove-section']}>
        <h6>
          <FormattedMessage id="MASS_ACTION_TAGS_MODAL.REMOVE_MESSAGE" />
        </h6>

        {usedTags.map((tag, index) => (
          <span
            key={tag}
            className={classNames(
              'react-tags__selected-tag',
              disabled && 'disabled'
            )}
            title="Click to remove tag"
            style={{
              backgroundColor: disabled ? '#eceeef' : '#fff',
              borderRadius: 5,
              padding: '4px 10px',
              lineHeight: '20px',
              fontSize: 12,
              marginTop: 10,
              marginRight: 10
            }}
          >
            <span
              className="react-tags__selected-tag-name"
              style={{ fontSize: 12 }}
            >
              {tag}
            </span>
            <button
              id={`used_tag_button_${tag}`}
              type="button"
              name={`used_tag_button_${tag}`}
              onClick={() => handleUsedTagDelete(index, tag)}
              disabled={disabled}
            >
              &#x2715;
            </button>
          </span>
        ))}
      </div>
    </form>
  );
};

TagsForm.propTypes = {
  actionData: PropTypes.object.isRequired,
  addedTags: PropTypes.array.isRequired,
  removedTags: PropTypes.array.isRequired,
  suggestions: PropTypes.array.isRequired
};

export default reduxForm({
  form: massActionTagsForm
})(
  connect(
    (state) => ({
      addedTags:
        formValueSelector(massActionTagsForm)(state, 'addedTags') || [],
      removedTags:
        formValueSelector(massActionTagsForm)(state, 'removedTags') || [],
      suggestions: state.users.getIn(['filterData', 'tags'])?.toJS() || []
    }),
    (dispatch) => bindActionCreators({ change, fetchMassActionTags }, dispatch)
  )(TagsForm)
);
