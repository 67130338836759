// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Components
import { Preview, NewAssessment, EditAssessment } from './subviews';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Customize
 * @constructor
 */
const Customize = (props) => (
  <div className={styles['customize-preview-head']}>
    <Helmet title="Pinsight - Customize" />
    {props.children}
  </div>
);

// Prop types
Customize.propTypes = {
  children: PropTypes.any
};

Customize.Preview = Preview;
Customize.NewAssessment = NewAssessment;
Customize.EditAssessment = EditAssessment;

export default Customize;
