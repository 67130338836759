import { getCurrentAssessmentId } from '../../../components/Folders/components/actions';

const reportsOfOneAssessment = ({ data, route }) => {
  const selectedReports = route.query.selected?.split(',');
  const selectedAssessments = [];
  let report = null;

  selectedReports?.map((reportId) => {
    const assessmentId = getCurrentAssessmentId(data.get(Number(reportId)));

    if (selectedAssessments.indexOf(assessmentId) < 0) {
      selectedAssessments.push(assessmentId);
    }
  });

  if (selectedAssessments.length === 1) {
    report = data.get(Number(selectedReports[0]));
  }

  return report;
};

export default reportsOfOneAssessment;
