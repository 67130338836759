import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, Col, Row, Table } from 'reactstrap';
import { Loader } from 'components';

import styles from './styles.css';
import './styles.css';

import { sortItems } from 'helpers';

@connect(() => ({}))
export default class BuildTable extends Component {
  static propTypes = {
    table: PropTypes.object.isRequired,
    tableKey: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    total: PropTypes.number,
    isLoading: PropTypes.bool
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      sortBy: { key: null, asc: false },
      page: 1
    };
  }

  loadMore = () => {
    const { table } = this.props;
    const { page } = this.state;
    const { userId } = table.helpers;
    if (userId) {
      table.helpers.loadMore(
        userId,
        page * table.helpers.itemsCount,
        table.helpers.itemsCount
      );
    } else {
      table.helpers.loadMore(
        page * table.helpers.itemsCount,
        table.helpers.itemsCount
      );
    }

    this.setState({
      page: page + 1
    });
  };

  saveTable = () => {
    const { addAlert } = this.context;
    window.scrollTo(0, 0);
    addAlert({
      color: 'success',
      content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
    });
  };

  render() {
    const { table, tableKey, isLoading } = this.props;
    const { sortBy } = this.state;
    let counter = 0;

    return (
      <Row>
        <Col xs={table.button ? 10 : 12}>
          {table.counter && (
            <h6
              className={classNames(
                styles['users-count'],
                table.customClassName ? styles[`${table.customClassName}`] : ''
              )}
            >
              Total: {table.helpers ? table.helpers.total : table.data.length}{' '}
              <FormattedMessage id={table.key} />
            </h6>
          )}
          <Table
            bordered
            striped={!tableKey}
            className={[
              styles.table,
              table.customClassName
                ? table.customClassName
                    .split(' ')
                    .map((customClass) => styles[`${customClass}`])
                    .join(' ')
                : ''
            ]}
          >
            <thead>
              <tr>
                {table.fields.map((field, i) => {
                  if (tableKey > 1 || field.isHidden) {
                    return null;
                  }

                  return (
                    <th
                      key={i}
                      className={classNames(
                        field.key === 'empty' && styles.empty,
                        field.centered && styles.center,
                        field.verticalCentered && styles['vertical-centered'],
                        field.headerClassName && styles[field.headerClassName]
                      )}
                      width={field.width ? `${field.width}%` : ''}
                    >
                      {field.component != null && field.component}
                      <span
                        id={`toggle-sort-${field.key}`}
                        onClick={
                          field.sortable
                            ? () => {
                                this.setState({
                                  sortBy: {
                                    key: field.key,
                                    asc:
                                      sortBy.key === field.key
                                        ? !sortBy.asc
                                        : true
                                  }
                                });
                              }
                            : undefined
                        }
                      >
                        {field.key !== 'empty' &&
                          field.key &&
                          (field.notTranslate ? (
                            field.key
                          ) : (
                            <FormattedMessage
                              id={`TABLE_HEADING.${field.key}`}
                            />
                          ))}
                        {field.sortable && (
                          <span className={styles.table__carrots}>
                            <span
                              className={classNames(
                                styles.desc,
                                sortBy.key === field.key &&
                                  !sortBy.asc &&
                                  styles.active
                              )}
                            >
                              &#9650;
                            </span>
                            <span
                              className={classNames(
                                styles.asc,
                                sortBy.key === field.key &&
                                  sortBy.asc &&
                                  styles.active
                              )}
                            >
                              &#9660;
                            </span>
                          </span>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            {isLoading && (
              <div
                style={{
                  width: 1110,
                  position: 'absolute',
                  height: 'calc(100% - 47px)',
                  background: '#fff',
                  zIndex: 9,
                  left: 15,
                  top: 47
                }}
              >
                <Loader />
              </div>
            )}
            <tbody>
              {table.data &&
                sortItems(table.data, sortBy).map((item, i) => {
                  counter += 1;
                  return (
                    <tr
                      key={`row-${i}`}
                      onClick={
                        table.rowAction
                          ? () => table.rowAction(item)
                          : () => undefined
                      }
                      className={classNames(
                        tableKey && tableKey % 2 === 0
                          ? styles.dark
                          : styles.white,
                        table.rowAction && styles['action-row'],
                        table.getRowIndicator &&
                          styles[table.getRowIndicator(item)]
                      )}
                    >
                      {table.fields.map((field, j) =>
                        field.ownData ? (
                          field.renderField(item, counter)
                        ) : (
                          <td
                            key={`table-field-${i}-${j}`}
                            id={`table-field-${i}-${j}`}
                            className={classNames(
                              field.locked && styles.locked,
                              field.dark && styles.dark,
                              field.hasError && styles.error,
                              field.centered && styles.center,
                              field.verticalCentered &&
                                styles['vertical-centered']
                            )}
                            width={field.width ? `${field.width}%` : ''}
                          >
                            {field.isList ? (
                              <ul> {field.renderField(item, counter)} </ul>
                            ) : (
                              field.renderField(item, counter)
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {(!table.data ||
            table.data.size === 0 ||
            table.data.length === 0) && <div />}
          {table.helpers &&
            table.data.length > 0 &&
            table.data.length < table.helpers.total &&
            !table.helpers.query && (
              <div style={{ textAlign: 'center' }}>
                <Button type="button" color="link" onClick={this.loadMore}>
                  <FormattedMessage id="LOAD_MORE" />
                </Button>
              </div>
            )}
        </Col>
        <Col xs={2} className={styles['simulations-add-button']}>
          <div className="float-xs-right">{table.button}</div>
        </Col>
      </Row>
    );
  }
}
