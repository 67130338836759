import React, { useEffect, useState } from 'react';

import api from 'api';

import styles from './styles.css';
import './styles.css';

const CoachingNotes = (props) => {
  const [noteText, setNoteText] = useState('');
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = props;

  const fetchCoachingNotes = async () => {
    const response = await api().get(
      `/api/v1/analytics/development/coaching-notes/${user.get('id')}/`
    );

    setNotes(response.body);
  };

  useEffect(() => {
    fetchCoachingNotes();
  }, [user]);

  const handleSubmit = async () => {
    if (noteText.trim().length === 0) {
      return;
    }

    const data = { text: noteText.trim() };

    try {
      setError('');
      setIsLoading(true);
      await api().post(
        `/api/v1/analytics/development/coaching-note/${user.get('id')}/`,
        data
      );
      await fetchCoachingNotes();
      setNoteText('');
    } catch (err) {
      setError('Something went wrong, please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderNotes = () => {
    return notes.map((note) => (
      <div className={styles['coaching-note-wrapper']}>
        <div className={styles['coaching-note-header']}>
          <div className={styles['coaching-note-coach-img']}>
            <img
              src={`${process.env.REACT_APP_ASSETSURL}${note.coach.profile_photo}`}
              width={40}
              height={40}
              alt="coach"
            />
          </div>
          <div className={styles['coaching-note-coach-name']}>
            {' '}
            {note.coach.firstname} {note.coach.surname}
          </div>
          <div className={styles['coaching-note-coach-date']}>
            {' '}
            {note.created_at}
          </div>
        </div>
        <div className={styles['coaching-note-body']}>{note.text}</div>
      </div>
    ));
  };

  return (
    <div
      style={{
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <h6 className={styles['section-header']}>Coaching Notes</h6>
      <textarea
        className={styles['coaching-note-input']}
        onChange={(event) => setNoteText(event.target.value)}
        value={noteText}
        rows="5"
        placeholder="Type notes here, then click the 'Submit' button to submit your message to the feed. Submitted messages cannot be edited or deleted. These are internal notes to be shared with whoever has been given access and not visible to participants."
      />
      {error && <p className={styles['error-text']}>{error}</p>}
      <button
        type="button"
        className="btn btn-secondary w-100"
        style={{ marginBottom: '7px', marginTop: '7px' }}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Submit
      </button>
      {notes.length ? (
        <div className={styles['coaching-notes-container']}>
          {renderNotes()}
        </div>
      ) : null}
    </div>
  );
};

export default CoachingNotes;
