/* eslint react/prefer-stateless-function: 0 */
// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// Styles
import { Input } from 'reactstrap';
import styles from '../styles.css';

// Components

/**
 * Render form date input
 * @class
 */
@connect((state) => ({
  formErrors: state.app.get('formErrors') || {}
}))
export default class renderDateInput extends Component {
  static propTypes = {
    fieldErrors: PropTypes.object,
    field: PropTypes.any
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      days: '000',
      min: '00',
      hrs: '00'
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const field = this.props;
      const { value } = field.input;
      const withDay = field.d;

      if (value) {
        if (withDay) {
          const days = value.substring(0, 3);
          const min = value.substring(4, 6);
          const hrs = value.substring(7, 9);

          this.setState({
            days,
            min,
            hrs
          });

          field.input.onChange(`${days} ${min}:${hrs}`);
        } else {
          const min = value.substring(0, 2);
          const hrs = value.substring(3, 5);

          this.setState({
            min,
            hrs
          });

          field.input.onChange(`${min}:${hrs}`);
        }
      }
    }, 10);
  }

  /**
   * On change
   * @param e
   * @param type
   * @returns {*}
   */
  onChange = (e, type) => {
    const field = this.props;
    const withDay = field.d;
    let { days, min, hrs } = this.state;

    switch (type) {
      case 'd':
        if (e.target.value === days) {
          return false;
        }

        days = e.target.value;
        break;
      case 'm':
        if (e.target.value === min) {
          return false;
        }

        min = e.target.value;
        break;
      case 'h':
        if (e.target.value === hrs) {
          return false;
        }

        hrs = e.target.value;
        break;
      default:
        break;
    }

    this.setState({ days, hrs, min });
    return withDay
      ? field.input.onChange(`${days} ${min}:${hrs}`)
      : field.input.onChange(`${min}:${hrs}`);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const field = this.props;
    const { days, hrs, min } = this.state;
    const { formErrors } = field;
    const fieldName = field.subOwner
      ? field.input.name.split('.')[1]
      : field.input.name;

    const errorSelector = field.subOwner ? formErrors.owner : formErrors;
    let hasServerError = false;

    if (
      errorSelector &&
      errorSelector[fieldName] &&
      errorSelector[fieldName].errors
    ) {
      hasServerError = true;
    }

    return (
      <span className={styles['date-input']}>
        <div className={styles['date-input__inner']}>
          {field.d && (
            <Input
              id={`${field.input.name}-days`}
              name={`${field.input.name}-days`}
              disabled={field.disabled}
              type="number"
              min="0"
              max="364"
              onChange={(e) => this.onChange(e, 'd')}
              value={days}
              className={classNames(hasServerError && styles.error)}
            />
          )}
          {/* field.d && field.h && <span className={styles['time-dots']}>:</span> */}
          {field.h && (
            <Input
              id={`${field.input.name}-minutes`}
              name={`${field.input.name}-minutes`}
              disabled={field.disabled}
              type="number"
              min="0"
              max="23"
              onChange={(e) => this.onChange(e, 'm')}
              value={min}
              className={classNames(hasServerError && styles.error)}
            />
          )}
          {/* field.h && field.m && <span className={styles['time-dots']}>:</span> */}
          {field.m && (
            <Input
              id={`${field.input.name}-hours`}
              name={`${field.input.name}-hours`}
              disabled={field.disabled}
              type="number"
              min="0"
              max="59"
              onChange={(e) => this.onChange(e, 'h')}
              value={hrs}
              className={classNames(hasServerError && styles.error)}
            />
          )}
        </div>
        {hasServerError &&
          errorSelector[fieldName].errors.map((error) => (
            <div className={styles.form__error}>
              <FormattedMessage id={error} />
            </div>
          ))}
      </span>
    );
  }
}
