// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Render select
 * @param i
 * @returns {XML}
 */
@connect((state) => ({
  direction: state.app.get('direction')
}))
class Select extends Component {
  // Default props
  static defaultProps = {
    id: 'default'
  };

  // Prop types
  static propTypes = {
    id: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.object,
    active: PropTypes.bool,
    options: PropTypes.array,
    direction: PropTypes.string.isRequired,
    day: PropTypes.object
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  /**
   * Open box
   */
  openBox = () => {
    this.setState({ open: true });
    window.addEventListener('click', this.clickHandler, false);
    window.addEventListener('keydown', this.keyDownHandler, false);
  };

  keyDownHandler = (e) => {
    const area = ReactDOM.findDOMNode(this.refs.box);

    if (!area || !area.contains(e.target)) {
      this.setState({ open: false });
      window.removeEventListener('keydown', this.keyDownHandler, false);
    }
  };

  /**
   * Click handler
   * @param e
   */
  clickHandler = (e) => {
    const area = ReactDOM.findDOMNode(this.refs.box);

    if (!area || !area.contains(e.target)) {
      this.setState({ open: false });
      window.removeEventListener('click', this.clickHandler, false);
    }
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      id,
      selected,
      onSelect,
      active,
      options,
      direction,
      day
    } = this.props;
    const { open } = this.state;

    return (
      <div
        id={id}
        className={styles['options-box']}
        style={direction === 'rtl' ? { right: 0 } : {}}
        ref="box"
      >
        <div
          id={`${id}_open_box_button`}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          aria-label={`${day.format('dddd')} ${day.format('MMMM')} ${day.format(
            'Do'
          )} ${options.length} ${this.props.intl.formatMessage({
            id: 'SESSIONS'
          })}`}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              return active ? () => false : this.openBox();
            }

            return () => false;
          }}
          onClick={active ? () => false : this.openBox}
          className={classNames(
            styles['options-box__heading'],
            active && styles.active
          )}
        >
          <span>
            {selected.time ? (
              selected.time
            ) : (
              <span>
                {options.length} <FormattedMessage id="SESSIONS" />{' '}
                <span className={styles.arrow}>&#x276F;</span>
              </span>
            )}
          </span>
        </div>

        <div
          id={`${id}_options`}
          className={classNames(
            styles['options-box__block'],
            open && styles.open
          )}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <div className={styles.head} tabIndex="0">
            <FormattedMessage id="CHOOSE_YOUR_SESSION" />
          </div>
          {options.map((option, counter) => (
            <div
              id={`${id}_options_${counter}`}
              className={styles['options-box__block__item']}
              onClick={() => {
                onSelect(option);
                this.setState({ open: false });
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onSelect(option);
                  this.setState({ open: false });
                  document.getElementById('schedule_button').focus();
                }
              }}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              aria-label={option}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(Select);
