import {
  START_VIDEO_CALL_REQUEST,
  START_VIDEO_CALL_SUCCESS,
  START_VIDEO_CALL_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Start video call action
 *
 * @param{Number} participantId
 * @param{Number} assignmentId
 * @param{String} roomName
 * @returns {{types: *[], promise: Request }}
 */

export default function startTwilioCall(
  participantId,
  assignmentId,
  roomName,
  assessorId
) {
  return {
    types: [
      START_VIDEO_CALL_REQUEST,
      START_VIDEO_CALL_SUCCESS,
      START_VIDEO_CALL_FAILURE
    ],
    promise: api().post(`/api/v1/twilio/call-participant/`, {
      participant_id: participantId,
      assignment_id: assignmentId,
      room: roomName,
      assessorId
    })
  };
}
