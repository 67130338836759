import React from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { Button, Row, Col } from 'reactstrap';
import { validate } from './validate';

import styles from '../../styles.css';
import '../../styles.css';

import { renderInput } from '../../actions';

const form = 'technicalSupportForm';

let TechnicalSupport = (props) => {
  const { handleSubmit, isLoading, formErrors, transitionTo, router } = props;

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h6>
        <FormattedMessage id="FORM_FIELDS.description" />
      </h6>

      <Field
        name="description"
        component={renderInput}
        type="textarea"
        aria-label="Technical support input form"
      />

      {formErrors != null && typeof formErrors === 'string' && (
        <div className={styles.form__error}>
          <FormattedMessage id={formErrors} />
        </div>
      )}

      <div style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={6}>
            <Button
              id="cancel_technical_support_form"
              name="cancel_technical_support_form"
              onClick={() =>
                transitionTo(
                  router.route.query != null
                    ? router.route.query.backLink
                    : '/development/app',
                  router.route.query.backLinkQuery
                )
              }
              color="secondary"
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              id="submit_technical_support_form"
              name="submit_technical_support_form"
              onClick={handleSubmit}
              disabled={isLoading}
              color="primary-dark"
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'SEND'} />
            </Button>
          </Col>
        </Row>
      </div>
    </form>
  );
};

TechnicalSupport.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  isLoading: PropTypes.bool,
  formErrors: PropTypes.object,
  route: PropTypes.object.isRequired,
  transitionTo: PropTypes.func.isRequired
};

TechnicalSupport.contextTypes = {
  intl: PropTypes.object.isRequired
};

TechnicalSupport = connect(
  (state) => ({
    isLoading: state.development.get('postTicketLoading'),
    router: state.router,
    formErrors: state.app.get('formErrors') || {},
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)(TechnicalSupport);

export default reduxForm({
  form,
  validate
})(TechnicalSupport);
