export default function getProgressValues(value, _HIGH, _MEDIUM) {
  let color = '#001c50';
  let colorString = 'blue';
  let message = 'needs_12+';
  let position = 'LOW';

  const HIGH = _HIGH || 67;
  const MEDIUM = _MEDIUM || 34;

  if (Math.round(value) >= HIGH) {
    color = '#2FBC88';
    colorString = 'green';
    message = 'ready_now';
    position = 'HIGH';
  } else if (Math.round(value) >= MEDIUM) {
    color = '#F9CB36';
    colorString = 'orange';
    message = 'needs_3-6';
    position = 'MEDIUM';
  }

  return {
    color,
    colorString,
    message: `FORM_FIELDS.${message}`,
    position
  };
}
