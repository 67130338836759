import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import { InputGroup, Label } from 'reactstrap';
import { uploadFile } from 'actions/global';
import { validate } from './validate';

import styles from '../../styles.css';

import { ToolTip, UploadBlock } from '../../../components';

import { renderInput } from '../../actions';

const form = 'documentForm';

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-document'])
      ? state.app.getIn(['codeList', 'allowed-ext-document']).toArray()
      : [],
    formErrors: state.app.get('formErrors') || {}
  }),
  (dispatch) => bindActionCreators({ uploadFile, change }, dispatch)
)
export default class Document extends Component {
  static propTypes = {
    allowedTypes: PropTypes.array.isRequired,
    simulation: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    confirmError: PropTypes.bool,
    formErrors: PropTypes.object.isRequired,
    wasPublished: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      output: [],
      formatError: false,
      sizeError: false
    };
  }

  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'name', edit.get('name'));
      change(form, 'prework', edit.get('prework'));
      // eslint-disable-next-line
      this.setState({
        output: [{ name: edit.get('path'), path: edit.get('path') }]
      });
    }
  }

  handleChange = (files) => {
    const { allowedTypes, uploadFile, change, dispatch } = this.props;

    this.setState({ sizeError: false, formatError: false });

    dispatch({ type: 'RESET_FORM_ERRORS' });
    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      this.setState({ formatError: true });
    } else if (files[0].size > 2000000) {
      this.setState({ sizeError: true });
    } else {
      this.setState({ output: files, sizeError: false, typeError: false });
      uploadFile(files[0], dispatch, 'document').then((action) => {
        if (!action.error) {
          change(form, 'file_id', action.result.body.uniqid);
          change(form, 'file_name', files[0].name);
        } else {
          this.setState({ typeError: action.error.body });
        }
      });
    }
  };

  render() {
    const {
      allowedTypes,
      handleSubmit,
      edit,
      formErrors,
      wasPublished,
      simulation
    } = this.props;
    const { formatError, output, sizeError, typeError } = this.state;
    const fileError = formErrors.file_name;
    const version =
      simulation &&
      simulation.has('languages') &&
      simulation.get('languages').find((l) => l.get('language') === 'english');

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published')) {
        return true;
      }

      if (version.get('was_published')) {
        switch (field) {
          case 'name':
            return false;
          case 'prework':
            return false;
          default:
            return true;
        }
      }

      return false;
    };

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.document_name" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="name"
              component={renderInput}
              type="text"
              disabled={isFieldDisabled('name')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-checkbox']}>
            <Label>
              <Field
                name="prework"
                component={renderInput}
                type="checkbox"
                disabled={isFieldDisabled('prework')}
              />
              <FormattedMessage id="FORM_FIELDS.document_prework_info" />
              <ToolTip
                id="PREWORK_FILES_JIT"
                index="PREWORK_FILES_JIT"
                hint={<FormattedMessage id="PREWORK_FILES_JIT" />}
              />
            </Label>
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <UploadBlock
              onDrop={this.handleChange}
              output={output}
              title={wasPublished ? 'REPLACE_DOCUMENT' : 'UPLOAD_NEW_DOCUMENT'}
              edit={edit}
              allowedFileTypes={['application/pdf']}
            />
            {(sizeError || fileError) && (
              <div className={styles.form__error}>
                {sizeError ? (
                  <FormattedMessage
                    id="ERRORS.large_file"
                    values={{ max: '2' }}
                  />
                ) : (
                  <FormattedMessage id="ERRORS.missing_file" />
                )}
              </div>
            )}
            {typeError && (
              <div className={styles.form__error}>
                <FormattedMessage id={typeError.message} values={typeError} />
              </div>
            )}
            {formatError && (
              <div className={styles.form__error}>
                <FormattedMessage
                  id="ERRORS.FILE_TYPE_NOT_ALLOWED"
                  values={{
                    allowed_types: allowedTypes
                      .map((t) => t.get('name'))
                      .join(', ')
                  }}
                />
              </div>
            )}
          </InputGroup>
        </form>
      </div>
    );
  }
}
