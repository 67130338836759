import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';

import { ExpandRow } from 'components';
import { UserBox } from '../index';
import styles from '../../styles.css';
import {
  getCurrentAssessmentId,
  getSelected,
  onDelete,
  onDownload,
  onNarrativeUpdate,
  onSelect,
  onShare,
  updateCheckboxes,
  updatePreviewData
} from '../actions';

import {
  getNarrative,
  fetchAnalytics,
  fetchDetailAnalyticsData,
  shareReport,
  downloadPdf,
  downloadExcel,
  removeReport
} from 'actions/analytics';

const Overview = (props, context) => {
  const {
    allData,
    analyticsDetailLoading,
    isLoading,
    overviewData,
    sort,
    route
  } = props;
  const selectedUsers = getSelected(route);

  function comparator(a, b, sort) {
    if (a.getIn(['user', 'firstname']) < b.getIn(['user', 'firstname'])) {
      return sort === 'down' ? -1 : 1;
    }
    if (a.getIn(['user', 'firstname']) > b.getIn(['user', 'firstname'])) {
      return sort === 'down' ? 1 : -1;
    }

    return 0;
  }

  const fetchAnalyticsMetaData = (assessment_id) => {
    const { allData, fetchAnalytics } = props;
    const data = allData.filter(
      (r) => getCurrentAssessmentId(r) === assessment_id && r.has('folders')
    );

    if (data.size === 0) {
      return fetchAnalytics({ assessments: [assessment_id] });
    }

    return Promise.resolve({
      result: {
        body: data.map((i) => i.toJS()).toArray()
      }
    });
  };

  const getPreview = (uuid) => {
    const { allData } = props;

    const finalItems = allData
      .sort((a, b) => comparator(a, b, sort))
      .filter((r) => {
        const report_uuid = getCurrentAssessmentId(r);

        return uuid.toString() === report_uuid.toString();
      })
      .toArray()
      .map((item) => (
        <Col
          xs={4}
          key={`report-${item.get('id').toString()}-${item.getIn([
            'user',
            'id'
          ])}`}
        >
          <UserBox
            selected={selectedUsers.indexOf(item.get('id')) > -1}
            user={item.get('user')}
            report={item}
            onSelect={(data) => onSelect(data, uuid, false, props)}
            onShare={(data) => onShare(data, props, context)}
            onNarrativeUpdate={(data) =>
              onNarrativeUpdate(data, props, context)
            }
            onDelete={(data) => onDelete(data, props, context)}
            onDownload={(data) => onDownload(data, props, context)}
          />
        </Col>
      ));

    return (
      <div className={styles.folders__preview}>
        <Row className={styles['users-box']}>{finalItems}</Row>
      </div>
    );
  };

  return overviewData?.toArray().map((assessment) => {
    return (
      <div
        key={`${assessment.get('name')}-${assessment.get('uuid')}`}
        className={styles.folders__overview}
      >
        <ExpandRow
          onOpen={async () => {
            await fetchAnalyticsMetaData(assessment.get('uuid'));
            await updatePreviewData(props);
            updateCheckboxes(props);
          }}
          noBorder
          bold={
            !!(
              selectedUsers.length > 0 &&
              allData.find(
                (r) =>
                  selectedUsers.indexOf(r.get('id')) > -1 &&
                  r.get('assessment_name') === assessment.get('name')
              )
            )
          }
          header={
            <div>
              <input
                id={`select-all-${assessment.get('uuid')}`}
                name={`select-all-${assessment.get('uuid')}`}
                disabled={
                  analyticsDetailLoading.find((loading) => loading === true) ||
                  isLoading
                }
                className={styles['select-all']}
                type="checkbox"
                onClick={() => {
                  fetchAnalyticsMetaData(assessment.get('uuid')).then(
                    (action) => {
                      const data = Map(
                        action.result.body.map((r) => [r.id, fromJS(r)])
                      );
                      const newIDS = data.map((r) => r.get('id')).toArray();

                      const arrayContainsArray = (superset, subset) => {
                        if (subset.length === 0) {
                          return false;
                        }

                        return subset.every((value) => {
                          return superset.indexOf(value) >= 0;
                        });
                      };

                      const filteredIds = arrayContainsArray(
                        selectedUsers,
                        newIDS
                      )
                        ? selectedUsers.filter((v) => newIDS.indexOf(v) < 0)
                        : selectedUsers.concat(newIDS);

                      onSelect(
                        filteredIds,
                        assessment.get('uuid'),
                        true,
                        props
                      );
                    }
                  );
                }}
              />{' '}
              <div className={styles.expand_header}>
                {assessment.get('name')}{' '}
                <small>
                  [{assessment.get('reports')}{' '}
                  <FormattedMessage
                    id={
                      Number(assessment.get('reports')) === 1
                        ? 'participant'
                        : 'participants'
                    }
                  />
                  ]
                </small>
              </div>
            </div>
          }
          body={<div>{getPreview(assessment.get('uuid'))}</div>}
        />
      </div>
    );
  });
};

Overview.contextTypes = {
  addModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    allData: state.analytics.get('allData'),
    analyticsDetailLoading: state.analytics.get('analyticsDetailLoading'),
    isLoading: state.analytics.get('analyticsLoading'),
    overviewData: state.analytics.get('overviewData'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        getNarrative,
        fetchAnalytics,
        fetchDetailAnalyticsData,
        shareReport,
        downloadPdf,
        downloadExcel,
        removeReport,
        transitionTo
      },
      dispatch
    );
  }
)(
  React.memo(Overview, (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps?.route?.query) ===
        JSON.stringify(nextProps?.route?.query) &&
      prevProps?.overviewData?.equals(nextProps?.overviewData) &&
      prevProps?.allData?.equals(nextProps?.allData) &&
      prevProps?.isLoading === nextProps?.isLoading &&
      prevProps?.analyticsDetailLoading?.equals(
        nextProps?.analyticsDetailLoading
      ) &&
      prevProps?.sort === nextProps?.sort
    );
  })
);
