import {
  FETCH_INDICATOR_REQUEST,
  FETCH_INDICATOR_SUCCESS,
  FETCH_INDICATOR_FAILURE,
  FETCH_INDICATORS_REQUEST,
  FETCH_INDICATORS_SUCCESS,
  FETCH_INDICATORS_FAILURE,
  CREATE_INDICATOR_REQUEST,
  CREATE_INDICATOR_SUCCESS,
  CREATE_INDICATOR_FAILURE,
  EDIT_INDICATOR_REQUEST,
  EDIT_INDICATOR_SUCCESS,
  EDIT_INDICATOR_FAILURE,
  DELETE_INDICATOR_REQUEST,
  DELETE_INDICATOR_SUCCESS,
  DELETE_INDICATOR_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Indicators action
 *
 * @param{Object} body
 * @returns {{types: *[], promise: Request }}
 */

export default function indicators(body) {
  let types;
  let promise;
  const { type } = body;
  const { behaviorId } = body;
  const { data } = body;
  const { id } = body;

  if (!data.language) {
    data.language = 'english'; // eslint-disable-line
  }

  switch (type) {
    case 'get':
      types = [
        FETCH_INDICATOR_REQUEST,
        FETCH_INDICATOR_SUCCESS,
        FETCH_INDICATOR_FAILURE
      ];
      promise = api().get(
        `/api/v1/behaviors/${behaviorId}/indicators/${id}/?language=${data.language}`
      );
      break;
    case 'fetch':
      types = [
        FETCH_INDICATORS_REQUEST,
        FETCH_INDICATORS_SUCCESS,
        FETCH_INDICATORS_FAILURE
      ];
      promise = api().get(
        `/api/v1/behaviors/${behaviorId}/indicators/?language=${data.language}`
      );
      break;
    case 'create':
      types = [
        CREATE_INDICATOR_REQUEST,
        CREATE_INDICATOR_SUCCESS,
        CREATE_INDICATOR_FAILURE
      ];
      promise = api().post(`/api/v1/behaviors/${behaviorId}/indicators/`, data);
      break;
    case 'edit':
      types = [
        EDIT_INDICATOR_REQUEST,
        EDIT_INDICATOR_SUCCESS,
        EDIT_INDICATOR_FAILURE
      ];
      promise = api().put(
        `/api/v1/behaviors/${behaviorId}/indicators/${id}/`,
        data
      );
      break;
    case 'delete':
      types = [
        DELETE_INDICATOR_REQUEST,
        DELETE_INDICATOR_SUCCESS,
        DELETE_INDICATOR_FAILURE
      ];
      promise = api().del(
        `/api/v1/behaviors/${behaviorId}/indicators/${id}/?language=${data.language}`
      );
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    behaviorId,
    id,
    language: data.language
  };
}
