import {
  FETCH_SIMULATIONS_REQUEST,
  FETCH_SIMULATIONS_SUCCESS,
  FETCH_SIMULATIONS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch simulations action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchSimulations(offset, limit, query, language) {
  const string = `?q=${query ? encodeURIComponent(query) : ''}&offset=${
    offset || 0
  }&limit=all&language=${
    language || 'english'
  }&fields=id,name,lenght,partner,client,customized,language,languages,published,partners&onlyDebriefs=0`; // eslint-disable-line
  return {
    types: [
      FETCH_SIMULATIONS_REQUEST,
      FETCH_SIMULATIONS_SUCCESS,
      FETCH_SIMULATIONS_FAILURE
    ],
    promise: api().get(`/api/v1/simulations/${string}`),
    language: language || 'english'
  };
}
