// Vendor
import React from 'react';
import PropTypes from 'prop-types';

// Subviews
import Login from './Login';
import Logout from './Logout';
import ResetPassword from './ResetPassword';
import SetNewPassword from './SetNewPassword';
import Invitation from './Invitation';

/**
 * Auth
 * @param props
 * @constructor
 */
const Auth = (props) => <div>{props.children}</div>;

Auth.propTypes = {
  children: PropTypes.any
};

Auth.Login = Login;
Auth.Logout = Logout;
Auth.ResetPassword = ResetPassword;
Auth.SetNewPassword = SetNewPassword;
Auth.Invitation = Invitation;

export default Auth;
