import {
  MASS_RESEND_INVITATION_REQUEST,
  MASS_RESEND_INVITATION_SUCCESS,
  MASS_RESEND_INVITATION_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function massResendInvitation(selected) {
  return {
    types: [
      MASS_RESEND_INVITATION_REQUEST,
      MASS_RESEND_INVITATION_SUCCESS,
      MASS_RESEND_INVITATION_FAILURE
    ],
    promise: api().post('/api/v1/mass/invite/', { selected })
  };
}
