import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.css';
import './styles.css';

const CompletedMessage = (props) => {
  return (
    <div className={props.className || ''}>
      <h3 className={styles.centered}>
        <FormattedMessage id="CONGRATULATIONS_COMPLETING_TPA" />
      </h3>
      <p className={styles.centered}>
        <FormattedMessage id="CONTACT_YOUR_REPRESENTATIVE_NEXT_STEPS" />
      </p>
    </div>
  );
};

export default CompletedMessage;
