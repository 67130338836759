import {
  GET_SHAREABLE_STAKEHOLDERS_REQUEST,
  GET_SHAREABLE_STAKEHOLDERS_SUCCESS,
  GET_SHAREABLE_STAKEHOLDERS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function getShareableStakeholdersUsingFormValues(
  partner,
  client
) {
  return {
    types: [
      GET_SHAREABLE_STAKEHOLDERS_REQUEST,
      GET_SHAREABLE_STAKEHOLDERS_SUCCESS,
      GET_SHAREABLE_STAKEHOLDERS_FAILURE
    ],
    promise: api().get(
      `/api/v1/analytics/shareable/?partner=${partner || ''}&client=${
        client || ''
      }`
    )
  };
}
