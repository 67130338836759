import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ActionButtons, ConfirmDelete } from 'components';
import { Button } from 'reactstrap';
import { exercises } from 'actions/simulations';
import { Exercise } from '../modals';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';

export default function getExercisesTable(simulation, context, readOnly) {
  const version =
    simulation.has('languages') &&
    simulation.get('languages').find((l) => l.get('language') === 'english');
  const disabled = version.get('published');

  const fields = [
    {
      key: 'exercise',
      sortable: true,
      renderField: (item) => (
        <span>
          <h6>{item.get('name')}</h6>
          <p>{item.get('description')}</p>
        </span>
      )
    },
    {
      key: 'video_call',
      sortable: false,
      renderField: (item) => (
        <span
          className={
            item.get('is_roleplay')
              ? styles['checkmark-yes']
              : styles['checkmark-no']
          }
          dangerouslySetInnerHTML={{
            __html: item.get('is_roleplay') ? '&#10003;' : '&#10005;'
          }}
        />
      )
    },
    {
      key: 'behaviors',
      sortable: false,
      renderField: (item) => {
        if (item.has('behaviors')) {
          return item
            .get('behaviors')
            .map((b) => (
              <div
                className={classNames(
                  b.get('display_as_default') && styles.green
                )}
              >
                {b.get('name')}
              </div>
            ));
        }

        return null;
      }
    }
  ];

  if (disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <Button
          id={`view_button_${item.get('id')}`}
          name={`view_button_${item.get('id')}`}
          type="button"
          color="link"
          onClick={() => {
            context.addModal(
              <Exercise edit={item} readOnly onClose={context.removeModal} />
            );
          }}
        >
          <FormattedMessage id="VIEW" />
        </Button>
      )
    });
  }

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          edit={() => {
            context.addModal(
              <Exercise
                edit={item}
                version={version}
                onClose={context.removeModal}
              />
            );
          }}
          editDisabled={readOnly}
          delete={
            (version && version.get('was_published')) || readOnly
              ? null
              : () => {
                  context.addModal(
                    <ConfirmDelete
                      action={exercises}
                      data={{
                        type: 'delete',
                        simulationId: simulation.get('id'),
                        data: {},
                        id: item.get('id')
                      }}
                      onClose={context.removeModal}
                    />
                  );
                }
          }
        />
      )
    });
  }

  return {
    data: simulation.has('exercises')
      ? simulation.get('exercises').toArray()
      : [],
    customClassName: 'exercises-table',
    fields,
    button: (
      <Button
        id="new_exercise_button"
        name="new_exercise_button"
        onClick={() => {
          context.addModal(<Exercise onClose={context.removeModal} />);
        }}
        className={styles['add-button']}
        disabled={disabled || version.get('was_published') || readOnly}
      >
        <FormattedMessage id="NEW_EXERCISE" />
      </Button>
    )
  };
}
