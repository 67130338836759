import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionButtons } from 'components';
import { Button } from 'reactstrap';
import { Instruction } from '../modals';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';
import { getCookie } from '../../../../../helpers';
import { AUTH_TOKEN } from '../../../../../constants/cookies';
import { SIMULATION, DEBRIEF } from '../../../../../constants/stringTypes';

import fileDownload from 'js-file-download';
import ConfirmDelete from '../../../../../components/ConfirmDelete';
import { instructions } from 'actions/simulations';

export default function getInstructionTable(simulation, context, readOnly) {
  let inst = [];
  const isDebrief =
    simulation.has('is_debrief') && simulation.get('is_debrief');

  const version =
    simulation.has('languages') &&
    simulation.get('languages').find((l) => l.get('language') === 'english');

  if (simulation && simulation.has('documents')) {
    inst = simulation
      .get('documents')
      .filter((document) => document.get('type') === 'instruction')
      .toArray();
  }

  const fields = [
    {
      key: 'name',
      sortable: false,
      renderField: (item) => item.get('name')
    },
    {
      key: 'pre-work',
      sortable: false,
      renderField: (item) => (
        <span
          className={
            item.get('prework')
              ? styles['checkmark-yes']
              : styles['checkmark-no']
          }
          dangerouslySetInnerHTML={{
            __html: item.get('prework') ? '&#10003;' : '&#10005;'
          }}
        />
      )
    }
  ];

  if (version.get('published')) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <span
          className="btn btn-link"
          onClick={() => {
            fetch(
              `${process.env.REACT_APP_APIURL}/api/v1/assets${item.get(
                'path'
              )}`,
              {
                method: 'get',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
                },
                mode: 'cors'
              }
            )
              .then((response) => response.blob())
              .then((blob) => {
                fileDownload(blob, item.get('file_name'));
              })
              .catch((reason) => {
                // eslint-disable-next-line no-console
                console.warn(reason);
                alert('There was an error when downloading the file');
              });
            return true;
          }}
        >
          <FormattedMessage id="DOWNLOAD" />
        </span>
      )
    });
  }

  if (!version.get('published')) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          secureDownload={item.get('path')}
          secureDownloadName={item.get('file_name')}
          edit={() => {
            context.addModal(
              <Instruction
                edit={item}
                wasPublished={version.get('was_published')}
                onClose={context.removeModal}
                sessionType={isDebrief ? DEBRIEF : SIMULATION}
              />
            );
          }}
          editDisabled={readOnly}
          editKey={version.get('was_published') ? 'REPLACE' : 'EDIT'}
          delete={
            readOnly
              ? null
              : () => {
                  context.addModal(
                    <ConfirmDelete
                      action={instructions}
                      data={{
                        type: 'delete',
                        simulationId: simulation.get('id'),
                        data: {},
                        id: item.get('id'),
                        isDebrief
                      }}
                      onClose={context.removeModal}
                    />
                  );
                }
          }
        />
      )
    });
  }

  return {
    data: inst,
    customClassName: 'instructions-table',
    fields,
    button: (
      <Button
        id="new_instructions_button"
        name="new_instructions_button"
        onClick={() => {
          context.addModal(
            <Instruction
              onClose={context.removeModal}
              sessionType={isDebrief ? DEBRIEF : SIMULATION}
            />
          );
        }}
        disabled={inst.length > 0 || version.get('published') || readOnly}
        className={styles['add-button']}
      >
        <FormattedMessage id="NEW_INSTRUCTIONS" />
      </Button>
    )
  };
}
