import {
  UPDATE_ANSWER_REQUEST,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Update answer action
 *
 * @param{Number} id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function updateAnswer(id, data) {
  return {
    types: [
      UPDATE_ANSWER_REQUEST,
      UPDATE_ANSWER_SUCCESS,
      UPDATE_ANSWER_FAILURE
    ],
    promise: api().put(`/api/v1/users/${id}/personality-tests/`, data)
  };
}
