import { Map, fromJS } from 'immutable';
import {
  SET_LOCALE,
  SET_DIRECTION,
  LIST_CODELISTS_SUCCESS,
  SET_PROGRESS,
  CREATE_USER_FAILURE,
  EDIT_USER_FAILURE,
  CREATE_CLIENT_FAILURE,
  EDIT_CLIENT_FAILURE,
  CREATE_PARTNER_FAILURE,
  EDIT_PARTNER_FAILURE,
  INVITE_USERS_FAILURE,
  CREATE_EMAIL_FAILURE,
  EDIT_EMAIL_FAILURE,
  CREATE_CONTACT_FAILURE,
  EDIT_CONTACT_FAILURE,
  CREATE_EVENT_FAILURE,
  EDIT_EVENT_FAILURE,
  CREATE_INDICATOR_FAILURE,
  EDIT_INDICATOR_FAILURE,
  CREATE_EXERCISE_FAILURE,
  EDIT_EXERCISE_FAILURE,
  CREATE_SIMULATION_INSTRUCTION_FAILURE,
  CREATE_DEBRIEF_INSTRUCTION_FAILURE,
  EDIT_SIMULATION_INSTRUCTION_FAILURE,
  EDIT_DEBRIEF_INSTRUCTION_FAILURE,
  CREATE_BEHAVIOR_FAILURE,
  CREATE_SIMULATION_FAILURE,
  EDIT_BEHAVIOR_FAILURE,
  EDIT_SIMULATION_FAILURE,
  EDIT_ASSESSMENT_FAILURE,
  EDIT_SIMULATION_SUCCESS,
  CREATE_RELATIONSHIP_FAILURE,
  EDIT_RELATIONSHIP_FAILURE,
  EXTEND_TIME_FAILURE,
  SCHEDULE_ASSESSOR_FAILURE,
  CREATE_ASSESSMENT_FAILURE,
  UPDATE_NARRATIVE_FAILURE,
  RESET_FORM_ERRORS,
  GET_USER_VIEW_REQUEST,
  GET_USER_VIEW_SUCCESS,
  GET_USER_VIEW_FAILURE,
  CREATE_STRATEGY_FAILURE,
  EDIT_STRATEGY_FAILURE,
  POST_TICKET_FAILURE,
  LOGOUT_SUCCESS,
  SET_FORM_ERROR,
  EDIT_DEBRIEF_SUCCESS,
  EDIT_DEBRIEF_FAILURE,
  CREATE_DEBRIEF_FAILURE
} from 'constants/actionTypes';
import { DETAIL_FETCH_ERROR } from '../../constants/errors';

const initialState = Map({
  codeList: Map(),
  permissions: Map(),
  uploading: Map(),
  locale: 'en',
  direction: 'ltr'
});

export default function app(state = initialState, action) {
  switch (action.type) {
    case LIST_CODELISTS_SUCCESS:
      return state.withMutations((newState) => {
        action.result.body.data.map((codeList, i) =>
          newState.setIn(
            ['codeList', codeList.name],
            Map(
              codeList.values
                .sort((a, b) => {
                  if (codeList.name === 'allowed-ext-attachment') {
                    return 0;
                  }

                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }

                  return 0;
                })
                .map((current) => [current, fromJS({ id: i, name: current })])
            )
          )
        );
      });
    case CREATE_USER_FAILURE: {
      if (action.error.status === 403) {
        return (window.location = `/users?${DETAIL_FETCH_ERROR}=${action.error.body}`);
      }

      return state.set('formErrors', action.error.body);
    }
    case EDIT_USER_FAILURE:
    case CREATE_CLIENT_FAILURE:
    case EDIT_CLIENT_FAILURE:
    case CREATE_PARTNER_FAILURE:
    case EDIT_PARTNER_FAILURE:
    case INVITE_USERS_FAILURE:
    case CREATE_EMAIL_FAILURE:
    case CREATE_CONTACT_FAILURE:
    case CREATE_EVENT_FAILURE:
    case CREATE_INDICATOR_FAILURE:
    case CREATE_EXERCISE_FAILURE:
    case CREATE_SIMULATION_INSTRUCTION_FAILURE:
    case CREATE_DEBRIEF_INSTRUCTION_FAILURE:
    case EDIT_EXERCISE_FAILURE:
    case EDIT_INDICATOR_FAILURE:
    case EDIT_EVENT_FAILURE:
    case EDIT_CONTACT_FAILURE:
    case EDIT_EMAIL_FAILURE:
    case EDIT_SIMULATION_INSTRUCTION_FAILURE:
    case EDIT_DEBRIEF_INSTRUCTION_FAILURE:
    case EDIT_BEHAVIOR_FAILURE:
    case EDIT_SIMULATION_FAILURE:
    case EDIT_ASSESSMENT_FAILURE:
    case CREATE_BEHAVIOR_FAILURE:
    case CREATE_SIMULATION_FAILURE:
    case CREATE_RELATIONSHIP_FAILURE:
    case EXTEND_TIME_FAILURE:
    case SCHEDULE_ASSESSOR_FAILURE:
    case CREATE_ASSESSMENT_FAILURE:
    case UPDATE_NARRATIVE_FAILURE:
    case EDIT_RELATIONSHIP_FAILURE:
    case CREATE_STRATEGY_FAILURE:
    case EDIT_STRATEGY_FAILURE:
    case POST_TICKET_FAILURE:
    case EDIT_DEBRIEF_FAILURE:
    case CREATE_DEBRIEF_FAILURE:
    case SET_FORM_ERROR:
      return state.set('formErrors', action.error.body);
    case EDIT_SIMULATION_SUCCESS:
    case EDIT_DEBRIEF_SUCCESS:
    case RESET_FORM_ERRORS:
      return state.set('formErrors', null);
    case SET_PROGRESS:
      return state.setIn(['uploading', action.title], action.percentage);
    case GET_USER_VIEW_REQUEST:
      return state.set('permissionsLoading', true);
    case GET_USER_VIEW_SUCCESS:
      return state
        .set('permissionsLoading', false)
        .set('permissions', fromJS(action.result.body));
    case GET_USER_VIEW_FAILURE:
      return state.set('permissionsLoading', false);
    case LOGOUT_SUCCESS:
      return state.set('permissions', fromJS([]));
    case SET_LOCALE:
      document.documentElement.lang = action.locale;
      return state.set('locale', action.locale);
    case SET_DIRECTION:
      return state.set('direction', action.dir);
    default:
      return state;
  }
}
