// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function formatError(errors) {
  return (
    <span>
      {errors.map((error) => (
        <div>
          <FormattedMessage id={error} />
        </div>
      ))}
    </span>
  );
}
