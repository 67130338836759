import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { Input } from 'reactstrap';

import { isMobile } from 'helpers';

import { defaultValue } from 'constants/fieldsData';
import styles from '../styles.css';
import moment from 'moment';

@connect((state) => ({
  formErrors: state.app.get('formErrors') || {}
}))
export default class renderInput extends Component {
  static propTypes = {
    fieldErrors: PropTypes.object,
    field: PropTypes.any
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  render() {
    const field = this.props;
    const { intl } = this.context;
    const { formErrors } = field;
    const { disabledValues } = field;
    let fieldName = field.subOwner
      ? field.input.name.split('.')[1]
      : field.input.name;
    let placeholder = '';
    let options;

    const hasError = (field.meta.touched && field.meta.error) || field.error;
    let errorSelector = field.subOwner ? formErrors.owner : formErrors;

    if (field.input.name.indexOf('active_simulation') > -1) {
      fieldName = field.input.name.split('.')[1];
      fieldName = fieldName === 'id' ? 'simulation_id' : fieldName;
      errorSelector = formErrors.active_simulation;
    }

    let hasServerError = false;

    if (
      errorSelector &&
      errorSelector[fieldName] &&
      errorSelector[fieldName].errors
    ) {
      hasServerError = true;
    }

    if (field.placeholder) {
      if (field.translatePlaceholder) {
        placeholder = intl.formatMessage({ id: field.placeholder });
      } else {
        placeholder = field.placeholder;
      }
    }

    if (!field.noEmpty) {
      options = [''].concat(field.options);
    } else {
      options = field.options;
    }

    // Options Sorting
    // eslint-disable-next-line
    if (!field.noOrder) {
      options = options.sort((a, b) => {
        if (
          field.optionKey === 'fullname' &&
          a &&
          b &&
          a.has('firstname') &&
          b.has('firstname')
        ) {
          const first = a.get('firstname');
          const second = b.get('firstname');
          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }

          return 0;
        }

        if (a && b) {
          const first = a.get(field.optionKey);
          const second = b.get(field.optionKey);

          if (a.get(field.valueKey) === defaultValue.toLowerCase()) {
            return -1;
          }

          if (b.get(field.valueKey) === defaultValue.toLowerCase()) {
            return 1;
          }

          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }

          return 0;
        }

        return 0;
      });
    }

    return (
      <span
        className={classNames(
          styles['input-outer'],
          hasError || (hasServerError && 'has-danger')
        )}
        style={field.style}
      >
        {field.onClose && (
          <button
            type="button"
            tabIndex="-1"
            className={styles['cancel-button']}
            onClick={field.onClose}
          />
        )}
        <Input
          dir={field.forceDir && document.dir === 'rtl' && 'ltr'}
          style={
            field.forceDir && document.dir === 'rtl'
              ? { textAlign: 'right' }
              : {}
          }
          id={field.id ? field.id : field.input.name}
          {...field.input}
          aria-label={field['aria-label']}
          value={field.input.value === ' ' ? '' : field.input.value}
          disabled={field.readOnly || field.disabled}
          onChange={(val) => {
            field.input.onChange(val);

            if (field.onValueChange) {
              field.onValueChange(val);
            }
          }}
          multiple={field.multiple}
          min={field.min}
          max={field.max}
          onPaste={() => {
            if (field.onPaste) {
              setTimeout(() => {
                field.onPaste();
              }, 500);
            }
          }}
          onBlur={
            field.input.value || field.type === 'checkbox'
              ? () => false
              : () => field.input.onChange(' ')
          }
          placeholder={placeholder}
          type={field.type}
          className={classNames(
            (hasError || hasServerError) && styles.error_input,
            field.readOnly && styles['read-only']
          )}
        >
          {field.options &&
            options.map((option, i) => {
              let result;
              if (i === 0 && !field.noEmpty) {
                if (field.loading) {
                  return (
                    <option key={`loading-${i}`} value="">
                      Loading...
                    </option>
                  );
                }

                return (
                  <option value={field.nullValue || ''} key={i}>
                    {field.placeholder && field.translatePlaceholder
                      ? intl.formatMessage({ id: field.placeholder })
                      : field.placeholder}
                  </option>
                );
              }

              if (field.optionKey === 'fullname') {
                result = `${option.get('firstname')} ${option.get('surname')}`;
              } else {
                result = option.get(field.optionKey);
              }

              if (field.optionKey === 'access_simulation_name') {
                const simulationName = option.has('assessment_name')
                  ? option.get('assessment_name')
                  : option.get('simulation_name');

                if (option.has('scheduled_at')) {
                  result = `${simulationName} - ${moment(
                    option.getIn(['scheduled_at', 'start_time'])
                  )
                    .zone(option.getIn(['scheduled_at', 'start_time']))
                    .format('DD MMM YYYY')} ${moment(
                    option.getIn(['scheduled_at', 'start_time'])
                  )
                    .zone(option.getIn(['scheduled_at', 'start_time']))
                    .format('hh:mm A')}`;
                } else {
                  result = simulationName;
                }
              }

              if (field.translationKey && field.translationKey === 'ORIGINAL') {
                return (
                  <option
                    disabled={
                      disabledValues
                        ? disabledValues.indexOf(option.get(field.valueKey)) >
                          -1
                        : false
                    }
                    value={option.get(field.valueKey)}
                    key={i}
                  >
                    {intl.formatMessage({ id: option.get(field.optionKey) })}
                  </option>
                );
              }

              return (
                <option
                  disabled={
                    disabledValues
                      ? disabledValues.indexOf(option.get(field.valueKey)) > -1
                      : false
                  }
                  value={
                    typeof field.valueKey === 'string'
                      ? option.get(field.valueKey)
                      : option.getIn(field.valueKey)
                  }
                  key={i}
                >
                  {field.translationKey
                    ? intl.formatMessage({
                        id:
                          typeof field.translationKey === 'string'
                            ? `${field.translationKey}.${option.get(
                                field.optionKey
                              )}`
                            : option.get(field.optionKey)
                      })
                    : result}
                </option>
              );
            })}
        </Input>
        {field.infoError && !hasError && (
          <div
            className={classNames(
              styles['form__dark-error'],
              isMobile() && styles.mobile
            )}
          >
            <FormattedHTMLMessage id="ERRORS.password_error" />
          </div>
        )}
        {hasError && !field.noTextError && !field.error && (
          <div className={styles.form__error} style={{ ...field.errorsStyle }}>
            <FormattedHTMLMessage id={`ERRORS.${field.meta.error}`} />
          </div>
        )}
        {hasServerError &&
          errorSelector[fieldName].errors.map((error, index) => (
            <div key={index} className={styles.form__error}>
              <FormattedMessage id={error} />
            </div>
          ))}
      </span>
    );
  }
}
