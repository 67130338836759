// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, getFormValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';
import { Relationship as RelationshipForm } from 'forms';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';

// Actions
import { editBehavior, relationships } from 'actions/behaviors';

// Const
const form = 'relationshipForm';

/**
 * Relationship modal
 * @class
 */
@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    behavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    formValues: getFormValues(form)(state) || {},
    isLoading: state.behaviors.get('editBehaviorLoading')
  }),
  (dispatch) =>
    bindActionCreators({ submit, editBehavior, relationships }, dispatch)
)
export default class Relationship extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    edit: PropTypes.object,
    submit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    editBehavior: PropTypes.func.isRequired,
    relationships: PropTypes.func.isRequired,
    behavior: PropTypes.object.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { edit, id, relationships } = this.props;
    const { addAlert, removeModal } = this.context;

    relationships({
      type: edit ? 'edit' : 'create',
      behaviorId: id,
      data,
      id: edit ? edit.get('id') : null
    }).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
        });
        removeModal();
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { onClose, edit, submit, isLoading } = this.props;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage
            id={`${edit ? 'EDIT' : 'NEW'}_RELATIONSHIP_WITH_PSYCHOMETRICS`}
          />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <RelationshipForm edit={edit} onSubmit={this.handleSubmit} />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_relationship_modal"
                name="cancel_relationship_modal"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_relationship_modal"
                name="submit_relationship_modal"
                color="primary"
                onClick={() => submit(form)}
                className={modals['footer-button']}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
