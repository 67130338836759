import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import GetPerformanceOverview from './getPerformanceOverview';
import GetProminentPersonality from './getProminentPersonality';
import GetLeadershipPotential from './getLeadershipPotential';
import GetOverallPerfomance from './getOverallPerformance';
import { connect } from 'react-redux';
import styles from '../styles.css';
import { Alert } from 'reactstrap';

const getOverview = (props) => {
  const { data, route, user, setToSection } = props;
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();
  const isParticipant = user.get('active_role') === 'participant';
  const showNewOverallPerformance = report.get('show_new_overall_performance');

  useEffect(() => {
    setToSection('');
    return () => {
      setToSection('');
    };
  }, []);

  return (
    <Col sm="12" className="p-0">
      {isParticipant &&
        report.has('simulations_count') &&
        report.get('simulations_count') > 1 && (
          <div className="text-center">
            (<FormattedMessage id="ONLY_ONE_ASSESS_INFO" />)
          </div>
        )}

      <Alert color="info" isOpen className={styles['analytics-infobox']}>
        <FormattedMessage id="PERSONALITY_INFO_OVERVIEW" />
      </Alert>

      {current ? (
        <Col sm="6">
          <div>
            <u
              className={styles.performanceTitle}
              style={{ cursor: 'pointer', marginBottom: '20px' }}
              onClick={() => setToSection('performance')}
            >
              <FormattedMessage id="OVERALL_PERFORMANCE" />
            </u>
            {showNewOverallPerformance && (
              <GetOverallPerfomance data={props.data} />
            )}
            <GetPerformanceOverview data={props.data} />
          </div>
        </Col>
      ) : null}

      {current ? (
        <Col sm="6">
          <div>
            <h2 className={styles.leadershipPotentialTitle}>
              <u
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => setToSection('potential')}
              >
                <FormattedMessage id="leadership_potential" />
              </u>
            </h2>
            <GetLeadershipPotential data={props.data} />
          </div>
          <div>
            <h2 className={styles.prominentPersonalityTitle}>
              <u
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => setToSection('personality')}
              >
                <FormattedMessage id="prominent_personality" />
              </u>
            </h2>
            <GetProminentPersonality data={props.data} />
          </div>
        </Col>
      ) : null}
    </Col>
  );
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route,
  user: state.auth.get('loggedUser')
}))(
  React.memo(
    getOverview,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
