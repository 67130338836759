import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from './utils';

const propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object
};

const defaultProps = {
  tag: 'div'
};

const FormFeedback = (props) => {
  const { className, cssModule, tag: Tag, ...attributes } = props;

  const classes = mapToCssModules(
    classNames(className, 'form-control-feedback'),
    cssModule
  );

  return <Tag {...attributes} className={classes} />;
};

FormFeedback.propTypes = propTypes;
FormFeedback.defaultProps = defaultProps;

export default FormFeedback;
