import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Map, fromJS } from 'immutable';
import { ToolTip, ConfirmModal } from '../../../components';

import styles from '../../styles.css';
import '../../styles.css';

import { validate } from './validate';

import { Button, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import { PartnersAndClients } from '../../blocks';

import { renderInput } from '../../actions';
import { fetchAllowedSimulations } from 'actions/users';

export const form = 'editAssessmentBasicInformation';

@reduxForm({
  form,
  validate
})
@connect(
  (state, ownProps) => ({
    user: state.auth.get('loggedUser'),
    assessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    assessmentCopy: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.query.copy)
    ]),
    isLoading:
      state.assessments.get('editAssessmentLoading') ||
      state.assessments.get('createAssessmentLoading'),
    selectedPartner: Number(
      formValueSelector(ownProps.form)(state, 'partner_id')
    ),
    selectedClient: Number(
      formValueSelector(ownProps.form)(state, 'client_id')
    ),
    selectedSimulation: Number(
      formValueSelector(ownProps.form)(state, 'simulation')
    ),
    partners: state.partners.get('allPartners'),
    partnersLoading: state.partners.get('partnersLoading'),
    clients: state.clients.get('allClients'),
    clientsLoading: state.clients.get('clientsLoading'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { change, transitionTo, fetchAllowedSimulations },
      dispatch
    );
  }
)
export default class EditAssessment extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    assessment: PropTypes.object,
    assessmentCopy: PropTypes.object,
    change: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    fetchAllowedSimulations: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    selectedPartner: PropTypes.number,
    selectedClient: PropTypes.number,
    partners: PropTypes.object,
    partnersLoading: PropTypes.number,
    clients: PropTypes.object,
    clientsLoading: PropTypes.number,
    selectedSimulation: PropTypes.number,
    route: PropTypes.object.isRequired
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  constructor(...args) {
    super(...args);
    this.state = {
      simulations: Map(),
      allSimulations: Map(),
      loading: false,
      hideIncludePsychometricsCheckbox: false
    };
  }

  componentDidMount() {
    const { change, assessment, assessmentCopy } = this.props;

    if (!assessment) {
      change(form, 'has_psychometrics', true);
    }

    if (assessment) {
      change(form, 'name', assessment.get('name'));
      change(form, 'simulation', assessment.getIn(['simulation', 'id']));
      change(form, 'partner_id', Number(assessment.getIn(['partner', 'id'])));
      change(form, 'client_id', Number(assessment.getIn(['client', 'id'])));
      change(form, 'has_psychometrics', assessment.get('has_psychometrics'));
    }

    if (assessmentCopy) {
      change(form, 'name', `Copy ${assessmentCopy.get('name')}`);
      change(form, 'simulation', assessmentCopy.getIn(['simulation', 'id']));

      this.fillAssessmentCopyData(this.props);
    }

    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { assessmentCopy, partners, clients } = nextProps;

    if (
      assessmentCopy &&
      (!this.props.clients.equals(clients) ||
        !this.props.partners.equals(partners))
    ) {
      this.fillAssessmentCopyData(nextProps);
    }
  }

  fillAssessmentCopyData = (props) => {
    const { assessmentCopy, partners, clients, change } = props;

    if (
      (assessmentCopy.getIn(['partner', 'id']) == null ||
        this.getOptions(
          partners,
          assessmentCopy.getIn(['simulation', 'id'])
        )?.has(Number(assessmentCopy.getIn(['partner', 'id'])))) &&
      (assessmentCopy.getIn(['client', 'id']) == null ||
        this.getOptions(
          clients,
          assessmentCopy.getIn(['simulation', 'id'])
        )?.has(Number(assessmentCopy.getIn(['client', 'id']))))
    ) {
      change(
        form,
        'partner_id',
        Number(assessmentCopy.getIn(['partner', 'id']))
      );
      change(form, 'client_id', Number(assessmentCopy.getIn(['client', 'id'])));
    }
  };

  fetchData = async () => {
    const { user, fetchAllowedSimulations } = this.props;
    this.setState({ loading: true });

    fetchAllowedSimulations(
      user.get('partner_id') || null,
      user.get('client_id') || null,
      false,
      false,
      null,
      true
    ).then((action) => {
      if (!action.error) {
        const simulations = action.result.body;
        const allSimulations = Map(
          simulations
            .filter((s) => !s.assessment_id && !s.is_debrief)
            .map((s, index) => [index, fromJS(s)])
        );

        this.setState({
          allSimulations,
          simulations: allSimulations
        });
      }
      this.setState({ loading: false });
    });
  };

  getOptions = (object, selectedSimulation) => {
    const simulation = selectedSimulation || this.props.selectedSimulation;

    return object.filter(
      (p) =>
        p.has('simulations') &&
        p.get('simulations').find((s) => s.get('id') === Number(simulation)) &&
        p.has('modules') &&
        p.get('modules').find((m) => m.get('name') === 'Customize')
    );
  };

  isFieldDisabled = (field) => {
    const { assessment } = this.props;

    const version =
      assessment &&
      assessment.has('languages') &&
      assessment.get('languages').find((l) => l.get('language') === 'english');

    if (!version) {
      return false;
    }

    if (version.get('published')) {
      return true;
    }

    if (version.get('was_published')) {
      switch (field) {
        case 'name':
          return false;
        default:
          return true;
      }
    }

    return false;
  };

  render() {
    const { loading, simulations } = this.state;
    const { intl, addModal, removeModal } = this.context;

    const {
      user,
      handleSubmit,
      isLoading,
      transitionTo,
      assessment,
      assessmentCopy,
      selectedSimulation,
      partners,
      clients,
      partnersLoading,
      clientsLoading
    } = this.props;

    const version =
      assessment &&
      assessment.has('languages') &&
      assessment.get('languages').find((l) => l.get('language') === 'english');

    const message = assessment ? 'SAVE' : 'CREATE_ASSESSMENT';

    const isPinsightAdmin = !user.get('isPartner') && !user.get('isClient');
    let hideIncludePsychometricsCheckbox = false;
    const isRemovePsychometricsEnabled =
      (user.get('isPartner') &&
        user.getIn(['partner', 'modules']).indexOf('RemovePsychometrics') !==
          -1) ||
      (user.get('isClient') &&
        user.getIn(['client', 'modules']).indexOf('RemovePsychometrics') !==
          -1);

    if (selectedSimulation) {
      const selectedSimulationData = simulations
        .filter((simulation) => {
          return simulation.get('simulation_id') === Number(selectedSimulation);
        })
        .first();

      hideIncludePsychometricsCheckbox =
        selectedSimulationData &&
        selectedSimulationData.get('is_psychometrics_only');
    }
    if (!isRemovePsychometricsEnabled && !isPinsightAdmin) {
      hideIncludePsychometricsCheckbox = true;
    }

    const filteredPartners =
      assessment && assessment.has('partner')
        ? [assessment.get('partner')]
        : this.getOptions(partners).toArray();
    const filteredClients =
      assessment && assessment.has('client')
        ? [assessment.get('client')]
        : this.getOptions(clients).toArray();

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={5}>
            <FormGroup>
              <Label htmlFor="name">
                <FormattedMessage id="FORM_FIELDS.assessment_name" />
                <span>
                  {' '}
                  (
                  <FormattedMessage id="REQUIRED" />)
                </span>
              </Label>
              <Field
                name="name"
                component={renderInput}
                type="text"
                placeholder={intl.formatMessage({ id: 'NEW_ASSESSMENT' })}
                disabled={this.isFieldDisabled('name')}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="developability">
                <FormattedMessage id="FORM_FIELDS.based_on_simulation" />
                <span>
                  {' '}
                  (
                  <FormattedMessage id="REQUIRED" />)
                </span>
              </Label>
              <Field
                name="simulation"
                component={renderInput}
                type="select"
                valueKey="simulation_id"
                optionKey="name"
                options={
                  (version && assessment.get('simulation')) || assessmentCopy
                    ? [
                        fromJS({
                          simulation_id: (assessment || assessmentCopy).getIn([
                            'simulation',
                            'id'
                          ]),
                          name: (assessment || assessmentCopy).getIn([
                            'simulation',
                            'name'
                          ])
                        })
                      ]
                    : simulations.toArray()
                }
                disabled={
                  this.isFieldDisabled('simulation') ||
                  loading ||
                  assessment ||
                  assessmentCopy
                }
                placeholder={loading ? 'LOADING' : 'PLEASE_SELECT_SIMULATION'}
                translatePlaceholder
              />
            </FormGroup>
            {!hideIncludePsychometricsCheckbox &&
              !this.state.hideIncludePsychometricsCheckbox && (
                <FormGroup>
                  <Label htmlFor="has_psychometrics">
                    <Field
                      name="has_psychometrics"
                      component={renderInput}
                      type="checkbox"
                      disabled={!!assessment}
                      onChange={(e) => {
                        if (e.target.checked) return;
                        addModal(
                          <ConfirmModal
                            confirmMessage="CONFIRM"
                            header="Are you sure?"
                            centerBody
                            message="Are you sure you want to remove the psychometrics from this assessment? The Pinsight App and some report outputs won’t be available. This option cannot be changed after the customization has been created."
                            submitAction={removeModal}
                            onClose={() => {
                              document
                                .getElementById('has_psychometrics')
                                .click();
                              removeModal();
                            }}
                          />
                        );
                      }}
                    />
                    Include psychometrics &nbsp;
                    <ToolTip
                      id="tooltip"
                      index="tooltip"
                      hint="By unchecking this option, you will remove the personality questionnaire and learning aptitude test from the assessment. Some report outputs will not be included. This option cannot be changed after the customization has been created."
                    />
                  </Label>
                </FormGroup>
              )}
          </Col>
          <Col xs={12}>
            {!user.get('isClient') && (
              <PartnersAndClients
                form={form}
                assignment={
                  user.get('isPartner') ? ['client'] : ['partner', 'client']
                }
                hidden={user.get('isPartner') ? ['partner'] : []}
                disabled={
                  !selectedSimulation ||
                  assessment ||
                  this.isFieldDisabled('partnersAndClients') ||
                  (assessmentCopy && (partnersLoading || clientsLoading))
                }
                filtered={{
                  partners: filteredPartners,
                  clients: filteredClients
                }}
                onPartnerChangeHandler={(selectedPartnerId) => {
                  if (selectedPartnerId === 'null') {
                    this.setState({ hideIncludePsychometricsCheckbox: false });
                    return;
                  }
                  if (!selectedPartnerId) {
                    return;
                  }

                  const selectedPartner = filteredPartners
                    .filter(
                      (filteredPartner) =>
                        Number(filteredPartner.get('id')) ===
                        Number(selectedPartnerId)
                    )
                    .pop()
                    .toJS();

                  this.setState({
                    hideIncludePsychometricsCheckbox:
                      selectedPartner.modules.filter(
                        (module) => module.name === 'RemovePsychometrics'
                      ).length === 0
                  });
                }}
                onClientChangeHandler={(selectedClientId) => {
                  if (selectedClientId === 'null') {
                    this.setState({ hideIncludePsychometricsCheckbox: false });
                    return;
                  }
                  if (!selectedClientId) {
                    return;
                  }
                  const selectedClient = filteredClients
                    .filter(
                      (filteredClient) =>
                        Number(filteredClient.get('id')) ===
                        Number(selectedClientId)
                    )
                    .pop()
                    .toJS();

                  this.setState({
                    hideIncludePsychometricsCheckbox:
                      selectedClient.modules.filter(
                        (module) => module.name === 'RemovePsychometrics'
                      ).length === 0
                  });
                }}
              />
            )}
          </Col>
        </Row>

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="cancel_assessment"
              name="cancel_assessment"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/customize')}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_assessment"
              name="submit_assessment"
              type="button"
              color="primary"
              onClick={handleSubmit}
              disabled={version && version.get('published')}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : message} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
