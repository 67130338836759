// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import { InputGroup } from 'reactstrap';
import { validate } from './validate';

// Styles
import styles from '../../styles.css';

// Components

// Actions
import { renderInput } from '../../actions';

// Const
const form = 'relationshipForm';

/**
 * Relationship
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    behavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    facets: state.behaviors.hasIn(['facets', 'english'])
      ? state.behaviors.getIn(['facets', 'english']).toArray()
      : [],
    facets_better_if: state.app.hasIn(['codeList', 'facets_better_if'])
      ? state.app.getIn(['codeList', 'facets_better_if']).toArray()
      : []
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Relationship extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    behavior: PropTypes.object.isRequired,
    facets: PropTypes.array.isRequired,
    facets_better_if: PropTypes.array.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'facet_id', Number(edit.get('id')));
      change(form, 'better_if', edit.get('better_if'));
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      edit,
      handleSubmit,
      behavior,
      facets,
      facets_better_if
    } = this.props;
    const newFacets = [];
    facets.map((f) => {
      if (
        edit == null &&
        behavior.get('facets').find((fac) => fac.get('id') === f.get('id'))
      ) {
        return false;
      }

      return newFacets.push(f);
    });

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.facet_name" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="facet_id"
              component={renderInput}
              type="select"
              valueKey="id"
              optionKey="name"
              options={newFacets}
              translationKey="ORIGINAL"
              placeholder="PLEASE_SELECT_FACET"
              translatePlaceholder
              disabled={edit != null}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.better_if" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="better_if"
              component={renderInput}
              type="select"
              valueKey="name"
              optionKey="name"
              options={facets_better_if}
              translationKey="FACET_BETTER_IF_VALUES"
              placeholder="PLEASE_SELECT_BETTER_IF"
              translatePlaceholder
            />
          </InputGroup>
        </form>
      </div>
    );
  }
}
