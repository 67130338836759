// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RemainingTimeBox } from 'components';

// Styles
import modals from '../../modals.css';
import '../../modals.css';
import styles from '../../../components/RemainingTimeBox/styles.css';

/**
 * Remaining time modal
 * @constructor
 */
const RemainingTimeModal = () => (
  <Modal
    isOpen
    toggle={() => false}
    style={{ textAlign: 'center', color: '#ffffff' }}
  >
    <ModalHeader
      toggle={false}
      className={styles['modal-header-practice-mode']}
    >
      <FormattedMessage id="BEGINNING_LIVE_SESSION" />
    </ModalHeader>
    <ModalBody className={modals['modal-body']} style={{ padding: '0px' }}>
      <RemainingTimeBox />
    </ModalBody>
  </Modal>
);

export default RemainingTimeModal;
