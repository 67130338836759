// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

// Styles
import styles from '../../styles.css';

// Components
import { EditAssessmentTable } from '../../components';

/**
 * Edit
 * @constructor
 */
const Edit = () => (
  <div>
    <div className={styles['link-back']}>
      <Link to="/customize" id="back_to_customize">
        &larr; <FormattedMessage id="BACK_TO_CUSTOMIZE" />
      </Link>
    </div>
    <h1>
      <FormattedMessage id="EDIT_ASSESSMENT" />
    </h1>

    <EditAssessmentTable />
  </div>
);

export default Edit;
