import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Announcement = (props) => (
  <div style={{ borderBottom: '1px solid #ddd' }}>
    <Container>
      <Row>
        <Col>
          <center>
            <h6 style={{ padding: '.5rem 0' }}>
              <img
                src="/assets/img/bullhorn-solid.svg"
                alt="announcement icon"
                width="35"
              />
              &nbsp;
              {props.text}
            </h6>
          </center>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Announcement;
