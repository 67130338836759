import { getCurrentAssessmentId, getSelected } from './index';

export default (props, selectedUsers, checkboxIndex) => {
  const { allData, analyticsData, route } = props;
  const selectedAssessmentRows = [];
  const selected = selectedUsers || getSelected(route);
  let filteredSize = allData?.size || 0;

  allData.map((r) => {
    const assessmentRowId = getCurrentAssessmentId(r);

    if (selectedAssessmentRows.indexOf(assessmentRowId) < 0) {
      return selectedAssessmentRows.push(assessmentRowId);
    }

    return false;
  });

  if (typeof checkboxIndex === 'number' && checkboxIndex === 0) {
    filteredSize = analyticsData?.size || 0;
    selectedAssessmentRows.push(0);
  }

  setTimeout(() => {
    selectedAssessmentRows.map((rowId) => {
      const current = document.getElementById(`select-all-${rowId}`);

      const allAssessmentReports = allData.filter(
        (r) => rowId === getCurrentAssessmentId(r)
      );
      const selectedAssessmentsReports = allAssessmentReports.filter(
        (r) => selected.indexOf(r.get('id')) > -1
      );

      if (current) {
        if (typeof rowId === 'number' && rowId === 0) {
          if (selected.length > 0 && selected.length !== filteredSize) {
            current.indeterminate = true;
            current.checked = true;
          } else if (
            selected.length === filteredSize &&
            selected.length !== 0
          ) {
            current.indeterminate = false;
            current.checked = true;
          } else {
            current.indeterminate = false;
            current.checked = false;
          }
        } else if (
          selectedAssessmentsReports.size > 0 &&
          selectedAssessmentsReports.size !== allAssessmentReports.size
        ) {
          current.indeterminate = true;
          current.checked = true;
        } else if (
          selectedAssessmentsReports.size === allAssessmentReports.size &&
          selectedAssessmentsReports.size !== 0
        ) {
          current.indeterminate = false;
          current.checked = true;
        } else {
          current.indeterminate = false;
          current.checked = false;
        }
      }

      return true;
    });
  }, 50);
};
