import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionButtons, ConfirmDelete } from 'components';
import { Button } from 'reactstrap';
import { contacts } from 'actions/simulations';
import { Contact } from '../modals';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';

export default function getContactsTable(simulation, context, readOnly) {
  const version = simulation
    .get('languages')
    .find((l) => l.get('language') === 'english');
  const disabled = version.get('published');

  const simulationEvents = simulation.has('events')
    ? simulation.get('events').toArray()
    : [];

  const simulationEventsContactIds = simulationEvents.map((simulationEvent) =>
    simulationEvent.get('contact_id')
  );

  const simulationEmails = simulation.has('emails')
    ? simulation.get('emails').toArray()
    : [];

  const simulationEmailsContactIds = simulationEmails.map((simulationEmail) =>
    simulationEmail.get('sender')
  );

  const fields = [
    {
      key: 'contact_name',
      sortable: true,
      renderField: (item) => {
        const profilePhoto = item.get('profile_picture');
        const profilePhotoLink = profilePhoto
          ? `${process.env.REACT_APP_ASSETSURL}${profilePhoto}`
          : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line

        return (
          <span>
            <div
              style={{ backgroundImage: `url(${profilePhotoLink})` }}
              className={styles['user-avatar']}
            />
            <div className={styles['user-name-email']}>
              <span dangerouslySetInnerHTML={{ __html: item.get('name') }} />
            </div>
          </span>
        );
      }
    },
    {
      key: 'video_call',
      sortable: false,
      renderField: (item) => (
        <span
          className={
            item.get('is_roleplay')
              ? styles['checkmark-yes']
              : styles['checkmark-no']
          }
          dangerouslySetInnerHTML={{
            __html: item.get('is_roleplay') ? '&#10003;' : '&#10005;'
          }}
        />
      )
    },
    {
      key: 'company',
      sortable: true,
      renderField: (item) => item.get('company')
    },
    {
      key: 'position',
      sortable: true,
      renderField: (item) => item.get('position')
    }
  ];

  if (disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <Button
          type="button"
          color="link"
          onClick={() => {
            context.addModal(
              <Contact edit={item} readOnly onClose={context.removeModal} />
            );
          }}
        >
          <FormattedMessage id="VIEW" />
        </Button>
      )
    });
  }

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          edit={() => {
            context.addModal(
              <Contact
                edit={item}
                version={version}
                onClose={context.removeModal}
              />
            );
          }}
          editDisabled={readOnly}
          delete={
            readOnly ||
            simulationEventsContactIds.indexOf(Number(item.get('id'))) !== -1 ||
            simulationEmailsContactIds.indexOf(Number(item.get('id'))) !== -1
              ? null
              : () => {
                  context.addModal(
                    <ConfirmDelete
                      action={contacts}
                      data={{
                        type: 'delete',
                        simulationId: simulation.get('id'),
                        data: {},
                        id: item.get('id')
                      }}
                      onClose={context.removeModal}
                    />
                  );
                }
          }
        />
      )
    });
  }

  let contactsData = [];

  if (simulation.has('contacts')) {
    contactsData = simulation
      .get('contacts')
      .toArray()
      .filter((contactData) => !contactData.get('is_generic'));
  }

  return {
    data: contactsData,
    customClassName: 'contacts-table',
    fields,
    button: (
      <Button
        id="new_contact_button"
        name="new_contact_button"
        onClick={() => {
          context.addModal(<Contact onClose={context.removeModal} />);
        }}
        className={styles['add-button']}
        disabled={disabled || readOnly}
      >
        <FormattedMessage id="NEW_CONTACT" />
      </Button>
    )
  };
}
