// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { ActionButtons, ConfirmDelete } from 'components';

// Actions
import { deleteSimulation } from 'actions/simulations';
import { englishLanguageFirst, sortItems } from '../../../../../helpers';

export default function getSimulationsTable(
  simulations,
  context,
  copySimulation,
  sessionType,
  helpers
) {
  const isDebrief = sessionType === 'debriefs' ? 1 : 0;
  const fields = [
    {
      key: isDebrief ? 'debrief' : 'simulation',
      sortable: true,
      renderField: (item) => item.get('name')
    },
    {
      key: 'language',
      sortable: true,
      renderField: (item) =>
        item.get('languages') &&
        englishLanguageFirst(
          sortItems(item.get('languages'), {
            key: 'language',
            asc: true
          })
        ).map((lang) => (
          <span>
            {lang.get('published') ? (
              <strong>
                <FormattedMessage
                  id={`LANGUAGE_VALUES.${lang.get('language')}`}
                />
              </strong>
            ) : (
              <span>
                <FormattedMessage
                  id={`LANGUAGE_VALUES.${lang.get('language')}`}
                />{' '}
                (
                <FormattedMessage id="UNPUBLISHED" />)
              </span>
            )}
            <br />
          </span>
        ))
    },
    {
      key: 'duration',
      sortable: true,
      renderField: (item) => item.get('lenght')
    },
    {
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <span
          title={
            item.has('locked_by') &&
            item.getIn(['locked_by', 'id']) !== helpers.loggedUser.get('id')
              ? helpers.intl.formatMessage(
                  { id: 'SIMULATION_IS_BEING_EDITED_BY' },
                  {
                    name: `${item.getIn([
                      'locked_by',
                      'firstname'
                    ])} ${item.getIn(['locked_by', 'surname'])}`
                  }
                )
              : null
          }
        >
          <ActionButtons
            id={item.get('id')}
            copy={() => {
              copySimulation(item.get('id'), Boolean(isDebrief));
            }}
            editLink={`/simulations/edit/${item.get('id')}`}
            copyDisabled={
              !item.getIn(['permissions', 'actions']).contains('copy')
            }
            title={
              item.getIn(['permissions', 'actions']).contains('copy')
                ? null
                : helpers.intl.formatMessage({
                    id: 'ERRORS.ASSESSMENT_BASE_SIM_NOT_ALLOWED_COPY'
                  })
            }
            editKey={
              item.getIn(['permissions', 'actions']).contains('edit')
                ? 'EDIT'
                : 'VIEW'
            }
            editDisabled={
              !item.getIn(['permissions', 'actions']).contains('edit') &&
              !item.getIn(['permissions', 'actions']).contains('view')
            }
            delete={() => {
              context.addModal(
                <ConfirmDelete
                  action={deleteSimulation}
                  data={{
                    id: item.get('id'),
                    isDebrief
                  }}
                  onClose={context.removeModal}
                />
              );
            }}
            deleteDisabled={
              !item.getIn(['permissions', 'actions']).contains('delete')
            }
          />
        </span>
      )
    }
  ];

  if (!helpers.loggedUser.get('isPartner')) {
    fields.splice(1, 0, {
      key: 'owner',
      sortable: true,
      renderField: (item) =>
        item.has('owner') ? item.getIn(['owner', 'name']) : 'Pinsight'
    });
  }

  return {
    key: `SIMULATIONS_TABS.${sessionType}`,
    counter: true,
    helpers,
    data: simulations,
    customClassName: 'simulations-table',
    fields
  };
}
