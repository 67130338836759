import {
  TWILIO_CONNECT_REQUEST,
  TWILIO_CONNECT_SUCCESS,
  TWILIO_CONNECT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Twilio phone call connect action
 *
 * @returns {{promise}}
 */

export default function twilioRegisterPhoneCall(data) {
  return {
    types: [
      TWILIO_CONNECT_REQUEST,
      TWILIO_CONNECT_SUCCESS,
      TWILIO_CONNECT_FAILURE
    ],
    promise: api().post(`/api/v1/twilio/register-phone-call/`, data)
  };
}
