import {
  FETCH_FILTER_DATA_REQUEST,
  FETCH_FILTER_DATA_SUCCESS,
  FETCH_FILTER_DATA_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function fetchFilterData(filterValues, hideDebriefs = false) {
  const { partners, clients, assessments } = filterValues;

  return {
    types: [
      FETCH_FILTER_DATA_REQUEST,
      FETCH_FILTER_DATA_SUCCESS,
      FETCH_FILTER_DATA_FAILURE
    ],
    promise: api().get(
      `/api/v1/filters/participants/?partners=${partners || ''}&clients=${
        clients || ''
      }&assessments=${assessments || ''}${hideDebriefs ? '&hideDebriefs' : ''}`
    )
  };
}
