// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Button,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';
import formStyles from '../../../../../../forms/styles.css';
import '../../../../../../forms/styles.css';

/**
 * Formatted translation modal
 * @class
 */
@connect((state) => ({
  id: Number(state.router.route.vars.id),
  isLoading: state.behaviors.get('indicatorsLoading')
}))
export default class FormattedTranslationModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    item: PropTypes.object,
    isLoading: PropTypes.bool,
    modalData: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultValue: PropTypes.string
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    const { defaultValue } = this.props;

    this.state = {
      value: defaultValue
        ? RichTextEditor.createValueFromString(defaultValue, 'html')
        : RichTextEditor.createEmptyValue()
    };
  }

  /**
   * On change
   * @param value
   */
  onChange = (value) => {
    this.setState({ value });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { removeModal } = this.context;
    const { value } = this.state;
    const { isLoading, modalData, onSubmit } = this.props;

    return (
      <Modal size="lg" style={{ width: 650 }} isOpen toggle={() => false}>
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id={`FORM_FIELDS.${modalData.item}`} />{' '}
          <FormattedMessage id="TRANSLATION" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <form className={formStyles.form}>
            <InputGroup className={formStyles['heading-modal-group']}>
              <h6>
                <FormattedMessage id={`FORM_FIELDS.${modalData.item}`} />{' '}
                <FormattedMessage id="ORIGINAL" />
              </h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: modalData.root.get(modalData.item)
                }}
              />
            </InputGroup>
            <InputGroup className={formStyles['heading-modal-group']}>
              <h6>
                <FormattedMessage id={`FORM_FIELDS.${modalData.item}`} />{' '}
                <FormattedMessage id="TRANSLATION" /> (
                <FormattedMessage id="REQUIRED" />)
              </h6>
              <RichTextEditor
                value={value}
                onChange={(e) => this.onChange(e, modalData.item)}
                className={formStyles.rte}
              />
            </InputGroup>
          </form>
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                color="secondary"
                onClick={removeModal}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                color="primary"
                onClick={() =>
                  onSubmit({
                    translation:
                      value.toString('html') === '<p><br></p>'
                        ? ' '
                        : value.toString('html')
                  })
                }
                className={modals['footer-button']}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
