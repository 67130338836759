// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Map, fromJS } from 'immutable';

// Components
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tag
} from 'reactstrap';
import { Dropdown, Link } from 'components';

// Styles
import styles from '../../../../components/BuildTable/styles.css';
import '../../../../components/BuildTable/styles.css';
import stylesSims from '../styles.css';
import '../styles.css';

// Actions
import { Main, Translate } from './versions';
import { editSimulation } from 'actions/simulations';

const SUPPORTED_DEBRIEF_LANGUAGES = ['english', 'german', 'spanish'];

/**
 * Edit simulation table
 * @class
 */
@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    route: state.router.route,
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    debrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ]),
    languages: state.simulations.hasIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id),
      'owner',
      'languages'
    ])
      ? Map(
          state.simulations
            .getIn([
              'simulations',
              'english',
              Number(state.router.route.vars.id),
              'owner',
              'languages'
            ])
            .map((name, id) => [name, fromJS({ id, name })])
        )
      : state.app.hasIn(['codeList', 'language'])
      ? state.app.getIn(['codeList', 'language']).toArray()
      : []
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ editSimulation, transitionTo }, dispatch);
  }
)
export default class EditSimulationTable extends Component {
  // PropTypes
  static propTypes = {
    id: PropTypes.number.isRequired,
    route: PropTypes.object.isRequired,
    simulation: PropTypes.object.isRequired,
    debrief: PropTypes.object.isRequired,
    languages: PropTypes.array.isRequired,
    editSimulation: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.tab ? route.query.tab.toString() : '1'
    };
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.tab !== nextProps.route.query.tab) {
      this.toggle(nextProps.route.query.tab);
    }
  }

  /**
   * Get tab content
   * @param tab
   * @param version
   */
  getTabContent = (tab, version) => {
    if (version === 'english') {
      return <Main />;
    }

    return <Translate version={version} />;
  };

  /**
   * Toggle
   * @param tab
   */
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      id,
      simulation,
      debrief,
      languages,
      editSimulation,
      transitionTo
    } = this.props;
    const { activeTab } = this.state;
    const languageVersions = [];

    const session = simulation || debrief;

    const sorted = session
      .get('languages')
      .sortBy((l) => (l.get('language') === 'english' ? -1 : 1));
    const englishPublished = session
      .get('languages')
      .find((l) => l.get('language') === 'english')
      .get('published');

    const supportedLanguages = !debrief
      ? languages
      : languages.filter((lang) =>
          SUPPORTED_DEBRIEF_LANGUAGES.includes(lang.get('name'))
        );

    supportedLanguages.map((lang) => {
      if (
        session
          .get('languages')
          .find((item) => item.get('language') === lang.get('name')) ||
        lang.get('name') === 'english'
      ) {
        return false;
      }

      return languageVersions.push({
        title: `LANGUAGE_VALUES.${lang.get('name')}`,
        action: () => {
          editSimulation(id, {
            language: lang.get('name'),
            isDebrief: Boolean(debrief)
          }).then((action) => {
            if (!action.error) {
              transitionTo(`/simulations/edit/${id}`, {
                tab: (sorted.size + 1).toString(),
                subTab: '1'
              });
            }
          });
        }
      });
    });

    return (
      <div className={styles['edit-simulation-layout']}>
        <Nav tabs>
          {sorted.map((language, id) => {
            const tabId = (id + 1).toString();

            return (
              <NavItem key={tabId} style={{ ':hover': { cursor: 'pointer' } }}>
                <Link
                  id={`tab_${language.get('language')}`}
                  to={`/simulations/edit/${session.get('id')}`}
                  query={{ tab: tabId.toString() }}
                  type="inherit"
                >
                  <NavLink
                    className={classNames(
                      { active: activeTab === tabId },
                      styles['nav-link']
                    )}
                  >
                    <FormattedMessage
                      id={`LANGUAGE_VALUES.${language.get('language')}`}
                    />
                    <Tag
                      className={[
                        styles['indicator-tag'],
                        language.get('published')
                          ? styles['indicator-on']
                          : styles['indicator-off']
                      ]}
                    />
                  </NavLink>
                </Link>
              </NavItem>
            );
          })}
          <Dropdown
            customClassName={stylesSims['add-language']}
            title="ADD_LANGUAGE_VERSION"
            translate
            items={languageVersions}
            disabled={languageVersions.length === 0 || !englishPublished}
            onTab
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          {sorted.map((language, id) => {
            const tabId = (id + 1).toString();

            return (
              <TabPane tabId={tabId} key={tabId}>
                <Row>
                  <Col sm="12">
                    {tabId === activeTab &&
                      this.getTabContent(activeTab, language.get('language'))}
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    );
  }
}
