// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Schedule } from '../../..';

// Styles
import modals from '../../../../../../modals.css';
import '../../../../../../modals.css';

/**
 * Reschedule modal
 * @constructor
 */
const RescheduleModal = (props, context) => {
  const { removeModal } = context;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id="RESCHEDULE" />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <Schedule />
      </ModalBody>
    </Modal>
  );
};

RescheduleModal.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

export default RescheduleModal;
