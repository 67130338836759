// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { ResetPasswordForm } from 'forms';

// Actions
import { resetPassword } from 'actions/auth';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Reset password
 * @class
 */
@connect(undefined, (dispatch) => {
  const { transitionTo } = actions;
  return bindActionCreators({ resetPassword, transitionTo }, dispatch);
})
export default class ResetPassword extends Component {
  // PropTypes
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  // ContextTypes
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
  }

  /**
   * Handle submit
   * @param data
   */
  handleSubmit(data) {
    const { resetPassword } = this.props;
    const { addAlert } = this.context;

    resetPassword(data.email).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: (
            <span>
              <h3 className={styles['alert-success-heading']}>
                <FormattedMessage id="EMAIL_SENT" />
              </h3>
              <FormattedHTMLMessage
                id="RESET_PASSWORD_SUCCESS_MESSAGE"
                values={{ email: action.result.body.email }}
              />
            </span>
          ),
          redirect: '/auth/login'
        });
        this.setState({ success: true });
      }
    });
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { success } = this.state;

    return (
      <div>
        <Helmet title="Pinsight - Reset password" />
        {!success && (
          <ResetPasswordForm onSubmit={this.handleSubmit.bind(this)} />
        )}
      </div>
    );
  }
}
