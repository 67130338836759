import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'reactstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getCurrentAssessmentId } from '../../../components/Folders/components/actions';

import ReactHighcharts from 'react-highcharts';
import { ColorIndicator, BuildTable } from '../../../../../components';
import { getProgressValues } from '../../../../../helpers';

import styles from '../styles.css';

const getGroupCapability = (props, context) => {
  const { data, route } = props;
  const selectedAssessment = route.query.assessment;
  const assessmentData = data.filter((r) => {
    return getCurrentAssessmentId(r) === selectedAssessment;
  });
  const todayData = [];

  assessmentData
    .find((a) => {
      return getCurrentAssessmentId(a) === selectedAssessment?.toString();
    })
    ?.getIn(['simulation', 'default', 'behaviors'])
    ?.map((behavior) => {
      const performanceValues = [];
      const potentialValues = [];
      const improvementValues = [];

      assessmentData.map((r) => {
        const current = r
          ?.getIn(['simulation', 'default', 'behaviors'])
          ?.find((b) => b.get('id') === behavior.get('id'));

        if (current) {
          performanceValues.push(current.get('tbis_perc'));
          potentialValues.push(current.get('bp') * 100);
          improvementValues.push(current.get('improvement') || 0);
        }

        return false;
      });

      return todayData.push({
        performanceValues,
        potentialValues,
        improvementValues,
        behavior: behavior.get('name'),
        definition: behavior.get('definition')
      });
    });
  const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const finalBehaviors = todayData.sort((a, b) => {
    if (arrAvg(a.performanceValues) < arrAvg(b.performanceValues)) {
      return 1;
    }
    if (arrAvg(a.performanceValues) > arrAvg(b.performanceValues)) {
      return -1;
    }

    return 0;
  });

  const series = [
    {
      name: context.intl.formatMessage({
        id: 'potential'
      }),
      color: '#e2e2e5',
      data: finalBehaviors.map((b) => arrAvg(b.potentialValues)),
      showInLegend: true
    },
    {
      name: context.intl.formatMessage({
        id: 'LOW_PERFORMANCE'
      }),
      color: '#001c50',
      data: finalBehaviors.map((b) =>
        getProgressValues(arrAvg(b.performanceValues)).position === 'LOW'
          ? arrAvg(b.performanceValues)
          : 0
      ),
      pointPadding: 0.4,
      pointPlacement: 0,
      showInLegend: true
    },
    {
      name: context.intl.formatMessage({
        id: 'MEDIUM_PERFORMANCE'
      }),
      color: '#F9CB36',
      data: finalBehaviors.map((b) =>
        getProgressValues(arrAvg(b.performanceValues)).position === 'MEDIUM'
          ? arrAvg(b.performanceValues)
          : 0
      ),
      pointPadding: 0.4,
      pointPlacement: 0,
      showInLegend: true
    },
    {
      name: context.intl.formatMessage({
        id: 'HIGH_PERFORMANCE'
      }),
      color: '#2FBC88',
      data: finalBehaviors.map((b) =>
        getProgressValues(arrAvg(b.performanceValues)).position === 'HIGH'
          ? arrAvg(b.performanceValues)
          : 0
      ),
      pointPadding: 0.4,
      pointPlacement: 0,
      showInLegend: true
    }
  ];

  const config = {
    chart: {
      height: 500,
      type: 'column'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: finalBehaviors.map((i) => i.behavior),
      title: {
        text: context.intl.formatMessage({
          id: 'GAPS_PARTICIPANTS_SKILLS'
        }),
        style: {
          fontSize: 14
        },
        y: 10
      }
    },
    yAxis: [
      {
        min: 0,
        max: 100,
        title: {
          text: context.intl.formatMessage({
            id: 'GROUP_AVERAGE'
          }),
          style: {
            fontSize: 14
          },
          x: -10
        },
        allowDecimals: false,
        tickPositions: [0, 34, 67, 100],
        labels: {
          enabled: data.size !== 1
        }
      }
    ],
    legend: {
      useHTML: true,
      shadow: false,
      padding: 15,
      borderWidth: 1,
      reversed: true,
      margin: 30,
      itemStyle: {
        fontWeight: 'normal'
      },
      title: {
        text: `<div style="width: 645px; text-align: center; font-weight: normal; margin-bottom: 5px;">${context.intl.formatMessage(
          {
            id: 'GROUP_AVERAGE'
          }
        )}</div>`
      }
    },
    tooltip: {
      shared: true,
      borderColor: '#999999',
      useHTML: true,
      headerFormat:
        '<table><tr><td style="text-align: center;">' +
        '<span style="font-size: 14px;">{point.key}</span>' +
        '</td></tr><tr><td style="text-align: center; padding-top: 5px;">',
      pointFormatter() {
        return this.y === 0
          ? null
          : `
              <span style="margin: 0 10px;">
                <span style="color:${this.color}">\u25CF</span>
                <span style="font-size: 12px;"> ${Math.round(this.y)}%</span>
              </span>
            `;
      },
      footerFormat: '</td></tr></table>'
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
        maxPointWidth: 55
      },
      series: {
        states: {
          hover: {
            brightness: 0.05
          }
        }
      }
    },
    series,
    credits: {
      enabled: false
    }
  };

  const getTable = () => {
    const fields = [
      {
        key: 'skill',
        sortable: false,
        verticalCentered: true,
        width: 20,
        renderField: (item) => <strong>{item.behavior}</strong>
      },
      {
        key: 'definition',
        sortable: false,
        verticalCentered: true,
        renderField: (item) => item.definition
      },
      {
        key: 'average_group_performance',
        sortable: false,
        ownData: true,
        centered: true,
        width: 12,
        renderField: (item, index) => (
          <td
            key={`average_group_performance_${index}`}
            style={{
              textAlign: 'center'
            }}
          >
            <ColorIndicator
              type="circle"
              color={
                getProgressValues(arrAvg(item.performanceValues)).colorString
              }
            />
            <strong>{Math.round(arrAvg(item.performanceValues))}%</strong>
          </td>
        )
      },
      {
        key: 'average_group_improvement',
        sortable: false,
        centered: true,
        width: 12,
        renderField: (item) => `${arrAvg(item.improvementValues).toFixed(2)}%`
      },
      {
        key: 'average_group_potential',
        sortable: false,
        ownData: true,
        centered: true,
        width: 12,
        renderField: (item, index) => (
          <td
            key={`average_group_potential_${index}`}
            style={{
              textAlign: 'center'
            }}
          >
            <ColorIndicator
              type="circle"
              color={
                getProgressValues(arrAvg(item.potentialValues)).colorString
              }
            />
            <strong>{Math.round(arrAvg(item.potentialValues))}%</strong>
          </td>
        )
      }
    ];

    return {
      data: finalBehaviors,
      fields
    };
  };

  return (
    <div>
      {selectedAssessment && (
        <Alert className={styles['analytics-infobox']} color="info" isOpen>
          <FormattedHTMLMessage
            id={
              assessmentData.size === 1
                ? 'GROUP_CAPABILITY_INFO_INDIVIDUAL'
                : 'GROUP_CAPABILITY_INFO'
            }
          />
        </Alert>
      )}

      {assessmentData.size > 1 && (
        <React.Fragment>
          <div className="text-center">
            <h4 className={styles['analytics-section']}>
              <FormattedMessage id="ASSESSMENT" />:{' '}
              {assessmentData.first().get('assessment_name')}
            </h4>
          </div>

          <div
            style={{ margin: '40px 0' }}
            dir="ltr"
            className="html2pdf__page-break"
          >
            <ReactHighcharts config={config} />
          </div>

          <div className="html2pdf__page-break">
            <BuildTable table={getTable()} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

getGroupCapability.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect((state) => ({
  route: state.router.route
}))(React.memo(getGroupCapability));
