import {
  GET_PARTNER_REQUEST,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get partner action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */

export default function getPartner(id) {
  return {
    types: [GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS, GET_PARTNER_FAILURE],
    promise: api().get(`/api/v1/partners/${id}/`),
    id
  };
}
