import {
  EDIT_SIMULATION_REQUEST,
  EDIT_SIMULATION_SUCCESS,
  EDIT_SIMULATION_FAILURE,
  EDIT_DEBRIEF_REQUEST,
  EDIT_DEBRIEF_SUCCESS,
  EDIT_DEBRIEF_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Edit simulation action
 *
 * @param{String} id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function editSimulation(id, data) {
  const result = data;
  const types = [];

  if (!result.language) {
    result.language = 'english';
  }

  if (!result.isDebrief) {
    result.isDebrief = 0;
    types.push(
      EDIT_SIMULATION_REQUEST,
      EDIT_SIMULATION_SUCCESS,
      EDIT_SIMULATION_FAILURE
    );
  } else {
    types.push(
      EDIT_DEBRIEF_REQUEST,
      EDIT_DEBRIEF_SUCCESS,
      EDIT_DEBRIEF_FAILURE
    );
  }

  return {
    types,
    promise: api().put(`/api/v1/simulations/${id}/`, data),
    id,
    language: result.language,
    isDebrief: result.isDebrief
  };
}
