import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { change, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { Col, InputGroup, Label, Row } from 'reactstrap';
import { SimulationAndLanguage, ScheduleManually } from '.';

import ShareReportsAccessForm from '../Global/ShareReportsAccessForm';
import { renderInput } from '../actions';

import styles from '../styles.css';

const InviteNowBlock = (props) => (
  <Row style={{ marginTop: '40px' }}>
    <Col xs={12}>
      <InputGroup className={styles['invite-now-checkbox']}>
        <Label check>
          <Field
            name="invite_now"
            component={renderInput}
            type="checkbox"
            disabled={props.disabled}
            onChange={(e) => {
              if (e.target.checked === false) {
                props.change(props.form, 'scheduled_at', null);
              }
            }}
          />
          <FormattedMessage id="FORM_FIELDS.invite_now" />
        </Label>
      </InputGroup>
    </Col>
    {props.inviteNow && (
      <React.Fragment>
        <SimulationAndLanguage disabled={props.disabled} form={props.form} />
        {props.withManualSchedule && (
          <ScheduleManually
            form={props.form}
            seed={props.availableTimes.seed}
            timezone={props.timezone}
          />
        )}
        <Col xs={12}>
          <h5>
            <FormattedMessage id="SHARE_RESULTS_ACCESS" />
          </h5>
          <ShareReportsAccessForm
            disabled={
              props.selectedSimulation == null || props.selectedLanguage == null
            }
            parentForm={props.form}
            removeButton="x"
            usage="invitation"
          />
        </Col>
      </React.Fragment>
    )}
  </Row>
);

// Prop types
InviteNowBlock.propTypes = {
  availableTimes: PropTypes.object,
  inviteNow: PropTypes.bool,
  form: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  change: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  withManualSchedule: PropTypes.bool,
  selectedSimulation: PropTypes.number,
  selectedLanguage: PropTypes.string
};

export default connect(
  (state, ownProps) => ({
    inviteNow: formValueSelector(ownProps.form)(state, 'invite_now'),
    timezone: formValueSelector(ownProps.form)(state, 'timezone'),
    availableTimes: state.simulations.get('availableTimes') || {
      seed: null
    },
    selectedSimulation: formValueSelector(ownProps.form)(
      state,
      'active_simulation.simulation_id'
    ),
    selectedLanguage: formValueSelector(ownProps.form)(
      state,
      'active_simulation.language'
    )
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)(InviteNowBlock);
