import request from 'superagent-bluebird-promise';

const checkDowntime = (callback) => {
  request.get(`${process.env.REACT_APP_APIURL}/api/v1/config/`).then((res) => {
    if (res.ok) {
      callback(res);
    }
  });
};

export default checkDowntime;
