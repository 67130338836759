import PropTypes from 'prop-types';
import React from 'react';
import { Field, formValueSelector } from 'redux-form';

import { Col, Row } from 'reactstrap';

import { ShareReportsAccessForm } from '../index';
import { renderInput } from '../actions';
import { connect } from 'react-redux';

const ShareResultsAccess = (props) => {
  const { editedParticipant, form, selectedAssessment } = props;
  const pastSimulations = editedParticipant
    .get('past_simulations')
    .toArray()
    .filter((pastSimulation) => pastSimulation.get('is_debrief') === false);

  return (
    <Row id="SHARE_REPORTS_ACCESS" style={{ marginTop: 30 }}>
      <Col xs={5}>
        <Field
          name="share_reports_access_assessment"
          component={renderInput}
          disabled={
            editedParticipant && editedParticipant.get('status') === 'suspended'
          }
          type="select"
          valueKey={['sharing', 'active_simulation']}
          optionKey="access_simulation_name"
          options={
            editedParticipant.has('active_simulation') &&
            !editedParticipant.get('active_simulation').get('is_debrief')
              ? [editedParticipant.get('active_simulation')].concat(
                  pastSimulations
                )
              : pastSimulations
          }
          placeholder="SELECT_ASSESSMENT"
          translatePlaceholder
        />
      </Col>
      <Col xs={12}>
        <ShareReportsAccessForm
          editedParticipant={editedParticipant}
          disabled={
            !selectedAssessment ||
            (editedParticipant &&
              editedParticipant.get('status') === 'suspended')
          }
          parentForm={form}
          removeButton="x"
          usage="invitation"
        />
      </Col>
    </Row>
  );
};

ShareResultsAccess.propTypes = {
  form: PropTypes.string,
  selectedAssessment: PropTypes.number,
  editedParticipant: PropTypes.object
};

export default connect((state, ownProps) => ({
  selectedAssessment: Number(
    formValueSelector(ownProps.form)(state, 'share_reports_access_assessment')
  )
}))(ShareResultsAccess);
