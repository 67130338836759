export const validate = (values) => {
  const errors = {};

  const isEmpty = (value) =>
    value == null || !value || value === ' ' || value === `<p>&nbsp;</p>`;

  if (!isEmpty(values.narrative)) {
    if (isEmpty(values.author_name)) {
      errors.author_name = 'FORM_BLANK_VALUE';
    }

    if (isEmpty(values.author_company)) {
      errors.author_company = 'FORM_BLANK_VALUE';
    }
  }

  return errors;
};
