// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Components
import { Preview, Score } from './subviews';

/**
 * Assignments
 * @constructor
 */
const Assignments = (props) => (
  <div>
    <Helmet title="Pinsight - Assignments" />
    {props.children}
  </div>
);

// Prop types
Assignments.propTypes = {
  children: PropTypes.any
};

Assignments.Preview = Preview;
Assignments.Score = Score;

export default Assignments;
