import {
  CREATE_BEHAVIOR_REQUEST,
  CREATE_BEHAVIOR_SUCCESS,
  CREATE_BEHAVIOR_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Create behavior action
 *
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function createBehavior(data) {
  const result = data;

  if (!result.language) {
    result.language = 'english'; // eslint-disable-line
  }

  return {
    types: [
      CREATE_BEHAVIOR_REQUEST,
      CREATE_BEHAVIOR_SUCCESS,
      CREATE_BEHAVIOR_FAILURE
    ],
    promise: api().post(`/api/v1/behaviors/`, data),
    language: result.language
  };
}
