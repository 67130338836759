import {
  FETCH_BEHAVIOR_REQUEST,
  FETCH_BEHAVIOR_SUCCESS,
  FETCH_BEHAVIOR_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch behavior action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchBehavior(id, language) {
  return {
    types: [
      FETCH_BEHAVIOR_REQUEST,
      FETCH_BEHAVIOR_SUCCESS,
      FETCH_BEHAVIOR_FAILURE
    ],
    promise: api().get(
      `/api/v1/behaviors/${id}/?lang=${language || 'english'}`
    ),
    language: language || 'english'
  };
}
