import React from 'react';
import {
  Access,
  AccountOwner,
  TimeAndLanguage,
  NotificationBlock,
  PreferredSchedule,
  PartnersAndClients,
  ModulesSimulationsLanguages,
  HistoryBlock,
  Status,
  ResetPasswordBlock,
  DeleteBlock,
  TagsBlock,
  MarketingConsent,
  NotificationPreferencesBlock
} from '../../blocks';

export default function getBlocks(permissions, blockData) {
  const result = [];
  const relationship = blockData.user
    .get('permissions_debug')
    .get('relationship')
    .toArray()[0];
  const assignment = [];
  let serviceTable = false;
  let serviceTableHideLinks = false;
  let deleteBlock = false;
  let modulesBlock = false;
  let tagsBlock = false;

  const isAssessor = blockData.selectedRole === 'assessor';
  const isParticipant = blockData.selectedRole === 'participant';
  const disabled =
    blockData.user && blockData.user.get('status') === 'suspended';
  const disabledAssessorBlock =
    blockData.selectedRole === 'assessor' &&
    permissions.indexOf(
      'user_profile_partner_assignment_disable_edit_assessor'
    ) > -1;

  permissions.map((permission) => {
    switch (permission) {
      case `user_profile_profile_edit_${relationship}`: {
        const blockDisabled =
          disabled ||
          blockData.user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('profile') > -1;

        result.push(
          <AccountOwner
            user={blockData.user}
            form={blockData.form}
            header="BASIC_INFORMATION"
            bio={isAssessor}
            disabled={blockDisabled}
            withIdentifyingNumber={
              isParticipant &&
              permissions.find((p) => p.indexOf('user_profile_eid_edit') > -1)
            }
          />
        );
        break;
      }
      case `user_profile_modules_edit_${relationship}`:
        modulesBlock = true;
        break;
      case `user_profile_timezone_language_edit_${relationship}`: {
        const blockDisabled =
          disabled ||
          disabledAssessorBlock ||
          blockData.user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('timezone_language') > -1;

        result.push(
          <TimeAndLanguage
            form={blockData.form}
            disabled={
              blockDisabled ||
              (blockData.user.has('is_timezone_editable') &&
                !blockData.user.get('is_timezone_editable'))
            }
          />
        );
        break;
      }
      case `user_profile_notifications_edit_${relationship}`: {
        const blockDisabled =
          disabled ||
          blockData.user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('notifications') > -1;

        result.push(
          <NotificationBlock
            form={blockData.form}
            disabled={blockDisabled}
            selectedRole={blockData.selectedRole}
          />
        );
        break;
      }
      case `user_profile_preferred_schedule_edit_${relationship}`:
        if (isAssessor) {
          result.push(
            <PreferredSchedule
              form={blockData.form}
              preferredTimesError={blockData.preferredTimesError}
              disabled={
                disabled ||
                disabledAssessorBlock ||
                (blockData.loggedUser &&
                  blockData.loggedUser.get('id') !== blockData.user.get('id'))
              }
            />
          );
        }
        break;
      case `user_profile_status_edit_${relationship}`: {
        const blockDisabled =
          blockData.user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('status') > -1;
        result.push(<Status disabled={blockDisabled} />);
        break;
      }
      case `user_profile_partner_assignment_edit_${relationship}`:
        assignment.push('partner');
        break;
      case `user_profile_client_assignment_edit_${relationship}`:
        assignment.push('client');
        break;
      case `user_profile_service_table_edit_${relationship}`:
        if (isParticipant) {
          serviceTable = true;
        }
        break;
      case 'user_profile_service_table_hide_links_edit':
        serviceTableHideLinks = true;
        break;
      case `user_profile_assessor_manager_edit_${relationship}`:
        if (isAssessor) {
          result.push(
            <ModulesSimulationsLanguages
              form={blockData.form}
              hidden={[1]}
              user={blockData.user}
              disabled={
                disabled ||
                disabledAssessorBlock ||
                blockData.user
                  .getIn(['permissions_debug', 'disabled_mutual_blocks'])
                  .indexOf('modules_simulations_languages') > -1
              }
            />
          );
        }
        break;
      case `user_profile_assessor_manager_view_${relationship}`:
        if (isAssessor) {
          result.push(
            <ModulesSimulationsLanguages
              form={blockData.form}
              disabled
              hidden={[1]}
              user={blockData.user}
            />
          );
        }
        break;
      case `user_profile_reset_password_edit_${relationship}`: {
        const blockDisabled =
          disabled ||
          blockData.user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('reset_password') > -1;
        result.push(<ResetPasswordBlock disabled={blockDisabled} />);
        break;
      }
      case `user_profile_delete_user_edit_${relationship}`:
        deleteBlock = true;
        break;
      case `user_profile_tags_assignment_edit_${relationship}`:
        tagsBlock = true;
        break;
      case `user_profile_consent_view_${relationship}`:
        result.splice(
          result.length - 1,
          0,
          <MarketingConsent
            disabled={
              permissions.indexOf(`user_profile_consent_edit_${relationship}`) <
              0
            }
          />
        );
        break;
      default:
        break;
    }

    return false;
  });

  if (
    modulesBlock &&
    blockData.user.has('roles') &&
    blockData.user.get('roles').contains('participant') &&
    blockData.selectedRole === 'participant'
  ) {
    result.splice(
      1,
      0,
      <Access
        form={blockData.form}
        disabled={disabled}
        editedParticipant={blockData.user}
      />
    );
  }

  if (tagsBlock && isParticipant) {
    result.splice(
      1,
      0,
      <TagsBlock disabled={disabled} form={blockData.form} />
    );
  }

  if (assignment.length > 0) {
    const user = blockData.user;
    const loggedUser = blockData.loggedUser;

    const blockDisabled =
      disabled ||
      disabledAssessorBlock ||
      (user.get('roles').contains('participant') &&
        user.has('active_simulation')) ||
      (user.get('roles').contains('stakeholder') &&
        user.get('has_shared_report') === true) ||
      user.get('roles').size > 1 ||
      (user.has('tags') && user.get('tags').length > 0) ||
      user.get('roles').contains('assessor');

    const ssoEnabled =
      user.get('sso_enabled') &&
      // Only visible by Pinsight admins and Clients who have SSO enabled
      ((!loggedUser.get('isPartner') && !loggedUser.get('isClient')) ||
        (loggedUser.get('isClient') && loggedUser.get('sso_enabled')));

    result.splice(
      1,
      0,
      <PartnersAndClients
        disabled={blockDisabled}
        disabledPartner={
          user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('partner_assignment') < 0
        }
        disabledClient={
          user
            .getIn(['permissions_debug', 'disabled_mutual_blocks'])
            .indexOf('client_assignment') < 0
        }
        form={blockData.form}
        assignment={assignment}
        ssoEnabled={ssoEnabled}
      />
    );
  }

  if (serviceTable) {
    result.splice(1, 0, <HistoryBlock hideLinks={serviceTableHideLinks} />);
  }

  if (deleteBlock) {
    result.push(
      <DeleteBlock user={blockData.user} entity="user" userDeletion />
    );
  }

  if (
    blockData.user.has('roles') &&
    blockData.user.get('roles').contains('participant') &&
    blockData.selectedRole === 'participant' &&
    blockData.user.get('modules').contains('Development')
  ) {
    result.splice(
      result.length -
        (blockData.loggedUser.get('id') === blockData.user.get('id') ? 2 : 3),
      0,
      <NotificationPreferencesBlock
        form={blockData.form}
        disabled={blockData.loggedUser.get('id') !== blockData.user.get('id')}
      />
    );
  }

  return result;
}
