// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { submit, formValueSelector } from 'redux-form';
import classNames from 'classnames';

// Components
import { ConfirmModal } from 'components';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { AssessModalForm } from 'forms';

// Actions
import { editUser, fetchUserStageHistory } from 'actions/users';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';

const form = 'assessModalForm';

/**
 * Assess
 * @class
 */
@connect(
  (state) => ({
    allowedSimulations: state.users.get('allowedSimulations'),
    users: state.users.get('allUsers'),
    selectedSimulation: formValueSelector(form)(
      state,
      'active_simulation.simulation_id'
    ),
    selectedAssessment: formValueSelector(form)(
      state,
      'active_simulation.assessment_id'
    ),
    selectedLanguage: formValueSelector(form)(
      state,
      'active_simulation.language'
    )
  }),
  (dispatch) =>
    bindActionCreators({ submit, editUser, fetchUserStageHistory }, dispatch)
)
export default class Assess extends Component {
  // Prop types
  static propTypes = {
    allowedSimulations: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    users: PropTypes.object,
    submit: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    editUser: PropTypes.func.isRequired,
    selectedSimulation: PropTypes.string,
    selectedAssessment: PropTypes.string,
    selectedLanguage: PropTypes.bool,
    fetchUserStageHistory: PropTypes.func.isRequired
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    intl: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const {
      onClose,
      editUser,
      userId,
      users,
      fetchUserStageHistory
    } = this.props;
    const { addAlert } = this.context;
    data.invite_now = true; // eslint-disable-line

    editUser(userId, data).then((action) => {
      if (!action.error) {
        const size = users.hasIn([userId, 'stageHistory'])
          ? users.getIn([userId, 'stageHistory']).size + 1
          : 1;
        fetchUserStageHistory(userId, 0, size);
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SAVED_AND_NOTIFIED" />
        });
        onClose();
      } else if (
        action.error.body != null &&
        action.error.body.scheduled_at == null
      ) {
        addAlert({
          color: 'danger',
          content: <FormattedMessage id="ERROR" />
        });
        onClose();
      }
    });
  };

  /**
   * Submit form
   */
  submitForm = () => {
    const { submit } = this.props;
    submit(form);
  };

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { intl, addModal, removeModal } = this.context;
    const {
      allowedSimulations,
      onClose,
      users,
      userId,
      selectedSimulation,
      selectedAssessment,
      selectedLanguage
    } = this.props;
    const user = users.get(userId);
    const userImg = user.get('profile_photo')
      ? `${process.env.REACT_APP_ASSETSURL}${user.get('profile_photo')}`
      : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line
    const selectedSimulationId = selectedSimulation
      ? `${selectedSimulation}${selectedAssessment || 0}`
      : null;
    const userName =
      user.get('firstname') !== '' && user.get('surname') !== ''
        ? `${user.get('firstname')} ${user.get('surname')}`
        : intl.formatMessage({ id: 'THE_PARTICIPANT' });

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id="ASSESS" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div className={modals['user-info']}>
            <div
              className={modals['user-avatar']}
              style={{ backgroundImage: `url(${userImg})` }}
            />
            <div className={modals['user-name']}>
              <h6>{`${user.get('firstname')} ${user.get('surname')}`}</h6>
              <span className={modals['user-roles']}>{user.get('role')}</span>
            </div>
          </div>

          <div
            className={classNames(modals['modal-form'], modals['assess-form'])}
          >
            <AssessModalForm user={user} onSubmit={this.handleSubmit} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_assess"
                name="cancel_assess"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_assess"
                name="submit_assess"
                type="button"
                color="primary"
                onClick={() => {
                  addModal(
                    <ConfirmModal
                      header={<FormattedMessage id="ARE_YOU_SURE" />}
                      message={
                        <FormattedHTMLMessage
                          id={
                            selectedAssessment
                              ? 'ARE_YOU_SURE_ASSESSMENT_SINGLE_INVITE'
                              : 'ARE_YOU_SURE_DEFAULT_SINGLE_INVITE'
                          }
                          values={{
                            simulation: allowedSimulations.has(
                              selectedSimulationId
                            )
                              ? allowedSimulations.getIn([
                                  selectedSimulationId,
                                  'name'
                                ])
                              : null,
                            name: userName
                          }}
                        />
                      }
                      submitAction={() => {
                        removeModal();
                        this.submitForm();
                      }}
                      onClose={removeModal}
                    />
                  );
                }}
                className={modals['footer-button']}
                disabled={
                  !selectedSimulation ||
                  !selectedLanguage ||
                  selectedLanguage === ' '
                }
              >
                <FormattedMessage id="SAVE_AND_SEND_NOTIFICATION" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
