import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  change,
  reduxForm,
  Field,
  FieldArray,
  arrayPush,
  formValueSelector
} from 'redux-form';

import { InputGroup, Label, Row } from 'reactstrap';
import { ToolTip } from 'components';
import styles from '../../styles.css';

import { validate } from './validate';

import { getShareableStakeholdersUsingFormValues } from 'actions/analytics';
import {
  getShareableStakeholdersUsingSelectedValue,
  getSelectedStakeholdersUsingSelectedValue
} from 'actions/users';
import { renderInput } from '../../actions';
import { renderStakeholders } from './renderStakeholders';

export const shareReportAccessFormOption1 = 'is_enabled_self';
export const shareReportAccessFormOption2 = 'is_enabled_stakeholders';
export const shareReportAccessFormStakeholders = 'stakeholders';
export const form = 'shareReportsAccessForm';
export const getShareReportsAccessFormData = (state) => ({
  [shareReportAccessFormOption1]: formValueSelector(form)(
    state,
    shareReportAccessFormOption1
  ),
  [shareReportAccessFormOption2]: formValueSelector(form)(
    state,
    shareReportAccessFormOption2
  ),
  stakeholders: formValueSelector(form)(
    state,
    shareReportAccessFormStakeholders
  )
    ?.map((s) => s?.id)
    ?.filter((id) => id != null)
});

const ShareReportsAccessForm = (props) => {
  const {
    allowedSimulations,
    actionData,
    arrayPush,
    allStakeholders,
    change,
    count,
    disabled,
    handleSubmit,
    removeButton,
    stakeholders,
    usage,
    getShareableStakeholdersUsingFormValues,
    getShareableStakeholdersUsingSelectedValue,
    getSelectedStakeholdersUsingSelectedValue,
    partner,
    client,
    selectedAssessment,
    selectedFormSimulation,
    selectedFormAssessment,
    type,
    enabledBlock,
    editedParticipant,
    option2
  } = props;

  useEffect(() => {
    change(
      form,
      selectedAssessment
        ? `${shareReportAccessFormOption2}-${selectedAssessment}`
        : shareReportAccessFormOption2,
      true
    );

    if (usage === 'invitation') {
      if (editedParticipant == null && selectedFormSimulation != null) {
        change(
          form,
          shareReportAccessFormOption1,
          allowedSimulations
            ?.get(`${selectedFormSimulation}${selectedFormAssessment}`)
            ?.get('share_result_by_default')
        );
      }
      getShareableStakeholdersUsingFormValues(partner, client);
    }

    if (usage === 'massAction') {
      if (type === 'share') {
        change(form, shareReportAccessFormOption1, false);

        getShareableStakeholdersUsingSelectedValue(actionData);
      }

      if (type === 'remove') {
        getSelectedStakeholdersUsingSelectedValue(actionData).then((action) => {
          const { sharing } = action.result.body;

          sharing.stakeholders?.map(({ id }) =>
            arrayPush(form, shareReportAccessFormStakeholders, {
              id
            })
          );
        });
      }
    }

    if (stakeholders == null && type !== 'remove') {
      arrayPush(
        form,
        selectedAssessment
          ? `${shareReportAccessFormStakeholders}-${selectedAssessment}`
          : shareReportAccessFormStakeholders,
        {}
      );
    }
  }, [
    partner,
    client,
    selectedAssessment,
    selectedFormSimulation,
    selectedFormAssessment
  ]);

  return (
    <form
      style={editedParticipant ? { marginTop: 15 } : {}}
      className={classNames(styles.form, styles['share-reports'])}
      onSubmit={handleSubmit}
    >
      <InputGroup key="option-1" className={styles.option}>
        <Label check>
          <FormattedMessage
            id="SHARE_REPORTS_ACCESS.OPTION_X"
            values={{ value: 1 }}
          />
          .{' '}
          <Field
            name={
              selectedAssessment
                ? `${shareReportAccessFormOption1}-${selectedAssessment}`
                : shareReportAccessFormOption1
            }
            component={renderInput}
            type="checkbox"
            disabled={disabled}
          />{' '}
          <FormattedMessage
            id={
              usage === 'massAction' && type === 'remove'
                ? 'REMOVE_RESULTS_ACCESS.OPTION_1'
                : usage === 'invitation'
                ? 'SHARE_REPORTS_ACCESS.OPTION_1.INVITATION'
                : 'SHARE_REPORTS_ACCESS.OPTION_1'
            }
            values={{ count, value: usage === 'massAction' ? count : '' }}
          />
        </Label>
      </InputGroup>
      <InputGroup key="option-2" className={styles.option}>
        <Label check style={{ cursor: 'initial' }}>
          <FormattedMessage
            id="SHARE_REPORTS_ACCESS.OPTION_X"
            values={{ value: 2 }}
          />
          .
          <span
            style={{
              display: 'inline-block',
              marginLeft: 27,
              verticalAlign: 'top',
              width: '80%'
            }}
          >
            <FormattedMessage
              id={
                usage === 'massAction' && type === 'remove'
                  ? 'REMOVE_RESULTS_ACCESS.OPTION_2'
                  : 'SHARE_REPORTS_ACCESS.OPTION_2'
              }
            />
            <ToolTip
              id="stakeholders-access-hint"
              index="stakeholders-access-hint"
              hint={
                <FormattedMessage
                  id={
                    usage === 'massAction'
                      ? type === 'share'
                        ? 'SHARE_RESULTS_ACCESS.JIT'
                        : 'REMOVE_RESULTS_ACCESS.JIT'
                      : 'SHARE_REPORTS_ACCESS.OPTION_2.JIT'
                  }
                />
              }
            />
          </span>
        </Label>

        <Row>
          <FieldArray
            name={
              selectedAssessment
                ? `${shareReportAccessFormStakeholders}-${selectedAssessment}`
                : shareReportAccessFormStakeholders
            }
            helpers={{
              stakeholders,
              allStakeholders,
              form,
              removeButton,
              disabled: disabled || !option2,
              enabledBlock,
              usage,
              type
            }}
            component={renderStakeholders}
          />
        </Row>
      </InputGroup>
    </form>
  );
};

ShareReportsAccessForm.defaultProps = {
  count: 2,
  removeButton: 'button',
  usage: 'massAction',
  enabledBlock: false
};

ShareReportsAccessForm.propTypes = {
  allowedSimulations: PropTypes.object,
  actionData: PropTypes.object,
  change: PropTypes.func.isRequired,
  count: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  arrayPush: PropTypes.func.isRequired,
  allStakeholders: PropTypes.array,
  stakeholders: PropTypes.array,
  removeButton: PropTypes.oneOfType(['x', 'button']),
  usage: PropTypes.oneOfType(['massAction', 'invitation']),
  parentForm: PropTypes.string,
  partner: PropTypes.number,
  client: PropTypes.number,
  type: PropTypes.oneOfType(['share', 'remove']),
  getShareableStakeholdersUsingFormValues: PropTypes.func.isRequired,
  getShareableStakeholdersUsingSelectedValue: PropTypes.func.isRequired,
  getSelectedStakeholdersUsingSelectedValue: PropTypes.func.isRequired,
  enabledBlock: PropTypes.bool,
  option2: PropTypes.bool,
  selectedAssessment: PropTypes.number,
  selectedFormSimulation: PropTypes.number,
  selectedFormAssessment: PropTypes.number,
  editedParticipant: PropTypes.object
};

export default reduxForm({
  form,
  validate
})(
  connect(
    (state, ownProps) => ({
      allowedSimulations: state.users.get('allowedSimulations'),
      partner:
        ownProps.usage === 'massAction'
          ? null
          : Number(formValueSelector(ownProps.parentForm)(state, 'partner_id')),
      client:
        ownProps.usage === 'massAction'
          ? null
          : Number(formValueSelector(ownProps.parentForm)(state, 'client_id')),
      selectedAssessment:
        ownProps.usage === 'massAction'
          ? null
          : Number(
              formValueSelector(ownProps.parentForm)(
                state,
                'share_reports_access_assessment'
              )
            ),
      selectedFormSimulation:
        ownProps.usage === 'invitation'
          ? formValueSelector(ownProps.parentForm)(
              state,
              'active_simulation.simulation_id'
            )
          : null,
      selectedFormAssessment:
        ownProps.usage === 'invitation'
          ? Number(
              formValueSelector(ownProps.parentForm)(
                state,
                'active_simulation.assessment_id'
              )
            ) || 0
          : null,
      allStakeholders: (() => {
        let selectedStakeholders = [];

        if (ownProps.editedParticipant) {
          selectedStakeholders = selectedStakeholders.concat(
            ownProps.editedParticipant
              .getIn(['active_simulation', 'sharing', 'stakeholders'])
              ?.toArray()
          );

          ownProps.editedParticipant
            ?.get('past_simulations')
            ?.forEach(
              (simulation) =>
                (selectedStakeholders = selectedStakeholders.concat(
                  simulation.getIn(['sharing', 'stakeholders'])
                ))
            );
        }

        return state.analytics
          ?.get('shareableStakeholders')
          ?.toArray()
          ?.concat(selectedStakeholders);
      })(),
      stakeholders: formValueSelector(form)(
        state,
        ownProps.usage === 'invitation' &&
          Number(
            formValueSelector(ownProps.parentForm)(
              state,
              'share_reports_access_assessment'
            )
          )
          ? `${shareReportAccessFormStakeholders}-${Number(
              formValueSelector(ownProps.parentForm)(
                state,
                'share_reports_access_assessment'
              )
            )}`
          : shareReportAccessFormStakeholders
      ),
      option2: formValueSelector(form)(
        state,
        ownProps.usage === 'invitation' &&
          Number(
            formValueSelector(ownProps.parentForm)(
              state,
              'share_reports_access_assessment'
            )
          )
          ? `${shareReportAccessFormOption2}-${Number(
              formValueSelector(ownProps.parentForm)(
                state,
                'share_reports_access_assessment'
              )
            )}`
          : shareReportAccessFormOption2
      ),
      enabledBlock: state.analytics.get('enabled')
    }),
    (dispatch) =>
      bindActionCreators(
        {
          arrayPush,
          change,
          getShareableStakeholdersUsingFormValues,
          getShareableStakeholdersUsingSelectedValue,
          getSelectedStakeholdersUsingSelectedValue
        },
        dispatch
      )
  )(ShareReportsAccessForm)
);
