import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ITCheck as ITCheckComponent } from '../Auth/Invitation/steps';

@connect((state) => ({
  route: state.router.route
}))
export default class ITCheck extends React.Component {
  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  // Prop types
  static propTypes = {
    route: PropTypes.object.isRequired
  };

  state = {
    finished: false
  };

  /**
   * Redirect
   * @returns {string}
   */
  redirect = () => {
    const { route } = this.props;
    return (window.location =
      route.query && route.query.url ? route.query.url : '/');
  };

  /**
   * Render
   * @returns {*}
   */
  render() {
    const { addAlert } = this.context;

    if (this.state.finished) {
      return false;
    }

    return (
      <ITCheckComponent
        onFinish={() => {
          addAlert({
            color: 'success',
            content: <FormattedMessage id="IT_CHECK_SUCCESSFULLY_DONE" />,
            stay: true
          });

          this.setState({ finished: true });
        }}
      />
    );
  }
}
