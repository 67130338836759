import { SET_USERS_DATA_FROM_CACHE } from 'constants/actionTypes';

/**
 * Set users from cache action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function setUsersDataFromCache(data) {
  return {
    type: SET_USERS_DATA_FROM_CACHE,
    data
  };
}
