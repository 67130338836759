import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from './Box';
import ConfirmModal from './ConfirmModal';
import CongratulationsModal from './CongratulationsModal';
import CompletedMessage from '../CompletedMessage';

import styles from './styles.css';
import './styles.css';
import priotityStyles from '../MyPriorities/styles.css';
import '../MyPriorities/styles.css';

import { setWeeklyFocus } from 'actions/development';
import { updateUserStage } from 'actions/users';
import { getMe } from 'actions/auth';

@connect(
  (state) => ({
    focuses: state.development.get('focuses'),
    improvement: state.development.get('improvement'),
    user: state.auth.get('loggedUser'),
    focusesFailure: state.development.get('focusesFailure')
  }),
  (dispatch) => {
    const transitionTo = actions.transitionTo;
    return bindActionCreators(
      { transitionTo, getMe, updateUserStage, setWeeklyFocus },
      dispatch
    );
  }
)
export default class ChooseFocus extends Component {
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    focuses: PropTypes.object.isRequired,
    setWeeklyFocus: PropTypes.func.isRequired,
    improvement: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updateUserStage: PropTypes.func.isRequired,
    getMe: PropTypes.func.isRequired
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired,
    changeHeading: PropTypes.func.isRequired
  };

  constructor(...args) {
    super(...args);
    const { improvement } = this.props;

    this.state = {
      showModal: false,
      showCongratulations: improvement.has('last_focus')
        ? improvement.get('last_focus')
        : false
    };
  }

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.context.changeHeading(
        this.context.intl.formatMessage({ id: 'CHOOSE_WEEKLY_FOCUS' })
      );
    }, 50);
  }

  componentDidMount() {
    const { user, updateUserStage, getMe } = this.props;

    if (
      user.has('stages') &&
      user.getIn(['stages', 'development1', 'completed']) === false
    ) {
      updateUserStage(user.get('id'), {
        stage: 'development1',
        completed: true
      }).then(() => {
        getMe();
      });
    }
  }

  render() {
    const {
      transitionTo,
      focuses,
      setWeeklyFocus,
      focusesFailure
    } = this.props;
    const { showModal, showCongratulations } = this.state;

    if (focuses.size === 0 && !focusesFailure) {
      return <CompletedMessage className={styles['margin-top']} />;
    }

    return (
      <div className={styles['choose-focus']}>
        <p className={styles.centered}>
          <FormattedHTMLMessage id="DEVELOPMENT_FOCUS_INFO" />
        </p>

        <button
          className={classNames(
            styles['set-priority-button'],
            priotityStyles['priorities-button']
          )}
          onClick={() => transitionTo('/development/my-priorities')}
        >
          <FormattedMessage id="SET_SKILL_PRIORITIES" />
        </button>

        {focuses.toArray().map((focus, i) => (
          <Box
            key={i}
            focus={focus}
            onClick={() =>
              this.setState({ showModal: focus.getIn(['indicator', 'id']) })
            }
          />
        ))}

        <ConfirmModal
          isOpen={showModal}
          onClose={() => this.setState({ showModal: false })}
          onConfirm={() => {
            const focus = focuses.get(showModal);

            setWeeklyFocus({
              behavior_id: focus.getIn(['behavior', 'id']),
              indicator_id: focus.getIn(['indicator', 'id'])
            }).then((action) => {
              if (!action.error) {
                transitionTo('/development/app');
              }
            });
          }}
        />
        <CongratulationsModal
          isOpen={showCongratulations}
          onClose={() => this.setState({ showCongratulations: false })}
          onConfirm={() => {
            const focus = showCongratulations;

            setWeeklyFocus({
              behavior_id: focus.getIn(['behavior', 'id']),
              indicator_id: focus.getIn(['indicator', 'id'])
            }).then((action) => {
              if (!action.error) {
                transitionTo('/development/app');
              }
            });
          }}
        />
      </div>
    );
  }
}
