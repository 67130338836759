// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

// Styles
import modals from '../../../../../../../modals.css';
import '../../../../../../../modals.css';
import styles from './styles.css';
import './styles.css';

/**
 * Ember preview
 * @param props
 * @param context
 * @returns {XML}
 * @constructor
 */
const EmbedPreview = (props, context) => {
  const { removeModal } = context;
  const { src } = props;

  return (
    <div>
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id="VIEW_VOICEMAIL" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video className={styles.embed} controls controlsList="nodownload">
            <source src={src} />
          </video>
        </ModalBody>

        <ModalFooter>
          <Row>
            <Col
              xs={12}
              className={modals['modal-button-col']}
              style={{ paddingRight: '15px' }}
            >
              <Button
                id="close_embed_modal"
                name="close_embed_modal"
                color="primary"
                onClick={removeModal}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CLOSE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

// Prop types
EmbedPreview.propTypes = {
  src: PropTypes.string.isRequired
};

// Context types
EmbedPreview.contextTypes = {
  addAlert: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired
};

export default EmbedPreview;
