// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';
import modals from '../../../../../modals.css';
import '../../../../../modals.css';
import { UserBox } from '..';

// Styles

/**
 * Remove modal
 * @class
 */
const RemoveModal = (props, context) => {
  const { submitAction, participants } = props;
  const { removeModal } = context;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id="REMOVE_PARTICIPANTS_REPORT" />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <div className="text-center">
          <FormattedMessage id="REMOVE_PARTICIPANTS_MODAL_INFO" />
          {participants[0].get('is_sharing_enabled') &&
            participants[0].get('shared') &&
            participants[0].get('shared').size > 0 && (
              <p>
                <strong>
                  (
                  <FormattedMessage
                    id="ALSO_SHARED_WITH"
                    values={{
                      names: participants[0]
                        .get('shared')
                        .map(
                          (a) =>
                            `${a.getIn(['user', 'firstname'])} ${a.getIn([
                              'user',
                              'surname'
                            ])}`
                        )
                        .join(', ')
                    }}
                  />
                  )
                </strong>
              </p>
            )}

          <div style={{ marginTop: 25 }}>
            {participants
              .filter((p) => p.get('is_deleteable'))
              .map((p, i) => (
                <UserBox key={i} report={p} user={p.get('user')} />
              ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={5} className={modals['modal-button-col']}>
            <Button
              id="cancel_remove_report_modal"
              name="cancel_remove_report_modal"
              color="secondary"
              onClick={removeModal}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CANCEL" />
            </Button>{' '}
          </Col>
          <Col xs={7} className={modals['modal-button-col']}>
            <Button
              id="submit_remove_report_modal"
              name="submit_remove_report_modal"
              color="danger"
              onClick={submitAction}
              className={modals['footer-button']}
            >
              <FormattedMessage id="YES_REMOVE_REPORT" />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

// Props
RemoveModal.propTypes = {
  submitAction: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired
};

// Context
RemoveModal.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

export default RemoveModal;
