// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import { ProgressCircle } from '..';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Congratulations modal
 * @param props
 * @returns {XML}
 * @constructor
 */
const CongratulationsModal = (props) => {
  const { isOpen, onClose, onConfirm, focuses } = props;

  const current = isOpen;
  const allowContinue =
    current &&
    focuses.find(
      (f) => f.getIn(['indicator', 'id']) === current.getIn(['indicator', 'id'])
    );
  const missedDays =
    current && !current.get('exercises').find((e) => e.get('is_tracked'));

  return (
    <div
      className={classNames(styles.modal, isOpen && styles.open)}
      key="congratulations-modal"
    >
      <div className={styles.modal__box}>
        <button
          id="close_button"
          name="close_button"
          className={styles['close-button']}
          onClick={onClose}
          aria-label="Close window"
        />

        {!missedDays && (
          <h6 className={styles.gratz}>
            <strong>
              <FormattedMessage id="CONGRATULATIONS" />!
            </strong>
          </h6>
        )}
        <ProgressCircle
          defaultColor
          block
          value={
            current
              ? current.has('tbis_est_perc') &&
                current.get('tbis_est_perc') !== 0
                ? current.get('tbis_est_perc')
                : current.get('tbis_perc')
              : null
          }
          focus={current}
        />

        {current ? (
          <h6>
            <strong>
              <span>
                {current.getIn(['behavior', 'name'])} -{' '}
                <FormattedMessage id="EXERCISE" /> {current.get('focus_index')}
              </span>
            </strong>
          </h6>
        ) : null}

        {allowContinue ? (
          <p>
            <small>
              ({current ? Math.round(current.get('dli')) || 0 : 0}{' '}
              <FormattedMessage id="DAYS_REMAINING" />)
            </small>
          </p>
        ) : (
          <br />
        )}

        <p>
          <FormattedMessage
            id={
              allowContinue
                ? missedDays
                  ? 'MISSED_DAYS_MODAL_INFO'
                  : 'CONGRATULATIONS_MODAL_INFO'
                : 'CONGRATULATIONS_FINAL_MODAL_INFO'
            }
          />
        </p>

        <button
          id="review_options_button"
          name="review_options_button"
          className={styles.white}
          onClick={onClose}
        >
          &larr; <FormattedMessage id="REVIEW_OPTIONS" />
        </button>
        {allowContinue && (
          <button
            id="continue_button"
            name="continue_button"
            className={styles.green}
            onClick={onConfirm}
          >
            <FormattedMessage id="CONTINUE" />
          </button>
        )}
      </div>
    </div>
  );
};

// Prop types
CongratulationsModal.propTypes = {
  isOpen: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  focuses: PropTypes.object.isRequired
};

export default connect((state) => ({
  focuses: state.development.get('focuses')
}))(CongratulationsModal);
