import {
  FETCH_BEHAVIORS_REQUEST,
  FETCH_BEHAVIORS_SUCCESS,
  FETCH_BEHAVIORS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch behaviors action
 *
 * @param {null | boolean} [published=null] If param is not set (**null**), then all skills are returned.
 * A value of **true** returns only *published* skills, and a value of **false** returns *unpublished* skills.
 *
 * @param {null | number} [simulationId=null] If the parameter is (**null**), then it is not set in request.
 * A value of **number** returns behaviors for the specific simulation which can be "*pinsight*" or "*own*".
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchBehaviors(
  published = null,
  simulationId = null,
  offset = 0,
  limit = 0,
  query = '',
  language = 'english'
) {
  const string = `?q=${encodeURIComponent(
    query
  )}&offset=${offset}&limit=${limit}&language=${language}${
    published !== null ? `&published=${Number(published)}` : ''
  }&fields=id,name,definition,indicators,developability,language,languages${
    simulationId !== null ? `&simulationId=${simulationId}` : ''
  }`; // eslint-disable-line
  return {
    types: [
      FETCH_BEHAVIORS_REQUEST,
      FETCH_BEHAVIORS_SUCCESS,
      FETCH_BEHAVIORS_FAILURE
    ],
    promise: api().get(`/api/v1/behaviors/${string}`),
    language: language || 'english'
  };
}
