// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Modal system
 * @class
 */
@connect(null, null, null, { withRef: true })
export default class ModalSystem extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   * @param context
   */
  constructor(props, context) {
    super(props, context);
    this.state = {
      modals: [],
      draggable: false
    };
  }

  /**
   * Add modal
   * @param modal
   * @param draggable
   */
  addModal(modal, draggable) {
    this.setState({
      modals: this.state.modals.concat([modal])
    });
    this.setState({
      draggable
    });
  }

  /**
   * Remove modal
   */
  removeModal() {
    const { dispatch } = this.props;
    const { modals } = this.state;
    modals.pop();
    this.setState({ modals });
    dispatch({ type: 'RESET_FORM_ERRORS' });
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { modals } = this.state;
    return (
      <div>
        {modals.map((modal, i) => (
          <span
            key={i}
            style={{
              position: !this.state.draggable ? 'relative' : 'inherit',
              zIndex: `'{${999 + i}}'`
            }}
          >
            {modal}
          </span>
        ))}
      </div>
    );
  }
}
