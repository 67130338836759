// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import { Col, Row } from 'reactstrap';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * My improvement
 * @param props
 * @constructor
 */
const MyImprovement = (props) => (
  <div className={styles.improvement}>
    <Row>
      <Col xs="6">
        <h6>
          <FormattedMessage id="OVERALL_IMPROVEMENT" />
        </h6>
        <div className={styles.box}>
          <span className={styles.green}>
            +{parseFloat(props.improvement.get('total_improvement')).toFixed(2)}
            %
          </span>
        </div>
      </Col>
      <Col xs="6">
        <h6>
          <FormattedMessage id="DAYS_PRACTICED" />
        </h6>
        <div className={styles.box}>
          {props.improvement.get('total_days_practised')}
        </div>
      </Col>
    </Row>

    <div className={styles.preview}>
      {props.improvement.get('focuses').map((focus, index) => (
        <div className={styles.preview__block} key={index}>
          <Row>
            <Col xs="4" sm="6">
              <h4>
                <span className={styles.green}>
                  +{parseFloat(focus.get('i_perc')).toFixed(2)}%
                </span>
              </h4>
            </Col>
            <Col xs="8" sm="6">
              <h5>{focus.getIn(['behavior', 'name'])}</h5>
              <p>
                <strong>{focus.get('days_practiced')}</strong>{' '}
                <FormattedMessage id="DAYS_PRACTICED" />
              </p>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  </div>
);

// Prop types
MyImprovement.propTypes = {
  improvement: PropTypes.object.isRequired
};

export default connect((state) => ({
  improvement: state.development.get('improvement')
}))(MyImprovement);
