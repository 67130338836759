// Components
import { Analytics } from 'views';

// Actions
import { actions } from 'routex';
import { fetchAnalyticsOverview } from 'actions/analytics';

const AnalyticsRoutes = [
  {
    path: '/',
    component: Analytics.Preview,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();

      return new Promise((resolve) => {
        if (state.analytics.get('overviewData').size === 0) {
          return dispatch(fetchAnalyticsOverview()).then((action) => {
            if (action.result.body.length > 0) {
              if (
                state.auth.hasIn(['loggedUser', 'roles']) &&
                state.auth
                  .getIn(['loggedUser', 'roles'])
                  .indexOf('participant') > -1 &&
                action.result.body.length === 0
              ) {
                dispatch(actions.transitionTo('/dashboard'));
              }

              return resolve();
            }

            return resolve();
          });
        }

        return resolve();
      });
    }
  }
];

export default AnalyticsRoutes;
