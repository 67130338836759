import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BuildTable } from 'components';
import { TranslationRow, UploadRow } from 'forms/components';

import {
  instructions as instructionsAction,
  fetchSimulation
} from 'actions/simulations';
import { uploadFile } from 'actions/global';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';
import { FormattedMessage } from 'react-intl';

@connect(
  (state, ownProps) => ({
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-instructions'])
      ? state.app.getIn(['codeList', 'allowed-ext-instructions']).toArray()
      : [],
    id: Number(state.router.route.vars.id),
    mainSimulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    mainDebrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ]),
    simulation: state.simulations.hasIn([
      'simulations',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.simulations.getIn([
          'simulations',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.simulations.getIn([
          'simulations',
          'english',
          Number(state.router.route.vars.id)
        ]),
    debrief: state.debriefs.hasIn([
      'debriefs',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.debriefs.getIn([
          'debriefs',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.debriefs.getIn([
          'debriefs',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators(
      { instructionsAction, fetchSimulation, uploadFile, dispatch },
      dispatch
    )
)
export default class InstructionsTranslateTable extends Component {
  static propTypes = {
    allowedTypes: PropTypes.array.isRequired,
    id: PropTypes.number,
    simulation: PropTypes.object,
    mainSimulation: PropTypes.object.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    instructionsAction: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    uploadFile: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    debrief: PropTypes.object,
    mainDebrief: PropTypes.object.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  getTable = (type) => {
    const {
      simulation,
      mainSimulation,
      version,
      hasError,
      disabled,
      debrief,
      mainDebrief
    } = this.props;

    const mainSessionData = mainSimulation || mainDebrief;
    const session = simulation || debrief;

    return {
      data: mainSessionData
        .get('documents')
        .filter((d) => d.get('type') === type)
        .toArray(),
      customClassName: 'trans-3-table-equal',
      fields: [
        {
          key: 'original_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.get('name')
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let document = mainSessionData.get('documents');
            let defaultValue = null;

            // If we have language translation, choose it
            if (session.has('documents')) {
              document = session
                .get('documents')
                .find((d) => d.get('id') === item.get('id'));

              if (document) {
                defaultValue = document.get('name') || null;
              }
            }

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={item.get('id')}
                  defaultValue={defaultValue}
                  onSubmit={(data) => this.handleSubmit(data, item)}
                  disabled={disabled}
                />
              </td>
            );
          }
        },
        {
          key: 'actions',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            const document = session.hasIn([
              'documents',
              item.get('id'),
              'path'
            ])
              ? session.getIn(['documents', item.get('id')])
              : null;
            const simulationVersion = session
              .get('languages')
              .find((l) => l.get('language') === version);

            let doc = mainSessionData.get('documents');
            let translateName = false;

            // If we have language translation, choose it
            if (session.has('documents')) {
              doc = session
                .get('documents')
                .find((d) => d.get('id') === item.get('id'));

              if (!doc) {
                translateName = true;
              }
            }

            return (
              <td
                className={classNames(hasError && !document && styles.error)}
                id={`upload_file_${version}_${item.get('name')}`}
              >
                <UploadRow
                  version={version}
                  data={document}
                  type={type}
                  onDrop={(data) =>
                    this.handleChange(data, item, translateName)
                  }
                  action={instructionsAction}
                  simulation={mainSessionData}
                  disabled={disabled}
                  wasPublished={simulationVersion.get('was_published')}
                  isDebrief={Boolean(debrief)}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  handleChange = (files, item, translateName) => {
    const { addAlert, intl } = this.context;
    const { allowedTypes, uploadFile, dispatch } = this.props;

    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      const types = allowedTypes.map((t) => t.get('name'));
      let typesAfter = '';

      if (types.length > 1) {
        types.pop();
        typesAfter = ` ${intl.formatMessage({
          id: 'AND'
        })} ${allowedTypes[allowedTypes.length - 1].get('name')}`;
      }

      window.scrollTo(0, 0);
      addAlert({
        color: 'danger',
        content: (
          <FormattedMessage
            id="ERRORS.FILE_TYPE_NOT_ALLOWED"
            values={{
              allowed_types: `${types.join(', ')}${typesAfter}.`
            }}
          />
        )
      });
    } else {
      uploadFile(files[0], dispatch, 'instructions').then((action) => {
        if (!action.error) {
          this.handleSubmit(null, item, {
            id: action.result.body.uniqid,
            name: files[0].name,
            translateName
          });
        } else {
          const error = action.error.body;

          if (typeof error === 'string') {
            addAlert({
              color: 'danger',
              content: intl.formatMessage({ id: error })
            });
          } else {
            addAlert({
              color: 'danger',
              content: <FormattedMessage id={error.message} values={error} />
            });
          }

          window.scrollTo(0, 0);
        }
      });
    }
  };

  handleSubmit = (data, document, file) => {
    const {
      id,
      instructionsAction,
      version,
      fetchSimulation,
      debrief
    } = this.props;

    const { addAlert, intl } = this.context;
    let translated;

    if (data) {
      translated = {
        name: data.translation,
        language: version
      };
    } else {
      translated = {
        file_id: file.id,
        file_name: file.name,
        language: version
      };

      if (file.translateName) {
        translated.name = file.name.replace(/\..+$/, '');
      }
    }

    instructionsAction(
      {
        type: 'edit',
        simulationId: id,
        id: document.get('id'),
        data: translated,
        isDebrief: Boolean(debrief)
      },
      document.get('type')
    ).then((action) => {
      if (
        action.error &&
        action.error.body &&
        typeof action.error.body === 'string'
      ) {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });
        fetchSimulation(id, Boolean(debrief));
      }
    });
  };

  render() {
    const { type } = this.props;

    return (
      <div>
        <BuildTable table={this.getTable(type)} />
      </div>
    );
  }
}
