import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { change, reduxForm, Field, formValueSelector } from 'redux-form';

import { Button, Table, Row, Col } from 'reactstrap';

import { getMe, updateAnswer } from 'actions/auth';
import { updateUserStage } from 'actions/users';
import { renderInput } from '../../actions';
import { validate } from './validate';

import styles from '../../styles.css';

const form = 'questionnaireForm';
const answers = [
  'very_inaccurate',
  'moderately_inaccurate',
  'neutral',
  'moderately_accurate',
  'very_accurate'
];

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    isLoading: state.auth.get('questionsLoading'),
    questions: state.auth.has('questions')
      ? state.auth.get('questions').toArray()
      : [],
    userAnswers: formValueSelector(form)(state, 'answers') || [],
    test: state.auth.get('test'),
    answerUpdateLoading: state.auth.get('answerUpdateLoading')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { change, getMe, transitionTo, updateAnswer, updateUserStage },
      dispatch
    );
  }
)
class QuestionnaireForm extends Component {
  static propTypes = {
    getMe: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    valid: PropTypes.bool,
    isLoading: PropTypes.bool,
    questions: PropTypes.object,
    userAnswers: PropTypes.array,
    updateAnswer: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    test: PropTypes.object,
    change: PropTypes.func.isRequired,
    updateUserStage: PropTypes.func.isRequired,
    answerUpdateLoading: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired
  };

  state = {
    submited: false
  };

  componentDidMount() {
    const {
      user,
      test,
      change,
      questions,
      setLoading,
      transitionTo
    } = this.props;

    test
      .get('answers')
      .map((answer) =>
        change(
          form,
          `answers[${answer.getIn(['question', 'id'])}]`,
          answer.get('answer').get('id').toString()
        )
      );

    setLoading(true);
    this.modalTimeout = setTimeout(() => {
      let disabled = this.props.userAnswers.length < questions.length;
      for (let i = 0; i < this.props.userAnswers.length; i++) {
        if (typeof this.props.userAnswers[i] === 'undefined' && i !== 0) {
          disabled = true;
        }
      }

      setLoading(false);

      if (
        !disabled &&
        user.getIn(['stages', 'pre-work1', 'completed']) === true &&
        user.getIn(['stages', 'pre-work2', 'completed']) === false
      ) {
        transitionTo('/auth/invite/prework/lat');
      }
    }, 2000);

    const position = this.heading.getBoundingClientRect();
    this.fixed_heading.style.width = position.width;
    this.fixed_heading.style.left = position.left;
    for (let i = 0; i <= 5; i++) {
      const item = this.refs[`th_${i}`].getBoundingClientRect();
      this.refs[`fixed_th_${i}`].width = item.width;
    }
    window.addEventListener('scroll', this.checkScroll, false);
  }

  componentWillUnmount() {
    clearTimeout(this.modalTimeout);
    window.removeEventListener('scroll', this.checkScroll, false);
  }

  checkScroll = () => {
    const position = this.heading.getBoundingClientRect();

    if (position.top < 0) {
      this.fixed_heading.style.display = 'block';
    } else {
      this.fixed_heading.style.display = 'none';
    }
  };

  redirect = () => {
    const {
      questions,
      userAnswers,
      answerUpdateLoading,
      transitionTo
    } = this.props;

    let disabled = userAnswers.length < questions.length;

    for (let i = 1; i <= questions.length; i++) {
      if (typeof userAnswers[i] === 'undefined') {
        disabled = true;
      }
    }

    this.setState({ submited: true });

    if (disabled || answerUpdateLoading) {
      for (let i = 0; i < questions.length; i++) {
        if (this.refs[`missing${i}`]) {
          const row = this.refs[`missing${i}`];
          return window.scrollTo(0, row.offsetTop - 80);
        }
      }

      return false;
    }

    return transitionTo('/auth/invite/prework/lat');
  };

  render() {
    const { submited } = this.state;
    const { addAlert } = this.context;
    const {
      change,
      isLoading,
      questions,
      userAnswers,
      updateAnswer,
      user,
      intl
    } = this.props;

    return (
      <div>
        <form className={styles.form}>
          <Table className={styles['questionaire-table']} striped>
            <thead ref={(h) => (this.heading = h)}>
              <tr>
                <td
                  ref="th_0"
                  style={{
                    border: 0,
                    borderBottom: '1px solid var(--color--grey-light)'
                  }}
                />
                {answers.map((a, i) => (
                  <th key={i} ref={`th_${i + 1}`}>
                    <FormattedMessage id={`ANSWERS.${a}`} />
                  </th>
                ))}
              </tr>
            </thead>
            <thead
              ref={(h) => (this.fixed_heading = h)}
              style={{
                position: 'fixed',
                backgroundColor: '#fff',
                zIndex: 999,
                top: 0,
                display: 'none'
              }}
            >
              <tr>
                <td
                  ref="fixed_th_0"
                  style={{
                    border: 0,
                    borderBottom: '1px solid var(--color--grey-light)'
                  }}
                />
                {answers.map((a, i) => (
                  <th key={i} ref={`fixed_th_${i + 1}`}>
                    <FormattedMessage id={`ANSWERS.${a}`} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {questions
                .sort((a, b) => {
                  if (a.get('order') > b.get('order')) {
                    return 1;
                  }
                  if (a.get('order') < b.get('order')) {
                    return -1;
                  }

                  return 0;
                })
                .map((question, i) => (
                  <tr
                    key={i}
                    ref={
                      userAnswers[question.get('id')]
                        ? `done${i}`
                        : `missing${i}`
                    }
                    className={classNames(
                      submited &&
                        !userAnswers[question.get('id')] &&
                        styles.incomplete
                    )}
                  >
                    <td>
                      {i + 1}
                      .
                      <FormattedMessage id={question.get('text')} />
                    </td>
                    <td colSpan={5}>
                      <div style={{ display: 'flex' }}>
                        {question.get('answers').map((answer, j) => (
                          <Field
                            id={`answers[${question.get('id')}-${j}]`}
                            style={{ marginBottom: '10px' }}
                            aria-label={`${intl.formatMessage({
                              id: question.get('text')
                            })} - ${intl.formatMessage({
                              id: `ANSWERS.${answers[j]}`
                            })}`}
                            name={`answers[${question.get('id')}]`}
                            component={renderInput}
                            value={answer.get('id').toString()}
                            type="radio"
                            onChange={(e) => {
                              e.preventDefault();
                              return updateAnswer(user.get('id'), {
                                question_id: question.get('id'),
                                answer_id: answer.get('id')
                              }).then((action) => {
                                if (!action.error) {
                                  change(
                                    form,
                                    `answers[${question.get('id')}]`,
                                    answer.get('id').toString()
                                  );
                                } else {
                                  window.scrollTo(0, 0);
                                  addAlert({
                                    color: 'danger',
                                    content: (
                                      <FormattedMessage id="ERROR_OCCURRED" />
                                    )
                                  });
                                }
                              });
                            }}
                          />
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Row className={styles['form-buttons']}>
            <Col xs={3} className={styles['btn-col']}>
              <Button
                id="questionnaire_next_button"
                name="questionnaire_next_button"
                type="button"
                color="primary-dark"
                className={styles['submit-button']}
                onClick={this.redirect}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'NEXT'} />
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default injectIntl(QuestionnaireForm);
