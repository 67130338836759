import React from 'react';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from 'constants/cookies';

import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ResetPasswordModal } from 'forms/modals';
import { ConfirmDelete, Link, ToolTip } from 'components';
import {
  ExtendTime,
  EditAssessor
} from 'views/Users/components/UsersTable/modals';
import { EmbedPreview } from 'views/Assignments/subviews/Score/components/PreviewTable/components';
import { ManualRescheduleModal } from '../modals';

import DownloadPDFModal from '../../views/Analytics/components/DownloadPDFModal';

export default function getStageActions(
  stage,
  history,
  user,
  actions,
  permissions
) {
  let stageActions = [];

  if (stage.has('actions')) {
    stageActions = stage.get('actions').toArray();
  }

  function reloadData() {
    return actions.fetchUserStageHistory(user.get('id'), 0, history.size + 1);
  }

  return stageActions.map((a) => {
    switch (a) {
      case 'USERHISTORYACTION.resend_invitation':
        return (
          <Button
            id="ht_resend_invitation"
            name="ht_resend_invitation"
            disabled={user && user.get('status') === 'suspended'}
            type="button"
            color="link"
            size="sm"
            onClick={() =>
              actions.resendInvitation(user.get('id')).then((action) => {
                if (!action.error) {
                  window.scrollTo(0, 0);
                  reloadData();
                  actions.addAlert({
                    color: 'success',
                    content: <FormattedMessage id="REINVITED_TO_SUCCESSFUL" />
                  });
                }
              })
            }
          >
            <FormattedMessage id="RESEND_INVITATION_EMAIL" />
          </Button>
        );
      case 'USERHISTORYACTION.reset_password':
        return (
          <Button
            id="ht_reset_password"
            name="ht_reset_password"
            disabled={user && user.get('status') === 'suspended'}
            type="button"
            color="link"
            size="sm"
            onClick={() =>
              actions.addModal(
                <ResetPasswordModal
                  submit={() =>
                    actions.resendInvitation(user.get('id')).then((action) => {
                      if (!action.error) {
                        window.scrollTo(0, 0);
                        actions.addAlert({
                          color: 'success',
                          content: (
                            <FormattedMessage id="REINVITED_TO_SUCCESSFUL" />
                          )
                        });
                      }
                    })
                  }
                  message="RESET_ANOTHER_PASSWORD_INFO"
                />
              )
            }
          >
            <FormattedMessage id="RESET_PASSWORD" />
          </Button>
        );
      case 'USERHISTORYACTION.reschedule':
        return (
          <div>
            <Button
              id="ht_reschedule"
              name="ht_reschedule"
              disabled={user && user.get('status') === 'suspended'}
              type="button"
              color="link"
              size="sm"
              onClick={() => {
                actions.addModal(
                  <ConfirmDelete
                    header={<FormattedMessage id="RESCHEDULE" />}
                    message={<FormattedMessage id="CONFIRM_RESCHEDULE_INFO" />}
                    submitAction={() =>
                      actions
                        .schedule(user.get('id'), null, true)
                        .then((action) => {
                          window.scrollTo(0, 0);
                          actions.removeModal();
                          if (!action.error) {
                            reloadData();
                            actions.addAlert({
                              color: 'success',
                              content: (
                                <FormattedMessage id="SUCCESSFULLY_RESCHEDULED" />
                              )
                            });
                          } else {
                            actions.addAlert({
                              color: 'danger',
                              content: (
                                <FormattedMessage id="NO_SCHEDULED_SIMULATION" />
                              )
                            });
                          }
                        })
                    }
                    onClose={actions.removeModal}
                  />
                );
              }}
            >
              <FormattedMessage id="RESCHEDULE" />
            </Button>
          </div>
        );
      case 'USERHISTORYACTION.reschedule-manually':
        return (
          <div>
            <div style={{ position: 'absolute', right: '-30px', top: '21px' }}>
              <ToolTip
                index="RESCHEDULE_MANUALLY_HINT"
                hint={<FormattedMessage id="RESCHEDULE_MANUALLY_HINT" />}
              />
            </div>
            <Button
              id="ht_manual_reschedule"
              name="ht_manual_reschedule"
              disabled={user && user.get('status') === 'suspended'}
              type="button"
              color="link"
              size="sm"
              onClick={() => {
                actions.addModal(
                  <ConfirmDelete
                    header={<FormattedMessage id="MANUAL_RESCHEDULE" />}
                    message={
                      <FormattedMessage id="CONFIRM_RESCHEDULE_MANUALLY_INFO" />
                    }
                    submitAction={() => {
                      actions.removeModal();
                      actions.addModal(
                        <ManualRescheduleModal
                          removeModal={actions.removeModal}
                          user={user}
                          reloadData={reloadData}
                        />
                      );
                    }}
                    onClose={actions.removeModal}
                  />
                );
              }}
            >
              <FormattedMessage id="MANUAL_RESCHEDULE" />
            </Button>
          </div>
        );
      case 'USERHISTORYACTION.extend_time':
        return (
          <div>
            <Button
              id="ht_extend_time"
              name="ht_extend_time"
              disabled={user && user.get('status') === 'suspended'}
              type="button"
              color="link"
              size="sm"
              onClick={() => {
                actions.addModal(
                  <ExtendTime
                    userId={user.get('id')}
                    onClose={actions.removeModal}
                  />
                );
              }}
            >
              <FormattedMessage id="EXTEND_TIME" />
            </Button>
          </div>
        );
      case 'USERHISTORYACTION.edit_roleplayer':
      case 'USERHISTORYACTION.edit_scoring_assessor': {
        let allowed = false;

        permissions.map((permission) => {
          switch (permission) {
            case 'user_profile_service_table_assign_assessor_edit_own':
            case 'user_profile_service_table_assign_assessor_edit_child':
            case 'user_profile_service_table_assign_assessor_edit_other':
              allowed = true;
              break;
            default:
              break;
          }

          return false;
        });

        if (allowed) {
          return (
            <div>
              <Button
                id={`ht_edit_${stage}`}
                name={`ht_edit_${stage}`}
                disabled={user && user.get('status') === 'suspended'}
                type="button"
                color="link"
                size="sm"
                onClick={() => {
                  actions.addModal(<EditAssessor stage={stage} />);
                }}
              >
                <FormattedMessage id="EDIT_ROLEPLAYER" />
              </Button>
            </div>
          );
        }

        return false;
      }
      case 'USERHISTORYACTION.cancel':
        if (!user.has('active_simulation')) {
          return false;
        }

        return (
          <div>
            <Button
              id="ht_cancel"
              name="ht_cancel"
              disabled={user && user.get('status') === 'suspended'}
              type="button"
              color="link"
              size="sm"
              onClick={() => {
                const isScheduled =
                  user.getIn(['stages', 'scheduled1', 'completed']) === true;
                actions.addModal(
                  <ConfirmDelete
                    header={<FormattedMessage id="CANCEL" />}
                    message={<FormattedMessage id="CONFIRM_RESCHEDULE_INFO" />}
                    submitAction={() => {
                      actions
                        .schedule(user.get('id'), null, null, true)
                        .then((action) => {
                          window.scrollTo(0, 0);
                          actions.removeModal();

                          if (!action.error) {
                            reloadData();
                            actions.getUser(user.get('id'), true).then(() =>
                              actions.addAlert({
                                color: 'success',
                                content: (
                                  <FormattedMessage
                                    id={
                                      isScheduled
                                        ? 'SCH_ASSESSMENT_CANCELED_SUCCESSFULLY'
                                        : 'ASSESSMENT_CANCELED_SUCCESSFULLY'
                                    }
                                  />
                                )
                              })
                            );
                          } else {
                            actions.addAlert({
                              color: 'danger',
                              content: (
                                <FormattedMessage id="NO_SCHEDULED_SIMULATION" />
                              )
                            });
                          }
                        });
                    }}
                    onClose={actions.removeModal}
                  />
                );
              }}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </div>
        );
      case 'USERHISTORYACTION.view_voicemail':
        return (
          <Button
            id={`ht_view_voicemail_${stage.getIn(['data', 'url'])}`}
            name={`ht_view_voicemail_${stage.getIn(['data', 'url'])}`}
            type="button"
            color="link"
            size="sm"
            onClick={() =>
              actions.addModal(
                <EmbedPreview
                  src={`${stage.getIn(['data', 'url'])}&bearer=${getCookie(
                    AUTH_TOKEN
                  )}`}
                />
              )
            }
          >
            <FormattedMessage id="VIEW_VOICEMAIL" />
          </Button>
        );
      case 'USERHISTORYACTION.report_share':
        return (
          <Button
            id={`ht_report_share_${stage.getIn(['data', 'report_id'])}`}
            name={`ht_report_share_${stage.getIn(['data', 'report_id'])}`}
            type="button"
            color="link"
            size="sm"
            onClick={() => {
              const section = document.getElementById('SHARE_REPORTS_ACCESS');
              const headerOffset = 60;
              const elementPosition =
                section.getBoundingClientRect().top + window.pageYOffset;
              const offsetPosition = elementPosition - headerOffset;

              return window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
            }}
          >
            <FormattedMessage id="SHARE_RESULTS_ACCESS" />
          </Button>
        );
      case 'USERHISTORYACTION.report_view': {
        const report = actions.allData.get(stage.getIn(['data', 'report_id']));

        const downloadPdfReports = (formData, id) => {
          const { allData, downloadPdf, removeModal } = actions;

          const report = allData.find((r) => r.get('id') === Number(id));

          return downloadPdf({
            files: [
              report
                .get('files')
                .find(
                  (f) =>
                    f.get('scope') === formData.version &&
                    f.get('language') === formData.language
                )
                .get('uuid')
            ]
          }).then((action) => {
            if (!action.error) {
              const fileName = action.result.xhr.getResponseHeader(
                'Content-Disposition'
              );
              const binaryPdf = action.result.xhr.response;
              const blob = new Blob([binaryPdf], {
                type: 'application/pdf'
              });
              const objectUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.style = 'display: none';
              a.href = objectUrl;
              a.download = decodeURIComponent(
                fileName.substring(fileName.indexOf("'") + 2, fileName.length)
              );
              a.click();
              window.URL.revokeObjectURL(objectUrl);
              removeModal();
            }
          });
        };

        return (
          <Button
            id={`ht_report_view_${stage.getIn(['data', 'report_id'])}`}
            name={`ht_report_view_${stage.getIn(['data', 'report_id'])}`}
            disabled={report == null}
            type="button"
            color="link"
            size="sm"
            onClick={() => {
              const reportId = Number(stage.getIn(['data', 'report_id']));
              return actions.addModal(
                <DownloadPDFModal
                  data={actions.allData
                    .find((r) => r.get('id') === reportId)
                    .get('files')}
                  onSubmit={(e) => {
                    return downloadPdfReports(e, reportId);
                  }}
                  multipleDownload={false}
                />
              );
            }}
          >
            <FormattedMessage
              id="DOWNLOAD_REPORT"
              defaultMessage="Download PDF report"
            />
          </Button>
        );
      }
      case 'USERHISTORYACTION.monitoring': {
        const assignment = stage.getIn(['data', 'assignment_id']);

        return (
          <Link
            id={`ht_monitoring_${stage.getIn(['data', 'assessor', 'id'])}_link`}
            to={`/users/score/${assignment}`}
            query={{ backLink: `/users/edit/${user.get('id')}` }}
          >
            <Button
              id={`ht_monitoring_${stage.getIn([
                'data',
                'assessor',
                'id'
              ])}_button`}
              name={`ht_monitoring_${stage.getIn([
                'data',
                'assessor',
                'id'
              ])}_button`}
              type="button"
              color="link"
              size="sm"
            >
              <FormattedMessage id="MONITORING" />
            </Button>
          </Link>
        );
      }
      case 'USERHISTORYACTION.development_resend_invitation': {
        if (
          user.has('modules') &&
          !user.get('modules').contains('Development')
        ) {
          return false;
        }

        return (
          <Button
            id="ht_resend_invitation"
            name="ht_resend_invitation"
            type="button"
            color="link"
            size="sm"
            onClick={() =>
              actions
                .resendDevelopmentInvitation(user.get('id'))
                .then((action) => {
                  if (!action.error) {
                    window.scrollTo(0, 0);
                    reloadData();
                    actions.addAlert({
                      color: 'success',
                      content: <FormattedMessage id="REINVITED_TO_SUCCESSFUL" />
                    });
                  }
                })
            }
          >
            <FormattedMessage id="RESEND" />
          </Button>
        );
      }
      default:
        return false;
    }
  });
}
