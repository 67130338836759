import moment from 'moment';
import { getSortString, getTagsSearchString } from 'helpers';

export default function getUsersFetchString(
  offset,
  limit,
  filter = {},
  role,
  sortBy
) {
  const query =
    typeof filter === 'string'
      ? encodeURIComponent(filter)
      : filter.search && filter.search !== ' '
      ? encodeURIComponent(filter.search)
      : '';
  const tags =
    filter.tags && filter.tags.length > 0
      ? getTagsSearchString(filter.tags)
      : null;
  const defaultSort =
    role === 'participant' ? '+stage' : getSortString('+firstname');

  if (role === 'partner') {
    return `?q=${query}&offset=${offset || 0}&limit=${
      limit || 'all'
    }&fields=id,name,email,role,status,languages,modules,simulations,owner&sort=${encodeURIComponent(
      sortBy || '+name'
    )}`;
  }

  if (role === 'client') {
    return `?q=${query}&offset=${offset || 0}&limit=${
      limit || 'all'
    }&fields=id,name,email,role,status,languages,modules,simulations,owner,partner&sort=${encodeURIComponent(
      getSortString(sortBy) || '+name'
    )}`;
  }

  return `?q=${query}&offset=${offset || 0}&limit=${limit || 'all'}&role=${
    role || ''
  }&fields=id,firstname,surname,email,role,status,partner,client,language,profile_photo,simulations,stages&sort=${encodeURIComponent(
    getSortString(sortBy) || defaultSort
  )}${tags ? `&tags=${tags}` : ''}${
    filter.partners ? `&partners=${filter.partners}` : ''
  }${filter.clients ? `&clients=${filter.clients}` : ''}&assessments=${
    filter.assessments || ''
  }&is_exported=1&from=${
    filter.from ? moment(filter.from).format('YYYY-MM-DD') : ''
  }&to=${filter.to ? moment(filter.to).format('YYYY-MM-DD') : ''}${
    filter.stakeholders && filter.stakeholders !== ' '
      ? `&stakeholders=${encodeURIComponent(filter.stakeholders)}`
      : ''
  }`;
}
