import {
  SURVEY_ACTION_REQUEST,
  SURVEY_ACTION_SUCCESS,
  SURVEY_ACTION_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function surveyAction(action) {
  return {
    types: [
      SURVEY_ACTION_REQUEST,
      SURVEY_ACTION_SUCCESS,
      SURVEY_ACTION_FAILURE
    ],
    promise: api().post(`/api/v1/users/survey/assessment/`, { action })
  };
}
