export default (action, type) => {
  const fileName = action.result.xhr.getResponseHeader('Content-Disposition');
  const binaryFile = action.result.xhr.response;
  const blob = new Blob([binaryFile], { type });
  const objectUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = objectUrl;
  a.download = decodeURIComponent(
    fileName.substring(fileName.indexOf("'") + 2, fileName.length)
  );
  a.click();
  window.URL.revokeObjectURL(objectUrl);
};
