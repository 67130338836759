import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Button
} from 'reactstrap';

import { Dropdown, Link, VirtualLink } from 'components';

import { logout } from 'actions/auth';
import { setVirtualButton, setShowIframe, setIframeSrc } from 'actions/global';
import { getParameterByName } from 'routes/actions';

import { menuItems } from 'constants/menuItems';

import styles from './styles.css';
import './styles.css';

@connect(
  (state) => ({
    id: state.router.route.vars.id,
    route: state.router.route,
    user: state.auth.get('loggedUser'),
    overviewData: state.analytics.get('overviewData'),
    permissions: state.app.get('permissions') || [],
    direction: state.app.get('direction'),
    isDebrief:
      state.auth.hasIn(['loggedUser', 'active_simulation', 'is_debrief']) &&
      state.auth.getIn(['loggedUser', 'active_simulation', 'is_debrief']),
    userHasReportShared:
      state.auth.hasIn(['loggedUser', 'has_shared_report']) &&
      state.auth.getIn(['loggedUser', 'has_shared_report']),
    userModules:
      state.auth.hasIn(['loggedUser', 'modules']) &&
      state.auth.getIn(['loggedUser', 'modules']).toArray(),
    activeVirtualButton: state.virtualNavigation.get('activeVirtualButton'),
    showIframe: state.virtualNavigation.get('showIframe')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { logout, transitionTo, setVirtualButton, setShowIframe, setIframeSrc },
      dispatch
    );
  }
)
export default class TopNav extends Component {
  static propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
    route: PropTypes.object,
    permissions: PropTypes.object.isRequired,
    overviewData: PropTypes.object,
    transitionTo: PropTypes.func.isRequired,
    direction: PropTypes.string.isRequired,
    isDebrief: PropTypes.bool.isRequired,
    userHasReportShared: PropTypes.bool.isRequired,
    userModules: PropTypes.array.isRequired,
    setVirtualButton: PropTypes.func.isRequired,
    setShowIframe: PropTypes.func.isRequired,
    setIframeSrc: PropTypes.func.isRequired,
    activeVirtualButton: PropTypes.number
  };

  getMenuItems = (permissions) => {
    const { user, route, isDebrief } = this.props;
    const items = [];

    permissions.map((permission) => {
      switch (permission) {
        case 'top_menu_development_view':
          if (
            user &&
            user.has('roles') &&
            user.get('active_role') === 'participant' &&
            user.has('modules') &&
            user.get('modules').indexOf('Development') > -1 &&
            user.get('stages').find(
              (s) =>
                // Shows "The Pinsight App" button when a report is generated
                (s.get('name') === 'report1' && s.get('completed') === true) ||
                // or a session is done and it is the debrief
                (s.get('name') === 'simulation5' &&
                  s.get('completed') === true &&
                  isDebrief)
            )
          ) {
            items.push(menuItems[0]);
          }
          break;

        case 'top_menu_analytics_view':
          if (
            user &&
            user.has('roles') &&
            user.get('active_role') === 'participant'
          ) {
            if (user.get('has_shared_report') === true) {
              const updated = menuItems[1];
              updated.query = route.query;
              items.push(updated);
            }
          } else {
            items.push(menuItems[1]);
          }
          break;

        case 'top_menu_assignments_view':
          items.push(menuItems[2]);
          break;

        case 'top_menu_simulations_view':
          items.push(menuItems[3]);
          break;

        case 'top_menu_users_view':
          items.push(menuItems[4]);
          break;

        case 'top_menu_customize_view':
          if (
            (user.get('isPartner') &&
              user.has('partner') &&
              user.getIn(['partner', 'modules']).indexOf('Customize') > -1) || // eslint-disable-line
            (user.get('isClient') &&
              user.has('client') &&
              user.getIn(['client', 'modules']).indexOf('Customize') > -1) || // eslint-disable-line
            (user.get('active_role') === 'admin' &&
              !user.get('isPartner') &&
              !user.get('isClient')) // eslint-disable-line
          ) {
            items.push(menuItems[5]);
          }
          break;

        default:
          break;
      }

      return false;
    });

    const isPinsightAdmin =
      user.get('active_role') === 'admin' &&
      !user.get('isPartner') &&
      !user.get('isClient');

    const isDirectClientAdmin =
      user.get('active_role') === 'admin' &&
      user.get('isClient') &&
      !user.has('partner');

    if (
      user.get('isPartner') &&
      user.hasIn(['partner', 'modules']) &&
      user
        .getIn(['partner', 'modules'])
        .toArray()
        .some((module) => module === 'Skills') &&
      user.get('active_role') === 'admin'
    ) {
      items.splice(1, 0, menuItems[7]);
    } else if (isDirectClientAdmin) {
      if (
        user.hasIn(['client', 'modules']) &&
        user
          .getIn(['client', 'modules'])
          .toArray()
          .some((module) => module === 'Insights')
      ) {
        // TODO uncomment this before official Insights release
        // items.splice(0, 0, menuItems[8]);
      }
    } else if (isPinsightAdmin) {
      items.splice(1, 0, menuItems[7]);
      // TODO uncomment this before official Insights release
      // items.splice(0, 0, menuItems[8]);
    }

    return items;
  };

  getHomeUrl = () => {
    const { route, user } = this.props;
    const role = user.get('active_role');

    if (role === 'admin') {
      return '/users';
    }
    if (
      user.has('stages') &&
      user.getIn(['stages', 'simulation5', 'completed']) === true
    ) {
      return '/dashboard';
    }
    if (window.location.pathname === '/it-check') {
      if (route.query && route.query.url) {
        return route.query.url;
      }
    }

    return '/';
  };

  getLink = (user) => {
    const { route } = this.props;
    const homeUrl = this.getHomeUrl();
    const assessorsItCheck =
      user.get('active_role') === 'assessor' &&
      route.pathname.indexOf('it-check') > -1;

    if (route.pathname.indexOf('/auth') > -1 && !assessorsItCheck) {
      return (
        <a href="/auth/login" id="home_logo">
          <img
            className={styles['header-logo']}
            src={
              user.has('brand_header')
                ? `${user.get('brand_header')}`
                : '/assets/img/pinsight_logo_white.png?v=3'
            }
            alt="pinsight"
          />
        </a>
      );
    }

    if (route.query.url) {
      return (
        <a href={homeUrl} id="home_logo">
          <img
            className={styles['header-logo']}
            src={
              user.has('brand_header')
                ? `${user.get('brand_header')}`
                : '/assets/img/pinsight_logo_white.png?v=3'
            }
            alt="pinsight"
          />
        </a>
      );
    }

    return (
      <Link to={homeUrl} id="home_logo">
        <img
          className={styles['header-logo']}
          src={
            user.has('brand_header')
              ? `${user.get('brand_header')}`
              : '/assets/img/pinsight_logo_white.png?v=3'
          }
          alt="pinsight"
        />
      </Link>
    );
  };

  getDropdownItems = (dropdown) => {
    const { user, logout } = this.props;
    const items = [];

    if (user.has('roles') && user.get('active_role') === 'assessor') {
      items.push({
        title: 'MY_CALENDAR',
        path: `/profile/edit/${user.get('id')}`,
        query: { calendar: true, profile: true }
      });
    }

    if (dropdown === 'reduced') {
      items.push({
        title: 'LOGOUT',
        action: logout
      });
    } else {
      items.push({
        title: 'EDIT_PROFILE',
        path: `/profile/edit/${user.get('id')}`,
        query: { profile: true }
      });
      items.push({
        title: 'LOGOUT',
        action: logout
      });
    }

    return items;
  };

  showVirtualMenu = () => {
    const { user, isDebrief, route } = this.props;

    // Check if multirole user is logged in as participant
    const userIsParticipant = user.get('active_role') === 'participant';

    // Check if debrief was already scheduled
    const debriefWasScheduled = user.getIn([
      'stages',
      'debrief_scheduled1',
      'completed'
    ]);

    // Check if the route has /prework
    const routeHasPrework = route.pathname.indexOf('/prework') > -1;

    // Check if debrief is over
    const debriefHasFinnished = user.getIn([
      'stages',
      'simulation5',
      'completed'
    ]);

    if (
      isDebrief &&
      userIsParticipant &&
      debriefWasScheduled &&
      routeHasPrework &&
      !debriefHasFinnished
    ) {
      return true;
    }

    return false;
  };

  render() {
    const {
      route,
      user,
      permissions,
      direction,
      isDebrief,
      userHasReportShared,
      userModules,
      setVirtualButton,
      setShowIframe,
      setIframeSrc,
      activeVirtualButton
    } = this.props;

    const navigation = this.getMenuItems(permissions);
    let showHeader = false;
    let dropdown = 'hidden';

    if (user && route.pathname.indexOf('auth') < 0) {
      showHeader = true;
    } else if (isDebrief) {
      showHeader = true;
      if (
        (activeVirtualButton === 1 && !userHasReportShared) ||
        (activeVirtualButton === 2 && !userModules.includes('Development'))
      ) {
        setShowIframe(false);
        setVirtualButton(0);
      }
    }

    if (user.has('email')) {
      if (route.pathname.indexOf('auth') < 0) {
        dropdown = 'visible';
      }

      if (route.pathname.indexOf('prework') > -1) {
        dropdown = 'reduced';
      }
    }

    return (
      <Container fluid className={styles['header-bg']}>
        <Container style={direction === 'rtl' ? { margin: 'auto' } : {}}>
          <Row>
            <Col>
              <Navbar className={styles['navbar-pin']}>
                <NavbarBrand
                  tag="span"
                  className={styles['navbar-brand']}
                  style={
                    direction === 'rtl'
                      ? { marginLeft: 66 }
                      : { marginRight: 66 }
                  }
                >
                  {this.getLink(user)}
                </NavbarBrand>
                {showHeader && (
                  <Nav
                    navbar
                    style={{ float: direction === 'rtl' ? 'right' : 'left' }}
                  >
                    {this.showVirtualMenu() ? (
                      <>
                        {userModules.length || userHasReportShared ? (
                          <NavItem
                            key={'current_session'}
                            className={styles['nav-pin-item']}
                            onClick={() => {
                              setShowIframe(false);
                              setVirtualButton(0);
                            }}
                          >
                            <VirtualLink
                              id={'current_session'}
                              className={classNames(
                                styles['top-nav-link'],
                                activeVirtualButton === 0 && styles.active
                              )}
                            >
                              <FormattedMessage
                                id={'MENU_ITEMS.current_session'}
                              />
                            </VirtualLink>
                          </NavItem>
                        ) : null}

                        {userHasReportShared ? (
                          <NavItem
                            key={'analytics'}
                            className={styles['nav-pin-item']}
                            onClick={() => {
                              setIframeSrc(
                                `${process.env.REACT_APP_URL}/analytics`
                              );
                              setShowIframe(true);
                              setVirtualButton(1);
                            }}
                          >
                            <VirtualLink
                              id={'analytics'}
                              className={classNames(
                                styles['top-nav-link'],
                                activeVirtualButton === 1 && styles.active
                              )}
                            >
                              <FormattedMessage id={'MENU_ITEMS.analytics'} />
                            </VirtualLink>
                          </NavItem>
                        ) : null}

                        {userModules.includes('Development') ? (
                          <NavItem
                            key={'the_pinsight_app'}
                            className={styles['nav-pin-item']}
                            onClick={() => {
                              setIframeSrc(
                                `${process.env.REACT_APP_URL}/development`
                              );
                              setShowIframe(true);
                              setVirtualButton(2);
                            }}
                          >
                            <VirtualLink
                              id={'the_pinsight_app'}
                              className={classNames(
                                styles['top-nav-link'],
                                activeVirtualButton === 2 && styles.active
                              )}
                            >
                              <FormattedMessage id={'MENU_ITEMS.development'} />
                            </VirtualLink>
                          </NavItem>
                        ) : null}
                      </>
                    ) : (
                      navigation.map((item) => {
                        const active = route.pathname.indexOf(item.path) > -1;

                        // Hide TopNav menu for participant on scheduling screen
                        if (route.pathname.indexOf('schedule') > -1) {
                          return null;
                        }

                        return (
                          <NavItem
                            key={item.title}
                            className={styles['nav-pin-item']}
                          >
                            {item.title === 'skills' ||
                            item.title === 'insights' ? (
                              <a href={item.path}>
                                <FormattedMessage
                                  id={`MENU_ITEMS.${item.title}`}
                                  defaultMessage={
                                    item.title[0].toUpperCase() +
                                    item.title.slice(1)
                                  }
                                />
                              </a>
                            ) : (
                              <Link
                                id={item.title}
                                to={item.path}
                                query={item.query}
                                className={classNames(
                                  styles['top-nav-link'],
                                  active && styles.active
                                )}
                              >
                                <FormattedMessage
                                  id={`MENU_ITEMS.${item.title}`}
                                />
                              </Link>
                            )}
                          </NavItem>
                        );
                      })
                    )}
                  </Nav>
                )}
                {dropdown !== 'hidden' && (
                  <Nav
                    navbar
                    className={styles.navbar}
                    style={{ float: direction === 'rtl' ? 'left' : 'right' }}
                  >
                    <NavItem>
                      <Dropdown
                        user={{
                          img: user.get('profile_photo')
                            ? `${process.env.REACT_APP_ASSETSURL}${user.get(
                                'profile_photo'
                              )}`
                            : '/assets/img/profile-photo-placeholder.gif',
                          name: `${user.get('firstname')} ${user.get(
                            'surname'
                          )}`,
                          email: user.get('email')
                        }}
                        items={this.getDropdownItems(dropdown)}
                      />
                    </NavItem>
                  </Nav>
                )}

                {route.pathname.indexOf('/auth/login') < 0 &&
                  (!user || user.size === 0) &&
                  (route.pathname.indexOf('/auth/forgot-password') > -1 ||
                    route.pathname.indexOf('/auth/set-new-password') > -1) && (
                    <Link
                      to="/auth/login"
                      query={
                        getParameterByName('lang')
                          ? { lang: getParameterByName('lang') }
                          : null
                      }
                      className={styles['login-button']}
                      style={direction === 'rtl' ? { float: 'left' } : {}}
                    >
                      <Button>
                        <FormattedMessage id="LOGIN" />
                      </Button>
                    </Link>
                  )}
              </Navbar>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
