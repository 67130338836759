import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import { Provider } from 'react-redux';
import { createHistory } from 'history';
import { createRoutex } from 'routex';
import en from 'react-intl/locale-data/en';
import sk from 'react-intl/locale-data/sk';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import he from 'react-intl/locale-data/he';
import nl from 'react-intl/locale-data/nl';
import fr from 'react-intl/locale-data/fr';
import tr from 'react-intl/locale-data/tr';
import zh from 'react-intl/locale-data/zh';
import ko from 'react-intl/locale-data/ko';
import id from 'react-intl/locale-data/id';
import ja from 'react-intl/locale-data/ja';
import ar from 'react-intl/locale-data/ar';
import de from 'react-intl/locale-data/de';
import { createRedux, routes } from './shared';
import { App } from './shared/containers';
import * as serviceWorker from './serviceWorker';

import api from 'api';
import { setCookie } from 'helpers';
import { AUTH_TOKEN } from './shared/constants/cookies';

const routex = createRoutex(routes, createHistory());
const store = createRedux(undefined, routex);

/**
 * Render application
 */
const renderApplication = () => {
  // Internationalization
  // Data provided by react-intl specifies the date, time, number... formats for each language
  addLocaleData(en);
  addLocaleData(sk);
  addLocaleData(es);
  addLocaleData(pt);
  addLocaleData(he);
  addLocaleData(nl);
  addLocaleData(fr);
  addLocaleData(tr);
  addLocaleData(zh);
  addLocaleData(ko);
  addLocaleData(id);
  addLocaleData(ja);
  addLocaleData(ar);
  addLocaleData(de);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('ssotoken');

  if (token) {
    api.defaultHeaders.Authorization = `Bearer ${token}`;
    setCookie(AUTH_TOKEN, token, 365);
  }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

renderApplication();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
