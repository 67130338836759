// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { ActionButtons, ConfirmDelete } from 'components';
import { Button } from 'reactstrap';
import { relationships } from 'actions/behaviors';
import { Relationship } from '../modals';

// Actions

// Styles
import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';

export default function getRelationshipTable(behavior, context) {
  const disabled = behavior
    .get('languages')
    .find((l) => l.get('language') === 'english')
    .get('published');

  const fields = [
    {
      key: 'facet',
      sortable: true,
      renderField: (item) => <FormattedMessage id={item.get('name')} />
    },
    {
      key: 'better_if',
      sortable: true,
      renderField: (item) => (
        <FormattedMessage
          id={`FACET_BETTER_IF_VALUES.${item.get('better_if')}`}
        />
      )
    }
  ];

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          edit={() => {
            context.addModal(
              <Relationship edit={item} onClose={context.removeModal} />
            );
          }}
          delete={() => {
            context.addModal(
              <ConfirmDelete
                action={relationships}
                data={{
                  type: 'delete',
                  behaviorId: behavior.get('id'),
                  data: {},
                  id: item.get('id')
                }}
                onClose={context.removeModal}
              />
            );
          }}
        />
      )
    });
  }

  return {
    data: behavior.get('facets').toArray(),
    customClassName: 'psychometrics-table',
    fields,
    button: (
      <Button
        id="new_facet_button"
        name="new_facet_button"
        onClick={() => {
          context.addModal(<Relationship onClose={context.removeModal} />);
        }}
        className={styles['add-button']}
        disabled={disabled}
      >
        <FormattedMessage id="NEW_FACET" />
      </Button>
    )
  };
}
