// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { submit, getFormValues } from 'redux-form';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { ChangeRoleForm } from 'forms';

// Actions
import { editUser, getUserPermissions } from 'actions/users';

// Styles
import modals from '../../../modals.css';
import '../../../modals.css';

/**
 * Change roles
 * @class
 */
@connect(
  (state) => ({
    user: state.users.getIn(['allUsers', Number(state.router.route.vars.id)]),
    roles: state.app.getIn(['codeList', 'role']),
    formValues: getFormValues('changeRoleForm')(state) || {}
  }),
  (dispatch) =>
    bindActionCreators({ submit, editUser, getUserPermissions }, dispatch)
)
export default class ChangeRolesModal extends Component {
  // Prop types
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    editUser: PropTypes.func.isRequired,
    roles: PropTypes.object,
    getUserPermissions: PropTypes.func.isRequired,
    formValues: PropTypes.object
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { user, onClose, editUser, getUserPermissions } = this.props;
    const { addAlert } = this.context;

    editUser(user.get('id'), {
      roles: data.roles,
      stakeholder: data.stakeholder
    }).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: (
            <FormattedHTMLMessage
              id="ROLES_SUCCESSFULLY_SET"
              values={{ roles: data.roles.join(', ') }}
            />
          )
        });
        onClose();
        getUserPermissions();
      }
    });
  };

  /**
   * Submit form
   */
  submitForm = () => {
    const { submit } = this.props;
    submit('changeRoleForm');
  };

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { onClose, user, formValues } = this.props;
    const userImg = user.get('profile_photo')
      ? `${process.env.REACT_APP_ASSETSURL}${user.get('profile_photo')}`
      : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id="CHANGE_ROLES" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div className={modals['user-info']}>
            <div
              className={modals['user-avatar']}
              style={{ backgroundImage: `url(${userImg})` }}
            />
            <div className={modals['user-name']}>
              <h6>{`${user.get('firstname')} ${user.get('surname')}`}</h6>
              <span className={modals['user-roles']}>{user.get('role')}</span>
            </div>
          </div>

          <div className={modals['modal-form']}>
            <ChangeRoleForm onSubmit={this.handleSubmit} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_change_role"
                name="cancel_change_role"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_change_role"
                name="submit_change_role"
                color="primary"
                onClick={this.submitForm}
                className={modals['footer-button']}
                disabled={formValues.roles && formValues.roles.length === 0}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
