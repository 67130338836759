import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

import {
  contacts as contactsAction,
  fetchSimulation
} from 'actions/simulations';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainSimulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    simulation: state.simulations.hasIn([
      'simulations',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.simulations.getIn([
          'simulations',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.simulations.getIn([
          'simulations',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators({ contactsAction, fetchSimulation }, dispatch)
)
export default class ContactsTranslateTable extends Component {
  static propTypes = {
    id: PropTypes.number,
    simulation: PropTypes.object,
    mainSimulation: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    contactsAction: PropTypes.func.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  getTable = (contact) => {
    const { simulation, mainSimulation, hasError, disabled } = this.props;
    let data = [];

    if (contact) {
      data = [
        { key: 'name', value: contact.get('name') },
        { key: 'company', value: contact.get('company') },
        { key: 'position', value: contact.get('position') }
      ];
    }

    return {
      data,
      customClassName: 'trans-3-table',
      fields: [
        {
          key: 'empty',
          dark: true,
          sortable: false,
          renderField: (item) => (
            <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
          )
        },
        {
          key: 'original_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.value
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let defaultValue = null;
            let translatedContact;
            let mainContact = mainSimulation
              .get('contacts')
              .find((c) => c.get('id') === contact.get('id'));

            if (simulation.has('contacts')) {
              translatedContact = simulation
                .get('contacts')
                .find((c) => c.get('id') === contact.get('id'));
              mainContact = mainSimulation
                .get('contacts')
                .find((c) => c.get('id') === contact.get('id'));

              if (translatedContact) {
                defaultValue = translatedContact.get(item.key) || null;
              }
            }

            let hasEnglishValue = false;
            if (
              mainContact.has(item.key) &&
              mainContact.get(item.key) !== ' '
            ) {
              hasEnglishValue = true;
            }

            if (!mainContact.get(item.key)) {
              hasEnglishValue = false;
            }

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && hasEnglishValue && styles.error
                )}
              >
                {hasEnglishValue && (
                  <TranslationRow
                    id={`${item.key}-${item.value}`}
                    defaultValue={defaultValue}
                    onSubmit={(e) =>
                      this.handleSubmit(
                        e,
                        item,
                        translatedContact || mainContact
                      )
                    }
                    disabled={disabled}
                  />
                )}
              </td>
            );
          }
        }
      ]
    };
  };

  handleSubmit = (data, field, contact) => {
    const { id, contactsAction, version, fetchSimulation } = this.props;
    const { addAlert, intl } = this.context;

    const translated = {
      language: version
    };
    translated[field.key] = data.translation;

    contactsAction({
      type: 'edit',
      simulationId: id,
      id: contact.get('id'),
      data: translated
    }).then((action) => {
      if (
        action.error &&
        action.error.body &&
        typeof action.error.body === 'string'
      ) {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });

        fetchSimulation(id);
      }
    });
  };

  render() {
    const { simulation, mainSimulation } = this.props;

    if (!simulation) {
      return <div />;
    }

    return (
      <div>
        {mainSimulation.has('contacts') &&
        mainSimulation.get('contacts').size > 0 ? (
          mainSimulation
            .get('contacts')
            .toArray()
            .filter((contact) => !contact.get('is_generic'))
            .map((contact, i) => (
              <BuildTable
                table={this.getTable(contact)}
                tableKey={i + 1}
                key={i}
                total={mainSimulation.get('contacts').size}
              />
            ))
        ) : (
          <BuildTable table={this.getTable(null)} tableKey={1} />
        )}
      </div>
    );
  }
}
