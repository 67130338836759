import {
  FETCH_TIMESLOTS_REQUEST,
  FETCH_TIMESLOTS_SUCCESS,
  FETCH_TIMESLOTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch timeslots action
 *
 * @param{String} id
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchTimeslots(id) {
  return {
    types: [
      FETCH_TIMESLOTS_REQUEST,
      FETCH_TIMESLOTS_SUCCESS,
      FETCH_TIMESLOTS_FAILURE
    ],
    promise: api().get(`/api/v1/users/${id}/timeslots/preferred/`),
    id
  };
}
