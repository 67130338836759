import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';
import { getSortString } from 'helpers';

/**
 * Fetch clients action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchClients(offset, limit, filter = {}, role, sortBy) {
  const query =
    typeof filter === 'string'
      ? encodeURIComponent(filter)
      : filter.search && filter.search !== ' '
      ? encodeURIComponent(filter.search)
      : '';

  const string = `?q=${query}&offset=${offset || 0}&limit=${
    limit || 'all'
  }&fields=id,name,email,role,status,languages,modules,simulations,owner,partner&sort=${encodeURIComponent(
    getSortString(sortBy) || '+name'
  )}`; // eslint-disable-line
  return {
    types: [
      FETCH_CLIENTS_REQUEST,
      FETCH_CLIENTS_SUCCESS,
      FETCH_CLIENTS_FAILURE
    ],
    promise: api().get(`/api/v1/clients/${string}`)
  };
}
