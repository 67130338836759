import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';

import modals from '../../../../../modals.css';
import customStyles from './InviteToDebriefModal.styles.css';
import './InviteToDebriefModal.styles.css';
import { bindActionCreators } from 'redux';
import { fetchDebriefs } from 'actions/simulations';

const InviteToDebriefModal = (props, context) => {
  const { onSubmit, debriefs, fetchDebriefs } = props;
  const { removeModal } = context;
  const [dropdownOpen, setOpen] = useState(false);
  const [debriefName, setDebriefName] = useState('Please select debrief');
  const [debriefID, setDebriefID] = useState(-1);

  useEffect(() => {
    fetchDebriefs();
  }, []);

  const handleOnClickButton = (id, text) => {
    setDebriefID(id);
    setDebriefName(text);
  };

  const toggle = () => {
    setOpen(!dropdownOpen);
  };

  return (
    <Modal isOpen toggle={() => false} style={customStyles}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id="MASS_ACTION.INVITE_TO_DEBRIEF" />
      </ModalHeader>
      <ModalBody
        className={classNames(modals['modal-body'], customStyles.modalBody)}
      >
        <Row className={customStyles.message}>
          <FormattedMessage id="MASS_ACTION.INVITE_TO_DEBRIEF.BODY" />
        </Row>
        <Row className={customStyles.message}>
          <FormattedMessage id="MASS_ACTION.INVITE_TO_DEBRIEF.AUTHORIZATION" />
        </Row>
        <Row className={customStyles.dropdown}>
          <div>
            <FormattedMessage id="DEBRIEF" />
          </div>
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={() => toggle()}
            className={customStyles.btnGroup}
          >
            <DropdownToggle className={customStyles.dropdownToggle}>
              {debriefName}
              <span
                className={classNames(
                  customStyles.arrow,
                  dropdownOpen && customStyles.expanded
                )}
              >
                <img src="/assets/img/expand.png" alt="expand" />
              </span>
            </DropdownToggle>
            <DropdownMenu className={customStyles.dropdownMenu}>
              {debriefs.length > 0 ? (
                debriefs.map((debrief) => {
                  return (
                    <DropdownItem
                      id={debrief.get('id')}
                      key={debrief.get('id')}
                      onClick={(e) =>
                        handleOnClickButton(e.target.id, e.target.innerText)
                      }
                    >
                      {debrief.get('name')}
                    </DropdownItem>
                  );
                })
              ) : (
                <></>
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col xs={12} className={modals['modal-button-col']}>
            <Button
              color="primary"
              onClick={() => onSubmit(debriefID)}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CONFIRM" />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

InviteToDebriefModal.propTypes = {
  actionData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchDebriefs: PropTypes.func.isRequired,
  debriefs: PropTypes.array
};

InviteToDebriefModal.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    debriefs:
      state.auth.getIn(['loggedUser', 'isPartner']) === false &&
      state.auth.getIn(['loggedUser', 'isClient']) === false &&
      state.auth.getIn(['loggedUser', 'active_role']) === 'admin'
        ? state.debriefs.get('filtered').toArray()
        : state.auth.getIn(['loggedUser', 'isClient']) === true &&
          state.auth.getIn(['loggedUser', 'active_role']) === 'admin'
        ? state.auth.getIn(['loggedUser', 'client', 'debriefs']).toArray()
        : []
  }),
  (dispatch) => bindActionCreators({ fetchDebriefs }, dispatch)
)(InviteToDebriefModal);
