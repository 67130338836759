import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

import { ButtonGroup, Button } from 'reactstrap';

import styles from './styles.css';
import './styles.css';
import { getCookie } from '../../helpers';
import { AUTH_TOKEN } from '../../constants/cookies';
import fileDownload from 'js-file-download';

const ActionButtons = (props) => (
  <ButtonGroup className={styles['button-group']}>
    {props.secureDownload && (
      <span
        className="btn btn-link"
        onClick={() => {
          fetch(
            `${process.env.REACT_APP_APIURL}/api/v1/assets${props.secureDownload}`,
            {
              method: 'get',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
              },
              mode: 'cors'
            }
          )
            .then((response) => response.blob())
            .then((blob) => {
              fileDownload(blob, props.secureDownloadName);
            })
            .catch((reason) => {
              // eslint-disable-next-line no-console
              console.warn(reason);
              alert('There was an error when downloading the file');
            });
          return true;
        }}
      >
        <FormattedMessage id="DOWNLOAD" />
      </span>
    )}
    {props.download &&
      (props.downloadDisabled ? (
        <Button type="button" color="link" disabled>
          <FormattedMessage id="DOWNLOAD" />
        </Button>
      ) : (
        <a
          href={`${process.env.REACT_APP_ASSETSURL}${props.download}`}
          id={`download-${props.id}`}
          target="_blank"
        >
          <Button type="button" color="link">
            <FormattedMessage id="DOWNLOAD" />
          </Button>
        </a>
      ))}
    {props.upload && props.upload}
    {props.edit && (
      <Button
        type="button"
        color="link"
        id={`edit-${props.id}`}
        name={`edit-${props.id}`}
        onClick={props.edit}
        disabled={props.editDisabled}
      >
        <FormattedMessage id={props.editKey} />
      </Button>
    )}
    {props.editLink &&
      (props.editDisabled ? (
        <Button type="button" color="link" disabled>
          <FormattedMessage id={props.editKey} />
        </Button>
      ) : (
        <Link
          to={props.editLink}
          query={props.editQuery || {}}
          id={`edit-link-${props.id}`}
        >
          <Button type="button" color="link">
            <FormattedMessage id={props.editKey} />
          </Button>
        </Link>
      ))}
    {props.copy && (
      <Button
        type="button"
        color="link"
        id={`copy-${props.id}`}
        name={`copy-${props.id}`}
        onClick={props.copy}
        disabled={props.copyDisabled}
        title={props.title}
      >
        <FormattedMessage id="COPY" />
      </Button>
    )}
    {props.delete && (
      <Button
        id={`delete-${props.id}`}
        name={`delete-${props.id}`}
        type="button"
        color="link"
        style={{ color: 'red' }}
        onClick={props.delete}
        disabled={props.deleteDisabled}
      >
        <FormattedMessage id="DELETE" />
      </Button>
    )}
  </ButtonGroup>
);

ActionButtons.defaultProps = {
  id: 'default',
  editKey: 'EDIT'
};

ActionButtons.propTypes = {
  id: PropTypes.string,
  download: PropTypes.string,
  secureDownload: PropTypes.string,
  secureDownloadName: PropTypes.string,
  hidden: PropTypes.array,
  edit: PropTypes.func,
  delete: PropTypes.func,
  copy: PropTypes.func,
  editLink: PropTypes.string,
  upload: PropTypes.any,
  editQuery: PropTypes.object,
  copyDisabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
  downloadDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  editKey: PropTypes.string,
  title: PropTypes.string
};

export default ActionButtons;
