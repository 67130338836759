import {
  INVITE_USERS_REQUEST,
  INVITE_USERS_SUCCESS,
  INVITE_USERS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Invite users action
 *
 * @param{Object} data
 * @param{String} role
 * @returns {{types: *[], promise: Request }}
 */

export default function inviteUsers(data, role) {
  const result = {};
  Object.keys(data).map((item) => (result[item] = data[item]));

  if (result.invite_now === false) {
    delete result.active_simulation;
  }
  delete result.invite_now;

  if (role) {
    result.role = role;
  }

  if (result.email == null) {
    result.email = '';
  }

  if (result.partner_id === 'null') {
    result.partner_id = null;
  }

  if (result.client_id === 'null') {
    result.client_id = null;
  }

  if (result.active_simulation) {
    delete result.active_simulation;
  }

  if (result.scheduled_at == null) {
    delete result.scheduled_at;
  }

  if (data.active_simulation && data.invite_now === true) {
    result.active_simulation = {
      simulation_id: data.active_simulation.simulation_id,
      assessment_id: data.active_simulation.assessment_id,
      language: data.active_simulation.language
    };
  }

  if (result.active_simulation && result.active_simulation.language === ' ') {
    delete result.active_simulation.language;
  }

  return {
    types: [INVITE_USERS_REQUEST, INVITE_USERS_SUCCESS, INVITE_USERS_FAILURE],
    promise: api().post('/api/v1/users/invite/', result)
  };
}
