// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change } from 'redux-form';

// Styles
import { Button, Col } from 'reactstrap';
import { demographicForm } from 'constants/fieldsData';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components
import { DemographicBlock } from '../../blocks';

// Const

const form = demographicForm;

/**
 * Demographic questionnaire form
 * @param props
 * @returns {*}
 * @constructor
 */
let DemographicQuestionnaireForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Col xs={12}>
        <DemographicBlock form={form} />
      </Col>
      <Col xs={3} className={styles['btn-col']}>
        <Button
          style={{ marginTop: 20 }}
          id="submit_demographic_form"
          name="submit_demographic_form"
          type="button"
          color="primary-dark"
          onClick={handleSubmit}
        >
          <FormattedMessage id="NEXT" />
        </Button>
      </Col>
    </form>
  );
};

DemographicQuestionnaireForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  loggedUser: PropTypes.object
};

DemographicQuestionnaireForm = connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser')
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)(DemographicQuestionnaireForm);

export default reduxForm({
  form,
  validate
})(DemographicQuestionnaireForm);
