import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Input, Row, Col } from 'reactstrap';

import styles from '../../subviews/Preview/styles.css';

@connect(
  (state) => ({
    analyticsData: state.analytics.get('analyticsData'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class ParticipantSelect extends React.Component {
  static propTypes = {
    analyticsData: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  render() {
    const { analyticsData, route, transitionTo } = this.props;
    const options = [];

    analyticsData.map((report) => options.push(report));

    return (
      <Row
        className={classNames(
          styles['report-select-row'],
          styles['participant-select']
        )}
      >
        <Col xs={3}>
          <strong>
            <FormattedMessage id="STAGE.report" />:
          </strong>
        </Col>
        <Col xs={9}>
          <Input
            id={`input_select_${route.query.tab}`}
            name={`input_select_${route.query.tab}`}
            type="select"
            onChange={(e) => {
              transitionTo('/analytics', {
                tab: route.query.tab || '1',
                selected: route.query.selected,
                current: Number(e.target.value),
                assessment: route.query.assessment,
                scroll: 0,
                filtered: route.query.filtered
              });
            }}
            value={Number(route.query.current)}
            defaultValue={Number(route.query.current)}
          >
            {options
              .sort((a, b) => {
                const first = a.get('date').toLowerCase();
                const second = b.get('date').toLowerCase();
                if (moment(first).isBefore(second)) {
                  return 1;
                }
                if (moment(first).isAfter(second)) {
                  return -1;
                }

                return 0;
              })
              .map((item) => (
                <option value={item.get('id')}>
                  {item.get('assessment_name')},{' '}
                  {moment(item.get('date')).format('DD MMM YYYY')}
                </option>
              ))}
          </Input>
        </Col>
      </Row>
    );
  }
}
