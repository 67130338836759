import {
  MASS_REPORTS_ACCESS_REQUEST,
  MASS_REPORTS_ACCESS_SUCCESS,
  MASS_REPORTS_ACCESS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function massReportsAccess(
  selected,
  sharing,
  enable = false,
  allStakeholders
) {
  return {
    types: [
      MASS_REPORTS_ACCESS_REQUEST,
      MASS_REPORTS_ACCESS_SUCCESS,
      MASS_REPORTS_ACCESS_FAILURE
    ],
    promise: api().post(
      `/api/v1/mass/report/${enable ? 'enable' : 'disable'}/`,
      { selected, sharing }
    ),
    selected,
    sharing,
    enable,
    allStakeholders
  };
}
