// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Components
import api from 'api';
import { Row, Col, Button } from 'reactstrap';
import { StageProgress } from 'components';
import { RescheduleModal } from '../../components';

// Styles
import styles from '../../styles.css';

// Actions
import { getRemainingTime } from 'helpers';
import { getCookie } from '../../../../../../../helpers';
import { AUTH_TOKEN } from '../../../../../../../constants/cookies';
import { Loader } from '../../../../../../../components';
import { getMe } from 'actions/auth';

/**
 * Practice mode
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    schedule: state.simulations.get('schedule'),
    isDebrief:
      state.auth.hasIn(['loggedUser', 'active_simulation', 'is_debrief']) &&
      state.auth.getIn(['loggedUser', 'active_simulation', 'is_debrief']),
    showIframe: state.virtualNavigation.get('showIframe'),
    iframeSrc: state.virtualNavigation.get('iframeSrc')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo, getMe }, dispatch);
  }
)
export default class PracticeMode extends Component {
  // Prop types
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired,
    isDebrief: PropTypes.bool.isRequired,
    showIframe: PropTypes.bool.isRequired,
    iframeSrc: PropTypes.string.isRequired,
    getMe: PropTypes.func.isRequired
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { user } = this.props;
    const stages = user.has('stages') ? user.get('stages') : [];

    this.state = {
      timer: null,
      hasPractice: stages.getIn(['practice2', 'completed']) === true,
      practiceDisabled: true,
      loading: true
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { user, schedule, transitionTo, getMe } = this.props;
    let { timer } = this.state;

    api()
      .get(`/api/v1/sim/has-prework/`)
      .then((res) => {
        this.setState({ practiceDisabled: res.body });
        this.setState({ loading: false });
      });

    if (user.getIn(['stages', 'simulation5', 'completed']) === true) {
      transitionTo('/dashboard');
    }

    this.getMeInterval = setInterval(() => {
      getMe(undefined, true);
    }, 10000);

    this.interval = setInterval(() => {
      const remaining = getRemainingTime(schedule.get('start_time'));

      if (
        remaining.days +
          remaining.hours +
          remaining.minutes +
          remaining.seconds <
        0
      ) {
        return api()
          .get(`/api/v1/users/${user.get('id')}/stage/history/?notify=1`)
          .then((res) => {
            if (!res.error) {
              const { body } = res;

              if (body.data.length === 0) {
                window.location = `${user.get(
                  'simulator_url'
                )}&jwttoken=${getCookie(AUTH_TOKEN)}`;
              } else {
                clearInterval(this.getMeInterval);
                clearInterval(this.interval);
              }
            } else {
              location.reload();
            }
          });
      }

      timer = `${remaining.days}d ${remaining.hours}h ${remaining.minutes}m ${remaining.seconds}s`;
      return this.setState({ timer });
    }, 1000);
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    clearInterval(this.getMeInterval);
    clearInterval(this.interval);
  }

  /**
   * Get stages
   * @returns {*[]}
   */
  getStages = () => {
    const { timer, hasPractice, practiceDisabled } = this.state;
    const { addModal } = this.context;
    const { user, isDebrief } = this.props;

    const stages = [
      {
        title: <FormattedMessage id="QUESTIONNAIRE_HEADING" />,
        bottom: null,
        active: false,
        finished: true,
        inDebrief: false
      },
      {
        title: <FormattedMessage id="LEARNING_EFFICIENCY" />,
        bottom: null,
        active: false,
        finished: true,
        inDebrief: false
      },
      {
        title: practiceDisabled ? (
          isDebrief ? (
            <FormattedMessage id="PREPARATION" />
          ) : (
            <FormattedMessage id="PRACTICE_MODE" />
          )
        ) : isDebrief ? (
          <FormattedMessage id="PREPARATION" />
        ) : (
          <FormattedMessage id="READY" />
        ),
        bottom: hasPractice ? (
          <Button
            size="sm"
            type="button"
            color="primary"
            onClick={() => window.open(user.get('simulator_url'))}
          >
            <FormattedMessage id="ENTER_PRACTICE_MODE" />
          </Button>
        ) : null,
        active: true,
        finished: false,
        inDebrief: true
      },
      {
        title: (
          <span>
            <FormattedMessage id="LIVE_SESSION" />
            <br />
            <small>{timer}</small>
          </span>
        ),
        bottom: (
          <Button
            id="reschedule_steps_button"
            name="reschedule_steps_button"
            size="sm"
            type="button"
            color="primary"
            outline
            disabled={
              user.has('stages') &&
              user.getIn(['stages', 'simulation1', 'completed']) === true
            }
            onClick={() => addModal(<RescheduleModal />)}
          >
            <FormattedMessage id="RESCHEDULE" />
          </Button>
        ),
        active: false,
        finished: false,
        inDebrief: true
      }
    ];

    if (
      user.has('active_simulation') &&
      !user.getIn(['active_simulation', 'has_psychometrics'])
    ) {
      stages.shift();
      stages.shift();
    }

    if (isDebrief) {
      // If session is debrief, show only filtered stages
      return stages.filter((stage) => stage.inDebrief);
    }

    return stages;
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { user, isDebrief, showIframe, iframeSrc } = this.props;
    const { hasPractice, loading, practiceDisabled } = this.state;

    if (loading) {
      return <Loader />;
    }

    return showIframe ? (
      <Row className={styles.iframeWrapper}>
        <iframe src={iframeSrc} loading="lazy" className={styles.iframe} />
      </Row>
    ) : (
      <div className={styles.prework}>
        <StageProgress stages={this.getStages()} />

        <h2>
          {practiceDisabled ? (
            isDebrief ? (
              <FormattedMessage id="PREPARATION" />
            ) : (
              <FormattedMessage id="PRACTICE_MODE" />
            )
          ) : isDebrief ? (
            <FormattedMessage id="PREPARATION" />
          ) : (
            <FormattedMessage id="THATS_ALL_FOR_NOW" />
          )}
        </h2>
        <p className={styles['prevork-intro']}>
          {practiceDisabled ? (
            isDebrief ? (
              <FormattedHTMLMessage id="PREPARATION_INFO" />
            ) : (
              <FormattedMessage id="PRACTICE_MODE_INFO" />
            )
          ) : isDebrief ? (
            <FormattedMessage id="ALL_DONE_FOR_NOW" />
          ) : (
            <FormattedMessage id="PLEASE_COME_BACK" />
          )}
        </p>

        {!hasPractice && practiceDisabled && (
          <Row>
            <Col xs={{ size: 4, offset: 4 }} className={styles['submit-box']}>
              <Button
                id="practice_mode_next_button"
                name="practice_mode_next_button"
                color="primary-dark"
                onClick={() =>
                  (window.location = `${user.get(
                    'simulator_url'
                  )}&jwttoken=${getCookie(AUTH_TOKEN)}`)
                }
              >
                <FormattedMessage id="NEXT" />
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
