// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Expand row
 * @class
 */
export default class ExpandRow extends Component {
  // Default props
  static defaultProps = {
    className: '',
    disabled: false,
    boldOnOpen: false,
    bold: false
  };

  // Prop types
  static propTypes = {
    boldOnOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    header: PropTypes.any,
    body: PropTypes.any,
    className: PropTypes.string,
    noBorder: PropTypes.bool,
    rowWithTopBorder: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    bold: PropTypes.bool
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.defaultOpen || false
    };
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultOpen } = this.props;

    if (
      defaultOpen !== nextProps.defaultOpen &&
      nextProps.defaultOpen === true
    ) {
      this.setState({ expanded: true });
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { expanded } = this.state;
    const {
      disabled,
      header,
      body,
      className,
      noBorder,
      rowWithTopBorder,
      onOpen,
      boldOnOpen,
      bold
    } = this.props;

    return (
      <div
        className={classNames(
          styles.row,
          className,
          disabled && styles.disabled
        )}
      >
        <div
          className={classNames(
            styles.header,
            ((expanded && boldOnOpen) || bold) && styles.bold
          )}
          onClick={(e) => {
            const { tagName } = e.target;
            const parentTagName = e.target.parentElement.tagName;

            if (
              tagName === 'INPUT' ||
              tagName === 'BUTTON' ||
              tagName === 'A' ||
              parentTagName === 'BUTTON' ||
              parentTagName === 'A'
            ) {
              return;
            }

            if (typeof onOpen === 'function' && !expanded) {
              onOpen();
            }

            this.setState({ expanded: !expanded });
          }}
        >
          {header}
          <span
            className={classNames(styles.arrow, expanded && styles.expanded)}
          >
            <img src="/assets/img/expand.png" alt="expand" />
          </span>
        </div>
        <div
          className={classNames(
            styles.body,
            expanded && styles.expanded,
            noBorder && styles['no-border'],
            rowWithTopBorder && styles['top-border']
          )}
        >
          {body}
        </div>
      </div>
    );
  }
}
