import React from 'react';
import './styles.css';

import { testVideoInputDevice, VideoInputTest } from '@twilio/rtc-diagnostics';
import { testAudioInputDevice, AudioInputTest } from '@twilio/rtc-diagnostics';
import styles from './styles.css';

class TwilioItCheck extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      volume: 0,
      videoInputDeviceTest: null,
      audioInputDeviceTest: null
    };
  }

  componentDidMount() {
    const videoElement = document.getElementById('video-test');
    const videoInputDeviceTest = testVideoInputDevice({
      element: videoElement
    });
    this.setState({ videoInputDeviceTest });

    videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });

    videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
      // eslint-disable-next-line no-console
      console.info(report);
    });

    const audioInputDeviceTest = testAudioInputDevice();
    this.setState({ audioInputDeviceTest });

    audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
      this.setState({ volume });
    });

    audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });

    audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
      // eslint-disable-next-line no-console
      console.log(report);
    });
  }

  componentWillUnmount() {
    this.state.videoInputDeviceTest.stop();
    this.state.audioInputDeviceTest.stop();
  }

  volumeStyle() {
    let size = 50;
    size += this.state.volume * 5;
    let opacity = 0;
    opacity = (this.state.volume / 100) * 10;

    return {
      width: `${size}px`,
      height: `${size}px`,
      opacity,
      maxWidth: '250px',
      maxHeight: '250px'
    };
  }

  render() {
    return (
      <div className={styles['video-container']}>
        <span style={this.volumeStyle()} className={styles.dot} />
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video id="video-test" />
      </div>
    );
  }
}

export default TwilioItCheck;
