import {
  FETCH_ASSESSMENT_STRATEGIES_REQUEST,
  FETCH_ASSESSMENT_STRATEGIES_SUCCESS,
  FETCH_ASSESSMENT_STRATEGIES_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch assessment strategies action
 *
 * @param{String} id
 * @param{String} sort
 * @param{Number} offset
 * @param{Number} limit
 * @param{String} language
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchAssessmentStrategies(
  id,
  sort,
  offset,
  limit,
  language
) {
  const string = `?sort=${sort || ''}&language=${language || 'english'}`;
  return {
    types: [
      FETCH_ASSESSMENT_STRATEGIES_REQUEST,
      FETCH_ASSESSMENT_STRATEGIES_SUCCESS,
      FETCH_ASSESSMENT_STRATEGIES_FAILURE
    ],
    promise: api().get(`/api/v1/assessment/${id}/strategies/${string}`),
    id,
    language: language || 'english'
  };
}
