import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { ToolTip } from '../../../../../../components';

import {
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { BasicInformation } from 'forms';
import { BuildTable, Loader, Link } from 'components';
import { SubmitPublishModal } from '../../modals';

import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';
import { SIMULATION, DEBRIEF } from '../../../../../../constants/stringTypes';

import {
  getInstructionTable,
  getDocumentsTable,
  getCalendarEventsTable,
  getContactsTable,
  getEmailsTable,
  getExercisesTable,
  showSimulationError
} from '../../actions';
import {
  fetchSimulation,
  editSimulation,
  fetchSimulationDocuments,
  fetchSimulationEvents,
  fetchSimulationExercises,
  fetchSimulationContacts,
  fetchSimulationEmails
} from 'actions/simulations';
import { form } from '../../../../../../forms/Simulations/Edit/BasicInformation';

const editSimulationTabs = [
  { id: '1', title: 'basic_information' },
  { id: '2', title: 'instructions' },
  { id: '3', title: 'files' },
  { id: '4', title: 'video_calls' },
  { id: '5', title: 'contacts' },
  { id: '6', title: 'inbox' },
  { id: '7', title: 'exercises' }
];

@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    users: state.users.get('users'),
    id: Number(state.router.route.vars.id),
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    debrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ]),
    route: state.router.route,
    isLoading:
      state.simulations.get('simulationsLoading') ||
      state.simulations.get('simulationDocumentsLoading') ||
      state.simulations.get('simulationEventsLoading') ||
      state.simulations.get('simulationContactsLoading') ||
      state.simulations.get('simulationEmailsLoading') ||
      state.simulations.get('simulationExercisesLoading') ||
      state.debriefs.get('debriefsLoading') ||
      state.behaviors.get('behaviorsLoading')
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        change,
        fetchSimulation,
        editSimulation,
        fetchSimulationDocuments,
        fetchSimulationEvents,
        fetchSimulationExercises,
        fetchSimulationContacts,
        fetchSimulationEmails,
        transitionTo
      },
      dispatch
    );
  }
)
class Main extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    users: PropTypes.object,
    id: PropTypes.number,
    fetchSimulation: PropTypes.func.isRequired,
    editSimulation: PropTypes.func.isRequired,
    simulation: PropTypes.object,
    fetchSimulationDocuments: PropTypes.func.isRequired,
    fetchSimulationEvents: PropTypes.func.isRequired,
    fetchSimulationExercises: PropTypes.func.isRequired,
    fetchSimulationContacts: PropTypes.func.isRequired,
    fetchSimulationEmails: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    loggedUser: PropTypes.object.isRequired,
    debrief: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.subTab || '1'
    };
  }

  componentDidMount() {
    const {
      id,
      simulation,
      fetchSimulationDocuments,
      fetchSimulationEvents,
      fetchSimulationExercises,
      fetchSimulationContacts,
      fetchSimulationEmails,
      debrief
    } = this.props;
    const promises = [];
    const session = simulation || debrief;

    if (!session.has('documents')) {
      if (debrief) {
        promises.push(
          fetchSimulationDocuments(id, null, null, null, null, true)
        );
      } else {
        promises.push(fetchSimulationDocuments(id));
      }
    }

    if (simulation) {
      if (!simulation.has('events')) {
        promises.push(fetchSimulationEvents(id));
      }
      if (!simulation.has('exercises')) {
        promises.push(fetchSimulationExercises(id));
      }
      if (!simulation.has('contacts')) {
        promises.push(fetchSimulationContacts(id));
      }
      if (!simulation.has('emails')) {
        promises.push(fetchSimulationEmails(id));
      }
    }

    Promise.all(promises);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.subTab !== nextProps.route.query.subTab) {
      this.toggle(nextProps.route.query.subTab || '1');
    }
  }

  getTabContent = (tab) => {
    const { simulation, transitionTo, loggedUser, debrief } = this.props;
    const session = simulation || debrief;

    const simulationVersion = session.has('languages')
      ? session
          .get('languages')
          .find((lang) => lang.get('language') === 'english')
      : false;

    const readOnly =
      simulationVersion &&
      simulationVersion.get('locked') &&
      simulationVersion.getIn(['locked_by', 'id']) !== loggedUser.get('id');

    switch (tab) {
      case '1':
        return (
          <BasicInformation disabled={readOnly} onSubmit={this.handleSubmit} />
        );
      case '2':
        return (
          <BuildTable
            table={getInstructionTable(session, this.context, readOnly)}
          />
        );
      case '3':
        return (
          <BuildTable
            table={getDocumentsTable(session, this.context, readOnly)}
          />
        );
      case '4':
        return (
          <BuildTable
            table={getCalendarEventsTable(session, this.context, readOnly)}
          />
        );
      case '5':
        return (
          <BuildTable
            table={getContactsTable(session, this.context, readOnly)}
          />
        );
      case '6':
        return (
          <BuildTable
            table={getEmailsTable(
              session,
              this.context,
              readOnly,
              transitionTo
            )}
          />
        );
      case '7':
        return (
          <div>
            <BuildTable
              table={getExercisesTable(session, this.context, readOnly)}
            />
            <div className={styles.green}>
              <FormattedMessage id="GREEN_BEHAVIORS_INFO" />
            </div>
          </div>
        );
      default:
        return <BasicInformation onSubmit={this.handleSubmit} />;
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleSubmit = (data) => {
    const { id, fetchSimulation, editSimulation, debrief } = this.props;
    const { addAlert, intl } = this.context;

    editSimulation(id, { ...data, isDebrief: Boolean(debrief) }).then(
      (action) => {
        if (!action.error) {
          addAlert({
            color: 'success',
            content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
          });
        } else if (action.error && action.error.body) {
          showSimulationError(action, addAlert, intl, () => {
            fetchSimulation(id);
          });
        }
      }
    );
  };

  submitPublish = () => {
    const { id, editSimulation, simulation, change, debrief } = this.props;
    const { addAlert, removeModal, intl } = this.context;
    const session = simulation || debrief;

    const simulationVersion = session.has('languages')
      ? session
          .get('languages')
          .find((lang) => lang.get('language') === 'english')
      : false;
    const errorFields = ['name', 'translation'];

    editSimulation(id, {
      language: 'english',
      published: !simulationVersion.get('published'),
      isDebrief: Boolean(debrief)
    }).then((action) => {
      if (action.error && action.error.body) {
        errorFields.map((field) => {
          if (action.error.body[field] && action.error.body[field].errors) {
            const string = action.error.body[field].errors
              .map((err) => intl.formatMessage({ id: err }))
              .join(', ')
              .trim();

            return addAlert({
              color: 'danger',
              content: string
            });
          }

          return false;
        });
      } else if (action.error) {
        addAlert({
          color: 'danger',
          content: JSON.stringify(action.error.body)
        });
      } else {
        if (action.result.body && action.result.body.name) {
          change(form, 'name', action.result.body.name);
        }

        addAlert({
          color: 'success',
          content: (
            <FormattedMessage
              id={`SUCCESSFULLY_${
                !simulationVersion.get('published')
                  ? 'PUBLISHED'
                  : 'UNPUBLISHED'
              }`}
              values={{
                item: intl.formatMessage({ id: debrief ? DEBRIEF : SIMULATION })
              }}
            />
          )
        });
      }
      removeModal();
    });
  };

  render() {
    const { isLoading, simulation, route, loggedUser, debrief } = this.props;
    const { addModal } = this.context;
    const { activeTab } = this.state;
    const session = simulation || debrief;

    const simulationVersion = session
      .get('languages')
      .find((l) => l.get('language') === 'english');

    return (
      <div className={styles['simulation-subtabs-div']}>
        <Nav tabs>
          {(debrief
            ? editSimulationTabs.filter(
                (tab) =>
                  tab.title === 'basic_information' ||
                  tab.title === 'instructions' ||
                  tab.title === 'files'
              )
            : editSimulationTabs
          ).map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.title}`}
                to={`/simulations/edit/${session.get('id')}`}
                query={{
                  tab: route.query.tab || '1',
                  subTab: tab.id.toString()
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`EDIT_SIMULATION_TABS.${tab.title}`} />
                  {tab.title === 'video_calls' && (
                    <ToolTip
                      id="VIDEO_CALLS_JIT"
                      index="VIDEO_CALLS_JIT"
                      hint={this.props.intl.formatMessage({
                        id: 'VIDEO_CALL_SCHEDULE_NOT_VISIBILE_TO_PARTICIPANTS'
                      })}
                    />
                  )}
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {simulationVersion.get('locked') && (
            <span key="locked-message" className={styles['locked-message']}>
              <FormattedMessage
                id={'SIMULATION_IS_BEING_EDITED_BY'}
                values={{
                  name:
                    simulationVersion.getIn(['locked_by', 'id']) ===
                    loggedUser.get('id')
                      ? 'you'
                      : simulationVersion.getIn(['locked_by', 'name'])
                }}
              />
            </span>
          )}
          {simulationVersion.get('published') &&
            simulationVersion.has('published_at') && (
              <span className={styles['published-message']}>
                <FormattedMessage
                  id={
                    simulationVersion.has('published_by')
                      ? 'PUBLISHED_BY'
                      : 'PUBLISHED_ON'
                  }
                  values={{
                    admin: simulationVersion.getIn(['published_by', 'name']),
                    date: moment(simulationVersion.get('published_at')).format(
                      'DD MMM YYYY hh:mm A'
                    )
                  }}
                />
              </span>
            )}
          <div
            key="switcher"
            className={classNames(
              styles['custom-switcher'],
              simulationVersion.get('published') &&
                !simulationVersion.get('is_unpublishable') &&
                styles.disabled,
              simulationVersion.get('published') && styles.bold
            )}
          >
            <FormattedMessage
              id={
                simulationVersion.get('published') ? 'PUBLISHED' : 'UNPUBLISHED'
              }
            />
            <Label
              className={styles['custom-switch']}
              id="publish_button_label"
            >
              <input
                id="publish_button"
                name="publish_button"
                type="checkbox"
                disabled={
                  simulationVersion.get('published') &&
                  !simulationVersion.get('is_unpublishable')
                }
                onChange={() =>
                  addModal(
                    <SubmitPublishModal
                      action={this.submitPublish}
                      publish={simulationVersion.get('published')}
                      lockedBy={
                        simulationVersion.get('locked') &&
                        simulationVersion.getIn(['locked_by', 'id']) !==
                          loggedUser.get('id')
                          ? simulationVersion.getIn(['locked_by', 'name'])
                          : null
                      }
                    />
                  )
                }
                checked={simulationVersion.get('published')}
              />
              <div className={styles['switch-slider']} />
            </Label>
          </div>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className={styles['simulation-subtabs']}
        >
          {editSimulationTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {activeTab === tab.id &&
                    (isLoading ? <Loader /> : this.getTabContent(activeTab))}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}

export default injectIntl(Main);
