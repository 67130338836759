// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * RescheduleRow
 * @param props
 * @returns {XML}
 * @constructor
 */
const RescheduleRow = (props) => {
  const { onReschedule, user, schedule, locale } = props;

  return (
    <div>
      <div className={styles['reschedule-row']}>
        <span className={styles.info}>
          <FormattedMessage id="SESSION_SCHEDULED_FOR" />
          <br />
          <h5>
            <strong>
              {moment(schedule.get('start_time'))
                .zone(schedule.get('start_time'))
                .lang(locale)
                .format('Do MMM YYYY hh:mm A')}
              <br />
              {user.get('timezone')}{' '}
              {moment(schedule.get('start_time'))
                .zone(schedule.get('start_time'))
                .format('(UTC Z)')}
            </strong>
          </h5>
        </span>

        <button
          id="reschedule_modal_button"
          name="reschedule_modal_button"
          onClick={onReschedule}
          disabled={moment()
            .add(48, 'h')
            .isAfter(moment(schedule.get('start_time')))}
        >
          <FormattedMessage id="RESCHEDULE" />
        </button>
      </div>
    </div>
  );
};

// Prop types
RescheduleRow.propTypes = {
  onReschedule: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};

export default connect((state) => ({
  user: state.auth.get('loggedUser'),
  locale: state.app.get('locale'),
  schedule: state.simulations.get('schedule')
}))(RescheduleRow);
