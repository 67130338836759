import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';
import { InputGroup } from 'reactstrap';
import { uploadFile } from 'actions/global';
import { FormattedMessage } from 'react-intl';
import { validate } from './validate';

import styles from '../../styles.css';

import { UploadBlock } from '../../../components';

const form = 'attachmentForm';

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-attachment'])
      ? state.app.getIn(['codeList', 'allowed-ext-attachment']).toArray()
      : []
  }),
  (dispatch) => bindActionCreators({ uploadFile, change }, dispatch)
)
export default class Attachment extends Component {
  static propTypes = {
    allowedTypes: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    editId: PropTypes.number,
    dispatch: PropTypes.func.isRequired
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      output: [],
      typeError: false,
      formatError: false
    };
  }

  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'name', edit.get('name'));
      this.setState({ output: [{ name: edit.get('path') }] }); // eslint-disable-line
    }
  }

  handleChange = (files) => {
    const { allowedTypes, uploadFile, change, dispatch } = this.props;

    this.setState({ formatError: false });

    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      this.setState({ formatError: true });
    } else {
      this.setState({ output: files });

      uploadFile(files[0], dispatch, 'attachment').then((action) => {
        if (!action.error) {
          change(form, 'file_id', action.result.body.uniqid);
          change(form, 'filename', files[0].name);
        } else {
          this.setState({ typeError: action.error.body });
        }
      });
    }
  };

  render() {
    const { allowedTypes, handleSubmit, editId } = this.props;
    const { formatError, output, typeError } = this.state;
    const { intl } = this.context;
    const types = allowedTypes.map((t) => t.get('name'));
    let typesAfter = '';

    if (types.length > 1) {
      types.pop();
      typesAfter = ` ${intl.formatMessage({
        id: 'AND'
      })} ${allowedTypes[allowedTypes.length - 1].get('name')}`;
    }

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup>
            <UploadBlock
              onDrop={this.handleChange}
              output={output}
              title={editId ? 'REPLACE_ATTACHMENT' : 'UPLOAD_NEW_ATTACHMENT'}
              allowedFileTypes={[
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'image/jpeg',
                'imapge/png',
                'application/pdf'
              ]}
            />
          </InputGroup>
          {typeError && (
            <div className={styles.form__error}>
              <FormattedMessage id={typeError.message} values={typeError} />
            </div>
          )}
          {formatError && (
            <div className={styles.form__error}>
              <FormattedMessage
                id="ERRORS.FILE_TYPE_NOT_ALLOWED"
                values={{
                  allowed_types: `${types.join(', ')}${typesAfter}.`
                }}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
}
