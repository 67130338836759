import {
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

import compareUserData from './compareUserData';

/**
 * Edit user action
 *
 * @param{String} id
 * @param{Object} data
 * @param{Object} previousData
 * @returns {{types: *[], promise: Request }}
 */

export default function editUser(id, data, previousData) {
  const reduced = {};
  Object.keys(data).map((item) => (reduced[item] = data[item]));

  if (!reduced.invite_now) {
    delete reduced.active_simulation;
  }

  if (reduced.active_simulation != null) {
    reduced.active_simulation = { ...data.active_simulation };
  }

  if (reduced.scheduled_at == null) {
    delete reduced.scheduled_at;
  } else {
    reduced.scheduled_at = { ...data.scheduled_at };
    reduced.scheduled_at = {
      start_time: `${reduced.scheduled_at.day.format('YYYY-MM-DD')} ${
        reduced.scheduled_at.time
      }`
    };
  }

  if (reduced.active_simulation && reduced.active_simulation.language === ' ') {
    delete reduced.active_simulation.language;
  }

  delete reduced.preferred;
  delete reduced.invite_now;
  delete reduced.from;
  delete reduced.to;
  delete reduced.unavailable_id;
  delete reduced.consent;

  const reducedClone = { ...reduced };

  Object.keys(reduced).map((key) => {
    if (!reduced[key] || compareUserData(reduced, previousData, key)) {
      return delete reduced[key];
    }

    return false;
  });

  if (reduced.debriefs && reduced.debriefs.length === 0) {
    delete reduced.debriefs;
    reduced.simulations = reducedClone.simulations;
  }
  if (reduced.partner_id === 'null') {
    reduced.partner_id = null;
    reduced.client_id = null;
  }

  if (reduced.client_id === 'null') {
    reduced.client_id = null;
  }

  if (
    previousData != null &&
    previousData.get('consent_email') &&
    !data.consent_email
  ) {
    reduced.consent_email = false;
  }

  if (reduced.active_simulation && reduced.active_simulation.id) {
    delete reduced.active_simulation.id;
  }

  return {
    types: [EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE],
    promise: api().put(`/api/v1/users/${id}/`, reduced),
    id
  };
}
