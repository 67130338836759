// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

// Components
import { Button, Col, Row } from 'reactstrap';
import { ResetPasswordModal } from 'forms/modals';

// Actions
import { resetPassword } from 'actions/auth';
import { resendInvitation } from 'actions/users';

// Styles
import styles from '../styles.css';

@connect(
  (state) => ({
    direction: state.app.get('direction'),
    loggedUser: state.auth.get('loggedUser'),
    user:
      state.users.getIn(['allUsers', Number(state.router.route.vars.id)]) ||
      Map()
  }),
  (dispatch) =>
    bindActionCreators({ resetPassword, resendInvitation }, dispatch)
)
export default class ResetPasswordBlock extends Component {
  // Prop types
  static propTypes = {
    direction: PropTypes.string,
    resetPassword: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    disabled: PropTypes.bool
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired
  };

  /**
   * Reset password
   */
  resetPassword() {
    const { loggedUser, resetPassword, user } = this.props;
    const { addAlert, removeModal } = this.context;

    const successMessageKey =
      loggedUser.get('id') === user.get('id')
        ? 'RESET_PASSWORD_SUCCESS'
        : 'SUCCESSFUL';

    resetPassword(user.get('email')).then((action) => {
      if (!action.error) {
        removeModal();
        window.scrollTo(0, 0);
        addAlert({
          color: 'success',
          content: <FormattedMessage id={successMessageKey} />
        });
      }
    });
  }

  /**
   * Show modal
   */
  showModal() {
    const { addModal } = this.context;

    addModal(
      <ResetPasswordModal
        submit={this.resetPassword.bind(this)}
        message="RESET_PASSWORD_INFO"
      />
    );
  }

  /**
   * Resend invitation
   */
  resendInvitation = () => {
    const { resendInvitation, user } = this.props;
    const { addAlert } = this.context;

    resendInvitation(user.get('id')).then((action) => {
      if (!action.error) {
        window.scrollTo(0, 0);
        addAlert({
          color: 'success',
          content: <FormattedMessage id="REINVITED_TO_SUCCESSFUL" />
        });
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { direction, user, disabled } = this.props;
    const allowResendInvitation =
      user.has('stages') &&
      (user.getIn(['stages', 'invited4', 'completed']) === false ||
        user.getIn(['stages', 'invited5', 'completed']) === false);

    return (
      <Row>
        {allowResendInvitation ? (
          <div>
            <Col xs={12}>
              <h5>
                <FormattedMessage id="RESEND_INVITATION" />
              </h5>
            </Col>
            <Col xs={10}>
              <FormattedMessage id="RESEND_INVITATION_INFO" />
            </Col>
            <Col xs={2} className={styles['btn-col']}>
              <Button
                id="resend_invitation_button"
                name="resend_invitation_button"
                disabled={disabled}
                color="primary"
                type="button"
                onClick={this.resendInvitation}
              >
                <FormattedMessage id="RESEND_INVITATION" />
              </Button>
            </Col>
          </div>
        ) : (
          <div>
            <Col xs={12}>
              <h5>
                <FormattedMessage id="PASSWORD" />
              </h5>
            </Col>
            <Col xs={9}>
              <FormattedMessage id="RESET_PASSWORD_INFO" />
            </Col>
            <Col xs={3} className={styles['btn-col']}>
              <Button
                id="reset_password_button"
                name="reset_password_button"
                disabled={disabled}
                color="primary"
                type="button"
                onClick={this.showModal.bind(this)}
                style={{
                  float: direction === 'rtl' ? 'left' : 'right',
                  minWidth: 170,
                  width: 'auto'
                }}
              >
                <FormattedMessage id="RESET_PASSWORD" />
              </Button>
            </Col>
          </div>
        )}
      </Row>
    );
  }
}
