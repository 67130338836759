import React from 'react';
import {
  Access,
  AccountOwner,
  TimeAndLanguage,
  PartnersAndClients,
  Status,
  InviteNowBlock,
  ModulesSimulationsLanguages,
  TagsBlock
} from '../../blocks';

export default function getBlocks(permissions, blockData) {
  const result = [];
  const { role } = blockData;
  const assignment = [];
  let modulesBlock = false;

  // ADMIN
  if (role === 'admin') {
    permissions.map((permission) => {
      switch (permission) {
        case 'user_profile_profile_create_admin':
          result.push(
            <AccountOwner
              user={blockData.user}
              form={blockData.form}
              header="BASIC_INFORMATION"
              profileNotRequired
            />
          );
          break;
        case 'user_profile_partner_assignment_create_admin':
          assignment.push('partner');
          break;
        case 'user_profile_client_assignment_create_admin':
          assignment.push('client');
          break;
        case 'user_profile_timezone_language_create_admin':
          result.push(<TimeAndLanguage form={blockData.form} />);
          break;
        case 'user_profile_status_create_admin':
          result.push(<Status disabled />);
          break;
        default:
          break;
      }

      return false;
    });
  }

  if (role === 'stakeholder') {
    permissions.map((permission) => {
      switch (permission) {
        case 'user_profile_profile_create_stakeholder':
          result.push(
            <AccountOwner
              user={blockData.user}
              form={blockData.form}
              header="BASIC_INFORMATION"
              profileNotRequired
            />
          );
          break;
        case 'user_profile_partner_assignment_create_stakeholder':
          assignment.push('partner');
          break;
        case 'user_profile_client_assignment_create_stakeholder':
          assignment.push('client');
          break;
        case 'user_profile_timezone_language_create_stakeholder':
          result.push(<TimeAndLanguage form={blockData.form} />);
          break;
        case 'user_profile_status_create_stakeholder':
          result.push(<Status disabled />);
          break;
        default:
          break;
      }

      return false;
    });
  }

  if (role === 'assessor') {
    permissions.map((permission) => {
      switch (permission) {
        case 'user_profile_profile_create_assessor':
          result.push(
            <AccountOwner
              user={blockData.user}
              form={blockData.form}
              header="BASIC_INFORMATION"
              bio={
                permissions.indexOf('user_profile_show_bio_create_assessor') >
                -1
              }
              profileNotRequired
            />
          );
          break;
        case 'user_profile_partner_assignment_create_assessor':
          assignment.push('partner');
          break;
        case 'user_profile_client_assignment_create_assessor':
          assignment.push('client');
          break;
        case 'user_profile_assessor_manager_create_assessor':
          result.push(
            <ModulesSimulationsLanguages
              form={blockData.form}
              hidden={[1, 4]}
            />
          );
          break;
        case 'user_profile_timezone_language_create_assessor':
          result.push(<TimeAndLanguage form={blockData.form} />);
          break;
        case 'user_profile_status_create_assessor':
          result.push(<Status disabled />);
          break;
        default:
          break;
      }

      return false;
    });
  }

  if (role === 'participant') {
    permissions.map((permission) => {
      switch (permission) {
        case 'user_profile_profile_create_participant':
          result.push(
            <AccountOwner
              user={blockData.user}
              form={blockData.form}
              header="BASIC_INFORMATION"
              profileNotRequired
              withIdentifyingNumber={permissions.find(
                (p) => p.indexOf('user_profile_eid_edit') > -1
              )}
            />
          );
          break;
        case 'user_profile_partner_assignment_create_participant':
          assignment.push('partner');
          break;
        case 'user_profile_client_assignment_create_participant':
          assignment.push('client');
          break;
        case 'user_profile_modules_create_participant':
          modulesBlock = true;
          break;
        case 'user_profile_timezone_language_create_participant':
          result.push(<TimeAndLanguage form={blockData.form} />);
          break;
        case 'user_profile_choose_simulation_create_participant':
          result.push(
            <InviteNowBlock
              form={blockData.form}
              withManualSchedule={permissions.find(
                (p) => p.indexOf('user_participant_schedule') > -1
              )}
            />
          );
          break;
        case 'user_profile_status_create_participant':
          result.push(<Status disabled />);
          break;
        case 'user_profile_tags_assignment_create_participant':
          result.push(<TagsBlock form={blockData.form} />);
          break;
        default:
          break;
      }

      return false;
    });
  }

  if (modulesBlock) {
    if (blockData.loggedUser.get('isClient')) {
      if (
        blockData.loggedUser.hasIn(['client', 'modules']) &&
        blockData.loggedUser
          .getIn(['client', 'modules'])
          .contains('Development')
      ) {
        result.splice(1, 0, <Access form={blockData.form} />);
      }
    } else if (blockData.loggedUser.get('isPartner')) {
      if (
        blockData.loggedUser.hasIn(['partner', 'modules']) &&
        blockData.loggedUser
          .getIn(['partner', 'modules'])
          .contains('Development')
      ) {
        result.splice(1, 0, <Access form={blockData.form} />);
      }
    } else if (blockData.loggedUser.get('entity') === 'pinsight') {
      result.splice(1, 0, <Access form={blockData.form} />);
    }
  }

  if (assignment.length > 0) {
    result.splice(
      1,
      0,
      <PartnersAndClients form={blockData.form} assignment={assignment} />
    );
  }

  return result;
}
