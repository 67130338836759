// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
// import { OTPublisher, OTSubscriber, createSession } from 'opentok-react';

// Components
import { Alert, Row, Col, Label, Button, Tooltip } from 'reactstrap';

// Actions
import { checkBrowser, setCookie, stageRedirect, checkSupport } from 'helpers';
import { getMe } from 'actions/auth';
import { updateUserStage } from 'actions/users';
import NotSupported from './NotSupported';

// Styles
import styles from './styles.css';
import './styles.css';

import {
  IT_CHECK,
  PARTICIPANTS_IT_CHECK
} from '../../../../../constants/cookies';
import TwilioItCheck from '../../../../../components/TwilioItCheck';

/**
 * IT check
 * @class
 */
@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    direction: state.app.get('direction')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { transitionTo, updateUserStage, getMe },
      dispatch
    );
  }
)
export default class ITCheck extends Component {
  // Default props
  static defaultProps = {
    onFinish: null
  };

  // Prop types
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    updateUserStage: PropTypes.func.isRequired,
    getMe: PropTypes.func.isRequired,
    direction: PropTypes.string.isRequired,
    onFinish: PropTypes.func
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const isSupported = checkSupport();
    this.state = {
      step: isSupported ? 0 : 4,
      hear: false,
      see: false,
      streams: [],
      tooltipOpen: false
    };

    this.sessionHelper = null;
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    if (this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
  }

  /**
   * Get not supported
   */
  getNotSupported = () => <NotSupported />;

  /**
   * Get content
   * @param step
   * @returns {XML}
   */
  getContent = (step) => {
    const { direction } = this.props;
    const { hear, see, tooltipOpen } = this.state;

    switch (step) {
      case 0:
        return (
          <div className={styles['it-check']}>
            <h1>
              <FormattedMessage id="IT_CHECK" />
            </h1>

            <p>
              <FormattedMessage id="IT_CHECK_INFO" />
              <br />
              <FormattedMessage id="IT_CHECK_INFO_DIFF_PC" />
            </p>

            <Row>
              <Col xs={{ size: 4, offset: 4 }} className={styles['submit-box']}>
                <Button
                  id={`next_it_check_button_${step}`}
                  name={`next_it_check_button_${step}`}
                  color="primary-dark"
                  onClick={() => this.setState({ step: 1 })}
                >
                  <FormattedMessage id="NEXT" />
                </Button>
              </Col>
            </Row>
          </div>
        );
      case 1:
        return (
          <div className={styles['it-check']}>
            <h1>
              <FormattedMessage id="IT_CHECK" />
            </h1>
            <h2>
              <FormattedMessage id="BROWSER_CHECK" />
            </h2>

            <Row>
              <Col xs={{ size: 4, offset: 4 }} className={styles.alert}>
                <Alert color="success">
                  <FormattedMessage id="BROWSER" />:{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {checkBrowser()}
                  </span>
                </Alert>
              </Col>
            </Row>

            <p>
              <FormattedMessage id="BROWSER_CHECK_INFO" />
              <br />
              <FormattedMessage id="BROWSER_CHECK_INFO_OK" />
            </p>

            <Row>
              <Col xs={{ size: 4, offset: 4 }} className={styles['submit-box']}>
                <Button
                  id={`next_it_check_button_${step}`}
                  name={`next_it_check_button_${step}`}
                  color="primary-dark"
                  onClick={() => this.setState({ step: 2 })}
                >
                  <FormattedMessage id="NEXT" />
                </Button>
              </Col>
            </Row>
          </div>
        );
      case 2:
        return (
          <div className={styles['it-check']}>
            <h1>
              <FormattedMessage id="IT_CHECK" />
            </h1>
            <h2>
              <FormattedMessage id="SPEAKERS_TEST" />
            </h2>
            <p>
              <FormattedMessage id="SPEAKERS_TEST_INFO" />
              <br />
              <FormattedMessage id="SPEAKERS_TEST_INFO_NO_HEADSET" />
              <br />
              <FormattedMessage id="SPEAKERS_TEST_INFO_EXTERNAL_SPEAKERS" />
            </p>

            <Row>
              <Col xs={{ size: 4, offset: 4 }}>
                <button
                  id="play_sound_button"
                  name="play_sound_button"
                  className={styles['play-button']}
                  onClick={this.playSound}
                >
                  <span className={styles['play-arrow']}>&#9205;</span>
                  <FormattedMessage id="PLAY_SOUND" />
                </button>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className={styles.checkboxes}>
                <Label check>
                  <input
                    id="i_can_hear_input"
                    name="i_can_hear_input"
                    type="checkbox"
                    checked={hear}
                    onChange={() => this.setState({ hear: !hear })}
                    style={direction === 'rtl' ? { float: 'left' } : {}}
                  />
                  <FormattedMessage id="I_CAN_HEAR" />
                </Label>
              </Col>
              <Col
                xs={{ size: 4, offset: 4 }}
                className={styles['submit-box-with-checkbox']}
              >
                <Button
                  id={`next_it_check_button_${step}`}
                  name={`next_it_check_button_${step}`}
                  color="primary-dark"
                  onClick={() => this.setState({ step: 3 })}
                  disabled={!hear}
                >
                  <FormattedMessage id="NEXT" />
                </Button>
                <span className={styles.tooltip}>
                  <img
                    src="/assets/img/tooltip.png"
                    id="Tooltip-1"
                    width="30"
                    height="30"
                    alt="tooltip"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="Tooltip-1"
                    toggle={this.toggle}
                    className={styles['tooltip-box']}
                  >
                    <FormattedHTMLMessage id="SPEAKERS_TOOLTIP" />
                  </Tooltip>
                </span>
              </Col>
            </Row>
          </div>
        );
      case 3: {
        return (
          <div className={styles['it-check']}>
            <h1>
              <FormattedMessage id="IT_CHECK" />
            </h1>
            <h2>
              <FormattedMessage id="CAMERA_AND_MICROPHONE_TEST" />
            </h2>
            <p>
              <FormattedMessage id="CAMERA_AND_MICROPHONE_TEST_INFO" />
              <br />
              <FormattedMessage id="CAMERA_AND_MICROPHONE_TEST_INFO_RIGHT_CORNER" />
            </p>

            <div
              className={styles['twilio-test']}
              style={direction === 'rtl' ? { margin: 'auto' } : {}}
            >
              <TwilioItCheck />
            </div>

            <Row>
              <Col xs={12} className={styles['checkboxes-video-test']}>
                <Label check>
                  <input
                    id="i_can_see_button"
                    name="i_can_see_button"
                    type="checkbox"
                    checked={see}
                    onChange={() => this.setState({ see: !see })}
                    style={direction === 'rtl' ? { float: 'left' } : {}}
                  />
                  <FormattedMessage id="I_CAN_SEE" />
                </Label>
              </Col>
              <Col
                xs={{ size: 4, offset: 4 }}
                className={styles['submit-box-with-checkbox']}
              >
                <Button
                  id="finish_it_check_button"
                  name="finish_it_check_button"
                  color="primary-dark"
                  onClick={this.nextStage}
                  disabled={!see}
                >
                  <FormattedMessage id="FINISH_IT_CHECK" />
                </Button>
                <span className={styles.tooltip}>
                  <img
                    src="/assets/img/tooltip.png"
                    id="Tooltip-2"
                    width="30"
                    height="30"
                    alt="tooltip"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="Tooltip-2"
                    toggle={this.toggle}
                    className={styles['tooltip-box']}
                  >
                    <FormattedHTMLMessage id="CAMERA_TOOLTIP" />
                  </Tooltip>
                </span>
              </Col>
            </Row>
          </div>
        );
      }
      case 4:
        return this.getNotSupported();
      default:
        return false;
    }
  };

  /**
   * Toggle tooltip
   */
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  /**
   * Play sound
   */
  playSound = () => {
    const audio = new Audio('/assets/files/notification.mp3');
    audio.play();
  };

  /**
   * Next stage
   */
  nextStage = () => {
    const {
      transitionTo,
      loggedUser,
      getMe,
      updateUserStage,
      onFinish
    } = this.props;
    const { addAlert } = this.context;

    if (onFinish != null) {
      return onFinish();
    }

    return updateUserStage(loggedUser.get('id'), {
      stage: 'invited3',
      completed: true
    }).then((action) => {
      if (!action.error) {
        getMe(true).then((action) => {
          if (!action.error) {
            setCookie(IT_CHECK, 'true', 365);
            setCookie(PARTICIPANTS_IT_CHECK, 'true', 365);
            transitionTo(stageRedirect(action.result.body));
            addAlert({
              color: 'success',
              content: <FormattedMessage id="IT_CHECK_SUCCESSFULLY_DONE" />
            });
          }
        });
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { step } = this.state;

    return (
      <div>
        <div className={styles['progress-bar']}>{this.getContent(step)}</div>
      </div>
    );
  }
}
