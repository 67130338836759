import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { actions } from 'routex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';
import { formValueSelector } from 'redux-form';

import { ButtonGroup, Col, Row } from 'reactstrap';
import { BuildTable, ExpandRow, Loader } from 'components';

import { resetPassword } from 'actions/auth';
import {
  fetchUserStageHistory,
  resendInvitation,
  getUser
} from 'actions/users';
import { shareReport, fetchAnalytics } from 'actions/analytics';
import { schedule } from 'actions/simulations';
import { resendDevelopmentInvitation } from 'actions/development';
import { getStageActions, getHistoryDescription } from '../actions';

import downloadPdf from '../../actions/analytics/downloadPdf';

function comparator(a, b) {
  if (a.get('logged_at') < b.get('logged_at')) {
    return 1;
  }
  if (a.get('logged_at') > b.get('logged_at')) {
    return -1;
  }
  if (a.get('logged_at') === b.get('logged_at')) {
    if (a.get('id') < b.get('id')) {
      return 1;
    }
    if (a.get('id') > b.get('id')) {
      return -1;
    }
  }

  return 0;
}

@connect(
  (state) => ({
    user:
      state.users.getIn(['allUsers', Number(state.router.route.vars.id)]) ||
      Map(),
    historyStageLoading: state.users.get('historyStageLoading'),
    historyStageTotal: state.users.get('historyStageTotal'),
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    allData: state.analytics.get('allData'),
    analyticsData: state.analytics.get('analyticsData'),
    selectedAnalyticsUsers: state.analytics.get('selectedAnalyticsUsers'),
    recipients:
      formValueSelector('shareParticipants')(state, 'participants') || [],
    users: state.users.has('allUsers')
      ? state.users.get('allUsers').toArray()
      : [],
    shareWithParticipant: formValueSelector('shareParticipants')(
      state,
      'share_with_participant'
    ),
    shareWith: formValueSelector('shareParticipants')(state, 'share_with'),
    router: state.router,
    analyticsShareUsers: state.analytics.has('reportUsers')
      ? state.analytics.get('reportUsers')
      : Map()
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        resendDevelopmentInvitation,
        fetchUserStageHistory,
        resendInvitation,
        resetPassword,
        shareReport,
        schedule,
        fetchAnalytics,
        getUser,
        transitionTo,
        downloadPdf
      },
      dispatch
    );
  }
)
export default class HistoryBlock extends Component {
  static propTypes = {
    allData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    fetchUserStageHistory: PropTypes.func.isRequired,
    historyStageLoading: PropTypes.bool,
    hideLinks: PropTypes.bool,
    resendInvitation: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    schedule: PropTypes.func.isRequired,
    historyStageTotal: PropTypes.number,
    permissions: PropTypes.array,
    analyticsData: PropTypes.object.isRequired,
    shareReport: PropTypes.func.isRequired,
    recipients: PropTypes.array.isRequired,
    users: PropTypes.object.isRequired,
    shareWithParticipant: PropTypes.bool,
    shareWith: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    fetchAnalytics: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    resendDevelopmentInvitation: PropTypes.func.isRequired,
    router: PropTypes.object,
    analyticsShareUsers: PropTypes.object.isRequired,
    downloadPdf: PropTypes.func.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { fetchUserStageHistory, fetchAnalytics, user } = this.props;
    fetchUserStageHistory(user.get('id')).then((action) => {
      const stages = action.result.body.data;
      if (stages) {
        let prevId = null;

        stages.forEach((s) => {
          if (
            s.actions &&
            s.actions.indexOf('USERHISTORYACTION.report_share') > -1 &&
            prevId !== s.data.report_id
          ) {
            prevId = s.data.report_id;

            return fetchAnalytics({
              report_id: [s.data.report_id]
            });
          }

          return false;
        });
      }
    });

    this.refreshHistoryInterval = setInterval(() => {
      this.refreshTable();
    }, 10000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Reset BG interval on route change
    if (
      nextProps.router.nextRoute != null &&
      nextProps.router.nextRoute.pathname.indexOf('analytics') > -1
    ) {
      clearInterval(this.refreshHistoryInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshHistoryInterval);
  }

  getTable = () => {
    const {
      allData,
      user,
      hideLinks,
      resendInvitation,
      resetPassword,
      schedule,
      historyStageTotal,
      fetchUserStageHistory,
      resendDevelopmentInvitation,
      permissions,
      analyticsData,
      transitionTo,
      getUser,
      downloadPdf
    } = this.props;
    const { addAlert, addModal, removeModal, intl } = this.context;

    const allFields = [
      {
        key: 'date',
        sortable: false,
        renderField: (item) =>
          moment(item.get('logged_at'))
            .utcOffset(item.get('logged_at'))
            .format('DD. MMM YYYY hh:mm A')
      },
      {
        key: 'description',
        sortable: false,
        renderField: (item) => getHistoryDescription(item, intl, user)
      },
      {
        key: 'actions',
        sortable: false,
        width: 20,
        renderField: (item) => (
          <ButtonGroup>
            {getStageActions(
              item,
              user.get('stageHistory'),
              user,
              {
                allData,
                resendInvitation,
                addAlert,
                addModal,
                removeModal,
                resetPassword,
                schedule,
                fetchUserStageHistory,
                analyticsData,
                shareReport: this.shareReport,
                transitionTo,
                getUser,
                resendDevelopmentInvitation,
                downloadPdf
              },
              permissions
            )}
          </ButtonGroup>
        )
      }
    ];

    return {
      data: user.has('stageHistory')
        ? user.get('stageHistory').sort(comparator).toArray()
        : [],
      helpers: {
        total: historyStageTotal,
        loadMore: fetchUserStageHistory,
        itemsCount: 15,
        userId: user.get('id')
      },
      fields: hideLinks ? [allFields[0], allFields[1]] : allFields
    };
  };

  shareReport = (reports, current, callback) => {
    const {
      shareWith,
      shareWithParticipant,
      shareReport,
      recipients,
      analyticsShareUsers
    } = this.props;
    const { addAlert, removeModal, intl } = this.context;
    const newSharedUsers = [];
    const report = reports[0];
    const shareModalUsers = [];

    if (report.has('shared')) {
      report
        .get('shared')
        .map((sharedData) => shareModalUsers.push(sharedData.get('user')));
    }

    if (analyticsShareUsers.has(report.get('id'))) {
      analyticsShareUsers
        .get(report.get('id'))
        .map((user) => shareModalUsers.push(user));
    }

    if (report.has('user')) {
      shareModalUsers.push(report.get('user'));
    }

    // Set recipients to share report
    const allRecipients = recipients
      .filter((r) => r.id && r.id !== ' ')
      .map((r) => {
        const user = shareModalUsers
          .find((u) => u.get('id') === Number(r.id))
          .get('email');
        return {
          user,
          share: true
        };
      });

    // If selected participant, add participant to recipients
    if (shareWithParticipant && current) {
      allRecipients.push({
        user: current.getIn(['user', 'email']),
        share: true
      });
    }

    // Stop sharing for deleted users
    if (report.has('shared')) {
      report.get('shared').map((u) => {
        const numbers = recipients
          .filter((r) => r.id && r.id !== ' ')
          .map((r) => Number(r.id));

        if (numbers.indexOf(u.getIn(['user', 'id'])) < 0) {
          if (
            shareWithParticipant &&
            current.getIn(['user', 'id']) === u.getIn(['user', 'id'])
          ) {
            return false;
          }

          return allRecipients.push({
            user: u.getIn(['user', 'email']),
            share: false
          });
        }

        return false;
      });
    }

    // Get updated recipients
    allRecipients
      .filter((r) => r.share)
      .map((r) =>
        newSharedUsers.push({
          user: shareModalUsers.find((u) => u.get('email') === r.user).toJS()
        })
      );

    shareReport(
      {
        is_sharing_enabled: Boolean(shareWith),
        report: report.get('id'),
        recipients: allRecipients
      },
      report.get('id'),
      newSharedUsers
    ).then((action) => {
      window.scrollTo(0, 0);
      removeModal();
      if (!action.error) {
        if (callback != null) {
          callback();
        }
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_SHARED" />
        });
      } else {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: JSON.stringify(action.error) })
        });
      }
    });
  };

  refreshTable = () => {
    const { fetchUserStageHistory, user } = this.props;

    if (user.has('stageHistory')) {
      fetchUserStageHistory(user.get('id'), 0, user.get('stageHistory').size, {
        backgroundRefresh: true
      });
    }
  };

  render() {
    const { historyStageLoading, user } = this.props;

    return (
      <Row>
        <Col xs={12}>
          <ExpandRow
            defaultOpen
            noBorder
            header={
              <h5 style={{ lineHeight: '22px' }}>
                <FormattedMessage id="HISTORY" />
              </h5>
            }
            body={
              <div>
                {historyStageLoading && <Loader />}
                <div
                  style={
                    historyStageLoading || !user.get('stageHistory')
                      ? { visibility: 'hidden' }
                      : {}
                  }
                >
                  <BuildTable table={this.getTable()} />
                </div>
              </div>
            }
          />
        </Col>
      </Row>
    );
  }
}

HistoryBlock.comparator = comparator;
