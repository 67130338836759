import React from 'react';
import styles from './styles.css';

const AvailabilityBadge = (props) => {
  if (props.badge === 'not_active') {
    return null;
  }
  return <span className={styles[props.badge]}>{props.badge}</span>;
};

export default AvailabilityBadge;
