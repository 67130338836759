import React from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { EditSimulationTable } from '../../components';
import { Link } from 'components';

import styles from '../styles.css';
import '../styles.css';

@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    route: state.router.route,
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    debrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ])
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
class Edit extends React.Component {
  static propTypes = {
    loggedUser: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    simulation: PropTypes.object.isRequired,
    debrief: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  render() {
    const { debrief } = this.props;
    const query = debrief ? { tab: 2 } : { tab: 1 };

    return (
      <div>
        <div className={styles['link-back']}>
          <Link id="back_to_simulations" to="/simulations" query={query}>
            &larr; <FormattedMessage id="BACK_TO_SIMULATIONS" />
          </Link>
        </div>
        <h1>
          <FormattedMessage id={debrief ? 'EDIT_DEBRIEF' : 'EDIT_SIMULATION'} />
        </h1>

        <EditSimulationTable />
      </div>
    );
  }
}

export default Edit;
