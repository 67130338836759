import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from 'constants/actionTypes';
import api from 'api';
import { getUsersFetchString } from 'helpers';

export default function fetchUsers(offset, limit, filter = {}, role, sortBy) {
  const string = getUsersFetchString(offset, limit, filter, role, sortBy);

  return {
    types: [FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE],
    promise: api().get(`/api/v1/users/${string}`),
    role,
    string
  };
}
