export default function elementInViewport(el, onlyAfterElement, offset = 0) {
  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent; // eslint-disable-line no-param-reassign
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  if (onlyAfterElement) {
    return (
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    );
  }

  return (
    top + offset < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}
