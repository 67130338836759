import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

import {
  exercises as exercisesAction,
  fetchSimulation
} from 'actions/simulations';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainSimulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    simulation: state.simulations.hasIn([
      'simulations',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.simulations.getIn([
          'simulations',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.simulations.getIn([
          'simulations',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators({ exercisesAction, fetchSimulation }, dispatch)
)
export default class ContactsTranslateTable extends Component {
  static propTypes = {
    id: PropTypes.number,
    simulation: PropTypes.object,
    mainSimulation: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    exercisesAction: PropTypes.func.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  getTable = (exercise) => {
    const { simulation, mainSimulation, hasError, disabled } = this.props;
    let data = [];

    if (exercise) {
      data = [
        { key: 'name', value: exercise.get('name') },
        { key: 'description', value: exercise.get('description') }
      ];
    }

    return {
      data,
      customClassName: 'trans-3-table',
      fields: [
        {
          key: 'empty',
          dark: true,
          sortable: false,
          renderField: (item) => (
            <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
          )
        },
        {
          key: 'original_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.value
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let defaultValue = null;
            let translatedExercise;
            let mainExercise = mainSimulation
              .get('exercises')
              .find((e) => e.get('id') === exercise.get('id'));

            if (simulation.has('exercises')) {
              translatedExercise = simulation
                .get('exercises')
                .find((e) => e.get('id') === exercise.get('id'));
              mainExercise = mainSimulation
                .get('exercises')
                .find((e) => e.get('id') === exercise.get('id'));

              if (translatedExercise) {
                defaultValue = translatedExercise.get(item.key) || null;
              }
            }

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={item.value}
                  defaultValue={defaultValue}
                  onSubmit={(e) =>
                    this.handleSubmit(
                      e,
                      item,
                      translatedExercise || mainExercise
                    )
                  }
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  handleSubmit = (data, field, exercise) => {
    const { id, exercisesAction, version, fetchSimulation } = this.props;
    const { addAlert, intl } = this.context;

    const translated = {
      language: version
    };
    translated[field.key] = data.translation;

    exercisesAction({
      type: 'edit',
      simulationId: id,
      id: exercise.get('id'),
      data: translated
    }).then((action) => {
      if (
        action.error &&
        action.error.body &&
        typeof action.error.body === 'string'
      ) {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });

        fetchSimulation(id);
      }
    });
  };

  render() {
    const { simulation, mainSimulation } = this.props;

    if (!simulation) {
      return <div />;
    }

    return (
      <div>
        {mainSimulation.has('exercises') &&
        mainSimulation.get('exercises').size > 0 ? (
          mainSimulation
            .get('exercises')
            .toArray()
            .map((exercise, i) => (
              <BuildTable
                table={this.getTable(exercise)}
                tableKey={i + 1}
                key={i}
                total={mainSimulation.get('exercises').size}
              />
            ))
        ) : (
          <BuildTable table={this.getTable(null)} tableKey={1} />
        )}
      </div>
    );
  }
}
