import React from 'react';
import moment from 'moment';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { Button } from 'reactstrap';

import { ExtendTime } from 'views/Users/components/UsersTable/modals';
import { ToolTip, Link } from 'components';
import { TwilioVideoModal } from '../components';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';
import { TWILIO_CONNECT_SUCCESS } from '../../../../../constants/actionTypes';
import { connect } from 'twilio-video';
import { PhoneCall } from '../../../../Users/components/UsersTable/modals';
import { DEFAULT_ASSESSOR } from '../../../../../constants/stringTypes';

// eslint-disable-next-line no-unused-vars
const globalDevice = {};
export default function getAssignmentsTable(activeTab, assignments, helpers) {
  const disabled = false;

  function getAction(action, item) {
    switch (action) {
      case 'score':
        return () =>
          helpers.transitionTo(`/assignments/score/${item.get('id')}`);
      case 'video_call':
        // TODO: Remove later - old Twilio call
        return () => {
          helpers
            .fetchIsInCall(
              item.getIn(['user', 'id']),
              item.getIn(['assessor', 'id'])
            )
            .then((inCall) => {
              if (inCall.result.text === 'false') {
                helpers
                  .twilioConnect(
                    item.getIn(['assessor', 'id']),
                    item.getIn(['user', 'id'])
                  )
                  .then((response) => {
                    if (response.type === TWILIO_CONNECT_SUCCESS) {
                      // connect to a room
                      connect(response.result.body, {
                        audio: true,
                        preferredVideoCodecs: 'auto',
                        bandwidthProfile: {
                          video: {
                            mode: 'grid',
                            contentPreferencesMode: 'auto',
                            clientTrackSwitchOffControl: 'auto',
                            trackSwitchOffMode: 'predicted'
                          }
                        },
                        video: {
                          height: 720,
                          frameRate: 24,
                          width: 1280
                        },
                        networkQuality: {
                          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                          remote: 1 // RemoteParticipants' Network Quality verbosity [0 - 3]
                        }
                      })
                        .then((room) => {
                          helpers
                            .startTwilioCall(
                              item.getIn(['user', 'id']),
                              item.get('id'),
                              room.name,
                              item.getIn(['assessor', 'id'])
                            )
                            .then((videoCallData) => {
                              // save room name, essentialy make video call
                              helpers.context.addModal(
                                <TwilioVideoModal
                                  room={room}
                                  videoCall={videoCallData.result.body}
                                  isDebrief={item.toJS().simulation.is_debrief}
                                />,
                                true
                              );
                            });
                        })
                        .catch((error) => {
                          // eslint-disable-next-line no-console
                          console.warn(error);
                          helpers.context.addAlert({
                            color: 'danger',
                            content: <FormattedMessage id="ERROR_OCCURRED" />
                          });
                        });
                    } else {
                      helpers.context.addAlert({
                        color: 'danger',
                        content: <FormattedMessage id="ERROR_OCCURRED" />
                      });
                    }
                  });
              } else {
                helpers.context.addAlert({
                  color: 'danger',
                  content: 'Participant is in RP right now.'
                });
              }
            });
        };
      case 'video_call_chime':
        return () => {
          const assignmentId = item.get('id');
          const participantId = item.getIn(['user', 'id']);
          const isDebrief = !!(
            item.hasIn(['simulation', 'is_debrief']) &&
            item.getIn(['simulation', 'is_debrief']) === true
          );

          const leftPosition = window.screen.width / 2 - (800 / 2 + 10);
          const topPosition = window.screen.height / 2 - (600 / 2 + 50);

          let url = `${process.env.REACT_APP_URL}/v2/video-call?`;
          url += `assignmentId=${assignmentId}`;
          url += `&participantId=${participantId}`;
          if (isDebrief) {
            url += '&isDebrief=true';
          }

          window.open(
            url,
            '_blank',
            `left=${leftPosition},top=${topPosition},width=800,height=600,toolbar=0,menubar=0,location=0`
          );
        };
      case 'extend_time':
        return () => {
          helpers.context.addModal(
            <ExtendTime
              userId={item.getIn(['user', 'id'])}
              onClose={helpers.context.removeModal}
            />
          );
        };
      case 'phone_call':
        return () => {
          const phoneDisabled =
            !item.has('actions') ||
            (item.has('actions') &&
              !item.get('actions').contains('video_call'));
          const videoModal = document.getElementById('twilio-video-subscriber');

          if (!phoneDisabled && videoModal === null) {
            helpers.context.addModal(
              <PhoneCall
                userId={item.getIn(['user', 'id'])}
                onClose={helpers.context.removeModal}
                assessorId={item.getIn(['assessor', 'id'])}
                assignmentId={item.get('id')}
                user={item.get('user')}
                helpers={helpers}
              />
            );
          }
        };
      case 'phone_call_chime':
        return () => {
          const phoneDisabled =
            !item.has('actions') ||
            (item.has('actions') &&
              !item.get('actions').contains('video_call'));
          const videoModal = document.getElementById('twilio-video-subscriber');

          if (!phoneDisabled && videoModal === null) {
            const assignmentId = item.get('id');
            const participantId = item.getIn(['user', 'id']);
            const isDebrief = !!(
              item.hasIn(['simulation', 'is_debrief']) &&
              item.getIn(['simulation', 'is_debrief']) === true
            );
            const phoneNumber1 = item.getIn(['user', 'tel_mobile']);
            const phoneNumber2 = item.getIn(['user', 'tel_secondary']);
            const leftPosition = window.screen.width / 2 - (800 / 2 + 10);
            const topPosition = window.screen.height / 2 - (600 / 2 + 50);

            let url = `${process.env.REACT_APP_URL}/v2/phone-call?`;
            url += `assignmentId=${assignmentId}`;
            url += `&participantId=${participantId}`;
            if (phoneNumber1 !== undefined) {
              url += `&phone1=${encodeURIComponent(phoneNumber1)}`;
            }
            if (phoneNumber2 !== undefined) {
              url += `&phone2=${encodeURIComponent(phoneNumber2)}`;
            }
            if (isDebrief) {
              url += '&isDebrief=true';
            }

            window.open(
              url,
              '_blank',
              `left=${leftPosition},top=${topPosition},width=800,height=600,toolbar=0,menubar=0,location=0`
            );
          }
        };
      default:
        return () => false;
    }
  }

  const fields = [
    {
      key: 'user',
      sortable: false,
      ownData: true,
      renderField: (item, counter) => {
        const user = item.get('user');
        const profilePhoto = user.get('profile_photo');
        const profilePhotoLink = profilePhoto
          ? `${process.env.REACT_APP_ASSETSURL}${profilePhoto}`
          : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line
        const name = `${user.get('firstname')} ${user.get('surname')}`;
        const mail = user.get('email') || '';

        return (
          <td style={{ minWidth: '300px' }}>
            <div
              style={{ backgroundImage: `url(${profilePhotoLink})` }}
              className={styles['user-avatar']}
            />
            <div className={styles['user-name-email']}>
              <strong>{name}</strong>
              {/* <div
                className={styles['phone-indicator']}
                onClick={getAction('phone_call', item)}
              >
                <ToolTip
                  index={`phone-indicator-${counter}`}
                  hint={
                    <FormattedHTMLMessage
                      id="SCORING_PHONE_HINT"
                      values={{
                        phone1: user.get('tel_mobile') || '',
                        phone2: user.get('tel_secondary') || ''
                      }}
                    />
                  }
                  icon="/assets/img/phone.svg"
                />
              </div> */}
              <div
                className={styles['phone-indicator']}
                onClick={getAction('phone_call_chime', item)}
              >
                <ToolTip
                  index={`phone-indicator-chime-${counter}`}
                  hint={
                    <FormattedHTMLMessage
                      id="SCORING_PHONE_HINT"
                      values={{
                        phone1: user.get('tel_mobile') || '',
                        phone2: user.get('tel_secondary') || ''
                      }}
                    />
                  }
                  icon="/assets/img/phone.svg"
                />
              </div>
              <br />
              <span>{mail || 'undefined'}</span>
            </div>
          </td>
        );
      }
    },
    {
      key: 'partner',
      sortable: false,
      renderField: (item) =>
        item.has('partner') ? item.getIn(['partner', 'name']) : 'Pinsight'
    },
    {
      key: 'client',
      sortable: false,
      renderField: (item) =>
        item.has('client') ? item.getIn(['client', 'name']) : 'N/A'
    },
    {
      key: 'service',
      sortable: false,
      renderField: (item) => (
        <div>
          {item.getIn(['simulation', 'name'])}
          <br />[{item.get('language')}]
        </div>
      )
    },
    {
      key: 'stage',
      sortable: false,
      renderField: (item) => {
        // Debriefs
        if (
          item.hasIn(['simulation', 'is_debrief']) &&
          item.getIn(['simulation', 'is_debrief']) === true
        ) {
          return moment(item.get('time_to')) > moment() ? (
            // When debrief is not yet done
            <span>
              <FormattedMessage
                id="STAGE.scheduled"
                values={{
                  date: moment(item.get('time_from'))
                    .utcOffset(item.get('time_from'))
                    .format('DD MMM YYYY')
                }}
              />
            </span>
          ) : (
            // When debrief is done
            <span>
              <FormattedMessage id="DONE" />{' '}
              {moment(item.get('time_to'))
                .utcOffset(item.get('time_to'))
                .format('DD MMM YYYY')}
            </span>
          );
        }

        // Simulations
        return (
          <div>
            {item.has('stage') ? (
              <FormattedHTMLMessage
                id={item.get('stage')}
                values={{
                  time: item.has('stage_time')
                    ? moment(item.get('stage_time'))
                        .utcOffset(item.get('stage_time'))
                        .format('DD MMM YYYY')
                    : ''
                }}
              />
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      key: 'assignments',
      sortable: false,
      renderField: (item) => {
        if (item.has('assigments') && !item.toJS().simulation.is_debrief) {
          return (
            <div>
              <FormattedHTMLMessage
                id={
                  item.get('contact') === DEFAULT_ASSESSOR
                    ? 'ASSIGNMENT.DEFAULT_ASSESSOR'
                    : item.get('assigments')
                }
                values={{
                  time: item.has('assigments_time')
                    ? moment(item.get('assigments_time'))
                        .utcOffset(item.get('assigments_time'))
                        .format('DD MMM YYYY - hh:mm A')
                    : '',
                  contact: item.get('contact') || '',
                  rp: item.get('roleplay') || 'Roleplay',
                  eventName: item.hasIn(['event', 'nameEnglish', 'name'])
                    ? item.getIn(['event', 'nameEnglish', 'name'])
                    : 'eventName'
                }}
              />
            </div>
          );
        }

        if (item.has('assigments') && item.toJS().simulation.is_debrief) {
          return moment(item.get('time_to')) > moment() ? (
            // Debrief is not yet done
            <span>
              {item.getIn(['simulation', 'name'])}{' '}
              {moment(item.get('time_from'))
                .utcOffset(item.get('time_from'))
                .format('DD MMM YYYY hh:mm A')}
            </span>
          ) : (
            // Debrief is done
            <FormattedMessage
              id="NO_SCORING"
              defaultMessage="No scoring required"
            />
          );
        }

        return '';
      }
    }
  ];

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item, index) => {
        const isDebrief =
          item.hasIn(['simulation', 'is_debrief']) &&
          item.getIn(['simulation', 'is_debrief']) === true;

        const scoreDisabled =
          !item.has('actions') ||
          (item.has('actions') && !item.get('actions').contains('score'));
        const videoDisabled =
          !item.has('actions') ||
          (item.has('actions') && !item.get('actions').contains('video_call'));
        const extendTimeDisabled =
          !item.has('actions') ||
          (item.has('actions') && !item.get('actions').contains('extend_time'));
        const resultsDownloadDisabled = moment(item.get('time_to')) < moment();

        let monitoringDisabled = scoreDisabled;
        if (isDebrief && resultsDownloadDisabled) {
          monitoringDisabled = true;
        }

        return (
          <div>
            <ToolTip
              index={`action-score-tooltip-${index}`}
              hint={<FormattedMessage id="ACTION_NOT_AVAILABLE" />}
              ownContent
              active={scoreDisabled}
            >
              <Link
                id={`score_link_${item.get('id')}`}
                to={
                  scoreDisabled
                    ? '/assignments'
                    : `/assignments/score/${item.get('id')}`
                }
                query={{ fromTab: helpers.route.query.tab || '1' }}
              >
                <Button
                  id={`score_button_${item.get('id')}`}
                  name={`score_button_${item.get('id')}`}
                  color="link"
                  size="sm"
                  disabled={monitoringDisabled}
                >
                  {isDebrief ? (
                    <FormattedMessage id="MONITORING" />
                  ) : (
                    <FormattedMessage id="score" />
                  )}
                </Button>
              </Link>
            </ToolTip>
            <br />
            {isDebrief && (
              <Button
                color="link"
                size="sm"
                disabled={resultsDownloadDisabled}
                onClick={() => {
                  helpers
                    .downloadPdf(
                      {
                        userId: item.get('user').get('id')
                      },
                      true
                    )
                    .then((action) => {
                      // eslint-disable-next-line no-console
                      console.log(action);
                      const fileName = action.result.xhr.getResponseHeader(
                        'Content-Disposition'
                      );
                      const binaryPdf = action.result.xhr.response;
                      const blob = new Blob([binaryPdf], {
                        type: 'application/pdf'
                      });
                      const objectUrl = URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      document.body.appendChild(a);
                      a.style = 'display: none';
                      a.href = objectUrl;
                      a.download = decodeURIComponent(
                        fileName.substring(
                          fileName.indexOf("'") + 2,
                          fileName.length
                        )
                      );
                      a.click();
                      window.URL.revokeObjectURL(objectUrl);
                    })
                    .catch(() =>
                      alert('There was an error. Please contact administrator')
                    );
                }}
              >
                <FormattedMessage
                  id="RESULTS_DOWNLOAD"
                  defaultMessage="Results download"
                />
              </Button>
            )}
            {item.get('type') === 'roleplay' && (
              <React.Fragment>
                {/* TODO: Remove later - Old Twilio video call button */}
                {/* <ToolTip
                  index={`action-video-tooltip-${index}`}
                  hint={<FormattedMessage id="ACTION_NOT_AVAILABLE" />}
                  ownContent
                  active={videoDisabled}
                >
                  <Button
                    id={`video_call_button_${item.get('id')}`}
                    name={`video_call_button_${item.get('id')}`}
                    color="link"
                    size="sm"
                    onClick={getAction('video_call', item)}
                    disabled={videoDisabled}
                  >
                    <FormattedMessage id="video_call" />
                  </Button>
                </ToolTip> */}
                <ToolTip
                  index={`action-video-tooltip-${index}`}
                  hint={<FormattedMessage id="ACTION_NOT_AVAILABLE" />}
                  ownContent
                  active={videoDisabled}
                >
                  <Button
                    id={`video_call_button_${item.get('id')}-chime`}
                    name={`video_call_button_${item.get('id')}-chime`}
                    color="link"
                    size="sm"
                    onClick={getAction('video_call_chime', item)}
                    disabled={videoDisabled}
                  >
                    <FormattedMessage id="video_call" />
                  </Button>
                </ToolTip>
              </React.Fragment>
            )}
            <ToolTip
              index={`action-extend-time-tooltip-${index}`}
              hint={<FormattedMessage id="ACTION_NOT_AVAILABLE" />}
              ownContent
              active={extendTimeDisabled}
            >
              <Button
                id={`extend_time_button_${item.get('id')}`}
                name={`extend_time_button_${item.get('id')}`}
                color="link"
                size="sm"
                onClick={getAction('extend_time', item)}
                disabled={extendTimeDisabled}
              >
                <FormattedMessage id="TABLE_EDIT_ACTION_ITEMS.extend_time" />
              </Button>
            </ToolTip>
          </div>
        );
      }
    });
  }

  return {
    key: 'ASSIGNMENTS',
    counter: true,
    helpers,
    data: assignments,
    getRowIndicator: (item) => {
      if (item.get('is_scored')) {
        return 'completed';
      }
      if (!item.get('is_scored') && item.has('actions')) {
        return 'incompleted';
      }

      return '';
    },
    fields
  };
}
