// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { formValueSelector } from 'redux-form';

// Components
import { Alert } from 'reactstrap';
import { SetNewPasswordForm, CreateProfileForm } from 'forms';
import { StageProgress } from 'components';

// Actions
import { getMe, setPassword, listCodelists } from 'actions/auth';
import { editUser, updateUserStage } from 'actions/users';
import { roleRedirect } from 'helpers';

/**
 * User zone
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    formErrors: state.app.get('formErrors') || {},
    hasProfilePhoto:
      formValueSelector('createProfileForm')(state, 'profile_photo') &&
      formValueSelector('createProfileForm')(state, 'profile_photo') !== ' '
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      {
        transitionTo,
        updateUserStage,
        listCodelists,
        setPassword,
        editUser,
        getMe
      },
      dispatch
    );
  }
)
export default class UserZone extends Component {
  // Prop types
  static propTypes = {
    setPassword: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    user: PropTypes.object,
    editUser: PropTypes.func.isRequired,
    getMe: PropTypes.func.isRequired,
    listCodelists: PropTypes.func.isRequired,
    updateUserStage: PropTypes.func.isRequired,
    hasProfilePhoto: PropTypes.bool,
    formErrors: PropTypes.object
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { user } = this.props;
    let hasPassword = null;

    try {
      if (user.has('stages')) {
        hasPassword = user.getIn(['stages', 'invited4', 'completed']);
      }
    } catch (e) {
      // Do nothing
    }

    this.state = {
      step: hasPassword === true ? 3 : 2
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { listCodelists } = this.props;
    listCodelists();
  }

  /**
   * Handle submit
   * @param data
   */
  handlePasswordSubmit(data) {
    const { addAlert } = this.context;
    const { editUser, user, getMe } = this.props;

    const body = {
      password: data.password
    };

    editUser(user.get('id'), body).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="PASSWORD_CREATED_SUCCESSFULLY" />
        });

        this.setState({
          step: 3
        });

        getMe();
      }
    });
  }

  /**
   * Handle create profile submit
   * @param data
   */
  handleCreateProfileSubmit(data) {
    const { transitionTo, editUser, user, updateUserStage, getMe } = this.props;
    const { addAlert } = this.context;

    editUser(user.get('id'), data, user).then((action) => {
      if (!action.error) {
        updateUserStage(user.get('id'), {
          stage: 'invited5',
          completed: true
        }).then(() => getMe());
        addAlert({
          color: 'success',
          content: <FormattedMessage id="PROFILE_SUCCESSFULLY_CREATED" />
        });
        transitionTo(roleRedirect(user, true));
      } else {
        const error = action.error.body;
        if (error.profile_photo) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { step } = this.state;
    const { hasProfilePhoto, formErrors, user } = this.props;

    const stages = [
      {
        title: <FormattedMessage id="CREATE_PASSWORD" />,
        bottom: null,
        active: step === 2,
        finished: step > 2
      },
      {
        title: <FormattedMessage id="CREATE_PROFILE" />,
        bottom: null,
        active: step === 3,
        finished: false
      },
      {
        title: (
          <FormattedMessage
            id={
              user &&
              user.has('roles') &&
              user.get('roles').contains('participant') &&
              user.has('active_simulation')
                ? 'SCHEDULE'
                : 'FINISH'
            }
          />
        ),
        bottom: false,
        active: false,
        finished: false
      }
    ];

    if (user.has('sso_enabled') && user.get('sso_enabled') === true) {
      stages.shift();
    }

    if (
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'is_psychometrics_only'])
    ) {
      stages.pop();
    }

    return (
      <div>
        <Alert
          color="danger"
          isOpen={
            !hasProfilePhoto && (formErrors.profile_photo ? true : false) // eslint-disable-line
          }
        >
          <FormattedHTMLMessage id="REQUIRED_PHOTO_ERROR" />
        </Alert>

        <StageProgress stages={stages} />

        {step === 2 ? (
          <SetNewPasswordForm
            invitation
            onSubmit={this.handlePasswordSubmit.bind(this)}
          />
        ) : (
          <div>
            <h2>
              <FormattedMessage id="CREATE_PROFILE" />
            </h2>
            <CreateProfileForm
              invitation
              onSubmit={this.handleCreateProfileSubmit.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}
