import { RESET_FILTER_ANALYTICS_DATA } from 'constants/actionTypes';

/**
 * Reset filter data action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function resetFilter() {
  return {
    type: RESET_FILTER_ANALYTICS_DATA
  };
}
