import {
  FETCH_ANALYTICS_DETAIL_REQUEST,
  FETCH_ANALYTICS_DETAIL_SUCCESS,
  FETCH_ANALYTICS_DETAIL_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function fetchDetailAnalyticsData(data) {
  const string = `?assessments=${data.assessment_id || ''}&is_exported=1&from=${
    data.from || ''
  }&to=${data.to || ''}&q=${data.q || ''}&reports=${data.report_id || ''}`;
  return {
    types: [
      FETCH_ANALYTICS_DETAIL_REQUEST,
      FETCH_ANALYTICS_DETAIL_SUCCESS,
      FETCH_ANALYTICS_DETAIL_FAILURE
    ],
    promise: api().get(`/api/v1/analytics/${string}`),
    data
  };
}
