import {
  SHARE_REPORT_REQUEST,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function shareReport(data, report, sharing) {
  return {
    types: [SHARE_REPORT_REQUEST, SHARE_REPORT_SUCCESS, SHARE_REPORT_FAILURE],
    promise: api().put('/api/v1/analytics/share/', data),
    data,
    report,
    sharing
  };
}
