// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

// Components
import { EditBehaviorTable } from '../../components';

// Styles
import styles from '../styles.css';
import '../styles.css';

/**
 * Edit
 * @constructor
 */
const Edit = () => (
  <div>
    <div className={styles['link-back']}>
      <Link id="back_to_behaviors" to="/simulations" query={{ tab: 2 }}>
        &larr; <FormattedMessage id="BACK_TO_BEHAVIORS" />
      </Link>
    </div>
    <h1>
      <FormattedMessage id="EDIT_BEHAVIOR" />
    </h1>

    <EditBehaviorTable />
  </div>
);

export default Edit;
