import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import { Alert } from 'reactstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMobile, checkDownTime } from 'helpers';

import styles from './styles.css';
import './styles.css';

import { LoginForm } from 'forms';

import { login } from 'actions/auth';

@connect(
  (state) => ({
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ login, transitionTo }, dispatch);
  }
)
export default class Login extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  state = {
    downtime: [],
    showDowntime: false
  };

  componentDidMount() {
    checkDownTime((res) => {
      const downtime = res.body.DOWNTIME;
      const showDowntime = !!Number(res.body?.SHOW_DOWNTIME);
      this.setState({ downtime, showDowntime });
    });
  }

  handleSubmit = (data) => {
    const { login, route, transitionTo } = this.props;

    // Reset query error
    if (route.query && route.query.error) {
      transitionTo(route.pathname, {});
    }

    login(data);
  };

  render() {
    const { downtime, showDowntime } = this.state;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
      <div className={isMobile() ? styles.mobileLayout : null}>
        <Helmet title="Pinsight - Login" />

        {downtime.length !== 0 && showDowntime && (
          <Alert color="info" style={{ textAlign: 'center' }}>
            <FormattedHTMLMessage
              id="DOWNTIME_INFO"
              values={{
                from: `${moment(downtime[0]).format(
                  'hh:mm A, MMMM Do, YYYY'
                )} (${timezone})`,
                to: `${moment(downtime[1]).format(
                  'hh:mm A, MMMM Do, YYYY'
                )} (${timezone})`
              }}
            />
          </Alert>
        )}
        <LoginForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}
