import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Col, InputGroup, Label, Row } from 'reactstrap';
import { Divider } from 'components';

import { getPartner } from 'actions/users';
import { renderInput } from '../actions';
import { sortItems } from 'helpers';

import styles from '../styles.css';
import '../styles.css';
import { modules } from '../../constants/fieldsData';

const modulesField = 'modules';
const simulationField = 'simulations';
const debriefField = 'debriefs';
const languageField = 'languages';

// TODO: REFACTOR THIS BLOCK!!! (module simulation and language indexing)
@connect(
  (state, ownProps) => ({
    loggedUser: state.auth.get('loggedUser'),
    clients: state.clients.get('allClients'),
    partners: state.partners.get('allPartners'),
    users: state.users.get('allUsers'),
    editMode: state.router.route.attrs.edit,
    editedId: state.router.route.vars.id
      ? Number(state.router.route.vars.id)
      : null,
    formRole: state.router.route.attrs.role,
    simulations: state.simulations.hasIn(['simulations', 'english'])
      ? sortItems(state.simulations.getIn(['simulations', 'english']), {
          key: 'name',
          asc: true
        }).toArray()
      : [],
    languages: state.app.hasIn(['codeList', 'language_published'])
      ? sortItems(state.app.getIn(['codeList', 'language_published']), {
          key: 'name',
          asc: true
        }).toArray()
      : [],
    selectedPartner: state.partners.getIn([
      'allPartners',
      Number(formValueSelector(ownProps.form)(state, 'partner_id'))
    ]),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ getPartner }, dispatch)
)
export default class ModulesSimulationsLanguages extends Component {
  static propTypes = {
    hidden: PropTypes.array,
    readOnly: PropTypes.bool,
    editedId: PropTypes.number,
    editMode: PropTypes.bool,
    formRole: PropTypes.string,
    simulations: PropTypes.object,
    languages: PropTypes.object,
    user: PropTypes.object.isRequired,
    loggedUser: PropTypes.object,
    clients: PropTypes.object,
    partners: PropTypes.object,
    users: PropTypes.object,
    disabled: PropTypes.bool,
    selectedPartner: PropTypes.object,
    route: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { selected: {} };
  }

  componentDidMount() {
    this.setState({ selected: this.props.selectedPartner });

    this.initializeEventListeners();
  }

  initializeEventListeners = () => {
    const newReportingModuleIndex = modules
      .filter((module) => module.get('name') === 'newreporting')
      .pop()
      ?.get('id');

    let newReportingCheckbox = null;

    if (newReportingModuleIndex) {
      newReportingCheckbox = document.getElementById(
        `modules[${newReportingModuleIndex - 1}]`
      );
    }

    if (newReportingCheckbox) {
      newReportingCheckbox.addEventListener('change', (event) => {
        const pppLabel = document.getElementsByClassName(
          'pinsight-potential-profile'
        )[0];
        const wspLabel = document.getElementsByClassName(
          'work-styles-profile'
        )[0];
        const pppCheckbox = pppLabel.getElementsByTagName('input')[0];
        const wsppCheckbox = wspLabel.getElementsByTagName('input')[0];

        if (event.target.checked) {
          pppLabel.style.overflow = 'hidden';
          pppLabel.style.height = '0px';
          pppLabel.style.display = 'block';
          wspLabel.style.overflow = 'auto';
          wspLabel.style.height = 'auto';

          if (pppCheckbox.checked) {
            wsppCheckbox.click();
            pppCheckbox.click();
          }
        } else {
          pppLabel.style.overflow = 'auto';
          pppLabel.style.height = 'auto';
          wspLabel.style.display = 'block';
          wspLabel.style.overflow = 'hidden';
          wspLabel.style.height = '0px';

          if (wsppCheckbox.checked) {
            pppCheckbox.click();
            wsppCheckbox.click();
          }
        }
      });
    }
  };

  getModules = (modules) => {
    const { form, editMode, loggedUser } = this.props;

    let result = modules;
    if (form === 'createPartner') {
      result = result.filter((m) => m.get('allowedForPartner') === true);
    } else {
      result = result.filter((m) => m.get('allowedForClient') === true);
      // selected partner is PINSIGHT
      if (this.props.selectedPartner === undefined) {
        result = result.filter((m) => m.get('name') !== 'phonecalls');
      }

      let enableRemovePsychometrics = true;

      if (
        loggedUser.get('isPartner') &&
        loggedUser
          .getIn(['partner', 'modules'])
          .indexOf('RemovePsychometrics') === -1
      ) {
        enableRemovePsychometrics = false;
      }

      if (!enableRemovePsychometrics) {
        result = result.filter((m) => m.get('name') !== 'removepsychometrics');
      }
    }

    if (!editMode) {
      result = result.filter((m) => !m.get('onlyEdit'));
    }

    return result;
  };

  getSimulations = () => {
    const {
      loggedUser,
      editMode,
      editedId,
      formRole,
      clients,
      partners,
      users,
      route
    } = this.props;
    const { selected } = this.state;

    if (editMode) {
      switch (formRole || route.query.role) {
        case 'partner':
          return partners.hasIn([editedId, 'simulations_enableable'])
            ? partners.getIn([editedId, 'simulations_enableable']).toArray()
            : [];
        case 'client':
          return clients.hasIn([editedId, 'simulations_enableable'])
            ? clients.getIn([editedId, 'simulations_enableable']).toArray()
            : [];
        case 'all':
        case 'assessors':
          return users.hasIn([editedId, 'simulations_enableable'])
            ? users.getIn([editedId, 'simulations_enableable']).toArray()
            : [];
        default:
          return this.props.simulations.filter(
            (simulation) => !simulation.has('owner')
          );
      }
    } else if ((formRole === 'client' || formRole === 'assessor') && selected) {
      return this.props.simulations;
    } else if (
      (formRole === 'client' || formRole === 'assessor') &&
      loggedUser.get('isPartner')
    ) {
      return loggedUser.getIn(['partner', 'simulations']).toArray();
    } else if (
      editedId === loggedUser.get('id') &&
      loggedUser.has('simulations')
    ) {
      return loggedUser.get('simulations').toArray();
    }

    return this.props.simulations.filter(
      (simulation) => simulation.get('owner') == null
    );
  };

  getDebriefs = () => {
    const {
      editMode,
      editedId,
      formRole,
      users,
      route,
      clients,
      loggedUser
    } = this.props;

    if (editMode) {
      switch (formRole || route.query.role) {
        case 'all':
        case 'assessors':
          return users.hasIn([editedId, 'debriefs_enableable'])
            ? users.getIn([editedId, 'debriefs_enableable']).toArray()
            : [];
        case 'client':
          return clients.hasIn([editedId, 'debriefs_enableable'])
            ? clients.getIn([editedId, 'debriefs_enableable']).toArray()
            : [];
        default:
          return [];
      }
    }

    if (editedId === loggedUser.get('id') && loggedUser.has('debriefs')) {
      return loggedUser.get('debriefs').toArray();
    }

    return [];
  };

  getLanguages = () => {
    const { editedId, loggedUser } = this.props;
    const isOwnProfile = editedId === loggedUser.get('id');

    return isOwnProfile
      ? this.props.languages.filter(
          (l) => loggedUser.get('languages').indexOf(l.get('name')) > -1
        )
      : this.props.languages;
  };

  getModSimLan = () => {
    const { loggedUser, partners, languages, editedId } = this.props;

    const debriefs = this.getDebriefs();
    const simulations = this.getSimulations();
    const isOwnProfile = editedId === loggedUser.get('id');

    let userLanguages = [];
    let userSimulations = [];
    let userDebriefs = [];
    let userModules = [];

    if (
      (loggedUser.get('isPartner') || this.props.selectedPartner) &&
      !isOwnProfile
    ) {
      const partner =
        this.props.selectedPartner ||
        partners.get(loggedUser.get('partner_id'));
      if (partner) {
        if (partner.has('languages')) {
          partner.get('languages').map((lang) => {
            const current = languages.find((l) => l.get('name') === lang);
            if (current) {
              return userLanguages.push(current);
            }

            return false;
          });
        }

        if (partner.has('simulations')) {
          partner.get('simulations').map((sim) => {
            const current = simulations.find(
              (s) => s.get('id') === sim.get('id')
            );
            if (current) {
              return userSimulations.push(current);
            }

            return false;
          });
        }

        if (partner.has('modules')) {
          partner.get('modules').map((mod) => {
            const current = this.getModules(modules).find(
              (m) => m.get('id') === mod.get('id')
            );

            if (current) {
              return userModules.push(current);
            }

            return false;
          });
        }
      }
    } else {
      userLanguages = this.getLanguages();
      userSimulations = simulations;
      userModules = this.getModules(modules);
      userDebriefs = debriefs;
    }

    return {
      userLanguages: sortItems(userLanguages, { key: 'name', asc: true }),
      userSimulations: sortItems(userSimulations, { key: 'name', asc: true }),
      userDebriefs: sortItems(userDebriefs, { key: 'name', asc: true }),
      userModules
    };
  };

  getDebriefsSection = (debriefs) => {
    const { disabled } = this.props;

    const renderDebriefField = (debrief, index) => (
      <InputGroup
        key={`${debrief.get('id')}-${index}`}
        className={styles['checkbox-block']}
      >
        <Label check key={`${debrief.get('id')}-${index}`}>
          <Field
            disabled={disabled}
            name={`${debriefField}[${debrief.get('id')}]`}
            component={renderInput}
            readOnly={this.props.readOnly}
            type="checkbox"
          />
          <span>{debrief.get('name')}</span>
        </Label>
      </InputGroup>
    );

    return debriefs.map((debrief, index) => renderDebriefField(debrief, index));
  };

  getSimulationsSection = (
    simulationsParam,
    selectedPartner,
    psychometricsOnly = false
  ) => {
    const { disabled, editMode, formRole, route, clients, user } = this.props;

    let hasNewReporting = false;

    const userJs = user ? user.toJS() : null;

    if (userJs && userJs.id && userJs.owner?.entity === 'client') {
      hasNewReporting =
        userJs.modules.filter(
          (module) => module.name.toLowerCase() === 'newreporting'
        ).length === 1;
    }

    // Filter psychometrics sims
    const simulations = simulationsParam.filter((simulation) => {
      if (
        !simulation.has('isPsychometrics') &&
        !simulation.has('is_psychometrics_only')
      ) {
        return true;
      }
      return (
        simulation.get('isPsychometrics') === psychometricsOnly ||
        simulation.get('is_psychometrics_only') === psychometricsOnly
      );
    });

    const renderSimulationField = (simulation, index) => {
      let hideLabel = false;

      if (
        psychometricsOnly &&
        hasNewReporting &&
        simulation.get('name') === 'Pinsight Potential Profile'
      ) {
        hideLabel = true;
      }

      if (
        psychometricsOnly &&
        !hasNewReporting &&
        simulation.get('name') === 'Work Styles Profile'
      ) {
        hideLabel = true;
      }

      if (
        simulation.get('name') === 'Pinsight Potential Profile' &&
        clients.size === 0
      ) {
        hideLabel = false;
      }

      return (
        <InputGroup
          key={`${simulation.get('id')}-${index}`}
          className={styles['checkbox-block']}
        >
          <Label
            check
            key={`${simulation.get('id')}-${index}`}
            style={{
              overflow: hideLabel ? 'hidden' : 'auto',
              height: hideLabel ? '0px' : 'auto',
              display: hideLabel ? 'block' : 'auto'
            }}
            className={`${
              simulation.get('name') === 'Pinsight Potential Profile'
                ? 'pinsight-potential-profile'
                : ''
            } ${
              simulation.get('name') === 'Work Styles Profile'
                ? 'work-styles-profile'
                : ''
            }`}
          >
            <Field
              disabled={disabled}
              name={`${simulationField}[${simulation.get('id')}]`}
              component={renderInput}
              readOnly={this.props.readOnly}
              type="checkbox"
            />
            <span>{simulation.get('name')}</span>
          </Label>
        </InputGroup>
      );
    };

    if (
      (editMode && formRole === 'partner') ||
      (!editMode && formRole === 'client') ||
      (formRole === 'client' && selectedPartner) ||
      ((formRole === 'assessor' ||
        route.query.role === 'all' ||
        route.query.role === 'assessors') &&
        selectedPartner)
    ) {
      return (
        <div>
          {simulations
            .filter(
              (simulation) => simulation.has('owner') || simulation.get('isOwn')
            )
            .map((simulation, index) =>
              renderSimulationField(simulation, index)
            )}
          {simulations.filter(
            (simulation) => simulation.has('owner') || simulation.get('isOwn')
          ).length > 0 && <Divider />}
          {simulations
            .filter(
              (simulation) =>
                !simulation.has('owner') && !simulation.get('isOwn')
            )
            .map((simulation, index) =>
              renderSimulationField(simulation, index)
            )}
        </div>
      );
    }

    return simulations.map((simulation, index) =>
      renderSimulationField(simulation, index)
    );
  };

  inputValidatonHandler = (event, module) => {
    // Validation for the SimManager checkbox
    if (module.get('name') === 'simulations') {
      // Create constant for the Skills checkbox
      const skillCheckbox = document.getElementById('modules[5]');
      // Create constant for the Pinsight library checkbox
      const pinsightLibraryCheckbox = document.getElementById('modules[6]');

      if (event.target.checked) {
        // If the SimManager is checked - Allow Skills
        skillCheckbox.removeAttribute('disabled');
      } else {
        // If the SimManager is unchecked - Uncheck (if checked) & Disable Skills
        if (skillCheckbox.checked) skillCheckbox.click();
        skillCheckbox.disabled = true;
        // If the SimManager is unchecked - Uncheck (if checked) & Disable Skills
        if (pinsightLibraryCheckbox.checked) pinsightLibraryCheckbox.click();
        pinsightLibraryCheckbox.disabled = true;
      }
    }

    // Validation for the Skills checkbox
    if (module.get('name') === 'skills') {
      // Create constant for the Pinsight library checkbox
      const pinsightLibraryCheckbox = document.getElementById('modules[6]');

      if (event.target.checked) {
        // If the Skills is checked - Allow the Pinsight library and check it
        pinsightLibraryCheckbox.removeAttribute('disabled');
        pinsightLibraryCheckbox.click();
      } else {
        // If the Skills is unchecked - Uncheck (if checked) & Pinsight library
        if (pinsightLibraryCheckbox.checked) pinsightLibraryCheckbox.click();
        pinsightLibraryCheckbox.disabled = true;
      }
    }
  };

  render() {
    const hidden = this.props.hidden || [];
    const { disabled, languages, user, selectedPartner } = this.props;
    const { selected } = this.state;
    const size = hidden.length === 1 ? 5 : 4;
    const data = this.getModSimLan();

    let languageNames = [];

    if (user && user.has('languages')) {
      languageNames = user.get('languages').toArray();
    }

    const hasEnglishLanguage = languageNames.indexOf('english') !== -1;

    return (
      <Row>
        {hidden.indexOf(1) < 0 && (
          <Col xs={size}>
            <h5>
              <FormattedMessage id="MODULES" />
            </h5>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {data.userModules.map((module, index) => {
                let all = [];
                const disabledModule =
                  (module.get('name') === 'development' &&
                    user.has('is_development_disableable') &&
                    !user.get('is_development_disableable')) ||
                  (module.get('name') === 'simulations' &&
                    user.has('has_own_assessors') &&
                    !user.get('has_own_assessors')) ||
                  // Rendered checkbox is for Skills at the moment
                  (module.get('name') === 'skills' &&
                    // Check if the user has previously checked SimManager; if not, then disable the option Skills
                    !user
                      .get('modules')
                      .toArray()
                      .some(
                        (module) => module.get('name') === 'Simulations'
                      )) ||
                  (module.get('name') === 'pinsightlibrary' &&
                    // Check if the user has previously checked Skills; if not, then disable the option Pinsight skills library
                    !user
                      .get('modules')
                      .toArray()
                      .some((module) => {
                        return module.get('name') === 'Skills';
                      }));

                this.getModules(modules).map((m) => all.push(m.get('id')));
                // Shifting of indexes
                const newAll = [];
                Object.keys(all).forEach(function (key) {
                  const newkey = all[key] - 1;
                  newAll[newkey] = all[key];
                });
                all = newAll;
                return (
                  <InputGroup
                    key={index}
                    className={styles['checkbox-block']}
                    style={{
                      paddingLeft: module.has('indent')
                        ? `${module.get('indent') * 1.5}rem`
                        : '',
                      order: module.get('displayOrder')
                    }}
                    onChange={(event) => {
                      this.inputValidatonHandler(event, module);
                    }}
                  >
                    <Label check key={index}>
                      <Field
                        disabled={disabled || disabledModule}
                        name={`${modulesField}[${all.indexOf(
                          module.get('id')
                        )}]`}
                        component={renderInput}
                        readOnly={this.props.readOnly}
                        type="checkbox"
                      />
                      <FormattedMessage
                        id={`MODULE_VALUES.${module.get('name')}`}
                      />
                    </Label>
                  </InputGroup>
                );
              })}
            </div>

            <Col className="p-0">
              <h5>
                <FormattedMessage id="PSYCHOMETRICS" />
              </h5>
            </Col>
            {this.getSimulationsSection(data.userSimulations, selected, true)}
          </Col>
        )}
        {hidden.indexOf(2) < 0 && (
          <Col xs={size}>
            <h5>
              <FormattedMessage id="SIMULATION" />
            </h5>
            {this.getSimulationsSection(data.userSimulations, selected)}
          </Col>
        )}
        {hidden.indexOf(3) < 0 && (
          <Col xs={size}>
            <h5>
              <FormattedMessage id="LANGUAGE" />
            </h5>
            {data.userLanguages.map((language, index) => (
              <InputGroup key={index} className={styles['checkbox-block']}>
                <Label check key={index}>
                  <Field
                    disabled={disabled}
                    name={`${languageField}[${languages.indexOf(language)}]`}
                    component={renderInput}
                    readOnly={this.props.readOnly}
                    type="checkbox"
                  />
                  <FormattedMessage
                    id={`LANGUAGE_VALUES.${language.get('name')}`}
                  />
                </Label>
              </InputGroup>
            ))}
          </Col>
        )}
        {hidden.indexOf(4) < 0 &&
          hasEnglishLanguage &&
          selectedPartner === undefined &&
          data.userDebriefs.length > 0 && (
            <Col xs={size}>
              <h5>
                <FormattedMessage id="DEBRIEF" defaultMessage="Debrief" />
              </h5>
              {this.getDebriefsSection(data.userDebriefs)}
            </Col>
          )}
      </Row>
    );
  }
}
