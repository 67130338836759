// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';

// Components
import { Questionnaire, PracticeMode, LearningAptitudeTest } from './subviews';
import { setCookie, getCookie } from 'helpers';
// Styles
import styles from './styles.css';
import './styles.css';
import modals from '../../views/modals.css';
import api from '../../../../../api';

// Actions
// import { getRemainingTime } from 'helpers';

/**
 * Prework
 * @constructor
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    schedule: state.simulations.get('schedule')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class Prework extends Component {
  // Prop types
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired
  };

  state = {
    showAiModal: true
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { user, transitionTo } = this.props;

    if (
      user.has('stages') &&
      user.getIn(['stages', 'simulation5', 'completed']) === true
    ) {
      transitionTo('/dashboard');
    }

    /*
    if (schedule && schedule.has('start_time')) {
      this.preworkInterval = setInterval(() => {
        const remaining = getRemainingTime(schedule.get('start_time'));

        if (
          remaining.days +
            remaining.hours +
            remaining.minutes +
            remaining.seconds <
          0
        ) {
          return api()
            .get(`/api/v1/users/${user.get('id')}/stage/history/?notify=1`)
            .then(res => {
              if (!res.error) {
                const { body } = res;

                if (body.data.length === 0) {
                  console.log('tu som zle');
                  return (window.location = user.get('simulator_url'));
                } else {
                  location.reload();
                }
              } else {
                location.reload();
              }
            });
        }
      }, 1000);
    }
    */
  }

  /*
  componentWillUnmount() {
    clearInterval(this.preworkInterval);
  }
  */

  /**
   * Handle submit
   */
  handleSubmit = () => {
    const { user } = this.props;
    const activeSimualtion =
      user.has('active_simulation') && user.getIn(['active_simulation']);
    api().post('/api/v2/auditable-trail/chat-gpt-popup/', {
      active_simulation: activeSimualtion
    });
    setCookie('showaicookie', false, 1);
    this.setState({
      showAiModal: false
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { transitionTo, user } = this.props;
    const isPsychometricsOnly =
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'is_psychometrics_only']);
    const hasPsychometrics =
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'has_psychometrics']);
    let redirectPath = '/auth/invite/prework/questionnaire';

    if (!hasPsychometrics) {
      redirectPath = '/auth/invite/prework/practice-mode';
    }

    return (
      <div className={styles.prework}>
        <h2>
          {isPsychometricsOnly ? (
            <FormattedMessage id="WELCOME" />
          ) : (
            <FormattedMessage id="WELCOME_IN_PREWORK" />
          )}
        </h2>
        <p className={styles['prevork-intro']}>
          {isPsychometricsOnly ? (
            <FormattedMessage id="WELCOME_PSYCHOMETRICS_ONLY_INFO" />
          ) : (
            <FormattedMessage id="WELCOME_IN_PREWORK_INFO" />
          )}
        </p>

        <Row>
          <Col xs={{ size: 4, offset: 4 }} className={styles['submit-box']}>
            <Button
              id="prework_welcome_next_button"
              name="prework_welcome_next_button"
              color="primary-dark"
              onClick={() => transitionTo(redirectPath)}
            >
              <FormattedMessage id="NEXT" />
            </Button>
          </Col>
        </Row>

        <Row>
          {user.get('language') === 'english' && !getCookie('showaicookie') ? (
            <>
              <Modal
                style={{ 'z-index': '1026' }}
                isOpen={this.state.showAiModal}
                toggle={() => false}
                zIndex={1026}
              >
                <ModalHeader style={{ textAlign: 'center' }}>
                  Notice
                </ModalHeader>
                <ModalBody
                  className={modals['modal-body']}
                  style={{ textAlign: 'justify' }}
                >
                  <p
                    className={styles['prevork-intro']}
                    style={{
                      'font-size': '12px',
                      'font-style': 'italic',
                      'margin-top': '10px'
                    }}
                  >
                    Notice: We kindly request all participants to respect the
                    confidentiality and intellectual property rights of our
                    materials. Please refrain from copying, uploading, or
                    sharing Pinsight materials on external websites or AI
                    platforms, including ChatGPT, Microsoft Bing, Google Bard,
                    and others. Your cooperation in safeguarding our content is
                    greatly appreciated.
                  </p>
                </ModalBody>
                <ModalFooter style={{ textAlign: 'center' }}>
                  <Row>
                    <Col xs={12} className={modals['modal-button-col']}>
                      <Button
                        id="submit_delete"
                        name="submit_delete"
                        color="primary"
                        style={{ margin: '10px 0px' }}
                        onClick={this.handleSubmit}
                        className={modals['footer-button']}
                      >
                        <FormattedMessage id="CONFIRM" />
                      </Button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>
            </>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  }
}

Prework.Questionnaire = Questionnaire;
Prework.PracticeMode = PracticeMode;
Prework.LearningAptitudeTest = LearningAptitudeTest;
