import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import api from 'api';
import { getProgressValues } from 'helpers';

import ProgressCircle from '../../../../../Development/components/ProgressCircle';

import styles from './styles.css';
import './styles.css';

const WeeklyFocus = (props) => {
  const [exerciseFocus, setExerciseFocus] = useState();
  const [noFocusChosen, setNoFocusenChosen] = useState(false);
  const { user } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api().get(
          `/api/v1/analytics/development/current-focus/${user.get('id')}/`
        );
        setExerciseFocus(response.body);
      } catch (err) {
        if (err && err.body === 'ERRORS.WEEKLY_FOCUS_NOT_SET') {
          setExerciseFocus(null);
          setNoFocusenChosen(true);
        }
      }
    };

    fetchData();
  }, [user]);

  return (
    <div style={{ paddingLeft: '10px' }}>
      <h6 className={styles['section-header']}>Weekly Focus</h6>
      {noFocusChosen && (
        <p style={{ fontSize: '14px' }}>
          Help the participant select a weekly focus and exercise based on their
          interests, then refresh the page.
        </p>
      )}

      {exerciseFocus && (
        <>
          <div
            className={classNames(
              styles['weekly-focus-header'],
              exerciseFocus &&
                styles[
                  getProgressValues(
                    exerciseFocus.tbis_est_perc &&
                      exerciseFocus.tbis_est_perc !== 0
                      ? exerciseFocus.tbis_est_perc
                      : exerciseFocus.tbis_perc
                  ).position
                ]
            )}
          >
            <ProgressCircle
              value={
                exerciseFocus
                  ? exerciseFocus.tbis_est_perc &&
                    exerciseFocus.tbis_est_perc !== 0
                    ? exerciseFocus.tbis_est_perc
                    : exerciseFocus.tbis_perc
                  : null
              }
              focus={exerciseFocus}
            />
            <h5>
              {exerciseFocus.behavior.name} - Exercise{' '}
              {exerciseFocus.focus_index}
            </h5>
          </div>

          <div>
            <h6 style={{ marginTop: '5px' }}>Habit/Exercise:</h6>
            <p
              className={styles['weekly-focus-development-suggestion']}
              dangerouslySetInnerHTML={{
                __html: exerciseFocus.indicator.development_suggestion
              }}
            />
          </div>

          <hr />

          <div style={{ marginBottom: '7px' }}>
            <strong>{exerciseFocus.dli}</strong> day(s) practice left
          </div>

          <div>
            <h6>More Information:</h6>
            <p
              className={styles['weekly-focus-development-suggestion']}
              dangerouslySetInnerHTML={{
                __html: exerciseFocus.indicator.more_info
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyFocus;
