import enTranslate from './build/en.json';
import esTranslate from './build/es.json';
import ptTranslate from './build/pt.json';
import heTranslate from './build/he.json';
import skTranslate from './build/sk.json';
import nlTranslate from './build/nl.json';
import frTranslate from './build/fr.json';
import trTranslate from './build/tr.json';
import zhTranslate from './build/zh.json';
import koTranslate from './build/ko.json';
import idTranslate from './build/id.json';
import jaTranslate from './build/ja.json';
import arTranslate from './build/ar-sa.json';
import deTranslate from './build/de.json';

export const en = enTranslate;
export const es = esTranslate;
export const pt = ptTranslate;
export const he = heTranslate;
export const sk = skTranslate;
export const nl = nlTranslate;
export const fr = frTranslate;
export const tr = trTranslate;
export const zh = zhTranslate;
export const ko = koTranslate;
export const id = idTranslate;
export const ja = jaTranslate;
export const arsa = arTranslate;
export const de = deTranslate;
