import {
  EDIT_BEHAVIOR_REQUEST,
  EDIT_BEHAVIOR_SUCCESS,
  EDIT_BEHAVIOR_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Edit behavior action
 *
 * @param{Number} id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function editBehavior(id, data) {
  const result = data;

  if (!result.language) {
    result.language = 'english'; // eslint-disable-line
  }

  return {
    types: [
      EDIT_BEHAVIOR_REQUEST,
      EDIT_BEHAVIOR_SUCCESS,
      EDIT_BEHAVIOR_FAILURE
    ],
    promise: api().put(`/api/v1/behaviors/${id}/`, data),
    language: result.language,
    id
  };
}
