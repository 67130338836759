import {
  EDIT_PARTNER_REQUEST,
  EDIT_PARTNER_SUCCESS,
  EDIT_PARTNER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

import compareUserData from './compareUserData';

/**
 * Edit partner action
 *
 * @param{String} id
 * @param{Object} data
 * @param{Object} previousData
 * @returns {{types: *[], promise: Request }}
 */

export default function editPartner(id, data, previousData) {
  const reduced = {};
  Object.keys(data).map((item) => (reduced[item] = data[item]));

  Object.keys(reduced).map((key) => {
    if (!reduced[key] || compareUserData(reduced, previousData, key)) {
      return delete reduced[key];
    }

    return false;
  });

  if (previousData.has('brand_under_children') && !data.brand_under_children) {
    reduced.brand_under_children = false;
  }

  return {
    types: [EDIT_PARTNER_REQUEST, EDIT_PARTNER_SUCCESS, EDIT_PARTNER_FAILURE],
    promise: api().put(`/api/v1/partners/${id}/`, reduced),
    id
  };
}
