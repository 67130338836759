import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

import '../../styles.css';

import { EditAssessmentForm } from 'forms';

import { createAssessment } from 'actions/assessments';
import styles from '../../styles.css';

@connect(
  (state) => ({
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ createAssessment, transitionTo }, dispatch);
  }
)
export default class NewAssessment extends Component {
  static propTypes = {
    createAssessment: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  handleSubmit = (data) => {
    const { createAssessment, route } = this.props;
    const { addAlert } = this.context;
    const id = route.query.copy != null ? Number(route.query.copy) : null;

    createAssessment(data, id).then((action) => {
      if (
        action.error &&
        action.error.body &&
        typeof action.error.body === 'string'
      ) {
        addAlert({
          color: 'danger',
          content: <FormattedMessage id={action.error.body} />
        });
      }
    });
  };

  render() {
    return (
      <div>
        <div className={styles['link-back']}>
          <Link to="/customize" id="back_to_customize">
            &larr; <FormattedMessage id="BACK_TO_CUSTOMIZE" />
          </Link>
        </div>
        <h1 className={styles['new-assessment-heading']}>
          <FormattedMessage id="NEW_ASSESSMENT" />
        </h1>

        <EditAssessmentForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}
