// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// Components
import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

// Styles
import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

// Actions
import { strategies as strategiesAction } from 'actions/assessments';

/**
 * Strategies translate table
 * @class
 */
@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainAssessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    assessment: state.assessments.hasIn([
      'assessments',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.assessments.getIn([
          'assessments',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.assessments.getIn([
          'assessments',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ strategiesAction }, dispatch)
)
export default class StrategiesTranslateTable extends Component {
  // PropTypes
  static propTypes = {
    id: PropTypes.number,
    assessment: PropTypes.object,
    mainAssessment: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    strategiesAction: PropTypes.func.isRequired
  };

  /**
   * Get table
   * @returns {{data: Array, fields: *[]}}
   */
  getTable = (strategy) => {
    const {
      assessment,
      mainAssessment,
      hasError,
      disabled,
      version
    } = this.props;
    let data = [];

    if (strategy) {
      data = [
        { key: 'name', value: strategy.get('name') },
        { key: 'description', value: strategy.get('description') }
      ];
    }

    return {
      data,
      customClassName: 'customize-behaviors-table',
      fields: [
        {
          key: 'empty',
          dark: true,
          sortable: false,
          renderField: (item) => (
            <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
          )
        },
        {
          key: 'customize_strategy',
          sortable: false,
          locked: true,
          renderField: (item) => item.value
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let defaultValue = null;
            let translatedStrategy;
            let mainStrategy = mainAssessment
              .get('strategies')
              .find((s) => s.get('id') === strategy.get('id'));

            if (assessment.has('strategies')) {
              translatedStrategy = assessment
                .get('strategies')
                .find((s) => s.get('id') === strategy.get('id'));
              mainStrategy = mainAssessment
                .get('strategies')
                .find((s) => s.get('id') === strategy.get('id'));

              if (translatedStrategy && translatedStrategy.has(item.key)) {
                defaultValue = translatedStrategy.get(item.key) || null;
              }
            }
            const allowTranslation = true;

            return (
              <td
                className={classNames(
                  hasError &&
                    !defaultValue &&
                    version !== 'english' &&
                    mainStrategy.has(item.key) &&
                    styles.error
                )}
              >
                <TranslationRow
                  id={item.value}
                  translationKey={
                    item.key === 'name'
                      ? 'ADD_NEW_LABEL'
                      : 'ADD_NEW_DESCRIPTION'
                  }
                  defaultValue={defaultValue}
                  onSubmit={(e) =>
                    this.handleSubmit(
                      e,
                      item,
                      translatedStrategy || mainStrategy
                    )
                  }
                  disabled={
                    disabled ||
                    !allowTranslation ||
                    (version !== 'english' && !mainStrategy.has(item.key))
                  }
                />
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * Handle submit
   * @param data
   * @param field
   * @param strategy
   */
  handleSubmit = (data, field, strategy) => {
    const { id, strategiesAction, version } = this.props;

    const translated = {
      language: version,
      [field.key]: data.translation
    };

    strategiesAction({
      type: 'edit',
      assessmentId: id,
      id: strategy.get('id'),
      data: translated
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { assessment, mainAssessment } = this.props;

    if (!assessment) {
      return <div />;
    }

    return (
      <div>
        {mainAssessment.has('strategies') &&
        mainAssessment.get('strategies').size > 0 ? (
          mainAssessment
            .get('strategies')
            .toArray()
            .map((strategy, i) => (
              <BuildTable
                table={this.getTable(strategy)}
                tableKey={i + 1}
                key={i}
                total={mainAssessment.get('strategies').size}
              />
            ))
        ) : (
          <BuildTable table={this.getTable(null)} tableKey={1} />
        )}
      </div>
    );
  }
}
