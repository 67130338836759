import {
  MASS_TPA_REQUEST,
  MASS_TPA_SUCCESS,
  MASS_TPA_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function massTPA(selected, is_tpa_enabled) {
  return {
    types: [MASS_TPA_REQUEST, MASS_TPA_SUCCESS, MASS_TPA_FAILURE],
    promise: api().post('/api/v1/mass/tpa/', { selected, is_tpa_enabled }),
    selected,
    is_tpa_enabled
  };
}
