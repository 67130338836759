import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change, Field } from 'redux-form';

import styles from '../../styles.css';
import '../../styles.css';

import { InputGroup, Label, Col, Row } from 'reactstrap';
import { ToolTip } from 'components';

import { validate } from './validate';
import { renderInput } from '../../actions';

const form = 'narrativeForm';

@reduxForm({
  form,
  validate
})
@connect(
  (state, ownProps) => ({
    isLoading: state.simulations.get('emailLoading'),
    formErrors: state.app.get('formErrors') || {},
    readOnly: state.router.route.query.readOnly,
    report: state.analytics.getIn(['allData', ownProps.report.get('id')])
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class Narrative extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    report: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: RichTextEditor.createEmptyValue()
    };
  }

  componentDidMount() {
    this.setFormData();
  }

  onChange = (value) => {
    const { change, onValueChange } = this.props;

    this.setState({
      value
    });
    const stringValue =
      value.toString('html') === '<p><br></p>' ? ' ' : value.toString('html');
    change(form, 'narrative', stringValue);
    onValueChange(stringValue);
  };

  setFormData = () => {
    const { defaultValue, report, change, data } = this.props;

    if (defaultValue != null) {
      change(form, 'narrative', defaultValue);

      this.setState({
        value: RichTextEditor.createValueFromString(defaultValue, 'html')
      });
    } else if (report) {
      change(
        form,
        'narrative',
        report.getIn(['narrative', 'narrative']) || ' '
      );

      this.setState({
        value: RichTextEditor.createValueFromString(
          report.hasIn(['narrative', 'narrative'])
            ? report.getIn(['narrative', 'narrative'])
            : '',
          'html'
        )
      });
    }

    if (data.author_name != null) {
      change(form, 'author_name', data.author_name);
    }

    if (data.author_company != null) {
      change(form, 'author_company', data.author_company);
    }
  };

  render() {
    const { value } = this.state;
    const { formErrors, handleSubmit, readOnly } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        className={styles.form}
        style={{ marginBottom: 20 }}
      >
        <Row>
          <Col xs={12}>
            <h5>
              <FormattedMessage id="TEXT" />
            </h5>
          </Col>
          <Col xs={12}>
            <InputGroup id="narrative_text">
              <RichTextEditor
                value={value}
                onChange={this.onChange}
                className={classNames(styles.rte, readOnly && styles.disabled)}
                disabled={readOnly}
              />
            </InputGroup>

            {formErrors.narrative && (
              <div className={styles.form__error}>
                {formErrors.narrative.errors.map((e) => (
                  <div>
                    <FormattedMessage id={e} />
                  </div>
                ))}
              </div>
            )}
          </Col>

          <Col xs={12}>
            <h5 className={styles['author-heading']}>
              <FormattedMessage id="AUTHOR" />
            </h5>
            <div className={styles.tooltip}>
              <ToolTip
                index="tooltip"
                hint={<FormattedMessage id="NARRATIVE_AUTHOR_TOOLTIP" />}
              />
            </div>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <Label>
                <FormattedMessage id="FORM_FIELDS.name" /> (
                <FormattedMessage id="REQUIRED" />)
              </Label>
              <Field
                name="author_name"
                component={renderInput}
                type="text"
                placeholder="AUTHOR_NAME"
                translatePlaceholder
              />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <Label>
                <FormattedMessage id="FORM_FIELDS.company" /> (
                <FormattedMessage id="REQUIRED" />)
              </Label>
              <Field
                name="author_company"
                component={renderInput}
                type="text"
                placeholder="COMPANY_NAME"
                translatePlaceholder
              />
            </InputGroup>
          </Col>
        </Row>
      </form>
    );
  }
}
