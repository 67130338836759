/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';

// Styles
import styles from '../../../styles.css';
import '../../../styles.css';

// Validation
import { validate } from './validate';

// Components
import { Button, Form, FormGroup, Label, Col, Row } from 'reactstrap';

// Actions
import { renderInput } from '../../../actions';

// Const
const form = 'editBehaviorDefinition';

/**
 * Edit basic information form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    behaviorsLoading: state.app.get('simulationsLoading'),
    behavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    isLoading: state.behaviors.get('editBehaviorLoading')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class Definition extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    behavior: PropTypes.object,
    behaviorsLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { change, behavior } = this.props;

    change(form, 'definition', behavior.get('definition'));
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { handleSubmit, isLoading, transitionTo, behavior } = this.props;
    const disabled = behavior
      .get('languages')
      .find((l) => l.get('language') === 'english')
      .get('published');

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={8}>
            <FormGroup>
              <Label htmlFor="definition">
                <FormattedMessage id="FORM_FIELDS.definition" />
              </Label>
              <Field
                name="definition"
                component={renderInput}
                type="textarea"
                disabled={disabled}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="cancel_behavior_definition"
              name="cancel_behavior_definition"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/simulations', { tab: '2' })}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_behavior_definition"
              name="submit_behavior_definition"
              type="button"
              color="primary"
              onClick={handleSubmit}
              disabled={disabled}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
