// Components
import { fetchAssignment, fetchScoredAssignments } from 'actions/assignments';
import { Assignments, Users } from '../views';

// Actions
import { onDetailEnterHandler } from './actions';
import { getUser, getPartner, getClient } from '../actions/users';

const UsersRoutes = [
  {
    path: '/',
    component: Users.Preview
  },
  {
    path: '/partner/create',
    component: Users.Create,
    attrs: { role: 'partner' }
  },
  {
    path: '/client/create',
    component: Users.Create,
    attrs: { role: 'client' }
  },
  {
    path: '/partner/edit/:id',
    component: Users.Edit,
    attrs: { role: 'partner', edit: true },
    onEnter: onDetailEnterHandler(getPartner)
  },
  {
    path: '/client/edit/:id',
    component: Users.Edit,
    attrs: { role: 'client', edit: true },
    onEnter: onDetailEnterHandler(getClient)
  },
  {
    path: '/assessor/create',
    component: Users.Create,
    attrs: { role: 'assessor' }
  },
  {
    path: '/admin/create',
    component: Users.Create,
    attrs: { role: 'admin' }
  },
  {
    path: '/admin/invite',
    component: Users.Invite,
    attrs: { role: 'admin' }
  },
  {
    path: '/stakeholder/create',
    component: Users.Create,
    attrs: { role: 'stakeholder' }
  },
  {
    path: '/stakeholder/invite',
    component: Users.Invite,
    attrs: { role: 'stakeholder' }
  },
  {
    path: '/participant/create',
    component: Users.Create,
    attrs: { role: 'participant' }
  },
  {
    path: '/participant/invite',
    component: Users.Invite,
    attrs: { role: 'participant' }
  },
  {
    path: '/edit/:id',
    component: Users.Edit,
    onEnter: onDetailEnterHandler(getUser),
    attrs: { edit: true }
  },
  {
    path: '/score/:id',
    component: Assignments.Score,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const { id } = nextRoute.vars;
      const state = getState();

      if (state.assignments.hasIn(['assignments', Number(id), 'excercises'])) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        dispatch(fetchAssignment(Number(id))).then((action) => {
          dispatch(
            fetchScoredAssignments(
              action.result.body.user.id,
              action.result.body.id
            )
          ).then(() => resolve());
        });
      });
    }
  }
];

export default UsersRoutes;
