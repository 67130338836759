// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Styles
import styles from './styles.css';
import './styles.css';

// Components
import { Input } from 'reactstrap';

// Actions
import handleSelect from './handleSelect';

/**
 * Search selection
 * @class
 */
@connect(
  (state) => ({
    formErrors: state.app.get('formErrors') || {}
  }),
  (dispatch) => bindActionCreators({ dispatch }, dispatch)
)
export default class SearchSelection extends Component {
  // Prop types
  static propTypes = {
    data: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    showValue: PropTypes.string.isRequired,
    selectValue: PropTypes.string.isRequired,
    action: PropTypes.func,
    name: PropTypes.string.isRequired,
    form: PropTypes.string,
    searchable: PropTypes.bool,
    constant: PropTypes.bool,
    defaultValue: PropTypes.string,
    translationKey: PropTypes.string,
    placeholder: PropTypes.string,
    translatePlaceholder: PropTypes.bool,
    formErrors: PropTypes.object.isRequired,
    subOwner: PropTypes.bool,
    disabled: PropTypes.bool,
    recipient: PropTypes.bool
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      selected: this.props.defaultValue || '',
      visible: false,
      focused: 0
    };

    this.clickHandler = this.clickHandler.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { action } = this.props;
    if (action) {
      action(0, 15);
    }

    window.addEventListener('click', this.clickHandler, false);
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.selectItem.bind(this, nextProps.defaultValue)();
    }
  }

  /**
   * Component did update
   */
  componentDidUpdate() {
    const { focused } = this.state;
    const filtered = this.getFiltered();

    if (filtered.length > 0) {
      this.refs.ul.scrollTop = this.refs[`item${focused}`].offsetTop - 60;
    }
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    window.removeEventListener('click', this.clickHandler, false);
  }

  /**
   * Get filtered
   * @returns {*}
   */
  getFiltered = () => {
    const { data, constant, showValue, recipient } = this.props;
    const { query } = this.state;

    if (recipient) {
      return data.filter(
        (item) =>
          item &&
          item.get(showValue) &&
          `${item.get(showValue)} ${item.get('firstname')} ${item.get(
            'surname'
          )}`
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
      );
    }

    if (constant) {
      return data.filter(
        (item) => item && item.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    }

    return data.filter(
      (item) =>
        item &&
        item.get(showValue) &&
        item.get(showValue).toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  /**
   * Click handler
   * @param e
   */
  clickHandler(e) {
    const { name } = this.props;
    const area = ReactDOM.findDOMNode(this.refs[name]);

    if (!area.contains(e.target)) {
      this.setState({ visible: false, focused: 0 });
      window.removeEventListener('keydown', this.keyDownHandler, false);
    }
  }

  /**
   * Key down handler
   * @param e
   */
  keyDownHandler(e) {
    const { constant, selectValue } = this.props;
    const { focused } = this.state;
    const filtered = this.getFiltered();

    switch (e.keyCode) {
      case 27:
        e.preventDefault();
        this.setState({ visible: false });
        break;
      case 38:
        e.preventDefault();
        this.setState({
          focused: focused === 0 ? filtered.length - 1 : focused - 1
        });
        break;
      case 40:
        e.preventDefault();
        this.setState({
          focused: focused === filtered.length - 1 ? 0 : focused + 1
        });
        break;
      case 13:
        e.preventDefault();
        this.selectItem.bind(
          this,
          constant
            ? filtered[focused]
            : filtered[focused] != null && filtered[focused].has(selectValue)
            ? filtered[focused].get(selectValue)
            : null
        )();
        break;
      default:
        break;
    }
  }

  /**
   * Handle change
   * @param e
   */
  handleChange(e) {
    const { action } = this.props;

    if (action) {
      action(0, 15, e.target.value);
    }
    this.setState({
      query: e.target.value,
      focused: 0
    });
  }

  /**
   * Select item
   * @param item
   */
  selectItem(item) {
    const { name, searchable, form, dispatch } = this.props;
    handleSelect(name, item, form, dispatch, this.context);
    this.setState({
      selected: searchable ? '' : item,
      visible: false,
      focused: 0
    });
    window.removeEventListener('keydown', this.keyDownHandler, false);
  }

  /**
   * Handle focus
   */
  handleFocus = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({ visible: true, query: '' });
      window.addEventListener('keydown', this.keyDownHandler, false);

      setTimeout(() => {
        this.input.focus();
      }, 100);
    }
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      data,
      recipient,
      selectValue,
      showValue,
      name,
      constant,
      translationKey,
      placeholder,
      translatePlaceholder,
      formErrors,
      subOwner,
      disabled
    } = this.props;
    const { query, selected, visible, focused } = this.state;
    const { intl } = this.context;
    const fieldName = subOwner ? name.split('.')[1] : name;
    const errorSelector = subOwner ? formErrors.owner : formErrors;
    let finalPlaceholder = '';
    let hasServerError = false;

    if (
      errorSelector &&
      errorSelector[fieldName] &&
      errorSelector[fieldName].errors
    ) {
      hasServerError = true;
    }

    if (placeholder) {
      if (translatePlaceholder) {
        finalPlaceholder = intl.formatMessage({ id: placeholder });
      } else {
        finalPlaceholder = placeholder;
      }
    }

    if (recipient) {
      let fullName = null;
      let hasFilledName = false;

      if (selected) {
        const user = data.find((u) => u.get('id') === selected);
        hasFilledName =
          user.has('firstname') &&
          user.get('firstname') !== '' &&
          user.get('firstname') !== ' ';

        fullName = hasFilledName
          ? `${user.get('firstname')} ${user.get('surname')}`
          : user.get('email');
      }

      return (
        <div
          className={classNames(
            styles['search-selection'],
            hasFilledName && styles.capitalize
          )}
          ref={name}
        >
          <div onClick={this.handleFocus}>
            <Input
              id={fieldName}
              name={fieldName}
              type="text"
              className={classNames(hasServerError && styles.error_input)}
              value={fullName}
              placeholder={finalPlaceholder}
              disabled={disabled}
            />
          </div>
          <nav className={classNames(visible && styles.visible)}>
            <Input
              id={`${fieldName}-search`}
              name={`${fieldName}-search`}
              className={styles.plain}
              type="text"
              onChange={this.handleChange.bind(this)}
              value={query}
              getRef={(input) => (this.input = input)}
            />
            <ul ref="ul" style={{ marginTop: 10 }}>
              {data
                .filter(
                  (item) =>
                    item &&
                    item.get(showValue) &&
                    `${item.get(showValue)} ${item.get('firstname')} ${item.get(
                      'surname'
                    )}`
                      .toLowerCase()
                      .indexOf(query.toLowerCase()) > -1
                )
                .map((item, i) => {
                  const username =
                    item.get('firstname') == null || item.get('surname') == null
                      ? ''
                      : `${item.get('firstname')} ${item.get('surname')}`;

                  return (
                    <li
                      onClick={this.selectItem.bind(
                        this,
                        item.get(selectValue)
                      )}
                      className={classNames(focused === i && styles.focused)}
                      ref={`item${i}`}
                      key={i}
                    >
                      {`${username} <${item.get(showValue)}>`}
                    </li>
                  );
                })}
            </ul>
          </nav>
          {hasServerError &&
            errorSelector[fieldName].errors.map((error) => (
              <div className={styles.form__error}>
                {error.indexOf('ERRORS') > -1 ? (
                  <FormattedMessage id={error} />
                ) : (
                  error
                )}
              </div>
            ))}
        </div>
      );
    }

    return (
      <div className={styles['search-selection']} ref={name}>
        <div onClick={this.handleFocus}>
          <Input
            id={fieldName}
            name={fieldName}
            type="text"
            className={classNames(hasServerError && styles.error_input)}
            value={
              translationKey && selected
                ? intl.formatMessage({ id: `${translationKey}.${selected}` })
                : selected
            }
            placeholder={finalPlaceholder}
            disabled={disabled}
          />
        </div>
        <nav className={classNames(visible && styles.visible)}>
          <Input
            id={`${fieldName}-search`}
            name={`${fieldName}-search`}
            type="text"
            onChange={this.handleChange.bind(this)}
            value={query}
            getRef={(input) => (this.input = input)}
            aria-labelledby="timezone-label"
          />
          <ul ref="ul">
            {constant
              ? data
                  .filter(
                    (item) =>
                      item &&
                      item.toLowerCase().indexOf(query.toLowerCase()) > -1
                  )
                  .map((item, i) => (
                    <li
                      id={item}
                      onClick={this.selectItem.bind(this, item)}
                      className={classNames(focused === i && styles.focused)}
                      ref={`item${i}`}
                      key={i}
                    >
                      {translationKey ? (
                        <FormattedMessage id={`${translationKey}.${item}`} />
                      ) : (
                        item
                      )}
                    </li>
                  ))
              : data
                  .filter(
                    (item) =>
                      item &&
                      item.get(showValue) &&
                      item
                        .get(showValue)
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) > -1
                  )
                  .map((item, i) => (
                    <li
                      onClick={this.selectItem.bind(this, item.get(showValue))}
                      className={classNames(focused === i && styles.focused)}
                      ref={`item${i}`}
                      key={i}
                    >
                      {translationKey ? (
                        <FormattedMessage
                          id={`${translationKey}.${item.get(showValue)}`}
                        />
                      ) : (
                        item.get(showValue)
                      )}
                    </li>
                  ))}
          </ul>
        </nav>
        {hasServerError &&
          errorSelector[fieldName].errors.map((error) => (
            <div className={styles.form__error}>
              {error.indexOf('ERRORS') > -1 ? (
                <FormattedMessage id={error} />
              ) : (
                error
              )}
            </div>
          ))}
      </div>
    );
  }
}
