import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AND, OR } from 'constants/operators';
import { isEmptyOrSpaces } from 'helpers';

import Tag from './Tag';
import Input from './Input';
import Suggestions from './Suggestions';

const KEYS = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40
};

const CLASS_NAMES = {
  filteringOptions: 'react-tags__filtering-options',
  root: 'react-tags',
  rootFocused: 'is-focused',
  selected: 'react-tags__selected',
  selectedTag: 'react-tags__selected-tag',
  selectedTagName: 'react-tags__selected-tag-name',
  search: 'react-tags__search',
  searchInput: 'react-tags__search-input',
  suggestions: 'react-tags__suggestions',
  suggestionActive: 'is-active',
  suggestionDisabled: 'is-disabled'
};

const OPERATORS = [AND, OR];

export default class ReactTags extends React.Component {
  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  static defaultProps = {
    disabled: false,
    tags: [],
    placeholder: 'ADD_NEW_TAG',
    suggestions: [],
    autofocus: true,
    autoresize: true,
    delimiters: [KEYS.TAB, KEYS.ENTER],
    delimiterChars: [],
    minQueryLength: 2,
    maxSuggestionsLength: 6,
    allowNew: false,
    allowBackspace: true,
    tagComponent: null,
    onCreateNew: null
  };

  static propTypes = {
    disabled: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    suggestions: PropTypes.arrayOf(PropTypes.object),
    autofocus: PropTypes.bool,
    autoresize: PropTypes.bool,
    delimiters: PropTypes.arrayOf(PropTypes.number),
    delimiterChars: PropTypes.arrayOf(PropTypes.string),
    handleDelete: PropTypes.func.isRequired,
    handleAddition: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    minQueryLength: PropTypes.number,
    maxSuggestionsLength: PropTypes.number,
    classNames: PropTypes.object,
    allowNew: PropTypes.bool,
    allowBackspace: PropTypes.bool,
    tagComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    onCreateNew: PropTypes.func,
    withClearButton: PropTypes.bool,
    filterTagsInput: PropTypes.bool,
    onClear: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      focused: false,
      expandable: false,
      selectedIndex: -1,
      classNames: Object.assign({}, CLASS_NAMES, this.props.classNames),
      filteringMode: OR.name,
      inputPosition: this.props.tags.length
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      classNames: Object.assign({}, CLASS_NAMES, newProps.classNames)
    });
  }

  handleInput = (e) => {
    const query = e.target.value;

    if (this.props.handleInputChange) {
      this.props.handleInputChange(query);
    }

    this.setState({ query });
  };

  handleKeyDown = (e) => {
    const { query, selectedIndex } = this.state;
    const { delimiters, delimiterChars } = this.props;

    // when one of the terminating keys is pressed, add current query to the tags.
    if (
      delimiters.indexOf(e.keyCode) > -1 ||
      delimiterChars.indexOf(e.key) > -1
    ) {
      e.preventDefault();

      if (query.length >= this.props.minQueryLength) {
        // Check if the user typed in an existing suggestion.
        const match = this.suggestions.state.options.findIndex(
          (suggestion) =>
            suggestion.name.search(new RegExp(`^${query}$`, 'i')) === 0
        );

        const index = selectedIndex === -1 ? match : selectedIndex;

        if (index > -1) {
          this.addTag(this.suggestions.state.options[index]);
        } else if (this.props.allowNew) {
          this.addTag({ name: query });
        }
      }
    }

    // when backspace key is pressed and query is blank, delete the last tag
    if (
      e.keyCode === KEYS.BACKSPACE &&
      query.length === 0 &&
      this.props.allowBackspace
    ) {
      this.deleteTag(
        this.state.inputPosition - 1,
        this.props.tags[this.props.tags.length - 1]
      );
    }

    if (e.keyCode === KEYS.UP_ARROW) {
      e.preventDefault();

      // if last item, cycle to the bottom
      if (selectedIndex <= 0) {
        this.setState({
          selectedIndex: this.suggestions.state.options.length - 1
        });
      } else {
        this.setState({ selectedIndex: selectedIndex - 1 });
      }
    }

    if (e.keyCode === KEYS.DOWN_ARROW) {
      e.preventDefault();

      this.setState({
        selectedIndex:
          (selectedIndex + 1) % this.suggestions.state.options.length
      });
    }

    if (e.keyCode === KEYS.LEFT_ARROW && query.length === 0) {
      e.preventDefault();

      if (this.props.tags.length > 0 && this.state.inputPosition > 0) {
        this.setState({
          inputPosition: this.state.inputPosition - 1
        });

        this.focusInput();
      }
    }

    if (e.keyCode === KEYS.RIGHT_ARROW && query.length === 0) {
      e.preventDefault();

      if (this.props.tags.length > this.state.inputPosition) {
        this.setState({
          inputPosition: this.state.inputPosition + 1
        });

        this.focusInput();
      }
    }
  };

  handleClick = (e) => {
    if (document.activeElement !== e.target) {
      this.setState({ inputPosition: this.props.tags.length });
      this.focusInput();
    }
  };

  handleTagClick = (e, index) => {
    if (document.activeElement !== e.target) {
      e.stopPropagation();

      this.setState({ inputPosition: index });
      this.focusInput();
    }
  };

  handleBlur = () => {
    this.setState({ focused: false, selectedIndex: -1 });

    if (this.props.handleBlur) {
      this.props.handleBlur();
    }
  };

  handleFocus = () => {
    this.setState({ focused: true });

    if (this.props.handleFocus) {
      this.props.handleFocus();
    }
  };

  handleAddition = (tag) => {
    this.props.handleAddition(tag, this.state.inputPosition);
    this.setState({ inputPosition: this.state.inputPosition + 1 });
    this.focusInput();
  };

  addTag = (tag) => {
    if (tag && isEmptyOrSpaces(tag.name)) {
      return;
    }

    if (tag.disabled) {
      return;
    }

    // Autocomplete default operator tag
    if (
      this.props.filterTagsInput &&
      this.props.tags.length > 0 &&
      this.state.inputPosition > 0 &&
      this.props.tags[this.state.inputPosition - 1] != null &&
      !OPERATORS.find(
        (o) =>
          o.name === tag.name ||
          o.name === this.props.tags[this.state.inputPosition - 1].name
      )
    ) {
      this.handleAddition(
        OPERATORS.find((o) => o.name === this.state.filteringMode)
      );
    }

    setTimeout(() => {
      this.handleAddition(tag);
    }, 10);

    // reset the state
    this.setState({
      query: '',
      selectedIndex: -1
    });
  };

  deleteTag = (i, tag) => {
    if (i == null || tag == null) {
      return false;
    }

    this.props.handleDelete(i, tag);
    this.setState({ query: '', inputPosition: i });
    this.focusInput();

    return true;
  };

  focusInput = () => {
    setTimeout(() => {
      if (this.input != null) {
        this.input.input.focus();
      }
    }, 10);
  };

  resetInput = (newPosition) => {
    this.setState({
      query: '',
      selectedIndex: -1,
      inputPosition: newPosition != null ? newPosition : this.props.tags.length
    });

    return true;
  };

  getTags = () => {
    const { intl } = this.context;
    const listboxId = 'ReactTags-listbox';

    const TagComponent = this.props.tagComponent || Tag;
    const expandable =
      this.state.focused &&
      this.state.query.length >= this.props.minQueryLength;

    const tags = this.props.tags.map((tag, i) => (
      <TagComponent
        key={i}
        tag={tag}
        classNames={this.state.classNames}
        onDelete={() => this.deleteTag(i, tag)}
        onClick={(e) => this.handleTagClick(e, i)}
      />
    ));
    tags.splice(
      this.state.inputPosition,
      0,
      <div
        key={this.props.tags.length + 1}
        className={this.state.classNames.search}
        onBlurCapture={this.handleBlur}
        onFocusCapture={this.handleFocus}
        onInput={this.handleInput}
        onKeyDown={this.handleKeyDown}
      >
        <Input
          {...this.state}
          ref={(c) => {
            this.input = c;
          }}
          listboxId={listboxId}
          autofocus={this.props.autofocus}
          autoresize={this.props.autoresize}
          expandable={expandable}
          placeholder={intl.formatMessage({ id: this.props.placeholder })}
          disabled={this.props.disabled}
        />
        <Suggestions
          {...this.state}
          ref={(c) => {
            this.suggestions = c;
          }}
          listboxId={listboxId}
          expandable={expandable}
          suggestions={this.props.suggestions}
          addTag={this.addTag}
          maxSuggestionsLength={this.props.maxSuggestionsLength}
          onCreateNew={
            this.props.onCreateNew
              ? (data) => {
                  this.setState({
                    query: '',
                    selectedIndex: -1,
                    inputPosition: this.state.inputPosition + 1
                  });
                  this.focusInput();

                  return this.props.onCreateNew(data, this.state.inputPosition);
                }
              : false
          }
        />
      </div>
    );

    return tags;
  };

  onClear = (e) => {
    e.stopPropagation();
    this.props.onClear();
    this.resetInput(0);
    this.focusInput();
  };

  render() {
    const classNames = [this.state.classNames.root].concat([
      this.props.disabled && 'disabled'
    ]);

    if (this.state.focused) {
      classNames.push(this.state.classNames.rootFocused);
    }

    return (
      <div
        style={{
          position: 'relative'
        }}
      >
        <div className={classNames.join(' ')} onClick={this.handleClick}>
          <div
            className={this.state.classNames.selected}
            aria-live="polite"
            aria-relevant="additions removals"
          >
            {this.getTags()}
          </div>
          {this.props.withClearButton && (
            <span className="clear-button" onClick={this.onClear}>
              <FormattedMessage id="CLEAR" />
            </span>
          )}
        </div>
      </div>
    );
  }
}
