import {
  FETCH_ASSESSMENTS_REQUEST,
  FETCH_ASSESSMENTS_SUCCESS,
  FETCH_ASSESSMENTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch assessments action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchAssessments(query, sort, offset, limit, language) {
  const string = `?q=${query ? encodeURIComponent(query) : ''}&offset=${
    offset || 0
  }&limit=all&language=${language || 'english'}`; // eslint-disable-line

  return {
    types: [
      FETCH_ASSESSMENTS_REQUEST,
      FETCH_ASSESSMENTS_SUCCESS,
      FETCH_ASSESSMENTS_FAILURE
    ],
    promise: api().get(`/api/v1/assessment/${string}`),
    query
  };
}
