import { Map, fromJS } from 'immutable';
import {
  FETCH_DEBRIEFS_REQUEST,
  FETCH_DEBRIEFS_SUCCESS,
  FETCH_DEBRIEFS_FAILURE,
  DELETE_DEBRIEF_SUCCESS,
  CREATE_DEBRIEF_REQUEST,
  CREATE_DEBRIEF_SUCCESS,
  CREATE_DEBRIEF_FAILURE,
  EDIT_DEBRIEF_REQUEST,
  EDIT_DEBRIEF_SUCCESS,
  EDIT_DEBRIEF_FAILURE,
  FETCH_DEBRIEF_REQUEST,
  FETCH_DEBRIEF_SUCCESS,
  FETCH_DEBRIEF_FAILURE,
  FETCH_DEBRIEF_DOCUMENTS_REQUEST,
  FETCH_DEBRIEF_DOCUMENTS_SUCCESS,
  FETCH_DEBRIEF_DOCUMENTS_FAILURE,
  CREATE_DEBRIEF_INSTRUCTION_REQUEST,
  CREATE_DEBRIEF_INSTRUCTION_SUCCESS,
  CREATE_DEBRIEF_INSTRUCTION_FAILURE,
  EDIT_DEBRIEF_INSTRUCTION_REQUEST,
  EDIT_DEBRIEF_INSTRUCTION_SUCCESS,
  EDIT_DEBRIEF_INSTRUCTION_FAILURE,
  DELETE_DEBRIEF_INSTRUCTION_SUCCESS,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  debriefs: Map(),
  filtered: Map(),
  schedule: Map()
});

export default function debriefs(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEBRIEFS_REQUEST:
      return state.set('debriefsLoading', true);

    case FETCH_DEBRIEFS_SUCCESS: {
      const debriefs = action.result.body.data;
      const { language } = action;

      return state.withMutations((newState) => {
        newState
          .set('debriefsLoading', false)
          .set('debriefsTotal', action.result.body.filtered)
          .set(
            'filtered',
            Map(debriefs.map((debrief) => [debrief.id, fromJS(debrief)]))
          )
          .mergeDeepIn(
            ['debriefs', language],
            Map(debriefs.map((debrief) => [debrief.id, fromJS(debrief)]))
          );
      });
    }

    case FETCH_DEBRIEFS_FAILURE:
      return state.set('debriefsLoading', false);

    case DELETE_DEBRIEF_SUCCESS: {
      const id = Number(action.id);
      const language = action.language || 'english';

      return state
        .deleteIn(['debriefs', language, id])
        .set('debriefsTotal', state.get('debriefsTotal') - 1);
    }

    case CREATE_DEBRIEF_REQUEST:
      return state.set('createDebriefLoading', true);

    case CREATE_DEBRIEF_SUCCESS: {
      const debrief = action.result.body;
      const { language } = action;

      return state
        .set('createDebriefLoading', false)
        .set('debriefsTotal', state.get('debriefsTotal') + 1)
        .mergeIn(['debriefs', language, debrief.id], fromJS(debrief));
    }

    case CREATE_DEBRIEF_FAILURE:
      return state.set('createDebriefLoading', false);

    case EDIT_DEBRIEF_REQUEST:
      return state.set('editDebriefLoading', true);

    case EDIT_DEBRIEF_SUCCESS: {
      const debrief = action.result.body;
      const id = Number(action.id);
      const { language } = action;

      return state.withMutations((newState) => {
        if (language === 'english') {
          newState.setIn(
            ['debriefs', language, id, 'languages'],
            fromJS(debrief.languages)
          );
        }

        newState
          .set('editDebriefLoading', false)
          .mergeIn(['debriefs', language, id], fromJS(debrief))
          .setIn(
            ['debriefs', 'english', id, 'languages'],
            fromJS(debrief.languages)
          );
      });
    }

    case EDIT_DEBRIEF_FAILURE: {
      const debrief =
        typeof action.error.body === 'string'
          ? { error: action.error.body }
          : action.error.body;
      const id = Number(action.id);
      const { language } = action;

      return state.withMutations((newState) => {
        if (language === 'english' && debrief.languages != null) {
          newState.setIn(
            ['debriefs', language, id, 'languages'],
            fromJS(debrief.languages)
          );
        }

        if (debrief && debrief.name && debrief.name.errors) {
          newState.set('editDebriefLoading', false);
        } else {
          newState
            .set('editDebriefLoading', false)
            .mergeIn(['debriefs', language, id], fromJS(debrief));
        }
      });
    }

    case FETCH_DEBRIEF_REQUEST:
      return state.set('debriefsLoading', true);

    case FETCH_DEBRIEF_SUCCESS: {
      const { language } = action;
      const debrief = action.result.body;

      return state.withMutations((newState) => {
        newState
          .set('debriefsLoading', false)
          .mergeDeepIn(['debriefs', language, debrief.id], fromJS(debrief));
      });
    }

    case FETCH_DEBRIEF_FAILURE:
      return state.set('debriefsLoading', false);

    case FETCH_DEBRIEF_DOCUMENTS_REQUEST:
      return state.set('debriefDocumentsLoading', true);

    case FETCH_DEBRIEF_DOCUMENTS_SUCCESS: {
      const id = Number(action.id);
      const documents = action.result.body.data;
      const { language } = action;

      return state
        .set('debriefDocumentsLoading', false)
        .setIn(
          ['debriefs', language, id, 'documents'],
          Map(documents.map((document) => [document.id, fromJS(document)]))
        );
    }

    case FETCH_DEBRIEF_DOCUMENTS_FAILURE:
      return state.set('debriefDocumentsLoading', false);

    case CREATE_DEBRIEF_INSTRUCTION_REQUEST: {
      return state.set('instructionLoading', true);
    }

    case CREATE_DEBRIEF_INSTRUCTION_SUCCESS: {
      const instruction = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(instruction.id);
      const { language } = action;

      return state
        .set('instructionLoading', false)
        .setIn(
          ['debriefs', language, simulationId, 'documents', id],
          fromJS(instruction)
        );
    }

    case CREATE_DEBRIEF_INSTRUCTION_FAILURE: {
      return state.set('instructionLoading', false);
    }

    case EDIT_DEBRIEF_INSTRUCTION_REQUEST:
      return state.set('instructionLoading', true);

    case EDIT_DEBRIEF_INSTRUCTION_SUCCESS: {
      const instruction = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('instructionLoading', false)
        .setIn(
          ['debriefs', language, simulationId, 'documents', id],
          fromJS(instruction)
        );
    }
    case EDIT_DEBRIEF_INSTRUCTION_FAILURE:
      return state.set('instructionLoading', false);

    case DELETE_DEBRIEF_INSTRUCTION_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'debriefs',
        language,
        simulationId,
        'documents',
        id
      ]);
    }

    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
