import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { SubmitPublishModal } from 'views/Simulations/components/EditSimulationTable/modals';
import {
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Loader, Link } from 'components';

import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';

import {
  fetchAssessment,
  fetchAssessmentStrategies,
  editAssessment
} from 'actions/assessments';
import {
  BasicInformationTranslateTable,
  BehaviorsTranslateTable,
  StrategiesTranslateTable
} from './components';

let editAssessmentTabs = [
  { id: '1', title: 'basic_information' },
  { id: '2', title: 'behaviors' },
  { id: '3', title: 'strategies' }
];

@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    route: state.router.route,
    isLoading: state.assessments.get('assessmentsLoading'),
    assessments: state.assessments.get('assessments')
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        fetchAssessment,
        fetchAssessmentStrategies,
        editAssessment,
        transitionTo
      },
      dispatch
    );
  }
)
export default class Translate extends Component {
  static propTypes = {
    id: PropTypes.number,
    transitionTo: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    fetchAssessment: PropTypes.func.isRequired,
    editAssessment: PropTypes.func.isRequired,
    fetchAssessmentStrategies: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    assessments: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { route } = this.props;

    this.state = {
      activeTab: route.query.subTab || '1',
      hasError: false
    };
  }

  UNSAFE_componentWillMount() {
    const {
      id,
      version,
      fetchAssessment,
      fetchAssessmentStrategies
    } = this.props;
    const promises = [
      fetchAssessment(id, version),
      fetchAssessmentStrategies(id),
      fetchAssessmentStrategies(id, null, null, null, version)
    ];

    Promise.all(promises);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.subTab !== nextProps.route.query.subTab) {
      this.toggle(nextProps.route.query.subTab || '1');
    }
  }

  getTabContent = (tab) => {
    const { id, version, assessments } = this.props;
    const { hasError } = this.state;

    const assessmentVersion = assessments.hasIn(['english', id, 'languages'])
      ? assessments
          .getIn(['english', id, 'languages'])
          .find((lang) => lang.get('language') === version)
      : false;
    const published = assessmentVersion && assessmentVersion.get('published');

    switch (tab) {
      case '1':
        return (
          <BasicInformationTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '2':
        return (
          <BehaviorsTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '3':
        return (
          <StrategiesTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      default:
        return (
          <BasicInformationTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
    }
  };

  getErrorTab = (string) => {
    this.setState({ hasError: true });

    if (string.indexOf('behavior') > -1) {
      return '2';
    }

    if (string.indexOf('strategy') > -1) {
      return '3';
    }

    return '1';
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  submitPublish = () => {
    const {
      editAssessment,
      id,
      version,
      assessments,
      transitionTo,
      route
    } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    const assessmentVersion = assessments.hasIn(['english', id, 'languages'])
      ? assessments
          .getIn(['english', id, 'languages'])
          .find((lang) => lang.get('language') === version)
      : false;
    const published = assessmentVersion && assessmentVersion.get('published');
    const errorFields = [
      'name',
      'simulation',
      'translation',
      'partner_id',
      'client_id'
    ];

    editAssessment(id, { language: version, published: !published }).then(
      (action) => {
        if (action.error && action.error.body) {
          errorFields.map((field) => {
            if (action.error.body[field] && action.error.body[field].errors) {
              const string = action.error.body[field].errors
                .map((err) => intl.formatMessage({ id: err }))
                .join(', ')
                .trim();
              this.setState({ activeTab: this.getErrorTab(string) });

              transitionTo(`/customize/assessment/edit/${id}`, {
                tab: route.query.tab || 1,
                subTab: this.getErrorTab(string)
              }).then(() =>
                addAlert({
                  color: 'danger',
                  content: string
                })
              );
            }

            return false;
          });
        } else if (action.error) {
          addAlert({
            color: 'danger',
            content:
              typeof action.error.body === 'string'
                ? intl.formatMessage({ id: action.error.body })
                : JSON.stringify(action.error.body)
          });
          removeModal();
        } else {
          addAlert({
            color: 'success',
            content: (
              <FormattedMessage
                id={`SUCCESSFULLY_${!published ? 'PUBLISHED' : 'UNPUBLISHED'}`}
                values={{ item: intl.formatMessage({ id: 'ASSESSMENT' }) }}
              />
            )
          });
        }
        removeModal();
      }
    );
  };

  render() {
    const { isLoading, id, version, assessments, route } = this.props;
    const { addModal } = this.context;
    const { activeTab } = this.state;
    const isPsychometricsOnly = assessments
      .getIn(['english', id])
      .getIn(['simulation', 'is_psychometrics_only']);

    if (isPsychometricsOnly) {
      editAssessmentTabs = editAssessmentTabs.filter(
        (tab) => ['behaviors', 'strategies'].indexOf(tab.title) === -1
      );
    }

    const assessmentVersion = assessments.hasIn(['english', id, 'languages'])
      ? assessments
          .getIn(['english', id, 'languages'])
          .find((lang) => lang.get('language') === version)
      : false;

    const published = assessmentVersion && assessmentVersion.get('published');
    const isDisabled =
      (assessmentVersion.get('published') &&
        !assessmentVersion.get('is_unpublishable')) ||
      (!assessmentVersion.get('published') &&
        !assessmentVersion.get('is_publishable'));

    return (
      <div className={styles['simulation-subtabs-div']}>
        <Nav tabs>
          {editAssessmentTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.title}_${version}`}
                to={`/customize/assessment/edit/${id}`}
                query={{
                  tab: route.query.tab || '1',
                  subTab: tab.id.toString()
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`EDIT_ASSESSMENT_TABS.${tab.title}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {assessmentVersion.get('published') &&
            assessmentVersion.has('published_at') && (
              <span className={styles['published-message']}>
                <FormattedMessage
                  id={
                    assessmentVersion.has('published_by')
                      ? 'PUBLISHED_BY'
                      : 'PUBLISHED_ON'
                  }
                  values={{
                    admin: assessmentVersion.getIn(['published_by', 'name']),
                    date: moment(assessmentVersion.get('published_at')).format(
                      'DD MMM YYYY hh:mm A'
                    )
                  }}
                />
              </span>
            )}

          <div
            key="switcher"
            className={classNames(
              styles['custom-switcher'],
              isDisabled && styles.disabled,
              assessmentVersion.get('published') && styles.bold
            )}
          >
            <FormattedMessage
              id={
                assessmentVersion.get('published') ? 'PUBLISHED' : 'UNPUBLISHED'
              }
            />
            <Label
              id={`input_label_publish_${version}`}
              className={styles['custom-switch']}
            >
              <input
                id={`input_publish_${version}`}
                name={`input_publish_${version}`}
                type="checkbox"
                disabled={isDisabled}
                onChange={() =>
                  addModal(
                    <SubmitPublishModal
                      action={this.submitPublish}
                      publish={published}
                      type="BEHAVIOR"
                    />
                  )
                }
                checked={published}
              />
              <div className={styles['switch-slider']} />
            </Label>
          </div>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className={styles['simulation-subtabs']}
        >
          {editAssessmentTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {isLoading && <Loader />}
                  {tab.id === activeTab &&
                    !isLoading &&
                    this.getTabContent(activeTab)}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
