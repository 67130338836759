// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

// Components
import { BasicInformation } from 'forms';

// Actions
import { createSimulation } from 'actions/simulations';
import { getPartner, getClient } from 'actions/users';

// Styles
import styles from '../styles.css';
import '../styles.css';

/**
 * Create
 * @class
 */
@connect(
  (state) => ({
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators({ createSimulation, getPartner, getClient }, dispatch)
)
export default class Create extends Component {
  // Prop types
  static propTypes = {
    createSimulation: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    getPartner: PropTypes.func.isRequired,
    getClient: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { createSimulation, getPartner, getClient } = this.props;

    createSimulation(data).then((action) => {
      if (
        action.result &&
        action.result.body &&
        action.result.body.partners &&
        action.result.body.partners.length > 0
      ) {
        const { partners } = action.result.body;

        partners.map((p) => {
          if (p.partner != null) {
            getPartner(p.partner.id);
          }

          if (p.client != null) {
            getClient(p.client.id);
          }

          return true;
        });
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { route } = this.props;

    return (
      <div>
        <div className={styles['link-back']}>
          <Link to="/simulations" query={{ tab: route.query.tab || '1' }}>
            &larr; <FormattedMessage id="BACK" />
          </Link>
        </div>
        <h1 className={styles['new-sim-heading']}>
          <FormattedMessage id="NEW_SIMULATION" />
        </h1>

        <BasicInformation onSubmit={this.handleSubmit} />
      </div>
    );
  }
}
