// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { logout } from 'actions/auth';

// Components
import { Loader } from 'components';

/**
 * Logout
 * @class
 */
@connect(undefined, (dispatch) => bindActionCreators({ logout }, dispatch))
export default class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  UNSAFE_componentWillMount = () => {
    const { logout } = this.props;
    logout();
  };

  render() {
    return <Loader />;
  }
}
