import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Input, Row, Col } from 'reactstrap';

import styles from '../styles.css';
import { Map } from 'immutable';

const IndividualSectionFilter = (props, context) => {
  const { data, user, transitionTo, route, tab, jit, filterOptions } = props;
  const { intl } = context;

  const isParticipant = user.get('active_role') === 'participant';

  if (isParticipant) {
    return null;
  }

  const getOptions = () => {
    if (filterOptions) {
      const filtered = [];

      data
        .sort((a, b) => {
          const aDate = a.get('date');
          const bDate = b.get('date');

          if (moment(aDate).isBefore(moment(bDate))) {
            return -1;
          }

          if (moment(bDate).isBefore(moment(aDate))) {
            return 1;
          }

          return 0;
        })
        .map((i) => {
          if (
            !filtered.find(
              (f) => f.getIn(['user', 'id']) === i.getIn(['user', 'id'])
            )
          ) {
            return filtered.push(i);
          }

          return false;
        });

      return filtered;
    }

    return data;
  };

  const individualSectionTransition = (value) => {
    transitionTo('/analytics', {
      tab: route.query.tab || '1',
      selected: route.query.selected,
      current: value,
      assessment: route.query.assessment,
      scroll: 0,
      filtered: route.query.filtered
    });
  };

  useEffect(() => {
    if (
      props.route.query.tab === props.tab.id &&
      props.route.query.current != null &&
      !getOptions().find(
        (a) => a.get('id') === Number(props.route.query.current)
      )
    ) {
      individualSectionTransition(null);
    }
  }, [props.route.query.selected, props.route.query.tab]);

  const options = getOptions();
  const report =
    data.find((r) => r.get('id') === Number(route.query.current)) || Map();

  return (
    <Row
      className={classNames(
        styles['report-select-row'],
        isParticipant && styles['participants-view']
      )}
    >
      <Col xs={3}>
        <strong>
          <FormattedMessage id="PARTICIPANT" />:
        </strong>
      </Col>
      <Col xs={9}>
        <Input
          id={`input_select_${route.query.tab}`}
          name={`input_select_${route.query.tab}`}
          type="select"
          onChange={(e) => individualSectionTransition(e.target.value)}
          value={Number(route.query.current)}
          defaultValue={Number(route.query.current)}
          style={{ display: 'inline-block', marginRight: 5, width: '95%' }}
        >
          <option value="" key="option-default">
            {intl.formatMessage({ id: 'PLEASE_SELECT_PARTICIPANT' })}
          </option>
          {options
            ?.sort((a, b) => {
              const first = a.getIn(['user', 'firstname']).toLowerCase();
              const second = b.getIn(['user', 'firstname']).toLowerCase();
              if (first < second) {
                return -1;
              }
              if (first > second) {
                return 1;
              }

              return 0;
            })
            ?.toArray()
            ?.map((item, index) => {
              let disabled = false;

              if (
                route.query.tab === 'development-dashboard' &&
                item
                  .getIn(['user', 'modules'])
                  .toArray()
                  .indexOf('Development') === -1
              ) {
                disabled = true;
              }

              return (
                <option
                  value={item.get('id')}
                  key={`option-${index}`}
                  disabled={disabled}
                >
                  {item.getIn(['user', 'firstname'])}{' '}
                  {item.getIn(['user', 'surname'])},{' '}
                  {item.get('assessment_name')},{' '}
                  {moment(item.get('date')).format('DD MMM YYYY')}
                </option>
              );
            })}
        </Input>

        {jit}

        {report.has('simulations_count') &&
          report.get('simulations_count') > 1 &&
          (tab.key === 'personality' || tab.key === 'potential') && (
            <span
              style={{
                fontSize: 14
              }}
            >
              (<FormattedMessage id="ONLY_ONE_ASSESS_INFO" />)
            </span>
          )}
      </Col>
    </Row>
  );
};

IndividualSectionFilter.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    route: state.router.route,
    user: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)(React.memo(IndividualSectionFilter));
