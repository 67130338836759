/* eslint-disable no-lonely-if */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { actions } from 'routex';
import { reducer as formReducer } from 'redux-form';
import * as reducers from './reducers';
import { promiseMiddleware, actionInterceptorMiddleware } from './middlewares';
import {
  loadingBarMiddleware,
  loadingBarReducer
} from 'react-redux-loading-bar';
import {
  setCookie,
  getCookie,
  stageRedirect,
  roleRedirect,
  checkSupport,
  checkRoutePermissions,
  isMobile
} from 'helpers';

// Const
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_ME_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_FORM_ERRORS,
  CREATE_SIMULATION_SUCCESS,
  CREATE_BEHAVIOR_SUCCESS,
  CREATE_ASSESSMENT_SUCCESS,
  GET_USER_FAILURE,
  CREATE_DEBRIEF_SUCCESS
} from './constants/actionTypes';
import {
  AUTH_TOKEN,
  AUTH_REFRESH_TOKEN,
  IT_CHECK,
  PARTICIPANTS_IT_CHECK
} from './constants/cookies';

const LAST_UPDATE = 'last-update';
const NEED_RELOAD = 'need-reload';

const sendMessageToNativeApp = (action, token) => {
  if (window && window.platform != null) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        action,
        token
      })
    );
  }
};

export default function create(initialState, routex) {
  const routexReducer = routex.reducer;
  // eslint-disable-next-line
  let dispatch;
  // eslint-disable-next-line
  let store;

  function redirectOnSetCookie(user) {
    const interval = setInterval(() => {
      function notParticipantRedirect() {
        dispatch(actions.transitionTo(stageRedirect(user)));
      }

      if (getCookie(AUTH_TOKEN) !== '') {
        clearInterval(interval);
        if (isMobile()) {
          if (
            !user.stages.invited2a_tc.completed ||
            !user.stages.invited2b_pp.completed
          ) {
            dispatch(actions.transitionTo(stageRedirect(user)));
          } else {
            dispatch(actions.transitionTo('/development'));
          }
        } else {
          if (
            getCookie(IT_CHECK) === 'true' &&
            getCookie(PARTICIPANTS_IT_CHECK) === 'true'
          ) {
            if (user.active_role === 'participant') {
              dispatch(actions.transitionTo(stageRedirect(user)));
            } else {
              notParticipantRedirect();
            }
          } else {
            if (user.active_role !== 'participant') {
              notParticipantRedirect();
            } else {
              if (checkSupport()) {
                dispatch(actions.transitionTo(stageRedirect(user)));
              }
            }
          }
        }
      }
    }, 10);
  }

  /**
   * Interceptor middleware
   */
  const interceptorMiddleware = actionInterceptorMiddleware({
    // Action redirects
    [LOGIN_SUCCESS]: (action, next) => {
      const userData = action.result.body;

      if (
        window.platform != null &&
        isMobile() &&
        userData.active_role !== 'participant'
      ) {
        setCookie(AUTH_TOKEN, '', 365);
        return (window.location =
          '/mobile-not-supported?type=desktop-required');
      }

      setCookie(AUTH_TOKEN, userData.token, userData.cookie_duration, true);
      setCookie(
        AUTH_REFRESH_TOKEN,
        userData.refresh_token,
        userData.cookie_duration,
        true
      );

      sendMessageToNativeApp('PUSH_TOKEN', userData.token);
      redirectOnSetCookie(userData);
      return next(action);
    },
    [LOGIN_FAILURE]: (action, next) => {
      if (
        (action.error.body && action.error.body.message) ||
        action.error.status === 404
      ) {
        if (
          action.error.body.message === 'ERRORS.LOGIN_TOKEN_EXPIRED' ||
          action.error.body.message === 'ERRORS.TOKEN_INVALID' ||
          action.error.status === 404
        ) {
          return (window.location = `/auth/login?error=${action.error.body.message}`);
        }
      }

      return next(action);
    },
    [GET_ME_SUCCESS]: (action, next) => {
      const user = action.result.body;
      const pathName = window.location.pathname;
      const redirectPath = stageRedirect(user);

      sendMessageToNativeApp('PUSH_TOKEN', getCookie(AUTH_TOKEN));

      // Check terms after get-me
      if (
        (!user.stages.invited2a_tc.completed ||
          !user.stages.invited2b_pp.completed) &&
        pathName.indexOf('terms') < 0
      ) {
        return (window.location = redirectPath);
      }

      if (action.disallowRedirect) {
        return next(action);
      }

      if (pathName === '/' || pathName.indexOf('auth') > -1) {
        if (
          (redirectPath.indexOf('prework') > -1 &&
            pathName.indexOf('prework') < 0) ||
          (redirectPath.indexOf('schedule') > -1 &&
            pathName.indexOf('schedule') < 0) ||
          (redirectPath.indexOf('it-check') > -1 &&
            pathName.indexOf('it-check') < 0) ||
          (redirectPath.indexOf('eeo') > -1 && pathName.indexOf('eeo') < 0)
        ) {
          window.location = redirectPath;
          // Simulator redirect without next action
        } else if (redirectPath.indexOf('index.php') > -1) {
          return dispatch(actions.transitionTo(redirectPath));
        } else {
          if (
            pathName.indexOf('prework/questionnaire') < 0 &&
            pathName.indexOf('prework/lat') < 0
          ) {
            if (user.active_role === 'participant' && isMobile()) {
              return (window.location = '/development');
            }

            setTimeout(() => {
              // Wait for prev-action to finish
              dispatch(actions.transitionTo(redirectPath));
            }, 100);
          }
        }
      }

      return next(action);
    },
    [LOGOUT_REQUEST]: (action, next) => {
      dispatch(actions.transitionTo('/'));
      return next(action);
    },
    [LOGOUT_SUCCESS]: (action, next) => {
      setCookie(AUTH_TOKEN, '', 365);
      setCookie(AUTH_REFRESH_TOKEN, '', 365);
      window.location = '/auth/login';
      return next(action);
    },
    [GET_USER_FAILURE]: (action, next) => {
      const currentState = store.getState();
      if (action.error.status === 403) {
        dispatch(
          actions.transitionTo(
            roleRedirect(currentState.auth.get('loggedUser'))
          )
        );
      }

      return next(action);
    },
    [CREATE_SIMULATION_SUCCESS]: (action, next) => {
      dispatch(
        actions.transitionTo(`/simulations/edit/${action.result.body.id}`, {
          subTab: '2'
        })
      );
      return next(action);
    },
    [CREATE_BEHAVIOR_SUCCESS]: (action, next) => {
      dispatch(
        actions.transitionTo(
          `/simulations/behaviors/edit/${action.result.body.id}`,
          { subTab: '2' }
        )
      );
      return next(action);
    },
    [CREATE_ASSESSMENT_SUCCESS]: (action, next) => {
      dispatch(
        actions.transitionTo(
          `/customize/assessment/edit/${action.result.body.id}`,
          { subTab: '1' } // Main
        )
      );

      return next(action);
    },
    [CREATE_DEBRIEF_SUCCESS]: (action, next) => {
      dispatch(
        actions.transitionTo(`/simulations/edit/${action.result.body.id}`, {
          subTab: '2'
        })
      );
      return next(action);
    },
    ['@@ROUTEX/ROUTE_CHANGE_SUCCESS']: (action, next) => {
      const currentState = store.getState();
      const loggedUser = currentState.auth.get('loggedUser');
      const permissions = currentState.app.get('permissions') || [];

      if (getCookie(NEED_RELOAD) === 'true') {
        setCookie(NEED_RELOAD, '', -1);
        location.reload(true);
        window.location = action.route.pathname;
      }

      if (
        action.route &&
        action.route.query &&
        Number(action.route.query.scroll) !== Number(0)
      ) {
        window.scrollTo(0, 0);
      }

      dispatch({ type: RESET_FORM_ERRORS });

      if (
        loggedUser.size > 0 &&
        !checkRoutePermissions(loggedUser, action.route.pathname, permissions)
      ) {
        return (window.location = stageRedirect(loggedUser.toJS()));
      }

      return next(action);
    }
  });

  /**
   * Check auth middleware
   */
  const checkAuthMiddleware = () => (next) => (action) => {
    if (action && action.result && action.result.xhr) {
      const currentHash = action.result.xhr.getResponseHeader(
        'frontend-commit-hash'
      );
      const savedHash = getCookie(LAST_UPDATE);

      const updateCookies = () => {
        setCookie(LAST_UPDATE, currentHash, 1825);
        setCookie(NEED_RELOAD, 'true', 1825);
      };

      if (savedHash && currentHash) {
        if (savedHash !== currentHash) {
          updateCookies();
        }
      }

      if (!savedHash && currentHash) {
        updateCookies();
      }
    }

    return next(action);
  };

  // App store
  store = compose(
    routex.store,
    applyMiddleware(
      promiseMiddleware,
      checkAuthMiddleware,
      loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE']
      }),
      interceptorMiddleware
    )
  )(createStore)(
    combineReducers({
      ...reducers,
      ...routexReducer,
      form: formReducer,
      loadingBar: loadingBarReducer
    }),
    initialState
  );

  dispatch = store.dispatch; // eslint-disable-line

  return store;
}
