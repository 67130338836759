import fieldInputs from './fieldInputs';
import {
  form as shareReportAccessForm,
  shareReportAccessFormOption1,
  shareReportAccessFormOption2,
  shareReportAccessFormStakeholders
} from '../Global/ShareReportsAccessForm';

const getUserFields = (form, user, change, helpers) => {
  const data = [
    { name: 'firstname', value: user.get('firstname') },
    { name: 'surname', value: user.get('surname') },
    { name: 'email', value: user.get('email') },
    { name: 'forwarding_email', value: user.get('forwarding_email') },
    { name: 'tel_mobile', value: user.get('tel_mobile') },
    { name: 'tel_secondary', value: user.get('tel_secondary') },
    { name: 'eid', value: user.get('eid') },
    { name: 'timezone', value: user.get('timezone') },
    // { name: 'language', value: user.get('language') },
    { name: 'partner_id', value: user.get('partner_id') },
    { name: 'client_id', value: user.get('client_id') },
    { name: 'status', value: user.get('status') },
    { name: 'consent_email', value: user.get('consent_email') }
  ];

  if (user.get('roles').indexOf('assessor') > -1) {
    data.push({ name: 'bio', value: user.get('bio') });
    helpers.simulations.map((simulation) =>
      data.push({
        name: `simulations[${simulation.get('id')}]`,
        value: !!user
          .get('simulations')
          .find((sim) => sim.get('id') === simulation.get('id'))
      })
    );

    helpers.debriefs.map((debrief) =>
      data.push({
        name: `debriefs[${debrief.get('id')}]`,
        value: !!user
          .get('debriefs')
          .find((sim) => sim.get('id') === debrief.get('id'))
      })
    );

    helpers.languages.map((language, index) =>
      data.push({
        name: `languages[${index}]`,
        value: user.get('languages').find((lan) => lan === language.get('name'))
      })
    );
  }

  if (user.has('modules')) {
    data.push({
      name: 'modules[1]',
      value: user.get('modules').indexOf('Development') > -1
    });
  }

  if (user.get('roles').contains('participant')) {
    const allSimulations = user.has('active_simulation')
      ? [user.get('active_simulation')].concat(
          user.get('past_simulations').toArray()
        )
      : user.get('past_simulations').toArray();
    if (allSimulations.length > 0) {
      const shareData = [];

      allSimulations.forEach((simulation) => {
        const sharing = simulation.get('sharing');

        shareData.push({
          name: `${shareReportAccessFormOption1}-${sharing.get(
            'active_simulation'
          )}`,
          value: sharing.get(shareReportAccessFormOption1)
        });
        shareData.push({
          name: `${shareReportAccessFormOption2}-${sharing.get(
            'active_simulation'
          )}`,
          value: sharing.get(shareReportAccessFormOption2)
        });
        shareData.push({
          name: `${shareReportAccessFormStakeholders}-${sharing.get(
            'active_simulation'
          )}`,
          value: sharing
            .get(shareReportAccessFormStakeholders)
            ?.map((s) => ({ id: s.get('id') }))
            ?.toArray()
            ?.concat([{}])
        });
      });

      fieldInputs(shareReportAccessForm, shareData, change);
    }
  }

  fieldInputs(form, data, change);
};

export default getUserFields;
