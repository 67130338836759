import { SET_DIRECTION } from 'constants/actionTypes';

/**
 * Set direction action
 *
 * @param{String} dir
 * @returns {{types: *[], promise: Request }}
 */

export default function setDirection(dir) {
  return {
    type: SET_DIRECTION,
    dir
  };
}
