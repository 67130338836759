/* eslint-disable eqeqeq */
import request from 'superagent-bluebird-promise';
import { getCookie, setCookie } from 'helpers';
import { Map } from 'immutable';

import { AUTH_TOKEN, AUTH_REFRESH_TOKEN } from '../constants/cookies';

const pendingRequests = Map();

const api = () => {
  const doFetch = (method, endpoint, data, headers = {}, responseType = '') => {
    const url = `${process.env.REACT_APP_APIURL}${endpoint}`;
    const options = {
      method,
      headers: { ...api.defaultHeaders, ...headers },
      body: data != null ? JSON.stringify(data) : undefined
    };

    const baseRequest = request[options.method](url)
      .set(options.headers)
      .responseType(responseType)
      .send(options.body);

    const fetchAction = baseRequest
      .then((res) => Promise.resolve(res))
      .catch((err) => Promise.reject(err));

    api.pendingRequests = pendingRequests.set(
      JSON.stringify(endpoint),
      baseRequest
    );

    return fetchAction.catch((error) => {
      const throwError = (e) => Promise.reject(e);
      const cookieRefreshToken = getCookie(AUTH_REFRESH_TOKEN);
      const isOnLoginPage = window.location.pathname === '/auth/login';
      const isOnInvitePage =
        window.location.pathname === '/auth/invite' ||
        window.location.pathname === '/auth/invite/';
      const hasRefreshToken =
        cookieRefreshToken &&
        cookieRefreshToken != null &&
        cookieRefreshToken != '';

      if (
        error.status === 401 &&
        hasRefreshToken &&
        !isOnLoginPage &&
        !isOnInvitePage
      ) {
        request
          .post(`${process.env.REACT_APP_APIURL}/api/v1/token/refresh/`)
          .send({
            refresh_token: cookieRefreshToken
          })
          .then((response) => {
            const data = response.body;

            setCookie(AUTH_TOKEN, data.token, 365);
            setCookie(AUTH_REFRESH_TOKEN, data.refresh_token, 365);

            return location.reload();
          })
          .catch(() => {
            setTimeout(() => {
              setCookie(AUTH_TOKEN, '', 365);
              setCookie(AUTH_REFRESH_TOKEN, '', 365);

              if (!isOnLoginPage) {
                return (window.location = '/auth/login');
              }

              return false;
            }, 200);
          });
      } else if (error.status === 401) {
        if (!isOnLoginPage) {
          return (window.location = '/auth/login');
        }
      }

      return throwError(error);
    });
  };

  ['get', 'post', 'put', 'del'].forEach((method) => {
    doFetch[method] = doFetch.bind(null, method);
  });

  return doFetch;
};

api.defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
};

api.pendingRequests = pendingRequests;

export default api;
