import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import api from 'api';
import { Loader, SurveyBanner } from 'components';
import { Preview } from './subviews';
import { Filter, Folders, ParticipantSelect } from './components';

import styles from './styles.css';
import './styles.css';
import { Alert } from 'reactstrap';

@connect((state) => ({
  analyticsDetailLoading: state.analytics.get('analyticsDetailLoading'),
  isLoading: state.analytics.get('analyticsLoading'),
  route: state.router.route,
  showTabs: state.analytics.get('overviewData').size > 0,
  user: state.auth.get('loggedUser')
}))
export default class Analytics extends Component {
  static propTypes = {
    analyticsDetailLoading: PropTypes.object.isRequired,
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    route: PropTypes.object.isRequired,
    showTabs: PropTypes.bool,
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showAcceptanceFeedbackBanner: false
    };
  }

  componentDidMount = async () => {
    const { user } = this.props;

    if (user.get('active_role') === 'participant') {
      try {
        if (
          window.sessionStorage.getItem('feedback-acceptance-survery-dismissed')
        ) {
          return;
        }
        await api().get(
          '/api/v1/users/survey/eligibility/feedback-acceptance/'
        );
        this.setState({ showAcceptanceFeedbackBanner: true });
      } catch (err) {
        this.setState({ showAcceptanceFeedbackBanner: false });
      }
    }
  };

  getHeader = () => {
    const { user, showTabs } = this.props;

    if (user.get('active_role') === 'participant' && !showTabs) {
      return null;
    }

    return (
      <div>
        <Helmet title="Pinsight - Analytics" />
        <h2>
          <FormattedMessage id="ANALYTICS" />
        </h2>
      </div>
    );
  };

  handleOpenSurvey = async () => {
    const response = await api().post(
      `/api/v1/users/survey/feedback-acceptance/`,
      {
        action: 'take'
      }
    );
    window.open(response.body.url, '_blank');
    this.setState({ showAcceptanceFeedbackBanner: false });
  };

  handleCloseSurvey = () => {
    this.setState({ showAcceptanceFeedbackBanner: false });
    window.sessionStorage.setItem(
      'feedback-acceptance-survery-dismissed',
      true
    );
  };

  render() {
    const {
      analyticsDetailLoading,
      children,
      isLoading,
      user,
      showTabs,
      route
    } = this.props;
    const dataLoading =
      analyticsDetailLoading.find((loading) => loading === true) || isLoading;
    const fromHT = Number(route.query.ht) === 1;
    const fromHTLoading = fromHT && dataLoading;
    const header = this.getHeader();

    return (
      <>
        {user.get('active_role') === 'participant' && (
          <SurveyBanner
            show={this.state.showAcceptanceFeedbackBanner}
            headingTextId="ACCEPTANCE_SURVEY_BANNER.HEADING"
            bannerTextId="ACCEPTANCE_SURVEY_BANNER.TEXT"
            buttonTextId="ACCEPTANCE_SURVEY_BANNER.BUTTON"
            openSurveyHandler={this.handleOpenSurvey}
            closeSurveyHandler={this.handleCloseSurvey}
          />
        )}
        <div className={styles['analytics-preview-head']}>
          {header}
          {fromHTLoading && <Loader />}
          <div
            style={{
              display: fromHTLoading ? 'none' : 'block',
              position: 'relative'
            }}
          >
            {user.get('active_role') !== 'participant' && (
              <div style={{ marginTop: 40 }}>
                <Filter />
                <Folders
                  analyticsElement={this.content}
                  fromHTLoading={fromHTLoading}
                />
              </div>
            )}
            {user.get('active_role') === 'participant' &&
              !dataLoading &&
              showTabs && <ParticipantSelect />}
            <div ref={(node) => (this.content = node)}>
              {showTabs && children}
            </div>
            {header === null && (
              <Alert color="success">
                <FormattedMessage id="BEFORE_DEVELOPMENT_INFO" />
              </Alert>
            )}
          </div>
        </div>
      </>
    );
  }
}

Analytics.Preview = Preview;
