import {
  FETCH_ANALYTICS_OVERVIEW_REQUEST,
  FETCH_ANALYTICS_OVERVIEW_SUCCESS,
  FETCH_ANALYTICS_OVERVIEW_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function fetchAnalyticsOverview() {
  return {
    types: [
      FETCH_ANALYTICS_OVERVIEW_REQUEST,
      FETCH_ANALYTICS_OVERVIEW_SUCCESS,
      FETCH_ANALYTICS_OVERVIEW_FAILURE
    ],
    promise: api().get(`/api/v1/analytics/overview/`)
  };
}
