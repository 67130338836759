import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { AUTH_TOKEN } from 'constants/cookies';
import { getCookie } from 'helpers';
import { Button } from 'reactstrap';

import { EmailDetail } from '../components';
import { EmbedPreview } from '../components';
import { getFileType, sortItems } from 'helpers';

import styles from '../styles.css';
import fileDownload from 'js-file-download';

export default function getEmailsTable(data, context, rtl) {
  return {
    data: sortItems(
      data.filter((i) => !i.get('is_practice')),
      { key: 'created_at' }
    ).toArray(),
    customClassName: 'scoring-emails-table',
    rowAction: (item) =>
      context.addModal(
        <EmailDetail rtl={rtl} email={item} addModal={context.addModal} />
      ),
    fields: [
      {
        key: 'date',
        sortable: true,
        renderField: (item) => (
          <span>
            {moment(item.get('created_at'))
              .utcOffset(item.get('created_at'))
              .format('DD MMM YYYY hh:mm:ss A')}
            <br />
            {item.get('is_draft') ? (
              <span>
                (<FormattedMessage id="DRAFT" />)
              </span>
            ) : null}
          </span>
        ),
        width: 25
      },
      {
        key: 'from',
        sortable: true,
        renderField: (item) => item.get('from'),
        width: 25
      },
      {
        key: 'to',
        sortable: true,
        renderField: (item) => (
          <div title={item.get('to')} className={styles['small-field']}>
            {item.get('to')}
          </div>
        ),
        width: 25
      },
      {
        key: 'subject',
        sortable: true,
        renderField: (item) => item.get('subject'),
        width: 30
      },
      {
        key: 'attach',
        sortable: false,
        width: 15,
        renderField: (item) =>
          item.get('attachments').map((att) => (
            <div className={styles['small-field']}>
              {att.get('name').endsWith('.mp4') !== true && (
                <span
                  className="email-attachment"
                  style={{ cursor: 'pointer' }}
                  id={`attachment_link_${att.get('name')}`}
                  onClick={() => {
                    fetch(
                      `${process.env.REACT_APP_APIURL}/api/v1/assets/${att.get(
                        'source'
                      )}`,
                      {
                        method: 'get',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
                        },
                        mode: 'cors'
                      }
                    )
                      .then((response) => response.blob())
                      .then((blob) => {
                        fileDownload(blob, att.get('name'));
                      });
                    return true;
                  }}
                >
                  <img
                    src={`/assets/img/file-${getFileType(
                      att.get('source')
                    )}.png`}
                    alt={att.get('name')}
                  />
                </span>
              )}

              {att.get('name').endsWith('.mp4') && (
                <Button
                  id={`open_roleplay_button_${item.get('id')}`}
                  name={`open_roleplay_button_${item.get('id')}`}
                  size="sm"
                  color="link"
                  onClick={(event) => {
                    event.stopPropagation();
                    let callType = 'twilio_call';
                    if (att.get('chime_call') !== undefined) {
                      callType = 'chime_call';
                    }
                    if (
                      att.get(callType).get('archive_url') === undefined ||
                      att.get(callType).get('archive_status') === undefined
                    ) {
                      alert('Video is being processed.');
                    } else {
                      let src = att.get('source');
                      src +=
                        src.toLowerCase().indexOf('?istwilio') === -1
                          ? '?'
                          : '&';
                      context.addModal(
                        <EmbedPreview
                          src={`${src}bearer=${getCookie(AUTH_TOKEN)}`}
                        />
                      );
                    }
                  }}
                >
                  <FormattedMessage id="OPEN" />
                </Button>
              )}
            </div>
          ))
      }
    ]
  };
}
