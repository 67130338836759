import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { surveyAction } from 'actions/users';

import modals from '../../modals.css';
import '../../modals.css';

@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    return bindActionCreators({ surveyAction }, dispatch);
  }
)
class ZohoSurveyModal extends React.Component {
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  static propTypes = {
    surveyAction: PropTypes.func.isRequired
  };

  surveyAction = (action) => {
    const { addAlert, removeModal } = this.context;
    const { surveyAction } = this.props;

    surveyAction(action).then((requestAction) => {
      removeModal();

      if (requestAction.error) {
        return addAlert({
          color: 'danger',
          content: requestAction?.error?.body?.action?.errors?.map((error) => (
            <FormattedMessage id={error} />
          )) || <FormattedMessage id="ERROR_OCCURRED" />
        });
      }

      if (action === 'take') {
        return window.open(requestAction.result.body.url, '_blank');
      }

      return true;
    });
  };

  render() {
    return (
      <Modal isOpen toggle={() => false} style={{ textAlign: 'center' }}>
        <ModalHeader toggle={false}>
          <FormattedMessage id="WE_WOULD_LOVE_YOUR_FEEDBACK" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <FormattedHTMLMessage id="SURVEY_MODAL_BODY" />

          <div className={modals['survey-modal-button-group']}>
            <Button color="primary" onClick={() => this.surveyAction('take')}>
              <FormattedMessage id="TAKE_THE_SURVEY" />
            </Button>
            <Button
              color="secondary"
              onClick={() => this.surveyAction('remind')}
            >
              <FormattedMessage id="REMIND_ME_LATER" />
            </Button>
            <Button color="secondary" onClick={() => this.surveyAction('skip')}>
              <FormattedMessage id="CONTINUE_TO_DASHBOARD" />
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ZohoSurveyModal;
