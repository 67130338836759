import React from 'react';

import { RemoveModal } from '../index';
import removeReport from './removeReport';
import { getSelected } from './index';

export default (data, props, context) => {
  const { analyticsData, route } = props;
  const { addModal } = context;
  let selected;

  if (!data) {
    selected = getSelected(route);
  } else {
    selected = [data.get('id')];
  }
  const participants = [];
  analyticsData.map((a) => {
    if (selected.indexOf(a.get('id')) > -1) {
      return participants.push(a);
    }

    return false;
  });

  addModal(
    <RemoveModal
      participants={participants}
      submitAction={() => removeReport(data.get('id'))}
    />
  );
};
