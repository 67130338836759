import React, { useEffect, useState } from 'react';

import api from 'api';
import { getProgressValues } from 'helpers';

import PriorityCard from '../../../../../Development/components/MyPriorities/PriorityCard';

import styles from './styles.css';
import './styles.css';

const SkillPriorities = (props) => {
  const [prioritiesCards, setPrioritiesCards] = useState([]);
  const { user } = props;

  useEffect(() => {
    const fetchData = async () => {
      const response = await api().get(
        `/api/v1/analytics/development/skill-priorities/${user.get('id')}/`
      );

      const priorities = response.body.list;
      const prioritiesCards = priorities.map((priority) => {
        return {
          id: priority.id,
          text: priority.name,
          tbis_perc: priority.tbis_perc,
          tbis_est_perc: priority.tbis_est_perc,
          improvement: priority.improvement ? priority.improvement : 0.0
        };
      });

      setPrioritiesCards(prioritiesCards);
    };

    fetchData();
  }, [user]);

  return (
    <div>
      <h6 className={styles['section-header']}>Skill Priorities</h6>

      <div className={styles['priorities-improvements-header-container']}>
        <span className={styles['priorities-improvements-header']}>
          Improvement
        </span>
      </div>
      {prioritiesCards.map((cardData, index) => (
        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center',
            columnGap: '5px'
          }}
        >
          <div>{index + 1}.</div>
          <PriorityCard
            key={cardData.id}
            text={cardData.text}
            progress={
              getProgressValues(
                cardData.tbis_est_perc && cardData.tbis_est_perc !== 0
                  ? cardData.tbis_est_perc
                  : cardData.tbis_perc
              ).position
            }
          />
          <div className={styles['priority-card-improvement']}>
            {Number(cardData.improvement).toFixed(2)}%
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillPriorities;
