export default (map, arrayValue) =>
  map.sort((a, b) => {
    const first = a.getIn(arrayValue);
    const second = b.getIn(arrayValue);

    if (first > second) {
      return 1;
    }
    if (first < second) {
      return -1;
    }

    return 0;
  });
