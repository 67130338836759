import {
  EXTEND_TIME_REQUEST,
  EXTEND_TIME_SUCCESS,
  EXTEND_TIME_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Extend simulation time action
 *
 * @param{Number} id
 * @param{Number} minutes
 * @returns {{types: *[], promise: Request }}
 */

export default function extendSimulationTime(id, minutes) {
  return {
    types: [EXTEND_TIME_REQUEST, EXTEND_TIME_SUCCESS, EXTEND_TIME_FAILURE],
    promise: api().post(`/api/v1/users/${id}/extend-time/`, { minutes })
  };
}
