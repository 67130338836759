import {
  FETCH_MASS_ACTION_TAGS_REQUEST,
  FETCH_MASS_ACTION_TAGS_SUCCESS,
  FETCH_MASS_ACTION_TAGS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function fetchMassActionTags(selected) {
  return {
    types: [
      FETCH_MASS_ACTION_TAGS_REQUEST,
      FETCH_MASS_ACTION_TAGS_SUCCESS,
      FETCH_MASS_ACTION_TAGS_FAILURE
    ],
    promise: api().post(`/api/v1/mass/tags/selected/`, { selected })
  };
}
