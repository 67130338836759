// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { ActionButtons, ConfirmDelete, ExpandRow } from 'components';
import { Button } from 'reactstrap';
import { indicators as indicatorsAction } from 'actions/behaviors';
import { Indicators } from '../modals';

// Actions

// Styles
import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';

export default function getIndicatorsTable(behavior, context) {
  const disabled = behavior
    .get('languages')
    .find((l) => l.get('language') === 'english')
    .get('published');

  const fields = [
    {
      key: 'indicator',
      sortable: true,
      renderField: (item) => (
        <div>
          <ExpandRow
            noBorder
            header={<h5>{item.get('name')}</h5>}
            body={
              <div>
                <h6>
                  <FormattedMessage id="FORM_FIELDS.bars" />:
                </h6>
                <p dangerouslySetInnerHTML={{ __html: item.get('BARS') }} />
                <h6>
                  <FormattedMessage id="FORM_FIELDS.development_suggestion" />:
                </h6>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.get('development_suggestion')
                  }}
                />
                <h6>
                  <FormattedMessage id="FORM_FIELDS.more_info" />:
                </h6>
                <p
                  dangerouslySetInnerHTML={{ __html: item.get('more_info') }}
                />
              </div>
            }
          />
        </div>
      )
    }
  ];

  if (disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          edit={() => {
            context.addModal(
              <Indicators edit={item} onClose={context.removeModal} />
            );
          }}
        />
      )
    });
  }

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          edit={() => {
            context.addModal(
              <Indicators edit={item} onClose={context.removeModal} />
            );
          }}
          delete={() => {
            context.addModal(
              <ConfirmDelete
                action={indicatorsAction}
                data={{
                  type: 'delete',
                  behaviorId: behavior.get('id'),
                  data: {},
                  id: item.get('id')
                }}
                onClose={context.removeModal}
              />
            );
          }}
        />
      )
    });
  }

  return {
    data: behavior.has('indicators')
      ? behavior.get('indicators').toArray()
      : [],
    customClassName: 'indicators-table',
    fields,
    button: (
      <Button
        id="new_indicator_button"
        name="new_indicator_button"
        onClick={() => {
          context.addModal(<Indicators onClose={context.removeModal} />);
        }}
        className={styles['add-button']}
        disabled={disabled}
      >
        <FormattedMessage id="NEW_INDICATOR" />
      </Button>
    )
  };
}
