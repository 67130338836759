import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Input, Row, Col } from 'reactstrap';

import styles from '../styles.css';
import { getCurrentAssessmentId } from '../../../components/Folders/components/actions';

const AssessmentSelect = (props, context) => {
  const { data, filterOptions, jit, route, transitionTo, user } = props;
  const { intl } = context;

  const assessmentSelectTransition = (value) => {
    transitionTo('/analytics', {
      tab: props.route.query.tab || '1',
      selected: props.route.query.selected,
      current: props.route.query.current,
      assessment: value,
      scroll: 0,
      filtered: props.route.query.filtered
    });
  };

  const getOptions = () => {
    if (filterOptions) {
      const filtered = [];

      data
        .sort((a, b) => {
          const aDate = a.get('date');
          const bDate = b.get('date');

          if (moment(aDate).isBefore(moment(bDate))) {
            return -1;
          }

          if (moment(bDate).isBefore(moment(aDate))) {
            return 1;
          }

          return 0;
        })
        .map((i) => {
          if (
            !filtered.find(
              (f) => getCurrentAssessmentId(f) === getCurrentAssessmentId(i)
            )
          ) {
            return filtered.push(i);
          }

          return false;
        });

      return filtered;
    }

    return data;
  };

  useEffect(() => {
    if (
      props.route.query.assessment != null &&
      !getOptions().find(
        (a) =>
          getCurrentAssessmentId(a) === props.route.query.assessment.toString()
      )
    ) {
      assessmentSelectTransition(null);
    }
  }, [props.route.query.selected, props.route.query.tab]);

  const options = getOptions();

  return (
    <Row className={classNames(styles['report-select-row'])}>
      <Col xs={3}>
        <strong>
          <FormattedMessage id="ASSESSMENT" />:
        </strong>
      </Col>
      <Col xs={9}>
        <Input
          id="assessment_input_select"
          name="assessment_input_select"
          type="select"
          onChange={(e) => assessmentSelectTransition(e.target.value)}
          value={
            route.query.assessment != null
              ? route.query.assessment?.toString()
              : ''
          }
          defaultValue={route.query.assessment?.toString()}
          style={{ display: 'inline-block', marginRight: 5, width: '95%' }}
        >
          {user.get('active_role') !== 'participant' && (
            <option value="">
              {intl.formatMessage({ id: 'SELECT_ASSESSMENT' })}
            </option>
          )}
          {options
            .sort((a, b) => {
              const first = a.get('assessment_name').toLowerCase();
              const second = b.get('assessment_name').toLowerCase();

              if (first < second) {
                return -1;
              }
              if (first > second) {
                return 1;
              }

              return 0;
            })
            .map((item, index) => (
              <option key={index} value={getCurrentAssessmentId(item)}>
                {item.get('assessment_name')}
              </option>
            ))}
        </Input>
        {jit}
      </Col>
    </Row>
  );
};

AssessmentSelect.contextTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default connect(
  (state) => ({
    route: state.router.route,
    user: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators({ transitionTo }, dispatch);
  }
)(React.memo(AssessmentSelect));
