// Vendor
import React from 'react';

// Forms
import { EditUserForm, CreatePartnerForm, CreateClientForm } from 'forms';

// Actions
import { editUser, editPartner, editClient } from 'actions/users';

/**
 * Get create user data
 * @param role
 * @param onSubmit
 * @param selectedRole
 * @param preferredTimesError
 * @returns {*}
 */
export default function getEditUserData(
  role,
  onSubmit,
  selectedRole,
  preferredTimesError
) {
  switch (role) {
    case 'partner': {
      return {
        component: <CreatePartnerForm onSubmit={onSubmit} />,
        action: editPartner
      };
    }
    case 'client': {
      return {
        component: <CreateClientForm onSubmit={onSubmit} />,
        action: editClient
      };
    }
    default:
      return {
        component: (
          <EditUserForm
            onSubmit={onSubmit}
            selectedRole={selectedRole}
            preferredTimesError={preferredTimesError}
          />
        ),
        action: editUser
      };
  }
}
