import {
  fetchSimulation,
  fetchSimulations,
  fetchSimulationContacts,
  fetchSimulationEmails,
  fetchDebriefs
} from 'actions/simulations';
import fetchBehaviors from '../actions/behaviors/fetchBehaviors';
import { Simulations } from '../views';

import { multipleOnEnterHandler } from './actions';
import { DETAIL_FETCH_ERROR, SIMULATION_NOT_FOUND } from '../constants/errors';

const SimulationRoutes = [
  {
    path: '/',
    component: Simulations.Preview,
    onEnter: multipleOnEnterHandler([
      { action: fetchSimulations, state: 'simulations', object: 'simulations' },
      { action: fetchBehaviors, state: 'behaviors', object: 'behaviors' },
      { action: fetchDebriefs, state: 'debriefs', object: 'debriefs' }
    ])
  },
  {
    path: '/create',
    component: Simulations.Create
  },
  {
    path: '/edit/:id',
    component: Simulations.Edit,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const { id } = nextRoute.vars;
      const { subTab } = nextRoute.query;
      const state = getState();
      const isDebrief = state.debriefs
        .getIn(['debriefs', 'english'])
        ?.has(Number(id));

      const promises = [];

      promises.push(
        dispatch(fetchSimulation(Number(id), Boolean(isDebrief))).then(
          (action) => {
            if (
              action.error &&
              action.error.body &&
              action.error.body === SIMULATION_NOT_FOUND
            ) {
              return (window.location = `/simulations?${DETAIL_FETCH_ERROR}=${action.error.body}`);
            }

            return true;
          }
        )
      );

      // Load behaviors after the subTab exercises was clicked
      if (Number(subTab) === 7) {
        // Load only published behaviors when editing the simulation
        promises.push(dispatch(fetchBehaviors(true, Number(id))));
      }

      return Promise.all(promises);
    }
  },
  {
    path: '/edit/:id/email',
    component: Simulations.Email,
    onEnter: (currentRoute, nextRoute, router, dispatch) => {
      const { id } = nextRoute.vars;

      const promises = [];
      promises.push(dispatch(fetchSimulationContacts(Number(id))));

      return Promise.all(promises);
    }
  },
  {
    path: '/edit/:id/email/:emailId',
    component: Simulations.Email,
    onEnter: (currentRoute, nextRoute, router, dispatch) => {
      const { id } = nextRoute.vars;

      const promises = [];
      promises.push(dispatch(fetchSimulationContacts(Number(id))));
      promises.push(dispatch(fetchSimulationEmails(Number(id))));

      return Promise.all(promises);
    }
  }
];

export default SimulationRoutes;
