import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import en from '@react-pdf-viewer/locales/lib/en_US.json';
import es from '@react-pdf-viewer/locales/lib/es_ES.json';
import fr from '@react-pdf-viewer/locales/lib/fr_FR.json';
import ja from '@react-pdf-viewer/locales/lib/jp_JP.json';
import ko from '@react-pdf-viewer/locales/lib/ko_KR.json';
import pt from '@react-pdf-viewer/locales/lib/pt_PT.json';
import zh from '@react-pdf-viewer/locales/lib/zh_CN.json';
import de from '@react-pdf-viewer/locales/lib/de_DE.json';
import tr from './locales/tr.json';
import sk from './locales/sk.json';
import nl from './locales/nl.json';
import id from './locales/id.json';
import ar_sa from './locales/ar-sa.json';
import { connect } from 'react-redux';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from '../../constants/cookies';

const PdfReader = (props) => {
  const { loggedUser, pdfUrl } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  let localization = null;

  switch (loggedUser.language) {
    case 'english':
      localization = en;
      break;
    case 'spanish':
      localization = es;
      break;
    case 'french':
      localization = fr;
      break;
    case 'japanese':
      localization = ja;
      break;
    case 'korean':
      localization = ko;
      break;
    case 'portuguese':
      localization = pt;
      break;
    case 'chinese':
      localization = zh;
      break;
    case 'turkish':
      localization = tr;
      break;
    case 'slovak':
      localization = sk;
      break;
    case 'dutch':
      localization = nl;
      break;
    case 'indonesian':
      localization = id;
      break;
    case 'saudi-arabic':
      localization = ar_sa;
      break;
    case 'german':
      localization = de;
      break;
    default:
      localization = en;
  }

  const getHeaders = () => {
    return {
      Accept: '*/*',
      Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
    };
  };

  return (
    <Worker workerUrl={'assets/js/pdf.worker.min.js'}>
      <div className="rpv-container">
        <Viewer
          className="rpv-toolbar__right rpv-default-layout__sidebar"
          localization={localization}
          fileUrl={pdfUrl}
          httpHeaders={getHeaders()}
          plugins={[defaultLayoutPluginInstance]}
          defaultScale={1}
          theme={{
            theme: 'light'
          }}
        />
      </div>
    </Worker>
  );
};

export default connect((state) => ({
  loggedUser: state.auth.get('loggedUser').toJS()
}))(PdfReader);
