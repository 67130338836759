import { Map, fromJS } from 'immutable';
import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILURE,
  FETCH_ASSIGNMENT_REQUEST,
  FETCH_ASSIGNMENT_SUCCESS,
  FETCH_ASSIGNMENT_FAILURE,
  FETCH_ASSESSOR_DATA_REQUEST,
  FETCH_ASSESSOR_DATA_SUCCESS,
  FETCH_ASSESSOR_DATA_FAILURE,
  SCORE_ASSIGNMENT_REQUEST,
  SCORE_ASSIGNMENT_SUCCESS,
  SCORE_ASSIGNMENT_FAILURE,
  FETCH_SCORED_ASSIGNMENTS_REQUEST,
  FETCH_SCORED_ASSIGNMENTS_SUCCESS,
  FETCH_SCORED_ASSIGNMENTS_FAILURE,
  TOKBOX_CONNECT_REQUEST,
  TOKBOX_CONNECT_SUCCESS,
  TOKBOX_CONNECT_FAILURE,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  assignments: Map(),
  filteredAssignments: Map(),
  assessorData: Map(),
  tokboxData: Map(),
  scoredAssignment: Map()
});

export default function assignments(state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSIGNMENTS_REQUEST:
      if (action.backgroundFetch) {
        return state;
      }

      return state.set('assignmentsLoading', true);
    case FETCH_ASSIGNMENTS_SUCCESS: {
      const assignments = action.result.body.data;
      const { total } = action.result.body;

      return state
        .set('assignmentsLoading', false)
        .set('assignmentsTotal', total)
        .set('assignmentsTotalCompleted', action.result.body.total_completed)
        .set('assignmentsTotalToday', action.result.body.total_today)
        .set('assignmentsTotalUpcoming', action.result.body.total_upcoming)
        .set(
          'filteredAssignments',
          Map(assignments.map((a) => [a.id, fromJS(a)]))
        )
        .setIn(['assignments'], Map(assignments.map((a) => [a.id, fromJS(a)])));
    }
    case FETCH_ASSIGNMENTS_FAILURE:
      return state.set('assignmentsLoading', false);
    case FETCH_ASSIGNMENT_REQUEST:
      return state.set('assignmentLoading', true);
    case FETCH_ASSIGNMENT_SUCCESS: {
      const id = Number(action.id);
      const assignment = action.result.body;

      return state
        .set('assignmentLoading', false)
        .mergeIn(['assignments', id], fromJS(assignment));
    }
    case FETCH_ASSIGNMENT_FAILURE:
      return state.set('assignmentLoading', false);
    case FETCH_ASSESSOR_DATA_REQUEST:
      return state.set('assessorDataLoading', true);
    case FETCH_ASSESSOR_DATA_SUCCESS: {
      const data = action.result.body;

      return state.withMutations((newState) => {
        Object.keys(data).map((key) =>
          newState
            .set('assessorDataLoading', false)
            .setIn(
              ['assessorData', key],
              Map(data[key].map((item) => [item.id, fromJS(item)]))
            )
        );
      });
    }
    case FETCH_ASSESSOR_DATA_FAILURE:
      return state.set('assessorDataLoading', false);
    case TOKBOX_CONNECT_REQUEST:
      return state.set('tokboxConnectLoading', true);
    case TOKBOX_CONNECT_SUCCESS:
      return state
        .set('tokboxConnectLoading', false)
        .set('tokboxData', action.result.body);
    case TOKBOX_CONNECT_FAILURE:
      return state.set('tokboxConnectLoading', false);
    case SCORE_ASSIGNMENT_REQUEST:
      return state.set('scoreAssignmentsLoading', true);
    case SCORE_ASSIGNMENT_SUCCESS: {
      /*
            const data = action.data;
            const finding = state.get('scoredAssignment').find(
                (a) =>
                    a.get('excercise_id') === data.excercise_id
                    && a.get('behavior_id') === data.behavior_id
                    && a.get('indicator_id') === data.indicator_id
            );
            data.id = finding.get('id');

            return state
                    .set('scoreAssignmentsLoading', false)
                    .setIn(['scoredAssignment', data.id], fromJS(data));
            */
      return state;
    }
    case SCORE_ASSIGNMENT_FAILURE:
      return state.set('scoreAssignmentsLoading', false);
    case FETCH_SCORED_ASSIGNMENTS_REQUEST:
      return state.set('fetchScoredLoading', true);
    case FETCH_SCORED_ASSIGNMENTS_SUCCESS: {
      const result = action.result.body;

      return state
        .set('fetchScoredLoading', false)
        .set('scoredAssignment', Map(result.map((r) => [r.id, fromJS(r)])));
    }
    case FETCH_SCORED_ASSIGNMENTS_FAILURE:
      return state.set('fetchScoredLoading', false);
    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
