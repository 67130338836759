import { getSelected, handleBufferDownload } from './index';

export default (formData, files, props, context) => {
  const { analyticsData, downloadPdf, route } = props;
  const { removeModal } = context;
  let _selected;

  if (!formData) {
    _selected = analyticsData.toArray().map((r) => {
      if (getSelected(route)?.indexOf(r.getIn(['user', 'id'])) > -1) {
        return r.has('files')
          ? r.get('files').map((f) => f.get('uuid'))
          : false;
      }

      return false;
    });
  } else {
    _selected = [
      files
        .find(
          (f) =>
            f.get('scope') === formData.version &&
            f.get('language') === formData.language
        )
        .get('uuid')
    ];
  }

  downloadPdf({ files: _selected }).then((action) => {
    if (!action.error) {
      handleBufferDownload(action, 'application/pdf');
      removeModal();
    } else {
      throw action.error;
    }
  });
};
