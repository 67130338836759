import {
  LIST_TIMES_REQUEST,
  LIST_TIMES_SUCCESS,
  LIST_TIMES_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List times action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function listTimes(
  id,
  from,
  to,
  sort,
  offset,
  limit,
  language,
  timezone,
  seed,
  partner,
  reschedule
) {
  const string = `?from=${from || ''}&to=${to || ''}&sort=${
    sort || ''
  }&offset=${offset || 0}&limit=${limit || 1000}`;
  const query = `?language=${language || ''}&timezone=${timezone}${
    seed ? `&seed=${seed}` : ''
  }${partner != null ? `&partner=${partner}` : ''}${
    reschedule != null ? '&reschedule=1' : ''
  }`;

  const req = api().get(
    `/api/v1/simulations/${id}/available-times/${from && to ? string : ''}${
      language != null ? query : ''
    }`
  );

  return {
    types: [LIST_TIMES_REQUEST, LIST_TIMES_SUCCESS, LIST_TIMES_FAILURE],
    promise: req
  };
}
