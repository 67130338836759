import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BuildTable, ToolTip } from 'components';

import { editAssessment } from 'actions/assessments';

const AnalyticsTranslateTable = (props) => {
  const { id, assessment, editAssessment } = props;
  const tableData = [];
  let analytics = props.analytics.toJS();
  let TPAenabled = false;

  if (assessment.get('partner')) {
    const partner = assessment.get('partner');

    partner.get('modules').forEach((module) => {
      if (module.get('name').toLowerCase() === 'development') {
        TPAenabled = true;
      }
    });
  } else if (assessment.get('client')) {
    const client = assessment.get('client');

    client.get('modules').forEach((module) => {
      if (module.get('name').toLowerCase() === 'development') {
        TPAenabled = true;
      }
    });
  } else {
    TPAenabled = true;
  }

  if (
    !analytics.overview &&
    !assessment.getIn(['simulation', 'is_psychometrics_only']) &&
    assessment.get('has_psychometrics')
  ) {
    analytics = {
      overview: {
        admin: false,
        stakeholder: false,
        participant: false
      },
      ...analytics
    };
  }

  if (
    !analytics['development-dashboard'] &&
    !assessment.getIn(['simulation', 'is_psychometrics_only']) &&
    assessment.get('has_psychometrics')
  ) {
    analytics = {
      ...analytics,
      'development-dashboard': {
        admin: false,
        stakeholder: false,
        participant: false
      }
    };
  }

  Object.keys(analytics).map((key) => {
    return tableData.push({
      name: key,
      data: analytics[key]
    });
  });

  const mirrorOption = (item, role, value) => {
    analytics[item.name][role] = value;

    switch (item.name) {
      case 'climate':
        analytics.personality[role] = value;
        break;
      case 'personality':
        analytics.climate[role] = value;
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <BuildTable
        table={{
          data: tableData
            .filter((v) => v.name !== 'climate' && v.name !== 'personality')
            .concat(
              tableData.filter(
                (v) => v.name === 'climate' || v.name === 'personality'
              )
            ),
          customClassName: `customize-analytics-table ${
            assessment.get('has_psychometrics') ? '' : 'no-after'
          }`,
          fields: [
            {
              key: 'report_name',
              sortable: true,
              renderField: (item) => {
                return (
                  <>
                    <FormattedMessage id={item.name} />
                    {item.name === 'development-dashboard' && (
                      <ToolTip
                        index="DEVELOPMENT_DASHBOARD_HINT"
                        hint={
                          <FormattedMessage id="DEVELOPMENT_DASHBOARD_HINT" />
                        }
                      />
                    )}

                    {item.name === 'narrative' && (
                      <ToolTip
                        index="NARRATIVE_HINT"
                        hint={<FormattedMessage id="NARRATIVE_HINT" />}
                      />
                    )}

                    {item.name === 'bench' && (
                      <ToolTip
                        index="BENCH_HINT"
                        hint={<FormattedMessage id="BENCH_HINT" />}
                      />
                    )}
                  </>
                );
              }
            },
            {
              key: 'participant',
              sortable: false,
              renderField: (item) => {
                let disabled = props.disabled || item.data.participant == null;
                if (
                  item.name === 'overview' &&
                  !props.published &&
                  item.data.participant != null
                ) {
                  disabled = false;
                }

                return (
                  <input
                    id={`input-${item.name}-participant`}
                    name={`input-${item.name}-participant`}
                    type="checkbox"
                    disabled={disabled}
                    checked={item.data.participant}
                    onChange={() => {
                      analytics[item.name].participant = !analytics[item.name]
                        .participant;
                      editAssessment(id, { analytics });
                    }}
                  />
                );
              }
            },
            {
              key: 'stakeholder',
              sortable: false,
              renderField: (item) => {
                let disabled = props.disabled;
                if (item.name === 'overview' && !props.published) {
                  disabled = false;
                }
                if (item.name === 'development-dashboard' && !props.published) {
                  disabled = false;

                  if (!TPAenabled) {
                    disabled = true;
                  }
                }

                return (
                  <input
                    id={`input-${item.name}-stakeholder`}
                    name={`input-${item.name}-stakeholder`}
                    type="checkbox"
                    disabled={disabled}
                    checked={item.data.stakeholder}
                    onChange={() => {
                      const nextValue = !analytics[item.name].stakeholder;
                      mirrorOption(item, 'stakeholder', nextValue);
                      editAssessment(id, { analytics });
                    }}
                  />
                );
              }
            },
            {
              key: 'admin',
              sortable: false,
              renderField: (item) => {
                let disabled = props.disabled;
                if (item.name === 'overview' && !props.published) {
                  disabled = false;
                }
                if (item.name === 'development-dashboard' && !props.published) {
                  disabled = false;

                  if (!TPAenabled) {
                    disabled = true;
                  }
                }

                return (
                  <input
                    id={`input-${item.name}-admin`}
                    name={`input-${item.name}-admin`}
                    type="checkbox"
                    disabled={disabled}
                    checked={item.data.admin}
                    onChange={() => {
                      const nextValue = !analytics[item.name].admin;
                      mirrorOption(item, 'admin', nextValue);
                      editAssessment(id, { analytics });
                    }}
                  />
                );
              }
            }
          ]
        }}
      />
    </div>
  );
};

AnalyticsTranslateTable.propTypes = {
  id: PropTypes.number.isRequired,
  analytics: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  published: PropTypes.bool,
  editAssessment: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    analytics: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id),
      'analytics'
    ])
  }),
  (dispatch) => bindActionCreators({ editAssessment }, dispatch)
)(AnalyticsTranslateTable);
