// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { submit } from 'redux-form';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { extendSimulationTime } from 'actions/users';
import { ExtendTimeModalForm } from '../../../../../../forms';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';

// Actions

/**
 * Extend time modal
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    users: state.users.get('allUsers')
  }),
  (dispatch) => bindActionCreators({ submit, extendSimulationTime }, dispatch)
)
export default class ExtendTime extends Component {
  // Prop types
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    users: PropTypes.object,
    submit: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    extendSimulationTime: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { onClose, extendSimulationTime, userId } = this.props;
    const { addAlert } = this.context;
    const time = data.minutes;

    extendSimulationTime(userId, time).then((action) => {
      if (!action.error) {
        window.scrollTo(0, 0);
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_EXTENDED" />
        });
        onClose();
      }
    });
  };

  /**
   * Submit form
   */
  submitForm = () => {
    const { submit } = this.props;
    submit('extendTimeModalForm');
  };

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { onClose } = this.props;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id="EXTEND_TIME" />
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              <FormattedMessage id="EXTEND_TIME_INFO" />
            </p>
            <ExtendTimeModalForm onSubmit={this.handleSubmit} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_extend_time"
                name="cancel_extend_time"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_extend_time"
                name="submit_extend_time"
                color="primary"
                onClick={this.submitForm}
                className={modals['footer-button']}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
