import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from 'constants/actionTypes';
import { AUTH_TOKEN, AUTH_REFRESH_TOKEN } from 'constants/cookies';
import { setCookie } from 'helpers';
import api from 'api';

/**
 * Logout action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function logout() {
  return {
    types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    promise: new Promise((resolve) => {
      if (window.platform != null) {
        api()
          .del('/api/v1/users/push-token/')
          .then(() => {
            api()
              .get('/api/v1/users/logout/')
              .then((response) => {
                resolve(response);
              });
          });
      } else {
        api()
          .get('/api/v1/users/logout/')
          .then((response) => {
            if (response.body.logout_url.value) {
              setCookie(AUTH_TOKEN, '', 365);
              setCookie(AUTH_REFRESH_TOKEN, '', 365);
              window.location = response.body.logout_url.value;
              return;
            }
            resolve(response);
          });
      }
    })
  };
}
