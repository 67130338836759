import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import { Map, fromJS } from 'immutable';

import { validate } from './validate';

import styles from '../../../styles.css';
import '../../../styles.css';

import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup
} from 'reactstrap';

import api from 'api';
import { renderInput, renderDateInput } from '../../../actions';

export const form = 'editSimulationBasicInformation';

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    simulationsLoading: state.app.get('simulationsLoading'),
    debriefsLoading: state.app.get('debriefsLoading'),
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    debrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ]),
    isLoading:
      state.simulations.get('editSimulationLoading') ||
      state.simulations.get('createSimulationLoading') ||
      state.debriefs.get('createDebriefLoading'),
    loggedUser: state.auth.get('loggedUser'),
    pinsightSkillsLibraryAllowed:
      // If pinsight admin, default pinsight skill library is always allowed
      // If partner, we check if default pinsight skill library is allowed in modules
      !state.auth.get('loggedUser').get('isPartner') &&
      !state.auth.get('loggedUser').get('isPartner')
        ? true
        : state.auth.hasIn(['loggedUser', 'partner', 'modules'])
        ? state.auth
            .getIn(['loggedUser', 'partner', 'modules'])
            .toArray()
            .some((module) => module === 'PinsightSkillsLibrary')
        : false
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class BasicInformation extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    simulation: PropTypes.object,
    simulationsLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loggedUser: PropTypes.object.isRequired,
    debrief: PropTypes.object,
    debriefsLoading: PropTypes.bool,
    pinsightSkillsLibraryAllowed: PropTypes.object
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { skillLibraries: [] };
  }

  async componentDidMount() {
    const {
      change,
      simulation,
      debrief,
      pinsightSkillsLibraryAllowed
    } = this.props;
    const session = simulation || debrief;

    // The response is paginated, so we use limit=500 to make sure to get all
    const response = await api().get(
      `/api/v2/skills-libraries/?limit=500&orderBy=id&include_default=${
        pinsightSkillsLibraryAllowed ? 1 : 0
      }`
    );

    const skillLibraries = response.body.pagedResults;
    this.setState({ skillLibraries });

    if (session) {
      change(form, 'name', session.get('name'));
      change(form, 'lenght', session.get('lenght'));
      change(form, 'isDebrief', session.get('is_debrief'));
      change(form, 'skillsLibraryId', session.get('skills_library_id'));
    } else {
      change(form, 'skillsLibraryId', skillLibraries[0].id);
    }
  }

  render() {
    const { intl } = this.context;
    const {
      simulation,
      debrief,
      handleSubmit,
      isLoading,
      transitionTo,
      disabled,
      loggedUser
    } = this.props;

    const session = simulation || debrief;

    const version =
      session &&
      session.has('languages') &&
      session.get('languages').find((l) => l.get('language') === 'english');

    const isPinsightAdmin =
      loggedUser.get('active_role') === 'admin' &&
      !loggedUser.get('isPartner') &&
      !loggedUser.get('isClient');

    const message = session ? 'SAVE' : 'CREATE_SIMULATION';

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published') || disabled) {
        return true;
      }

      if (version.get('was_published')) {
        switch (field) {
          case 'name':
            return false;
          case 'lenght':
            return true;
          default:
            return true;
        }
      }

      return false;
    };

    const libraryProvider = () => {
      return Map(
        this.state.skillLibraries.map((item) => [item.name, fromJS(item)])
      ).toArray();
    };

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={5}>
            <FormGroup>
              <Label htmlFor="assessment">
                <FormattedMessage
                  id={
                    debrief
                      ? 'FORM_FIELDS.debrief_name'
                      : 'FORM_FIELDS.simulation_name'
                  }
                />
              </Label>
              <Field
                name="name"
                component={renderInput}
                type="text"
                placeholder={intl.formatMessage({ id: 'NEW_SIMULATION' })}
                disabled={isFieldDisabled('name')}
              />
            </FormGroup>
            <FormGroup className={styles['special-input-sim-length']}>
              <Label htmlFor="assessment">
                <FormattedMessage
                  id={
                    debrief
                      ? 'FORM_FIELDS.debrief_length'
                      : 'FORM_FIELDS.simulation_length'
                  }
                />
                :
              </Label>
              <Field
                name="lenght"
                component={renderDateInput}
                disabled={isFieldDisabled('lenght')}
                d
                h
                m
              />
            </FormGroup>
            <FormGroup>
              <Label for="skillsLibraryId">
                <FormattedMessage
                  id="Select"
                  defaultMessage="Choose a skills library"
                />
              </Label>
              <Field
                name="skillsLibraryId"
                type="select"
                valueKey="id"
                optionKey="name"
                component={renderInput}
                options={libraryProvider()}
                noOrder
                noEmpty
                disabled={session} // Session exists only while editing
              />
            </FormGroup>
            {
              // User is logged in as Pinsight admin and in "create new simulation" process
              // checkbox is not visible while editing
              isPinsightAdmin && !session && !simulation ? (
                <FormGroup>
                  <Label for="isDebrief" />
                  <InputGroup className={styles['consent-checkbox']}>
                    <Label check>
                      <Field
                        name="isDebrief"
                        component={renderInput}
                        type="checkbox"
                      />
                      <FormattedMessage id="FORM_FIELDS.is_debrief" />
                    </Label>
                  </InputGroup>
                </FormGroup>
              ) : null
            }
          </Col>
        </Row>
        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="cancel_basic_information"
              name="cancel_basic_information"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/simulations')}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_basic_information"
              name="submit_basic_information"
              type="button"
              color="primary"
              onClick={handleSubmit}
              disabled={(version && version.get('published')) || disabled}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : message} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
