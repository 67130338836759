// Vendor
import { editUser, editPartner, editClient } from 'actions/users';
import { OrderedMap } from 'immutable';
import moment from 'moment';

import { fromJSOrdered } from 'helpers';

/**
 * Get table data
 * @param tableKey
 * @param data
 * @param user
 * @returns {{}}
 */
export default function getTableData(tableKey, data, user) {
  let table = {};

  // Set table fields and data pack
  switch (Number(tableKey)) {
    case 1: {
      const fields = [
        { key: 'firstname', sortable: true },
        { key: 'role', sortable: false }
      ];

      if (!user.get('isPartner') && !user.get('isClient')) {
        fields.push({ key: 'partner', sortable: true });
      }

      if (!user.get('isClient')) {
        fields.push({ key: 'client', sortable: true });
      }

      table = {
        editPath: '',
        customClassName: 'all-users-table',
        data: data.users.toArray(),
        fields,
        settings: { action: true }
      };
      break;
    }
    case 2: {
      const fields = [
        { key: 'name', sortable: true },
        { key: 'modules', sortable: false },
        { key: 'simulations', sortable: false },
        { key: 'language', sortable: false },
        { key: 'owner', sortable: true }
      ];

      table = {
        editPath: '/partner',
        customClassName: 'partners-table',
        data: data.partners.toArray(),
        fields,
        settings: { action: false },
        action: editPartner
      };
      break;
    }
    case 3: {
      const fields = [{ key: 'name', sortable: true }];

      if (!user.get('isPartner') && !user.get('isClient')) {
        fields.push({ key: 'partner', sortable: true });
      }

      fields.push({ key: 'modules', sortable: false });
      fields.push({ key: 'simulations', sortable: false });
      fields.push({ key: 'language', sortable: false });
      fields.push({ key: 'debriefs', sortable: false });
      fields.push({ key: 'owner', sortable: true });

      if (!user.get('isPartner') && !user.get('isClient')) {
        // Only visible by Pinsight admins
        fields.push({ key: 'sso_enabled', sortable: false });
      }

      table = {
        editPath: '/client',
        customClassName: 'clients-table',
        data: data.clients.toArray(),
        fields,
        settings: { action: false },
        action: editClient
      };
      break;
    }
    case 4: {
      const fields = [{ key: 'firstname', sortable: true }];

      if (!user.get('isPartner') && !user.get('isClient')) {
        fields.push({ key: 'partner', sortable: true });
      }

      fields.push({ key: 'simulations', sortable: false });
      fields.push({ key: 'language', sortable: false });
      fields.push({ key: 'debriefs', sortable: false });

      table = {
        editPath: '',
        customClassName: 'assessors-table',
        data: data.users
          .filter((user) => user.get('roles').contains('assessor'))
          .toArray(),
        fields,
        settings: { action: false },
        action: editUser
      };
      break;
    }
    case 5: {
      const fields = [{ key: 'firstname', sortable: true }];

      if (!user.get('isPartner') && !user.get('isClient')) {
        fields.push({ key: 'partner', sortable: true });
      }

      if (!user.get('isClient')) {
        fields.push({ key: 'client', sortable: true });
      }

      if (
        (!user.get('isPartner') && !user.get('isClient')) ||
        (user.get('isClient') && user.get('sso_enabled'))
      ) {
        // Only visible by Pinsight admins and Clients who have SSO enabled
        fields.push({ key: 'sso_enabled', sortable: false });
      }

      table = {
        editPath: '',
        customClassName: 'stakeholders-table',
        data: data.users
          .filter((user) => user.get('roles').indexOf('stakeholder') > -1)
          .toArray(),
        fields,
        settings: { action: false }
      };
      break;
    }
    case 6: {
      const fields = [
        { key: 'firstname', sortable: true },
        { key: 'is_owner', sortable: false }
      ];

      if (!user.get('isPartner') && !user.get('isClient')) {
        fields.push({ key: 'partner', sortable: true });
      }

      if (!user.get('isClient')) {
        fields.push({ key: 'client', sortable: true });
      }

      if (!user.get('isPartner') && !user.get('isClient')) {
        // Only visible by Pinsight admins
        fields.push({ key: 'sso_enabled', sortable: false });
      }

      table = {
        editPath: '',
        customClassName: 'admins-table',
        data: data.users
          .filter((user) => user.get('roles').contains('admin'))
          .toArray(),
        fields,
        settings: { action: false }
      };
      break;
    }
    case 7: {
      const fields = [
        { key: 'checkbox', sortable: false },
        { key: 'firstname', sortable: true }
      ];

      if (!user.get('isPartner') && !user.get('isClient')) {
        fields.push({ key: 'partner', sortable: true });
      }

      if (!user.get('isClient')) {
        fields.push({ key: 'client', sortable: true });
      }

      fields.push({ key: 'shared_with_stakeholders', sortable: false });
      fields.push({ key: 'service', sortable: true });
      fields.push({ key: 'scheduled', sortable: true });
      fields.push({ key: 'stage', sortable: false });

      const users = data.users.filter(
        (user) => user.get('roles').indexOf('participant') > -1
      );
      const usersInJsForm = [];
      const markedUsers = [];
      users.map((item) => usersInJsForm.push(item.toJS()));

      // Set sub arrays
      usersInJsForm.map((i) => {
        if (
          i.active_simulation &&
          i.active_simulation.is_psychometrics_only &&
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'simulation5' && stage.completed) &&
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'report1' && !stage.completed)
        ) {
          const updated = i;
          updated.incompleted = true;
          markedUsers.push(updated);
        } else if (
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'simulation5' && stage.completed) &&
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'report1' && !stage.completed) &&
          i.scheduled_at &&
          moment(i.scheduled_at.end_time).add(24, 'h').isBefore(moment())
        ) {
          const updated = i;
          updated.incompleted = true;
          markedUsers.push(updated);
        } else if (
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'simulation5' && stage.completed) &&
          i.scheduled_at &&
          !i.active_simulation.is_debrief &&
          moment(i.scheduled_at.end_time).add(24, 'h').isAfter(moment())
        ) {
          const updated = i;
          updated.pending = true;
          markedUsers.push(updated);
        } else if (
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'simulation5' && stage.completed) &&
          i.scheduled_at &&
          i.active_simulation.is_debrief
        ) {
          const updated = i;
          updated.completed = true;
          markedUsers.push(updated);
        } else if (
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'simulation4' && stage.completed) &&
          !Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'simulation5' && stage.completed) &&
          i.scheduled_at &&
          i.active_simulation.is_debrief
        ) {
          const updated = i;
          updated.pending = true;
          markedUsers.push(updated);
        } else if (
          i.scheduled_at &&
          moment(i.scheduled_at.end_time).isAfter(moment())
        ) {
          markedUsers.push(i);
        } else if (
          i.scheduled_at &&
          moment(i.scheduled_at.start_time).isAfter(moment())
        ) {
          markedUsers.push(i);
        } else if (
          i.active_simulation &&
          i.active_simulation.is_psychometrics_only &&
          Object.keys(i.stages)
            .map((key) => i.stages[key])
            .find((stage) => stage.name === 'report1' && stage.completed)
        ) {
          const updated = i;
          updated.completed = true;
          markedUsers.push(updated);
        } else if (!i.scheduled_at) {
          markedUsers.push(i);
        } else {
          const updated = i;
          updated.completed = true;
          markedUsers.push(updated);
        }

        return true;
      });

      const tableData = OrderedMap(
        markedUsers.map((i) => [i.id, fromJSOrdered(i)])
      ).toArray();

      table = {
        editPath: '',
        customClassName: [
          'participants-table',
          user.get('isPartner') && 'partner-view',
          user.get('isClient') && 'client-view'
        ],
        data: tableData,
        fields,
        settings: { action: true }
      };
      break;
    }
    case 8: {
      const fields = [{ key: 'firstname', sortable: true }];
      fields.push({ key: 'partner', sortable: true });
      fields.push({ key: 'client', sortable: true });

      table = {
        editPath: '',
        customClassName: 'superusers-table',
        data: data.users.toArray(),
        fields,
        settings: { action: false }
      };
      break;
    }
    default:
      break;
  }

  return table;
}
