import {
  CREATE_STRATEGY_REQUEST,
  CREATE_STRATEGY_SUCCESS,
  CREATE_STRATEGY_FAILURE,
  EDIT_STRATEGY_REQUEST,
  EDIT_STRATEGY_SUCCESS,
  EDIT_STRATEGY_FAILURE,
  DELETE_STRATEGY_REQUEST,
  DELETE_STRATEGY_SUCCESS,
  DELETE_STRATEGY_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Strategies action
 *
 * @param{Object} body
 * @returns {{types: *[], promise: Request }}
 */

export default function strategies(body) {
  let types;
  let promise;
  const { type } = body;
  const { assessmentId } = body;
  const { data } = body;
  const { id } = body;

  if (!data.language) {
    data.language = 'english'; // eslint-disable-line
  }

  switch (type) {
    case 'create':
      types = [
        CREATE_STRATEGY_REQUEST,
        CREATE_STRATEGY_SUCCESS,
        CREATE_STRATEGY_FAILURE
      ];
      promise = api().post(
        `/api/v1/assessment/${assessmentId}/strategies/`,
        data
      );
      break;
    case 'edit':
      types = [
        EDIT_STRATEGY_REQUEST,
        EDIT_STRATEGY_SUCCESS,
        EDIT_STRATEGY_FAILURE
      ];
      promise = api().put(
        `/api/v1/assessment/${assessmentId}/strategies/${id}/`,
        data
      );
      break;
    case 'delete':
      types = [
        DELETE_STRATEGY_REQUEST,
        DELETE_STRATEGY_SUCCESS,
        DELETE_STRATEGY_FAILURE
      ];
      promise = api().del(
        `/api/v1/assessment/${assessmentId}/strategies/${id}/`
      );
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    assessmentId,
    id,
    language: data.language
  };
}
