// Vendor
import React from 'react';

// Styles
import styles from '../styles.css';
import '../styles.css';

/**
 * Get notes table
 * @param data
 * @param rtl
 * @returns {XML}
 */
export default function getNotesTable(data, rtl) {
  return (
    data &&
    data.toArray().map((note) => (
      <div className={styles['one-note']} dir={rtl ? 'RTL' : 'LTR'}>
        <h6 dangerouslySetInnerHTML={{ __html: note.get('subject') }} />
        <p dangerouslySetInnerHTML={{ __html: note.get('text') }} />
      </div>
    ))
  );
}
