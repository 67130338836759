import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Create user action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function createUser(data) {
  const reduced = {};
  Object.keys(data).map((item) => (reduced[item] = data[item]));

  if (!reduced.invite_now) {
    delete reduced.active_simulation;
  }

  if (reduced.active_simulation != null) {
    reduced.active_simulation = { ...data.active_simulation };
  }

  if (reduced.scheduled_at == null) {
    delete reduced.scheduled_at;
  } else {
    reduced.scheduled_at = { ...data.scheduled_at };
    reduced.scheduled_at = {
      start_time: `${reduced.scheduled_at.day.format('YYYY-MM-DD')} ${
        reduced.scheduled_at.time
      }`
    };
  }

  if (reduced.active_simulation && reduced.active_simulation.language === ' ') {
    delete reduced.active_simulation.language;
  }

  delete reduced.invite_now;

  if (reduced.partner_id === 'null') {
    reduced.partner_id = null;
    reduced.client_id = null;
  }

  if (reduced.client_id === 'null') {
    reduced.client_id = null;
  }

  if (reduced.active_simulation && reduced.active_simulation.id) {
    delete reduced.active_simulation.id;
  }

  return {
    types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
    promise: api().post('/api/v1/users/', reduced)
  };
}
