export const validate = (values) => {
  const errors = {};

  if (Number(values.report_sharing_delay) % 1 !== 0) {
    errors.report_sharing_delay = 'AUTOMATION_INVALID_DECIMAL_VALUE';
  }

  if (Number(values.enabling_tpa_delay) % 1 !== 0) {
    errors.enabling_tpa_delay = 'AUTOMATION_INVALID_DECIMAL_VALUE';
  }

  if (
    Number(values.report_sharing_delay) < 0 ||
    Number(values.report_sharing_delay) > 99
  ) {
    errors.report_sharing_delay = 'AUTOMATION_INVALID_DELAY_VALUE';
  }

  if (
    Number(values.enabling_tpa_delay) < 0 ||
    Number(values.enabling_tpa_delay) > 99
  ) {
    errors.enabling_tpa_delay = 'AUTOMATION_INVALID_DELAY_VALUE';
  }

  return errors;
};
