import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from './utils';

const propTypes = {
  toggle: PropTypes.func,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  children: PropTypes.node
};

const defaultProps = {};

const ModalHeader = (props) => {
  let closeButton;
  const { className, cssModule, children, toggle, ...attributes } = props;

  const classes = mapToCssModules(
    classNames(className, 'modal-header'),
    cssModule
  );

  if (toggle) {
    closeButton = (
      <button
        type="button"
        onClick={toggle}
        className="close"
        aria-label="Close"
      >
        <span aria-hidden="true">{String.fromCharCode(215)}</span>
      </button>
    );
  }

  return (
    <div {...attributes} className={classes}>
      {closeButton}
      <h4 className="modal-title">{children}</h4>
    </div>
  );
};

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
