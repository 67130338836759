import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Alert, ButtonGroup, Button } from 'reactstrap';
import { BuildTable, ColorIndicator } from 'components';
import { UserBox } from '../../../components/Folders/components';

import { getProgressValues } from 'helpers';
import { reportsOfOneAssessment } from '.';

import styles from '../styles.css';
import '../styles.css';

const get9Box = (props, context) => {
  const [selected, setSelected] = useState(1);

  const sortItems = (data) =>
    data.sort((a, b) => {
      const first =
        a.getIn(['simulation', 'default', 'operf']) * 100 + a.get('obp');
      const second =
        b.getIn(['simulation', 'default', 'operf']) * 100 + b.get('obp');

      if (first > second) {
        return -1;
      }
      if (first < second) {
        return 1;
      }

      return 0;
    });

  const getSmallConfig = (current) => ({
    chart: {
      height: 70,
      width: 115,
      type: 'scatter',
      zoomType: 'none',
      plotBackgroundImage: 'assets/img/9box.png?v=2',
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: ''
    },
    xAxis: {
      title: {
        enabled: false
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      gridLineColor: 'transparent',
      min: 0,
      max: 100
    },
    yAxis: {
      title: {
        enabled: false
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      gridLineColor: 'transparent',
      min: 0,
      max: 100
    },
    plotOptions: {
      scatter: {
        visible: true,
        showInLegend: 'false',
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        id: current.get('id'),
        data: [
          [
            Number(
              parseFloat(
                current.getIn(['simulation', 'default', 'operf']) * 100
              ).toFixed(2)
            ),
            current.get('obp')
          ]
        ],
        showInLegend: false,
        marker: {
          symbol: 'circle',
          radius: 3,
          fillColor: '#6e7474'
        }
      },
      {
        id: current.get('id'),
        data: [
          [
            Number(
              parseFloat(
                current.getIn(['simulation', 'default', 'operf']) * 100
              ).toFixed(2)
            ) + (current.getIn(['simulation', 'default', 'improvement']) || 0),
            current.get('obp')
          ]
        ],
        showInLegend: false,
        marker: {
          symbol: 'circle',
          radius: 5,
          fillColor: '#11b0da'
        }
      }
    ]
  });

  const getTable = () => {
    const data = props.data.toArray().filter((analyticsData) => {
      // Only analytics with performance
      // And assessment has "9box" analytics
      return (
        analyticsData.toJS().simulation?.default?.operf &&
        analyticsData.get('assessment_sections').includes('9box')
      );
    });

    const fields = [
      {
        key: 'rank',
        sortable: false,
        centered: true,
        renderField: (item, index) => `${index}`
      },
      {
        key: data.size === 1 ? 'participant' : 'participants',
        sortable: false,
        renderField: (item) => (
          <UserBox user={item.get('user')} report={item} nomarginR nomarginB />
        )
      },
      {
        key: 'performance',
        sortable: false,
        centered: true,
        renderField: (item) => (
          <strong>
            {Math.round(item.getIn(['simulation', 'default', 'operf']) * 100)}%
          </strong>
        )
      }
    ];

    if (data.find((r) => r.get('is_improvement_visible') === true)) {
      fields.push({
        key: 'improvement',
        sortable: false,
        centered: true,
        renderField: (item) => {
          if (item.get('is_improvement_visible') === false) {
            return <strong>N/A</strong>;
          }

          return (
            <strong>
              {parseFloat(
                item.getIn(['simulation', 'default', 'improvement']) || 0
              ).toFixed(2)}
              %
            </strong>
          );
        }
      });
    }

    fields.push(
      {
        key: 'potential',
        sortable: false,
        centered: true,
        renderField: (item) => <strong>{Math.round(item.get('obp'))}%</strong>
      },
      {
        key: 'recommendation',
        sortable: false,
        renderField: (item) => {
          const performance = getProgressValues(
            item.getIn(['simulation', 'default', 'operf']) * 100
          );
          const potential = getProgressValues(item.get('obp'));

          return (
            <span>
              <span style={{ position: 'relative' }}>
                <ColorIndicator type="line" color={performance.colorString} />
                <b>
                  <FormattedMessage
                    id={`${performance.position}_PERFORMANCE`}
                  />
                </b>
              </span>{' '}
              /{' '}
              <span style={{ position: 'relative' }}>
                <ColorIndicator type="line" color={potential.colorString} />
                <b>
                  <FormattedMessage id={`${potential.position}_POTENTIAL`} />
                </b>
              </span>
              <p style={{ marginBottom: 3 }}>
                <FormattedMessage
                  id={`9BOX_REC_${performance.position}_${potential.position}`}
                />
              </p>
            </span>
          );
        }
      }
    );

    if (data.find((r) => r.get('is_improvement_visible') === true)) {
      fields.push({
        key: 'graph',
        sortable: false,
        centered: true,
        renderField: (item) =>
          item.get('is_improvement_visible') === false ? (
            <strong>
              <FormattedMessage id="APP_NOT_ENABLED" />
            </strong>
          ) : (
            <div className={styles['table-graph']}>
              <ReactHighcharts config={getSmallConfig(item)} />
            </div>
          )
      });
    }

    return {
      data: sortItems(data),
      customClassName: 'b9box-table',
      fields
    };
  };

  const getConfig = (data, view) => ({
    chart: {
      height: 500,
      type: 'scatter',
      zoomType: 'none',
      plotBackgroundImage: 'assets/img/9box.png?v=2'
    },
    title: {
      text: ''
    },
    xAxis: {
      title: {
        text: context.intl.formatMessage({ id: '9_BOX_PERFORMANCE' }),
        style: {
          'font-size': '20px'
        },
        y: 15
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      gridLineColor: 'transparent',
      min: 0,
      max: 100
    },
    yAxis: {
      title: {
        text: context.intl.formatMessage({ id: '9_BOX_POTENTIAL' }),
        style: {
          'font-size': '20px'
        },
        x: -10
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      gridLineColor: 'transparent',
      min: 0,
      max: 100
    },
    plotOptions: {
      scatter: {
        visible: true,
        showInLegend: 'false',
        dataLabels: {
          align: 'center',
          verticalAlign: 'middle',
          enabled: true,
          format: '{series.name}',
          style: {
            fontSize: '16px',
            color: '#FFFFFF',
            outlineColor: '#FFFFFF',
            textOutline: 0,
            visibility: 'visible',
            opacity: 1
          }
        },
        marker: {
          radius: 15,
          fillColor: '#0275d8',
          symbol: 'circle',
          zIndex: 0,
          states: {
            hover: {
              enabled: false
            },
            select: {
              enabled: false
            }
          }
        }
      }
    },
    tooltip: {
      formatter() {
        // eslint-disable-next-line
        const report = data.find((r) => r.get('id') === this.series.options.id);
        const name = `${report.getIn(['user', 'firstname'])} ${report.getIn([
          'user',
          'surname'
        ])}`;

        return `<b>${name}, ${report.get('assessment_name')}</b>`;
      }
    },
    credits: {
      enabled: false
    },
    series: sortItems(data)
      .toArray()
      .map((report, index) => {
        return {
          id: report.get('id'),
          name: `${index + 1}`,
          pointIntervalUnit: `${index + 1}`,
          color: 'gray',
          data:
            view === 1
              ? [
                  [
                    Number(
                      parseFloat(
                        report.getIn(['simulation', 'default', 'operf']) * 100
                      ).toFixed(2)
                    ),
                    report.get('obp')
                  ]
                ]
              : [
                  [
                    Number(
                      parseFloat(
                        report.getIn(['simulation', 'default', 'operf']) * 100
                      ).toFixed(2)
                    ) +
                      (report.getIn(['simulation', 'default', 'improvement']) ||
                        0),
                    report.get('obp')
                  ]
                ],
          showInLegend: false
        };
      })
  });

  const data = props.data.filter((analyticsData) => {
    // Only assessment has "9box" analytics
    return analyticsData.get('assessment_sections').includes('9box');
  });
  const report = reportsOfOneAssessment(props);

  return (
    <div>
      <Alert className={styles['analytics-infobox']} color="info" isOpen>
        <FormattedMessage
          id={data.size === 1 ? '9_BOX_INFO_INDIVIDUAL' : '9_BOX_INFO'}
        />
      </Alert>

      {report && (
        <div className="text-center">
          <h4 className={styles['analytics-section']}>
            <FormattedMessage id="ASSESSMENT" />:{' '}
            {report.get('assessment_name')}
          </h4>
        </div>
      )}

      <div className="text-center">
        <ButtonGroup>
          <Button
            active={selected === 1}
            outline
            color="primary"
            size="lg"
            onClick={() => setSelected(1)}
          >
            <FormattedMessage id="TABLE_HEADING.simulation" />
          </Button>{' '}
          {data.find((r) => r.get('is_improvement_visible') === true) && (
            <Button
              active={selected === 2}
              outline
              color="primary"
              size="lg"
              onClick={() => setSelected(2)}
            >
              <FormattedMessage id="TABLE_HEADING.today-after-improvement" />
            </Button>
          )}
        </ButtonGroup>
      </div>

      <div
        style={{ margin: '40px 0' }}
        dir="ltr"
        className="html2pdf__page-break"
      >
        <ReactHighcharts config={getConfig(data, selected)} />
      </div>
      <div className="html2pdf__page-break">
        <BuildTable table={getTable()} />
      </div>
    </div>
  );
};

get9Box.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect((state) => ({
  route: state.router.route,
  selectedReport: state.analytics.get('selectedAnalyticsIndividualUser')
}))(
  React.memo(
    get9Box,
    (prevProps, nextProps) => prevProps?.data?.size === nextProps?.data?.size
  )
);
