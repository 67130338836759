/**
 * Get user table tabs
 * @param permissions
 * @param allUsers
 */

export default function getUserTableTabs(permissions, allUsers) {
  const userTableTabs = [
    { id: '1', title: 'all' },
    { id: '2', title: 'partners' },
    { id: '3', title: 'clients' },
    { id: '4', title: 'assessors' },
    { id: '5', title: 'stakeholders' },
    { id: '6', title: 'admins' },
    { id: '7', title: 'participants' },
    { id: '8', title: 'superusers' }
  ];

  const result = [];

  permissions
    .filter((permission) => permission.indexOf('table_tab') > 0)
    .map((permission) => {
      switch (permission) {
        case 'users_table_tab_all_view_own':
          result.push(userTableTabs[0]);
          break;
        case 'users_table_tab_partners_view_own':
          result.push(userTableTabs[1]);
          break;
        case 'users_table_tab_clients_view_own':
          result.push(userTableTabs[2]);
          break;
        case 'users_table_tab_assessors_view_own':
          if (
            allUsers != null &&
            allUsers.find((user) => user.get('roles').contains('assessor'))
          ) {
            result.push(userTableTabs[3]);
          }

          break;
        case 'users_table_tab_stakeholders_view_own':
          result.push(userTableTabs[4]);
          break;
        case 'users_table_tab_admins_view_own':
          result.push(userTableTabs[5]);
          break;
        case 'users_table_tab_participants_view_own':
          result.push(userTableTabs[6]);
          break;
        case 'users_table_tab_participants_view_superuser':
          result.push(userTableTabs[7]);
          break;
        default:
          break;
      }

      return true;
    });

  return result;
}
