// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { BuildTable, Link, Loader } from 'components';
import * as tables from './actions';

// Styles
import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';
import previewStyles from './styles.css';
import './styles.css';

// Actions
import { getAssessorData } from 'actions/assignments';

/**
 * Preview Table
 * @class
 */
@connect(
  (state) => ({
    assignment: state.assignments.getIn([
      'assignments',
      Number(state.router.route.vars.id)
    ]),
    assessorData: state.assignments.get('assessorData'),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ getAssessorData }, dispatch)
)
export default class PreviewTable extends Component {
  // Prop types
  static propTypes = {
    assignment: PropTypes.object.isRequired,
    assessorData: PropTypes.array,
    getAssessorData: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.tab || '1',
      ready: false
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { getAssessorData, assignment } = this.props;
    const userId = assignment.getIn(['user', 'id']);

    getAssessorData(userId, assignment.get('id')).then(() =>
      this.setState({ ready: true })
    );

    this.backgroundRefreshInterval = setInterval(() => {
      getAssessorData(userId, assignment.get('id'), {
        backgroundRefresh: true
      });
    }, 60000);
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.tab !== nextProps.route.query.tab) {
      this.toggle(nextProps.route.query.tab);
    }
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    if (this.backgroundRefreshInterval) {
      clearInterval(this.backgroundRefreshInterval);
    }
  }

  /**
   * Get tab content
   * @param tab
   * @param assessorData
   */
  getTabContent = (tab, assessorData, isDebrief = false) => {
    const { assignment } = this.props;
    const rtl = assignment.getIn(['user', 'language']) === 'hebrew';

    if (isDebrief) {
      return (
        <BuildTable
          table={tables.getRoleplaysTable(
            assessorData.get('roleplays'),
            this.context
          )}
        />
      );
    }

    switch (tab) {
      case '1':
        return (
          <div>{tables.getNotesTable(assessorData.get('notes'), rtl)}</div>
        );
      case '2':
        return (
          <BuildTable
            table={tables.getEmailsTable(
              assessorData.get('emails'),
              this.context,
              rtl
            )}
          />
        );
      case '3':
        return (
          <BuildTable
            table={tables.getRoleplaysTable(
              assessorData.get('roleplays'),
              this.context
            )}
          />
        );
      case '4':
        return (
          <BuildTable
            table={tables.getVoicemailsTable(
              assessorData.get('voicemails'),
              this.context
            )}
          />
        );
      case '5':
        return (
          <div>
            <BuildTable
              table={tables.getLogsTable(
                assessorData.get('logs'),
                this.context,
                assessorData.get('emails'),
                rtl
              )}
            />
            <span className={previewStyles['green-hint']}>
              <span className={previewStyles.preview} />
              <FormattedMessage id="GREEN_PRE_WORK_DATA" />
            </span>
          </div>
        );
      default:
        return <div />;
    }
  };

  /**
   * Toggle
   * @param tab
   */
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { assignment, assessorData, route } = this.props;
    const { activeTab, ready } = this.state;
    const isMonitoring =
      route.query &&
      route.query.backLink &&
      route.query.backLink.indexOf('users') > -1;

    const simulation = assignment.get('simulation');
    const isDebrief =
      simulation.has('is_debrief') && simulation.get('is_debrief');

    let previewTableTabs = [];

    if (isDebrief) {
      previewTableTabs = [{ id: '1', key: 'debrief-calls' }];
    } else {
      previewTableTabs = [
        { id: '1', key: 'notes' },
        { id: '2', key: 'emails' },
        { id: '3', key: 'role-plays' },
        { id: '4', key: 'voicemails' },
        { id: '5', key: 'logs' }
      ];
    }

    return (
      <div>
        <Nav tabs className={styles.offset}>
          {previewTableTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.key}`}
                to={`/${
                  isMonitoring ? 'users' : 'assignments'
                }/score/${assignment.get('id')}`}
                query={{
                  fromTab: route.query.fromTab || '1',
                  tab: tab.id.toString(),
                  backLink: route.query.backLink || null
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`TABLE_HEADING.${tab.key}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab}>
          {previewTableTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {tab.id === activeTab && ready ? (
                    this.getTabContent(activeTab, assessorData, isDebrief)
                  ) : (
                    <Loader />
                  )}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
