import {
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  GET_ME
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get me action
 *
 * @param{Boolean} disallowRedirect
 * @returns {{types: *[], promise: Request }}
 */
export default function getMe(disallowRedirect, silent) {
  const types = [];

  if (silent) {
    types.push(GET_ME, GET_ME_SUCCESS, GET_ME_FAILURE);
  } else {
    types.push(GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_FAILURE);
  }

  return {
    types,
    promise: api().get('/api/v1/users/me/'),
    disallowRedirect,
    silent
  };
}
