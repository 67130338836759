import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';

import styles from './styles.css';
import './styles.css';

export default class ToolTip extends Component {
  static propTypes = {
    hint: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.string.isRequired
    ]),
    index: PropTypes.string.isRequired,
    icon: PropTypes.string,
    ownContent: PropTypes.bool,
    children: PropTypes.object,
    active: PropTypes.bool,
    style: PropTypes.object,
    tooltipPopupClass: PropTypes.string,
    tooltipPlacement: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  toggle = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  render() {
    const {
      hint,
      index,
      icon,
      ownContent,
      children,
      active,
      style,
      tooltipPopupClass,
      tooltipPlacement
    } = this.props;
    const { visible } = this.state;

    if (ownContent) {
      return (
        <span
          onMouseOver={() => this.setState({ visible: active })}
          onMouseOut={() => this.setState({ visible: false })}
          id={`Tooltip-${index}`}
        >
          {children}
          <Tooltip
            placement="top"
            isOpen={active && visible}
            target={`Tooltip-${index}`}
            toggle={this.toggle}
          >
            {hint || ''}
          </Tooltip>
        </span>
      );
    }

    if (icon) {
      return (
        <span
          className={styles['icon-tooltip']}
          onMouseOver={() => this.setState({ visible: true })}
          onMouseOut={() => this.setState({ visible: false })}
          id={`Tooltip-${index}`}
        >
          <img src={icon} alt={icon} width="20" height="20" />
          <Tooltip
            placement="top"
            isOpen={visible}
            target={`Tooltip-${index}`}
            toggle={this.toggle}
            className={styles['icon-tooltip_hint']}
          >
            {hint || '-'}
          </Tooltip>
        </span>
      );
    }

    return (
      <span
        style={style || {}}
        className={styles.tooltip}
        onMouseOver={() => this.setState({ visible: true })}
        onMouseOut={() => this.setState({ visible: false })}
        id={`Tooltip-${index}`}
      >
        ?
        <Tooltip
          placement={tooltipPlacement || 'top'}
          isOpen={visible}
          target={`Tooltip-${index}`}
          toggle={this.toggle}
          className={tooltipPopupClass && styles[tooltipPopupClass]}
        >
          {hint || '-'}
        </Tooltip>
      </span>
    );
  }
}
