export default function getFileType(src) {
  const type = src.split('.').pop();

  switch (type) {
    case 'jpg':
    case 'png':
      return 'image';
    case 'doc':
    case 'docx':
      return 'word';
    case 'pdf':
      return 'pdf';
    case 'xlxs':
      return 'excel';
    default:
      return 'other';
  }
}
