import {
  UPDATE_LOCK_NARRATIVE_REQUEST,
  UPDATE_LOCK_NARRATIVE_SUCCESS,
  UPDATE_LOCK_NARRATIVE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Update lock narrative action
 *
 * @param{Number} report_id
 * @param{Boolean} is_locked
 * @returns {{types: *[], promise: Request }}
 */

export default function updateLockNarrative(report_id, is_locked) {
  return {
    types: [
      UPDATE_LOCK_NARRATIVE_REQUEST,
      UPDATE_LOCK_NARRATIVE_SUCCESS,
      UPDATE_LOCK_NARRATIVE_FAILURE
    ],
    promise: api().put(`/api/v1/analytics/${report_id}/narrative/`, {
      is_locked
    }),
    report_id,
    is_locked
  };
}
