import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import api from 'api';

import PreferencesForm from './PreferencesForm';
import { FormattedMessage } from 'react-intl';

@connect((state) => ({
  route: state.router.route
}))
class NotificationEmailPreferences extends React.Component {
  state = {
    loading: false,
    tpa_email_rate: null
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  static propTypes = {
    route: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    this.getUserPreferences();
  }

  getUserPreferences = async () => {
    const { route } = this.props;
    const { addAlert } = this.context;

    this.setState({ isLoading: true });

    try {
      const response = await api().get(
        `/api/v1/users/preferences/?token=${route.query.token}`
      );
      this.setState({
        tpa_email_rate: response.body.preferences.tpa_email_rate,
        isLoading: false
      });
    } catch (e) {
      addAlert({
        color: 'danger',
        content: JSON.stringify(e)
      });
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = async (data) => {
    const { addAlert } = this.context;
    const { route } = this.props;

    this.setState({ isLoading: true });

    try {
      const response = await api().put(
        `/api/v1/users/preferences/?token=${route.query.token}`,
        data
      );
      this.setState({
        isLoading: false,
        tpa_email_rate: response.body.preferences.tpa_email_rate
      });
      addAlert({
        color: 'success',
        content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
      });
    } catch (e) {
      addAlert({
        color: 'danger',
        content: JSON.stringify(e)
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { tpa_email_rate, isLoading } = this.state;

    return (
      <PreferencesForm
        isLoading={isLoading}
        initialValue={tpa_email_rate}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default NotificationEmailPreferences;
