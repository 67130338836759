import { Customize } from 'views';

import { fetchAssessments, fetchAssessment } from 'actions/assessments';
import { fetchBehavior } from 'actions/behaviors';
import { multipleOnEnterHandler } from './actions';
import {
  ASSESSMENT_NOT_FOUND,
  DETAIL_FETCH_ERROR,
  SIMULATION_NOT_FOUND
} from '../constants/errors';

const CustomizeRoutes = [
  {
    path: '/',
    component: Customize.Preview,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();

      if (
        state.assessments.get('assessments').size === 0 ||
        state.assessments.get('assessments').size <
          state.assessments.get('assessmentsTotal')
      ) {
        return dispatch(fetchAssessments());
      }

      return Promise.resolve();
    }
  },
  {
    path: '/assessment/create',
    component: Customize.NewAssessment,
    onEnter: multipleOnEnterHandler([
      { action: fetchAssessments, state: 'assessments', object: 'assessments' }
    ])
  },
  {
    path: '/assessment/edit/:id',
    component: Customize.EditAssessment,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();

      return dispatch(fetchAssessment(nextRoute.vars.id)).then((action) => {
        const promises = [];

        if (
          action.error &&
          action.error.body &&
          (action.error.body === ASSESSMENT_NOT_FOUND ||
            action.error.body === SIMULATION_NOT_FOUND)
        ) {
          return (window.location = `/customize?${DETAIL_FETCH_ERROR}=${action.error.body}`);
        }

        action.result.body.behaviors.map((behavior) => {
          if (
            !state.behaviors.hasIn([
              'behaviors',
              'english',
              behavior.behavior_id
            ])
          ) {
            return promises.push(dispatch(fetchBehavior(behavior.behavior_id)));
          }

          return false;
        });

        return Promise.all(promises);
      });
    }
  }
];

export default CustomizeRoutes;
