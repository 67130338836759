import {
  SCHEDULE_ASSESSOR_REQUEST,
  SCHEDULE_ASSESSOR_SUCCESS,
  SCHEDULE_ASSESSOR_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Schedule assessor action
 *
 * @param{Number} id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function scheduleAssessor(id, data) {
  return {
    types: [
      SCHEDULE_ASSESSOR_REQUEST,
      SCHEDULE_ASSESSOR_SUCCESS,
      SCHEDULE_ASSESSOR_FAILURE
    ],
    promise: api().post(`/api/v1/users/${id}/schedule-assessor/`, data)
  };
}
