import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'components';
import { FormattedMessage } from 'react-intl';

import { formatError } from 'helpers';
import { getInviteUserData } from '../actions';
import { getShareReportsAccessFormData } from '../../../../forms/Global/ShareReportsAccessForm';

import styles from '../styles.css';
import '../styles.css';

@connect(
  (state) => ({
    attributes: state.router.route.attrs,
    shareReportsAccessFormData: getShareReportsAccessFormData(state)
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class Invite extends Component {
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    attributes: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    shareReportsAccessFormData: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      data: getInviteUserData(this.props.attributes.role, this.handleSubmit)
    };
  }

  handleSubmit = (formData) => {
    const {
      attributes,
      transitionTo,
      dispatch,
      shareReportsAccessFormData
    } = this.props;
    const { addAlert } = this.context;
    const { data } = this.state;
    const { action } = data;

    dispatch(
      action(
        { ...formData, sharing: [shareReportsAccessFormData] },
        attributes.role
      )
    ).then((action) => {
      if (!action.error) {
        const { invalid } = action.result.body;
        const { valid } = action.result.body;

        transitionTo('/users').then(() => {
          if (Object.keys(invalid).length > 0) {
            addAlert({
              color: 'danger',
              content: Object.keys(invalid).map((error) => (
                <span>
                  <FormattedMessage id={error} />
                  {': '}
                  {invalid[error].join(', ')}
                  <br />
                </span>
              ))
            });
          }

          if (valid.length > 0) {
            addAlert({
              color: 'success',
              content: (
                <span>
                  <FormattedMessage id={data.success_message} /> (
                  {valid.map((u) => u.email).join(', ')})
                </span>
              )
            });
          }
        });
      } else {
        const { errors } = action.error.body;
        if (errors) {
          addAlert({
            color: 'danger',
            content: formatError(errors)
          });
        }
      }
    });
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        <div className={styles['link-back']}>
          <Link to="/users" id="back_to_users">
            &larr; <FormattedMessage id="BACK_TO_USERS" />
          </Link>
        </div>
        <h1>
          <FormattedMessage id={data.heading} />
        </h1>

        {data.component}
      </div>
    );
  }
}
