import React from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';
import { NarrativeForm } from 'forms';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';

import {
  fetchDetailAnalyticsData,
  updateLockNarrative,
  updateNarrative
} from 'actions/analytics';

@connect(
  (state) => ({
    isLoading: state.simulations.get('emailLoading'),
    formErrors: state.app.get('formErrors') || {},
    readOnly: state.router.route.query.readOnly,
    updateNarrativeLoading: state.analytics.get('updateNarrativeLoading'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        fetchDetailAnalyticsData,
        submit,
        updateLockNarrative,
        updateNarrative,
        transitionTo
      },
      dispatch
    );
  }
)
class NarrativeModal extends React.Component {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    updateNarrative: PropTypes.func.isRequired,
    updateLockNarrative: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired,
    updateNarrativeLoading: PropTypes.bool,
    fetchDetailAnalyticsData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    removeAllAlerts: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { removeAllAlerts } = this.context;
    removeAllAlerts();
  }

  UNSAFE_componentWillMount() {
    const { report, updateLockNarrative } = this.props;
    updateLockNarrative(report.get('id'), true);
  }

  componentWillUnmount() {
    const { report, updateLockNarrative } = this.props;
    updateLockNarrative(report.get('id'), false);
  }

  handleSubmit = (data) => {
    const { fetchDetailAnalyticsData, report, updateNarrative } = this.props;
    const { addAlert, removeModal } = this.context;
    const { narrative, author_name, author_company } = data;

    const isEmpty = (value) =>
      value == null || !value || value === ' ' || value === `<p>&nbsp;</p>`;

    updateNarrative(report.get('id'), {
      narrative: isEmpty(narrative) ? ' ' : narrative,
      author_name,
      author_company
    }).then((action) => {
      if (action.error?.body?.narrative) {
        return;
      }

      const response = action.result.body;

      if (response.error || action.error) {
        addAlert({
          color: 'danger',
          content: (
            <FormattedMessage
              id={response.error || action.error}
              values={{
                firstname: response ? response.locked_by.firstname : '',
                surname: response ? response.locked_by.surname : ''
              }}
            />
          )
        });
        window.scrollTo(0, 0);
      } else {
        this.removeLocalStorageData();

        // Fetch updated data
        fetchDetailAnalyticsData({ report_id: report.get('id') });
      }

      removeModal();
    });
  };

  onChange = (value) => {
    localStorage.setItem(
      `narrative-${this.props.report.get('id')}-value`,
      value
    );
  };

  removeLocalStorageData = () => {
    localStorage.removeItem(`narrative-${this.props.report.get('id')}-value`);
  };

  render() {
    const { report, submit, updateNarrativeLoading, data } = this.props;
    const { removeModal } = this.context;

    const userImg = report.hasIn(['user', 'profile_photo'])
      ? `${process.env.REACT_APP_ASSETSURL}${report.getIn([
          'user',
          'profile_photo'
        ])}`
      : '/assets/img/profile-photo-placeholder.gif';

    return (
      <Modal isOpen toggle={() => false} size="lg">
        <ModalHeader
          toggle={() => {
            removeModal();
            this.removeLocalStorageData();
          }}
          style={{ textAlign: 'center' }}
        >
          <FormattedMessage id="WRITE_A_NARRATIVE_SUMMARY" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div className={modals['user-info']}>
            <div
              className={modals['user-avatar']}
              style={{ backgroundImage: `url(${userImg})` }}
            />
            <div className={modals['user-name']}>
              <h6>
                {`${report.getIn(['user', 'firstname'])} ${report.getIn([
                  'user',
                  'surname'
                ])}`}
              </h6>
            </div>
          </div>

          <NarrativeForm
            data={data}
            report={report}
            onSubmit={this.handleSubmit}
            onValueChange={this.onChange}
            defaultValue={localStorage.getItem(
              `narrative-${report.get('id')}-value`
            )}
          />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={12} className={modals['modal-button-col']}>
              <Button
                id="submit_narrative_modal"
                name="submit_narrative_modal"
                color="primary"
                onClick={() => submit('narrativeForm')}
                className={modals['footer-button']}
                disabled={updateNarrativeLoading}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default NarrativeModal;
