// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Subviews
import {
  Preview,
  Create,
  Edit,
  CreateBehavior,
  EditBehavior
} from './subviews';
import Email from './components/EditSimulationTable/subviews/Email';

/**
 * Simulations
 * @param props
 * @constructor
 */
const Simulations = (props) => (
  <div>
    <Helmet title="Pinsight - SimManager" />
    {props.children}
  </div>
);

// Prop Types
Simulations.propTypes = {
  children: PropTypes.any
};

Simulations.Preview = Preview;
Simulations.Create = Create;
Simulations.Edit = Edit;
Simulations.Email = Email;
Simulations.CreateBehavior = CreateBehavior;
Simulations.EditBehavior = EditBehavior;

export default Simulations;
