import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.css';
import './styles.css';

const SurveyBanner = (props) => {
  const {
    show,
    headingTextId,
    bannerTextId,
    buttonTextId,
    openSurveyHandler,
    closeSurveyHandler
  } = props;

  return (
    <div
      className={styles['feedback-survey-container']}
      style={{
        display: show ? 'block' : 'none'
      }}
    >
      <h4>
        <FormattedMessage id={headingTextId} />
      </h4>
      <p>
        <FormattedMessage id={bannerTextId} />
      </p>
      <button className="btn btn-primary-dark" onClick={openSurveyHandler}>
        <FormattedMessage id={buttonTextId} />
      </button>
      <button
        type="button"
        className={`${styles['feedback-survey-container-close']} close`}
        aria-label="Close"
        onClick={closeSurveyHandler}
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
};

export default SurveyBanner;
