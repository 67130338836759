// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Confirm modal
 * @param props
 * @returns {XML}
 * @constructor
 */
const ConfirmModal = (props) => {
  const { isOpen, onClose, onConfirm, focuses } = props;

  const current = focuses.find(
    (f) => f.getIn(['indicator', 'id']) === Number(isOpen)
  );

  return (
    <div
      className={classNames(styles.modal, isOpen && styles.open)}
      key="confirm-modal"
    >
      <div className={styles.modal__box}>
        <button
          id="close_button"
          name="close_button"
          className={styles['close-button']}
          onClick={onClose}
          aria-label="Close window"
        />
        <p>
          <FormattedMessage id="YOU_HAVE_SELECTED" />
          <br />
          <strong>
            {current ? (
              <span>
                {current.getIn(['behavior', 'name'])} -{' '}
                <FormattedMessage id="EXERCISE" /> {current.get('focus_index')}
              </span>
            ) : null}
          </strong>
          <br />
          <FormattedMessage id="TO_WORK_NEXT_WEEK" />!
        </p>
        <button
          id="go_back_button"
          name="go_back_button"
          className={styles.white}
          onClick={onClose}
        >
          &larr; <FormattedMessage id="GO_BACK" />
        </button>
        <button
          id="confirm_button"
          name="confirm_button"
          className={styles.green}
          onClick={onConfirm}
        >
          <FormattedMessage id="CONFIRM" />
        </button>
      </div>
    </div>
  );
};

// Prop Types
ConfirmModal.propTypes = {
  isOpen: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  focuses: PropTypes.object.isRequired
};

export default connect((state) => ({
  focuses: state.development.get('focuses')
}))(ConfirmModal);
