import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SummaryModal from './SummaryModal';
import { RESET_FORM_ERRORS } from '../../../../constants/actionTypes';
import { TechnicalSupportForm } from 'forms';
import { postTicket } from 'actions/development';

import styles from './styles.css';
import './styles.css';

@connect(
  (state) => ({
    router: state.router,
    currentFocus: state.development.get('currentFocus'),
    locale: state.app.get('locale')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo, postTicket }, dispatch);
  }
)
export default class Overview extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.object,
    transitionTo: PropTypes.func.isRequired,
    currentFocus: PropTypes.object.isRequired,
    locale: PropTypes.string,
    postTicket: PropTypes.func.isRequired
  };

  static contextTypes = {
    changeHeading: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  state = {
    summaryModalOpen: false
  };

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.context.changeHeading(
        this.context.intl.formatMessage({ id: 'TECHNICAL_SUPPORT' })
      );
    }, 50);
  }

  handleSubmit = (data) => {
    const { postTicket, dispatch } = this.props;

    dispatch({ type: RESET_FORM_ERRORS });

    postTicket(data).then((response) => {
      if (!response.error) {
        this.openModal();
      }
    });
  };

  openModal = () => {
    this.setState({ summaryModalOpen: true });
  };

  closeModal = () => {
    const { transitionTo, router } = this.props;

    this.setState({ summaryModalOpen: false });

    transitionTo(
      router.route.query != null
        ? router.route.query.backLink
        : '/development/app',
      router.route.query.backLinkQuery
    );
  };

  render() {
    return (
      <Container className={styles['technical-support']}>
        <Row>
          <Col xs={{ size: 12 }}>
            <p>
              <FormattedMessage id="TECHNICAL_SUPPORT_INFO" />
            </p>
            <TechnicalSupportForm onSubmit={this.handleSubmit} />
          </Col>
        </Row>
        <SummaryModal
          isOpen={this.state.summaryModalOpen}
          onClose={this.closeModal}
        />
      </Container>
    );
  }
}
