import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { change } from 'redux-form';

import { Col, Row, Label, Input } from 'reactstrap';
import { Map } from 'immutable';

const notificationEmailPreferencesField = 'tpa_email_rate';
const preferences = ['daily', 'weekly', 'monthly', 'none'];

class NotificationPreferencesBlock extends React.Component {
  constructor(props) {
    super(props);

    const { initialValue, user, loggedUser } = this.props;
    const preference =
      initialValue != null
        ? initialValue
        : user != null
        ? user.getIn(['preferences', notificationEmailPreferencesField])
        : loggedUser.getIn(['preferences', notificationEmailPreferencesField]);

    this.state = {
      [notificationEmailPreferencesField]: preference
    };
  }

  /* eslint-disable */
  componentWillReceiveProps(nextProps) {
    if (this.props.initialValue !== nextProps.initialValue) {
      this.changeUserNotificationPreferences(nextProps.initialValue, nextProps);
    }
  }

  changeUserNotificationPreferences = (preference, nextProps) => {
    const { change, form, initialValue } = nextProps;

    this.setState({ [notificationEmailPreferencesField]: preference });
    change(
      form,
      initialValue == null
        ? `preferences.${notificationEmailPreferencesField}`
        : notificationEmailPreferencesField,
      preference
    );
  };

  render() {
    const { disabled } = this.props;

    return (
      <Row>
        <Col xs={12}>
          <h5>
            <FormattedMessage id="NOTIFICATION_EMAIL_PREFERENCES" />
          </h5>
          <p>
            <FormattedMessage id="NOTIFICATION_EMAIL_PREFERENCES_INFO" />
          </p>
        </Col>
        <Col xs={5}>
          {preferences.map((preference, index) => (
            <Row key={index}>
              <Label htmlFor={`preference${index}`}>
                <Col xs={1}>
                  <Input
                    name={notificationEmailPreferencesField}
                    id={`preference${index}`}
                    type="radio"
                    checked={
                      preference ===
                      this.state[notificationEmailPreferencesField]
                    }
                    onChange={() =>
                      this.changeUserNotificationPreferences(
                        preference,
                        this.props
                      )
                    }
                    disabled={disabled}
                  />
                </Col>
                <Col xs={6}>
                  <FormattedMessage
                    id={`NOTIFICATION_EMAIL_PREFERENCES.${preference}`}
                  />
                </Col>
              </Label>
            </Row>
          ))}
        </Col>
      </Row>
    );
  }
}

NotificationPreferencesBlock.propTypes = {
  form: PropTypes.string.isRequired,
  notificationEmailPreferences: PropTypes.string,
  disabled: PropTypes.bool,
  user: PropTypes.object,
  loggedUser: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  initialValue: PropTypes.string
};

export default connect(
  (state) => {
    return {
      user:
        state.users.getIn(['allUsers', Number(state.router.route.vars.id)]) ||
        Map(),
      loggedUser: state.auth.get('loggedUser')
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        change
      },
      dispatch
    );
  }
)(NotificationPreferencesBlock);
