import PropTypes from 'prop-types';
import React from 'react';

const Tag = (props, context) => (
  <span
    id={`tag_${props.tag.name}`}
    className={props.classNames.selectedTag}
    title={context.intl.formatMessage({ id: 'CLICK_TO_REMOVE_TAG' })}
    style={props.tag.style}
    onClick={props.onClick}
  >
    <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
    <button
      id={`tag_button_${props.tag.name}`}
      type="button"
      name={`tag_button_${props.tag.name}`}
      onClick={props.onDelete}
      style={props.tag.style}
    >
      &#x2715;
    </button>
  </span>
);

Tag.contextTypes = {
  intl: PropTypes.object.isRequired
};

Tag.propTypes = {
  tag: PropTypes.object,
  classNames: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Tag;
