import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change, Field, formValueSelector } from 'redux-form';

import { InputGroup, Row, Col } from 'reactstrap';

import styles from '../../styles.css';

import { getSelected } from '../../../views/Analytics/components/Folders/components/actions';
import { renderInput } from '../../actions';

const form = 'downloadPDFForm';

@reduxForm({
  form
})
@connect(
  (state) => ({
    analyticsData: state.analytics.get('allData'),
    route: state.router.route,
    user: state.auth.get('loggedUser'),
    version: formValueSelector(form)(state, 'version')
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class DownloadPDFForm extends Component {
  static propTypes = {
    analyticsData: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    version: PropTypes.string,
    user: PropTypes.object.isRequired,
    multipleDownload: PropTypes.bool
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      disabled: false
    };
  }

  componentDidMount() {
    const { user, change, data } = this.props;
    const languages = [];

    data.map((i) => {
      if (!languages.find((v) => v === i.get('language'))) {
        return languages.push(i.get('language'));
      }

      return false;
    });

    if (languages.indexOf(user.get('language')) > -1) {
      change(form, 'language', user.get('language'));
    } else {
      change(form, 'language', 'english');
    }

    if (user.get('active_role') === 'stakeholder') {
      change(form, 'version', 'stakeholder');
    } else if (user.get('active_role') === 'assessor') {
      change(form, 'version', 'participant');
    } else if (user.get('active_role') === 'participant') {
      change(form, 'version', 'participant');
    }
  }

  render() {
    const {
      analyticsData,
      user,
      handleSubmit,
      data,
      version,
      route,
      multipleDownload
    } = this.props;
    const { loading } = this.state;
    let languages;
    const final = [];
    const filtered = [];
    const versions = [];
    const helper = {};

    data.map((i) => {
      helper[i.get('scope')] = helper[i.get('scope')] || [];
      helper[i.get('scope')].push(i.get('language'));
      if (!versions.find((v) => v.get('scope') === i.get('scope'))) {
        versions.push(i);
      }

      return filtered.push(i);
    });

    if (!version) {
      languages = filtered;
    } else {
      languages = filtered.filter(
        (f) =>
          helper[version] && helper[version].indexOf(f.get('language')) > -1
      );
    }

    languages.map((f) => {
      let allowPushLanguage = true;

      if (final.find((i) => i.get('language') === f.get('language'))) {
        return false;
      }

      if (user.get('active_role') !== 'participant' && multipleDownload) {
        getSelected(route)?.map((reportId) => {
          if (
            !analyticsData
              .getIn([reportId, 'files'])
              .find((file) => file.get('language') === f.get('language'))
          ) {
            return (allowPushLanguage = false);
          }

          return false;
        });
      }

      return allowPushLanguage ? final.push(f) : false;
    });

    const isPinsightAdmin =
      user.get('active_role') === 'admin' &&
      !user.get('isPartner') &&
      !user.get('isClient');

    const isDirectClientAdmin =
      user.get('active_role') === 'admin' &&
      user.get('isClient') &&
      !user.has('partner');

    const hasClientNewReportingActivated =
      user.hasIn(['client', 'modules']) &&
      user.getIn(['client', 'modules']).contains('NewReporting');

    return (
      <Row>
        <Col xs="12">
          <form className={styles.form} onSubmit={handleSubmit}>
            <InputGroup>
              <label htmlFor="version">
                <FormattedMessage id="FORM_FIELDS.version" /> (
                <FormattedMessage id="BASED_ON_PERMISSIONS" />)
              </label>
              <Field
                loading={loading}
                name="version"
                component={renderInput}
                type="select"
                valueKey="scope"
                optionKey="scope"
                options={versions}
                translationKey={
                  // eslint-disable-next-line prettier/prettier
                  isPinsightAdmin || (isDirectClientAdmin && hasClientNewReportingActivated)
                    ? 'ROLE_VALUES.download'
                    : 'ROLE_VALUES'
                }
                placeholder="PLEASE_SELECT_VERSION"
                translatePlaceholder
                disabled={user.get('active_role') === 'participant'}
              />
            </InputGroup>
            <InputGroup>
              <label htmlFor="language">
                <FormattedMessage id="FORM_FIELDS.language" />
              </label>
              <Field
                loading={loading}
                name="language"
                component={renderInput}
                type="select"
                valueKey="language"
                optionKey="language"
                options={final}
                translationKey="LANGUAGE_VALUES"
                placeholder="PLEASE_SELECT_LANGUAGE"
                translatePlaceholder
              />
            </InputGroup>
          </form>
        </Col>
      </Row>
    );
  }
}
