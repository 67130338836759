import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ActionButtons, ConfirmDelete, UploadBlock } from 'components';

export default class UploadRow extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    data: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    simulation: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    emailId: PropTypes.number,
    disabled: PropTypes.bool,
    wasPublished: PropTypes.bool,
    isDebrief: PropTypes.bool
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      output: []
    };
  }

  render() {
    const {
      data,
      onDrop,
      action,
      simulation,
      version,
      type,
      emailId,
      disabled,
      wasPublished,
      isDebrief
    } = this.props;
    const { output } = this.state;
    const { addModal, removeModal } = this.context;

    return (
      <div>
        {data ? (
          <ActionButtons
            secureDownload={data.get('path')}
            secureDownloadName={
              data.has('file_name')
                ? data.get('file_name')
                : data.get('filename')
            }
            upload={
              !disabled ? (
                <UploadBlock
                  link
                  onDrop={(files) => {
                    this.setState({ output: files });
                    onDrop(files);
                  }}
                  output={output}
                  title={wasPublished ? 'REPLACE' : 'UPLOAD'}
                  max={1}
                />
              ) : null
            }
            delete={
              !disabled && !wasPublished
                ? () => {
                    addModal(
                      <ConfirmDelete
                        action={action}
                        data={{
                          type: 'delete',
                          simulationId: simulation.get('id'),
                          data: { language: version },
                          id: emailId || data.get('id'),
                          attachmentId: data.get('id'),
                          isDebrief
                        }}
                        onClose={removeModal}
                      />
                    );
                  }
                : false
            }
          />
        ) : (
          <UploadBlock
            link
            onDrop={(files) => {
              this.setState({ output: files });
              onDrop(files);
            }}
            output={output}
            title={
              version === 'english'
                ? `UPLOAD_NEW_${type.toUpperCase()}`
                : 'UPLOAD_TRANSLATED_DOCUMENT'
            }
            max={1}
            linkDisabled={disabled}
          />
        )}
      </div>
    );
  }
}
