import { SET_VIRTUAL_BUTTON } from 'constants/actionTypes';

/**
 * Set locale action
 *
 * @param{String} virtual button number
 * @returns {{types: *[], promise: Request }}
 */

export default function setVirtualButton(activeButton) {
  return {
    type: SET_VIRTUAL_BUTTON,
    activeButton
  };
}
