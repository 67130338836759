import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NarrativeModal } from '../index';

export default (report, props, context) => {
  const { getNarrative } = props;
  const { addAlert, addModal } = context;

  getNarrative(report.get('id')).then((action) => {
    const response = action.result.body;

    if (response.is_locked === true) {
      addAlert({
        color: 'danger',
        content: (
          <FormattedMessage
            id="ERRORS.NARRATIVE_ALREADY_LOCKED"
            values={{
              firstname: response.locked_by.firstname,
              surname: response.locked_by.surname
            }}
          />
        )
      });
      window.scrollTo(0, 0);
    } else {
      addModal(<NarrativeModal report={report} data={response} />);
    }
  });
};
