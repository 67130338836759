import {
  GET_CURRENT_FOCUS_REQUEST,
  GET_CURRENT_FOCUS_SUCCESS,
  GET_CURRENT_FOCUS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get current focus action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function getCurrentFocus() {
  return {
    types: [
      GET_CURRENT_FOCUS_REQUEST,
      GET_CURRENT_FOCUS_SUCCESS,
      GET_CURRENT_FOCUS_FAILURE
    ],
    promise: api().get('/api/v1/development/focus/current/')
  };
}
