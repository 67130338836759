// Actions
import moment from 'moment';
import getStageProgress from '../views/Users/components/UsersTable/actions/getStageProgress';

/**
 * Sort items
 * @param data
 * @param sortBy
 * @returns {*}
 */
export default function sortItems(data, sortBy) {
  if (sortBy.key) {
    return data.sort((a, b) => {
      // For subKeys set compare values
      let first;
      let second;

      switch (sortBy.key) {
        // Sort by log type
        case 'log-type':
          first = a.get('type') || '';
          second = b.get('type') || '';
          break;
        // Sort by date
        case 'date':
          first = a.get('created_at') || a.get('date') || '';
          second = b.get('created_at') || b.get('date') || '';
          break;
        // Sort by strategy name
        case 'strategy_name':
          first = a.has('strategy')
            ? a.getIn(['strategy', 'name'])
            : a.get('name');
          second = b.has('strategy')
            ? b.getIn(['strategy', 'name'])
            : b.get('name');
          break;
        // Sort by exercise
        case 'exercise':
        case 'behavior_name':
        case 'assessment':
          first = a.get('name') || a.get('assessment_name') || '';
          second = b.get('name') || b.get('assessment_name') || '';
          break;
        case 'based_on_simulation':
          first = a.hasIn(['simulation', 'name'])
            ? a.getIn(['simulation', 'name'])
            : '';
          second = b.hasIn(['simulation', 'name'])
            ? b.getIn(['simulation', 'name'])
            : '';
          break;
        case 'firstname':
          first = a.has('firstname')
            ? `${a.get('firstname')} ${a.get('surname')}`
            : a.get('email');
          second = b.has('firstname')
            ? `${b.get('firstname')} ${b.get('surname')}`
            : b.get('email');
          break;
        // Sort by owner
        case 'owner':
          first = a.has('owner')
            ? a.hasIn(['owner', 'firstname'])
              ? `${a.getIn(['owner', 'firstname'])} ${a.getIn([
                  'owner',
                  'surname'
                ])}`
              : a.getIn(['owner', 'email']) || a.getIn(['owner', 'name'])
            : '';
          second = b.has('owner')
            ? b.hasIn(['owner', 'firstname'])
              ? `${b.getIn(['owner', 'firstname'])} ${b.getIn([
                  'owner',
                  'surname'
                ])}`
              : b.getIn(['owner', 'email']) || b.getIn(['owner', 'name'])
            : '';
          break;
        // Sort by service name
        case 'service': {
          const aSimulation = a.get('active_simulation');
          const bSimulation = b.get('active_simulation');
          let aActive = null;
          let bActive = null;

          if (aSimulation || bSimulation) {
            if (aSimulation) {
              aActive = a.hasIn(['active_simulation', 'assessment_name'])
                ? a.getIn(['active_simulation', 'assessment_name'])
                : a.getIn(['active_simulation', 'simulation_name']);
            }
            if (bSimulation) {
              bActive = b.hasIn(['active_simulation', 'assessment_name'])
                ? b.getIn(['active_simulation', 'assessment_name'])
                : b.getIn(['active_simulation', 'simulation_name']);
            }
          }
          first = aActive || '';
          second = bActive || '';
          break;
        }
        // Sort by stage
        case 'stage':
          first = a.has('stages')
            ? getStageProgress(a.get('stages')).subProgress
            : 0;
          second = b.has('stages')
            ? getStageProgress(b.get('stages')).subProgress
            : 0;
          break;
        case 'client':
          if (a.has('user')) {
            first = a.getIn(['user', 'client', 'id']) || 0;
            second = b.getIn(['user', 'client', 'id']) || 0;
          } else {
            first = a.get('client') ? a.get('client').get('name') : '';
            second = b.get('client') ? b.get('client').get('name') : '';
          }
          break;
        case 'partner':
          if (a.has('user')) {
            first = a.getIn(['user', 'partner', 'id']) || 0;
            second = b.getIn(['user', 'partner', 'id']) || 0;
          } else {
            first = a.get('partner')
              ? a.get('partner').get('name')
              : 'pinsight';
            second = b.get('partner')
              ? b.get('partner').get('name')
              : 'pinsight';
          }
          break;
        case 'simulation':
        case 'debrief':
        case 'behavior':
        case 'contact_name':
          first = a.get('name') || '';
          second = b.get('name') || '';
          break;
        case 'report_name':
          first = a.name === 'gaps' ? 'Skill gaps' : a.name || '';
          second = b.name === 'gaps' ? 'Skill gaps' : b.name || '';
          break;
        case 'duration':
          first = a.get('lenght') || '';
          second = b.get('lenght') || '';
          break;
        case 'status':
          first = a.has('status') ? a.get('status') : '';
          second = b.has('status') ? b.get('status') : '';
          break;
        case 'scheduled':
          first = a.has('scheduled_at')
            ? a.getIn(['scheduled_at', 'start_time'])
            : '';
          second = b.has('scheduled_at')
            ? b.getIn(['scheduled_at', 'start_time'])
            : '';
          break;
        case 'participant':
        case 'participants':
          first = a.hasIn(['user', 'firstname'])
            ? `${a.getIn(['user', 'firstname'])} ${a.getIn([
                'user',
                'surname'
              ])}`
            : a.getIn(['user', 'email']);
          second = b.hasIn(['user', 'firstname'])
            ? `${b.getIn(['user', 'firstname'])} ${b.getIn([
                'user',
                'surname'
              ])}`
            : b.getIn(['user', 'email']);
          break;
        case 'to':
          first = a.get('to') || a.getIn(['contact', 'name']) || '';
          second = b.get('to') || b.getIn(['contact', 'name']) || '';
          break;
        case 'from':
          first = a.get('from') || a.getIn(['contact', 'name']) || '';
          second = b.get('from') || b.getIn(['contact', 'name']) || '';
          break;
        default:
          first = a.get(sortBy.key) || '';
          second = b.get(sortBy.key) || '';
          break;
      }

      first = first ? first.toString().toLowerCase() : '';
      second = second ? second.toString().toLowerCase() : '';

      // Sort
      if (sortBy.asc) {
        if (sortBy.key === 'date') {
          const firstDate = moment(first.toUpperCase()).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          const secondDate = moment(second.toUpperCase()).format(
            'YYYY-MM-DD HH:mm:ss'
          );

          if (moment(firstDate).isAfter(secondDate)) {
            return 1;
          }
          if (moment(firstDate).isBefore(secondDate)) {
            return -1;
          }

          return 0;
        }

        if (first > second) {
          return 1;
        }
        if (first < second) {
          return -1;
        }

        return 0;
      }

      if (sortBy.key === 'date') {
        const firstDate = moment(first.toUpperCase()).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        const secondDate = moment(second.toUpperCase()).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        if (moment(firstDate).isBefore(secondDate)) {
          return 1;
        }
        if (moment(firstDate).isAfter(secondDate)) {
          return -1;
        }

        return 0;
      }

      if (first < second) {
        return 1;
      }
      if (first > second) {
        return -1;
      }

      return 0;
    });
  }

  return data;
}
