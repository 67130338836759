import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionButtons, ConfirmDelete } from 'components';
import { Button } from 'reactstrap';
import { events } from 'actions/simulations';
import { Event } from '../modals';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';

export default function getCalendarEventsTable(simulation, context, readOnly) {
  const version =
    simulation.has('languages') &&
    simulation.get('languages').find((l) => l.get('language') === 'english');
  const disabled = version.get('published');

  const fields = [
    {
      key: 'name',
      sortable: true,
      renderField: (item) => item.get('name')
    },
    {
      key: 'date',
      sortable: false,
      renderField: (item) => item.get('date')
    },
    {
      key: 'duration',
      sortable: false,
      renderField: (item) => item.get('duration')
    }
  ];

  if (disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <Button
          type="button"
          color="link"
          onClick={() => {
            context.addModal(
              <Event edit={item} readOnly onClose={context.removeModal} />
            );
          }}
        >
          <FormattedMessage id="VIEW" />
        </Button>
      )
    });
  }

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          edit={() => {
            context.addModal(
              <Event
                edit={item}
                version={version}
                onClose={context.removeModal}
              />
            );
          }}
          editDisabled={readOnly}
          delete={
            readOnly
              ? null
              : () => {
                  context.addModal(
                    <ConfirmDelete
                      action={events}
                      data={{
                        type: 'delete',
                        simulationId: simulation.get('id'),
                        data: {},
                        id: item.get('id')
                      }}
                      onClose={context.removeModal}
                    />
                  );
                }
          }
        />
      )
    });
  }

  return {
    data: simulation.has('events') ? simulation.get('events').toArray() : [],
    customClassName: 'calendar-table',
    fields,
    button: (
      <Button
        id="new_event_button"
        name="new_event_button"
        onClick={() => {
          context.addModal(
            <Event version={version} onClose={context.removeModal} isNew />
          );
        }}
        className={styles['add-button']}
        disabled={disabled || readOnly}
      >
        <FormattedMessage id="NEW_EVENT" />
      </Button>
    )
  };
}
