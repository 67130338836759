import { SET_LOCALE } from 'constants/actionTypes';

/**
 * Set locale action
 *
 * @param{String} locale
 * @returns {{types: *[], promise: Request }}
 */

export default function setLocale(locale) {
  return {
    type: SET_LOCALE,
    locale
  };
}
