import request from 'superagent-bluebird-promise';
import {
  DOWNLOAD_EXCEL_REQUEST,
  DOWNLOAD_EXCEL_SUCCESS,
  DOWNLOAD_EXCEL_FAILURE
} from 'constants/actionTypes';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from '../../constants/cookies';

export default function downloadExcel(reports) {
  return {
    types: [
      DOWNLOAD_EXCEL_REQUEST,
      DOWNLOAD_EXCEL_SUCCESS,
      DOWNLOAD_EXCEL_FAILURE
    ],
    promise: request
      .get(
        `${
          process.env.REACT_APP_APIURL
        }/api/v1/analytics/export/xlsx/?reports=${reports.join(',')}`
      )
      .set('Authorization', `Bearer ${getCookie(AUTH_TOKEN)}`)
      .responseType('arraybuffer')
  };
}
