// Vendor
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from 'constants/cookies';

// Components
import { Button } from 'reactstrap';
import { EmbedPreview } from '../components';

/**
 * Get voice-emails table
 * @param data
 * @param context
 * @returns {{data: *, fields: *[]}}
 */
export default function getVoicemailsTable(data, context) {
  return {
    data: data.toArray(),
    fields: [
      {
        key: 'date',
        sortable: true,
        renderField: (item) =>
          moment(item.get('created_at'))
            .utcOffset(item.get('created_at'))
            .format('DD MMM YYYY hh:mm:ss A')
      },
      {
        key: 'to',
        sortable: true,
        renderField: (item) => item.getIn(['contact', 'name'])
      },
      {
        key: 'archive_url',
        sortable: true,
        renderField: (item) => (
          <Button
            id={`open_voicemail_button_${item.get('id')}`}
            name={`open_voicemail_button_${item.get('id')}`}
            size="sm"
            color={
              ['uploaded', 'completed'].indexOf(item.get('archive_status')) ===
              -1
                ? 'link link-disabled'
                : 'link'
            }
            onClick={() => {
              if (
                ['uploaded', 'completed'].indexOf(
                  item.get('archive_status')
                ) === -1
              ) {
                alert('Video is being processed.');
              } else {
                context.addModal(
                  <EmbedPreview
                    src={`${item.get('archive_url')}?bearer=${getCookie(
                      AUTH_TOKEN
                    )}`}
                  />
                );
              }
            }}
          >
            <FormattedMessage id="OPEN" />
          </Button>
        )
      }
    ]
  };
}
