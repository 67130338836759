import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import api from 'api';

import { FETCH_USERS_FAILURE } from 'constants/actionTypes';

import { Alert } from 'reactstrap';
import { FilterForm } from 'forms';
import { ExpandRow } from 'components';

import styles from './styles.css';
import './styles.css';

import { fetchUsers } from 'actions/users';

const form = 'filter';

@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    users: state.users.get('users'),
    allUsers: state.users.get('allUsers'),
    filterPartners: state.partners.get('partners'),
    allPartners: state.partners.get('allPartners'),
    filterClients: state.clients.get('clients'),
    allClients: state.clients.get('allClients'),
    assessments: formValueSelector(form)(state, 'assessments'),
    clients: formValueSelector(form)(state, 'clients'),
    partners: formValueSelector(form)(state, 'partners'),
    tags: formValueSelector(form)(state, 'tags'),
    search: formValueSelector(form)(state, 'search'),
    stakeholders: formValueSelector(form)(state, 'stakeholders'),
    from: formValueSelector(form)(state, 'from'),
    to: formValueSelector(form)(state, 'to')
  }),
  (dispatch) => bindActionCreators({ change, fetchUsers, dispatch }, dispatch)
)
class Filter extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    callback: PropTypes.func,
    filterClients: PropTypes.object.isRequired,
    allClients: PropTypes.object.isRequired,
    filterPartners: PropTypes.object.isRequired,
    allPartners: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    allUsers: PropTypes.object.isRequired,
    assessments: PropTypes.array,
    clients: PropTypes.array,
    partners: PropTypes.array,
    tags: PropTypes.array,
    change: PropTypes.func.isRequired,
    search: PropTypes.string,
    stakeholders: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    tabRole: PropTypes.string,
    action: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    resetSortData: PropTypes.func
  };

  componentDidMount() {
    this.resetData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.tab !== nextProps.tab) {
      this.resetData();
    }
  }

  onResetFilter = (fetchOnResetClick) => {
    const { action, resetSortData, tabRole } = this.props;

    if (typeof resetSortData === 'function') {
      resetSortData();
    }

    if (fetchOnResetClick) {
      action(0, 25, {}, tabRole);
    }
  };

  resetData = () => {
    const { user, change, tabRole } = this.props;
    const onlySearch = tabRole !== 'participant';

    change(form, 'partners', []);
    change(form, 'clients', []);
    change(form, 'assessments', []);
    change(form, 'tags', null);
    change(form, 'search', null);
    change(form, 'stakeholders', null);

    if (!onlySearch) {
      change(form, 'from', '2017-01-01T10:00:00.000Z');
      change(form, 'to', moment().zone(user.get('timezone_offset')).format());
    } else {
      change(form, 'from', null);
      change(form, 'to', null);
    }
  };

  render() {
    const { callback, resetSortData, tabRole, action, dispatch } = this.props;
    const onlySearch = tabRole !== 'participant';

    const filter = (
      <FilterForm
        participantsForm
        onFilter={() => {
          api.pendingRequests.map((r) => r.abort());
          dispatch({ type: FETCH_USERS_FAILURE, error: 'Aborted' });

          if (typeof resetSortData === 'function') {
            resetSortData();
          }

          setTimeout(() => {
            /* Block to stop action on empty value
            const search = this.props.search;

            if (onlySearch && (!search || search === '' || search === ' ')) {
              return false;
            }
            */

            if (onlySearch) {
              return action(
                0,
                25,
                {
                  search: this.props.search
                },
                tabRole
              );
            }

            return action(0, 25, this.props, tabRole).then((res) => {
              if (typeof callback === 'function') {
                callback(res);
              }
            });
          }, 50);
        }}
        onResetFilter={this.onResetFilter}
        onlySearch={onlySearch}
        fetchOnResetClick
      />
    );

    if (onlySearch) {
      return filter;
    }

    return (
      <div className={styles.filter}>
        <ExpandRow
          header={
            <h5>
              <FormattedMessage id="FILTER" />
            </h5>
          }
          body={
            <div>
              <Alert color="info">
                <FormattedMessage id="ANALYTICS_FILTER_NOTE" />
              </Alert>
              {filter}
            </div>
          }
          noBorder
          rowWithTopBorder
          defaultOpen
        />
      </div>
    );
  }
}

export default Filter;
