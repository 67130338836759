/**
 * Sort roles
 * @param roles
 */
export default function sortRoles(roles) {
  const order = ['owner', 'admin', 'assessor', 'stakeholder', 'participant'];

  return roles.sort((a, b) => {
    if (order.indexOf(a) > order.indexOf(b)) {
      return 1;
    }
    if (order.indexOf(a) < order.indexOf(b)) {
      return -1;
    }

    return 0;
  });
}
