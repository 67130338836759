import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Reset password action
 *
 * @param{String} email
 * @returns {{types: *[], promise: Request }}
 */

export default function resetPassword(email) {
  return {
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE
    ],
    promise: api().post('/api/v1/users/reset-password/', {
      email
    })
  };
}
