import { validatePassword } from '../../actions';

export const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'required';
  }

  if (!values.password_again) {
    errors.password_again = 'required';
  }

  if (values.password && values.password_again) {
    if (values.password !== values.password_again) {
      errors.password_again = 'not_match';
    }
  }

  if (!validatePassword(values.password)) {
    errors.password = 'password_error';
  }

  return errors;
};
