import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, Progress, Col } from 'reactstrap';

import styles from './styles.css';
import './styles.css';
import { getCookie } from '../../helpers';
import { AUTH_TOKEN } from '../../constants/cookies';
import fileDownload from 'js-file-download';

const UploadBlock = (props) => {
  const {
    onDrop,
    output,
    uploadProcess,
    title,
    max,
    link,
    edit,
    linkDisabled,
    allowedFileTypes
  } = props;

  const disabled = max <= output.length && !edit;
  const isUploading = output[0] && uploadProcess.get(output[0].name) > 0;
  const hasAllowedTypes = allowedFileTypes && allowedFileTypes.length > 0;

  return (
    <div
      className={classNames(
        link ? styles['upload-link'] : styles['upload-block'],
        (disabled || linkDisabled || isUploading) && styles.disabled
      )}
    >
      {!link && (
        <h6>
          <FormattedMessage id="SOURCE_FILE" /> (
          <FormattedMessage id="REQUIRED" />)
        </h6>
      )}
      {output.map((file) => {
        const name = file.path ? (
          <button
            type="button"
            style={{
              width: '100%',
              overflow: 'hidden',
              'white-space': 'nowrap',
              display: 'block',
              'text-overflow': 'ellipsis',
              background: 'none',
              border: 'none',
              'text-decoration': 'underline',
              cursor: 'pointer',
              color: 'blue'
            }}
            onClick={() => {
              fetch(
                `${process.env.REACT_APP_APIURL}/api/v1/assets${file.path}`,
                {
                  method: 'get',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
                  },
                  mode: 'cors'
                }
              )
                .then((response) => response.blob())
                .then((blob) => {
                  fileDownload(blob, file.name);
                })
                .catch((reason) => {
                  // eslint-disable-next-line no-console
                  console.warn(reason);
                  alert('There was an error when downloading the file');
                });
              return true;
            }}
            target="_blank"
            id={`link_${file.name}`}
          >
            {file.name}
          </button>
        ) : (
          file.name
        );

        return (
          (!link || isUploading) && (
            <Col xs={link ? 12 : 7} className={styles['file-div']}>
              <div>
                {uploadProcess.get(output[0].name) > 0 ? (
                  <Progress
                    style={{ margin: '10px 0' }}
                    value={uploadProcess.get(output[0].name)}
                  />
                ) : (
                  name
                )}
              </div>
            </Col>
          )
        );
      })}
      {link ? (
        <Dropzone
          id="upload_file_dropzone"
          onDrop={linkDisabled ? () => undefined : onDrop}
          className={classNames(
            styles.button,
            isUploading && styles.hidden,
            linkDisabled && styles.disabled
          )}
          output={output}
          disabled={linkDisabled}
          {...(hasAllowedTypes && { accept: allowedFileTypes.join(',') })}
        >
          <Button color="link" disabled={linkDisabled}>
            <FormattedMessage id={title} />
          </Button>
        </Dropzone>
      ) : (
        <Col xs={5} className={styles['button-div']}>
          <Dropzone
            id="upload_file_dropzone"
            onDrop={disabled || isUploading ? () => undefined : onDrop}
            className={classNames(styles.button, styles.flright)}
            output={output}
            disabled={disabled || isUploading}
            {...(hasAllowedTypes && { accept: allowedFileTypes.join(',') })}
          >
            <FormattedMessage id={title} />
          </Dropzone>
        </Col>
      )}
    </div>
  );
};

UploadBlock.propTypes = {
  onDrop: PropTypes.func.isRequired,
  output: PropTypes.array,
  uploadProcess: PropTypes.object,
  title: PropTypes.string.isRequired,
  max: PropTypes.number,
  link: PropTypes.bool,
  edit: PropTypes.object,
  linkDisabled: PropTypes.bool,
  allowedFileTypes: PropTypes.array
};

export default connect((state) => ({
  uploadProcess: state.app.get('uploading')
}))(UploadBlock);
