import React, { useEffect, useState } from 'react';

import styles from './styles.css';
import './styles.css';
import ReactHighcharts from 'react-highcharts';
import api from '../../../../../../api';

require('highcharts-more')(ReactHighcharts.Highcharts);

const SkillsPracticed = (props) => {
  const [skillsPracticed, setSkillPracticed] = useState([]);
  const [noSkillsPracticed, setNoSkillsPracticed] = useState(true);
  const { user } = props;

  useEffect(() => {
    if (!user) {
      setSkillPracticed([
        {
          name: '',
          title: '',
          y: 1,
          dataLabels: {
            enabled: true
          }
        }
      ]);
    }
    const fetchData = async () => {
      const response = await api().get(
        `/api/v1/analytics/development/skills-practiced/${user.get('id')}/`
      );
      let exercises = [];
      exercises = response.body;
      let skillsPractiedToShow = [];

      if (exercises !== undefined && exercises.length > 0) {
        setNoSkillsPracticed(false);
        skillsPractiedToShow = exercises.map((exercise) => {
          return {
            name: exercise.name,
            title: exercise.name,
            y: exercise.daysPracticed,
            dataLabels: {
              enabled: true
            }
          };
        });
      } else {
        setNoSkillsPracticed(true);
        skillsPractiedToShow = [
          {
            name: '',
            title: '',
            y: 1,
            dataLabels: {
              enabled: true
            }
          }
        ];
      }

      setSkillPracticed(skillsPractiedToShow);
    };
    fetchData();
  }, [user]);

  const getTooltip = () => {
    if (!noSkillsPracticed) {
      return {
        pointFormat: '{series.name}: <b>{point.y:f} Days</b>'
      };
    }
    return {
      enabled: false
    };
  };

  const getColors = () => {
    if (noSkillsPracticed) {
      return ['#dedede'];
    }
    return ['#001c50', '#DE2557', '#F9CB36', '#2FBC88'];
  };

  const config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'middle',
      y: 60
    },
    tooltip: getTooltip(),
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: !noSkillsPracticed
          }
        }
      },
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            fontWeight: 'bold',
            color: 'white'
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '75%'],
        size: '110%'
      }
    },
    series: [
      {
        type: 'pie',
        colors: getColors(),
        name: 'Days practiced',
        innerSize: '70%',
        data: skillsPracticed
      }
    ]
  };

  return (
    <div style={{ paddingLeft: '10px' }}>
      <h6 className={styles['section-header']}>Skills Practiced</h6>
      <ReactHighcharts config={config} />
      <p style={{ fontSize: '14px' }}>
        This figure displays which skills the participant has been practicing.
        Larger sections of the semicircle represent more practiced skills, while
        smaller sections represent less practiced skills. Hover over a skill to
        see total number of days practiced.
      </p>
    </div>
  );
};

export default SkillsPracticed;
