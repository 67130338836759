import { Development } from '../views';

import { multipleOnEnterHandler } from 'routes/actions';
import {
  listWeeklyFocuses,
  getCurrentFocus,
  listDailyExercises,
  listImprovement,
  listPriorities
} from 'actions/development';

const DevelopmentRoutes = [
  {
    path: '/overview',
    component: Development.Overview
  },
  {
    path: '/my-priorities',
    component: Development.MyPriorities,
    onEnter: multipleOnEnterHandler([
      { action: listPriorities, state: 'development', object: 'priorities' }
    ])
  },
  {
    path: '/choose-focus',
    component: Development.ChooseFocus,
    onEnter: multipleOnEnterHandler([
      { action: listWeeklyFocuses, state: 'development', object: 'focuses' },
      { action: listImprovement, state: 'development', object: 'improvement' }
    ])
  },
  {
    path: '/more-info-about-this-habit',
    component: Development.MoreInfo
  },
  {
    path: '/technical-support',
    component: Development.TechnicalSupport
  },
  {
    path: '/app',
    component: Development.App,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();
      const promises = [];

      if (state.development.get('currentFocus').size === 0) {
        promises.push(
          new Promise((resolve, reject) => {
            dispatch(getCurrentFocus()).then((action) => {
              if (!action.error) {
                dispatch(
                  listDailyExercises({ focus: action.result.body.id })
                ).then((action) => {
                  if (!action.error) {
                    return resolve();
                  }

                  return reject();
                });
              } else {
                window.location = '/development/choose-focus';
              }
            });
          })
        );
      } else if (state.development.get('exercises').size === 0) {
        promises.push(
          dispatch(
            listDailyExercises({
              focus: state.development.getIn(['currentFocus', 'id'])
            })
          )
        );
      }

      if (state.development.get('improvement').size === 0) {
        promises.push(dispatch(listImprovement()));
      }

      return Promise.all(promises);
    }
  }
];

export default DevelopmentRoutes;
