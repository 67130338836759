/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change, formValueSelector } from 'redux-form';

// Styles
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

import { ScheduleManually, SimulationAndLanguage } from '../../blocks';

// Const
const form = 'assessModalForm';
const editForm = 'editUser';
const PARTNER = 'partner_id';

/**
 * Assess modal form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    partner: formValueSelector(editForm)(state, PARTNER)
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class AssessModalForm extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    timezone: PropTypes.string,
    permissions: PropTypes.array.isRequired,
    partner: PropTypes.number
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { handleSubmit, user, permissions, partner } = this.props;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <SimulationAndLanguage selectedUser={user} form={form} modal />
        {permissions.find(
          (p) => p.indexOf('user_participant_schedule') > -1
        ) && (
          <ScheduleManually
            form={form}
            timezone={user.get('timezone')}
            seed={user.get('seed')}
            partner={partner}
          />
        )}
      </form>
    );
  }
}
