export default function scrollTo(element, to, duration) {
  if (duration < 0) return;
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 2;

  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick; // eslint-disable-line
    scrollTo(element, to, duration - 2);
  }, 10);
}
