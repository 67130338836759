import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AND, OR } from 'constants/operators';
import { isEmptyOrSpaces } from 'helpers';

const OPERATORS = [AND, OR];

function escapeForRegExp(query) {
  return query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
}

function markIt(input, query) {
  const regex = RegExp(escapeForRegExp(query), 'gi');

  return {
    __html: input.replace(regex, '<mark>$&</mark>')
  };
}

function filterSuggestions(query, suggestions) {
  const regex = new RegExp(`(?:^|\\s)${escapeForRegExp(query)}`, 'i');
  return suggestions.filter((item) => regex.test(item.name));
}

export default class Suggestions extends React.Component {
  static propTypes = {
    addTag: PropTypes.func.isRequired,
    onCreateNew: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
      .isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.object),
    classNames: PropTypes.object,
    query: PropTypes.string,
    expandable: PropTypes.bool,
    listboxId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedIndex: PropTypes.number,
    maxSuggestionsLength: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      options: filterSuggestions(
        this.props.query,
        this.props.suggestions,
        this.props.maxSuggestionsLength
      )
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      options: filterSuggestions(
        newProps.query,
        newProps.suggestions,
        newProps.maxSuggestionsLength
      )
    });
  }

  handleMouseDown(item, e) {
    // focus is shifted on mouse down but calling preventDefault prevents this
    e.preventDefault();
    this.props.addTag(item);
  }

  getOptions = (items, operatorOptions = []) => {
    return items.map((item, i) => {
      const key = `${this.props.listboxId}-${i + operatorOptions.length}`;
      const classNames = [];

      if (this.props.selectedIndex === i + operatorOptions.length) {
        classNames.push(this.props.classNames.suggestionActive);
      }

      if (item.disabled) {
        classNames.push(this.props.classNames.suggestionDisabled);
      }

      return (
        <li
          id={key}
          key={key}
          role="option"
          className={classNames.join(' ')}
          aria-disabled={item.disabled === true}
          onMouseDown={this.handleMouseDown.bind(this, item)}
        >
          <span dangerouslySetInnerHTML={markIt(item.name, this.props.query)} />
        </li>
      );
    });
  };

  render() {
    if (
      !this.props.expandable ||
      (!this.state.options.length && !this.props.onCreateNew) ||
      isEmptyOrSpaces(this.props.query)
    ) {
      return null;
    }

    const operators = this.state.options.filter((option) =>
      OPERATORS.find((operator) => operator.name === option.name)
    );
    const tags = this.state.options.filter(
      (option) => !OPERATORS.find((operator) => operator.name === option.name)
    );

    const operatorOptions = this.getOptions(operators);
    const tagsOptions = this.getOptions(tags, operatorOptions);

    return (
      <div className={this.props.classNames.suggestions}>
        <ul
          role="listbox"
          id={this.props.listboxId}
          onMouseDown={(e) => e.preventDefault()}
        >
          {operatorOptions.length > 0 && (
            <div style={{ fontWeight: 'bold' }}>
              <h5>
                <FormattedMessage id="FORM_FIELDS.operators" />
              </h5>
              {operatorOptions}
            </div>
          )}
          {tagsOptions.length > 0 && (
            <div>
              <h5>
                <FormattedMessage id="FORM_FIELDS.tags" />
              </h5>
              {tagsOptions}
            </div>
          )}
          {this.props.onCreateNew && this.props.query !== '' && (
            <li
              onMouseDown={() => this.props.onCreateNew(this.props.query)}
              id="CREATE_NEW_TAG"
            >
              + <FormattedMessage id="CREATE_NEW_TAG" /> - "
              <strong>{this.props.query}</strong>"
            </li>
          )}
        </ul>
      </div>
    );
  }
}
