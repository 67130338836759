// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

// Components
import {
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Loader, Link } from 'components';
import {
  BasicInformationTranslateTable,
  Definition,
  IndicatorsTranslateTable
} from './components';
import { SubmitPublishModal } from '../../../EditSimulationTable/modals';

// Styles
import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';

// Actions
import { fetchBehavior, editBehavior, indicators } from 'actions/behaviors';

// Constants
const editBehaviorTabs = [
  { id: '1', title: 'basic_information' },
  { id: '2', title: 'definition' },
  { id: '3', title: 'indicators' }
];

/**
 * Translate
 * @class
 */
@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    route: state.router.route,
    isLoading: state.behaviors.get('behaviorsLoading'),
    behaviors: state.behaviors.get('behaviors')
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        fetchBehavior,
        editBehavior,
        indicators,
        transitionTo
      },
      dispatch
    );
  }
)
export default class Translate extends Component {
  // PropTypes
  static propTypes = {
    id: PropTypes.number,
    transitionTo: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    fetchBehavior: PropTypes.func.isRequired,
    editBehavior: PropTypes.func.isRequired,
    indicators: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    behaviors: PropTypes.object
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.subTab || '1',
      hasError: false
    };
  }

  /**
   * Component will mount
   */
  UNSAFE_componentWillMount() {
    const { id, version, fetchBehavior, indicators } = this.props;
    const promises = [
      fetchBehavior(id, version),
      indicators({
        type: 'fetch',
        behaviorId: id,
        data: { language: version }
      })
    ];

    Promise.all(promises);
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.subTab !== nextProps.route.query.subTab) {
      this.toggle(nextProps.route.query.subTab || '1');
    }
  }

  /**
   * Get tab content
   * @param tab
   * @returns {XML}
   */
  getTabContent = (tab) => {
    const { id, version, behaviors } = this.props;
    const { hasError } = this.state;

    const published = behaviors.hasIn([version, id])
      ? behaviors.getIn([version, id, 'published'])
      : false;

    switch (tab) {
      case '1':
        return (
          <BasicInformationTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '2':
        return (
          <Definition
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '3':
        return (
          <IndicatorsTranslateTable hasError={hasError} version={version} />
        );
      default:
        return (
          <BasicInformationTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
    }
  };

  /**
   * Get error tab
   * @param string
   * @returns {*}
   */
  getErrorTab = (string) => {
    this.setState({ hasError: true });

    if (string.indexOf('definition') > -1) {
      return '2';
    }
    if (string.indexOf('indicator') > -1) {
      return '3';
    }

    return '1';
  };

  /**
   * Toggle
   * @param tab
   */
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  /**
   * Submit publish
   */
  submitPublish = () => {
    const { editBehavior, id, version, behaviors } = this.props;
    const { addAlert, removeModal, intl } = this.context;
    const published = behaviors.hasIn([version, id])
      ? behaviors.getIn([version, id, 'published'])
      : false;

    editBehavior(id, { language: version, published: !published }).then(
      (action) => {
        if (action.error && action.error.body.translation.errors) {
          const string = action.error.body.translation.errors
            .map((err) => intl.formatMessage({ id: err }))
            .join(', ');
          this.setState({ activeTab: this.getErrorTab(string) });

          addAlert({
            color: 'danger',
            content: string
          });
        } else {
          addAlert({
            color: 'success',
            content: (
              <FormattedMessage
                id={`SUCCESSFULLY_${!published ? 'PUBLISHED' : 'UNPUBLISHED'}`}
                values={{ item: intl.formatMessage({ id: 'BEHAVIOR' }) }}
              />
            )
          });
        }
        removeModal();
      }
    );
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { isLoading, id, version, behaviors, route } = this.props;
    const { addModal } = this.context;
    const { activeTab } = this.state;
    const published = behaviors.hasIn([version, id])
      ? behaviors.getIn([version, id, 'published'])
      : false;

    return (
      <div className={styles['simulation-subtabs-div']}>
        <Nav tabs>
          {editBehaviorTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.title}_${version}`}
                to={`/simulations/behaviors/edit/${id}`}
                query={{
                  tab: route.query.tab || '1',
                  subTab: tab.id.toString()
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`EDIT_BEHAVIOR_TABS.${tab.title}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {published ? (
            <span className={styles['published-message']}>
              <FormattedMessage
                id="PUBLISHED_ON"
                values={{
                  date: moment(
                    behaviors.getIn([version, id, 'published_at'])
                  ).format('DD MMM YYYY hh:mm A')
                }}
              />
            </span>
          ) : (
            <div className={styles['custom-switcher']}>
              <FormattedMessage id="PUBLISH" />
              <Label
                id={`input_label_publish_${version}`}
                className={styles['custom-switch']}
              >
                <input
                  id={`input_publish_${version}`}
                  name={`input_publish_${version}`}
                  type="checkbox"
                  onChange={() =>
                    addModal(
                      <SubmitPublishModal
                        action={this.submitPublish}
                        publish={published}
                        type="BEHAVIOR"
                      />
                    )
                  }
                  checked={false}
                />
                <div className={styles['switch-slider']} />
              </Label>
            </div>
          )}
        </Nav>

        <TabContent
          activeTab={activeTab}
          className={styles['simulation-subtabs']}
        >
          {editBehaviorTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {isLoading ? <Loader /> : this.getTabContent(activeTab)}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
