import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch assignments action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchAssignments(
  offset,
  limit,
  from,
  to,
  query,
  assignment_id,
  backgroundFetch = false
) {
  const string = `?from=${from || ''}&to=${to || ''}&sort=&offset=${
    offset || 0
  }&limit=all&q=${
    query ? encodeURIComponent(query) : ''
  }&partner_id=&client_id=&simulation_id=&assignment_id=${assignment_id || ''}`; // eslint-disable-line
  return {
    types: [
      FETCH_ASSIGNMENTS_REQUEST,
      FETCH_ASSIGNMENTS_SUCCESS,
      FETCH_ASSIGNMENTS_FAILURE
    ],
    promise: api().get(`/api/v1/users/assignments/${string}`),
    backgroundFetch
  };
}
