import {
  GET_USER_VIEW_REQUEST,
  GET_USER_VIEW_SUCCESS,
  GET_USER_VIEW_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get user view action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function getUserPermissions() {
  return {
    types: [
      GET_USER_VIEW_REQUEST,
      GET_USER_VIEW_SUCCESS,
      GET_USER_VIEW_FAILURE
    ],
    promise: api().get('/api/v1/permissions/')
  };
}
