import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components';

import { Email as EmailForm } from 'forms';

import { emails, fetchSimulation } from 'actions/simulations';

import styles from '../../../../subviews/styles.css';

@connect(
  (state) => ({
    id: Number(state.router.route.vars.emailId),
    simulationId: Number(state.router.route.vars.id),
    readOnly: state.router.route.query.readOnly,
    isNew: Boolean(state.router.route.query.isNew)
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { emails, transitionTo, fetchSimulation },
      dispatch
    );
  }
)
export default class Email extends Component {
  static propTypes = {
    id: PropTypes.number,
    simulationId: PropTypes.number.isRequired,
    emails: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.func.isRequired
  };

  handleSubmit = (data) => {
    const {
      emails,
      simulationId,
      transitionTo,
      id,
      fetchSimulation
    } = this.props;
    const { addAlert, intl } = this.context;

    emails({ type: id ? 'edit' : 'create', data, simulationId, id }).then(
      (action) => {
        if (!action.error) {
          addAlert({
            color: 'success',
            content: (
              <FormattedMessage
                id={`EMAIL_SUCCESSFULLY_${id ? 'EDITED' : 'CREATED'}`}
              />
            )
          });
          transitionTo(`/simulations/edit/${simulationId}`, { subTab: '6' });
        } else if (action.error && action.error.body) {
          if (
            typeof action.error.body === 'string' ||
            typeof action.error.body.error === 'string'
          ) {
            transitionTo(`/simulations/edit/${simulationId}`, {
              subTab: '6'
            }).then(() => {
              addAlert({
                color: 'danger',
                content: intl.formatMessage({
                  id:
                    typeof action.error.body === 'string'
                      ? action.error.body
                      : action.error.body.error
                })
              });

              fetchSimulation(simulationId);
            });
          }
        }
      }
    );
  };

  render() {
    const { id, simulationId, readOnly } = this.props;
    const heading = id
      ? readOnly
        ? 'VIEW_EMAIL'
        : 'EDIT_EMAIL'
      : 'CREATE_EMAIL';

    return (
      <div>
        <div className={styles['link-back']}>
          <Link
            to={`/simulations/edit/${simulationId}`}
            query={{ subTab: '6' }}
          >
            &larr; <FormattedMessage id="BACK" />
          </Link>
        </div>
        <h1>
          <FormattedMessage id={heading} />
        </h1>
        <EmailForm onSubmit={this.handleSubmit} isNew={this.props.isNew} />
      </div>
    );
  }
}
