export default function getSortString(key) {
  switch (key) {
    case '+firstname':
      return '+firstname,+surname,+email';
    case '-firstname':
      return '-firstname,-surname,-email';
    case '+partner':
      return '+partner,+firstname,+surname';
    case '-partner':
      return '-partner,-firstname,-surname';
    case '+client':
      return '+client,+firstname,+surname';
    case '-client':
      return '-client,-firstname,-surname';
    case '+service':
      return '+assessment';
    case '-service':
      return '-assessment';
    case '+scheduled':
      return '+scheduled,+firstname,+surname,+email';
    case '-scheduled':
      return '-scheduled,-firstname,-surname,-email';
    default:
      return key;
  }
}
