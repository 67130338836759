// Vendor
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

/**
 * Get stage progress
 * @param stages
 * @param simulation
 * @param user
 * @returns {{progress: *, info: *}}
 */
export default function getStageProgress(
  stages,
  simulation,
  user,
  isPsychometricsOnly = false,
  isDebrief = false
) {
  let progress;
  let subProgress;
  let info;
  let color = 'CCCCCC';
  let stage = null;
  const nthSimulation =
    user &&
    user.has('past_simulations') &&
    user.get('past_simulations').size > 0;

  if (stages) {
    if (
      stages.find(
        (s) => s.get('name') === 'scheduled1' && s.get('completed')
      ) &&
      user.has('scheduled_at') &&
      user.hasIn(['scheduled_at', 'scheduled_by']) &&
      !nthSimulation
    ) {
      stage = stages
        .filter(
          (item) =>
            item.get('name').indexOf('scheduled') < 0 &&
            item.get('completed') &&
            item.get('name').indexOf('development') < 0
        )
        .last();
    } else {
      stage = stages
        .filter(
          (s) =>
            s.get('name').indexOf('invited') > -1 ||
            s.get('name').indexOf('scheduled') > -1 ||
            (nthSimulation
              ? s.get('name').indexOf('pre-work') < 0 &&
                s.get('name').indexOf('development') < 0
              : s.get('name').indexOf('pre-work') > -1) ||
            s.get('name').indexOf('practice') > -1 ||
            s.get('name').indexOf('simulation') > -1 ||
            s.get('name').indexOf('scoring') > -1 ||
            s.get('name').indexOf('report') > -1
        )
        .filter((item) => item.get('completed'));
      if (isDebrief) {
        stage = stage.filter(
          (item) =>
            item.get('name') !== 'invited4' &&
            item.get('name') !== 'invited5' &&
            item.get('name') !== 'invited6'
        );
      }

      stage = stage.last() || stages.first();
    }
  }
  switch (stage ? stage.get('category') : stage) {
    case 'invited': {
      let name = null;
      progress = 1;

      if (nthSimulation === true) {
        subProgress = 1;
        color = 'CCCCCC';

        name = 'invited1';
        if (
          isDebrief &&
          stage.get('completed') &&
          stage.get('name') === 'invited3'
        ) {
          name = 'invited3';
          progress = 2;
          subProgress = 3;
          color = 'F7941E';
        }
        info = <FormattedMessage id={`STAGES.${name || stage.get('name')}`} />;
        break;
      }

      switch (stage.get('name')) {
        case 'invited1': {
          subProgress = 1;
          color = 'CCCCCC';
          break;
        }
        case 'invited2a_tc':
        case 'invited2b_pp': {
          subProgress = 2;
          color = 'F7941E';
          break;
        }
        case 'invited3': {
          subProgress = 3;
          color = 'F7941E';
          break;
        }
        case 'invited4': {
          subProgress = 4;
          color = 'F7941E';
          break;
        }
        case 'invited5': {
          subProgress = 5;
          color = '95BC0D';
          break;
        }
        case 'invited6': {
          subProgress = 6;
          color = '95BC0D';
          break;
        }
        default: {
          color = 'default';
          break;
        }
      }
      if (
        stage.get('name') === 'invited1' &&
        user.has('scheduled_at') &&
        user.hasIn(['scheduled_at', 'scheduled_by'])
      ) {
        info = (
          <FormattedMessage
            id={`STAGES.${stage.get('name')}.with_schedule`}
            values={{
              simulation: user.has('active_simulation')
                ? `${
                    user.hasIn(['active_simulation', 'assessment_name'])
                      ? user.getIn(['active_simulation', 'assessment_name'])
                      : user.getIn(['active_simulation', 'simulation_name'])
                  }, ${user
                    .getIn(['active_simulation', 'language'])
                    .charAt(0)
                    .toUpperCase()}${user
                    .getIn(['active_simulation', 'language'])
                    .slice(1)}`
                : '',
              name: user.getIn(['scheduled_at', 'scheduled_by', 'name']),
              date: moment(user.getIn(['scheduled_at', 'start_time'])).format(
                'DD. MMM YYYY hh:mm A'
              )
            }}
          />
        );
      } else {
        info = <FormattedMessage id={`STAGES.${name || stage.get('name')}`} />;
      }
      break;
    }
    case 'scheduled': {
      progress = 2;
      subProgress = 7;
      color = stage.get('name') === 'scheduled1' ? 'F7941E' : '95BC0D';

      if (
        stage.get('name') === 'scheduled1' &&
        user.has('scheduled_at') &&
        user.hasIn(['scheduled_at', 'scheduled_by'])
      ) {
        info = (
          <FormattedMessage
            id={`STAGES.${stage.get('name')}.manually`}
            values={{
              name: user.getIn(['scheduled_at', 'scheduled_by', 'name'])
            }}
          />
        );
      } else {
        if (isDebrief && stage.get('name') === 'debrief_scheduled1') {
          progress = 3;
          color = 'F7941E';
        }

        info = <FormattedMessage id={`STAGES.${stage.get('name')}`} />;
      }
      break;
    }
    case 'pre-work': {
      progress = isPsychometricsOnly ? 2 : 3;
      subProgress = 8;
      color = stage.get('name') === 'pre-work1' ? 'F7941E' : '95BC0D';
      info = <FormattedMessage id={`STAGES.${stage.get('name')}`} />;
      break;
    }
    case 'practice': {
      progress = isPsychometricsOnly ? 2 : 3;
      subProgress = 9;
      color = '95BC0D';
      info = <FormattedMessage id={`STAGES.${stage.get('name')}`} />;
      break;
    }
    case 'simulation': {
      if (isPsychometricsOnly) {
        progress = 3;
        info = (
          <FormattedMessage id={`STAGES.PSYCHOMETRICS.${stage.get('name')}`} />
        );
        color = '95BC0D';
        break;
      }
      progress = 5;
      if (isDebrief) {
        progress = 4;
      }
      switch (stage.get('name')) {
        case 'simulation1':
          subProgress = 10;
          color = 'F7941E';
          break;
        case 'simulation2':
          subProgress = 11;
          color = 'F7941E';
          break;
        case 'simulation3':
          subProgress = 12;
          color = 'F7941E';
          break;
        case 'simulation4':
          subProgress = 13;
          color = 'F7941E';
          break;
        case 'simulation5':
          subProgress = 14;
          color = '95BC0D';
          break;
        default:
          color = 'default';
          break;
      }

      if (stage.get('name') === 'simulation4') {
        info = (
          <FormattedMessage id={`STAGES.${stage.get('name')}.without_data`} />
        );
      } else {
        info = <FormattedMessage id={`STAGES.${stage.get('name')}`} />;
      }
      if (
        (stage.get('name') === 'simulation3' ||
          stage.get('name') === 'simulation1') &&
        isDebrief
      ) {
        color = 'F7941E';
        info = <FormattedMessage id={`STAGES.${stage.get('name')}_debrief`} />;
      }
      if (stage.get('name') === 'simulation4' && isDebrief) {
        info = (
          <FormattedMessage
            id={`STAGES.${stage.get('name')}_debrief_no_data`}
          />
        );
      }
      if (stage.get('name') === 'simulation5' && isDebrief) {
        progress = 5;
        info = <FormattedMessage id={`STAGES.${stage.get('name')}_debrief`} />;
      }

      break;
    }
    case 'scoring': {
      progress = 6;
      subProgress = 15;
      color = '95BC0D';
      info = (
        <FormattedMessage
          id={`STAGES.${stage.get('name')}`}
          values={{ simulation }}
        />
      );
      break;
    }
    case 'report': {
      progress = isPsychometricsOnly ? 4 : 7;
      subProgress = 16;
      color = '95BC0D';
      info = (
        <FormattedMessage
          id={`STAGES.${stage.get('name')}`}
          values={{ simulation }}
        />
      );
      break;
    }
    default: {
      progress = 0;
      subProgress = 0;
      info = null;
      break;
    }
  }
  return { progress, info, color, subProgress };
}
