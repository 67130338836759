// Vendor
import React from 'react';

// Forms
import { InviteForm } from 'forms';

// Actions
import { inviteUsers } from 'actions/users';

/**
 * Get invite user data
 * @returns {*}
 */
export default function getInviteUserData(role, onSubmit) {
  switch (role) {
    case 'admin': {
      return {
        component: <InviteForm onSubmit={onSubmit} />,
        heading: 'INVITE_NEW_ADMINS',
        success_message: 'ADMINS_INVITED',
        action: inviteUsers
      };
    }
    case 'participant': {
      return {
        component: <InviteForm onSubmit={onSubmit} />,
        heading: 'INVITE_NEW_PARTICIPANTS',
        success_message: 'PARTICIPANTS_INVITED',
        action: inviteUsers
      };
    }
    case 'stakeholder': {
      return {
        component: <InviteForm onSubmit={onSubmit} />,
        heading: 'INVITE_NEW_STAKEHOLDERS',
        success_message: 'STAKEHOLDERS_INVITED',
        action: inviteUsers
      };
    }
    default:
      return {};
  }
}
