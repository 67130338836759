import { Map, OrderedMap, fromJS } from 'immutable';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_ME_SUCCESS,
  EDIT_USER_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  LIST_QUESTIONS_REQUEST,
  LIST_QUESTIONS_SUCCESS,
  LIST_QUESTIONS_FAILURE,
  UPDATE_ANSWER_REQUEST,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAILURE,
  FETCH_LAT_REQUEST,
  FETCH_LAT_SUCCESS,
  FETCH_LAT_FAILURE,
  SAVE_LAT_ANSWER_REQUEST,
  SAVE_LAT_ANSWER_SUCCESS,
  SAVE_LAT_ANSWER_FAILURE,
  GET_TEST_SUCCESS,
  LIST_CODELISTS_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from 'constants/actionTypes';

const initialState = Map({
  loggedUser: Map(),
  questions: Map(),
  test: Map(),
  LAT: Map(),
  latStartTime: null,
  latRemainingTime: null
});

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return state.set('loginLoading', true).set('error', null);
    case LOGIN_SUCCESS:
    case GET_ME_SUCCESS: {
      const user = action.result.body;
      const updatedUser = user;
      sessionStorage.setItem('activeTab', '1');

      updatedUser.isPartner =
        user.permissions_debug.roles.indexOf('partner-owner') > -1 ||
        user.permissions_debug.roles.indexOf('partner-admin') > -1 ||
        user.entity === 'partner';
      updatedUser.isClient =
        user.permissions_debug.roles.indexOf('client-owner') > -1 ||
        user.permissions_debug.roles.indexOf('client-admin') > -1 ||
        user.entity === 'client';

      return state
        .set('loginLoading', false)
        .set('error', null)
        .set('loggedUser', fromJS(updatedUser));
    }
    case EDIT_USER_SUCCESS: {
      const id = Number(action.id);
      const user = action.result.body;
      const updatedUser = user;

      updatedUser.isPartner =
        user.permissions_debug.roles.indexOf('partner-owner') > -1 ||
        user.permissions_debug.roles.indexOf('partner-admin') > -1 ||
        user.entity === 'partner';
      updatedUser.isClient =
        user.permissions_debug.roles.indexOf('client-owner') > -1 ||
        user.permissions_debug.roles.indexOf('client-admin') > -1 ||
        user.entity === 'client';

      if (state.hasIn(['loggedUser', 'brand_header'])) {
        updatedUser.brand_header = state.getIn(['loggedUser', 'brand_header']);
      }

      if (id === state.get('loggedUser').get('id')) {
        return state.set('loggedUser', fromJS(updatedUser));
      }

      return state;
    }
    case LOGIN_FAILURE: {
      if (
        action?.error?.body?.message === 'mfa_required' &&
        action.error.body.message
      ) {
        return state.set('loginLoading', false).set('mfaRequired', true);
      }
      if (
        action?.error?.body?.message === 'mfa_provided_invalid' &&
        action.error.body.message
      ) {
        return state.set('loginLoading', false).set('mfaProvidedInvalid', true);
      }
      return state.set('loginLoading', false).set('error', action.error);
    }
    case RESET_PASSWORD_REQUEST:
      return state.set('resetPasswordLoading', true);
    case RESET_PASSWORD_SUCCESS:
      return state.set('resetPasswordLoading', false);
    case RESET_PASSWORD_FAILURE:
      return state
        .set('resetPasswordLoading', false)
        .set('resetPasswordError', action.error);

    case SET_PASSWORD_REQUEST:
      return state.set('setNewPasswordLoading', true);
    case SET_PASSWORD_SUCCESS:
      return state.set('setNewPasswordLoading', false);
    case SET_PASSWORD_FAILURE:
      return state
        .set('setNewPasswordLoading', false)
        .set('setPasswordError', action.error.body);

    case LIST_QUESTIONS_REQUEST:
      return state.set('questionsLoading', true);
    case LIST_QUESTIONS_SUCCESS: {
      const questions = action.result.body;
      return state
        .set('questionsLoading', false)
        .set('questions', Map(questions.map((q) => [q.id, fromJS(q)])));
    }
    case LIST_QUESTIONS_FAILURE:
      return state.set('questionsLoading', false);
    case UPDATE_ANSWER_REQUEST:
      return state.set('answerUpdateLoading', true);
    case UPDATE_ANSWER_SUCCESS: {
      return state.set('answerUpdateLoading', false);
    }
    case UPDATE_ANSWER_FAILURE:
      return state.set('answerUpdateLoading', false);
    case FETCH_LAT_REQUEST:
      return state.set('fetchLATStatus', 'pending');
    case FETCH_LAT_SUCCESS: {
      const test = action.result.body;

      return state
        .set('fetchLATStatus', 'done')
        .set('latStartTime', test.created_at)
        .set('latRemainingTime', test.remainingTime)
        .set('LAT', OrderedMap(test.questions.map((q) => [q.ref, fromJS(q)])))
        .setIn(['loggedUser', 'lat_start_time'], test.created_at);
    }
    case FETCH_LAT_FAILURE:
      return state.set('fetchLATStatus', 'error');
    case SAVE_LAT_ANSWER_REQUEST:
      return state.set('saveLATAnswerStatus', 'pending');
    case SAVE_LAT_ANSWER_SUCCESS: {
      const { question, answer } = action;

      return state.withMutations((prevState) => {
        prevState.set('saveLATAnswerStatus', 'done').setIn(
          ['LAT', question, 'user_answer'],
          fromJS({
            ref: answer
          })
        );
      });
    }
    case SAVE_LAT_ANSWER_FAILURE:
      return state.set('saveLATAnswerStatus', 'error');
    case GET_TEST_SUCCESS:
      return state.set('test', fromJS(action.result.body));
    case LIST_CODELISTS_SUCCESS: {
      const codeLists = action.result.body.data;
      const RTLLanguages = codeLists.find(
        (codeList) => codeList.name === 'language_rtl'
      );
      const userLanguage = state.getIn(['loggedUser', 'language']);

      return state.setIn(
        ['loggedUser', 'rtl_direction'],
        RTLLanguages.values.indexOf(userLanguage) > -1
      );
    }

    case LOGOUT_REQUEST:
      return state.set('logoutLoading', true);
    case LOGOUT_SUCCESS: {
      return state
        .set('logoutLoading', false)
        .set('loggedUser', Map())
        .set('error', null);
    }
    case LOGOUT_FAILURE:
      return state.set('logoutLoading', false).set('error', action.error);
    case '@@ROUTEX/ROUTE_CHANGE_SUCCESS':
      return state.set('error', null);
    default:
      return state;
  }
}
