import React, { useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import { Alert, ButtonGroup, Button } from 'reactstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { BuildTable, ColorIndicator } from 'components';
import { getProgressValues } from 'helpers';
import PropTypes from 'prop-types';
import styles from '../styles.css';

require('highcharts-more')(ReactHighcharts.Highcharts);

const getSkillsAndMotivation = (props, context) => {
  const [view, setView] = useState(1);

  const getBehaviors = (view) => {
    const { data, route } = props;
    const current = Number(route.query.current);

    return data
      .get(current)
      .getIn(['simulation', 'default', 'behaviors'])
      .sort((a, b) => {
        const value_a =
          view === 2 && a.has('improvement')
            ? a.get('tbis_perc') + a.get('improvement')
            : a.get('tbis_perc');
        const value_b =
          view === 2 && b.has('improvement')
            ? b.get('tbis_perc') + b.get('improvement')
            : b.get('tbis_perc');

        if (value_a > value_b) {
          return -1;
        }
        if (value_a < value_b) {
          return 1;
        }

        return 0;
      })
      .toArray();
  };

  const getBubbleSize = (dli) => {
    const MIN = 30;
    const MAX = 94;

    if (dli === 0) {
      return MIN;
    }

    const size = MIN + 8 * Math.ceil(dli / 52);

    if (dli > 0 && size <= MAX) {
      return size;
    }

    return MAX;
  };

  const getTable = (view) => {
    const behaviors = getBehaviors(view);

    const fields = [
      {
        key: 'rank',
        sortable: false,
        centered: true,
        verticalCentered: true,
        renderField: (item, index) => <strong>{index}</strong>
      },
      {
        key: 'skill',
        sortable: false,
        verticalCentered: true,
        renderField: (item) => <strong>{item.get('name')}</strong>,
        width: 20
      },
      {
        key: 'definition',
        sortable: false,
        verticalCentered: true,
        renderField: (item) => item.get('definition')
      },
      {
        key: 'result',
        sortable: false,
        centered: true,
        verticalCentered: true,
        ownData: true,
        renderField: (item, index) => {
          const xValue = getProgressValues(
            view === 2 && item.has('improvement')
              ? item.get('tbis_perc') + item.get('improvement')
              : item.get('tbis_perc')
          );
          const yValue = getProgressValues(item.get('fb') * 100, 66.8, 33.5);

          return (
            <td
              key={index}
              style={{
                textAlign: 'justify',
                verticalAlign: 'middle',
                width: '35%'
              }}
            >
              <strong>
                <span
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  <ColorIndicator type="circle" color={xValue.colorString} />
                  <FormattedHTMLMessage
                    id={`DEVELOPMENT_STATUS.${xValue.position}`}
                  />
                </span>
              </strong>

              <p style={{ margin: '5px 0' }}>
                <FormattedHTMLMessage
                  id={`SM_NARRATIVE_${xValue.position}_${yValue.position}`}
                  values={{ behavior: item.get('name') }}
                />
              </p>
            </td>
          );
        }
      }
    ];

    return {
      data: behaviors,
      fields
    };
  };

  const getConfig = (_data, view) => {
    const behaviors = getBehaviors(view);
    const data = [];
    const xLabels = {
      0: context.intl.formatMessage({ id: 'BELOW_AVERAGE' }),
      100: context.intl.formatMessage({ id: 'ABOVE_AVERAGE' })
    };

    const yLabels = {
      0: context.intl.formatMessage({ id: 'LOW' }),
      100: context.intl.formatMessage({ id: 'HIGH' })
    };

    behaviors.forEach((behavior, index) => {
      const values = getProgressValues(
        view === 2 && behavior.has('improvement')
          ? behavior.get('tbis_perc') + behavior.get('improvement')
          : behavior.get('tbis_perc')
      );

      data.push({
        x:
          view === 1
            ? Number(parseFloat(behavior.get('tbis_perc')).toFixed(2))
            : Number(parseFloat(behavior.get('tbis_perc')).toFixed(2)) +
              (behavior.get('improvement') || 0),
        y: behavior.get('fb') * 100,
        z:
          view === 1
            ? getBubbleSize(behavior.get('dli'))
            : getBubbleSize(behavior.get('dli_imp')),
        name: behavior.get('name'),
        number: index + 1,
        color: values.color
      });
    });

    return {
      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'none',
        height: 570,
        spacing: 47,
        marginRight: 80
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        gridLineWidth: 0,
        tickInterval: 100,
        title: {
          text: context.intl.formatMessage({ id: 'SM_SKILLS' }),
          style: {
            'font-size': '20px'
          },
          y: 15
        },
        labels: {
          formatter() {
            // eslint-disable-next-line
            return xLabels[this.value];
          }
        },
        min: 0,
        max: 100
      },
      yAxis: {
        gridLineWidth: 0,
        startOnTick: false,
        endOnTick: false,
        tickInterval: 50,
        title: {
          text: context.intl.formatMessage({ id: 'SM_MOTIVATION' }),
          style: {
            'font-size': '20px'
          },
          x: -10
        },
        labels: {
          step: 2,
          formatter() {
            // eslint-disable-next-line
            return yLabels[this.value];
          }
        },
        maxPadding: 0.2,
        min: 0,
        max: 100
      },
      tooltip: {
        headerFormat: '<table>',
        useHTML: true,
        pointFormat: '<b>{point.number}: {point.name}</b>',
        followPointer: true
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.number}',
            style: {
              fontSize: 13,
              textOutline: 0,
              color: 'black'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          data,
          sizeBy: 'width',
          maxSize: 94,
          minSize: 30,
          zMax: 94,
          zMin: 30,
          clip: false
        }
      ]
    };
  };

  const { data, route } = props;
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();

  return (
    <div>
      {current ? (
        <div>
          <Alert className={styles['analytics-infobox']} color="info" isOpen>
            <FormattedHTMLMessage id="SM_INFO" />
          </Alert>

          <div className="text-center html2pdf__page-break">
            <h4 className={styles['analytics-section']}>
              <FormattedMessage id="ASSESSMENT" />:{' '}
              {report.get('assessment_name')}
            </h4>
          </div>

          {report.get('is_improvement_visible') === true && (
            <div className="text-center">
              <ButtonGroup>
                <Button
                  active={view === 1}
                  outline
                  color="primary"
                  size="lg"
                  onClick={() => setView(1)}
                >
                  <FormattedMessage id="TABLE_HEADING.simulation" />
                </Button>{' '}
                <Button
                  active={view === 2}
                  outline
                  color="primary"
                  size="lg"
                  onClick={() => setView(2)}
                >
                  <FormattedMessage id="TABLE_HEADING.today-after-improvement" />
                </Button>
              </ButtonGroup>
            </div>
          )}

          <div style={{ margin: '40px 0' }} dir="ltr">
            <ReactHighcharts config={getConfig(data, view)} />
          </div>
          <div className="html2pdf__page-break">
            <BuildTable table={getTable(view)} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

getSkillsAndMotivation.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route
}))(
  React.memo(
    getSkillsAndMotivation,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
