// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change, getFormValues } from 'redux-form';

// Styles
import { Label, InputGroup } from 'reactstrap';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components

// Const
const form = 'changeRoleForm';

/**
 * Change role form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    user: state.users.getIn(['allUsers', Number(state.router.route.vars.id)]),
    formValues: getFormValues(form)(state),
    roles: state.app.getIn(['codeList', 'role'])
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class ChangeRoleForm extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    roles: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { change, user } = this.props;
    const userRoles = user.get('roles').toArray();
    change(form, 'roles', userRoles);
  }

  /**
   * Get values
   * @returns {*[]}
   */
  getValues = () => [
    { id: 4, value: 'admin', name: 'admin' },
    { id: 3, value: 'assessor', name: 'assessor' },
    { id: 2, value: 'stakeholder', name: 'stakeholder' },
    { id: 1, value: 'participant', name: 'participant' }
  ];

  /**
   * Check error
   * @param role
   * @param isChecked
   */
  checkError = (role, isChecked) => {
    const { user } = this.props;
    const changeRoles = user.get('change_roles');

    return isChecked
      ? changeRoles.getIn([role.name, 'delete'])
      : changeRoles.getIn([role.name, 'add']);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { user, formValues, handleSubmit, change } = this.props;
    const values = this.getValues();

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <h6>
          <FormattedMessage id="SELECT_ROLE" />
        </h6>
        <InputGroup>
          {values
            .filter(
              (role) =>
                !(
                  user
                    .getIn(['change_roles', role.name, 'add'])
                    .contains('ERRORS.ACCESS_DENIED') &&
                  user
                    .getIn(['change_roles', role.name, 'delete'])
                    .contains('ERRORS.ACCESS_DENIED')
                )
            )
            .sort((a, b) => a.id < b.id)
            .map((role, key) => {
              const isChecked =
                formValues && formValues.roles.indexOf(role.value) > -1;
              const hasError = this.checkError(role, isChecked);

              return (
                <div
                  key={key}
                  style={hasError.size > 0 ? { opacity: 0.7 } : {}}
                >
                  <Label htmlFor={role.name}>
                    <input
                      type="checkbox"
                      name={role.name}
                      id={role.name}
                      checked={isChecked}
                      disabled={hasError.size > 0}
                      onChange={() => {
                        const currentRoles = formValues.roles || [];
                        const currentIndex = currentRoles.indexOf(role.value);
                        const updated = currentRoles;

                        if (currentRoles.indexOf(role.value) > -1) {
                          updated.splice(currentIndex, 1);
                        } else {
                          updated.push(role.value);
                        }

                        return change(form, 'roles', updated);
                      }}
                    />
                    <FormattedMessage id={`ROLE_VALUES.${role.name}`} />
                    {hasError.size > 0 && (
                      <span className={styles['role-error']}>
                        {hasError.map((e) => (
                          <FormattedMessage id={e} />
                        ))}
                      </span>
                    )}
                    {user.get('is_owner') && role.name === 'admin' && (
                      <span>
                        {' '}
                        (
                        <FormattedMessage id="ROLE_VALUES.owner" />)
                      </span>
                    )}
                  </Label>
                </div>
              );
            })}
        </InputGroup>

        <div>
          {formValues && formValues.roles.length === 0 && (
            <span className={styles['min-roles-error']}>
              <FormattedMessage id="ERRORS.MIN_ONE_ROLE_REQUIRED" />
            </span>
          )}
        </div>
      </form>
    );
  }
}
