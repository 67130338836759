import {
  GET_SELECTED_STAKEHOLDERS_REQUEST,
  GET_SELECTED_STAKEHOLDERS_SUCCESS,
  GET_SELECTED_STAKEHOLDERS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function getSelectedStakeholdersUsingSelectedValue(selected) {
  return {
    types: [
      GET_SELECTED_STAKEHOLDERS_REQUEST,
      GET_SELECTED_STAKEHOLDERS_SUCCESS,
      GET_SELECTED_STAKEHOLDERS_FAILURE
    ],
    promise: api().post(`/api/v1/mass/report/selected/`, { selected })
  };
}
