import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';

const SubmitPublishModal = (props, context) => {
  const { action, publish, lockedBy } = props;
  const { removeModal } = context;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id={!publish ? 'PUBLISH' : 'UNPUBLISH'} />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <FormattedMessage
          id={!publish ? 'SUBMIT_PUBLISH_INFO' : 'SUBMIT_UNPUBLISH_INFO'}
        />{' '}
        {!publish && lockedBy && (
          <FormattedMessage
            id="SUBMIT_PUBLISH_LOCKED_INFO"
            values={{ admin: lockedBy }}
          />
        )}
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={5} className={modals['modal-button-col']}>
            <Button
              id="cancel_publish"
              name="cancel_publish"
              color="secondary"
              onClick={removeModal}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CANCEL" />
            </Button>{' '}
          </Col>
          <Col xs={7} className={modals['modal-button-col']}>
            <Button
              id="submit_publish"
              name="submit_publish"
              color={!publish ? 'primary' : 'danger'}
              onClick={action}
              className={modals['footer-button']}
            >
              <FormattedMessage id={!publish ? 'PUBLISH' : 'UNPUBLISH'} />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

SubmitPublishModal.propTypes = {
  action: PropTypes.func.isRequired,
  publish: PropTypes.bool,
  lockedBy: PropTypes.string,
  type: PropTypes.string
};

SubmitPublishModal.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

export default SubmitPublishModal;
