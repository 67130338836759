import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Row, Col } from 'reactstrap';

import SearchSelection from '../../components/SearchSelection';
import ShareUserBox from '../../Analytics/ShareParticipantsForm/ShareUserBox';

import styles from '../../styles.css';

export const renderStakeholders = ({ fields, helpers }) => (
  <div>
    {fields.map((stakeholder, index) => {
      const all = [];

      const user = helpers.allStakeholders.find(
        (s) => s?.get('id') === helpers.stakeholders[index]?.id
      );

      fields.getAll().map((f) => all.push(f));
      all.pop();
      const selected = fields
        .getAll()
        .filter((u) => all.find((f) => f.id === u.id));

      return (
        helpers.stakeholders[index] && (
          <Row key={index} className={styles['share-row']}>
            <Col xs={helpers.usage === 'massAction' ? 9 : 7}>
              {index + 1 === fields.length && helpers.type !== 'remove' ? (
                <div>
                  <SearchSelection
                    recipient
                    name={`${stakeholder}.id`}
                    form={helpers.form}
                    data={helpers.allStakeholders.filter(
                      (s) => !selected.find((u) => u.id === s.get('id'))
                    )}
                    showValue="email"
                    selectValue="id"
                    placeholder="PLEASE_SELECT_STAKEHOLDERS"
                    translatePlaceholder
                    disabled={helpers.disabled || !helpers.enabledBlock}
                  />
                </div>
              ) : (
                <ShareUserBox user={user} />
              )}
            </Col>
            <Col xs={helpers.usage === 'massAction' ? 3 : 2}>
              {index + 1 === fields.length && helpers.type !== 'remove' ? (
                <Button
                  id="share_report_add"
                  name="share_report_add"
                  key="add-button"
                  outline
                  color="primary"
                  type="button"
                  onClick={() => fields.push({})}
                  disabled={!helpers.stakeholders[index].id}
                >
                  + <FormattedMessage id="ADD" />
                </Button>
              ) : (
                <Button
                  id={`share_report_remove_${index}`}
                  name={`share_report_remove_${index}`}
                  key="remove-stakeholders-button"
                  outline={helpers.removeButton === 'button'}
                  type="button"
                  color={
                    helpers.removeButton === 'button' ? 'danger' : 'default'
                  }
                  title="Remove stakeholder"
                  onClick={() => fields.remove(index)}
                  className={styles[helpers.removeButton]}
                  disabled={helpers.disabled}
                >
                  {helpers.removeButton === 'x' ? (
                    ''
                  ) : (
                    <FormattedMessage id="REMOVE" />
                  )}
                </Button>
              )}
            </Col>
          </Row>
        )
      );
    })}
  </div>
);
