import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import debounce from 'js-debounce';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { downloadPdf } from 'actions/analytics';

import { BuildTable, Link } from 'components';
import { Alert, Button, Form, Input, Nav, NavItem, NavLink } from 'reactstrap';

import { fetchTimeslots } from 'actions/users';
import {
  fetchAssignments,
  startVideoCall,
  startTwilioCall,
  fetchIsInCall,
  tokboxConnect,
  twilioConnect,
  twilioStartPhoneCall,
  twilioRegisterPhoneCall
} from 'actions/assignments';

import { getAssignmentsTable } from './actions';

import styles from '../../../../components/BuildTable/styles.css';
import '../../../../components/BuildTable/styles.css';
import assignmentsStyles from '../../styles.css';
import '../../styles.css';

const tabs = [
  { id: '1', key: 'todo', name: 'To do' },
  { id: '2', key: 'completed', name: 'Completed' }
];

@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    route: state.router.route,
    timeslots:
      state.users.getIn([
        'allUsers',
        Number(state.auth.getIn(['loggedUser', 'id'])),
        'timeslots',
        'preferred'
      ]) || Map(),
    assignments: state.assignments.has('assignments')
      ? state.assignments.get('assignments').toArray()
      : [],
    assignmentsLoading: state.assignments.get('assignmentsLoading'),
    filteredAssignments: state.assignments.has('filteredAssignments')
      ? state.assignments.get('filteredAssignments').toArray()
      : [],
    assignmentsTotal: state.assignments.get('assignmentsTotal'),
    assignmentsTotalCompleted: state.assignments.get(
      'assignmentsTotalCompleted'
    ),
    assignmentsTotalUpcoming: state.assignments.get('assignmentsTotalUpcoming'),
    assignmentsTotalToday: state.assignments.get('assignmentsTotalToday'),
    fetchTimeslotsLoading: state.users.get('fetchTimeslotsLoading')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      {
        fetchAssignments,
        startVideoCall,
        startTwilioCall,
        fetchIsInCall,
        tokboxConnect,
        twilioConnect,
        downloadPdf,
        twilioStartPhoneCall,
        twilioRegisterPhoneCall,
        fetchTimeslots,
        transitionTo
      },
      dispatch
    );
  }
)
export default class Preview extends Component {
  static propTypes = {
    assignments: PropTypes.object.isRequired,
    assignmentsLoading: PropTypes.bool,
    filteredAssignments: PropTypes.object.isRequired,
    fetchAssignments: PropTypes.func.isRequired,
    assignmentsTotal: PropTypes.number,
    assignmentsTotalCompleted: PropTypes.number,
    assignmentsTotalUpcoming: PropTypes.number,
    assignmentsTotalToday: PropTypes.number,
    transitionTo: PropTypes.func.isRequired,
    startVideoCall: PropTypes.func.isRequired,
    tokboxConnect: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    fetchTimeslots: PropTypes.func.isRequired,
    fetchTimeslotsLoading: PropTypes.bool,
    timeslots: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.tab || '1',
      query: ''
    };
  }

  componentDidMount() {
    const { user, assignments, fetchAssignments, fetchTimeslots } = this.props;
    const { query } = this.state;
    const promises = [];
    promises.push(fetchTimeslots(user.get('id')));
    promises.push(fetchAssignments());

    // Set interval to load new data each 10s
    this.refreshInterval = setInterval(() => {
      fetchAssignments(
        0,
        assignments.length,
        null,
        null,
        query || '',
        null,
        true
      );
    }, 10000);

    return Promise.all(promises);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.tab !== nextProps.route.query.tab) {
      this.toggle(nextProps.route.query.tab);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { fetchAssignments, assignments } = this.props;

    if (
      this.state.query !== nextState.query ||
      assignments.length !== nextProps.assignments.length
    ) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(() => {
        fetchAssignments(
          0,
          nextProps.assignments.length,
          null,
          null,
          nextState.query || '',
          null,
          true
        );
      }, 10000);
    }
  }

  componentWillUnmount() {
    // Clear refresh data interval
    clearInterval(this.refreshInterval);
  }

  getFiltered = (query, activeTab) => {
    const { assignments, filteredAssignments } = this.props;
    const result = query ? filteredAssignments : assignments;

    switch (activeTab) {
      case '1': {
        const data = result
          .filter((a) => !a.get('is_scored'))
          .sort((a, b) =>
            moment(a.get('assigments_time')).diff(
              moment(b.get('assigments_time'))
            )
          );
        return {
          result: data,
          total: data.length
        };
      }
      case '2': {
        const data = result
          .filter((a) => a.get('is_scored'))
          .sort((a, b) =>
            moment(b.get('assigments_time')).diff(
              moment(a.get('assigments_time'))
            )
          );
        return {
          result: data,
          total: data.length
        };
      }
      default:
        return {
          result,
          total: result.length
        };
    }
  };

  findItems = (e) => {
    const { value } = e.target;

    if (value.length > 2) {
      this.setState({ query: value });
      debounce('search', 500, () => this.fetchData(value));
    } else if (value.length === 0) {
      this.setState({ query: '' });
      debounce('search', 500, () => this.fetchData(''));
    }
  };

  fetchData = (value) => {
    const { fetchAssignments } = this.props;
    fetchAssignments(0, 15, null, null, value);
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        query: ''
      });
    }
  };

  render() {
    const { intl } = this.context;
    const { activeTab, query } = this.state;
    const {
      user,
      fetchAssignments,
      transitionTo,
      startVideoCall,
      startTwilioCall,
      fetchIsInCall,
      downloadPdf,
      tokboxConnect,
      twilioConnect,
      twilioStartPhoneCall,
      twilioRegisterPhoneCall,
      timeslots,
      fetchTimeslotsLoading,
      route,
      assignmentsLoading
    } = this.props;
    const data = this.getFiltered(query, activeTab);
    const { result } = data;
    const { total } = data;

    return (
      <div>
        {user.has('roles') &&
          user.get('active_role') === 'assessor' &&
          !timeslots.find((slot) => slot.size > 0) &&
          !fetchTimeslotsLoading && (
            <Alert color="info">
              <FormattedMessage id="PLEASE_SET_CALENDAR" />{' '}
              <Link
                to={`/profile/edit/${user.get('id')}`}
                query={{ calendar: true, profile: true }}
              >
                <FormattedMessage id="HERE" />
              </Link>
            </Alert>
          )}
        <div
          className={`clearfix ${assignmentsStyles['assignments-preview-head']}`}
        >
          <h1>
            <FormattedMessage id="ASSIGNMENTS" />
          </h1>

          <Button
            color="primary"
            onClick={() => transitionTo('/auth/invite/it-check')}
          >
            <FormattedMessage id="IT_CHECK" />
          </Button>
        </div>

        <Nav tabs className={assignmentsStyles['assignments-tabs']}>
          {tabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.key}`}
                to="/assignments"
                query={{ tab: tab.id.toString() }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`ASSIGNMENTS_TABS.${tab.key}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}

          <Form
            onSubmit={(e) => e.preventDefault()}
            className={styles['search-input']}
          >
            <Input
              id="search"
              name="search"
              type="text"
              placeholder={intl.formatMessage({ id: 'SEARCH_BY_NAME' })}
              onChange={this.findItems}
              getRef={(input) => (this.input = input)}
            />
            <button
              id="submit_search"
              name="submit_search"
              type="button"
              className={classNames(
                styles['search-input__button'],
                query && styles.close
              )}
              onClick={
                !query
                  ? () => this.input.focus()
                  : () => {
                      this.input.value = '';
                      this.setState({ query: '' });
                      this.fetchData();
                    }
              }
            />
          </Form>
        </Nav>

        <BuildTable
          isLoading={assignmentsLoading}
          table={getAssignmentsTable(activeTab, result, {
            total,
            loadMore: fetchAssignments,
            itemsCount: 15,
            query,
            transitionTo,
            startVideoCall,
            startTwilioCall,
            fetchIsInCall,
            downloadPdf,
            tokboxConnect,
            twilioConnect,
            twilioStartPhoneCall,
            twilioRegisterPhoneCall,
            context: this.context,
            route
          })}
        />
      </div>
    );
  }
}
