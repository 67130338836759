// Vendor
import React from 'react';
import { change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Components
import { Alert } from 'reactstrap';

export default function handleSelect(name, value, form, dispatch, context) {
  const { addAlert, removeAlert } = context;

  switch (name) {
    case 'add_assessor':
      return addAlert(
        <Alert toggle={removeAlert}>
          <FormattedMessage
            id="ASSESSOR_ADD_SUCCESSFULLY"
            values={{ name: value }}
          />
        </Alert>
      );
    case 'timezone':
      return dispatch(change(form, 'timezone', value));
    case 'language':
      return dispatch(change(form, 'language', value));
    case 'owner.timezone':
      return dispatch(change(form, 'owner.timezone', value));
    case 'owner.language':
      return dispatch(change(form, 'owner.language', value));
    case 'owner':
      return dispatch(change(form, 'owner', value));
    default:
      return dispatch(change(form, name, value));
  }
}
