import { SET_IFRAME_SRC } from 'constants/actionTypes';

/**
 * Set locale action
 *
 * @param{string} iframe url
 * @returns {{types: *[], promise: Request }}
 */

export default function setIframeSrc(iframeSrc) {
  return {
    type: SET_IFRAME_SRC,
    iframeSrc
  };
}
