// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import api from 'api';
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Label
} from 'reactstrap';
import { Loader } from 'components';
import { validate } from './validate';

// Components
// Styles
import styles from '../../styles.css';
import '../../styles.css';
import createPasswordStyles from './styles.css';
import './styles.css';

// Actions
import { renderInput } from '../../actions';
import { isMobile } from '../../../helpers';

// Const
const form = 'createNewPasswordForm';

/**
 * Create new password form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    formErrors: state.auth.get('setPasswordError') || {},
    isLoading: state.auth.get('setNewPasswordLoading'),
    loggedUser: state.auth.get('loggedUser'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class CreateNewPasswordForm extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    pristine: PropTypes.bool,
    invitation: PropTypes.bool,
    formErrors: PropTypes.object,
    loggedUser: PropTypes.object,
    change: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    route: PropTypes.object
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: false,
      touched: false
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { change, loggedUser, invitation, route, transitionTo } = this.props;

    if (invitation) {
      change(form, 'email', loggedUser.get('email'));
    }

    if (route && route.query && route.query.token) {
      this.setState({ initialLoading: true }); // eslint-disable-line

      api()
        .post('/api/v1/tokens/verify/', {
          token: route.query.token,
          type: 'password'
        })
        .catch((err) => {
          if (err.res && err.res.body) {
            transitionTo('/auth/forgot-password', {
              errors: 'ERRORS.FORM_INVALID_TOKEN'
            });
          }
        })
        .then(() => this.setState({ initialLoading: false }));
    }
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { transitionTo } = this.props;

    if (nextProps.formErrors && nextProps.formErrors.token) {
      transitionTo('/auth/forgot-password', {
        errors: nextProps.formErrors.token.errors.join(',')
      });
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      handleSubmit,
      isLoading,
      pristine,
      invitation,
      formErrors
    } = this.props;
    const { initialLoading } = this.state;

    if (initialLoading) {
      return <Loader />;
    }

    return (
      <Container>
        <Row>
          <Col xs={{ size: 12 }} sm={{ size: 6, offset: 3 }}>
            <div
              className={classNames(
                createPasswordStyles.createPasswordForm,
                isMobile() && createPasswordStyles.mobile
              )}
            >
              <div className={styles['auth-wrapper']}>
                {formErrors &&
                  formErrors.token &&
                  formErrors.token.errors.map((error) => (
                    <Alert color="danger">
                      {error.indexOf('ERRORS') > -1 ? (
                        <FormattedMessage id={error} />
                      ) : (
                        error
                      )}
                    </Alert>
                  ))}
                {invitation ? (
                  <h2 className={styles['invite-form-heading']}>
                    <FormattedMessage id="CREATE_PASSWORD" />
                  </h2>
                ) : (
                  <h2>
                    <FormattedMessage id="CREATE_NEW_PASSWORD" />
                  </h2>
                )}
                <form
                  onSubmit={handleSubmit}
                  className={styles.form}
                  onClick={() => this.setState({ touched: true })}
                >
                  {invitation && (
                    <InputGroup>
                      <Label for="email">
                        <FormattedMessage id="FORM_FIELDS.email" />{' '}
                      </Label>
                      <Field
                        id="email"
                        name="email"
                        component={renderInput}
                        type="email"
                        disabled
                      />
                    </InputGroup>
                  )}
                  <InputGroup>
                    <Label for="password">
                      <FormattedMessage id="FORM_FIELDS.password" /> (
                      <FormattedMessage id="REQUIRED" />)
                    </Label>
                    <Field
                      id="password"
                      name="password"
                      component={renderInput}
                      type="password"
                      infoError
                    />
                  </InputGroup>

                  <InputGroup>
                    <Label for="password_again">
                      {invitation ? (
                        <FormattedMessage id="FORM_FIELDS.confirm_password" />
                      ) : (
                        <FormattedMessage id="FORM_FIELDS.confirm_new_password" />
                      )}{' '}
                      (
                      <FormattedMessage id="REQUIRED" />)
                    </Label>
                    <Field
                      id="password_again"
                      name="password_again"
                      component={renderInput}
                      type="password"
                    />
                  </InputGroup>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      id="submit_create_password_form"
                      name="submit_create_password_form"
                      onClick={handleSubmit}
                      disabled={pristine || isLoading}
                      color="primary-dark"
                      className={styles['submit-button']}
                    >
                      {invitation ? (
                        <FormattedMessage id={isLoading ? 'LOADING' : 'NEXT'} />
                      ) : (
                        <FormattedMessage
                          id={isLoading ? 'LOADING' : 'SAVE_NEW_PASSWORD'}
                        />
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
