// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

// Components
import { Button } from 'reactstrap';

// Styles
import styles from '../../styles.css';
import { renderInput } from '../../actions';

// Const
import { timeOptions } from '../../../constants/fieldsData';

/**
 * Render timeslot selects
 * @param fields
 * @param disabled
 * @param addDisabled
 * @param preferredTimesError
 * @param day
 */
const renderTimeslotSelects = ({
  fields,
  disabled,
  addDisabled,
  preferredTimesError,
  day
}) => (
  <div>
    {fields.map((field, index) => {
      const hasError =
        preferredTimesError[day.get('name')] != null &&
        preferredTimesError[day.get('name')].from === fields.get(index).from &&
        preferredTimesError[day.get('name')].to === fields.get(index).to;

      const hasFromError =
        preferredTimesError[day.get('name')] != null &&
        fields.get(index).to == null
          ? false
          : hasError;
      const hasToError =
        preferredTimesError[day.get('name')] != null &&
        fields.get(index).from == null
          ? false
          : hasError;

      return (
        <div key={index}>
          <div
            className={classNames(
              styles['time-select'],
              disabled && styles.disabled
            )}
          >
            <Field
              type="select"
              name={`${field}.from`}
              component={renderInput}
              valueKey="name"
              optionKey="name"
              options={timeOptions}
              disabled={disabled}
              placeholder="PLEASE_SELECT_TIME"
              translatePlaceholder
              error={hasFromError}
            />
          </div>

          <div
            className={classNames(
              styles['time-select'],
              disabled && styles.disabled
            )}
          >
            <Field
              type="select"
              name={`${field}.to`}
              component={renderInput}
              valueKey="name"
              optionKey="name"
              options={timeOptions}
              disabled={disabled}
              placeholder="PLEASE_SELECT_TIME"
              translatePlaceholder
              error={hasToError}
            />
          </div>

          {!disabled && (
            <Button
              id={`remove_${day.get('name')}_${index}`}
              name={`remove_${day.get('name')}_${index}`}
              className={styles['remove-button']}
              type="button"
              color="danger"
              title="Remove timeslot"
              onClick={() => fields.remove(index)}
              disabled={disabled}
            >
              <FormattedMessage id="REMOVE" />
            </Button>
          )}
        </div>
      );
    })}

    {!disabled && (
      <div style={fields.length === 0 ? {} : { width: '26%', float: 'right' }}>
        <Button
          id={`add_${day.get('name')}`}
          name={`add_${day.get('name')}`}
          outline
          className={styles['add-button']}
          color="primary"
          type="button"
          onClick={() => fields.push({})}
          disabled={disabled || addDisabled}
        >
          + <FormattedMessage id="ADD" />
        </Button>
      </div>
    )}
  </div>
);

// Prop Types
renderTimeslotSelects.propTypes = {
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  addDisabled: PropTypes.bool,
  disabledValues: PropTypes.array,
  preferredTimesError: PropTypes.object,
  day: PropTypes.object
};

export default renderTimeslotSelects;
