// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { Col, InputGroup, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from '../styles.css';
import '../styles.css';

// Actions
import { renderInput } from '../actions';

/**
 * Marketing consent
 * @param props
 * @returns {*}
 * @constructor
 */
const MarketingConsent = (props) => (
  <Row>
    <Col xs={12}>
      <h5>
        <FormattedMessage id="CONTACT_PERMISSION" />
      </h5>
    </Col>
    <Col xs={12}>
      <p>
        <FormattedMessage id="CONTACT_PERMISSION_INFO" />
      </p>
      <InputGroup className={styles['consent-checkbox']}>
        <Label check>
          <Field
            disabled={props.disabled}
            name="consent_email"
            component={renderInput}
            readOnly={props.disabled}
            type="checkbox"
          />
          <FormattedMessage id="CONTACT_PERMISSION_CONSENT_MESSAGE" />
        </Label>
      </InputGroup>
    </Col>
  </Row>
);

MarketingConsent.defaultProps = {
  disabled: false
};

MarketingConsent.propTypes = {
  disabled: PropTypes.bool
};

export default MarketingConsent;
