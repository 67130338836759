import {
  UPDATE_STAGE_REQUEST,
  UPDATE_STAGE_SUCCESS,
  UPDATE_STAGE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Update user stage action
 *
 * @param{Number} id
 * @param{Object} stage
 * @returns {{types: *[], promise: Request }}
 */

export default function updateUserStage(id, stage) {
  return {
    types: [UPDATE_STAGE_REQUEST, UPDATE_STAGE_SUCCESS, UPDATE_STAGE_FAILURE],
    promise: api().post(`/api/v1/users/${id}/stage/`, stage)
  };
}
