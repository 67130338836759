import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styles from './styles.css';
import './styles.css';

const STEP_CONST = 1000;

class Timer extends React.Component {
  static propTypes = {
    duration: PropTypes.number.isRequired,
    remainingTime: PropTypes.number,
    onFinish: PropTypes.func
  };

  constructor(props) {
    super(props);
    const { remainingTime, duration } = this.props;

    this.state = {
      remainingTime: remainingTime
        ? remainingTime > 0
          ? remainingTime
          : 0
        : duration
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { remainingTime } = nextProps;

    if (remainingTime !== this.props.remainingTime) {
      this.setState({ remainingTime });
    }
  }

  componentDidMount() {
    const { onFinish } = this.props;
    this.interval = setInterval(() => {
      if (this.state.remainingTime <= 0) {
        clearInterval(this.interval);
        if (typeof onFinish === 'function') {
          onFinish();
        }
        return;
      }
      this.setState({ remainingTime: this.state.remainingTime - STEP_CONST });
    }, STEP_CONST);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getColor = (remainingTime) => {
    const { duration } = this.props;

    if (remainingTime >= (duration / 3) * 2) {
      return '#95bd2c';
    }

    if (remainingTime >= duration / 3) {
      return '#f29420';
    }

    return '#d80028';
  };

  render() {
    const { remainingTime } = this.state;

    const remainingMinutes = Math.max(
      0,
      Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))
    );
    const remainingSeconds = Math.max(
      0,
      Math.floor((remainingTime % (1000 * 60)) / 1000)
    );

    const pad = (time) => {
      return `0${time}`.slice(-2);
    };

    return (
      <div className={styles['countdown-circle']}>
        <CircularProgressbarWithChildren
          background
          strokeWidth={4}
          value={(remainingTime / this.props.duration) * 100}
          styles={{
            background: {
              fill: '#ffffff'
            },
            path: {
              stroke: this.getColor(remainingTime),
              transformOrigin: 'center center',
              strokeLinecap: 'butt'
            }
          }}
        >
          <div className={styles['countdown-circle-children']}>
            <div className={styles['countdown-circle-children-time']}>
              <strong
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex="0"
                aria-label={`Time left: ${remainingMinutes} minutes ${remainingSeconds}`}
              >
                {pad(remainingMinutes)}:{pad(remainingSeconds)}
              </strong>
            </div>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    );
  }
}

export default Timer;
