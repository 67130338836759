// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

// Actions
import { editBehavior } from 'actions/behaviors';

// Styles
import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

/**
 * BasicInformationTranslateTable
 * @class
 */
@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainBehavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    behavior: state.behaviors.hasIn([
      'behaviors',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.behaviors.getIn([
          'behaviors',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.behaviors.getIn([
          'behaviors',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ editBehavior }, dispatch)
)
export default class BasicInformationTranslateTable extends Component {
  // PropTypes
  static propTypes = {
    id: PropTypes.number,
    behavior: PropTypes.object,
    mainBehavior: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    editBehavior: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  /**
   * Get table
   * @returns {{data: Array, fields: *[]}}
   */
  getTable = () => {
    const { behavior, mainBehavior, hasError, disabled } = this.props;

    return {
      data: [mainBehavior],
      customClassName: 'trans-2-table',
      fields: [
        {
          key: 'original_behavior_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.get('name')
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            const defaultValue = behavior.get('name') || null;

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={`basic_name_${item.get('id')}`}
                  defaultValue={defaultValue}
                  onSubmit={this.handleSubmit}
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { id, editBehavior, version } = this.props;

    const translated = {
      name: data.translation,
      language: version
    };

    editBehavior(id, translated);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    if (!this.props.behavior) {
      return <div />;
    }

    return (
      <div>
        <BuildTable table={this.getTable()} />
      </div>
    );
  }
}
