import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reschedule } from 'actions/users';

import Calendar from '../../../views/Auth/Invitation/steps/Schedule/components/Calendar';
import modals from '../../../views/modals.css';
import '../../../views/modals.css';

const isEmpty = (value) =>
  value == null || value === 'null' || value === '' || value === ' ';

@connect(
  (state) => ({
    rescheduleLoading: state.users.get('rescheduleLoading')
  }),
  (dispatch) => bindActionCreators({ reschedule }, dispatch)
)
export default class ManualRescheduleModal extends React.Component {
  static contextTypes = {
    addAlert: PropTypes.func.isRequired
  };

  static propTypes = {
    removeModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    reschedule: PropTypes.func.isRequired,
    rescheduleLoading: PropTypes.bool,
    reloadData: PropTypes.func.isRequired
  };

  state = {
    selected: null
  };

  onCalendarSelect = (data) => {
    this.setState({ selected: data });
  };

  submitManualReschedule = () => {
    const { addAlert } = this.context;
    const { user, reschedule, removeModal, reloadData } = this.props;
    const { selected } = this.state;

    reschedule(user.get('id'), {
      start_time: `${selected.day.format('YYYY-MM-DD')} ${selected.time}`
    }).then((action) => {
      removeModal();

      if (action.error) {
        window.scrollTo(0, 0);
        addAlert({
          color: 'danger',
          content:
            action.error.body.errors != null
              ? action.error.body.errors.map((e) => <FormattedMessage id={e} />)
              : JSON.stringify(action.error.body)
        });
      } else {
        reloadData();
      }
    });
  };

  render() {
    const { removeModal, user, rescheduleLoading } = this.props;
    const { selected } = this.state;

    return (
      <Modal isOpen toggle={() => false} size="lg">
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id="RESCHEDULE_MANUALLY" />
        </ModalHeader>
        <ModalBody
          className={modals['modal-body']}
          style={{ marginBottom: 50 }}
        >
          <p
            style={{
              fontSize: 13,
              textAlign: 'center'
            }}
          >
            <FormattedMessage id="RESCHEDULE_MANUALLY_MODAL_HINT" />
          </p>
          <Calendar
            manualSchedule
            selectedSimulation={{
              id: user.getIn(['active_simulation', 'simulation_id']),
              language: user.getIn(['active_simulation', 'language'])
            }}
            timezone={user.get('timezone')}
            onSelect={this.onCalendarSelect}
            seed={user.get('seed')}
            partner={
              isEmpty(user.get('partner_id')) ? null : user.get('partner_id')
            }
            reschedule
          />
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_manual_reschedule"
                name="cancel_manual_reschedule"
                color="secondary"
                onClick={removeModal}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_manual_reschedule"
                name="submit_manual_reschedule"
                color="primary"
                onClick={this.submitManualReschedule}
                className={modals['footer-button']}
                disabled={
                  selected == null || selected.time == null || rescheduleLoading
                }
              >
                <FormattedMessage id="RESCHEDULE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
