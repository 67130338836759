import {
  GET_NARRATIVE_REQUEST,
  GET_NARRATIVE_SUCCESS,
  GET_NARRATIVE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get narrative action
 *
 * @param{Number} report_id
 * @returns {{types: *[], promise: Request }}
 */

export default function getNarrative(report_id) {
  return {
    types: [
      GET_NARRATIVE_REQUEST,
      GET_NARRATIVE_SUCCESS,
      GET_NARRATIVE_FAILURE
    ],
    promise: api().get(`/api/v1/analytics/${report_id}/narrative/`),
    report_id
  };
}
