import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'js-debounce';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Label,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AutomationForm, EditAssessmentForm } from 'forms';
import { ActionButtons, BuildTable, ConfirmDelete, Link } from 'components';
import { SubmitPublishModal } from 'views/Simulations/components/EditSimulationTable/modals';
import { Strategy } from '../../modals';
import {
  BehaviorsTranslateTable,
  AnalyticsTranslateTable
} from '../Translate/components';

import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';

import {
  editAssessment,
  fetchAssessmentStrategies,
  strategies
} from 'actions/assessments';
import { form } from '../../../../../../forms/Assessments/Edit/index';

let editAssessmentTabs = [
  { id: '1', title: 'basic_information' },
  { id: '2', title: 'behaviors' },
  { id: '3', title: 'strategies' },
  { id: '4', title: 'analytics' },
  { id: '5', title: 'automation' }
];

@connect(
  (state) => ({
    users: state.users.get('users'),
    id: Number(state.router.route.vars.id),
    assessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        change,
        editAssessment,
        fetchAssessmentStrategies,
        transitionTo
      },
      dispatch
    );
  }
)
export default class Main extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    users: PropTypes.object,
    id: PropTypes.number,
    editAssessment: PropTypes.func.isRequired,
    assessment: PropTypes.object,
    transitionTo: PropTypes.func.isRequired,
    fetchAssessmentStrategies: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.subTab || '1'
    };
  }

  componentDidMount() {
    const { id, fetchAssessmentStrategies } = this.props;
    fetchAssessmentStrategies(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.subTab !== nextProps.route.query.subTab) {
      this.toggle(nextProps.route.query.subTab || '1');
    }
  }

  getStrategiesTable = (assessment, context) => {
    const assessmentVersion = assessment
      .get('languages')
      .find((l) => l.get('language') === 'english');
    const disabled = assessmentVersion.get('published');

    const fields = [
      {
        key: 'strategy_name',
        sortable: true,
        renderField: (item) => (item ? item.get('name') : '')
      },
      {
        key: 'description',
        sortable: false,
        renderField: (item) => (item ? item.get('description') : ''),
        width: 30
      },
      {
        key: 'behaviors',
        sortable: false,
        width: 30,
        renderField: (item) =>
          item && item.has('behaviors')
            ? item.get('behaviors').map((b) => (
                <div>
                  {assessment
                    .get('behaviors')
                    .find(
                      (behavior) => behavior.get('behavior_id') === Number(b)
                    )
                    .get('label')}
                </div>
              ))
            : null
      }
    ];

    if (!disabled) {
      fields.push({
        key: 'actions',
        sortable: false,
        ownData: true,
        renderField: (item) => (
          <td style={{ width: 112 }}>
            <ActionButtons
              id={item.get('id')}
              edit={() => {
                context.addModal(
                  <Strategy edit={item} onClose={context.removeModal} />
                );
              }}
              delete={
                assessmentVersion && assessmentVersion.get('was_published')
                  ? null
                  : () => {
                      context.addModal(
                        <ConfirmDelete
                          action={strategies}
                          data={{
                            type: 'delete',
                            assessmentId: assessment.get('id'),
                            data: {},
                            id: item.get('id')
                          }}
                          onClose={context.removeModal}
                        />
                      );
                    }
              }
            />
          </td>
        )
      });
    }

    return {
      data: assessment.has('strategies')
        ? assessment.get('strategies').toArray()
        : [],
      customClassName: 'strategies-table',
      fields,
      button: (
        <Button
          id="add_new_strategy_button"
          name="add_new_strategy_button"
          onClick={() => {
            context.addModal(<Strategy onClose={context.removeModal} />);
          }}
          className={styles['add-button']}
          disabled={
            disabled ||
            (assessment.has('strategies') &&
              assessment.get('strategies').size >= 3) ||
            assessmentVersion.get('was_published')
          }
        >
          <FormattedMessage id="ADD_NEW_STRATEGY" />
        </Button>
      )
    };
  };

  handleAutomationChange = (data) => {
    const { editAssessment, id } = this.props;

    debounce('onAutomationChange', 500, () => {
      editAssessment(id, {
        automation: {
          report: {
            enabled: data.report_sharing === true,
            delay: Number(data.report_sharing_delay)
          },
          tpa: {
            enabled: data.enabling_tpa === true,
            delay: Number(data.enabling_tpa_delay)
          }
        }
      });
    });
  };

  getTabContent = (tab) => {
    const { assessment } = this.props;
    const assessmentVersion = assessment
      .get('languages')
      .find((l) => l.get('language') === 'english');
    const published = assessmentVersion.get('published');
    const isPsychometricsOnly = assessment.getIn([
      'simulation',
      'is_psychometrics_only'
    ]);

    const tabMessage = ({ className, value }) => (
      <span className={className}>
        <FormattedMessage id={value} />
        <br />
        <br />
      </span>
    );

    switch (tab) {
      case '1':
        return <EditAssessmentForm onSubmit={this.handleSubmit} />;
      case '2':
        return (
          <BehaviorsTranslateTable disabled={published} version="english" />
        );
      case '3':
        return (
          <div>
            {tabMessage({
              className: classNames(
                assessment.has('strategies') &&
                  assessment.get('strategies').size >= 3 &&
                  styles.red
              ),
              value: 'MAX_STRATEGIES_INFO'
            })}
            <BuildTable
              table={this.getStrategiesTable(assessment, this.context)}
            />
          </div>
        );
      case '4':
        return (
          <AnalyticsTranslateTable
            published={published}
            disabled={published || assessmentVersion.get('was_published')}
            version="english"
            assessment={assessment}
          />
        );
      case '5':
        return (
          <div>
            {tabMessage({
              className: classNames(styles.red),
              value: `AUTOMATION_TAB_INFO${
                isPsychometricsOnly ? '_PSYCHOMETRICS_ONLY' : ''
              }`
            })}
            <AutomationForm
              assessment={assessment}
              tab={tab}
              disabled={published}
              onSubmit={this.handleAutomationChange}
            />
          </div>
        );
      default:
        return <div>{tab}</div>;
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleSubmit = (data) => {
    const { id, editAssessment } = this.props;
    const { addAlert } = this.context;

    editAssessment(id, data).then((action) => {
      window.scrollTo(0, 0);
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
        });
      }
    });
  };

  submitPublish = () => {
    const { id, editAssessment, assessment, change } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    const assessmentVersion = assessment
      .get('languages')
      .find((l) => l.get('language') === 'english');
    const errorFields = [
      'name',
      'simulation',
      'translation',
      'partner_id',
      'client_id'
    ];

    editAssessment(id, {
      language: 'english',
      published: !assessmentVersion.get('published')
    }).then((action) => {
      if (action.error && action.error.body) {
        errorFields.map((field) => {
          if (action.error.body[field] && action.error.body[field].errors) {
            const string = action.error.body[field].errors
              .map((err) => intl.formatMessage({ id: err }))
              .join(', ')
              .trim();

            return addAlert({
              color: 'danger',
              content: string
            });
          }

          return false;
        });
      } else if (action.error) {
        addAlert({
          color: 'danger',
          content: JSON.stringify(action.error.body)
        });
      } else {
        if (action.result.body && action.result.body.name) {
          change(form, 'name', action.result.body.name);
        }

        addAlert({
          color: 'success',
          content: (
            <FormattedMessage
              id={`SUCCESSFULLY_${
                !assessmentVersion.get('published')
                  ? 'PUBLISHED'
                  : 'UNPUBLISHED'
              }`}
              values={{ item: intl.formatMessage({ id: 'ASSESSMENT' }) }}
            />
          )
        });
      }
      removeModal();
    });
  };

  render() {
    const { assessment, route } = this.props;
    const { addModal } = this.context;
    const { activeTab } = this.state;
    const isPsychometricsOnly = assessment.getIn([
      'simulation',
      'is_psychometrics_only'
    ]);

    if (isPsychometricsOnly) {
      editAssessmentTabs = editAssessmentTabs.filter(
        (tab) => ['behaviors', 'strategies'].indexOf(tab.title) === -1
      );
    }

    const assessmentVersion = assessment
      .get('languages')
      .find((l) => l.get('language') === 'english');

    const isDisabled =
      (assessmentVersion.get('published') &&
        !assessmentVersion.get('is_unpublishable')) ||
      (!assessmentVersion.get('published') &&
        !assessmentVersion.get('is_publishable'));

    return (
      <div className={styles['simulation-subtabs-div']}>
        <Nav tabs>
          {editAssessmentTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab-${tab.title}`}
                to={`/customize/assessment/edit/${assessment.get('id')}`}
                query={{
                  tab: route.query.tab || '1',
                  subTab: tab.id.toString()
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`EDIT_ASSESSMENT_TABS.${tab.title}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {assessmentVersion.get('published') &&
            assessmentVersion.has('published_at') && (
              <span className={styles['published-message']}>
                <FormattedMessage
                  id={
                    assessmentVersion.has('published_by')
                      ? 'PUBLISHED_BY'
                      : 'PUBLISHED_ON'
                  }
                  values={{
                    admin: assessmentVersion.getIn(['published_by', 'name']),
                    date: moment(assessmentVersion.get('published_at')).format(
                      'DD MMM YYYY hh:mm A'
                    )
                  }}
                />
              </span>
            )}
          <div
            key="switcher"
            className={classNames(
              styles['custom-switcher'],
              isDisabled && styles.disabled,
              assessmentVersion.get('published') && styles.bold
            )}
          >
            <FormattedMessage
              id={
                assessmentVersion.get('published') ? 'PUBLISHED' : 'UNPUBLISHED'
              }
            />
            <Label
              className={styles['custom-switch']}
              id="publish_button_label"
            >
              <input
                id="publish_button"
                name="publish_button"
                type="checkbox"
                disabled={isDisabled}
                onChange={() =>
                  addModal(
                    <SubmitPublishModal
                      action={this.submitPublish}
                      publish={assessmentVersion.get('published')}
                      type="BEHAVIOR"
                    />
                  )
                }
                checked={assessmentVersion.get('published')}
              />
              <div className={styles['switch-slider']} />
            </Label>
          </div>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className={styles['simulation-subtabs']}
        >
          {editAssessmentTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {tab.id === activeTab && this.getTabContent(activeTab)}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
