import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import NotSupported from 'views/Auth/Invitation/steps/ITCheck/NotSupported';
import api from 'api';
import { TopNav, Footer, Announcement, SurveyBanner } from '../../components';
import { AlertSystem, ModalSystem } from '../../containers';
import Wrapper from './Wrapper';
import RemainingTimeModal from './RemainingTimeModal';
import ZohoSurveyModal from './ZohoSurveyModal';

import modals from '../modals.css';
import '../modals.css';
import styles from './styles.css';
import './styles.css';

import { DETAIL_FETCH_ERROR } from '../../constants/errors';
import {
  getRemainingTime,
  isMobile,
  stageRedirect,
  checkDownTime
} from 'helpers';
import { getCookie } from '../../helpers';
import { AUTH_TOKEN } from '../../constants/cookies';
import { isInIframe } from '../../helpers/isInIframeCheck';

@connect((state) => ({
  loggedUser: state.auth.get('loggedUser'),
  schedule: state.simulations.get('schedule'),
  route: state.router.route,
  loadingBar: state.loadingBar,
  direction: state.app.get('direction')
}))
class App extends Component {
  static propTypes = {
    children: PropTypes.any,
    loggedUser: PropTypes.object,
    schedule: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    loadingBar: PropTypes.object,
    direction: PropTypes.string.isRequired
  };

  static childContextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    removeAllAlerts: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.addAlert = this.addAlert.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.removeAllAlerts = this.removeAllAlerts.bind(this);
    this.addModal = this.addModal.bind(this);
    this.removeModal = this.removeModal.bind(this);
    this.notificationInterval = null;
    this.hasBeenCancelledNotified = false;
    this.state = {
      showAnnouncement: false,
      showProductValueBanner: false,
      showProductValueBannerChecked: false
    };
  }

  getChildContext() {
    return {
      addAlert: this.addAlert,
      removeAlert: this.removeAlert,
      removeAllAlerts: this.removeAllAlerts,
      addModal: this.addModal,
      removeModal: this.removeModal
    };
  }

  componentDidMount() {
    const { loggedUser, schedule, route } = this.props;

    document.getElementById('LOADER').style.display = 'none';
    this.checkSchedule(schedule, route);

    if (loggedUser && loggedUser.get('active_role') === 'participant') {
      this.setNotificationInterval(loggedUser);
    }

    if (route.query[DETAIL_FETCH_ERROR]) {
      this.addAlert({
        color: 'danger',
        content: <FormattedMessage id={route.query[DETAIL_FETCH_ERROR]} />
      });
    }

    this.showZohoSurveyModal(loggedUser);

    checkDownTime((res) => {
      const showAnnouncement = !!Number(res.body?.SHOW_ANNOUNCEMENT);
      this.setState({ showAnnouncement });
    });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { loggedUser, schedule, route } = this.props;

    if (
      schedule.get('start_time') !== nextProps.schedule.get('start_time') &&
      stageRedirect(loggedUser.toJS()).indexOf('prework/lat') < 0
    ) {
      this.checkSchedule(nextProps.schedule, nextProps.route);
    }

    if (
      route.pathname === '/auth/invite/terms' &&
      route.pathname !== nextProps.route.pathname &&
      stageRedirect(loggedUser.toJS()).indexOf('prework/lat') < 0
    ) {
      this.checkSchedule(nextProps.schedule, nextProps.route);
    }

    if (loggedUser.size !== nextProps.loggedUser.size) {
      this.setNotificationInterval(nextProps.loggedUser);
    }

    if (
      loggedUser.getIn(['surveys', 'assessment']) !==
      nextProps.loggedUser.getIn(['surveys', 'assessment'])
    ) {
      this.showZohoSurveyModal();
    }

    if (
      loggedUser.size > 0 &&
      route.pathname !== '/auth/login' &&
      !this.state.showProductValueBanner &&
      ['admin', 'stakeholder'].indexOf(loggedUser.get('active_role')) !== -1 &&
      !this.state.showProductValueBannerChecked
    ) {
      try {
        if (window.sessionStorage.getItem('product-value-survery-dismissed')) {
          return;
        }
        this.setState({
          showProductValueBannerChecked: true
        });
        await api().get('/api/v1/users/survey/eligibility/product-value/');
        this.setState({ showProductValueBanner: true });
      } catch (err) {
        this.setState({ showProductValueBanner: false });
      }
    }
  }

  componentWillUnmount() {
    if (this.notificationInterval != null) {
      clearInterval(this.notificationInterval);
    }
  }

  showZohoSurveyModal = (loggedUser) => {
    if (
      loggedUser != null &&
      loggedUser.getIn(['surveys', 'assessment']) === 'eligible'
    ) {
      this.addModal(<ZohoSurveyModal />);
    }
  };

  setNotificationInterval = (loggedUser) => {
    setTimeout(() => {
      this.checkNotifications(loggedUser);
    }, 2000);

    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }

    this.notificationInterval = setInterval(() => {
      this.checkNotifications(loggedUser);
    }, 10000);
  };

  checkNotifications = (loggedUser) => {
    if (loggedUser && loggedUser.get('active_role') === 'participant') {
      api()
        .get(`/api/v1/users/${loggedUser.get('id')}/stage/history/?notify=1`)
        .then((res) => {
          if (res.error) {
            throw res.error;
          } else {
            const { body } = res;
            const hasBeenCanceled = body.data.filter(
              (i) => i.name === 'USEREVENT.schedule_canceled'
            );
            const hasBeenRescheduled = body.data.filter(
              (i) => i.name === 'USEREVENT.reschedule_invite'
            );
            const hasBeenManuallyRescheduled = body.data.filter(
              (i) => i.name === 'USEREVENT.rescheduled_manually'
            );

            const getModalData = () => {
              if (
                hasBeenCanceled.length > 0 &&
                !this.hasBeenCancelledNotified
              ) {
                this.hasBeenCancelledNotified = true;
                return {
                  header: 'SESSION_HAS_ENDED',
                  body: 'SESSION_MODAL_CANCELED',
                  notifications: hasBeenCanceled
                };
              }

              if (hasBeenRescheduled.length > 0) {
                return {
                  header: 'SESSION_HAS_ENDED',
                  body: 'SESSION_MODAL_RESCHEDULED',
                  notifications: hasBeenRescheduled
                };
              }

              if (hasBeenManuallyRescheduled.length > 0) {
                return {
                  header: 'SESSION_HAS_BEEN_RESCHEDULED',
                  body: 'SESSION_MODAL_MANUALLY_RESCHEDULED',
                  bodyValues: {
                    date: moment(hasBeenManuallyRescheduled[0].data.starttime)
                      .utcOffset(hasBeenManuallyRescheduled[0].data.starttime)
                      .format('DD. MMM YYYY hh:mm A')
                  },
                  notifications: hasBeenManuallyRescheduled
                };
              }

              return null;
            };

            const data = getModalData();
            if (data != null) {
              clearInterval(this.notificationInterval);

              this.addModal(
                <Modal isOpen toggle={() => false}>
                  <ModalHeader style={{ textAlign: 'center' }}>
                    <FormattedMessage id={data.header} />
                  </ModalHeader>
                  <ModalBody
                    className={classNames(
                      modals['modal-body'],
                      modals['text-center']
                    )}
                  >
                    <FormattedHTMLMessage
                      id={data.body}
                      values={{ ...data.bodyValues }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      className={modals['footer-button']}
                      onClick={() => {
                        const promises = data.notifications.map(
                          (notification) =>
                            api().put(
                              `/api/v1/users/${loggedUser.get(
                                'id'
                              )}/stage/history/${notification.id}/`,
                              {
                                data: {
                                  notify: 0
                                }
                              }
                            )
                        );

                        Promise.all(promises).then(() => {
                          const { pathname } = window.location;
                          this.setNotificationInterval(loggedUser);

                          if (
                            pathname.indexOf('terms') > -1 ||
                            pathname.indexOf('it-check') > -1 ||
                            pathname.indexOf('setup') > -1
                          ) {
                            return this.removeModal();
                          }

                          return (window.location = '/');
                        });
                      }}
                    >
                      <FormattedMessage id="OK" />
                    </Button>
                  </ModalFooter>
                </Modal>
              );
            }
          }
        });
    }
  };

  checkSchedule = (schedule, route) => {
    const { loggedUser } = this.props;

    const isInLat = () =>
      this.props.route.pathname.indexOf('prework/lat') > -1 &&
      this.props.loggedUser.has('lat_start_time');

    if (
      // Show only if user is not in LAT
      !isInLat() &&
      // Show only if route !== terms
      route.query.token == null &&
      route.pathname.indexOf('terms') < 0 &&
      loggedUser.has('active_simulation') &&
      schedule.has('start_time') &&
      loggedUser.getIn(['stages', 'simulation5', 'completed']) === false
    ) {
      // Check 15 min before simulation
      this.baseTimeout = setTimeout(() => {
        this.interval = setInterval(() => {
          if (
            schedule.has('start_time') &&
            this.props.route.pathname.indexOf('it-check') < 0 &&
            this.props.route.pathname.indexOf('setup') < 0
          ) {
            const remaining = getRemainingTime(schedule.get('start_time'));

            if (
              remaining.days +
                remaining.hours +
                remaining.minutes +
                remaining.seconds <
              0
            ) {
              return api()
                .get(
                  `/api/v1/users/${loggedUser.get(
                    'id'
                  )}/stage/history/?notify=1`
                )
                .then((res) => {
                  if (!res.error) {
                    const { body } = res;

                    if (body.data.length === 0) {
                      setTimeout(() => {
                        window.location = `${loggedUser.get(
                          'simulator_url'
                        )}&jwttoken=${getCookie(AUTH_TOKEN)}`;
                      }, 2000);
                    } else {
                      clearInterval(this.interval);
                    }
                  } else {
                    location.reload();
                  }
                });
            }

            if (
              !isInLat() &&
              remaining.days === 0 &&
              remaining.hours === 0 &&
              remaining.minutes < 15
            ) {
              clearInterval(this.interval);
              clearTimeout(this.baseTimeout);
              this.addModal(<RemainingTimeModal />);

              // Redirect participants to the simulator for the IT Check when the 15 minute countdown-timer starts
              return api()
                .get(
                  `/api/v1/users/${loggedUser.get(
                    'id'
                  )}/stage/history/?notify=1`
                )
                .then((res) => {
                  if (!res.error) {
                    const { body } = res;

                    if (body.data.length === 0) {
                      setTimeout(() => {
                        window.location = `${loggedUser.get(
                          'simulator_url'
                        )}&jwttoken=${getCookie(AUTH_TOKEN)}`;
                      }, 2000);
                    } else {
                      clearInterval(this.interval);
                    }
                  } else {
                    location.reload();
                  }
                });
            }

            return false;
          }

          return false;
        }, 1000);
      }, 1000);

      // Check simulation start
      this.startInterval = setInterval(() => {
        const remaining = getRemainingTime(schedule.get('start_time'));

        if (
          remaining.days +
            remaining.hours +
            remaining.minutes +
            remaining.seconds <
            0 &&
          this.props.route.pathname.indexOf('it-check') < 0 &&
          this.props.route.pathname.indexOf('setup') < 0
        ) {
          return api()
            .get(
              `/api/v1/users/${loggedUser.get('id')}/stage/history/?notify=1`
            )
            .then((res) => {
              clearInterval(this.startInterval);

              if (!res.error) {
                const { body } = res;

                if (body.data.length === 0) {
                  setTimeout(() => {
                    window.location = `${loggedUser.get(
                      'simulator_url'
                    )}&jwttoken=${getCookie(AUTH_TOKEN)}`;
                  }, 2000);
                }
              } else {
                location.reload();
              }
            });
        }

        return false;
      }, 1000);
    }
  };

  addAlert(alert) {
    if (this.refs.alertSystem != null) {
      this.refs.alertSystem.getWrappedInstance().addAlert(alert);
    }
  }

  removeAlert() {
    this.refs.alertSystem.removeAlert();
  }

  removeAllAlerts() {
    if (this.refs.alertSystem != null) {
      this.refs.alertSystem.getWrappedInstance().removeAllAlerts();
    }
  }

  addModal(modal, draggable = false) {
    if (this.refs.modalSystem != null) {
      this.refs.modalSystem.getWrappedInstance().addModal(modal, draggable);
    }
  }

  removeModal(data) {
    if (this.refs.modalSystem != null) {
      this.refs.modalSystem.getWrappedInstance().removeModal(data);
    }
  }

  handleOpenSurvey = async () => {
    const response = await api().post(`/api/v1/users/survey/product-value/`, {
      action: 'take'
    });
    window.open(response.body.url, '_blank');
    this.setState({ showProductValueBanner: false });
  };

  handleCloseSurvey = () => {
    this.setState({ showProductValueBanner: false });
    window.sessionStorage.setItem('product-value-survery-dismissed', true);
  };

  renderProductValueSurvey() {
    if (this.state.showProductValueBanner) {
      return (
        <SurveyBanner
          show
          headingTextId="PRODUCT_VALUE_SURVEY_BANNER.HEADING"
          bannerTextId="PRODUCT_VALUE_SURVEY_BANNER.TEXT"
          buttonTextId="PRODUCT_VALUE_SURVEY_BANNER.BUTTON"
          openSurveyHandler={this.handleOpenSurvey}
          closeSurveyHandler={this.handleCloseSurvey}
        />
      );
    }
    return null;
  }

  render() {
    const { children, loadingBar, direction, route } = this.props;
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;
    const mobileDevelopment =
      isMobile() &&
      route.pathname.indexOf('/notification-email-preferences') < 0;

    if (isIE) {
      return (
        <Modal isOpen toggle={() => false}>
          <div style={{ padding: 50 }}>
            <NotSupported />
          </div>
        </Modal>
      );
    }

    const showAnnouncement =
      !mobileDevelopment &&
      this.props.loggedUser.size > 0 &&
      this.state.showAnnouncement &&
      this.props.loggedUser.get('active_role') !== 'participant';

    return (
      <div className={mobileDevelopment ? styles.mobileLoginContainer : null}>
        {loadingBar.default !== 0 && (
          <div className={styles['loading-bar']}>
            <span className={styles['loading-bar_inner']} />
          </div>
        )}
        {!mobileDevelopment && !isInIframe && <TopNav />}

        {showAnnouncement && (
          // Platform wide announcements. Update the text here.
          <Announcement
            text={this.props.intl.formatMessage(
              {
                id: 'SIM_V2_ANNOUNCEMENT'
              },
              { openDate: '2022-06-04' }
            )}
          />
        )}

        <Container style={direction === 'rtl' ? { margin: 'auto' } : {}}>
          <Row>
            <Col>
              <AlertSystem ref="alertSystem" />
            </Col>
            <Col className={styles['content-box']}>
              {this.renderProductValueSurvey()}
              {children}
            </Col>
          </Row>
        </Container>

        <ModalSystem ref="modalSystem" />

        {!mobileDevelopment && !isInIframe && <Footer />}
      </div>
    );
  }
}

App.Wrapper = Wrapper;

export default injectIntl(App);
