const englishLanguageFirst = (map) =>
  map.sort((a, b) => {
    if (a.get('language') === 'english' && b.get('language') !== 'english') {
      return -1;
    }
    if (a.get('language') !== 'english' && b.get('language') === 'english') {
      return 1;
    }

    return 0;
  });

export default englishLanguageFirst;
