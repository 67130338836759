import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Loader, ToolTip } from '../../../../../components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ClimateGraph } from '../../../components';
import {
  getClimateData,
  resetClimateData
} from '../../../../../actions/analytics';
import { getSelected } from '../../../components/Folders/components/actions';

import styles from '../styles.css';

const getClimate = (props) => {
  const { route, isLoading, climateData, data, error } = props;
  const dataWithEnabledClimateSection = data.filter((r) =>
    r.get('assessment_sections').contains('climate')
  );
  const selected = getSelected(route).filter((id) =>
    dataWithEnabledClimateSection.find(
      (r) => Number(r.get('id')) === Number(id)
    )
  );

  useEffect(() => {
    if (selected.length > 1) {
      props.getClimateData(selected);
    } else {
      props.resetClimateData();
    }
  }, [route.query.selected]);

  return (
    <div className={styles.climate}>
      <Alert
        className={styles['analytics-infobox']}
        style={{ marginBottom: 90 }}
        color="info"
        isOpen
      >
        <FormattedHTMLMessage
          id={
            selected.length < 2
              ? 'GET_CLIMATE_INFO_INDIVIDUAL'
              : 'GET_CLIMATE_INFO'
          }
        />
        <ToolTip
          id="select-tooltip-climate"
          index="select-tooltip-climate"
          hint={<FormattedMessage id="ONLY_ENABLED_ANALYTICS_WILL_DISPLAY" />}
          style={{
            position: 'absolute',
            top: 10,
            right: 25
          }}
        />
      </Alert>

      {isLoading && <Loader />}

      {climateData?.toArray().map((facet, index) => (
        <div
          key={index}
          className={index % 3 === 0 ? 'html2pdf__page-break' : ''}
        >
          <Row className={styles['facet-row']}>
            <Col xs={2}>
              <h6>
                <FormattedMessage id={facet.get('name')} />
              </h6>
            </Col>
            <Col xs={10}>
              <ClimateGraph facet={facet} />
            </Col>
          </Row>
        </div>
      ))}
      {error && (
        <Alert color="danger" isOpen>
          <FormattedMessage id="ERROR_OCCURRED" />
        </Alert>
      )}
    </div>
  );
};

getClimate.propTypes = {
  route: PropTypes.object.isRequired,
  getClimateData: PropTypes.func.isRequired,
  resetClimateData: PropTypes.func.isRequired,
  climateData: PropTypes.object,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.any
};

export default connect(
  (state) => ({
    route: state.router.route,
    climateData: state.analytics.get('climateData'),
    isLoading: state.analytics.get('climateDataLoading'),
    error: state.analytics.get('climateDataError')
  }),
  (dispatch) => {
    return bindActionCreators({ getClimateData, resetClimateData }, dispatch);
  }
)(React.memo(getClimate));
