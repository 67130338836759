import { getSelected, updateCheckboxes, updatePreviewData } from './index';

export default async (data, checkboxIndex, selectAll, props) => {
  const { route, transitionTo } = props;
  let selectedUsers = getSelected(route);

  if (selectAll) {
    selectedUsers = data;
  } else {
    const index = selectedUsers.indexOf(data.get('id'));

    if (index > -1) {
      selectedUsers.splice(index, 1);
    } else {
      selectedUsers.push(data.get('id'));
    }
  }

  const newSelection = await updatePreviewData(props, selectedUsers);

  setTimeout(async () => {
    await transitionTo('/analytics', {
      tab: newSelection?.length === 0 ? null : route.query.tab || null,
      selected: newSelection?.length === 0 ? null : newSelection?.join(','),
      current:
        newSelection?.indexOf(Number(route.query.current)) < 0
          ? null
          : route.query.current,
      assessment: route.query.assessment,
      scroll: 0,
      filtered: route.query.filtered
    });

    updateCheckboxes(props, newSelection, checkboxIndex);
  }, 10);
};
