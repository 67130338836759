import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

import { editAssessment } from 'actions/assessments';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainAssessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    assessment: state.assessments.hasIn([
      'assessments',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.assessments.getIn([
          'assessments',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.assessments.getIn([
          'assessments',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ editAssessment }, dispatch)
)
export default class BasicInformationTranslateTable extends Component {
  static propTypes = {
    id: PropTypes.number,
    assessment: PropTypes.object,
    mainAssessment: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    editAssessment: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  getTable = () => {
    const { assessment, mainAssessment, hasError, disabled } = this.props;

    return {
      data: [mainAssessment],
      customClassName: 'trans-2-table',
      fields: [
        {
          key: 'original_assessment_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.get('name')
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            const defaultValue = assessment.get('name') || null;

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={item.get('id')}
                  defaultValue={defaultValue}
                  onSubmit={this.handleSubmit}
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  handleSubmit = (data) => {
    const { id, editAssessment, version } = this.props;
    const { addAlert, intl } = this.context;

    const translated = {
      name: data.translation,
      language: version
    };

    editAssessment(id, translated).then((action) => {
      if (action.error) {
        Object.keys(translated).map((key) => {
          if (action.error.body[key] && action.error.body[key].errors) {
            return addAlert({
              color: 'danger',
              content: action.error.body[key].errors
                .map((error) => intl.formatMessage({ id: error }))
                .join(', ')
                .trim()
            });
          }

          return false;
        });
      }
    });
  };

  render() {
    if (!this.props.assessment) {
      return <div />;
    }

    return (
      <div>
        <BuildTable table={this.getTable()} />
      </div>
    );
  }
}
