import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';
import { BuildTable } from 'components';
import { UserBox } from '../../../components/Folders/components';
import { getProgressValues } from 'helpers';

import styles from '../styles.css';
import '../styles.css';
import { getCurrentAssessmentId } from '../../../components/Folders/components/actions';

const getBench = (props) => {
  const { data, route } = props;
  const selectedAssessment = route.query.assessment;
  let strategies = null;

  if (selectedAssessment) {
    strategies = data
      ?.find((a) => {
        return getCurrentAssessmentId(a) === selectedAssessment?.toString();
      })
      ?.get('simulation')
      ?.filter((i) => i.has('strategy'));
  }

  const getTable = () => {
    const final = [];
    data
      .filter(
        (a) => getCurrentAssessmentId(a) === selectedAssessment?.toString()
      )
      .sort((a, b) => {
        function getSum(total, num) {
          return total + num;
        }

        const aValues = a
          .get('simulation')
          .filter((i) => i.has('strategy'))
          .map(
            (strategy) =>
              strategy.get('operf') || strategy.get('operf_simulation')
          )
          .toArray();
        const bValues = b
          .get('simulation')
          .filter((i) => i.has('strategy'))
          .map(
            (strategy) =>
              strategy.get('operf') || strategy.get('operf_simulation')
          )
          .toArray();

        if (aValues.length === 0 || bValues.length === 0) {
          return 0;
        }

        const sumA = aValues.reduce(getSum);
        const sumB = bValues.reduce(getSum);

        if (sumA < sumB) {
          return 1;
        }
        if (sumA > sumB) {
          return -1;
        }

        return 0;
      })
      .map((report) =>
        final.push({
          user: report,
          strategies: report.get('simulation').filter((i) => i.has('strategy'))
        })
      );

    const fields = [
      {
        key: 'empty',
        sortable: false,
        renderField: (item) => (
          <UserBox report={item.user} user={item.user.get('user')} />
        )
      }
    ];

    strategies.map((strategy, strategyIndex) =>
      fields.push({
        key: strategy.getIn(['strategy', 'name']),
        notTranslate: true,
        ownData: true,
        sortable: false,
        centered: true,
        renderField: (item, fieldIndex) => {
          const currentStrategy = item.strategies.find(
            (s) =>
              s.getIn(['strategy', 'id']) === strategy.getIn(['strategy', 'id'])
          );
          const value = currentStrategy.has('operf')
            ? parseFloat(currentStrategy.get('operf') * 100).toFixed(2)
            : null;
          const simValue = parseFloat(
            currentStrategy.get('operf_simulation') * 100
          ).toFixed(2);

          return (
            <td
              key={`${strategyIndex}-${fieldIndex}`}
              className={classNames(
                styles['bench-colored-field'],
                styles[getProgressValues(value || simValue).colorString]
              )}
            >
              <strong>{Math.round(value || simValue)}%</strong>
              <br />
              {value && (
                <span style={{ fontSize: '13px' }}>
                  (
                  <FormattedMessage id="SIMULATION" />:{Math.round(simValue)}
                  %)
                </span>
              )}
            </td>
          );
        }
      })
    );

    return {
      customClassName: 'bench-table',
      data: final,
      fields
    };
  };

  const getStrategiesTable = () => {
    const fields = [
      {
        key: 'strategy_name',
        sortable: false,
        renderField: (item) => (item ? item.getIn(['strategy', 'name']) : '')
      },
      {
        key: 'description',
        sortable: false,
        renderField: (item) =>
          item ? item.getIn(['strategy', 'description']) : '',
        width: 30
      },
      {
        key: 'behaviors',
        sortable: false,
        width: 30,
        renderField: (item) =>
          item && item.has('behaviors')
            ? item.get('behaviors').map((b) => <div>{b.get('name')}</div>)
            : null
      }
    ];

    return {
      data: strategies != null ? strategies.toArray() : [],
      customClassName: 'strategies-table',
      fields
    };
  };

  return (
    <div>
      <Alert className={styles['analytics-infobox']} color="info" isOpen>
        <FormattedMessage id="BENCH_INFO" />
      </Alert>

      {selectedAssessment && strategies != null && (
        <React.Fragment>
          <div className="html2pdf__page-break">
            <BuildTable table={getTable()} />
          </div>

          <div
            className={`${styles['strategies-table-wrapper']} html2pdf__page-break`}
          >
            <h6>
              <FormattedMessage id="KEY_TO_MAPPED_BEHAVIORS" />:
            </h6>
            <BuildTable table={getStrategiesTable()} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default connect((state) => ({
  route: state.router.route
}))(
  React.memo(
    getBench,
    (prevProps, nextProps) =>
      prevProps?.data?.size === nextProps?.data?.size &&
      prevProps?.route?.query?.assessment ===
        nextProps?.route?.query?.assessment
  )
);
