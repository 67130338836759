import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Alert, Button, ButtonGroup } from 'reactstrap';
import { BuildTable } from 'components';
import { getCurrentAssessmentId } from '../../../components/Folders/components/actions';

import styles from '../styles.css';
import '../styles.css';
import { getProgressValues } from '../../../../../helpers';

const getGaps = (props, context) => {
  const { data, route } = props;
  const selectedAssessment = route.query.assessment;
  const todayData = [];
  const [selected, setSelected] = useState(1);
  const filteredAssessmentData = data.filter(
    (r) => getCurrentAssessmentId(r) === selectedAssessment?.toString()
  );

  filteredAssessmentData
    ?.first()
    ?.getIn(['simulation', 'default', 'behaviors'])
    ?.map((behavior) => {
      const lowSeries = [];
      const mediumSeries = [];
      const highSeries = [];

      filteredAssessmentData.map((r) => {
        const current = r
          ?.getIn(['simulation', 'default', 'behaviors'])
          ?.find((b) => b.get('id') === behavior.get('id'));

        if (current) {
          const compareValue =
            selected === 1
              ? current.get('tbis_perc')
              : current.get('tbis_perc') + (current.get('improvement') || 0);

          if (getProgressValues(compareValue).position === 'LOW') {
            lowSeries.push(current);
          }
          if (getProgressValues(compareValue).position === 'MEDIUM') {
            mediumSeries.push(current);
          }
          if (getProgressValues(compareValue).position === 'HIGH') {
            highSeries.push(current);
          }
        }

        return true;
      });

      return todayData.push({
        lowSeries,
        mediumSeries,
        highSeries,
        behavior: behavior.get('name'),
        definition: behavior.get('definition')
      });
    });

  const finalBehaviors = todayData
    .sort((a, b) => {
      if (a.lowSeries.length > b.lowSeries.length) {
        return -1;
      }

      if (a.lowSeries.length < b.lowSeries.length) {
        return 1;
      }

      if (a.mediumSeries.length > b.mediumSeries.length) {
        if (a.highSeries.length > b.highSeries.length) {
          return 1;
        }

        return -1;
      }

      if (a.mediumSeries.length < b.mediumSeries.length) {
        if (a.highSeries.length < b.highSeries.length) {
          return -1;
        }

        return 1;
      }

      if (a.behavior > b.behavior) {
        return 1;
      }

      if (a.behavior < b.behavior) {
        return -1;
      }

      return 0;
    })
    .map((i) => i);

  const translations = {
    LOW: context.intl.formatMessage({
      id: 'LOW'
    }),
    MEDIUM: context.intl.formatMessage({
      id: 'MEDIUM'
    }),
    HIGH: context.intl.formatMessage({
      id: 'HIGH'
    })
  };

  const colors = {
    [translations.HIGH]: '#000000',
    [translations.MEDIUM]: '#000000',
    [translations.LOW]: '#ffffff'
  };

  const series = [
    {
      name: context.intl.formatMessage({
        id: 'HIGH'
      }),
      color: '#2fbc88',
      data: finalBehaviors.map((i) => i.highSeries.length),
      showInLegend: true
    },
    {
      name: context.intl.formatMessage({
        id: 'MEDIUM'
      }),
      color: '#f9cb36',
      data: finalBehaviors.map((i) => i.mediumSeries.length),
      showInLegend: true
    },
    {
      name: context.intl.formatMessage({
        id: 'LOW'
      }),
      color: '#001c50',
      data: finalBehaviors.map((i) => i.lowSeries.length),
      showInLegend: true
    }
  ];

  const config = {
    chart: {
      height: 500,
      type: 'column'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: finalBehaviors.map((i) => i.behavior),
      title: {
        text: context.intl.formatMessage({
          id: 'GAPS_PARTICIPANTS_SKILLS'
        }),
        style: {
          fontSize: 14
        },
        y: 10
      }
    },
    yAxis: [
      {
        min: 0,
        max: filteredAssessmentData.size,
        title: {
          text: context.intl.formatMessage({
            id: 'PARTICIPANTS_WITH_GAP'
          }),
          style: {
            fontSize: 14
          },
          x: -10
        },
        stackLabels: {
          enabled: false
        },
        labels: {
          formatter() {
            return filteredAssessmentData.size === 1 ? '' : this.value;
          }
        },
        allowDecimals: false
      }
    ],
    legend: {
      useHTML: true,
      shadow: false,
      padding: 10,
      borderWidth: 1,
      reversed: true,
      margin: 30,
      itemStyle: {
        fontWeight: 'normal'
      },
      title: {
        text: `<div style="width: 320px; text-align: center; font-weight: normal; margin-bottom: 5px;">${context.intl.formatMessage(
          {
            id: 'performance'
          }
        )}</div>`
      }
    },
    tooltip: {
      shared: true,
      borderColor: '#999999',
      useHTML: true,
      headerFormat:
        '<table><tr><td style="text-align: center;">' +
        '<span style="font-size: 14px;">{point.key}</span>' +
        '</td></tr><tr><td style="text-align: center; padding-top: 5px;">',
      pointFormat:
        '<span style="color:{point.color};"> \u25CF </span>' +
        '<span style="font-size: 12px; vertical-align: middle; line-height: 20px;"> {point.y} {point.series.name} </span>',
      footerFormat: '</td></tr></table>'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: false,
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter() {
            return this.y !== 0
              ? `<span style="color:${colors[this.series.name]}">${
                  this.y
                }</span>`
              : '';
          },
          style: {
            textOutline: 0
          }
        }
      }
    },
    series,
    credits: {
      enabled: false
    }
  };

  const getTable = () => {
    const fields = [
      {
        key: 'skill',
        sortable: false,
        renderField: (item) => <strong>{item.behavior}</strong>
      },
      {
        key: 'definition',
        sortable: false,
        renderField: (item) => item.definition
      }
    ];

    return {
      data: finalBehaviors,
      fields
    };
  };

  return (
    <div>
      {selectedAssessment && (
        <React.Fragment>
          <Alert className={styles['analytics-infobox']} color="info" isOpen>
            <FormattedHTMLMessage
              id={
                filteredAssessmentData.size === 1
                  ? 'GAPS_INFO_INDIVIDUAL'
                  : 'GAPS_INFO'
              }
            />
          </Alert>

          {filteredAssessmentData.size > 0 && (
            <div className="text-center">
              <h4 className={styles['analytics-section']}>
                <FormattedMessage id="ASSESSMENT" />:{' '}
                {filteredAssessmentData.first().get('assessment_name')}
              </h4>
            </div>
          )}

          <div className="text-center">
            <ButtonGroup>
              <Button
                active={selected === 1}
                outline
                color="primary"
                size="lg"
                onClick={() => setSelected(1)}
              >
                <FormattedMessage id="TABLE_HEADING.simulation" />
              </Button>{' '}
              {data.find((r) => r.get('is_improvement_visible') === true) && (
                <Button
                  active={selected === 2}
                  outline
                  color="primary"
                  size="lg"
                  onClick={() => setSelected(2)}
                >
                  <FormattedMessage id="TABLE_HEADING.today-after-improvement" />
                </Button>
              )}
            </ButtonGroup>
          </div>

          <div
            style={{ margin: '40px 0' }}
            dir="ltr"
            className="html2pdf__page-break"
          >
            <ReactHighcharts config={config} />
          </div>

          <div className="html2pdf__page-break">
            <BuildTable table={getTable()} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

getGaps.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect((state) => ({
  route: state.router.route
}))(
  React.memo(
    getGaps,
    (prevProps, nextProps) =>
      prevProps?.data?.size === nextProps?.data?.size &&
      prevProps?.route?.query?.assessment ===
        nextProps?.route?.query?.assessment
  )
);
