import request from 'superagent-bluebird-promise';
import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE
} from 'constants/actionTypes';

import { setProgress } from 'actions/global';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from '../../constants/cookies';

/**
 * Upload file action
 *
 * @param{File} file
 * @param{Function} dispatch
 * @param{String} content
 * @returns {{types: *[], promise: Request }}
 */

export default function uploadFile(file, dispatch, content) {
  const formData = new FormData();
  formData.append('file', file);

  return {
    types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
    promise: request
      .post(
        `${process.env.REACT_APP_APIURL}/api/v1/files/upload/?content=${content}`
      )
      .send(formData)
      .set('Authorization', `Bearer ${getCookie(AUTH_TOKEN)}`)
      .on('progress', (e) => {
        dispatch(setProgress(file.name, e.percent));
      })
  };
}
