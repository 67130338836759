import {
  CREATE_RELATIONSHIP_REQUEST,
  CREATE_RELATIONSHIP_SUCCESS,
  CREATE_RELATIONSHIP_FAILURE,
  EDIT_RELATIONSHIP_REQUEST,
  EDIT_RELATIONSHIP_SUCCESS,
  EDIT_RELATIONSHIP_FAILURE,
  DELETE_RELATIONSHIP_REQUEST,
  DELETE_RELATIONSHIP_SUCCESS,
  DELETE_RELATIONSHIP_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Relationships action
 *
 * @param{Object} body
 * @returns {{types: *[], promise: Request }}
 */

export default function relationships(body) {
  let types;
  let promise;
  const { type } = body;
  const { behaviorId } = body;
  const { data } = body;
  const { id } = body;

  switch (type) {
    case 'create':
      types = [
        CREATE_RELATIONSHIP_REQUEST,
        CREATE_RELATIONSHIP_SUCCESS,
        CREATE_RELATIONSHIP_FAILURE
      ];
      promise = api().put(`/api/v1/behaviors/${behaviorId}/facets/`, data);
      break;
    case 'edit':
      types = [
        EDIT_RELATIONSHIP_REQUEST,
        EDIT_RELATIONSHIP_SUCCESS,
        EDIT_RELATIONSHIP_FAILURE
      ];
      promise = api().put(`/api/v1/behaviors/${behaviorId}/facets/`, data);
      break;
    case 'delete':
      types = [
        DELETE_RELATIONSHIP_REQUEST,
        DELETE_RELATIONSHIP_SUCCESS,
        DELETE_RELATIONSHIP_FAILURE
      ];
      promise = api().del(`/api/v1/behaviors/${behaviorId}/facets/${id}/`);
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    behaviorId,
    id
  };
}
