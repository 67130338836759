import React from 'react';
import classNames from 'classnames';

import styles from './styles.css';
import './styles.css';

const ColorIndicator = (props) => {
  return (
    <span
      key="color-indicator"
      className={classNames(
        styles['color-indicator'],
        styles[props.type],
        styles[props.color]
      )}
    />
  );
};

export default ColorIndicator;
