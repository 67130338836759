import PropTypes from 'prop-types';
import React from 'react';

const SIZER_STYLES = {
  position: 'absolute',
  width: 0,
  height: 0,
  visibility: 'hidden',
  overflow: 'scroll',
  whiteSpace: 'pre'
};

const STYLE_PROPS = [
  'fontSize',
  'fontFamily',
  'fontWeight',
  'fontStyle',
  'letterSpacing'
];

export default class Input extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    suggestions: PropTypes.arrayOf(PropTypes.object),
    autofocus: PropTypes.bool,
    autoresize: PropTypes.bool,
    classNames: PropTypes.object,
    query: PropTypes.string,
    expandable: PropTypes.bool,
    listboxId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedIndex: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = { inputWidth: null };
  }

  componentDidMount() {
    if (this.props.autoresize) {
      this.copyInputStyles();
      this.updateInputWidth();
    }

    if (this.props.autofocus) {
      this.input.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.input.value !== newProps.query) {
      this.input.value = newProps.query;
    }
  }

  componentDidUpdate() {
    this.updateInputWidth();
  }

  copyInputStyles() {
    const inputStyle = window.getComputedStyle(this.input);

    STYLE_PROPS.forEach((prop) => {
      this.sizer.style[prop] = inputStyle[prop];
    });
  }

  updateInputWidth() {
    let inputWidth;

    if (this.props.autoresize) {
      // scrollWidth is designed to be fast not accurate.
      // +2 is completely arbitrary but does the job.
      inputWidth = Math.ceil(this.sizer.scrollWidth) + 2;
    }

    if (inputWidth !== this.state.inputWidth) {
      this.setState({ inputWidth });
    }
  }

  render() {
    const {
      disabled,
      query,
      placeholder,
      expandable,
      listboxId,
      selectedIndex
    } = this.props;

    return (
      <div className={this.props.classNames.searchInput}>
        <input
          ref={(c) => {
            this.input = c;
          }}
          id="tags_input"
          value={query}
          placeholder={placeholder}
          role="combobox"
          aria-autocomplete="list"
          aria-label={placeholder}
          aria-owns={listboxId}
          aria-activedescendant={
            selectedIndex > -1 ? `${listboxId}-${selectedIndex}` : null
          }
          onChange={() => false}
          aria-expanded={expandable}
          style={{ width: this.state.inputWidth }}
          disabled={disabled}
          autoComplete="off"
        />
        <div
          ref={(c) => {
            this.sizer = c;
          }}
          style={SIZER_STYLES}
        >
          {query || placeholder}
        </div>
      </div>
    );
  }
}
