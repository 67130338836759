// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';

// Styles
import modals from '../../views/modals.css';
import '../../views/modals.css';

/**
 * Confirm delete modal
 * @class
 */
class ConfirmDelete extends Component {
  // Prop types
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    header: PropTypes.string,
    message: PropTypes.string,
    submitAction: PropTypes.func
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   */
  handleSubmit = () => {
    const { onClose, data, action, dispatch } = this.props;
    const { addAlert, removeModal } = this.context;

    if (data.facet) {
      dispatch(action(data.id, { facets: data.facets })).then((action) => {
        if (!action.error) {
          addAlert({
            color: 'success',
            content: <FormattedMessage id="SUCCESSFULLY_DELETED" />
          });
          removeModal();
        } else {
          window.scrollTo(0, 0);
          const error = action.error.body;
          addAlert({
            color: 'danger',
            content: error || <FormattedMessage id="ERROR" />
          });
        }
      });
    } else {
      dispatch(action(data)).then((action) => {
        if (!action.error) {
          addAlert({
            color: 'success',
            content: <FormattedMessage id="SUCCESSFULLY_DELETED" />
          });
          removeModal();
        } else {
          window.scrollTo(0, 0);
          const error = action.error.body;
          addAlert({
            color: 'danger',
            content: error || <FormattedMessage id="ERROR" />
          });
        }
      });
    }

    onClose();
  };

  render() {
    const { onClose, header, message, submitAction } = this.props;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          {header || <FormattedMessage id="CONFIRM_DELETE" />}
        </ModalHeader>
        <ModalBody
          className={modals['modal-body']}
          style={{ textAlign: 'justify' }}
        >
          <div>
            {message || <FormattedMessage id="CONFIRM_DELETE_MESSAGE" />}
          </div>
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_delete"
                name="cancel_delete"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_delete"
                name="submit_delete"
                color="danger"
                onClick={submitAction || this.handleSubmit}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CONFIRM" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(ConfirmDelete);
