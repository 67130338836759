/* eslint-disable global-require */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { View } from 'routex/lib/react';

import './normalize.css'; // eslint-disable-line

import * as translations from 'translations';

import { setLocale, setDirection as setDirectionAction } from 'actions/global';
import { getParameterByName } from 'routes/actions';
import { isInIframe } from '../../helpers/isInIframeCheck';

const zohoChatAllowedRoles = ['admin', 'assessor', 'participant'];

@connect(
  (state) => ({
    router: state.router,
    locale: state.app.get('locale'),
    user: state.auth.get('loggedUser'),
    direction: state.app.get('direction')
  }),
  (dispatch) => bindActionCreators({ setLocale, setDirectionAction }, dispatch)
)
export default class App extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    setDirectionAction: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    direction: PropTypes.string.isRequired
  };

  state = {
    zohoScriptUserID: null
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Do not translate login screen while transitioning
    if (
      nextProps.router &&
      nextProps.router.route &&
      !(
        nextProps.router.route.pathname === '/auth/login' &&
        nextProps.router.state === 'TRANSITIONING'
      ) &&
      nextProps.router.route.pathname !== '/auth/login' &&
      nextProps.user &&
      nextProps.user.get('active_role') === 'participant'
    ) {
      this.setLanguage(nextProps.user.get('language'), null, nextProps);
    } else if (getParameterByName('lang')) {
      this.setLanguage(getParameterByName('lang'), true, nextProps);
    } else {
      this.setLanguage('en', null, nextProps);
    }
  }

  insertZohoScript = (nextProps) => {
    if (isInIframe || nextProps == null) {
      return;
    }

    const url = '/assets/js/zoho.js';
    const zohoScriptKey = `${nextProps.user.get('id')}_${nextProps.locale}`;

    if (this.state.zohoScriptUserID !== zohoScriptKey) {
      setTimeout(() => {
        if (document.getElementById(url) != null) {
          const script = document.getElementById(url);
          script.parentElement.removeChild(script);
        }

        if (
          zohoChatAllowedRoles.indexOf(this.props.user.get('active_role')) >
            -1 ||
          nextProps?.router?.route?.pathname === '/auth/login'
        ) {
          const script = document.createElement('script');

          script.id = url;
          script.src = `${url}?v=${nextProps.user.get('language')}`;
          script.async = true;
          script.onload = () => {
            document.getElementById('chat-btn').style.display = 'inline-block';
          };

          document.body.appendChild(script);
          this.setState({
            zohoScriptUserID: zohoScriptKey
          });
        }
      }, 2000);
    }
  };

  setLanguage = (lang, query, nextProps) => {
    const { setLocale, user } = this.props;

    if (
      lang !== 'en' &&
      lang !== 'english' &&
      (user.get('rtl_direction') || (query && lang === 'hebrew'))
    ) {
      this.setDirection('rtl');
    } else {
      // Reset rtl layout
      // eslint-disable-next-line no-lonely-if
      if (this.props.direction === 'rtl') {
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    }

    switch (lang) {
      case 'en':
      case 'english':
        setLocale('en');
        break;
      case 'es':
      case 'spanish':
        require('moment/locale/es');
        setLocale('es');
        break;
      case 'pt':
      case 'portuguese':
        require('moment/locale/pt');
        setLocale('pt');
        break;
      case 'he':
      case 'hebrew':
        require('moment/locale/he');
        setLocale('he');
        break;
      case 'sk':
      case 'slovak':
        require('moment/locale/sk');
        setLocale('sk');
        break;
      case 'nl':
      case 'dutch':
        require('moment/locale/nl');
        setLocale('nl');
        break;
      case 'fr':
      case 'french':
        require('moment/locale/fr');
        setLocale('fr');
        break;
      case 'tr':
      case 'turkish':
        require('moment/locale/tr');
        setLocale('tr');
        break;
      case 'zh':
      case 'chinese':
        require('moment/locale/zh-cn');
        setLocale('zh');
        break;
      case 'ko':
      case 'korean':
        require('moment/locale/ko');
        setLocale('ko');
        break;
      case 'id':
      case 'indonesian':
        require('moment/locale/id');
        setLocale('id');
        break;
      case 'ja':
      case 'japanese':
        require('moment/locale/ja');
        setLocale('ja');
        break;
      case 'ar-sa':
      case 'saudi-arabic':
        require('moment/locale/ar-sa');
        setLocale('ar-sa');
        break;
      case 'de':
      case 'german':
        require('moment/locale/de');
        setLocale('de');
        break;
      default:
        setLocale('en');
        break;
    }

    this.insertZohoScript(nextProps);
  };

  setDirection = (dir) => {
    const { setDirectionAction } = this.props;

    const interval = setInterval(() => {
      // eslint-disable-next-line
      if (layout) {
        // eslint-disable-next-line
        layout.setDirection(dir);
        setDirectionAction('rtl');
        clearInterval(interval);
      }
    }, 10);
  };

  render() {
    const { locale } = this.props;

    return (
      <IntlProvider
        key={locale}
        locale={locale}
        defaultLocale={locale}
        messages={translations[locale.replace('-', '')]}
      >
        <View />
      </IntlProvider>
    );
  }
}
