import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';

import { FilterForm } from 'forms';
import { ExpandRow } from 'components';

import styles from './styles.css';
import './styles.css';

import { filterData, resetFilter } from 'actions/analytics';
import { Alert } from 'reactstrap';
import { updateCheckboxes } from '../Folders/components/actions';

const form = 'filter';

@connect(
  (state) => ({
    allData: state.analytics.get('allData'),
    user: state.auth.get('loggedUser'),
    assessments: formValueSelector(form)(state, 'assessments'),
    clients: formValueSelector(form)(state, 'clients'),
    partners: formValueSelector(form)(state, 'partners'),
    tags: formValueSelector(form)(state, 'tags'),
    search: formValueSelector(form)(state, 'search'),
    from: formValueSelector(form)(state, 'from'),
    to: formValueSelector(form)(state, 'to'),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        change,
        transitionTo,
        filterData,
        resetFilter
      },
      dispatch
    );
  }
)
class Filter extends Component {
  static propTypes = {
    allData: PropTypes.object,
    user: PropTypes.object.isRequired,
    assessments: PropTypes.array,
    clients: PropTypes.array,
    partners: PropTypes.array,
    tags: PropTypes.array,
    change: PropTypes.func.isRequired,
    search: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    resetFilter: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired
  };

  onFilter = async (props) => {
    const { filterData, transitionTo } = props;

    await transitionTo('/analytics', {
      tab: null,
      selected: null,
      current: null,
      assessment: null,
      scroll: 0,
      filtered: 1
    });

    filterData(props);
  };

  onResetFilter = async () => {
    const { resetFilter, transitionTo } = this.props;

    await transitionTo('/analytics', {
      tab: null,
      selected: null,
      current: null,
      assessment: null,
      scroll: 0,
      filtered: 0
    });

    updateCheckboxes(this.props, [], 0);

    resetFilter();
  };

  onUnmount = () => {
    const { resetFilter } = this.props;

    resetFilter();
  };

  render() {
    return (
      <div
        className={classNames(
          styles.filter,
          this.props.user.get('isClient') && styles.client
        )}
      >
        <ExpandRow
          header={
            <h5>
              <FormattedMessage id="FILTER" />
            </h5>
          }
          body={
            <div>
              <div className={styles['filter-alert']}>
                <Alert color="info">
                  <FormattedMessage id="ANALYTICS_FILTER_NOTE" />
                </Alert>
              </div>

              <FilterForm
                onFilter={() => {
                  setTimeout(() => {
                    this.onFilter(this.props);
                  }, 50);
                }}
                onResetFilter={() => {
                  this.onResetFilter();
                }}
                onUnmount={this.onUnmount}
              />
            </div>
          }
          noBorder
          rowWithTopBorder
          defaultOpen
        />
      </div>
    );
  }
}

export default Filter;
