// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

// Styles
import modals from '../../../views/modals.css';
import '../../../views/modals.css';

/**
 * Reset password modal
 * @constructor
 */
const ResetPasswordModal = (props, context) => {
  const { removeModal } = context;
  const { message, submit } = props;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id="RESET_PASSWORD" />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <FormattedMessage id={message} />
      </ModalBody>

      <ModalFooter>
        <Row>
          <Col xs={5} className={modals['modal-button-col']}>
            <Button
              id="cancel_reset_password"
              name="cancel_reset_password"
              color="secondary"
              onClick={removeModal}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CANCEL" />
            </Button>{' '}
          </Col>
          <Col xs={7} className={modals['modal-button-col']}>
            <Button
              id="submit_reset_password"
              name="submit_reset_password"
              color="primary"
              onClick={() => {
                submit();
                removeModal();
              }}
              className={modals['footer-button']}
            >
              <FormattedMessage id="RESET_PASSWORD" />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ResetPasswordModal.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

ResetPasswordModal.propTypes = {
  message: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired
};

export default ResetPasswordModal;
