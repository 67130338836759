// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Button, Alert } from 'reactstrap';
import { StageProgress } from 'components';

// Actions
import { getAfterSimulationProgress } from '../Auth/Invitation/steps/actions';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Dashboard
 * @constructor
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class Dashboard extends Component {
  // Prop types
  static propTypes = {
    user: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  /**
   * Get content
   * @returns {XML}
   */
  getContent = () => {
    const { user, transitionTo } = this.props;
    const hasPsychometrics =
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'has_psychometrics']);

    const isInPrework =
      user.has('stages') &&
      (user.getIn(['stages', 'pre-work1', 'completed']) === false ||
        user.getIn(['stages', 'pre-work2', 'completed']) === false) &&
      hasPsychometrics;

    if (isInPrework) {
      return (
        <div>
          <Alert color="info" className={styles.alert}>
            <img src="/assets/img/info-yellow.png" alt="info" />
            <strong>
              <FormattedMessage id="SIMULATION_PREWORK_WARNING" />
            </strong>
          </Alert>

          <StageProgress stages={getAfterSimulationProgress(user)} />

          <div className={styles['button-block']}>
            <Button
              color="primary"
              type="button"
              onClick={() => transitionTo('/auth/invite/prework/questionnaire')}
              className={styles['next-button']}
            >
              <FormattedMessage id="NEXT" />
            </Button>
          </div>
        </div>
      );
    }

    if (user.has('active_simulation') && !user.get('has_shared_report')) {
      return (
        <div>
          <Alert color="success">
            <FormattedMessage id="BEFORE_DEVELOPMENT_INFO" />
          </Alert>
        </div>
      );
    }

    return (
      <div>
        <Alert color="success">
          <FormattedMessage id="BEFORE_DEVELOPMENT_INFO" />
        </Alert>
      </div>
    );
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    return <div>{this.getContent()}</div>;
  }
}
