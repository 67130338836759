import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (reports, current, props, context) => {
  const {
    shareWith,
    shareWithParticipant,
    shareReport,
    recipients,
    analyticsShareUsers
  } = props;

  const { addAlert, removeModal } = context;
  const report = reports[0];

  const allStakeholders = analyticsShareUsers
    ?.get(report.getIn(['sharing', 'active_simulation']))
    ?.toArray()
    ?.concat(report.getIn(['sharing', 'stakeholders'])?.toArray());

  const data = {
    active_simulation: report.getIn(['sharing', 'active_simulation']),
    is_enabled_self: shareWith && shareWithParticipant,
    is_enabled_stakeholders: true,
    stakeholders: shareWith
      ? recipients.filter((r) => r.id != null).map((r) => r.id)
      : []
  };

  shareReport(data, report.get('id'), {
    ...data,
    is_shareable: true,
    stakeholders: shareWith
      ? recipients
          .filter((r) => r.id != null)
          .map((r) => allStakeholders.find((s) => s.get('id') === r.id))
      : []
  }).then((action) => {
    window.scrollTo(0, 0);
    removeModal();
    if (!action.error) {
      addAlert({
        color: 'success',
        content: <FormattedMessage id="SUCCESSFULLY_SHARED" />
      });
    } else {
      addAlert({
        color: 'danger',
        content: JSON.stringify(action.error)
      });
    }
  });
};
