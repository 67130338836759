// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import { Button, Input, Col, Row } from 'reactstrap';

// Styles
import styles from '../../styles.css';

/**
 * Translate row form
 * @class
 */
export default class TranslateRowForm extends Component {
  // Default props
  static defaultProps = {
    id: 'default'
  };

  // Prop types
  static propTypes = {
    id: PropTypes.string,
    change: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { defaultValue } = this.props;

    this.state = {
      data: {
        translation: defaultValue
      }
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this.focusTimeout = setTimeout(() => {
      const length = this.input.value ? this.input.value.length : 0;
      this.input.focus();
      this.input.setSelectionRange(length, length);
    }, 50);
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  /**
   * Handle submit
   * @param e
   */
  handleSubmit = (e) => {
    const { onSubmit } = this.props;
    const { data } = this.state;
    e.preventDefault();
    onSubmit(data);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { data } = this.state;
    const { id } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        <Row>
          <Col xs={10}>
            <span className={styles['input-outer']}>
              <button
                type="button"
                tabIndex="-1"
                className={styles['cancel-button']}
                onClick={() => {
                  this.setState({ data: { translation: '' } });
                  this.input.focus();
                }}
              />
              <Input
                id={`translation-input-${id}`}
                name={`translation-input-${id}`}
                type="text"
                value={data.translation}
                onChange={(e) =>
                  this.setState({ data: { translation: e.target.value } })
                }
                getRef={(i) => (this.input = i)}
              />
            </span>
          </Col>
          <Col xs={2}>
            <Button
              id={`done-button-${id}`}
              name={`done-button-${id}`}
              className={styles['done-button']}
              color="link"
              type="button"
              onClick={this.handleSubmit}
            >
              <FormattedMessage id="DONE" />
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
