import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import { Button } from 'reactstrap';
import { NotificationPreferencesBlock } from '../../forms/blocks';
import { validate } from './validate';

import styles from '../../forms/styles.css';
import '../../forms/styles.css';

const form = 'notificationEmailPreferencesForm';

const NotificationEmailPreferences = (props) => {
  return (
    <form className={styles.form} onSubmit={props.handleSubmit}>
      <NotificationPreferencesBlock
        initialValue={props.initialValue}
        form={form}
      />

      <Button
        color="primary"
        style={{ marginTop: 20 }}
        disabled={props.isLoading}
      >
        <FormattedMessage id="UPDATE" />
      </Button>
    </form>
  );
};

NotificationEmailPreferences.propTypes = {
  isLoading: PropTypes.bool,
  initialValue: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form,
  validate
})(NotificationEmailPreferences);
