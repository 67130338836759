import {
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Delete user action
 *
 * @param{String} id
 * @returns {{types: *[], promise: Request }}
 */

export default function deleteUser(id) {
  return {
    types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    promise: api().del(`/api/v1/users/${id}/`),
    id
  };
}
