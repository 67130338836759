/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change } from 'redux-form';

// Styles
import { Button, Col, Row } from 'reactstrap';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components
import { AccountOwner, TimeAndLanguage, MarketingConsent } from '../../blocks';

// Actions
import { getUserFields } from '../../actions';

// Const
const form = 'createProfileForm';

/**
 * Create stakeholder form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    isLoading: state.users.get('createUserLoading'),
    loggedUser: state.auth.get('loggedUser'),
    codeList: state.app.get('codeList'),
    simulations: state.simulations.get('simulations').toArray(),
    debriefs: state.debriefs.get('debriefs').toArray()
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class CreateProfileForm extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    loggedUser: PropTypes.object,
    invitation: PropTypes.bool,
    codeList: PropTypes.object,
    simulations: PropTypes.object,
    debriefs: PropTypes.object
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const {
      change,
      loggedUser,
      invitation,
      simulations,
      debriefs,
      codeList
    } = this.props;

    if (invitation) {
      change(form, 'email', loggedUser.get('email'));
    }

    if (loggedUser) {
      getUserFields(form, loggedUser, change, {
        debriefs,
        simulations,
        languages: codeList.get('language_published').toArray()
      });
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { handleSubmit, isLoading, invitation, loggedUser } = this.props;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Basic informations */}
        {
          <AccountOwner
            invitation={invitation}
            user={loggedUser}
            form={form}
            header="BASIC_INFORMATION"
          />
        }

        {/* Time and language */}
        <TimeAndLanguage form={form} />

        {/* Marketing consent block */}
        <MarketingConsent />

        {invitation ? (
          <Row className={styles['form-buttons']}>
            <Col xs={5} className={styles['btn-col']}>
              <Button
                id="next_create_profile_button"
                name="next_create_profile_button"
                type="button"
                color="primary-dark"
                onClick={handleSubmit}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'NEXT'} />
              </Button>
            </Col>
          </Row>
        ) : (
          <Row className={styles['form-buttons']}>
            <Col xs={2} className={styles['btn-col']}>
              <Button
                id="cancel_create_profile_form"
                name="cancel_create_profile_form"
                type="button"
                color="secondary"
              >
                <FormattedMessage id="CANCEL" />
              </Button>
            </Col>
            <Col xs={3} className={styles['btn-col']}>
              <Button
                id="submit_create_profile_form"
                name="submit_create_profile_form"
                type="button"
                color="primary"
                onClick={handleSubmit}
              >
                <FormattedMessage
                  id={isLoading ? 'LOADING' : 'CREATE_PROFILE'}
                />
              </Button>
            </Col>
          </Row>
        )}
      </form>
    );
  }
}
