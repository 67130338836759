// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import styles from './styles.css';
import './styles.css';

const VirtualLink = (props) => {
  let virtualLinkClass = 'default';

  switch (props.type) {
    case 'default':
      virtualLinkClass = 'default';
      break;
    case 'inherit':
      virtualLinkClass = 'inherit';
      break;
    case 'no-decoration':
      virtualLinkClass = 'no-decoration';
      break;
    default:
      break;
  }

  return (
    <div
      id={props.id}
      style={props.style}
      className={classNames(styles[virtualLinkClass], props.className)}
    >
      {props.children}
    </div>
  );
};

VirtualLink.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string
};

export default VirtualLink;
