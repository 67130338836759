// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Col, InputGroup, Row } from 'reactstrap';

// Actions
import { fetchPartners } from 'actions/users';
import { renderInput } from '../actions';

// Const
const selectPartnerField = 'partner_id';
const nameField = 'name';

@connect(
  (state) => ({
    client: state.clients.getIn([
      'allClients',
      Number(state.router.route.vars.id)
    ]),
    user: state.auth.get('loggedUser'),
    partners: state.partners.has('allPartners')
      ? state.partners.get('allPartners').toArray()
      : [],
    partnersTotal: state.partners.get('partnersTotal') || 0,
    users: state.users.has('allUsers')
      ? state.users.get('allUsers').toArray()
      : [],
    loading: state.users.get('usersLoading')
  }),
  (dispatch) => bindActionCreators({ fetchPartners }, dispatch)
)
export default class ClientBasic extends Component {
  // Prop types
  static propTypes = {
    partners: PropTypes.object,
    partnersTotal: PropTypes.number,
    users: PropTypes.object,
    owner: PropTypes.object,
    user: PropTypes.object,
    client: PropTypes.object,
    fetchPartners: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { fetchPartners, partners, partnersTotal } = this.props;

    if (partnersTotal === 0 || partners.length < partnersTotal) {
      fetchPartners(0, partnersTotal);
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { partners, users, user, owner, loading } = this.props;

    return (
      <Row>
        <Col xs={12}>
          <h5>
            <FormattedMessage id="BASIC_INFORMATION" />
          </h5>
        </Col>
        <Col xs={5}>
          {!user.get('isPartner') && (
            <InputGroup>
              <label htmlFor={selectPartnerField}>
                <FormattedMessage id={`FORM_FIELDS.${selectPartnerField}`} />
              </label>
              <Field
                name={selectPartnerField}
                component={renderInput}
                type="select"
                valueKey="id"
                optionKey="name"
                options={partners}
                placeholder="Pinsight"
                nullValue="null"
              />
            </InputGroup>
          )}
          <InputGroup>
            <label htmlFor={nameField}>
              <FormattedMessage id={`FORM_FIELDS.client_${nameField}`} /> (
              <FormattedMessage id="REQUIRED" />)
            </label>
            <Field name={nameField} component={renderInput} type="text" />
          </InputGroup>
          {owner && !user.get('isPartner') && (
            <InputGroup>
              <label htmlFor="owner">
                <FormattedMessage id="ACCOUNT_OWNER" />
              </label>
              <Field
                loading={loading}
                name="owner_id"
                component={renderInput}
                type="select"
                valueKey="id"
                optionKey="fullname"
                options={users.filter(
                  (u) =>
                    this.props.client &&
                    u.get('client_id') === this.props.client.get('id') &&
                    u.get('roles').indexOf('admin') > -1
                )}
                noEmpty={!loading}
              />
            </InputGroup>
          )}
        </Col>
      </Row>
    );
  }
}
