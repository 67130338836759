import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';
import { BuildTable, ColorIndicator } from 'components';
import { UserBox } from '../../../components/Folders/components';

import { get9BoxReadiness, orderMapByArrayValue } from 'helpers';
import { reportsOfOneAssessment } from '.';

import styles from '../styles.css';
import '../styles.css';

const getReadiness = (props) => {
  const getTable = () => {
    const data = props.data.toArray().filter((analyticsData) => {
      // Only analytics with performance
      // And assessment has "readiness" analytics
      return (
        analyticsData.toJS().simulation?.default?.operf &&
        analyticsData.get('assessment_sections').includes('readiness')
      );
    });

    const final = [
      {
        key: 'ready_now',
        simulation: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            let ret = false;
            if (get9BoxReadiness(perf, pot) === 'ready_now') {
              ret = true;
            }
            return ret;
          })
          .map((item) => item),
        afterImprovement: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            const improvement =
              r.getIn(['simulation', 'default', 'improvement']) || 0;

            let ret = false;
            if (
              get9BoxReadiness(perf + improvement, pot) === 'ready_now' &&
              r.get('is_improvement_visible') === true
            ) {
              ret = true;
            }
            return ret;
          })
          .map((item) => item)
      },
      {
        key: 'needs_3-6',
        simulation: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            let ret = false;
            if (get9BoxReadiness(perf, pot) === 'ready_6') {
              ret = true;
            }
            return ret;
          })
          .map((item) => item),
        afterImprovement: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            const improvement =
              r.getIn(['simulation', 'default', 'improvement']) || 0;

            let ret = false;
            if (
              get9BoxReadiness(perf + improvement, pot) === 'ready_6' &&
              r.get('is_improvement_visible') === true
            ) {
              ret = true;
            }
            return ret;
          })
          .map((item) => item)
      },
      {
        key: 'needs_7-12',
        simulation: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            let ret = false;
            if (get9BoxReadiness(perf, pot) === 'ready_12') {
              ret = true;
            }
            return ret;
          })
          .map((item) => item),
        afterImprovement: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            const improvement =
              r.getIn(['simulation', 'default', 'improvement']) || 0;

            let ret = false;
            if (
              get9BoxReadiness(perf + improvement, pot) === 'ready_12' &&
              r.get('is_improvement_visible') === true
            ) {
              ret = true;
            }
            return ret;
          })
          .map((item) => item)
      },
      {
        key: 'needs_12+',
        simulation: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            let ret = false;
            if (get9BoxReadiness(perf, pot) === 'ready_not') {
              ret = true;
            }
            return ret;
          })
          .map((item) => item),
        afterImprovement: data
          .filter((r) => {
            const perf = r.getIn(['simulation', 'default', 'operf']) * 100;
            const pot = r.get('obp');
            const improvement =
              r.getIn(['simulation', 'default', 'improvement']) || 0;

            let ret = false;
            if (
              get9BoxReadiness(perf + improvement, pot) === 'ready_not' &&
              r.get('is_improvement_visible') === true
            ) {
              ret = true;
            }
            return ret;
          })
          .map((item) => item)
      }
    ];

    const colorTypes = ['green', 'orange', 'orange', 'blue'];

    const fields = [
      {
        key: 'readiness',
        dark: true,
        ownData: true,
        sortable: false,
        renderField: (item, i) => (
          <td style={{ minWidth: 325, fontSize: 15 }} key={`table-field-${i}`}>
            <strong>
              <ColorIndicator type="circle" color={colorTypes[i - 1]} />
              <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
            </strong>
          </td>
        )
      },
      {
        key: data.size === 1 ? 'participant' : 'participants',
        sortable: false,
        renderField: (item, i) => (
          <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}>
            {orderMapByArrayValue(item.simulation, ['user', 'surname']).map(
              (s, j) => (
                <UserBox key={`${i}-${j}`} report={s} user={s.get('user')} />
              )
            )}
          </div>
        )
      }
    ];

    return {
      data: final,
      customClassName: 'readinessTable',
      fields
    };
  };

  const { data } = props;
  const report = reportsOfOneAssessment(props);

  return (
    <div>
      <Alert className={styles['analytics-infobox']} color="info" isOpen>
        <FormattedMessage
          id={data.size === 1 ? 'READINESS_INFO_INDIVIDUAL' : 'READINESS_INFO'}
        />
      </Alert>

      {report && (
        <div className="text-center html2pdf__page-break">
          <h4 className={styles['analytics-section']}>
            <FormattedMessage id="ASSESSMENT" />:{' '}
            {report.get('assessment_name')}
          </h4>
        </div>
      )}

      <div className="text-center">
        <BuildTable table={getTable()} />
      </div>
    </div>
  );
};

export default connect((state) => ({
  route: state.router.route
}))(
  React.memo(
    getReadiness,
    (prevProps, nextProps) => prevProps?.data?.size === nextProps?.data?.size
  )
);
