// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { submit } from 'redux-form';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { Strategy as StrategyForm } from 'forms';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';

// Actions
import { strategies as strategiesAction } from 'actions/assessments';

// Const
const form = 'strategyForm';

/**
 * Strategy modal
 * @class
 */
@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    isLoading: state.simulations.get('contactLoading')
  }),
  (dispatch) => bindActionCreators({ submit, strategiesAction }, dispatch)
)
export default class Strategy extends Component {
  // PropTypes
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    edit: PropTypes.object,
    submit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    strategiesAction: PropTypes.func.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { strategiesAction, edit, id } = this.props;
    const { addAlert, removeModal } = this.context;

    strategiesAction({
      type: edit ? 'edit' : 'create',
      assessmentId: id,
      data,
      id: edit ? edit.get('id') : null
    }).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: (
            <FormattedMessage
              id={`SUCCESSFULLY_${edit ? 'EDITED' : 'CREATED'}`}
            />
          )
        });
        removeModal();
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { onClose, submit, edit, isLoading } = this.props;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id={`${edit ? 'EDIT' : 'NEW'}_STRATEGY`} />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <StrategyForm edit={edit} onSubmit={this.handleSubmit} />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_new_strategy"
                name="cancel_new_strategy"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_new_strategy"
                name="submit_new_strategy"
                color="primary"
                onClick={() => submit(form)}
                className={modals['footer-button']}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
