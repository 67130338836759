/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { reduxForm, change, formValueSelector } from 'redux-form';

// Styles
import { ConfirmModal } from 'components';
import { Button, Col, Row } from 'reactstrap';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Actions
import getBlocks from './getBlocks';

import { RESET_USER_SEED } from 'constants/actionTypes';

// Const
const form = 'createUser';

/**
 * Create user form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    allowedSimulations: state.users.get('allowedSimulations'),
    isLoading: state.users.get('createUserLoading'),
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    user: state.auth.get('loggedUser'),
    clients: state.clients.get('allClients'),
    role: state.router.route.attrs.role,
    inviteNow: formValueSelector(form)(state, 'invite_now'),
    selectedSimulation: formValueSelector(form)(
      state,
      'active_simulation.simulation_id'
    ),
    selectedAssessment: formValueSelector(form)(
      state,
      'active_simulation.assessment_id'
    ),
    selectedLanguage: formValueSelector(form)(
      state,
      'active_simulation.language'
    ),
    formUserName: `${formValueSelector(form)(
      state,
      'firstname'
    )} ${formValueSelector(form)(state, 'surname')}`
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class CreateUserForm extends Component {
  // Prop types
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    allowedSimulations: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    change: PropTypes.func.isRequired,
    inviteNow: PropTypes.bool,
    selectedSimulation: PropTypes.number,
    selectedAssessment: PropTypes.number,
    selectedLanguage: PropTypes.string,
    editMode: PropTypes.bool,
    permissions: PropTypes.array,
    role: PropTypes.string.isRequired,
    clients: PropTypes.object.isRequired,
    message: PropTypes.string,
    transitionTo: PropTypes.func.isRequired,
    formUserName: PropTypes.string
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { user, clients, change, permissions, role, dispatch } = this.props;

    dispatch({ type: RESET_USER_SEED });
    change(form, 'status', 'active');

    if (
      user.get('isPartner') &&
      clients.size > 0 &&
      permissions.indexOf(`user_${role}_add_own`) < 0
    ) {
      change(form, 'client_id', clients.first().get('id'));
    }
    if (user.get('isPartner')) {
      change(form, 'partner_id', user.get('partner_id'));
    }
    if (role === 'participant') {
      change(form, 'invite_now', true);
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { addModal, removeModal } = this.context;
    const {
      allowedSimulations,
      user,
      handleSubmit,
      isLoading,
      permissions,
      role,
      message,
      inviteNow,
      transitionTo,
      selectedSimulation,
      selectedAssessment,
      formUserName
    } = this.props;

    const buttonMessage = inviteNow ? 'CREATE_AND_INVITE' : message;
    const isDisabled = false;
    const selectedSimulationId = selectedSimulation
      ? `${selectedSimulation}${selectedAssessment || 0}`
      : null;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        {getBlocks(permissions, { role, form, loggedUser: user })}

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="back_to_users"
              name="back_to_users"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/users')}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_create_user"
              name="submit_create_user"
              type="button"
              color="primary"
              disabled={isLoading || isDisabled}
              onClick={
                selectedSimulation == null ||
                !inviteNow ||
                formUserName.indexOf('undefined') > -1 ||
                role !== 'participant'
                  ? handleSubmit
                  : () =>
                      addModal(
                        <ConfirmModal
                          header={<FormattedMessage id="ARE_YOU_SURE" />}
                          message={
                            <FormattedHTMLMessage
                              id={
                                selectedAssessment
                                  ? 'ARE_YOU_SURE_ASSESSMENT_SINGLE_INVITE'
                                  : 'ARE_YOU_SURE_DEFAULT_SINGLE_INVITE'
                              }
                              values={{
                                simulation: allowedSimulations.has(
                                  selectedSimulationId
                                )
                                  ? allowedSimulations.getIn([
                                      selectedSimulationId,
                                      'name'
                                    ])
                                  : null,
                                name: formUserName
                              }}
                            />
                          }
                          submitAction={() => {
                            removeModal();
                            handleSubmit();
                          }}
                          onClose={removeModal}
                        />
                      )
              }
            >
              <FormattedMessage id={isLoading ? 'LOADING' : buttonMessage} />
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
