import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { doScrolling, elementInViewport } from '../../../../../../helpers';
import { getSelected } from '../actions';

import styles from '../../styles.css';

const FloatingButton = (props) => {
  const { getAnalyticsElement, getFoldersElement, route } = props;
  const [floatingButtonVisible, setFloatingButtonVisible] = useState(false);

  const handleScroll = () => {
    const analyticsElement = getAnalyticsElement();
    const element = getFoldersElement();

    if (!element) {
      return;
    }

    let nextValue;

    if (analyticsElement != null) {
      nextValue =
        elementInViewport(element, true) &&
        !elementInViewport(analyticsElement, false, 150);
    } else {
      nextValue = elementInViewport(element, true);
    }

    if (floatingButtonVisible !== nextValue) {
      setFloatingButtonVisible(nextValue);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false);
    window.addEventListener('resize', handleScroll, false);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
      window.removeEventListener('resize', handleScroll, false);
    };
  }, [floatingButtonVisible, route?.query?.selected]);

  const selected = getSelected(route);

  return (
    <div
      id="floating-block"
      className={classNames(
        styles['floating-button'],
        floatingButtonVisible &&
          selected &&
          selected.length > 0 &&
          styles.visible
      )}
      onClick={() => doScrolling(getAnalyticsElement().offsetTop, 300)}
    >
      <h3>
        <FormattedMessage id="SCROLL_DOWN_TO_VIEW_ANALYTICS" />
      </h3>
      <span className={styles['arrow-down']} />
    </div>
  );
};

export default connect((state) => ({
  route: state.router.route
}))(React.memo(FloatingButton));
