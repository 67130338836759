import {
  ALLOWED_SIMULATIONS_REQUEST,
  ALLOWED_SIMULATIONS_SUCCESS,
  ALLOWED_SIMULATIONS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch allowed simulations action
 *
 * @param{Number} partner
 * @param{Number} client
 * @param{Boolean} all
 * @param{Boolean} filterData
 * @param{Number} participant
 * @param{Boolean} published
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchAllowedSimulations(
  partner,
  client,
  all,
  filterData,
  participant,
  published
) {
  return {
    types: [
      ALLOWED_SIMULATIONS_REQUEST,
      ALLOWED_SIMULATIONS_SUCCESS,
      ALLOWED_SIMULATIONS_FAILURE
    ],
    promise: api().get(
      `/api/v1/simulations/allowed/?partner=${partner || ''}&client=${
        client || ''
      }&hierarchy=${all === true ? 'true' : 'false'}&filterData=${
        filterData === true ? 'true' : 'false'
      }${participant != null ? `&participant=${participant}` : ''}${
        published === true ? `&published=true` : ''
      }`
    )
  };
}
