// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { submit } from 'redux-form';

// Components
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';
import api from '../../../../../../api';

/**
 * Share report
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser')
  }),
  (dispatch) => bindActionCreators({ submit }, dispatch)
)
export default class VideoModal extends Component {
  // Prop types
  static propTypes = {
    user: PropTypes.object,
    data: PropTypes.object.isRequired,
    videoData: PropTypes.object.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    this.state = {
      defaultSettings: {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        showControls: false,
        subscribeToAudio: true,
        audioVolume: 100,
        publishAudio: true,
        publishVideo: true
      },
      publisher: null,
      session: null,
      callSession: null,
      sound: new Audio('/assets/files/call.mp3'),
      hasError: false
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { data, videoData } = this.props;
    const { sound } = this.state;
    const { addAlert } = this.context;

    // Play calling sound
    sound.addEventListener(
      'ended',
      () => {
        this.state.sound.currentTime = 0;
        this.state.sound.play();
      },
      false
    );

    this.soundTimeout = setTimeout(() => {
      if (!this.state.hasError && !this._calledComponentWillUnmount) {
        // eslint-disable-line
        sound.play();
      }
    }, 10000);

    const session = this.OpenTok.createSession(
      data.api_key,
      data.session,
      'standby'
    ); // eslint-disable-line
    session.connect(data.token, (error) => {
      if (error) {
        addAlert({
          color: 'danger',
          component: <FormattedMessage id="ERROR_OCCURRED" />
        });
      } else {
        this.setState({ session });
      }
    });

    session.on('signal', (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'decline') {
        this.OpenTok.endCall();
      }

      if (data.type === 'busy') {
        if (!this.state.hasError) {
          this.busyTimeout = setTimeout(() => {
            this.participantBusy();
          }, 2000);
        }
      }
    });

    session.on('connectionDestroyed', () => {
      this.OpenTok.endCall();
    });

    this.callTimeout = setTimeout(() => {
      let connected = false;
      const standbySession = session;
      const callSession = this.OpenTok.createSession(
        videoData.api_key,
        videoData.session,
        'call'
      );

      this.connectedTimeout = setTimeout(() => {
        if (!connected) {
          this.OpenTok.endCall();
        }
      }, 60000);

      callSession.on('streamCreated', (event) => {
        connected = true;
        clearTimeout(this.soundTimeout);
        sound.pause();
        callSession.subscribe(
          event.stream,
          'tokbox-video-subscriber',
          this.state.defaultSettings
        );
      });

      callSession.on('sessionDisconnected', () => {
        this.OpenTok.endCall();
      });

      callSession.on('networkDisconnected', () => {
        this.OpenTok.endCall();
      });

      callSession.on('connectionDestroyed', () => {
        this.OpenTok.endCall();
      });

      callSession.on('streamDestroyed', () => {
        this.OpenTok.endCall();
      });

      callSession.connect(videoData.token, (error) => {
        if (
          !error &&
          !this._calledComponentWillUnmount &&
          !this.state.hasError &&
          standbySession
        ) {
          // eslint-disable-line
          // Send signal
          this.OpenTok.sendSignal(standbySession, {
            type: 'call',
            id: videoData.call_id,
            contactId: videoData.contact_id
          });
          const publisher = this.OpenTok.createPublisher(
            'tokbox-video-publisher'
          );
          this.setState({ publisher, callSession });
          callSession.publish(publisher);
        }
      });
    }, 2000);
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    const { sound } = this.state;

    sound.removeEventListener(
      'ended',
      () => {
        this.state.sound.currentTime = 0;
        this.state.sound.play();
      },
      false
    );
    sound.pause();
    clearTimeout(this.callTimeout);
    clearTimeout(this.connectedTimeout);
    clearTimeout(this.soundTimeout);
    clearTimeout(this.busyTimeout);
    this.OpenTok.endCall();
  }

  OpenTok = {
    createSession: (appId, sessionId) => OT.initSession(appId, sessionId), // eslint-disable-line
    endCall: () => {
      const { publisher, session, callSession } = this.state;
      const { videoData } = this.props;

      if (session) {
        this.OpenTok.sendSignal(session, {
          type: 'endCall',
          id: videoData.call_id,
          contactId: videoData.contact_id
        });
      }

      if (publisher) {
        publisher.destroy();
      }

      if (session) {
        session.disconnect();
      }

      if (callSession) {
        callSession.disconnect();
      }

      const promises = [];
      promises.push(api().put(`/api/v1/video-call/${videoData.call_id}/`));
      Promise.all(promises);

      this.context.removeModal();
    },
    createPublisher: (element) =>
      OT.initPublisher(element, this.defaultSettings), // eslint-disable-line
    sendSignal: (connection, data) => {
      connection.signal({
        to: connection,
        data: JSON.stringify(data)
      });
    }
  };

  /**
   * Participant busy
   */
  participantBusy = () => {
    const busySound = new Audio('/assets/files/busy.mp3');

    if (!this.state.hasError) {
      busySound.play();
      this.OpenTok.endCall();
    } else {
      this.setState({ hasError: true });
    }
  };

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { removeModal } = this.context;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalBody className={modals['modal-body']}>
          <div>
            <div style={{ height: 400 }} id="tokbox-video-subscriber" />
            <div
              id="tokbox-video-publisher"
              className={modals['publisher-video-box']}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            id="end_call_button"
            name="end_call_button"
            color="danger"
            onClick={removeModal}
            className={modals['footer-button']}
          >
            <FormattedMessage id="END_CALL" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
