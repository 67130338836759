import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button
} from 'reactstrap';
import html2pdf from 'html2pdf.js';

import { Link } from 'components';
import DownloadPDFModal from '../../components/DownloadPDFModal';

import styles from './styles.css';
import './styles.css';

import { AssessmentSelect, IndividualSectionFilter } from './actions';
import * as contents from './actions';
import {
  downloadPdf,
  fetchAnalytics,
  fetchDetailAnalyticsData
} from 'actions/analytics';
import { ToolTip } from '../../../../components';
import {
  getCurrentAssessmentId,
  getSelected
} from '../../components/Folders/components/actions';
import getBase64ImageFromUrl from '../../../../helpers/getBase64ImageFromUrl';
import api from '../../../../api';
import PdfReader from '../../../../components/PdfReader/PdfReader';

const allTabs = [
  { key: 'overview', filters: ['individual'] },
  { key: 'readiness', filters: [] },
  { key: '9box', filters: [] },
  { key: 'bench', filters: ['assessment'] },
  { key: 'gaps', filters: ['assessment'] },
  { key: 'climate', filters: [] },
  { key: 'group-capability', filters: ['assessment'] },
  { key: 'narrative', filters: ['individual'] },
  { key: 'performance', filters: ['individual'] },
  { key: 'potential', filters: ['individual'] },
  { key: 'skills-motivation', filters: ['individual'] },
  { key: 'behaviors', filters: ['individual'] },
  { key: 'personality', filters: ['individual'] },
  { key: 'development-dashboard', filters: ['individual'] }
];

const Preview = (props, context) => {
  const [pdfBlob, setPdfBlob] = useState('');
  const [hasNewPdfReporting, setHasNewPdfReporting] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [toSection, setToSection] = useState('');
  const {
    analyticsData,
    direction,
    fetchAnalytics,
    fetchDetailAnalyticsData,
    user,
    overviewData,
    transitionTo,
    route
  } = props;
  const currentReportID = Number(route.query.current);

  useEffect(() => {
    setHasNewPdfReporting(
      analyticsData
        .find((item) => item.get('id') === currentReportID)
        ?.get('has_new_pdf_reporting')
    );
  }, [route]);

  const isParticipant = user.get('active_role') === 'participant';

  const individualReports = [
    'narrative',
    'performance',
    'potential',
    'skills-motivation',
    'personality',
    'behaviors',
    'development-dashboard'
  ];
  const isCurrentTabIndividualReport =
    individualReports.indexOf(route.query.tab) !== -1;

  const getTabContent = (tab, data) => {
    if (toSection === 'performance') {
      route.query.tab = toSection;
      return <contents.getPerformance data={data} />;
    }
    if (toSection === 'potential') {
      route.query.tab = toSection;
      return <contents.getPotential data={data} />;
    }
    if (toSection === 'personality') {
      route.query.tab = toSection;
      return <contents.getPersonality data={data} />;
    }
    switch (tab.key) {
      case 'overview':
        return <contents.getOverview data={data} setToSection={setToSection} />;
      case 'readiness':
        return <contents.getReadiness data={data} />;
      case '9box':
        return <contents.get9Box data={data} />;
      case 'bench':
        return <contents.getBench data={data} />;
      case 'gaps':
        return <contents.getGaps data={data} />;
      case 'climate':
        return <contents.getClimate data={data} />;
      case 'group-capability':
        return <contents.getGroupCapability data={data} />;
      case 'narrative':
        return <contents.getNarrative data={data} />;
      case 'performance':
        return <contents.getPerformance data={data} />;
      case 'potential':
        return <contents.getPotential data={data} />;
      case 'skills-motivation':
        return <contents.getSkillsAndMotivation data={data} />;
      case 'behaviors':
        return <contents.getBehaviors data={data} />;
      case 'personality':
        return <contents.getPersonality data={data} />;
      case 'development-dashboard':
        return <contents.getDevelopmentDashboard data={data} />;
      default:
        return <FormattedMessage id={tab.key} />;
    }
  };

  const checkDisabled = () => {
    const { route } = props;

    switch (route.query.tab) {
      case 'readiness':
      case '9box':
      case 'bench':
      case 'gaps':
      case 'group-capability':
      case 'climate':
        return false;
      default:
        return true;
    }
  };

  const downloadPdfReports = (formData, id) => {
    const { analyticsData, downloadPdf, route } = props;
    const { removeModal } = context;
    const selectedUsers = getSelected(route);
    const selected = [];
    const currentId = isParticipant ? route.query.current : id;

    if (currentId) {
      const report = analyticsData.find(
        (r) => r.get('id') === Number(currentId)
      );
      return downloadPdf({
        files: [
          report
            .get('files')
            .find(
              (f) =>
                f.get('scope') === formData.version &&
                f.get('language') === formData.language
            )
            .get('uuid')
        ]
      }).then((action) => {
        if (!action.error) {
          const fileName = action.result.xhr.getResponseHeader(
            'Content-Disposition'
          );
          const binaryPdf = action.result.xhr.response;
          const blob = new Blob([binaryPdf], { type: 'application/pdf' });
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = objectUrl;
          a.download = decodeURIComponent(
            fileName.substring(fileName.indexOf("'") + 2, fileName.length)
          );
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          removeModal();
        }
      });
    }

    analyticsData.toArray().map((r) => {
      if (selectedUsers.indexOf(r.get('id')) > -1) {
        return r.has('files')
          ? selected.push(
              r
                .get('files')
                .find(
                  (f) =>
                    f.get('scope') === formData.version &&
                    f.get('language') === formData.language
                )
                .get('uuid')
            )
          : false;
      }

      return false;
    });

    const type =
      selectedUsers.length === 1 ? 'application/pdf' : 'application/zip';

    return downloadPdf({ files: selected }).then((action) => {
      if (!action.error) {
        const fileName = action.result.xhr.getResponseHeader(
          'Content-Disposition'
        );
        const binaryPdf = action.result.xhr.response;
        const blob = new Blob([binaryPdf], { type });
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = objectUrl;
        a.download = decodeURIComponent(
          fileName.substring(fileName.indexOf("'") + 2, fileName.length)
        );
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        removeModal();
      }
    });
  };

  const downloadReports = (id) => {
    const { addModal } = context;
    const { analyticsData, route } = props;
    const current = Number(route.query.current);
    const data = analyticsData
      .find(
        (r) =>
          r.get('id') ===
          Number(
            current != null && current !== 0 ? current : getSelected(route)[0]
          )
      )
      .get('files');

    addModal(
      <DownloadPDFModal
        data={data}
        onSubmit={(e) => downloadPdfReports(e, id)}
        multipleDownload={id == null}
      />
    );
  };

  const getPrintAction = () => {
    const { route } = props;
    const current = Number(route.query.current);

    switch (route.query.tab) {
      case 'readiness':
      case '9box':
      case 'bench':
      case 'gaps':
      case 'group-capability':
      case 'climate':
        return downloadReports();
      default:
        return downloadReports(current);
    }
  };

  const downloadViewAsPdf = async () => {
    const analyticsPreviewContainer = document.getElementById(
      'analytics-preview-container'
    );
    const analyticsPreviewContainerClone = analyticsPreviewContainer.cloneNode(
      true
    );
    analyticsPreviewContainerClone
      .querySelector('#download_reports_button')
      .remove();
    analyticsPreviewContainerClone
      .querySelector('#download_view_as_pdf')
      .remove();
    analyticsPreviewContainerClone.style.marginTop = '20px';
    const dateToday = new Date();
    const tab = route.query.tab;
    let filename = tab.replace('-', '_');
    filename = filename === 'gaps' ? 'skill_gaps' : filename;
    filename += `_${dateToday.getFullYear()}`;
    filename += `_${dateToday.getMonth() + 1}`;
    filename += `_${dateToday.getDate()}`;
    filename += '.pdf';
    const options = {
      jsPDF: { orientation: 'landscape' },
      margin: [0, 10, 0, 10],
      pagebreak: {
        before: ['.html2pdf__page-break'],
        avoid: ['tr', 'span', '.html2pdf__avoid']
      },
      filename
    };
    const svgs = Array.from(
      analyticsPreviewContainerClone.getElementsByClassName('highcharts-root')
    );
    if (svgs.length) {
      await Promise.all(
        svgs.map(async (svg) => {
          const imageTag = svg.getElementsByTagName('image')[0];
          if (!imageTag) {
            return;
          }
          const imageUrl = `${window.location.origin}/${imageTag.getAttribute(
            'href'
          )}`;
          const base64image = await getBase64ImageFromUrl(imageUrl);
          imageTag.setAttribute('href', base64image);
        })
      );
    }
    const svgGraph = analyticsPreviewContainer.querySelector(
      '.highcharts-root'
    );
    if (svgGraph && svgGraph.clientWidth > 1000) {
      analyticsPreviewContainerClone.querySelector(
        'div[data-highcharts-chart]'
      ).style.transform = 'scale(.9)';
    }
    if (tab === 'overview') {
      const originalParticipantSelectEl = analyticsPreviewContainer.querySelector(
        '#input_select_overview'
      );
      const participantSelectEl = analyticsPreviewContainerClone.querySelector(
        '#input_select_overview'
      );
      analyticsPreviewContainerClone.querySelector('ul').remove();
      const parentEl = participantSelectEl.parentNode;
      const selectedParticipant =
        originalParticipantSelectEl.options[
          originalParticipantSelectEl.selectedIndex
        ].text;
      participantSelectEl.remove();
      parentEl.textContent = selectedParticipant;
    }
    const userImgs = Array.from(
      analyticsPreviewContainerClone.querySelectorAll('[class^=styles_img-pic]')
    );
    if (userImgs.length) {
      await Promise.all(
        userImgs.map(async (userImg) => {
          const backgroundImageUrl = userImg.style.backgroundImage;
          if (backgroundImageUrl) {
            const cleanedUrl = backgroundImageUrl
              .replace('url("', '')
              .replace('")', '');
            const base64image = await getBase64ImageFromUrl(cleanedUrl);
            const base64imageCorrected = base64image.replace(
              'application/octet-stream',
              'image/jpeg'
            );
            // eslint-disable-next-line no-param-reassign
            userImg.style.backgroundImage = `url("${base64imageCorrected}")`;
          }
        })
      );
    }
    api().post(
      '/api/v1/analytics/pdf-report-view-downloaded-auditable-trail/',
      route.query
    );
    html2pdf(analyticsPreviewContainerClone, options);
  };

  const updateTabsData = (participantReports) => {
    const { analyticsData, transitionTo, route } = props;
    const { query } = route;
    const current = Number(route.query.current);
    const filtered = isParticipant
      ? analyticsData.filter((r) => r.get('id') === current)
      : analyticsData.filter(
          (r) => getSelected(route)?.indexOf(r.get('id')) > -1
        );

    query.scroll = 0;

    if (filtered.size > 0 && filtered.first().has('assessment_sections')) {
      const sections = [];

      if (isParticipant) {
        if (analyticsData.hasIn([current, 'assessment_sections'])) {
          analyticsData
            .getIn([current, 'assessment_sections'])
            .toArray()
            .map((section) => sections.push(section));
        } else {
          filtered
            .first()
            .get('assessment_sections')
            .toArray()
            .map((section) => sections.push(section));
        }
      } else {
        filtered.map((report) => {
          if (report.has('assessment_sections')) {
            return report
              .get('assessment_sections')
              .toArray()
              .map((section) => {
                if (sections.indexOf(section) < 0) {
                  return sections.push(section);
                }

                return false;
              });
          }

          return false;
        });
      }

      const newTabs = allTabs.filter((t) => sections.indexOf(t.key) > -1);
      setTabs(newTabs);

      if (
        getSelected(route)?.length === 0 ||
        getSelected(route)?.length === 1 ||
        participantReports
      ) {
        query.tab =
          route.query.tab != null &&
          !isParticipant &&
          newTabs.find((t) => t.key === query.tab)
            ? route.query.tab
            : newTabs[0]?.key;
        query.selected =
          participantReports != null
            ? participantReports.join(',')
            : query.selected || null;
        query.current = route.query.current || null;
        query.filtered = route.query.filtered || 0;
        query.assessment = getCurrentAssessmentId(
          analyticsData.find((r) => r.get('id') === Number(route.query.current))
        );
        transitionTo('/analytics', query);
      } else if (!newTabs.find((t) => t.key === query.tab)) {
        query.tab = newTabs[0]?.key;
        transitionTo('/analytics', query);
      }
    }
  };

  useEffect(async () => {
    if (isParticipant) {
      if (
        analyticsData.size > 0 &&
        analyticsData.first().has('assessment_sections')
      ) {
        const sortedAnalyticsData = analyticsData.sort((a, b) => {
          const first = a.get('date').toLowerCase();
          const second = b.get('date').toLowerCase();
          if (moment(first).isBefore(second)) {
            return 1;
          }
          if (moment(first).isAfter(second)) {
            return -1;
          }

          return 0;
        });

        const reports = sortedAnalyticsData.toArray().map((r) => r.get('id'));

        transitionTo('/analytics', {
          selected: reports.join(','),
          current: route.query.current || reports[0],
          assessment: getCurrentAssessmentId(
            analyticsData.find(
              (r) => r.get('id') === Number(route.query.current)
            )
          ),
          scroll: 0,
          filtered: route.query.filtered
        }).then(() => {
          updateTabsData(reports);
        });
      } else {
        if (props.analyticsData.size > 0) {
          return;
        }

        const promises = [];

        const reports = overviewData.map((report) =>
          getCurrentAssessmentId(report)
        );

        reports.map((id) =>
          promises.push(fetchAnalytics({ assessments: [id] }))
        );

        Promise.all(promises).then((response) => {
          const reports = [];
          response.map((r) =>
            r.result.body.map((i) => reports.push(Number(i.id)))
          );

          fetchDetailAnalyticsData({
            report_id: reports.join(',')
          });
        });
      }

      const fileUuid = analyticsData
        .find((report) => report?.get('id') === Number(route.query.current))
        ?.get('files')
        .find((file) => file?.get('language') === user.get('language'))
        ?.get('uuid');

      if (fileUuid) {
        const res = await api().post(
          '/api/v1/reports/download/',
          {
            files: [fileUuid]
          },
          {},
          'arraybuffer'
        );
        const blob = new Blob([res.body], { type: 'application/pdf' });
        const objectUrl = URL.createObjectURL(blob);

        setPdfBlob(objectUrl);
      }
    } else {
      updateTabsData();
    }
  }, [
    props.route.query.selected,
    props.route.query.current,
    props.analyticsData
  ]);

  const filtered = analyticsData.filter(
    (r) => getSelected(route)?.indexOf(r.get('id')) > -1
  );
  const ids = [];
  filtered.map((u) => ids.push(u.get('id')));

  const disabledPrintButton = checkDisabled();

  if (
    (tabs.length === 0 || getSelected(route)?.length === 0) &&
    !hasNewPdfReporting
  ) {
    return <div />;
  }

  const getJIT = (tab) =>
    tab.filters.length > 0 && !isParticipant ? (
      <ToolTip
        id={`select-tooltip-${tab.key}`}
        index={`select-tooltip-${tab.key}`}
        hint={<FormattedMessage id="ONLY_ENABLED_ANALYTICS_ARE_DISPLAYED" />}
        style={{ verticalAlign: 'top', top: -13, position: 'relative' }}
      />
    ) : null;

  const restrictedShPdfDownloadClients = process.env.REACT_APP_RESTRICT_SH_PDF_DOWNLOAD_CLIENTS.split(
    ','
  );

  const restrictedPdfDownload =
    restrictedShPdfDownloadClients.indexOf(user.getIn(['client', 'name'])) !==
      -1 && user.get('active_role') === 'stakeholder';

  const selectedUsers = getSelected(route);

  const disabledDownloadPdfBtn =
    (disabledPrintButton && !route.query.current) || selectedUsers.length > 25;

  return (
    <div id="analytics-preview-container" className={styles.preview}>
      <Nav tabs className={classNames(styles.tabs, 'analytics-tabs')}>
        {(!isParticipant || !hasNewPdfReporting) &&
          tabs.map((t) => (
            <NavItem key={t.key}>
              <Link
                id={`tab_${t.key}`}
                to="/analytics"
                query={{
                  selected: route.query.selected || null,
                  current: route.query.current || null,
                  assessment: route.query.assessment || null,
                  tab: t.key.toString(),
                  scroll: 0,
                  filtered: route.query.filtered
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames({ active: route.query.tab === t.key })}
                >
                  <FormattedMessage id={t.key} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
        {restrictedPdfDownload ? null : (
          <Button
            id="download_reports_button"
            name="download_reports_button"
            color="primary-dark"
            disabled={disabledDownloadPdfBtn}
            onClick={() => getPrintAction()}
            style={{ float: direction === 'rtl' ? 'left' : 'right' }}
            className={classNames(
              styles['has-offset'],
              isParticipant && styles['participants-view']
            )}
          >
            <FormattedMessage id="DOWNLOAD_REPORTS" />
            {selectedUsers.length > 25 && (
              <ToolTip
                id="ANALYTICS_PDF_JIT"
                index="ANALYTICS_PDF_JIT"
                hint="To activate this button, please narrow down your search results to max. 25 reports."
              />
            )}
          </Button>
        )}
      </Nav>

      {!isParticipant && !isCurrentTabIndividualReport && (
        <Button
          id="download_view_as_pdf"
          type="button"
          color="secondary"
          onClick={downloadViewAsPdf}
          className={styles['download-view-as-pdf-btn']}
        >
          Download view as PDF
        </Button>
      )}

      {(!isParticipant || !hasNewPdfReporting) && tabs.length > 0 && (
        <TabContent activeTab={route.query.tab || tabs[0].key.toString()}>
          {tabs.map((t) => (
            <TabPane key={t.key} tabId={t.key} style={{ marginTop: 50 }}>
              <Row>
                <Col sm="12">
                  {t.filters.indexOf('individual') > -1 && (
                    <IndividualSectionFilter
                      tab={t}
                      data={filtered.filter((a) => {
                        if (t.key === 'narrative') {
                          return (
                            a.get('assessment_sections')?.contains(t.key) &&
                            a.get('has_narrative')
                          );
                        }

                        return a.get('assessment_sections')?.contains(t.key);
                      })}
                      jit={getJIT(t)}
                    />
                  )}
                  {!isParticipant && t.filters.indexOf('assessment') > -1 && (
                    <AssessmentSelect
                      data={filtered.filter((a) =>
                        a.get('assessment_sections')?.contains(t.key)
                      )}
                      filterOptions
                      jit={getJIT(t)}
                    />
                  )}

                  {route.query.tab === t.key &&
                    filtered.size > 0 &&
                    getTabContent(t, filtered)}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      )}

      {isParticipant && hasNewPdfReporting && pdfBlob && (
        <PdfReader pdfUrl={pdfBlob} />
      )}
    </div>
  );
};

Preview.contextTypes = {
  addModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    overviewData: state.analytics.get('overviewData'),
    analyticsData: state.analytics.get('allData'),
    analyticsDetailLoading: state.analytics.get('analyticsDetailLoading'),
    route: state.router.route,
    direction: state.app.get('direction')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      {
        fetchDetailAnalyticsData,
        downloadPdf,
        transitionTo,
        fetchAnalytics
      },
      dispatch
    );
  }
)(
  React.memo(
    Preview,
    (prevProps, nextProps) =>
      JSON.stringify(prevProps) === JSON.stringify(nextProps)
  )
);
