import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, getFormValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { Attachment as AttachmentForm } from 'forms';

import { emailAttachment } from 'actions/simulations';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';

const form = 'attachmentForm';

@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    emailId: Number(state.router.route.vars.emailId),
    formValues: getFormValues(form)(state) || {}
  }),
  (dispatch) => bindActionCreators({ submit, emailAttachment }, dispatch)
)
export default class Attachment extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    emailAttachment: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    id: PropTypes.number,
    formValues: PropTypes.object,
    emailId: PropTypes.number,
    editId: PropTypes.number
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  handleSubmit = (data) => {
    const { emailAttachment, id, emailId, editId } = this.props;
    const { addAlert, removeModal } = this.context;

    emailAttachment({
      type: editId ? 'edit' : 'create',
      simulationId: id,
      data,
      id: emailId,
      attachmentId: editId
    }).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
        });
        removeModal();
      } else {
        addAlert({
          color: 'danger',
          content: <FormattedMessage id="ERROR" />
        });
        removeModal();
      }
    });
  };

  render() {
    const { onClose, submit, formValues, editId } = this.props;
    const hasError = !formValues.file_id || !formValues.filename;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id="NEW_ATTACHMENT" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div>
            <AttachmentForm editId={editId} onSubmit={this.handleSubmit} />
          </div>
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_attachment_modal"
                name="cancel_attachment_modal"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_attachment_modal"
                name="submit_attachment_modal"
                color="primary"
                onClick={() => submit(form)}
                className={modals['footer-button']}
                disabled={hasError}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
