import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import styles from '../styles.css';
import CoachingNotes from './DevelopmentDashboard/CoachingNotes';
import CoachingSuggestions from './DevelopmentDashboard/CoachingSuggestions';
import DaysPracticed from './DevelopmentDashboard/DaysPracticed';
import SkillPriorities from './DevelopmentDashboard/SkillPriorities';
import SkillsPracticed from './DevelopmentDashboard/SkillsPracticed';
import WeeklyFocus from './DevelopmentDashboard/WeeklyFocus';
import { Map } from 'immutable';

const getDevelopmentDashboard = (props) => {
  const { data, route } = props;
  const current = Number(route.query.current);
  const report = data.find((r) => r.get('id') === Number(current)) || Map();
  const selectedUser = report.get('user');

  if (!selectedUser) {
    return (
      <Col className="p-0">
        <h6 className={styles['dev-dashboard-empty']}>
          Please select participant
        </h6>
      </Col>
    );
  }

  return (
    <Col className="p-0">
      <div className={styles['dev-dashboard-top']}>
        <div className={styles['dev-dashboard-top-left']}>
          <div style={{ width: '50%' }}>
            <DaysPracticed user={selectedUser} />
          </div>
          <div style={{ width: '50%' }}>
            <SkillsPracticed user={selectedUser} />
          </div>
          <div style={{ width: '50%' }}>
            <SkillPriorities user={selectedUser} />
          </div>
          <div style={{ width: '50%' }}>
            <WeeklyFocus user={selectedUser} />
          </div>
        </div>
        <div className={styles['dev-dashboard-top-right']}>
          <CoachingNotes user={selectedUser} />
        </div>
      </div>
      <div>
        <CoachingSuggestions user={selectedUser} />
      </div>
    </Col>
  );
};

export default connect((state) => ({
  route: state.router.route,
  user: state.auth.get('loggedUser')
}))(
  React.memo(
    getDevelopmentDashboard,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
