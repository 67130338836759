// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Label
} from 'reactstrap';
import { validate } from './validate';

// Components
import { Link } from 'components';

// Actions
import { renderInput } from '../../actions';
import { normalizeError, isMobile } from '../../../helpers';

// Styles
import styles from '../../styles.css';
import '../../styles.css';
import compStyles from './styles.css';
import './styles.css';
import stylesLogin from '../Login/styles.css';

// Const
const form = 'resetPasswordForm';

let ResetPassword = (props) => {
  const {
    handleSubmit,
    isLoading,
    formErrors,
    formFields,
    resetPasswordError,
    route
  } = props;
  let error = false;

  if (formFields.email) {
    if (formFields.email.touched && formErrors.email === 'wrong_email') {
      error = 'wrong_email';
    }
  }

  return (
    <Container>
      <Row>
        <Col xs={{ size: 12 }} sm={{ size: 6, offset: 3 }}>
          <div
            className={classNames(
              compStyles['forgot-pass-form'],
              isMobile() && compStyles.mobile
            )}
          >
            <div className={styles['auth-wrapper']}>
              {route &&
                route.query &&
                route.query.errors &&
                route.query.errors
                  .split(',')
                  .map((error) => (
                    <Alert color="danger">
                      {error.indexOf('ERRORS') > -1 ? (
                        <FormattedMessage id={error} />
                      ) : (
                        error
                      )}
                    </Alert>
                  ))}
              {error && (
                <Alert color="danger">
                  <FormattedMessage id={`ERRORS.${error}`} />
                </Alert>
              )}
              {resetPasswordError && (
                <Alert color="danger">
                  <FormattedMessage
                    id={`ERRORS.${normalizeError(resetPasswordError)}`}
                  />
                </Alert>
              )}
              <h2>
                <FormattedMessage id="PASSWORD_RESET" />
              </h2>
              <p>
                <FormattedMessage id="PASSWORD_RESET_HINT" />
              </p>
              <form onSubmit={props.handleSubmit} className={styles.form}>
                <InputGroup>
                  <Label>
                    <FormattedMessage id="FORM_FIELDS.email" /> (
                    <FormattedMessage id="REQUIRED" />)
                  </Label>
                  <Field
                    name="email"
                    component={renderInput}
                    noTextError
                    type="email"
                  />
                </InputGroup>

                <div style={{ textAlign: 'center' }}>
                  <Button
                    id="submit_reset_password_form"
                    name="submit_reset_password_form"
                    onClick={handleSubmit}
                    disabled={formErrors.email}
                    color="primary"
                    className={styles['submit-button']}
                  >
                    <FormattedMessage id={isLoading ? 'LOADING' : 'SUBMIT'} />
                  </Button>
                  {isMobile() && (
                    <Link
                      id="back_to_login_link"
                      to="/auth/login"
                      query={
                        route && route.query && route.query.lang
                          ? { lang: route.query.lang }
                          : {}
                      }
                      className={stylesLogin.forgotLink}
                    >
                      <FormattedMessage id="BACK" />
                    </Link>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  isLoading: PropTypes.bool,
  formErrors: PropTypes.object,
  formFields: PropTypes.object,
  resetPasswordError: PropTypes.object,
  route: PropTypes.object.isRequired
};

ResetPassword = connect((state) => ({
  isLoading: state.auth.get('resetPasswordLoading'),
  formErrors: state.form.resetPasswordForm.syncErrors || {},
  formFields: state.form.resetPasswordForm.fields || {},
  resetPasswordError: state.auth.get('resetPasswordError'),
  route: state.router.route
}))(ResetPassword);

// Decorate with redux-form
export default reduxForm({
  form,
  validate
})(ResetPassword);
