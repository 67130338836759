import {
  MASS_TAGS_UPDATE_REQUEST,
  MASS_TAGS_UPDATE_SUCCESS,
  MASS_TAGS_UPDATE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function massTagsUpdate(selected, tags) {
  return {
    types: [
      MASS_TAGS_UPDATE_REQUEST,
      MASS_TAGS_UPDATE_SUCCESS,
      MASS_TAGS_UPDATE_FAILURE
    ],
    promise: api().post('/api/v1/mass/tags/', { selected, tags }),
    selected,
    tags
  };
}
