// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Loader
 * @param props
 * @constructor
 */
const Loader = (props) => (
  <div className={classNames(styles.loader, props.small && styles.small)} />
);

// Prop types
Loader.propTypes = {
  small: PropTypes.bool
};

export default Loader;
