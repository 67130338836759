import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, getFormValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { Exercise as NewExerciseForm } from 'forms';

import {
  exercises as exercisesAction,
  fetchSimulation
} from 'actions/simulations';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';
import { showSimulationError } from '../../actions';

const form = 'exerciseForm';

@connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    formValues: getFormValues(form)(state) || {},
    isLoading: state.simulations.get('exerciseLoading')
  }),
  (dispatch) =>
    bindActionCreators({ submit, exercisesAction, fetchSimulation }, dispatch)
)
export default class Exercise extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    exercisesAction: PropTypes.func.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    edit: PropTypes.object,
    submit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    readOnly: PropTypes.bool,
    version: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.func.isRequired
  };

  handleSubmit = (data) => {
    const { exercisesAction, id, edit, fetchSimulation } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    exercisesAction({
      type: edit ? 'edit' : 'create',
      simulationId: id,
      data,
      id: edit ? edit.get('id') : null
    }).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
        });
        removeModal();
      } else if (action.error && action.error.body) {
        showSimulationError(action, addAlert, intl, () => {
          removeModal();
          fetchSimulation(id);
        });
      }
    });
  };

  render() {
    const { onClose, edit, submit, isLoading, readOnly, version } = this.props;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage
            id={`${
              edit ? (readOnly ? 'VIEW' : 'EDIT') : 'NEW'
            }_EXERCISE_HEADING`}
          />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <NewExerciseForm
            readOnly={readOnly}
            edit={edit}
            onSubmit={this.handleSubmit}
            version={version}
          />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={readOnly ? 12 : 5} className={modals['modal-button-col']}>
              <Button
                id="cancel_exercise_modal"
                name="cancel_exercise_modal"
                color="secondary"
                onClick={onClose}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            {!readOnly && (
              <Col xs={7} className={modals['modal-button-col']}>
                <Button
                  id="submit_exercise_modal"
                  name="submit_exercise_modal"
                  color="primary"
                  onClick={() => submit(form)}
                  className={modals['footer-button']}
                >
                  <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
                </Button>
              </Col>
            )}
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
