import {
  START_VIDEO_CALL_REQUEST,
  START_VIDEO_CALL_SUCCESS,
  START_VIDEO_CALL_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Start video call action
 *
 * @param{Number} participantId
 * @param{Number} assignmentId
 * @returns {{types: *[], promise: Request }}
 */

export default function startVideoCall(participantId, assignmentId) {
  return {
    types: [
      START_VIDEO_CALL_REQUEST,
      START_VIDEO_CALL_SUCCESS,
      START_VIDEO_CALL_FAILURE
    ],
    promise: api().get(
      `/api/v1/users/${participantId}/video-call/${assignmentId}/`
    ),
    participantId,
    assignmentId
  };
}
