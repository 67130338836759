import React from 'react';

import { ShareModal } from '../index';
import shareReport from './shareReport';
import { getSelected } from './index';

export default (data, props, context) => {
  const { allData, route } = props;
  const { addModal } = context;
  let _selected;

  if (!data) {
    _selected = getSelected(route);
  } else {
    _selected = [data.get('id')];
  }

  const reports = [];
  allData.map((a) => {
    if (_selected.indexOf(a.get('id')) > -1) {
      return reports.push(a);
    }

    return false;
  });

  addModal(
    <ShareModal
      participants={reports}
      submitAction={(_props, _context) =>
        shareReport(reports, data, _props, _context)
      }
    />
  );
};
