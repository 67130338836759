import {
  FETCH_SCORED_ASSIGNMENTS_REQUEST,
  FETCH_SCORED_ASSIGNMENTS_SUCCESS,
  FETCH_SCORED_ASSIGNMENTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch scored assignment action
 *
 * @param{Number} id
 * @param{Number} assignmentId
 * @param{Boolean} bg
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchScoredAssignments(id, assignmentId, bg) {
  const types = bg
    ? ['r', FETCH_SCORED_ASSIGNMENTS_SUCCESS, 'f']
    : [
        FETCH_SCORED_ASSIGNMENTS_REQUEST,
        FETCH_SCORED_ASSIGNMENTS_SUCCESS,
        FETCH_SCORED_ASSIGNMENTS_FAILURE
      ];

  return {
    types,
    promise: api().get(
      `/api/v1/users/${id}/assignments/${assignmentId}/score/`
    ),
    id
  };
}
