import { SET_SHOW_IFRAME } from 'constants/actionTypes';

/**
 * Set locale action
 *
 * @param{boolean} show iframe
 * @returns {{types: *[], promise: Request }}
 */

export default function setShowIframe(showIframe) {
  return {
    type: SET_SHOW_IFRAME,
    showIframe
  };
}
