import {
  LIST_QUESTIONS_REQUEST,
  LIST_QUESTIONS_SUCCESS,
  LIST_QUESTIONS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List questions action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function listQuestions() {
  return {
    types: [
      LIST_QUESTIONS_REQUEST,
      LIST_QUESTIONS_SUCCESS,
      LIST_QUESTIONS_FAILURE
    ],
    promise: api().get('/api/v1/questions/')
  };
}
