// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './styles.css';
import './styles.css';

// Actions
import { getRemainingTime } from 'helpers';

/**
 * Remaining time box
 * @class
 */
@connect((state) => ({
  schedule: state.simulations.get('schedule')
}))
export default class RemainingTimeBox extends Component {
  // Prop Types
  static propTypes = {
    schedule: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      timer: {
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0'
      }
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { schedule } = this.props;
    let { timer } = this.state;

    this.interval = setInterval(() => {
      const remaining = getRemainingTime(schedule.get('start_time'));
      timer = {
        days: remaining.days,
        hours: remaining.hours,
        minutes: remaining.minutes,
        seconds: remaining.seconds
      };

      this.setState({ timer });
    }, 1000);
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { timer } = this.state;

    return (
      <div className={styles['time-box']}>
        <h5>
          <FormattedMessage id="TIME_LEFT_TO_LIVE_SESSION" />
        </h5>
        <div className={styles['time-box-container']}>
          <div className={styles.item}>
            <span className={styles.number}>{timer.minutes}</span>
            <FormattedMessage id="TIMER.minutes" />
          </div>
          <div className={styles.item}>
            <span className={styles.number}>:</span>
          </div>
          <div className={styles.item}>
            <span className={styles.number}>
              {timer.seconds <= 0 ? 0 : timer.seconds}
            </span>
            <FormattedMessage id="TIMER.seconds" />
          </div>
        </div>
      </div>
    );
  }
}
