import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { IndicatorGraph } from '../../../components';

import styles from '../styles.css';
import '../styles.css';

const getPersonality = (props) => {
  const { data, route, user } = props;
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();
  const isParticipant = user.get('active_role') === 'participant';

  return (
    <div className={styles.personality}>
      {isParticipant &&
        report.has('simulations_count') &&
        report.get('simulations_count') > 1 && (
          <div className="text-center">
            (<FormattedMessage id="ONLY_ONE_ASSESS_INFO" />)
          </div>
        )}

      <Alert color="info" isOpen className={styles['analytics-infobox']}>
        <FormattedMessage id="PERSONALITY_INFO" />
      </Alert>
      {current ? (
        <div className={styles.facets}>
          {report.get('facets').map((facet, index) => (
            <div key={index}>
              <Row
                className={`${styles['facet-row']} ${
                  index % 2 === 0 ? 'html2pdf__page-break' : ''
                }`}
              >
                <Col xs={2}>
                  <h6>
                    <FormattedMessage id={facet.get('name')} />
                  </h6>
                </Col>
                <Col xs={10}>
                  <IndicatorGraph
                    facet={report
                      .get('facets')
                      .find((f) => f.get('id') === facet.get('id'))}
                    betterIf={facet.get('better_if')}
                  />
                </Col>
              </Row>
              <div className={styles['grey-box']}>
                <p>
                  <strong>
                    <FormattedMessage id={facet.get('name')} />
                  </strong>{' '}
                  - <FormattedMessage id={facet.get('definition')} />{' '}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route,
  user: state.auth.get('loggedUser')
}))(
  React.memo(
    getPersonality,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
