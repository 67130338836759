// Vendor
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './styles.css';
import './styles.css';

const NotSupported = () => (
  <div className={styles['it-check']}>
    <h1>
      <FormattedMessage id="IT_CHECK" />
    </h1>
    <h2>
      <FormattedMessage id="BROWSER_CHECK" />
    </h2>

    <p className={styles.danger}>
      <FormattedMessage id="YOUR_BROWSER_IS_NOT_SUPPORTED" />
    </p>
    <p>
      <FormattedMessage id="BROWSER_IS_NOT_SUPPORTED_INFO" />
      <br />
      <a
        href="https://www.google.com/intl/en/chrome/browser/desktop/index.html"
        target="_blank"
        className={styles.blue}
      >
        Google Chrome
      </a>
      ,{' '}
      <a
        href="https://www.mozilla.org/en/firefox/new/"
        target="_blank"
        className={styles.blue}
      >
        Mozilla Firefox
      </a>{' '}
      <FormattedMessage id="OR" />{' '}
      <a
        href="https://www.microsoft.com/en-us/edge"
        target="_blank"
        className={styles.blue}
      >
        Microsoft Edge
      </a>
      .
    </p>

    <div className={styles.browsers}>
      <img src="/assets/img/browsers.jpg?v=4" alt="browsers" />
    </div>

    <FormattedMessage id="CONTACT_IT_DEPARTMENT" />
  </div>
);

export default NotSupported;
