import {
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Events action
 *
 * @param{Object} body
 * @returns {{types: *[], promise: Request }}
 */

export default function events(body) {
  let types;
  let promise;
  const { type } = body;
  const { simulationId } = body;
  const { data } = body;
  const { id } = body;

  if (!data.language) {
    data.language = 'english'; // eslint-disable-line
  }
  if (!data.hidden) {
    data.hidden = false; // eslint-disable-line
  }
  if (!data.prework) {
    data.prework = false; // eslint-disable-line
  }
  if (!data.roleplay) {
    data.roleplay = false; // eslint-disable-line
  }

  switch (type) {
    case 'create':
      types = [
        CREATE_EVENT_REQUEST,
        CREATE_EVENT_SUCCESS,
        CREATE_EVENT_FAILURE
      ];
      promise = api().post(`/api/v1/simulations/${simulationId}/events/`, data);
      break;
    case 'edit':
      types = [EDIT_EVENT_REQUEST, EDIT_EVENT_SUCCESS, EDIT_EVENT_FAILURE];
      promise = api().put(
        `/api/v1/simulations/${simulationId}/events/${id}/`,
        data
      );
      break;
    case 'delete':
      types = [
        DELETE_EVENT_REQUEST,
        DELETE_EVENT_SUCCESS,
        DELETE_EVENT_FAILURE
      ];
      promise = api().del(`/api/v1/simulations/${simulationId}/events/${id}/`);
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    simulationId,
    id,
    language: data.language
  };
}
