// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Components
import {
  Terms,
  ITCheck,
  UserZone,
  Demographic,
  Schedule,
  Prework
} from './steps';

/**
 * Invitation
 * @param props
 * @constructor
 */
const Invitation = (props) => (
  <div>
    <Helmet title="Pinsight - Invitation" />
    {props.children}
  </div>
);

// Prop types
Invitation.propTypes = {
  children: PropTypes.any
};

Invitation.Terms = Terms;
Invitation.ITCheck = ITCheck;
Invitation.UserZone = UserZone;
Invitation.Demographic = Demographic;
Invitation.Schedule = Schedule;
Invitation.Prework = Prework;

export default Invitation;
