import {
  CLIENT_NOT_FOUND,
  DETAIL_FETCH_ERROR,
  PARTNER_NOT_FOUND,
  USER_NOT_FOUND
} from '../../constants/errors';

export default function onDetailEnterHandler(actionCreator) {
  return (currentRoute, nextRoute, router, dispatch) => {
    const { id } = nextRoute.vars;

    return dispatch(actionCreator(id)).then((action) => {
      if (action.error && action.error.body) {
        switch (action.error.body) {
          case USER_NOT_FOUND:
          case PARTNER_NOT_FOUND:
          case CLIENT_NOT_FOUND:
          default:
            return (window.location = `/users?${DETAIL_FETCH_ERROR}=${action.error.body}`);
        }
      }

      return true;
    });
  };
}
