import { AND, OR } from 'constants/operators';

export default function getTagsSearchString(tags, encode = true) {
  const string = tags
    .map((t) => t.name)
    .join('|')
    // replace OR values
    .replaceAll(`|${OR.name}|`, '|')
    // replace AND values
    .replaceAll(`|${AND.name}|`, ',')
    // remove first / last unused AND operator
    .replaceAll(`${AND.name}|`, '')
    .replaceAll(`|${AND.name}`, '')
    // remove first / last unused OR operator
    .replaceAll(`${OR.name}|`, '')
    .replaceAll(`|${OR.name}`, '');

  return encode ? encodeURIComponent(string) : string;
}
