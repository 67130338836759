import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';

import modals from '../../views/modals.css';
import '../../views/modals.css';
import classNames from 'classnames';

const ConfirmModal = (props) => {
  const {
    confirmMessage,
    onClose,
    header,
    message,
    submitAction,
    centerBody
  } = props;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
        {header}
      </ModalHeader>
      <ModalBody
        className={classNames(
          modals['modal-body'],
          centerBody && modals['text-center']
        )}
      >
        <div>{message}</div>
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={5} className={modals['modal-button-col']}>
            <Button
              id="cancel_modal"
              name="cancel_modal"
              color="secondary"
              onClick={onClose}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CANCEL" />
            </Button>{' '}
          </Col>
          <Col xs={7} className={modals['modal-button-col']}>
            <Button
              id="submit_modal"
              name="submit_modal"
              color="primary"
              onClick={submitAction}
              className={modals['footer-button']}
            >
              <FormattedMessage id={confirmMessage} />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  confirmMessage: 'CONFIRM'
};

ConfirmModal.propTypes = {
  confirmMessage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitAction: PropTypes.func,
  centerBody: PropTypes.bool
};

export default ConfirmModal;
