import fieldInputs from './fieldInputs';

const getParticipantFields = (form, user, change) => {
  const data = [
    { name: 'firstname', value: user.get('firstname') },
    { name: 'surname', value: user.get('surname') },
    { name: 'email', value: user.get('email') },
    { name: 'forwarding_email', value: user.get('forwarding_email') },
    { name: 'tel_mobile', value: user.get('tel_mobile') },
    { name: 'tel_secondary', value: user.get('tel_secondary') },
    { name: 'status', value: user.get('status') }
  ];

  fieldInputs(form, data, change);
};

export default getParticipantFields;
