import {
  RESCHEDULE_USER_REQUEST,
  RESCHEDULE_USER_SUCCESS,
  RESCHEDULE_USER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Reschedule action
 *
 * @param{Number} id
 * @param{Number} data
 * @returns {{types: *[], promise: Request }}
 */

export default function reschedule(id, data) {
  return {
    types: [
      RESCHEDULE_USER_REQUEST,
      RESCHEDULE_USER_SUCCESS,
      RESCHEDULE_USER_FAILURE
    ],
    promise: api().post(`/api/v1/users/${id}/reschedule/`, data)
  };
}
