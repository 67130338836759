import React from 'react';

import DownloadPDFModal from '../../../DownloadPDFModal';
import downloadPdf from './downloadPdf';

export default (data, props, context) => {
  const { addModal } = context;
  addModal(
    <DownloadPDFModal
      data={data}
      onSubmit={(formData, files) =>
        downloadPdf(formData, files, props, context)
      }
    />
  );
};
