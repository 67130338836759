// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

// Components
import { BuildTable, Link } from 'components';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Label,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';

import {
  BehaviorBasicInformation as BasicInformation,
  BehaviorDefinition,
  MathForm
} from 'forms';
import { SubmitPublishModal } from '../../../EditSimulationTable/modals';

// Styles
import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';

// Actions
import { editBehavior, indicators, fetchFacets } from 'actions/behaviors';
import { getRelationshipTable, getIndicatorsTable } from '../../actions';

// Constants
const editBehaviorTabs = [
  { id: '1', title: 'basic_information' },
  { id: '2', title: 'definition' },
  { id: '3', title: 'relationship' },
  { id: '4', title: 'indicators' },
  { id: '5', title: 'math' }
];

/**
 * Main
 * @class
 */
@connect(
  (state) => ({
    users: state.users.get('users'),
    id: Number(state.router.route.vars.id),
    behavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        editBehavior,
        indicators,
        fetchFacets,
        transitionTo
      },
      dispatch
    );
  }
)
export default class Main extends Component {
  // PropTypes
  static propTypes = {
    users: PropTypes.object,
    id: PropTypes.number,
    editBehavior: PropTypes.func.isRequired,
    behavior: PropTypes.object,
    transitionTo: PropTypes.func.isRequired,
    indicators: PropTypes.func.isRequired,
    fetchFacets: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired
  };

  // ContextTypes
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.subTab || '1',
      mathErrors: []
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { id, indicators, fetchFacets } = this.props;

    const promises = [
      fetchFacets(),
      indicators({
        type: 'fetch',
        behaviorId: id,
        data: {}
      })
    ];

    Promise.all(promises);
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.subTab !== nextProps.route.query.subTab) {
      this.toggle(nextProps.route.query.subTab || '1');
    }
  }

  /**
   * Get tab content
   * @param tab
   */
  getTabContent = (tab) => {
    const { behavior } = this.props;

    switch (tab) {
      case '1':
        return <BasicInformation onSubmit={this.handleSubmit.bind(this)} />;
      case '2':
        return <BehaviorDefinition onSubmit={this.handleSubmit.bind(this)} />;
      case '3':
        return (
          <BuildTable table={getRelationshipTable(behavior, this.context)} />
        );
      case '4':
        return (
          <BuildTable table={getIndicatorsTable(behavior, this.context)} />
        );
      case '5':
        return (
          <MathForm
            mathErrors={this.state.mathErrors}
            onSubmit={this.handleSubmit.bind(this)}
          />
        );
      default:
        return <BasicInformation onSubmit={this.handleSubmit.bind(this)} />;
    }
  };

  /**
   * Toggle
   * @param tab
   */
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit(data) {
    const { id, editBehavior } = this.props;
    const { addAlert } = this.context;

    this.setState({ mathErrors: [] });

    editBehavior(id, data).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
        });
      }
    });
  }

  /**
   * Submit publish
   */
  submitPublish = () => {
    const { id, editBehavior, behavior } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    this.setState({ mathErrors: [] });

    editBehavior(id, {
      language: 'english',
      published: !behavior.get('published')
    }).then((action) => {
      window.scrollTo(0, 0);
      if (action.error) {
        if (typeof action.error.body === 'string') {
          addAlert({
            color: 'danger',
            content: <FormattedMessage id={action.error.body} />
          });
        } else if (action.error.body.translation.errors) {
          const { errors } = action.error.body.translation;
          const errorArray = Object.keys(errors).map((e) => e);
          const formatted = errorArray.join(', ');

          if (formatted.indexOf('m') > -1 || formatted.indexOf('sd') > -1) {
            this.toggle('5');
          }
          this.setState({ mathErrors: errorArray });

          addAlert({
            color: 'danger',
            content: (
              <div>
                {Object.keys(errors).map((key) => (
                  <div>
                    {key.toUpperCase()} -
                    <FormattedMessage id={errors[key]} />
                  </div>
                ))}
              </div>
            )
          });
        }
      } else {
        addAlert({
          color: 'success',
          content: (
            <FormattedMessage
              id={`SUCCESSFULLY_${
                !behavior.get('published') ? 'PUBLISHED' : 'UNPUBLISHED'
              }`}
              values={{ item: intl.formatMessage({ id: 'BEHAVIOR' }) }}
            />
          )
        });
      }
      removeModal();
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { behavior, route } = this.props;
    const { addModal } = this.context;
    const { activeTab } = this.state;

    return (
      <div className={styles['simulation-subtabs-div']}>
        <Nav tabs>
          {editBehaviorTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.title}`}
                to={`/simulations/behaviors/edit/${behavior.get('id')}`}
                query={{
                  tab: route.query.tab || '1',
                  subTab: tab.id.toString()
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`EDIT_BEHAVIOR_TABS.${tab.title}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {behavior.get('published') ? (
            <span className={styles['published-message']}>
              <FormattedMessage
                id="PUBLISHED_ON"
                values={{
                  date: moment(
                    behavior
                      .get('languages')
                      .find((l) => l.get('language') === 'english')
                      .get('published_at')
                  ).format('DD MMM YYYY hh:mm A')
                }}
              />
            </span>
          ) : (
            <div className={styles['custom-switcher']}>
              <FormattedMessage id="PUBLISH" />
              <Label
                id="publish_button_label"
                className={styles['custom-switch']}
              >
                <input
                  id="publish_button"
                  name="publish_button"
                  type="checkbox"
                  onChange={() =>
                    addModal(
                      <SubmitPublishModal
                        action={this.submitPublish}
                        publish={behavior.get('published')}
                        type="BEHAVIOR"
                      />
                    )
                  }
                  checked={false}
                />
                <div className={styles['switch-slider']} />
              </Label>
            </div>
          )}
        </Nav>

        <TabContent
          activeTab={activeTab}
          className={styles['simulation-subtabs']}
        >
          {editBehaviorTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">{this.getTabContent(activeTab)}</Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
