import {
  DELETE_ENTITY_REQUEST,
  DELETE_ENTITY_SUCCESS,
  DELETE_ENTITY_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Delete entity action
 *
 * @param{Number} id
 * @param{String} entity
 * @returns {{types: *[], promise: Request }}
 */

export default function deleteEntity(id, entity) {
  return {
    types: [
      DELETE_ENTITY_REQUEST,
      DELETE_ENTITY_SUCCESS,
      DELETE_ENTITY_FAILURE
    ],
    promise: api().del(`/api/v1/${entity}s/${id}/`),
    id,
    entity
  };
}
