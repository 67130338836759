import {
  TWILIO_CONNECT_REQUEST,
  TWILIO_CONNECT_SUCCESS,
  TWILIO_CONNECT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Twilio phone call connect action
 *
 * @returns {{promise}}
 */

export default function twilioPhoneCallConnect(assessorId, participantId) {
  return {
    types: [
      TWILIO_CONNECT_REQUEST,
      TWILIO_CONNECT_SUCCESS,
      TWILIO_CONNECT_FAILURE
    ],
    promise: api().get(
      `/api/v1/twilio/phone-call-auth/?identity=${assessorId}&participantId=${participantId}`
    )
  };
}
