import { Map, OrderedMap, fromJS } from 'immutable';
import {
  FETCH_ANALYTICS_REQUEST,
  FETCH_ANALYTICS_SUCCESS,
  FETCH_ANALYTICS_FAILURE,
  FETCH_ANALYTICS_OVERVIEW_REQUEST,
  FETCH_ANALYTICS_OVERVIEW_SUCCESS,
  FETCH_ANALYTICS_OVERVIEW_FAILURE,
  FETCH_ANALYTICS_DETAIL_REQUEST,
  FETCH_ANALYTICS_DETAIL_SUCCESS,
  FETCH_ANALYTICS_DETAIL_FAILURE,
  FETCH_REPORT_USERS_REQUEST,
  FETCH_REPORT_USERS_SUCCESS,
  FETCH_REPORT_USERS_FAILURE,
  FILTER_ANALYTICS_DATA_REQUEST,
  FILTER_ANALYTICS_DATA_SUCCESS,
  FILTER_ANALYTICS_DATA_FAILURE,
  RESET_FILTER_ANALYTICS_DATA,
  SHARE_REPORT_REQUEST,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_FAILURE,
  REMOVE_REPORT_REQUEST,
  REMOVE_REPORT_SUCCESS,
  REMOVE_REPORT_FAILURE,
  UPDATE_NARRATIVE_REQUEST,
  UPDATE_NARRATIVE_SUCCESS,
  UPDATE_NARRATIVE_FAILURE,
  GET_CLIMATE_REQUEST,
  GET_CLIMATE_SUCCESS,
  GET_CLIMATE_FAILURE,
  RESET_CLIMATE_DATA,
  GET_NARRATIVE_SUCCESS,
  GET_SHAREABLE_STAKEHOLDERS_REQUEST,
  GET_SELECTED_STAKEHOLDERS_SUCCESS,
  GET_SHAREABLE_STAKEHOLDERS_SUCCESS,
  GET_SHAREABLE_STAKEHOLDERS_FAILURE,
  UPDATE_SELECTED,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  overviewData: OrderedMap(),
  allData: OrderedMap(),
  analyticsData: OrderedMap(),
  selectedAnalyticsUsers: [],
  analyticsDetailLoading: Map(),
  reportUsers: Map(),
  climateData: Map(),
  shareableStakeholders: Map()
});

export default function analytics(state = initialState, action) {
  switch (action.type) {
    case FETCH_ANALYTICS_REQUEST:
      return state.set('analyticsLoading', true);
    case FETCH_ANALYTICS_SUCCESS: {
      const result = action.result.body;

      return state
        .set('analyticsLoading', false)
        .mergeDeepIn(
          ['allData'],
          OrderedMap(result.map((r) => [r.id, fromJS(r)]))
        )
        .set('analyticsData', OrderedMap(result.map((r) => [r.id, fromJS(r)])));
    }
    case FETCH_ANALYTICS_FAILURE:
      return state.set('analyticsLoading', false);
    case FETCH_ANALYTICS_OVERVIEW_REQUEST:
      return state.set('analyticsOverviewLoading', true);
    case FETCH_ANALYTICS_OVERVIEW_SUCCESS: {
      const result = action.result.body;

      return state.set('analyticsOverviewLoading', false).set(
        'overviewData',
        OrderedMap(
          result.map((r) => {
            const id = r.assessment_id
              ? `${r.simulation_id}-${r.assessment_id}`
              : r.simulation_id;
            r.uuid = id; // eslint-disable-line no-param-reassign

            return [id, fromJS(r)];
          })
        )
      );
    }
    case FETCH_ANALYTICS_OVERVIEW_FAILURE:
      return state.set('analyticsOverviewLoading', false);
    case FETCH_ANALYTICS_DETAIL_REQUEST: {
      const { report_id } = action.data;
      const ids =
        typeof report_id === 'number' ? [report_id] : report_id.split(',');

      return state.withMutations((newState) => {
        ids.map((id) =>
          newState.setIn(['analyticsDetailLoading', Number(id)], true)
        );
      });
    }
    case FETCH_ANALYTICS_DETAIL_SUCCESS: {
      const result = action.result.body;
      const { report_id } = action.data;
      const ids =
        typeof report_id === 'number' ? [report_id] : report_id.split(',');

      return state.withMutations((newState) => {
        ids.map((id) =>
          newState.setIn(['analyticsDetailLoading', Number(id)], false)
        );

        newState
          .mergeDeepIn(['allData'], Map(result.map((r) => [r.id, fromJS(r)])))
          .mergeDeepIn(
            ['analyticsData'],
            Map(result.map((r) => [r.id, fromJS(r)]))
          );
      });
    }
    case FETCH_ANALYTICS_DETAIL_FAILURE: {
      const { report_id } = action.data;
      const ids =
        typeof report_id === 'number' ? [report_id] : report_id.split(',');

      return state.withMutations((newState) => {
        ids.map((id) =>
          newState.setIn(['analyticsDetailLoading', Number(id)], false)
        );
      });
    }
    case FETCH_REPORT_USERS_REQUEST:
      return state.set('reportUsersLoading', true);
    case FETCH_REPORT_USERS_SUCCESS: {
      const reportId = action.id;
      const { sharing } = action.result.body;

      return state
        .set('reportUsersLoading', false)
        .setIn(
          ['reportUsers', reportId],
          Map(sharing.stakeholders.map((user) => [user.id, fromJS(user)]))
        );
    }
    case FETCH_REPORT_USERS_FAILURE:
      return state.set('reportUsersLoading', false);
    case FILTER_ANALYTICS_DATA_REQUEST:
      return state.set('analyticsLoading', true);
    case FILTER_ANALYTICS_DATA_SUCCESS: {
      const result = action.result.body;

      return state
        .set('analyticsLoading', false)
        .mergeDeepIn(
          ['allData'],
          OrderedMap(result.map((r) => [r.id, fromJS(r)]))
        )
        .set('analyticsData', Map(result.map((r) => [r.id, fromJS(r)])));
    }
    case FILTER_ANALYTICS_DATA_FAILURE:
      return state.set('analyticsLoading', false);
    case RESET_FILTER_ANALYTICS_DATA:
      return state.set('analyticsData', OrderedMap());
    case SHARE_REPORT_REQUEST:
      return state.set('sharingLoading', true);
    case SHARE_REPORT_SUCCESS: {
      const { report, sharing } = action;

      return state
        .set('sharingLoading', false)
        .setIn(['analyticsData', report, 'sharing'], fromJS(sharing))
        .setIn(['allData', report, 'sharing'], fromJS(sharing));
    }
    case SHARE_REPORT_FAILURE:
      return state.set('sharingLoading', false);
    case REMOVE_REPORT_REQUEST:
      return state.set('removeReportLoading', true);
    case REMOVE_REPORT_SUCCESS: {
      const id = Number(action.id);

      return state
        .set('removeReportLoading', false)
        .deleteIn(['allData', id])
        .deleteIn(['analyticsData', id]);
    }
    case REMOVE_REPORT_FAILURE:
      return state.set('removeReportLoading', false);
    case UPDATE_NARRATIVE_REQUEST:
      return state.set('updateNarrativeLoading', true);
    case UPDATE_NARRATIVE_SUCCESS: {
      const { report_id } = action;
      const data = action.result.body;

      const hasNarrative = !(
        data.narrative == null ||
        data.narrative === '' ||
        data.narrative === ' '
      );

      return state
        .setIn(['allData', report_id, 'narrative'], fromJS(data))
        .setIn(['analyticsData', report_id, 'narrative'], fromJS(data))
        .setIn(['allData', report_id, 'has_narrative'], hasNarrative)
        .setIn(['analyticsData', report_id, 'has_narrative'], hasNarrative)
        .set('updateNarrativeLoading', false);
    }
    case UPDATE_NARRATIVE_FAILURE:
      return state.set('updateNarrativeLoading', false);
    case GET_CLIMATE_REQUEST:
      return state.set('climateDataLoading', true);
    case GET_CLIMATE_SUCCESS: {
      const { facets } = action.result.body;

      return state
        .set('climateData', Map(facets.map((f) => [f.name, fromJS(f)])))
        .set('climateDataLoading', false)
        .set('climateDataError', null);
    }
    case GET_CLIMATE_FAILURE:
      return state
        .set('climateDataLoading', false)
        .set('climateDataError', action.error.body);
    case RESET_CLIMATE_DATA:
      return state.set('climateData', Map());
    case GET_NARRATIVE_SUCCESS: {
      const { report_id } = action;
      const data = action.result.body;

      return state
        .setIn(['allData', report_id, 'narrative'], fromJS(data))
        .setIn(['analyticsData', report_id, 'narrative'], fromJS(data));
    }
    case GET_SHAREABLE_STAKEHOLDERS_REQUEST:
      return state
        .set('shareableStakeholdersLoading', true)
        .set('enabled', true);
    case GET_SELECTED_STAKEHOLDERS_SUCCESS:
    case GET_SHAREABLE_STAKEHOLDERS_SUCCESS: {
      const { sharing } = action.result.body;

      return state
        .set('shareableStakeholdersLoading', false)
        .set(
          'shareableStakeholders',
          Map(sharing?.stakeholders?.map((s) => [s.id, fromJS(s)]))
        )
        .set('enabled', true);
    }
    case GET_SHAREABLE_STAKEHOLDERS_FAILURE:
      return state
        .set('shareableStakeholders', Map())
        .set('shareableStakeholdersLoading', false)
        .set('enabled', false);
    case UPDATE_SELECTED:
      return state.set('selectedAnalyticsUsers', action.selected);
    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
