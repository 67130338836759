import {
  POST_TICKET_REQUEST,
  POST_TICKET_SUCCESS,
  POST_TICKET_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Post ticket action
 *
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function postTicket(data) {
  return {
    types: [POST_TICKET_REQUEST, POST_TICKET_SUCCESS, POST_TICKET_FAILURE],
    promise: api().post('/api/v1/support/ticket/', data)
  };
}
