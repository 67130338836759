import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';

import Calendar from '../../views/Auth/Invitation/steps/Schedule/components/Calendar';
import {
  Button,
  Col,
  InputGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import { RESET_FORM_ERRORS } from 'constants/actionTypes';
import { ToolTip } from 'components';

import { setFormError } from 'actions/global';

import modals from '../../views/modals.css';
import '../../views/modals.css';
import styles from '../styles.css';
import '../styles.css';
import api from '../../api';

const SCHEDULED_AT = 'scheduled_at';
const PARTNER = 'partner_id';

@connect(
  (state, ownProps) => ({
    simulations: state.simulations.get('simulations'),
    selectedSimulation: Number(
      formValueSelector(ownProps.form)(state, 'active_simulation.simulation_id')
    ),
    selectedLanguage: formValueSelector(ownProps.form)(
      state,
      'active_simulation.language'
    ),
    scheduledAt: formValueSelector(ownProps.form)(state, SCHEDULED_AT),
    partner:
      ownProps.partner != null
        ? ownProps.partner
        : state.auth.hasIn(['loggedUser', 'client']) &&
          state.auth.getIn(['loggedUser', 'client']) != null
        ? state.auth.getIn(['loggedUser', 'partner_id'])
        : formValueSelector(ownProps.form)(state, PARTNER),
    formErrors: state.app.get('formErrors') || {}
  }),
  (dispatch) => bindActionCreators({ change, setFormError, dispatch }, dispatch)
)
export default class ScheduleManually extends React.Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    selectedSimulation: PropTypes.number,
    selectedLanguage: PropTypes.string,
    scheduledAt: PropTypes.object,
    timezone: PropTypes.string,
    setFormError: PropTypes.func.isRequired,
    seed: PropTypes.number,
    fieldErrors: PropTypes.object,
    formErrors: PropTypes.object,
    partner: PropTypes.number,
    simulations: PropTypes.object
  };

  state = {
    modalOpen: false,
    updatedAt: null,
    isPPP: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change, form, setFormError } = this.props;

    if (this.props.timezone !== nextProps.timezone) {
      setFormError({ timezone: {} });
      change(form, SCHEDULED_AT, null);
    }
  }

  // fix for cancelled simulation
  componentDidUpdate(prevProps) {
    if (
      this.props.simulations.size === 0 &&
      this.props.selectedSimulation &&
      this.props.selectedLanguage &&
      (prevProps.selectedSimulation !== this.props.selectedSimulation ||
        prevProps.selectedLanguage !== this.props.selectedLanguage)
    ) {
      api()
        .get(`/api/v1/simulations/isppp/${this.props.selectedSimulation}/`)
        .then((response) => {
          this.setState({ isPPP: response.body });
        });
    }
  }

  toggleModal = () => {
    const { change, dispatch, form, setFormError, timezone } = this.props;

    if (!timezone) {
      setFormError({ timezone: { errors: ['ERRORS.FORM_BLANK_VALUE'] } });
      return document.getElementById('timezone').scrollIntoView();
    }

    if (this.props.scheduledAt != null) {
      this.setState({
        modalOpen: false
      });

      change(form, SCHEDULED_AT, null);
      dispatch({ type: RESET_FORM_ERRORS });
    } else {
      this.setState({
        modalOpen: !this.state.modalOpen
      });
    }

    return true;
  };

  onClose = () => {
    const { change, form } = this.props;
    this.setState({ modalOpen: false });
    change(form, SCHEDULED_AT, null);
  };

  submitSchedule = () => {
    this.setState({ modalOpen: false });
  };

  onCalendarSelect = (date) => {
    const { change, form } = this.props;

    change(form, SCHEDULED_AT, date);
    this.setState({ updatedAt: Date.now() });
  };

  render() {
    const {
      selectedSimulation,
      selectedLanguage,
      scheduledAt,
      timezone,
      seed,
      partner,
      formErrors
    } = this.props;
    let { disabled } = this.props;
    const { modalOpen, isPPP } = this.state;
    const errors = [];

    if (selectedSimulation && this.props.simulations.size) {
      this.props.simulations.first().forEach((simulation) => {
        if (
          Number(selectedSimulation) === Number(simulation.get('id')) &&
          simulation.get('is_psychometrics_only')
        ) {
          disabled = true;
        }
      });
    }

    if (formErrors[SCHEDULED_AT] != null) {
      const scheduledErrors = formErrors[SCHEDULED_AT].errors;

      if (!Array.isArray(scheduledErrors)) {
        Object.keys(scheduledErrors).map((key) =>
          scheduledErrors[key].errors.map((e) => errors.push(e))
        );
      } else {
        scheduledErrors.map((e) => errors.push(e));
      }
    }

    const isEmpty = (value) =>
      value == null || value === 'null' || value === '' || value === ' ';

    return (
      <Row>
        <Col xs={12}>
          <Col xs={12}>
            <InputGroup>
              <Label check>
                <input
                  disabled={
                    isPPP ||
                    disabled ||
                    isEmpty(selectedSimulation) ||
                    isEmpty(selectedLanguage)
                  }
                  type="checkbox"
                  onChange={this.toggleModal}
                  checked={scheduledAt != null}
                />
                <FormattedMessage id="FORM_FIELDS.schedule_manually" />{' '}
                <ToolTip
                  id="SCHEDULE_MANUALLY_JIT"
                  index="SCHEDULE_MANUALLY_JIT"
                  hint={<FormattedMessage id="SCHEDULE_MANUALLY_JIT" />}
                />{' '}
                {scheduledAt != null && (
                  <span>
                    [{' '}
                    <FormattedMessage
                      id="ASSESSMENT_SCHEDULED_FOR"
                      values={{
                        date: `${moment(scheduledAt.day).format(
                          'DD. MMM YYYY'
                        )} ${scheduledAt.time}`
                      }}
                    />{' '}
                    ]
                  </span>
                )}
              </Label>
            </InputGroup>
            {scheduledAt != null &&
              errors.map((error) => (
                <div className={styles.form__error}>
                  <FormattedMessage id={error} />
                </div>
              ))}
          </Col>
        </Col>

        {modalOpen && (
          <Modal isOpen toggle={() => false} size="lg">
            <ModalHeader toggle={this.onClose} style={{ textAlign: 'center' }}>
              <FormattedMessage id="FORM_FIELDS.schedule_manually" />
            </ModalHeader>
            <ModalBody
              className={modals['modal-body']}
              style={{ marginBottom: 50 }}
            >
              <Calendar
                manualSchedule
                selectedSimulation={{
                  id: selectedSimulation,
                  language: selectedLanguage
                }}
                timezone={timezone}
                onSelect={this.onCalendarSelect}
                seed={seed}
                partner={isEmpty(partner) ? null : partner}
              />
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col xs={5} className={modals['modal-button-col']}>
                  <Button
                    id="cancel_access"
                    name="cancel_access"
                    color="secondary"
                    onClick={this.onClose}
                    className={modals['footer-button']}
                  >
                    <FormattedMessage id="CANCEL" />
                  </Button>{' '}
                </Col>
                <Col xs={7} className={modals['modal-button-col']}>
                  <Button
                    id="submit_access"
                    name="submit_access"
                    color="primary"
                    onClick={this.submitSchedule}
                    className={modals['footer-button']}
                    disabled={scheduledAt == null || scheduledAt.time == null}
                  >
                    <FormattedMessage id="SCHEDULE" />
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        )}
      </Row>
    );
  }
}
