import { SET_FORM_ERROR } from 'constants/actionTypes';

/**
 * Set form error
 *
 * @param{Object} error
 * @returns {{types: *[], promise: Request }}
 */

export default function setFormError(error) {
  return {
    type: SET_FORM_ERROR,
    error: {
      body: {
        ...error
      }
    }
  };
}
