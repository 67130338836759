import React, { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';

import api from 'api';

import styles from './styles.css';
import './styles.css';

const DaysPracticed = (props) => {
  const [daysPracticedData, setDaysPracticedData] = useState([]);
  const { user } = props;

  useEffect(() => {
    const fetchData = async () => {
      const response = await api().get(
        `/api/v1/analytics/development/days-practiced/${user.get('id')}/`
      );

      setDaysPracticedData(response.body.daysPracticedData);
    };

    fetchData();
  }, [user]);

  const chartConfig = {
    title: {
      text: ''
    },

    credits: {
      enabled: false
    },

    yAxis: {
      title: {
        text: 'Days Practiced'
      },
      allowDecimals: false
    },

    xAxis: {
      title: {
        text: 'No. of Days App Enabled'
      }
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },

    tooltip: {
      formatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        return `Days practiced: ${this.y}`;
      }
    },

    plotOptions: {
      series: {
        pointStart: 0
      },
      line: {
        marker: {
          enabled: false
        }
      }
    },

    series: [
      {
        data: daysPracticedData,
        showInLegend: false
      }
    ]
  };

  return (
    <div>
      <h6 className={styles['section-header']}>Days Practiced</h6>
      <ReactHighcharts config={chartConfig} />
      <p style={{ fontSize: '14px' }}>
        This graph presents the total number of days the participant has
        practiced using The Pinsight App. If the line rises, the participant has
        been consistently using the app to practice their skills. If the line is
        flat, the participant did not practice at that time.
      </p>
    </div>
  );
};

export default DaysPracticed;
