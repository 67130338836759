// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import { StageProgress, Loader } from 'components';
import { QuestionnaireForm } from 'forms';
import { Button } from 'reactstrap';
import { RescheduleModal } from '../../components';

// Styles
import styles from './styles.css';
import './styles.css';

// Actions
import { getRemainingTime } from 'helpers';
import { getAfterSimulationProgress } from '../../../actions';
import api from '../../../../../../../api';

/**
 * Questionnaire
 * @class
 */
@connect((state) => ({
  user: state.auth.get('loggedUser'),
  schedule: state.simulations.get('schedule')
}))
export default class Questionnaire extends Component {
  // Prop types
  static propTypes = {
    user: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      loadingAvailability: false,
      practiceDisabled: true
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { schedule, user } = this.props;
    let { timer } = this.state;

    api()
      .get(`/api/v1/sim/has-prework/`)
      .then((res) => {
        this.setState({ practiceDisabled: res.body });
      });

    const isPsychometricsOnly =
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'is_psychometrics_only']);

    if (!isPsychometricsOnly) {
      this.interval = setInterval(() => {
        const remaining = getRemainingTime(schedule.get('start_time'));
        timer = `${remaining.days}d ${remaining.hours}h ${remaining.minutes}m ${remaining.seconds}s`;
        this.setState({ timer });
      }, 1000);
    }
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * Get stages
   * @returns {*[]}
   */
  getStages = () => {
    const { timer, practiceDisabled } = this.state;
    const { addModal } = this.context;
    const { user } = this.props;

    const stages = [
      {
        title: <FormattedMessage id="QUESTIONNAIRE_HEADING" />,
        bottom: null,
        active: true,
        finished: false
      },
      {
        title: <FormattedMessage id="LEARNING_EFFICIENCY" />,
        bottom: null,
        active: false,
        finished: false
      },
      {
        title: practiceDisabled ? (
          <FormattedMessage id="PRACTICE_MODE" />
        ) : (
          <FormattedMessage id="READY" />
        ),
        bottom: null,
        active: false,
        finished: false
      },
      {
        title: (
          <span>
            <FormattedMessage id="LIVE_SESSION" />
            <br />
            <small>{timer}</small>
          </span>
        ),
        bottom: (
          <Button
            id="reschedule_steps_button"
            name="reschedule_steps_button"
            size="sm"
            type="button"
            color="primary"
            outline
            onClick={() => addModal(<RescheduleModal />)}
          >
            <FormattedMessage id="RESCHEDULE" />
          </Button>
        ),
        active: false,
        finished: false
      }
    ];

    if (
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'is_psychometrics_only'])
    ) {
      stages.pop();
      stages.pop();
    }

    return stages;
  };

  /**
   * Set loading
   * @param value
   * @returns {*}
   */
  setLoading = (value) => this.setState({ loadingAvailability: value });

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { user } = this.props;
    const { loadingAvailability } = this.state;
    let isAfterSimulation = false;
    if (user.getIn(['stages', 'simulation5', 'completed']) === true) {
      isAfterSimulation = true;
    }

    return (
      <div>
        {loadingAvailability && <Loader />}

        <div
          className={styles.questionaire}
          style={loadingAvailability ? { display: 'none' } : {}}
        >
          <StageProgress
            stages={
              isAfterSimulation
                ? getAfterSimulationProgress(user)
                : this.getStages()
            }
          />
          <h1>
            <FormattedMessage id="QUESTIONNAIRE_HEADING" />
          </h1>
          <p>
            <FormattedMessage id="QUESTIONNAIRE_INFO" />
          </p>

          <QuestionnaireForm setLoading={this.setLoading} />
        </div>
      </div>
    );
  }
}
