import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { reduxForm, change, formValueSelector } from 'redux-form';

import { ConfirmModal } from 'components';
import { Button, Col, Row } from 'reactstrap';
import styles from '../../styles.css';

import { validate } from './validate';

import {
  MultipleAddress,
  PartnersAndClients,
  InviteNowBlock,
  TagsBlock
} from '../../blocks';

const form = 'inviteParticipants';

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    allowedSimulations: state.users.get('allowedSimulations'),
    isLoading: state.users.get('inviteUsersLoading'),
    formKey: state.router.route.attrs.role.toUpperCase(),
    user: state.auth.get('loggedUser'),
    clients: state.clients.get('allClients'),
    inviteNow: formValueSelector(form)(state, 'invite_now'),
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    selectedSimulation: formValueSelector(form)(
      state,
      'active_simulation.simulation_id'
    ),
    selectedAssessment: formValueSelector(form)(
      state,
      'active_simulation.assessment_id'
    ),
    selectedLanguage: formValueSelector(form)(
      state,
      'active_simulation.language'
    )
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class InviteForm extends Component {
  static propTypes = {
    allowedSimulations: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    formKey: PropTypes.string,
    user: PropTypes.object,
    clients: PropTypes.object,
    change: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    inviteNow: PropTypes.bool,
    permissions: PropTypes.array,
    selectedSimulation: PropTypes.number,
    selectedAssessment: PropTypes.number,
    selectedLanguage: PropTypes.string
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { user, clients, change, formKey, permissions } = this.props;

    if (
      user.get('isPartner') &&
      clients.size > 0 &&
      formKey === 'ADMIN' &&
      permissions.indexOf(`user_${formKey.toLowerCase()}_add_own`) < 0
    ) {
      change(form, 'client_id', clients.first().get('id'));
    }
    if (user.get('isPartner')) {
      change(form, 'partner_id', user.get('partner_id'));
    }
    if (formKey === 'PARTICIPANT') {
      change(form, 'invite_now', true);
    }
  }

  render() {
    const { addModal, removeModal } = this.context;
    const {
      allowedSimulations,
      handleSubmit,
      inviteNow,
      isLoading,
      formKey,
      user,
      selectedSimulation,
      selectedAssessment,
      transitionTo
    } = this.props;

    let assignments = ['client', 'partner'];
    if (user.get('isPartner')) {
      assignments = ['client'];
    }
    if (user.get('isClient')) {
      assignments = [];
    }

    const isDisabled = false;
    const selectedSimulationId = selectedSimulation
      ? `${selectedSimulation}${selectedAssessment || 0}`
      : null;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Multiple addresses */}
        <MultipleAddress formKey={formKey} />

        {/* Partners and clients */}
        {!user.get('isClient') && (
          <PartnersAndClients assignment={assignments} form={form} />
        )}

        {formKey === 'PARTICIPANT' && <TagsBlock form={form} />}
        {formKey === 'PARTICIPANT' && <InviteNowBlock form={form} />}

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="cancel_invite_form"
              name="cancel_invite_form"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/users')}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_invite_form"
              name="submit_invite_form"
              type="button"
              color="primary"
              onClick={() =>
                selectedSimulationId == null || !inviteNow
                  ? handleSubmit()
                  : addModal(
                      <ConfirmModal
                        header={<FormattedMessage id="ARE_YOU_SURE" />}
                        message={
                          <FormattedHTMLMessage
                            id={
                              selectedAssessment
                                ? 'ARE_YOU_SURE_ASSESSMENT_MULTIPLE_INVITE'
                                : 'ARE_YOU_SURE_DEFAULT_MULTIPLE_INVITE'
                            }
                            values={{
                              simulation: allowedSimulations.has(
                                selectedSimulationId
                              )
                                ? allowedSimulations.getIn([
                                    selectedSimulationId,
                                    'name'
                                  ])
                                : null
                            }}
                          />
                        }
                        submitAction={() => {
                          removeModal();
                          handleSubmit();
                        }}
                        onClose={removeModal}
                      />
                    )
              }
              disabled={isLoading || isDisabled}
            >
              <FormattedMessage
                id={isLoading ? 'LOADING' : 'SEND_INVITATIONS'}
              />
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
