import {
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get client action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */

export default function getClient(id) {
  return {
    types: [GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE],
    promise: api().get(`/api/v1/clients/${id}/`),
    id
  };
}
