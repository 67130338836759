// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Scoring input
 * @class
 */
@connect((state) => ({
  assignment: state.assignments.getIn([
    'assignments',
    Number(state.router.route.vars.id)
  ]),
  loggedUser: state.auth.get('loggedUser'),
  scoredAssignment: state.assignments.get('scoredAssignment').toArray() || []
}))
export default class ScoringInput extends Component {
  // Prop types
  static propTypes = {
    loggedUser: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    scoreSimulation: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    assignment: PropTypes.object.isRequired,
    finished: PropTypes.bool,
    scoredAssignment: PropTypes.array.isRequired,
    disabled: PropTypes.bool
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { scoredAssignment, data } = this.props;
    const defaultValue = scoredAssignment.find(
      (a) =>
        a.get('excercise_id') === data.e.get('id') &&
        a.get('behavior_id') === data.b.get('id') &&
        a.get('indicator_id') === data.i.get('id')
    );

    this.state = {
      value: defaultValue ? defaultValue.get('score').toString() : '',
      defaultValue: defaultValue ? defaultValue.get('score').toString() : null,
      valueError: false,
      success: defaultValue
    };
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loggedUser, scoredAssignment, data } = this.props;

    if (loggedUser.get('active_role') !== 'admin') {
      return;
    }

    const prevDefaultValue = scoredAssignment.find(
      (a) =>
        a.get('excercise_id') === data.e.get('id') &&
        a.get('behavior_id') === data.b.get('id') &&
        a.get('indicator_id') === data.i.get('id')
    );

    const nextDefaultValue = nextProps.scoredAssignment.find(
      (a) =>
        a.get('excercise_id') === data.e.get('id') &&
        a.get('behavior_id') === data.b.get('id') &&
        a.get('indicator_id') === data.i.get('id')
    );

    if (
      (prevDefaultValue == null && nextDefaultValue != null) ||
      (prevDefaultValue != null && nextDefaultValue == null) ||
      (prevDefaultValue != null &&
        prevDefaultValue.get('score') !== nextDefaultValue.get('score'))
    ) {
      this.setState({
        value: nextDefaultValue ? nextDefaultValue.get('score').toString() : '',
        defaultValue: nextDefaultValue
          ? nextDefaultValue.get('score').toString()
          : null,
        valueError: false,
        success: nextDefaultValue
      });
    }
  }

  /**
   * Handle change
   * @param value
   */
  handleChange = debounce((value) => {
    const { action, assignment, data, onSuccess } = this.props;
    const v = value.toString();

    if (v !== '' && v !== '0' && v !== '1' && v !== '2' && v !== '3') {
      this.setState({
        valueError: true,
        success: false
      });
    } else {
      const score = value === '' ? null : Number(value);

      if (score > -1 && score < 4) {
        action(assignment.getIn(['user', 'id']), {
          score,
          excercise_id: data.e.get('id'),
          behavior_id: data.b.get('id'),
          indicator_id: data.i.get('id')
        }).then((action) => {
          if (action.result && action.result.ok) {
            onSuccess();

            this.setState({
              valueError: false,
              success: score != null
            });
          } else {
            this.setState({
              valueError: true,
              success: false
            });
          }
        });
      }
    }
  }, 1000);

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { value, valueError, success } = this.state;
    const { assignment, finished, disabled, data } = this.props;
    const { e, b, i } = data;

    let isAfter = false;

    if (moment().isAfter(moment(assignment.get('assigments_time')))) {
      isAfter = true;
    }

    return (
      <input
        id={`scoring_input_${e.get('id')}_${b.get('id')}_${i.get('id')}`}
        onChange={(e) => {
          const value = e.target.value;
          this.setState({ value });
          this.handleChange(value);
        }}
        type="text"
        min="0"
        max="3"
        disabled={(finished && isAfter) || disabled}
        value={value}
        className={classNames(
          success && styles.success,
          valueError && styles.error
        )}
      />
    );
  }
}
