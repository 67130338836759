import { Map, OrderedMap, fromJS } from 'immutable';

const mod = [
  {
    id: 1,
    name: 'customize',
    allowedForPartner: true,
    allowedForClient: true,
    onlyEdit: false,
    displayOrder: 1
  },
  {
    id: 2,
    name: 'development',
    allowedForPartner: true,
    allowedForClient: true,
    onlyEdit: false,
    displayOrder: 3
  },
  {
    id: 3,
    name: 'simulations',
    allowedForPartner: true,
    allowedForClient: false,
    onlyEdit: true,
    displayOrder: 4
  },
  {
    id: 4,
    name: 'phonecalls',
    allowedForPartner: true,
    allowedForClient: true,
    onlyEdit: false,
    displayOrder: 6
  },
  {
    id: 5,
    name: 'removepsychometrics',
    allowedForPartner: true,
    allowedForClient: true,
    onlyEdit: false,
    displayOrder: 2,
    indent: 1
  },
  {
    id: 6,
    name: 'skills',
    allowedForPartner: true,
    allowedForClient: false,
    onlyEdit: true,
    displayOrder: 5,
    indent: 1
  },
  {
    id: 7,
    name: 'pinsightskillslibrary',
    allowedForPartner: true,
    allowedForClient: false,
    onlyEdit: true,
    displayOrder: 5,
    indent: 2
  },
  {
    id: 8,
    name: 'newreporting',
    allowedForPartner: false,
    allowedForClient: true,
    onlyEdit: true,
    displayOrder: 2,
    indent: 1
  },
  {
    id: 9,
    name: 'insights',
    allowedForPartner: false,
    allowedForClient: true,
    onlyEdit: true,
    displayOrder: 7
  }
];
const gen = [
  { id: 1, name: 'Female' },
  { id: 2, name: 'Male' }
];
const day = [
  { id: 1, name: 'monday' },
  { id: 2, name: 'tuesday' },
  { id: 3, name: 'wednesday' },
  { id: 4, name: 'thursday' },
  { id: 5, name: 'friday' },
  { id: 6, name: 'saturday' },
  { id: 7, name: 'sunday' }
];

const time = [
  { id: 1, name: '00:00:00' },
  { id: 2, name: '01:00:00' },
  { id: 3, name: '02:00:00' },
  { id: 4, name: '03:00:00' },
  { id: 5, name: '04:00:00' },
  { id: 6, name: '05:00:00' },
  { id: 7, name: '06:00:00' },
  { id: 8, name: '07:00:00' },
  { id: 9, name: '08:00:00' },
  { id: 10, name: '09:00:00' },
  { id: 11, name: '10:00:00' },
  { id: 12, name: '11:00:00' },
  { id: 13, name: '12:00:00' },
  { id: 14, name: '13:00:00' },
  { id: 15, name: '14:00:00' },
  { id: 16, name: '15:00:00' },
  { id: 17, name: '16:00:00' },
  { id: 18, name: '17:00:00' },
  { id: 19, name: '18:00:00' },
  { id: 20, name: '19:00:00' },
  { id: 21, name: '20:00:00' },
  { id: 22, name: '21:00:00' },
  { id: 23, name: '22:00:00' },
  { id: 24, name: '23:00:00' }
];

const timeEvents = [
  { id: 1, name: '00 00:30' },
  { id: 2, name: '00 01:00' },
  { id: 3, name: '00 01:30' },
  { id: 4, name: '00 02:00' },
  { id: 5, name: '00 02:30' },
  { id: 6, name: '00 03:00' },
  { id: 7, name: '00 03:30' },
  { id: 8, name: '00 04:00' },
  { id: 9, name: '00 04:30' },
  { id: 10, name: '00 05:00' },
  { id: 11, name: '00 05:30' },
  { id: 12, name: '00 06:00' },
  { id: 13, name: '00 06:30' },
  { id: 14, name: '00 07:00' },
  { id: 15, name: '00 07:30' },
  { id: 16, name: '00 08:00' },
  { id: 17, name: '00 08:30' },
  { id: 18, name: '00 09:00' },
  { id: 19, name: '00 09:30' },
  { id: 20, name: '00 10:00' },
  { id: 21, name: '00 10:30' },
  { id: 22, name: '00 11:00' },
  { id: 23, name: '00 11:30' }
];

const length = [
  { id: 1, name: '01:00' },
  { id: 2, name: '02:00' },
  { id: 3, name: '03:00' },
  { id: 4, name: '04:00' },
  { id: 5, name: '05:00' }
];

const lvl = [
  { id: 1, name: 'Owner/Partner' },
  { id: 2, name: 'Upper/Senior management' },
  { id: 3, name: 'Mid-level management' },
  { id: 4, name: 'First-line management/Team leader' },
  { id: 5, name: 'Non-management/individual contributor' },
  { id: 6, name: 'Other' }
];

const ind = [
  { id: 1, name: 'Agriculture, Forestry, Fishing and Hunting' },
  { id: 2, name: 'Utilities or other related services' },
  { id: 3, name: 'Construction' },
  { id: 4, name: 'Manufacturing' },
  { id: 5, name: 'Wholesale or Retail Trade' },
  { id: 6, name: 'Transportation and Warehousing' },
  { id: 7, name: 'Information' },
  { id: 8, name: 'Finance and Insurance' },
  { id: 9, name: 'Real Estate and Rental and Leasing' },
  { id: 10, name: 'Professional, Scientific, and Technical Services' },
  { id: 11, name: 'Education Services' },
  { id: 12, name: 'Health Care and Social Assistance' },
  { id: 13, name: 'Arts, Entertainment, and Recreation' },
  { id: 14, name: 'Public Administration' },
  { id: 15, name: 'Mining' },
  { id: 16, name: 'Other' }
];

const edStatus = [
  { id: 1, name: 'Less than high school' },
  { id: 2, name: 'GED' },
  { id: 3, name: 'High school graduate' },
  { id: 4, name: 'Some college' },
  { id: 5, name: 'Associate degree' },
  { id: 6, name: 'Undergraduate degree' },
  { id: 7, name: 'Postgraduate degree' }
];

const rac = [
  { id: 1, name: 'Asian/Pacific Islander' },
  { id: 2, name: 'Black/African' },
  { id: 3, name: 'Caucasian/White/European' },
  { id: 4, name: 'Latino/Hispanic' },
  { id: 5, name: 'Native American/Alaska Native' },
  { id: 6, name: 'Other/multi-racial' }
];

export const modules = OrderedMap(
  mod.map((item) => [item.name, fromJS(item)])
).toArray();
export const gender = Map(
  gen.map((item) => [item.name, fromJS(item)])
).toArray();
export const days = Map(day.map((item) => [item.name, fromJS(item)])).toArray();
export const timeOptions = Map(time.map((item) => [item.name, fromJS(item)]))
  .sortBy((i) => i.get('id'))
  .toArray();
export const timeEventsOptions = Map(
  timeEvents.map((item) => [item.name, fromJS(item)])
)
  .sortBy((i) => i.get('id'))
  .toArray();
export const lengthOptions = Map(
  length.map((item) => [item.name, fromJS(item)])
).toArray();
export const levels = OrderedMap(
  lvl.map((item) => [item.name, fromJS(item)])
).toArray();
export const industries = OrderedMap(
  ind.map((item) => [item.name, fromJS(item)])
).toArray();
export const educationsStatuses = OrderedMap(
  edStatus.map((item) => [item.name, fromJS(item)])
).toArray();
export const races = OrderedMap(
  rac.map((item) => [item.name, fromJS(item)])
).toArray();
export const defaultValue = 'DEFAULT';
export const EEOFields = [
  'age',
  'gender',
  'race',
  'education',
  'experience_total',
  'experience_manager',
  'work_industry',
  'work_responsibility'
];
export const demographicForm = 'demographicQuestionnaireForm';
