import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BuildTable } from 'components';
import { TranslationRow, UploadRow } from 'forms/components';

import {
  fetchSimulation,
  emails as emailsAction,
  emailAttachment as emailAttachmentAction
} from 'actions/simulations';
import { uploadFile } from 'actions/global';
import EmailTranslateModal from './EmailTranslateModal';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

@connect(
  (state, ownProps) => ({
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-attachment'])
      ? state.app.getIn(['codeList', 'allowed-ext-attachment']).toArray()
      : [],
    id: Number(state.router.route.vars.id),
    mainSimulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    simulation: state.simulations.hasIn([
      'simulations',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.simulations.getIn([
          'simulations',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.simulations.getIn([
          'simulations',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators(
      {
        emailsAction,
        uploadFile,
        emailAttachmentAction,
        fetchSimulation,
        dispatch
      },
      dispatch
    )
)
export default class EmailsTranslateTable extends Component {
  static propTypes = {
    allowedTypes: PropTypes.array.isRequired,
    id: PropTypes.number,
    simulation: PropTypes.object,
    mainSimulation: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    emailsAction: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    emailAttachmentAction: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  getTable = (email) => {
    const {
      simulation,
      mainSimulation,
      version,
      hasError,
      disabled
    } = this.props;
    const { addModal, removeModal } = this.context;
    const simulationVersion = mainSimulation
      .get('languages')
      .find((l) => l.get('language') === version);

    let translatedEmail;
    let mainEmail;
    let data = [];

    if (email) {
      mainEmail = mainSimulation
        .get('emails')
        .find((e) => e.get('id') === email.get('id'));

      if (simulation.has('emails')) {
        translatedEmail = simulation
          .get('emails')
          .find((e) => e.get('id') === email.get('id'));
        mainEmail = mainSimulation
          .get('emails')
          .find((e) => e.get('id') === email.get('id'));
      }

      data = [
        { key: 'subject', value: email.get('subject') },
        { key: 'text', value: email.get('text') }
      ];

      if (mainEmail.has('attachments')) {
        mainEmail.get('attachments').map((att) => {
          let value = att;
          let translated = false;
          if (
            translatedEmail &&
            translatedEmail.has('attachments') &&
            translatedEmail
              .get('attachments')
              .find((e) => e && e.get('id') === att.get('id'))
          ) {
            value = translatedEmail
              .get('attachments')
              .find((e) => e && e.get('id') === att.get('id'));
            translated = true;
          }

          return data.push({
            key: 'attachment',
            value,
            original: att,
            translated
          });
        });
      }
    }

    return {
      data,
      customClassName: 'trans-3-table',
      fields: [
        {
          key: 'empty',
          dark: true,
          sortable: false,
          renderField: (item) => (
            <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
          )
        },
        {
          key: 'original_name',
          sortable: false,
          locked: true,
          renderField: (item) => {
            if (item.key === 'attachment') {
              return <span>{item.original.get('filename')}</span>;
            }

            return <span dangerouslySetInnerHTML={{ __html: item.value }} />;
          }
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let defaultValue = null;
            if (translatedEmail && translatedEmail.has(item.key)) {
              defaultValue = translatedEmail.get(item.key) || null;
            }

            if (item.key !== 'attachment') {
              return (
                <td
                  className={classNames(
                    hasError && !defaultValue && styles.error
                  )}
                >
                  <TranslationRow
                    id={`${item.key}-${
                      item.original ? item.original.get('filename') : item.value
                    }`}
                    onEdit={
                      item.key === 'text'
                        ? () =>
                            addModal(
                              <EmailTranslateModal
                                defaultValue={defaultValue}
                                onClose={removeModal}
                                onSubmit={(e) =>
                                  this.handleSubmit(
                                    e,
                                    item,
                                    translatedEmail || mainEmail
                                  )
                                }
                              />
                            )
                        : false
                    }
                    defaultValue={defaultValue}
                    onSubmit={(e) =>
                      this.handleSubmit(e, item, translatedEmail || mainEmail)
                    }
                    disabled={disabled}
                  />
                </td>
              );
            }

            const hasTranslate = item.value.get('language') !== 'english';
            return (
              <td>
                <TranslationRow
                  id={`${item.key}-${
                    item.original ? item.original.get('filename') : item.value
                  }`}
                  defaultValue={
                    hasTranslate ? item.value.get('filename') : null
                  }
                  onSubmit={(e) =>
                    this.handleSubmit(e, item, translatedEmail || mainEmail)
                  }
                  disabled={disabled || !hasTranslate}
                />
              </td>
            );
          }
        },
        {
          key: 'actions',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            if (item.key !== 'attachment') {
              return <td style={{ minWidth: 250 }} />;
            }

            return (
              <td
                id={`upload_file_${version}_${
                  item.value ? item.value.get('filename') : 'default'
                }`}
                className={classNames(
                  hasError && !item.translated && styles.error
                )}
              >
                <UploadRow
                  version={version}
                  data={item.translated ? item.value : null}
                  type="ATTACHMENT"
                  onDrop={(data) =>
                    this.handleChange(data, email, item.value, translatedEmail)
                  }
                  wasPublished={simulationVersion.get('was_published')}
                  action={emailAttachmentAction}
                  simulation={mainSimulation}
                  emailId={email.get('id')}
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  handleChange = (files, email, item, trans) => {
    const { addAlert, intl } = this.context;
    const {
      allowedTypes,
      id,
      uploadFile,
      emailsAction,
      emailAttachmentAction,
      dispatch,
      version,
      fetchSimulation
    } = this.props;

    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      const types = allowedTypes.map((t) => t.get('name'));
      let typesAfter = '';

      if (types.length > 1) {
        types.pop();
        typesAfter = ` ${intl.formatMessage({
          id: 'AND'
        })} ${allowedTypes[allowedTypes.length - 1].get('name')}`;
      }

      window.scrollTo(0, 0);
      addAlert({
        color: 'danger',
        content: (
          <FormattedMessage
            id="ERRORS.FILE_TYPE_NOT_ALLOWED"
            values={{
              allowed_types: `${types.join(', ')}${typesAfter}.`
            }}
          />
        )
      });
    } else {
      uploadFile(files[0], dispatch, 'attachment').then((action) => {
        if (!action.error) {
          if (!trans) {
            return emailsAction({
              type: 'edit',
              simulationId: id,
              id: email.get('id'),
              data: {
                subject: '',
                language: version
              }
            }).then(
              emailAttachmentAction({
                type: 'edit',
                simulationId: id,
                id: email.get('id'),
                attachmentId: item.get('id'),
                data: {
                  file_id: action.result.body.uniqid,
                  filename: files[0].name,
                  language: version
                }
              })
            );
          }

          return emailAttachmentAction({
            type: 'edit',
            simulationId: id,
            id: email.get('id'),
            attachmentId: item.get('id'),
            data: {
              file_id: action.result.body.uniqid,
              filename: files[0].name,
              language: version
            }
          });
        }

        const error = action.error.body;
        window.scrollTo(0, 0);

        if (typeof error === 'string') {
          fetchSimulation(id);

          return addAlert({
            color: 'danger',
            content: intl.formatMessage({ id: error })
          });
        }

        return addAlert({
          color: 'danger',
          content: <FormattedMessage id={error.message} values={error} />
        });
      });
    }
  };

  handleSubmit = (data, field, email) => {
    const {
      id,
      emailsAction,
      version,
      emailAttachmentAction,
      fetchSimulation
    } = this.props;
    const { addAlert, intl, removeModal } = this.context;

    if (field.key === 'text') {
      removeModal();
    }

    if (field.key === 'attachment') {
      return emailAttachmentAction({
        type: 'edit',
        simulationId: id,
        id: email.get('id'),
        attachmentId: field.value.get('id'),
        data: {
          filename: data.translation,
          language: version
        }
      }).then((action) => {
        if (
          action.error &&
          action.error.body &&
          typeof action.error.body === 'string'
        ) {
          addAlert({
            color: 'danger',
            content: intl.formatMessage({ id: action.error.body })
          });

          fetchSimulation(id);
        }
      });
    }

    const translated = {
      language: version
    };
    translated[field.key] = data.translation;

    return emailsAction({
      type: 'edit',
      simulationId: id,
      id: email.get('id'),
      data: translated
    }).then((action) => {
      if (
        action.error &&
        action.error.body &&
        typeof action.error.body === 'string'
      ) {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });

        fetchSimulation(id);
      }
    });
  };

  render() {
    const { simulation, mainSimulation } = this.props;

    if (!simulation) {
      return <div />;
    }

    return (
      <div>
        {mainSimulation.has('emails') &&
        mainSimulation.get('emails').size > 0 ? (
          mainSimulation
            .get('emails')
            .toArray()
            .map((email, i) => (
              <BuildTable
                table={this.getTable(email)}
                tableKey={i + 1}
                key={i}
                total={mainSimulation.get('emails').size}
              />
            ))
        ) : (
          <BuildTable table={this.getTable(null)} tableKey={1} />
        )}
      </div>
    );
  }
}
