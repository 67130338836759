import {
  FETCH_REPORT_USERS_REQUEST,
  FETCH_REPORT_USERS_SUCCESS,
  FETCH_REPORT_USERS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch report users action
 *
 * @param{Object} id
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchReportUsers(id) {
  return {
    types: [
      FETCH_REPORT_USERS_REQUEST,
      FETCH_REPORT_USERS_SUCCESS,
      FETCH_REPORT_USERS_FAILURE
    ],
    promise: api().get(`/api/v1/analytics/${id}/shareable/`),
    id
  };
}
