import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import classNames from 'classnames';

import modals from '../../../../../../modals.css';
import { stageRedirect } from 'helpers';
import api from '../../../../../../../api';

@connect((state) => ({
  user: state.auth.get('loggedUser')
}))
export default class FinishModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasPracticeMode: true
    };
  }

  componentDidMount() {
    api()
      .get(`/api/v1/sim/has-prework/`)
      .then((res) => {
        this.setState({ hasPracticeMode: res.body });
      });
  }

  static contextTypes = {
    removeModal: PropTypes.func.isRequired
  };

  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { removeModal } = this.context;
    const { user } = this.props;
    const { hasPracticeMode } = this.state;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader style={{ textAlign: 'center' }}>
          <FormattedMessage id="LAT_MODAL_FINISH_HEADER" />
        </ModalHeader>
        <ModalBody
          className={classNames(modals['modal-body'], modals['text-center'])}
        >
          <div>
            {hasPracticeMode && <FormattedMessage id="LAT_MODAL_FINISH_INFO" />}
            {!hasPracticeMode && (
              <FormattedMessage id="LAT_MODAL_FINISH_INFO_NO_PRACTICE_MODE" />
            )}
          </div>
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col xs={12} className={modals['modal-button-col']}>
              <Button
                id="submit_modal"
                name="submit_modal"
                color="primary-dark"
                onClick={() => {
                  removeModal();
                  const userInJS = user.toJS();
                  userInJS.stages['pre-work2'].completed = true;

                  return (window.location = stageRedirect(userInJS));
                }}
                className={modals['footer-button']}
              >
                <FormattedMessage id="NEXT" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
