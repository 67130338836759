import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  Field,
  FieldArray,
  arrayPush,
  change,
  formValueSelector
} from 'redux-form';

import { Row, Col, Button } from 'reactstrap';
import { fetchReportUsers as fetchUsers } from 'actions/analytics';
import styles from '../../styles.css';

import ShareUserBox from './ShareUserBox';
import SearchSelection from '../../components/SearchSelection';

import { renderInput } from '../../actions';

const form = 'shareParticipants';

const renderParticipants = ({ fields, helpers }) => (
  <div>
    {helpers.shareWithParticipant && (
      <Row className={styles['share-row']}>
        <div>
          <Col xs={9}>
            <ShareUserBox user={helpers.report.get('user')} />
          </Col>
          <Col xs={3}>
            <Button
              id="share_report_participant_remove"
              name="share_report_participant_remove"
              outline
              type="button"
              color="danger"
              title="Remove user"
              onClick={() =>
                helpers.change(form, 'share_with_participant', false)
              }
              disabled={!helpers.shareWith}
            >
              <FormattedMessage id="REMOVE" />
            </Button>
          </Col>
        </div>
      </Row>
    )}
    {fields.map((participant, index) => {
      let user = null;
      const all = [];
      const users = [];
      helpers.users.map((u) => users.push(u));
      fields.getAll().map((f) => all.push(f));
      all.pop();

      const selected = fields
        .getAll()
        .filter((u) => all.find((f) => f.id === u.id));

      if (helpers.analyticsData.hasIn([helpers.report.get('id'), 'shared'])) {
        const findingUser = helpers.analyticsData
          .getIn([helpers.report.get('id'), 'sharing', 'stakeholders'])
          .find((u) => u.get('id') === Number(helpers.participants[index].id));

        if (findingUser) {
          user = findingUser;
        } else {
          user = users.find(
            (u) => u.get('id') === Number(helpers.participants[index].id)
          );
        }
      } else {
        user = users.find(
          (u) => u.get('id') === Number(helpers.participants[index].id)
        );
      }

      if (helpers.analyticsData.hasIn([helpers.report.get('id'), 'sharing'])) {
        helpers.analyticsData
          .getIn([helpers.report.get('id'), 'sharing', 'stakeholders'])
          .map((s) => {
            if (!users.find((u) => u.get('id') === s.get('id'))) {
              return users.push(s);
            }

            return false;
          });
      }

      return (
        helpers.participants[index] && (
          <Row key={index} className={styles['share-row']}>
            <Col xs={9}>
              {index + 1 === fields.length ? (
                <div>
                  <SearchSelection
                    recipient
                    name={`${participant}.id`}
                    form={form}
                    data={users
                      .filter(
                        (u) => !selected.find((p) => p.id === u.get('id'))
                      )
                      .sort((a, b) => {
                        if (a.get('firstname') < b.get('firstname')) {
                          return -1;
                        }
                        if (a.get('firstname') > b.get('firstname')) {
                          return 1;
                        }

                        return 0;
                      })}
                    showValue="email"
                    selectValue="id"
                    placeholder={
                      helpers.usersLoading ? 'LOADING' : 'PLEASE_SELECT_USER'
                    }
                    translatePlaceholder
                    disabled={!helpers.shareWith}
                  />
                </div>
              ) : (
                <ShareUserBox user={user} />
              )}
            </Col>
            <Col xs={3}>
              {index + 1 === fields.length ? (
                <Button
                  id="share_report_add"
                  name="share_report_add"
                  outline
                  color="primary"
                  type="button"
                  onClick={() => fields.push({})}
                  disabled={
                    !helpers.shareWith || !helpers.participants[index].id
                  }
                >
                  + <FormattedMessage id="ADD" />
                </Button>
              ) : (
                <Button
                  id={`share_report_remove_${index}`}
                  name={`share_report_remove_${index}`}
                  outline
                  type="button"
                  color="danger"
                  title="Remove user"
                  onClick={() => fields.remove(index)}
                  disabled={!helpers.shareWith}
                >
                  <FormattedMessage id="REMOVE" />
                </Button>
              )}
            </Col>
          </Row>
        )
      );
    })}
  </div>
);

renderParticipants.propTypes = {
  fields: PropTypes.object,
  helpers: PropTypes.object.isRequired
};

@reduxForm({
  form
})
@connect(
  (state, ownProps) => ({
    shareWith: formValueSelector(form)(state, 'share_with'),
    shareWithParticipant: formValueSelector(form)(
      state,
      'share_with_participant'
    ),
    participants: formValueSelector(form)(state, 'participants'),
    users: state.analytics.hasIn([
      'reportUsers',
      ownProps.report.getIn(['sharing', 'active_simulation'])
    ])
      ? state.analytics
          ?.getIn([
            'reportUsers',
            ownProps.report.getIn(['sharing', 'active_simulation'])
          ])
          ?.toArray()
          ?.concat(
            ownProps.report.getIn(['sharing', 'stakeholders'])?.toArray()
          )
      : [],
    usersLoading: state.analytics.get('reportUsersLoading'),
    analyticsData: state.analytics.get('allData')
  }),
  (dispatch) => bindActionCreators({ arrayPush, fetchUsers, change }, dispatch)
)
export default class ShareParticipantsForm extends Component {
  static propTypes = {
    arrayPush: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired,
    shareWith: PropTypes.bool,
    shareWithParticipant: PropTypes.bool,
    participants: PropTypes.array,
    users: PropTypes.array.isRequired,
    usersLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    analyticsData: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { users, report, fetchUsers } = this.props;

    if (users.length === 0) {
      fetchUsers(report.getIn(['sharing', 'active_simulation'])).then(() => {
        setTimeout(() => {
          this.setModalData();
        }, 200);
      });
    } else {
      this.setModalData();
    }
  }

  setModalData = () => {
    const { report, arrayPush, change } = this.props;

    change(
      form,
      'share_with',
      report.getIn(['sharing', 'is_enabled_self']) ||
        report.getIn(['sharing', 'stakeholders'])?.size > 0
    );

    if (report.has('sharing')) {
      if (report.getIn(['sharing', 'is_enabled_self'])) {
        change(form, 'share_with_participant', true);
      }

      report.getIn(['sharing', 'stakeholders'])?.map((stakeholder) => {
        return arrayPush(form, 'participants', { id: stakeholder.get('id') });
      });
    }

    arrayPush(form, 'participants', {});
  };

  render() {
    const {
      arrayPush,
      report,
      shareWith,
      shareWithParticipant,
      participants,
      users,
      usersLoading,
      change,
      analyticsData
    } = this.props;
    const fullName = `${report.getIn(['user', 'firstname'])} ${report.getIn([
      'user',
      'surname'
    ])}`;

    return (
      <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
        {usersLoading ? (
          <h4 style={{ textAlign: 'center' }}>
            <FormattedMessage id="LOADING" />
          </h4>
        ) : (
          <Col xs="12">
            <label htmlFor="share_with" className={styles['share-with']}>
              <Field
                name="share_with"
                type="checkbox"
                component={renderInput}
                onChange={() => {
                  change(form, 'share_with_participant', false);
                  change(form, 'participants', []);
                  setTimeout(() => {
                    arrayPush(form, 'participants', {});
                  }, 10);
                }}
              />
              <FormattedMessage id="SHARE_WITH" />
            </label>

            <Col xs="12">
              <label
                htmlFor="share_with_participant"
                style={{ borderBottom: '1px solid #ccc' }}
                className={styles['share-with']}
              >
                <Field
                  name="share_with_participant"
                  type="checkbox"
                  component={renderInput}
                  disabled={!shareWith}
                />
                <FormattedMessage id="SHARE_RESULTS_WITH_PARTICIPANT" /> (
                {fullName})
              </label>

              <Row>
                <FieldArray
                  name="participants"
                  helpers={{
                    shareWith,
                    participants,
                    users,
                    analyticsData,
                    usersLoading,
                    shareWithParticipant,
                    change,
                    report
                  }}
                  component={renderParticipants}
                />
              </Row>
            </Col>
          </Col>
        )}
      </form>
    );
  }
}
