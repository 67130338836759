// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import unescape from 'lodash.unescape';

// Components
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

// Styles
import modals from '../../../../../../../modals.css';
import '../../../../../../../modals.css';
import styles from './styles.css';
import './styles.css';
import { AUTH_TOKEN } from 'constants/cookies';
import { getCookie } from 'helpers';

// Actions
import { getFileType } from 'helpers';
import { EmbedPreview } from '../index';
import fileDownload from 'js-file-download';

// Const
const headerItems = ['subject', 'from', 'to', 'cc', 'bc', 'date'];

const EmailDetail = (props, context) => {
  const { email, rtl, addModal } = props;
  const { removeModal } = context;

  const emailText = unescape(email.get('text'));

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage id="EMAIL_DETAILS" />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <table className={styles['header-table']}>
          {headerItems.map((item) => (
            <tr>
              <td>
                <strong>
                  <FormattedMessage id={`EMAIL_ITEMS.${item}`} />:
                </strong>
              </td>
              <td>
                <span>
                  {item === 'date'
                    ? moment(email.get('created_at')).format(
                        'DD MMM YYYY hh:mm:ss A'
                      )
                    : email.get(item)}
                </span>
              </td>
            </tr>
          ))}
        </table>

        <div className={styles['text-block']} dir={rtl ? 'RTL' : 'LTR'}>
          <span dangerouslySetInnerHTML={{ __html: emailText }} />
        </div>

        {email.has('attachments') && email.get('attachments').size > 0 && (
          <div className={styles['email-attachment']}>
            {email.get('attachments').map((att) => (
              <div className={styles.row}>
                {att.get('name').endsWith('.mp4') && (
                  <Button
                    id={`open_roleplay_button_${att.get('id')}`}
                    name={`open_roleplay_button_${att.get('id')}`}
                    size="sm"
                    color="link"
                    onClick={(event) => {
                      event.stopPropagation();
                      let callType = 'twilio_call';
                      if (att.get('chime_call') !== undefined) {
                        callType = 'chime_call';
                      }
                      if (
                        att.get(callType).get('archive_url') === undefined ||
                        att.get(callType).get('archive_status') === undefined
                      ) {
                        alert('Video is being processed.');
                      } else {
                        let src = att.get('source');
                        src +=
                          src.toLowerCase().indexOf('?istwilio') === -1
                            ? '?'
                            : '&';
                        addModal(
                          <EmbedPreview
                            src={`${src}bearer=${getCookie(AUTH_TOKEN)}`}
                          />
                        );
                      }
                    }}
                  >
                    <FormattedMessage id="OPEN" />
                  </Button>
                )}
                {att.get('name').endsWith('.mp4') !== true && (
                  <span
                    className="email-attachment"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      fetch(
                        `${
                          process.env.REACT_APP_APIURL
                        }/api/v1/assets/${att.get('source')}`,
                        {
                          method: 'get',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
                          },
                          mode: 'cors'
                        }
                      )
                        .then((response) => response.blob())
                        .then((blob) => {
                          fileDownload(blob, att.get('name'));
                        });
                      return true;
                    }}
                  >
                    <img
                      src={`/assets/img/file-${getFileType(
                        att.get('source')
                      )}.png`}
                      alt={att.get('name')}
                    />
                    {att.get('name')}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Row>
          <Col
            xs={12}
            className={modals['modal-button-col']}
            style={{ paddingRight: '15px' }}
          >
            <Button
              id="close_email_detail_modal"
              name="close_email_detail_modal"
              color="primary"
              onClick={removeModal}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CLOSE" />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

// Prop types
EmailDetail.propTypes = {
  email: PropTypes.object.isRequired,
  rtl: PropTypes.bool
};

// Context types
EmailDetail.contextTypes = {
  addAlert: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired
};

export default EmailDetail;
