// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

// Components
import { Container, Row, Col } from 'reactstrap';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Footer
 * @constructor
 */
const Footer = (props) => (
  <footer className={styles.footer}>
    <Container style={props.direction === 'rtl' ? { margin: 'auto' } : {}}>
      <Row>
        <Col>
          <span>&copy;{moment().get('year')} Pinsight</span>
        </Col>
      </Row>
    </Container>
  </footer>
);

// Prop types
Footer.propTypes = {
  direction: PropTypes.string.isRequired
};

// Footer
export default connect((state) => ({
  direction: state.app.get('direction')
}))(Footer);
