import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

import ProgressCircle from '../../../ProgressCircle';
import Link from '../../../../../../components/Link';
import ToolTip from '../../../../../../components/ToolTip';

import styles from './styles.css';
import './styles.css';

import { getProgressValues } from '../../../../../../helpers';
import { updateDailyExercise } from 'actions/development';

@connect(
  (state) => ({
    exercises: state.development.get('exercises'),
    currentFocus: state.development.get('currentFocus'),
    locale: state.app.get('locale')
  }),
  (dispatch) => bindActionCreators({ updateDailyExercise }, dispatch)
)
export default class MyDailyExercise extends Component {
  static propTypes = {
    exercises: PropTypes.object.isRequired,
    currentFocus: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    updateDailyExercise: PropTypes.func.isRequired
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  constructor(...args) {
    super(...args);
    const { currentFocus } = this.props;

    this.state = {
      position: 0,
      selectedDay: currentFocus.has('date_start')
        ? moment().utc().utcOffset(currentFocus.get('date_start'))
        : moment(),
      requests: [],
      commentSaved: true,
      trackSaved: false,
      animate: false
    };

    this.sound = new Audio('/assets/files/doorbell.mp3');
    this.sound.preload = 'auto';
  }

  componentDidMount() {
    setTimeout(() => {
      this.setCalendarPosition();
    }, 50);

    this.dataInterval = setInterval(() => {
      this.saveData();
    }, 50);

    window.addEventListener('beforeunload', this.onBeforeUnload);
    window.addEventListener('unload', this.onBeforeUnload);
    window.addEventListener('pagehide', this.onBeforeUnload);
  }

  componentWillUnmount() {
    clearInterval(this.dataInterval);
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    window.removeEventListener('unload', this.onBeforeUnload);
    window.removeEventListener('pagehide', this.onBeforeUnload);
  }

  onBeforeUnload = (e) => {
    if (!this.state.commentSaved) {
      this.saveComments({
        target: { value: document.getElementById('comment').value }
      });

      e.preventDefault();
      e.returnValue = '';
    }
  };

  setCalendarPosition = (day) => {
    const { currentFocus } = this.props;
    const start = moment(currentFocus.get('date_start'))
      .utc()
      .utcOffset(currentFocus.get('date_start'))
      .startOf('week')
      .startOf('day');
    const selected =
      day ||
      moment()
        .utc()
        .utcOffset(currentFocus.get('date_start'))
        .startOf('day')
        .diff(start, 'days');

    const selectedDay = start.add(selected, 'days');
    const calendar = this.refs.calendar.getBoundingClientRect();
    const section = calendar.width / 7;

    this.setState({
      position: section * selected - section / 2 - 16,
      selectedDay
    });
  };

  trackExercise = (value) => {
    const { updateDailyExercise } = this.props;
    const { requests } = this.state;
    this.setState({ trackSaved: false });

    requests.push(
      updateDailyExercise({
        is_tracked: value
      }).then((r) => {
        if (!r.error) {
          if (value === true) {
            if (this.sound != null) {
              this.sound.play();
            }

            if (window.navigator.vibrate != null) {
              window.navigator.vibrate([100, 100, 100]);
            }
          }

          this.setState({
            trackSaved: true,
            animate: value
          });
        }
      })
    );
    this.setState({ requests });
  };

  saveComments = (event) => {
    const { exercises, updateDailyExercise } = this.props;
    const { requests } = this.state;
    const currentExercise =
      exercises.find(
        (e) =>
          moment(e.get('practiced_at'))
            .utc()
            .utcOffset(e.get('practiced_at'))
            .format('YYYYDDMM') ===
          moment().utc().utcOffset(e.get('practiced_at')).format('YYYYDDMM')
      ) || Map();

    requests.push(
      updateDailyExercise({
        comments: event.target.value,
        is_tracked: currentExercise.get('is_tracked') || false
      }).then((r) => {
        if (!r.error) {
          this.setState({
            commentSaved: true
          });
        }
      })
    );
    this.setState({ requests });
  };

  saveData = () => {
    const { requests } = this.state;
    Promise.all(requests);
  };

  render() {
    const { currentFocus, locale, exercises } = this.props;
    const { animate, commentSaved, position, selectedDay } = this.state;

    const days = [1, 2, 3, 4, 5, 6, 7];
    const currentExercise =
      exercises.find(
        (e) =>
          moment(e.get('practiced_at'))
            .utc()
            .utcOffset(e.get('practiced_at'))
            .format('YYYYDDMM')
            .toString() === selectedDay.format('YYYYDDMM').toString()
      ) || Map();
    const exerciseFocus =
      currentExercise.get('focus') || this.props.currentFocus;

    const notToday =
      selectedDay.format('YYYYDDMM') !==
      moment()
        .utc()
        .utcOffset(currentFocus.get('date_start'))
        .format('YYYYDDMM');
    const isDisabled = notToday || (notToday && currentExercise.size === 0);

    return (
      <div className={styles.exercise}>
        <div className={styles.calendar} ref="calendar">
          {days.map((day, index) => {
            const currentDay = moment(currentFocus.get('date_start'))
              .utc()
              .utcOffset(currentFocus.get('date_start'))
              .startOf('week')
              .add(index + 1, 'day');
            const dayExercise = exercises.find(
              (e) =>
                moment(e.get('practiced_at'))
                  .utc()
                  .utcOffset(e.get('practiced_at'))
                  .format('YYYYDDMM') ===
                moment(currentDay)
                  .utc()
                  .utcOffset(e.get('practiced_at'))
                  .format('YYYYDDMM')
            );
            const now = moment()
              .utc()
              .utcOffset(currentFocus.get('date_start'));

            let color = false;

            if (dayExercise) {
              if (
                moment(dayExercise.get('practiced_at'))
                  .utc()
                  .utcOffset(dayExercise.get('practiced_at'))
                  .format('YYYYDDMM') !== now.format('YYYYDDMM')
              ) {
                color = dayExercise.get('is_tracked') ? 'green' : 'red';
              } else if (dayExercise.get('is_tracked')) {
                color = 'green';
              }
            }

            const isCurrentDay = currentDay.format('DD') === now.format('DD');

            return (
              <div
                id={`day_${currentDay.format('DD')}`}
                key={index}
                className={classNames(
                  styles.day,
                  isCurrentDay && styles.current,
                  color && styles[color],
                  !dayExercise &&
                    !isCurrentDay &&
                    currentDay.isBefore(now) &&
                    styles.disabled
                )}
                onClick={
                  dayExercise ||
                  currentDay.format('DD') ===
                    moment()
                      .utc()
                      .utcOffset(currentFocus.get('date_start'))
                      .format('DD')
                    ? () => this.setCalendarPosition(day)
                    : null
                }
              >
                <p>
                  {currentDay.locale(locale).format('DD')}
                  <br />
                  {currentDay.locale(locale).format('MMM')}
                </p>
              </div>
            );
          })}
          <div className={styles.indicator} style={{ left: position }} />
        </div>

        <div className={styles.content}>
          <div
            key="content-header"
            className={classNames(
              styles.header,
              exerciseFocus &&
                styles[
                  getProgressValues(
                    exerciseFocus.has('tbis_est_perc') &&
                      exerciseFocus.get('tbis_est_perc') !== 0
                      ? exerciseFocus.get('tbis_est_perc')
                      : exerciseFocus.get('tbis_perc')
                  ).position
                ]
            )}
          >
            <ProgressCircle
              value={
                exerciseFocus
                  ? exerciseFocus.has('tbis_est_perc') &&
                    exerciseFocus.get('tbis_est_perc') !== 0
                    ? exerciseFocus.get('tbis_est_perc')
                    : exerciseFocus.get('tbis_perc')
                  : null
              }
              focus={exerciseFocus}
            />
            <h5>
              {exerciseFocus.getIn(['behavior', 'name'])} -{' '}
              <FormattedMessage id="EXERCISE" />{' '}
              {exerciseFocus.get('focus_index')}
            </h5>
          </div>

          <h6>
            <FormattedMessage id="HABIT" />
          </h6>

          <div className={classNames(styles.header, styles['small-padding'])}>
            <p
              dangerouslySetInnerHTML={{
                __html: exerciseFocus.getIn([
                  'indicator',
                  'development_suggestion'
                ])
              }}
            />
          </div>

          <Link
            to="/development/more-info-about-this-habit"
            query={{ scroll: 0, backLink: '/development/app' }}
            style={{
              display: 'block',
              textAlign: 'center',
              marginTop: 5,
              fontSize: 14,
              color: '#001c50'
            }}
          >
            <FormattedMessage id="MORE_INFO_ABOUT_THIS_HABIT" />
          </Link>

          <h6>
            <FormattedMessage id="WRITTEN_COMMENTS" />
            {commentSaved && (
              <span className={styles['save-indicator']}>
                <FormattedMessage id="SAVED" />
              </span>
            )}
          </h6>
          <textarea
            key={currentExercise.get('comments')}
            name="comment"
            id="comment"
            defaultValue={currentExercise.get('comments')}
            cols="30"
            rows="10"
            onBlur={(e) => this.saveComments(e)}
            onChange={() => this.setState({ commentSaved: false })}
            disabled={isDisabled}
            className={classNames(
              currentExercise.get('is_tracked') && styles.green,
              isDisabled && !currentExercise.get('is_tracked') && styles.red,
              isDisabled && styles.disabled
            )}
            aria-label="Comment"
          />

          <h6>
            <FormattedMessage id="TRACK" />
            {/*
              SAVED INDICATOR
              trackSaved && (
              <span className={styles['save-indicator']}>
                <FormattedMessage id="SAVED" />
              </span>
              )
              */}
          </h6>
          <div
            className={classNames(
              styles['track-block'],
              currentExercise.get('is_tracked') && styles.green,
              isDisabled && !currentExercise.get('is_tracked') && styles.red,
              isDisabled && styles.disabled
            )}
          >
            <p>
              <FormattedMessage id="TODAY_I_PRACTICED" />
            </p>
            <br />
            <p className={styles.trackedBehaviorName}>
              <ToolTip
                index="exercise-tooltip"
                hint={currentFocus.getIn(['behavior', 'name'])}
                ownContent
                active
              >
                <strong>{currentFocus.getIn(['behavior', 'name'])}</strong>
              </ToolTip>
            </p>

            <div
              id="track_day_button"
              key="track_button"
              className={classNames(
                styles.checkbox,
                currentExercise.get('is_tracked') && styles.green,
                animate && styles.animate,
                isDisabled && !currentExercise.get('is_tracked') && styles.red,
                isDisabled && styles.disabled,
                currentExercise.get('is_tracked') && styles.disableCheckbox
              )}
              onClick={() => {
                if (!isDisabled) {
                  this.trackExercise(!currentExercise.get('is_tracked'));
                }
              }}
            >
              <div key="track_background" className={styles.background} />
            </div>
          </div>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <div
              key="well-done"
              className={classNames(
                styles['well-done'],
                animate && styles.animate,
                currentExercise.get('is_tracked') && styles.visible
              )}
            >
              <FormattedMessage id="WELL_DONE" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
