import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { Map } from 'immutable';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Alert, Row, Col } from 'reactstrap';
import { getProgressValues } from 'helpers';

import styles from '../styles.css';

const getPotential = (props) => {
  const getConfig = (selected, key) => {
    const { data } = props;
    const report = data.find((r) => r.get('id') === Number(selected));

    if (!selected || !report) {
      return false;
    }

    const { color } = getProgressValues(report.get(key));

    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginTop: -50,
        spacingBottom: -50
      },
      title: {
        text: `<b>${Math.round(report.get(key))}%</b>`,
        align: 'center',
        verticalAlign: 'middle',
        y: 50,
        style: {
          fontSize: '28px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          border: 1,
          borderColor: '#BABABA',
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          allowPointSelect: false
        },
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: 'pie',
          name: 'Status',
          innerSize: '50%',
          data: [
            {
              name: 'Filled',
              y: report.get(key) / 100,
              color,
              dataLabels: {
                enabled: false
              }
            },
            {
              name: 'Unfilled',
              y: (100 - report.get(key)) / 100,
              color: '#eaeaea',
              dataLables: {
                enabled: false
              }
            }
          ]
        }
      ]
    };
  };

  const getPotentialPreview = (selected) => {
    const { data } = props;
    const report = data.find((r) => r.get('id') === Number(selected));

    if (!selected || !report) {
      return false;
    }

    const rowData = getProgressValues(report.get('obp'));

    return (
      <div
        className={styles['overall-block']}
        style={{
          border: `1px solid ${rowData.color}`
        }}
      >
        <div className={styles.left}>
          <h6>
            <FormattedMessage id="OVERALL_POTENTIAL" />
            <small>
              <FormattedMessage id="POTENTIAL_CALCULATE_INFO" />{' '}
            </small>
          </h6>
        </div>
        <div
          className={classNames(styles.right, styles.flex)}
          style={{
            backgroundColor: rowData.color,
            color: rowData.colorString === 'blue' ? 'white' : 'black'
          }}
        >
          <FormattedMessage id={rowData.position} /> :{' '}
          {Math.round(report.get('obp'))}%
        </div>
      </div>
    );
  };

  const { data, route, user } = props;
  const current = Number(route.query.current);
  const orientation = getConfig(current, 'learning_orientation');
  const capacity = getConfig(current, 'learning_capacity');
  const report = data.find((r) => r.get('id') === Number(current)) || Map();
  const isParticipant = user.get('active_role') === 'participant';

  return (
    <div className={styles.potential}>
      {isParticipant &&
        report.has('simulations_count') &&
        report.get('simulations_count') > 1 && (
          <div className="text-center">
            (<FormattedMessage id="ONLY_ONE_ASSESS_INFO" />)
          </div>
        )}

      <Alert color="info" isOpen className={styles['analytics-infobox']}>
        <FormattedMessage id="POTENTIAL_INFO" />
      </Alert>

      {current ? (
        <div>
          {getPotentialPreview(current)}

          <Row className="text-center">
            <Col xs={6}>
              <h3>
                <FormattedMessage id="LEARNING_ORIENTATION" />
              </h3>
              <div className={styles['potential-graph']} dir="ltr">
                <ReactHighcharts config={orientation} />
              </div>
            </Col>
            <Col xs={6}>
              <h3>
                <FormattedMessage id="LEARNING_CAPACITY" />
              </h3>
              <div className={styles['potential-graph']} dir="ltr">
                <ReactHighcharts config={capacity} />
              </div>
            </Col>
          </Row>

          <Row className={styles['potential-legends']}>
            <div className={styles['potential-grey-box']}>
              <h5>
                <FormattedMessage id="LEARNING_ORIENTATION" />
              </h5>
              <p>
                <FormattedMessage id="LEARNING_ORIENTATION_INFO" />
              </p>
            </div>
            <div className={styles['potential-grey-box']}>
              <h5>
                <FormattedMessage id="LEARNING_CAPACITY" />
              </h5>
              <p>
                <FormattedMessage id="LEARNING_CAPACITY_INFO" />
              </p>
            </div>
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route,
  user: state.auth.get('loggedUser')
}))(
  React.memo(
    getPotential,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
