import {
  MASS_DEBRIEF_INVITATION_REQUEST,
  MASS_DEBRIEF_INVITATION_SUCCESS,
  MASS_DEBRIEF_INVITATION_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function massDebriefInvitation(selected, debriefID) {
  return {
    types: [
      MASS_DEBRIEF_INVITATION_REQUEST,
      MASS_DEBRIEF_INVITATION_SUCCESS,
      MASS_DEBRIEF_INVITATION_FAILURE
    ],
    promise: api().post('/api/v1/mass/debrief/', { selected, debriefID }),
    selected,
    debriefID
  };
}
