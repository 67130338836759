import {
  LIST_FOCUSES_REQUEST,
  LIST_FOCUSES_SUCCESS,
  LIST_FOCUSES_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List weekly focuses action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function listWeeklyFocuses() {
  return {
    types: [LIST_FOCUSES_REQUEST, LIST_FOCUSES_SUCCESS, LIST_FOCUSES_FAILURE],
    promise: api().get('/api/v1/development/focus/')
  };
}
