import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { ShareReportsAccessForm } from '../../../../../../forms';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';

const ResultsAccess = (props, context) => {
  const { actionData, onSubmit, selectedRows, type } = props;
  const { removeModal } = context;

  return (
    <Modal
      isOpen
      toggle={() => false}
      size="lg"
      style={{ maxWidth: 650, width: '100%' }}
    >
      <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
        <FormattedMessage
          id={
            type === 'share' ? 'SHARE_RESULTS_ACCESS' : 'REMOVE_RESULTS_ACCESS'
          }
        />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <div className={modals['modal-form']}>
          <ShareReportsAccessForm
            actionData={actionData}
            onSubmit={onSubmit}
            usage="massAction"
            type={type}
            removeButton={type === 'share' ? 'x' : 'button'}
            count={selectedRows || 0}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col xs={12} className={modals['modal-button-col']}>
            <Button
              color="primary"
              onClick={onSubmit}
              className={modals['footer-button']}
            >
              <FormattedMessage id="SAVE" />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ResultsAccess.propTypes = {
  actionData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOfType(['share', 'remove']),
  selectedRows: PropTypes.number
};

ResultsAccess.contextTypes = {
  removeModal: PropTypes.func.isRequired
};

export default ResultsAccess;
