// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import update from 'immutability-helper';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// Actions
import { updatePriorities, listWeeklyFocuses } from 'actions/development';
import { getProgressValues } from 'helpers';

// Components
import Card from './Card';
import CompletedMessage from '../CompletedMessage';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * MyPriorities
 * @class
 */
@DragDropContext(HTML5Backend)
@connect(
  (state) => ({
    currentFocus: state.development.get('currentFocus'),
    priorities: state.development.get('priorities'),
    recommendedPriorities: state.development.get('recommendedPriorities'),
    rearrangedPriorities: state.development.get('rearrangedPriorities'),
    prioritiesFailure: state.development.get('prioritiesFailure')
  }),
  (dispatch) => {
    const transitionTo = actions.transitionTo;
    return bindActionCreators(
      { transitionTo, updatePriorities, listWeeklyFocuses },
      dispatch
    );
  }
)
export default class MyPriorities extends Component {
  // Context types
  static contextTypes = {
    intl: PropTypes.object.isRequired,
    changeHeading: PropTypes.func.isRequired
  };

  // Prop types
  static propTypes = {
    currentFocus: PropTypes.object.isRequired,
    priorities: PropTypes.object.isRequired,
    recommendedPriorities: PropTypes.array.isRequired,
    rearrangedPriorities: PropTypes.array.isRequired,
    transitionTo: PropTypes.func.isRequired,
    updatePriorities: PropTypes.func.isRequired,
    listWeeklyFocuses: PropTypes.func.isRequired
  };

  // State
  state = {
    cards: []
  };

  /**
   * Component will mount
   */
  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.context.changeHeading(
        this.context.intl.formatMessage({ id: 'MY_PRIORITIES' })
      );
    }, 50);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this.setData();
  }

  /**
   * Set Data
   */
  setData = () => {
    const { priorities, rearrangedPriorities } = this.props;
    const cards = [];

    rearrangedPriorities.forEach((id) =>
      cards.push({
        id,
        text: priorities.getIn([id, 'name']),
        tbis_perc: priorities.getIn([id, 'tbis_perc']),
        tbis_est_perc: priorities.getIn([id, 'tbis_est_perc'])
      })
    );

    this.setState({
      cards
    });
  };

  /**
   * Move card
   * @param dragIndex
   * @param hoverIndex
   */
  moveCard = (dragIndex, hoverIndex) => {
    const { cards } = this.state;
    const dragCard = cards[dragIndex];

    this.setState(
      update(this.state, {
        cards: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        }
      })
    );
  };

  /**
   * Update
   * @param rearranged
   * @param callback
   */
  update = (rearranged, callback) => {
    const { currentFocus, updatePriorities, listWeeklyFocuses } = this.props;

    updatePriorities(rearranged).then((action) => {
      if (currentFocus.size === 0) {
        listWeeklyFocuses();
      }

      if (typeof callback === 'function') {
        callback();
      }

      if (action.result.status !== 200) {
        this.setData();
      }
    });
  };

  /**
   * Render
   * @returns {*}
   */
  render() {
    const {
      currentFocus,
      transitionTo,
      recommendedPriorities,
      prioritiesFailure
    } = this.props;
    const { cards } = this.state;

    if (cards.length === 0 && !prioritiesFailure) {
      return <CompletedMessage className={classNames(styles['margin-top'])} />;
    }

    return (
      <div
        className={classNames(styles['my-priorities'])}
        onDragEnd={() => this.update(cards.map((c) => c.id))}
      >
        <p className={styles.centered}>
          <FormattedHTMLMessage id="MY_PRIORITIES_INFO" />
        </p>

        <div>
          <div className={styles.left}>
            {cards.map((_card, i) => (
              <div className={classNames(styles.card, styles.number)}>
                {i + 1}.
              </div>
            ))}
          </div>

          <div className={styles.right}>
            {cards.map((c, i) => (
              <Card
                key={c.id}
                index={i}
                id={c.id}
                text={c.text}
                moveCard={this.moveCard}
                progress={
                  getProgressValues(
                    c.tbis_est_perc && c.tbis_est_perc !== 0
                      ? c.tbis_est_perc
                      : c.tbis_perc
                  ).position
                }
              />
            ))}
          </div>
        </div>

        <button
          className={classNames(styles['priorities-button'], styles.white)}
          onClick={() => this.update(recommendedPriorities, this.setData)}
        >
          <FormattedMessage id="RECOMMENDED_FOR_YOU" />
        </button>

        {currentFocus.size === 0 && (
          <button
            className={classNames(styles['priorities-button'])}
            onClick={() => transitionTo('/development/choose-focus')}
          >
            <FormattedMessage id="RETURN_TO_CHOOSE_WEEKLY_FOCUS" />
          </button>
        )}
      </div>
    );
  }
}
