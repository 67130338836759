import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getProgressValues } from 'helpers';
import styles from '../styles.css';

const getLeadershipPotential = (props) => {
  const { data, route } = props;
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();

  const obpPercentile = Math.round(report.get('obp'));
  const { color, position } = getProgressValues(obpPercentile);
  const firstName = report?.get('user')?.get('firstname');

  return (
    <div style={{ marginBottom: '45px' }}>
      <div className={styles['ticked-bar']}>
        <div
          className={styles['ticked-bar']}
          style={{
            width: `${obpPercentile}%`,
            backgroundColor: color
          }}
        />
        <div className={styles.ticks}>
          <div className={styles.tick}>
            <div
              className={styles['tick-text']}
              style={{
                display: position === 'LOW' ? 'block' : 'none'
              }}
            >
              <FormattedMessage id={position} />
            </div>
          </div>
          <div className={styles.tick}>
            <div
              className={styles['tick-text']}
              style={{
                display: position === 'MEDIUM' ? 'block' : 'none'
              }}
            >
              <FormattedMessage id={position} />
            </div>
          </div>
          <div className={styles.tick}>
            <div
              className={styles['tick-text']}
              style={{
                display: position === 'HIGH' ? 'block' : 'none'
              }}
            >
              <FormattedMessage id={position} />
            </div>
          </div>
        </div>
      </div>
      <p style={{ marginTop: '40px', fontSize: '0.8rem' }}>
        <FormattedMessage
          id={`LEADERSHIP_POTENTIAL.${position}`}
          values={{ firstName }}
        />
      </p>
    </div>
  );
};

export default connect((state) => ({
  route: state.router.route
}))(
  React.memo(
    getLeadershipPotential,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
