import {
  REMOVE_REPORT_REQUEST,
  REMOVE_REPORT_SUCCESS,
  REMOVE_REPORT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Remove report action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */
export default function removeReport(id) {
  return {
    types: [
      REMOVE_REPORT_REQUEST,
      REMOVE_REPORT_SUCCESS,
      REMOVE_REPORT_FAILURE
    ],
    promise: api().del(`/api/v1/analytics/${id}/`),
    id
  };
}
