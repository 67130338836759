import { Wrapper } from 'components';

import {
  multipleOnEnterHandler,
  onDetailEnterHandler,
  getParameterByName
} from 'routes/actions';
import {
  getUserPermissions,
  getUser,
  fetchPartners,
  fetchClients
} from 'actions/users';
import { fetchSimulations, schedule, fetchDebriefs } from 'actions/simulations';
import { fetchBehaviors } from 'actions/behaviors';
import {
  login,
  getMe,
  listCodelists,
  listQuestions,
  getTest
} from 'actions/auth';
import { getCookie } from 'helpers';

import UserRoutes from 'routes/UserRoutes';
import SimulationRoutes from 'routes/SimulationRoutes';
import AssignmentsRoutes from 'routes/AssignmentsRoutes';
import AnalyticsRoutes from 'routes/AnalyticsRoutes';
import CustomizeRoutes from 'routes/CustomizeRoutes';
import DevelopmentRoutes from 'routes/DevelopmentRoutes';
import {
  App,
  AppRedirect,
  Auth,
  Home,
  Dashboard,
  Users,
  Simulations,
  Assignments,
  Analytics,
  Customize,
  Development,
  ITCheck,
  NotificationEmailPreferences
} from './views';

import { AUTH_TOKEN } from './constants/cookies';

// TODO: refactor routes
const AuthRoutes = [
  {
    path: '/login',
    component: Auth.Login
  },
  {
    path: '/logout',
    component: Auth.Logout
  },
  {
    path: '/forgot-password',
    component: Auth.ResetPassword
  },
  {
    path: '/set-new-password',
    component: Auth.SetNewPassword
  },
  {
    path: '/invite',
    component: Auth,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();
      const isLogged = state.auth.get('loggedUser').size > 0;
      const token = getParameterByName('token');

      if (token) {
        return dispatch(login(null, token || getCookie(AUTH_TOKEN))).then(
          (action) => {
            if (!action.error) {
              if (
                action.result.body.active_simulation &&
                state.simulations.get('schedule').size === 0
              ) {
                dispatch(schedule(action.result.body.id));
              }
            }
          }
        );
      }
      if (!isLogged) {
        return dispatch(getMe()).then((action) => {
          if (!action.error) {
            if (
              action.result.body.active_simulation &&
              state.simulations.get('schedule').size === 0
            ) {
              dispatch(schedule(action.result.body.id));
            }
          }
        });
      }

      return Promise.resolve();
    },
    children: [
      {
        path: '/terms',
        component: Auth.Invitation.Terms
      },
      {
        path: '/it-check',
        component: Auth.Invitation.ITCheck
      },
      {
        path: '/setup',
        component: Auth.Invitation.UserZone,
        onEnter: multipleOnEnterHandler([
          { action: listCodelists, state: 'app', object: 'codeList' }
        ])
      },
      {
        path: '/eeo',
        component: Auth.Invitation.Demographic
      },
      {
        path: '/schedule',
        component: Auth.Invitation.Schedule,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          {
            action: schedule,
            withId: true,
            state: 'simulations',
            object: 'schedule'
          }
        ])
      },
      {
        path: '/prework',
        component: Auth.Invitation.Prework,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          {
            action: schedule,
            withId: true,
            state: 'simulations',
            object: 'schedule'
          }
        ])
      },
      {
        path: '/prework/questionnaire',
        component: Auth.Invitation.Prework.Questionnaire,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          { action: listQuestions, state: 'auth', object: 'questions' },
          { action: getTest, withId: true, state: 'auth', object: 'test' },
          {
            action: schedule,
            withId: true,
            state: 'simulations',
            object: 'schedule'
          }
        ])
      },
      {
        path: '/prework/lat',
        component: Auth.Invitation.Prework.LearningAptitudeTest,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          {
            action: schedule,
            withId: true,
            state: 'simulations',
            object: 'schedule'
          }
        ])
      },
      {
        path: '/prework/practice-mode',
        component: Auth.Invitation.Prework.PracticeMode,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          {
            action: schedule,
            withId: true,
            state: 'simulations',
            object: 'schedule'
          }
        ])
      }
    ]
  }
];

// App routes
const AppRoutes = [
  {
    path: '/',
    component: App.Wrapper,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();
      const promises = [];

      if (state.auth.get('loggedUser').size > 0) {
        if (state.app.get('permissions').size === 0) {
          promises.push(dispatch(getUserPermissions()));
        }

        if (state.app.get('codeList').size === 0) {
          promises.push(dispatch(listCodelists()));
        }

        if (
          state.auth.getIn(['loggedUser', 'entity']) === 'pinsight' &&
          state.auth.getIn(['loggedUser', 'active_role']) === 'admin' &&
          state.simulations.get('simulations').size === 0
        ) {
          promises.push(dispatch(fetchSimulations()));
        }

        if (
          state.auth.getIn(['loggedUser', 'entity']) === 'pinsight' &&
          state.auth.getIn(['loggedUser', 'active_role']) === 'admin' &&
          state.debriefs.get('debriefs').size === 0
        ) {
          promises.push(dispatch(fetchDebriefs()));
        }
      }

      return Promise.all(promises);
    },
    children: [
      {
        path: '/',
        component: Home
      },
      {
        path: '/dashboard',
        component: Dashboard,
        onEnter: multipleOnEnterHandler([
          {
            action: schedule,
            withId: true,
            state: 'simulations',
            object: 'schedule'
          }
        ])
      },
      {
        path: '/profile/edit/:id',
        component: Users.Edit,
        onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
          const state = getState();
          const { id } = nextRoute.vars;

          if (state.auth.getIn(['loggedUser', 'id']) !== Number(id)) {
            return onDetailEnterHandler(getUser);
          }

          return Promise.resolve();
        }
      },
      {
        path: '/users',
        component: Users,
        children: UserRoutes,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          { action: listCodelists, state: 'app', object: 'codeList' }
        ])
      },
      {
        path: '/simulations',
        component: Simulations,
        children: SimulationRoutes,
        onEnter: multipleOnEnterHandler([
          { action: getUserPermissions, state: 'app', object: 'permissions' },
          { action: listCodelists, state: 'app', object: 'codeList' },
          {
            action: fetchSimulations,
            state: 'simulations',
            object: 'simulations'
          },
          { action: fetchBehaviors, state: 'behaviors', object: 'behaviors' },
          { action: fetchPartners, state: 'partners', object: 'allPartners' },
          { action: fetchClients, state: 'clients', object: 'allClients' },
          {
            action: fetchDebriefs,
            state: 'debriefs',
            object: 'debriefs'
          }
        ])
      },
      {
        path: '/assignments',
        component: Assignments,
        children: AssignmentsRoutes
      },
      {
        path: '/analytics',
        component: Analytics,
        children: AnalyticsRoutes
      },
      {
        path: '/customize',
        component: Customize,
        children: CustomizeRoutes
      }
    ]
  }
];

// Main route
export default [
  {
    path: '/',
    component: App,
    children: AppRoutes,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();
      if (state.auth.get('loggedUser').size === 0) {
        return dispatch(getMe());
      }

      if (state.app.get('codeList').size === 0) {
        return dispatch(listCodelists());
      }

      return Promise.resolve();
    }
  },
  {
    path: '/auth',
    component: App,
    children: [
      {
        path: '/',
        component: Auth,
        children: AuthRoutes
      }
    ],
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();

      if (
        state.auth.get('loggedUser').size > 0 &&
        state.app.get('codeList').size === 0
      ) {
        return dispatch(listCodelists());
      }

      return Promise.resolve();
    }
  },
  {
    path: '/development',
    component: Development,
    onEnter: (currentRoute, nextRoute, router, dispatch, getState) => {
      const state = getState();
      const promises = [];

      if (state.auth.get('loggedUser').size === 0) {
        promises.push(dispatch(getMe()));
      }

      if (state.app.get('permissions').size === 0) {
        promises.push(dispatch(getUserPermissions()));
      }

      if (state.app.get('codeList').size === 0) {
        promises.push(dispatch(listCodelists()));
      }

      return Promise.all(promises);
    },
    children: DevelopmentRoutes
  },
  {
    path: '/not-supported',
    component: Development,
    children: [
      {
        path: '/',
        component: Development.NotSupported
      }
    ]
  },
  {
    path: '/mobile-not-supported',
    component: Wrapper,
    children: [
      {
        path: '/',
        component: Development.NotSupported
      }
    ]
  },
  {
    path: '/it-check',
    component: App,
    children: [
      {
        path: '/',
        component: ITCheck
      }
    ]
  },
  {
    path: '/notification-email-preferences',
    component: App,
    children: [
      {
        path: '/',
        component: NotificationEmailPreferences
      }
    ]
  },
  {
    path: '/app-redirect',
    component: App,
    children: [
      {
        path: '/',
        component: AppRedirect
      }
    ]
  }
];
