import {
  GET_CLIMATE_REQUEST,
  GET_CLIMATE_SUCCESS,
  GET_CLIMATE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function getClimateData(reports) {
  return {
    types: [GET_CLIMATE_REQUEST, GET_CLIMATE_SUCCESS, GET_CLIMATE_FAILURE],
    promise: api().post(`/api/v1/analytics/graph/climate/`, { reports })
  };
}
