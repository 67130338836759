import {
  LIST_PRIORITIES_REQUEST,
  LIST_PRIORITIES_SUCCESS,
  LIST_PRIORITIES_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List priorities action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function listPriorities() {
  return {
    types: [
      LIST_PRIORITIES_REQUEST,
      LIST_PRIORITIES_SUCCESS,
      LIST_PRIORITIES_FAILURE
    ],
    promise: api().get('/api/v1/development/focus/priorities/')
  };
}
