export default function get9BoxReadiness(perf, pot) {
  let readiness = 'ready_not';

  if (perf >= 67) {
    if (pot >= 67) {
      readiness = 'ready_now';
    } else if (pot >= 34) {
      readiness = 'ready_now';
    } else {
      readiness = 'ready_6';
    }
  } else if (perf >= 34) {
    if (pot >= 67) {
      readiness = 'ready_6';
    } else if (pot >= 34) {
      readiness = 'ready_12';
    } else {
      readiness = 'ready_not';
    }
  } else if (pot >= 67) {
    readiness = 'ready_12';
  } else if (pot >= 34) {
    readiness = 'ready_not';
  } else {
    readiness = 'ready_not';
  }

  return readiness;
}
