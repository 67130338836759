import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../styles.css';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ColorIndicator } from '../../../../../components';

import styles from '../styles.css';
import '../styles.css';

const getPerformanceOverview = (props) => {
  const { data, route } = props;
  const [strategies, setStrategies] = useState([]);
  const [firstName, setFirstName] = useState('');
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();
  const behaviors = report
    ?.getIn(['simulation', 'default', 'behaviors'])
    ?.sort((a, b) => {
      if (a.get('tbis_perc') > b.get('tbis_perc')) {
        return -1;
      }
      if (a.get('tbis_perc') < b.get('tbis_perc')) {
        return 1;
      }

      return 0;
    })
    ?.toArray();
  const simulations = report.get('simulation');

  const loadUserFirstName = () => {
    setFirstName(report?.get('user')?.get('firstname'));
  };

  const renderBehaviorDefinition = (behaviorName) => {
    let helper;
    behaviors.forEach((item) => {
      if (item.get('name') === behaviorName) {
        helper = item.get('definition');
      }
    });
    return helper;
  };

  const renderBehaviorFb = (behaviorName) => {
    let helper;
    behaviors.forEach((item) => {
      if (item.get('name') === behaviorName) {
        helper = item.get('tbis_perc');
      }
    });
    return helper;
  };

  useEffect(() => {
    loadUserFirstName();
    if (simulations) {
      const helper = [];
      simulations.forEach((simulation) => {
        const helperObj = {};
        const helperBehaviorsList = [];
        const behaviours = simulation.get('behaviors');
        const strategy = simulation.get('strategy');
        const strategy_name = strategy?.get('name');
        if (strategy && strategy_name !== undefined && behaviors) {
          helperObj.strategy_name = simulation.get('strategy')?.get('name');
          behaviours.forEach((behavior) => {
            const helperBehaviorsObj = {};
            const name = behavior?.get('name');
            helperBehaviorsObj.name = name;
            helperBehaviorsObj.behavior = renderBehaviorDefinition(name);
            helperBehaviorsObj.tbis_perc = renderBehaviorFb(name);
            helperBehaviorsList.push(helperBehaviorsObj);
          });
          helperObj.behaviors = helperBehaviorsList;
          helper.push(helperObj);
        }
        if (!strategy && behaviors && !helper.length) {
          behaviours.forEach((behavior) => {
            const helperBehaviorsObj = {};
            const name = behavior?.get('name');
            helperBehaviorsObj.name = name;
            helperBehaviorsObj.behavior = renderBehaviorDefinition(name);
            helperBehaviorsObj.tbis_perc = renderBehaviorFb(name);
            helperBehaviorsList.push(helperBehaviorsObj);
          });
          helperObj.behaviors = helperBehaviorsList;
          helper.push(helperObj);
        }
      });
      if (helper.length > 0) {
        setStrategies(helper);
      }
    }
  }, [report]);

  const renderCircle = (color) => {
    return (
      <span style={{ minWidth: '325px', fontSize: '1rem' }}>
        <strong>
          <ColorIndicator type="circle" color={color} />
        </strong>
      </span>
    );
  };

  const renderStrategy = (strategies) => {
    const strategiesList = [];
    // reorder behaviors inside strategy based on value
    if (strategies.length > 0) {
      strategies.forEach((strategy) => {
        if (strategy?.behaviors?.length > 0) {
          strategy.behaviors.sort(function sortFunction(a, b) {
            return b.tbis_perc - a.tbis_perc;
          });
        }
      });
    }
    if (strategies.length > 0) {
      strategies.forEach((strategy, index) => {
        strategiesList.push(
          <div key={`${strategy.name}-${index}`}>
            <div className={styles.strategyTitle}>{strategy.strategy_name}</div>
            <div>
              {strategy?.behaviors?.length > 0 &&
                strategy.behaviors.map((behavior) => {
                  return (
                    <div
                      className={styles.behaviorContainer}
                      key={`${behavior.name}-${behavior.tbis_perc}`}
                    >
                      <span>
                        {behavior.tbis_perc > 0 &&
                          Math.round(behavior.tbis_perc) < 33.9999 &&
                          renderCircle('blue')}
                        {Math.round(behavior.tbis_perc) >= 34 &&
                          Math.round(behavior.tbis_perc) < 66.9999 &&
                          renderCircle('orange')}
                        {Math.round(behavior.tbis_perc) >= 67 &&
                          renderCircle('green')}
                      </span>
                      <div>
                        <div className={styles.behaviorTitle}>
                          <span>{behavior.name}</span>
                        </div>
                        <span className={styles.behaviorDefinition}>
                          {behavior.tbis_perc > 0 &&
                            Math.round(behavior.tbis_perc) < 33.9999 && (
                              <FormattedMessage
                                id="Demonstrated_a_below-average"
                                values={{ firstName }}
                              />
                            )}
                          {Math.round(behavior.tbis_perc) >= 34 &&
                            Math.round(behavior.tbis_perc) < 66.9999 && (
                              <FormattedMessage
                                id="Showed_an_average"
                                values={{ firstName }}
                              />
                            )}
                          {Math.round(behavior.tbis_perc) >= 67 && (
                            <FormattedMessage
                              id="Displayed_above_average"
                              values={{ firstName }}
                            />
                          )}
                          {': '}
                          {behavior.behavior}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      });
    }
    return <div>{strategiesList}</div>;
  };

  const renderBehaviors = (behaviors) => {
    const behaviorsList = [];
    // reorder behaviors inside strategy based on value
    if (behaviors?.behaviors?.length > 0) {
      behaviors.behaviors.sort(function sortFunction(a, b) {
        return b.tbis_perc - a.tbis_perc;
      });
    }
    if (behaviors?.behaviors?.length > 0) {
      behaviors.behaviors.forEach((behavior) => {
        behaviorsList.push(
          <div
            className={styles.behaviorContainer}
            key={`${behavior.name}-${behavior.tbis_perc}`}
          >
            <span>
              {behavior.tbis_perc > 0 &&
                Math.round(behavior.tbis_perc) < 33.9999 &&
                renderCircle('blue')}
              {Math.round(behavior.tbis_perc) >= 34 &&
                Math.round(behavior.tbis_perc) < 66.9999 &&
                renderCircle('orange')}
              {Math.round(behavior.tbis_perc) >= 67 && renderCircle('green')}
            </span>
            <div>
              <div className={styles.behaviorTitle}>
                <span>{behavior.name}</span>
              </div>
              <span className={styles.behaviorDefinition}>
                {behavior.tbis_perc > 0 &&
                  Math.round(behavior.tbis_perc) < 33.9999 && (
                    <FormattedMessage
                      id="Demonstrated_a_below-average"
                      values={{ firstName }}
                    />
                  )}
                {Math.round(behavior.tbis_perc) >= 34 &&
                  Math.round(behavior.tbis_perc) < 66.9999 && (
                    <FormattedMessage
                      id="Showed_an_average"
                      values={{ firstName }}
                    />
                  )}
                {Math.round(behavior.tbis_perc) >= 67 && (
                  <FormattedMessage
                    id="Displayed_above_average"
                    values={{ firstName }}
                  />
                )}
                {': '}
                {behavior.behavior}
              </span>
            </div>
          </div>
        );
      });
    }
    return <div>{behaviorsList}</div>;
  };

  const renderColorKeys = () => {
    return (
      <div className={styles.colorKeys}>
        <ul className={styles.keysList}>
          <li className={styles.keyColorTitle}>Key</li>
          <li className={styles.keyColorItem} style={{ marginBottom: '3px' }}>
            {renderCircle('green')} Above average
          </li>
          <li className={styles.keyColorItem} style={{ marginBottom: '3px' }}>
            {renderCircle('orange')} Average
          </li>
          <li className={styles.keyColorItem} style={{ marginBottom: '3px' }}>
            {renderCircle('blue')} Below average
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: '20px',
        padding: '20px',
        border: '1px',
        backgroundColor: '#F2F2F2'
      }}
    >
      <div style={{ listStyle: 'none', fontSize: '0.75rem' }}>
        {strategies?.length === 1 &&
          !strategies[0]?.strategy_name &&
          renderBehaviors(strategies[0])}
        {strategies?.length > 0 &&
          strategies[0]?.strategy_name !== undefined &&
          renderStrategy(strategies)}
        {renderColorKeys()}
      </div>
    </div>
  );
};

getPerformanceOverview.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route,
  user: state.auth.get('loggedUser')
}))(
  React.memo(
    getPerformanceOverview,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
