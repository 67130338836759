import {
  UPDATE_NARRATIVE_REQUEST,
  UPDATE_NARRATIVE_SUCCESS,
  UPDATE_NARRATIVE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Update narrative action
 *
 * @param{Number} report_id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function updateNarrative(report_id, data) {
  return {
    types: [
      UPDATE_NARRATIVE_REQUEST,
      UPDATE_NARRATIVE_SUCCESS,
      UPDATE_NARRATIVE_FAILURE
    ],
    promise: api().put(`/api/v1/analytics/${report_id}/narrative/`, data),
    report_id
  };
}
