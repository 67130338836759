import React, { useEffect, useState } from 'react';

import styles from './styles.css';
import './styles.css';
import api from '../../../../../../api';

const CoachingSuggestions = (props) => {
  // const [daysPracticed, setDaysPracticed] = useState(0);
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState({});
  const { user } = props;

  useEffect(() => {
    const fetchData = async () => {
      const response = await api().get(
        `/api/v1/analytics/development/coaching-suggestions/${user.get('id')}/`
      );
      setStages(response.body);
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    if (stages) {
      const selectedStage = stages.filter((stage) => stage.selected === true);
      setSelectedStage(selectedStage[0]);
    }
  }, [stages]);

  const handleClick = (selectedStage) => {
    setSelectedStage(selectedStage);
    stages.find((s) => s.selected === true).selected = false;
    stages.find((s) => s.key === selectedStage.key).selected = true;
    return true;
  };

  return (
    <div>
      <h6 className={styles['section-header']}>Coaching Suggestions</h6>
      <h5 className={styles['section-subheader']}>
        Based on the participant’s use of the app, we recommend engaging them in
        the following discussion. Click on a different stage for other
        suggestions.
      </h5>
      <div className={styles['chevron-wrap']}>
        {stages.map((s, index) => (
          <div
            onClick={() => handleClick(s)}
            key={index}
            className={
              s.selected ? styles['chevron-selected'] : styles['chevron-tab']
            }
          >
            {s.title}
          </div>
        ))}
      </div>
      {selectedStage && (
        <div className={styles['chevron-cont']}>
          <div className={styles['sub-box']}>
            <span className={styles['grey-title']}>{selectedStage.title}</span>
            <p>{selectedStage.grey_box}</p>
          </div>
          <div className={styles['coaching-goals']}>
            <h6>Coaching goals</h6>
            <ul>
              {selectedStage?.coaching_goals?.map((cg, index) => (
                <li style={{ margin: '15px 0px' }} key={index}>
                  {cg}
                </li>
              ))}
            </ul>
            <h6>Coaching questions</h6>
            <ol>
              {selectedStage?.coaching_questions?.map((cg, index) => (
                <li style={{ margin: '15px 0px' }} key={index}>
                  {cg}
                </li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachingSuggestions;
