// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { EmailBody as EmailBodyForm } from 'forms';

// Action
import { emails as emailsAction } from 'actions/simulations';

// Styles
import modals from '../../../../../../../modals.css';
import '../../../../../../../modals.css';

// Const
const form = 'emailBodyForm';

/**
 * Email translate modal
 * @constructor
 * @param props
 */
const EmailTranslateModal = (props) => {
  const { onClose, submit, onSubmit, isLoading, defaultValue } = props;

  return (
    <Modal isOpen toggle={() => false}>
      <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
        <FormattedMessage id="EMAIL_BODY" />
      </ModalHeader>
      <ModalBody className={modals['modal-body']}>
        <EmailBodyForm defaultValue={defaultValue} onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={5} className={modals['modal-button-col']}>
            <Button
              color="secondary"
              onClick={onClose}
              className={modals['footer-button']}
            >
              <FormattedMessage id="CANCEL" />
            </Button>{' '}
          </Col>
          <Col xs={7} className={modals['modal-button-col']}>
            <Button
              color="primary"
              onClick={() => submit(form)}
              className={modals['footer-button']}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

// Prop types
EmailTranslateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  emailsAction: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  edit: PropTypes.object,
  submit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.string
};

// Context types
EmailTranslateModal.contextTypes = {
  addAlert: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    id: Number(state.router.route.vars.id),
    isLoading: state.simulations.get('emailLoading')
  }),
  (dispatch) => bindActionCreators({ submit, emailsAction }, dispatch)
)(EmailTranslateModal);
