export default function showSimulationError(action, addAlert, intl, callback) {
  if (
    typeof action.error.body === 'string' ||
    typeof action.error.body.error === 'string'
  ) {
    addAlert({
      color: 'danger',
      content: intl.formatMessage({
        id:
          typeof action.error.body === 'string'
            ? action.error.body
            : action.error.body.error
      })
    });

    if (typeof callback === 'function') {
      callback();
    }

    return true;
  }

  return false;
}
