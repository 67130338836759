import { Map, fromJS } from 'immutable';
import {
  FETCH_SIMULATIONS_REQUEST,
  FETCH_SIMULATIONS_SUCCESS,
  FETCH_SIMULATIONS_FAILURE,
  FETCH_SIMULATION_REQUEST,
  FETCH_SIMULATION_SUCCESS,
  FETCH_SIMULATION_FAILURE,
  FETCH_SIMULATION_CONTACTS_REQUEST,
  FETCH_SIMULATION_CONTACTS_SUCCESS,
  FETCH_SIMULATION_CONTACTS_FAILURE,
  FETCH_SIMULATION_DOCUMENTS_REQUEST,
  FETCH_SIMULATION_DOCUMENTS_SUCCESS,
  FETCH_SIMULATION_DOCUMENTS_FAILURE,
  FETCH_SIMULATION_EVENTS_REQUEST,
  FETCH_SIMULATION_EVENTS_SUCCESS,
  FETCH_SIMULATION_EVENTS_FAILURE,
  FETCH_SIMULATION_EXERCISES_REQUEST,
  FETCH_SIMULATION_EXERCISES_SUCCESS,
  FETCH_SIMULATION_EXERCISES_FAILURE,
  FETCH_SIMULATION_EMAILS_REQUEST,
  FETCH_SIMULATION_EMAILS_SUCCESS,
  FETCH_SIMULATION_EMAILS_FAILURE,
  CREATE_SIMULATION_REQUEST,
  CREATE_SIMULATION_SUCCESS,
  CREATE_SIMULATION_FAILURE,
  EDIT_SIMULATION_REQUEST,
  EDIT_SIMULATION_SUCCESS,
  EDIT_SIMULATION_FAILURE,
  DELETE_SIMULATION_SUCCESS,
  CREATE_SIMULATION_INSTRUCTION_REQUEST,
  CREATE_SIMULATION_INSTRUCTION_SUCCESS,
  CREATE_SIMULATION_INSTRUCTION_FAILURE,
  EDIT_SIMULATION_INSTRUCTION_REQUEST,
  EDIT_SIMULATION_INSTRUCTION_SUCCESS,
  EDIT_SIMULATION_INSTRUCTION_FAILURE,
  DELETE_SIMULATION_INSTRUCTION_SUCCESS,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  CREATE_EXERCISE_REQUEST,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_FAILURE,
  EDIT_EXERCISE_REQUEST,
  EDIT_EXERCISE_SUCCESS,
  EDIT_EXERCISE_FAILURE,
  DELETE_EXERCISE_SUCCESS,
  CREATE_EMAIL_REQUEST,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  EDIT_EMAIL_FAILURE,
  SCHEDULE_REQUEST,
  SCHEDULE_SUCCESS,
  SCHEDULE_FAILURE,
  LIST_TIMES_REQUEST,
  LIST_TIMES_SUCCESS,
  LIST_TIMES_FAILURE,
  DELETE_EMAIL_SUCCESS,
  FETCH_EMAIL_ATTACHMENT_REQUEST,
  FETCH_EMAIL_ATTACHMENT_SUCCESS,
  FETCH_EMAIL_ATTACHMENT_FAILURE,
  CREATE_EMAIL_ATTACHMENT_SUCCESS,
  EDIT_EMAIL_ATTACHMENT_SUCCESS,
  DELETE_EMAIL_ATTACHMENT_SUCCESS,
  UPDATE_SIMULATION_LOCK_SUCCESS,
  UPDATE_SIMULATION_LOCK_FAILURE,
  RESET_USER_SEED,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  simulations: Map(),
  filtered: Map(),
  availableTimes: { available: [], seed: null },
  schedule: Map()
});

export default function simulations(state = initialState, action) {
  switch (action.type) {
    case FETCH_SIMULATIONS_REQUEST:
      return state.set('simulationsLoading', true);
    case FETCH_SIMULATIONS_SUCCESS: {
      const simulations = action.result.body.data;
      const { language } = action;

      return state.withMutations((newState) => {
        newState
          .set('simulationsLoading', false)
          .set('simulationsTotal', action.result.body.filtered)
          .set(
            'filtered',
            Map(
              simulations.map((simulation) => [
                simulation.id,
                fromJS(simulation)
              ])
            )
          )
          .mergeDeepIn(
            ['simulations', language],
            Map(
              simulations.map((simulation) => [
                simulation.id,
                fromJS(simulation)
              ])
            )
          );
      });
    }
    case FETCH_SIMULATIONS_FAILURE:
      return state.set('simulationsLoading', false);
    case FETCH_SIMULATION_REQUEST:
      return state.set('simulationsLoading', true);
    case FETCH_SIMULATION_SUCCESS: {
      const { language } = action;
      const simulation = action.result.body;

      return state.withMutations((newState) => {
        newState
          .set('simulationsLoading', false)
          .mergeDeepIn(
            ['simulations', language, simulation.id],
            fromJS(simulation)
          );
      });
    }
    case FETCH_SIMULATION_FAILURE:
      return state.set('simulationsLoading', false);
    case CREATE_SIMULATION_REQUEST:
      return state.set('createSimulationLoading', true);
    case CREATE_SIMULATION_SUCCESS: {
      const simulation = action.result.body;
      const { language } = action;

      return state
        .set('createSimulationLoading', false)
        .set('simulationsTotal', state.get('simulationsTotal') + 1)
        .mergeIn(['simulations', language, simulation.id], fromJS(simulation));
    }
    case CREATE_SIMULATION_FAILURE:
      return state.set('createSimulationLoading', false);
    case EDIT_SIMULATION_REQUEST:
      return state.set('editSimulationLoading', true);
    case EDIT_SIMULATION_SUCCESS: {
      const simulation = action.result.body;
      const id = Number(action.id);
      const { language } = action;

      return state.withMutations((newState) => {
        if (language === 'english') {
          newState.setIn(
            ['simulations', language, id, 'languages'],
            fromJS(simulation.languages)
          );
        }

        newState
          .set('editSimulationLoading', false)
          .mergeIn(['simulations', language, id], fromJS(simulation))
          .setIn(
            ['simulations', 'english', id, 'languages'],
            fromJS(simulation.languages)
          );
      });
    }
    case EDIT_SIMULATION_FAILURE: {
      const simulation =
        typeof action.error.body === 'string'
          ? { error: action.error.body }
          : action.error.body;
      const id = Number(action.id);
      const { language } = action;

      return state.withMutations((newState) => {
        if (language === 'english' && simulation.languages != null) {
          newState.setIn(
            ['simulations', language, id, 'languages'],
            fromJS(simulation.languages)
          );
        }

        if (simulation && simulation.name && simulation.name.errors) {
          newState.set('editSimulationLoading', false);
        } else {
          newState
            .set('editSimulationLoading', false)
            .mergeIn(['simulations', language, id], fromJS(simulation));
        }
      });
    }
    case DELETE_SIMULATION_SUCCESS: {
      const id = Number(action.id);
      const language = action.language || 'english';

      return state
        .deleteIn(['simulations', language, id])
        .set('simulationsTotal', state.get('simulationsTotal') - 1);
    }

    case FETCH_SIMULATION_CONTACTS_REQUEST:
      return state.set('simulationContactsLoading', true);
    case FETCH_SIMULATION_CONTACTS_SUCCESS: {
      const id = Number(action.id);
      const contacts = action.result.body.data;
      const { language } = action;

      return state
        .set('simulationContactsLoading', false)
        .setIn(
          ['simulations', language, id, 'contacts'],
          Map(contacts.map((contact) => [contact.id, fromJS(contact)]))
        );
    }
    case FETCH_SIMULATION_CONTACTS_FAILURE:
      return state.set('simulationContactsLoading', false);

    case FETCH_SIMULATION_DOCUMENTS_REQUEST:
      return state.set('simulationDocumentsLoading', true);
    case FETCH_SIMULATION_DOCUMENTS_SUCCESS: {
      const id = Number(action.id);
      const documents = action.result.body.data;
      const { language } = action;

      return state
        .set('simulationDocumentsLoading', false)
        .setIn(
          ['simulations', language, id, 'documents'],
          Map(documents.map((document) => [document.id, fromJS(document)]))
        );
    }
    case FETCH_SIMULATION_DOCUMENTS_FAILURE:
      return state.set('simulationDocumentsLoading', false);

    case FETCH_SIMULATION_EVENTS_REQUEST:
      return state.set('simulationEventsLoading', true);
    case FETCH_SIMULATION_EVENTS_SUCCESS: {
      const id = Number(action.id);
      const events = action.result.body.data;
      const { language } = action;

      return state
        .set('simulationEventsLoading', false)
        .setIn(
          ['simulations', language, id, 'events'],
          Map(events.map((event) => [event.id, fromJS(event)]))
        );
    }
    case FETCH_SIMULATION_EVENTS_FAILURE:
      return state.set('simulationEventsLoading', false);

    case FETCH_SIMULATION_EXERCISES_REQUEST:
      return state.set('simulationExercisesLoading', true);
    case FETCH_SIMULATION_EXERCISES_SUCCESS: {
      const { id } = action;
      const exercises = action.result.body.data;
      const { language } = action;

      return state
        .set('simulationExercisesLoading', false)
        .setIn(
          ['simulations', language, id, 'exercises'],
          Map(exercises.map((exercise) => [exercise.id, fromJS(exercise)]))
        );
    }
    case FETCH_SIMULATION_EXERCISES_FAILURE:
      return state.set('simulationExercisesLoading', false);

    case FETCH_SIMULATION_EMAILS_REQUEST:
      return state.set('simulationEmailsLoading', true);
    case FETCH_SIMULATION_EMAILS_SUCCESS: {
      const id = Number(action.id);
      const emails = action.result.body.data;
      const { language } = action;

      return state
        .set('simulationEmailsLoading', false)
        .mergeIn(
          ['simulations', language, id, 'emails'],
          Map(emails.map((email) => [email.id, fromJS(email)]))
        );
    }
    case FETCH_SIMULATION_EMAILS_FAILURE:
      return state.set('simulationEmailsLoading', false);

    case CREATE_SIMULATION_INSTRUCTION_REQUEST: {
      return state.set('instructionLoading', true);
    }
    case CREATE_SIMULATION_INSTRUCTION_SUCCESS: {
      const instruction = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(instruction.id);
      const { language } = action;

      return state
        .set('instructionLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'documents', id],
          fromJS(instruction)
        );
    }
    case CREATE_SIMULATION_INSTRUCTION_FAILURE: {
      return state.set('instructionLoading', false);
    }

    case EDIT_SIMULATION_INSTRUCTION_REQUEST:
      return state.set('instructionLoading', true);
    case EDIT_SIMULATION_INSTRUCTION_SUCCESS: {
      const instruction = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('instructionLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'documents', id],
          fromJS(instruction)
        );
    }
    case EDIT_SIMULATION_INSTRUCTION_FAILURE:
      return state.set('instructionLoading', false);

    case DELETE_SIMULATION_INSTRUCTION_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'simulations',
        language,
        simulationId,
        'documents',
        id
      ]);
    }

    case CREATE_EVENT_REQUEST:
      return state.set('eventLoading', true);
    case CREATE_EVENT_SUCCESS: {
      const event = action.result.body;
      const simulationId = Number(action.simulationId);
      const { id } = event;
      const { language } = action;

      return state
        .set('eventLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'events', id],
          fromJS(event)
        );
    }
    case CREATE_EVENT_FAILURE:
      return state.set('eventLoading', false);

    case EDIT_EVENT_REQUEST:
      return state.set('eventLoading', true);
    case EDIT_EVENT_SUCCESS: {
      const event = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('eventLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'events', id],
          fromJS(event)
        );
    }
    case EDIT_EVENT_FAILURE:
      return state.set('eventLoading', false);

    case DELETE_EVENT_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'simulations',
        language,
        simulationId,
        'events',
        id
      ]);
    }

    case CREATE_CONTACT_REQUEST:
      return state.set('contactLoading', true);
    case CREATE_CONTACT_SUCCESS: {
      const contact = action.result.body;
      const simulationId = Number(action.simulationId);
      const { id } = contact;
      const { language } = action;

      return state
        .set('contactLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'contacts', id],
          fromJS(contact)
        );
    }
    case CREATE_CONTACT_FAILURE:
      return state.set('contactLoading', false);

    case EDIT_CONTACT_REQUEST:
      return state.set('contactLoading', true);
    case EDIT_CONTACT_SUCCESS: {
      const contact = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('contactLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'contacts', id],
          fromJS(contact)
        );
    }
    case EDIT_CONTACT_FAILURE:
      return state.set('contactLoading', false);

    case DELETE_CONTACT_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'simulations',
        language,
        simulationId,
        'contacts',
        id
      ]);
    }

    case CREATE_EXERCISE_REQUEST: {
      return state.set('exerciseLoading', true);
    }
    case CREATE_EXERCISE_SUCCESS: {
      const exercise = action.result.body;
      const simulationId = Number(action.simulationId);
      const { id } = exercise;
      const { language } = action;

      return state
        .set('exerciseLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'exercises', id],
          fromJS(exercise)
        );
    }
    case CREATE_EXERCISE_FAILURE: {
      return state.set('exerciseLoading', false);
    }

    case EDIT_EXERCISE_REQUEST: {
      return state.set('exerciseLoading', true);
    }
    case EDIT_EXERCISE_SUCCESS: {
      const exercise = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('exerciseLoading', false)
        .mergeIn(
          ['simulations', language, simulationId, 'exercises', id],
          fromJS(exercise)
        );
    }
    case EDIT_EXERCISE_FAILURE: {
      return state.set('exerciseLoading', false);
    }

    case DELETE_EXERCISE_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'simulations',
        language,
        simulationId,
        'exercises',
        id
      ]);
    }

    case CREATE_EMAIL_REQUEST:
      return state.set('emailLoading', true);
    case CREATE_EMAIL_SUCCESS: {
      const email = action.result.body;
      const simulationId = Number(action.simulationId);
      const { id } = email;
      const { language } = action;

      return state
        .set('emailLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'emails', id],
          fromJS(email)
        );
    }
    case CREATE_EMAIL_FAILURE:
      return state.set('emailLoading', false);

    case EDIT_EMAIL_REQUEST:
      return state.set('emailLoading', true);
    case EDIT_EMAIL_SUCCESS: {
      const email = action.result.body;
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('emailLoading', false)
        .mergeIn(
          ['simulations', language, simulationId, 'emails', id],
          fromJS(email)
        );
    }
    case EDIT_EMAIL_FAILURE:
      return state.set('emailLoading', false);

    case DELETE_EMAIL_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'simulations',
        language,
        simulationId,
        'emails',
        id
      ]);
    }

    case FETCH_EMAIL_ATTACHMENT_REQUEST:
      return state.set('emailAttachmentsLoading', true);
    case FETCH_EMAIL_ATTACHMENT_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const attachments = action.result.body.data;
      const { language } = action;

      return state
        .set('emailAttachmentsLoading', false)
        .setIn(
          ['simulations', language, simulationId, 'emails', id, 'attachments'],
          Map(attachments.map((att) => [att.id, fromJS(att)]))
        );
    }
    case FETCH_EMAIL_ATTACHMENT_FAILURE:
      return state.set('emailAttachmentsLoading', false);

    case CREATE_EMAIL_ATTACHMENT_SUCCESS:
    case EDIT_EMAIL_ATTACHMENT_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const attachment = action.result.body;
      const { language } = action;
      const oldAttachments = state.hasIn([
        'simulations',
        language,
        simulationId,
        'emails',
        id,
        'attachments'
      ])
        ? state.getIn([
            'simulations',
            language,
            simulationId,
            'emails',
            id,
            'attachments'
          ])
        : Map();
      const newAttachments = oldAttachments
        .toArray()
        .map((item) => item.toJS());

      if (oldAttachments.size === 0) {
        newAttachments.push(attachment);
      } else if (newAttachments.find((a) => a.id === attachment.id)) {
        newAttachments.map((old, index) => {
          if (old.id === attachment.id) {
            return (newAttachments[index] = attachment);
          }

          return false;
        });
      } else {
        newAttachments.push(attachment);
      }

      return state.setIn(
        ['simulations', language, simulationId, 'emails', id, 'attachments'],
        Map(newAttachments.map((a) => [a.id, fromJS(a)]))
      );
    }
    case DELETE_EMAIL_ATTACHMENT_SUCCESS: {
      const simulationId = Number(action.simulationId);
      const id = Number(action.id);
      const attachmentId = Number(action.attachmentId);
      const { language } = action;

      const oldAttachments = state.getIn([
        'simulations',
        language,
        simulationId,
        'emails',
        id,
        'attachments'
      ]);
      const newAttachments = oldAttachments
        .toArray()
        .map((item) => item.toJS());

      newAttachments.map((old, index) => {
        if (old.id === attachmentId) {
          return newAttachments.splice(index, 1);
        }

        return false;
      });

      return state.setIn(
        ['simulations', language, simulationId, 'emails', id, 'attachments'],
        Map(newAttachments.map((a) => [a.id, fromJS(a)]))
      );
    }

    case SCHEDULE_REQUEST:
      return state.set('scheduleLoading', true);
    case SCHEDULE_SUCCESS:
      if (action.getting) {
        return state
          .set('scheduleLoading', false)
          .set('schedule', fromJS(action.result.body));
      }

      return state.set('scheduleLoading', false);
    case SCHEDULE_FAILURE:
      return state.set('scheduleLoading', false);

    case LIST_TIMES_REQUEST:
      return state.set('timesLoading', true);
    case LIST_TIMES_SUCCESS:
      return state
        .set('timesLoading', false)
        .set('availableTimes', action.result.body);
    case LIST_TIMES_FAILURE:
      return state.set('timesLoading', false);
    case UPDATE_SIMULATION_LOCK_SUCCESS: {
      const { simulation_id } = action;

      return state.mergeIn(
        ['simulations', 'english', Number(simulation_id)],
        fromJS(action.result.body)
      );
    }
    case UPDATE_SIMULATION_LOCK_FAILURE: {
      const { simulation_id } = action;

      if (
        action.error &&
        action.error.body &&
        action.error.body.error === 'ERRORS.SIMULATION_ALREADY_LOCKED'
      ) {
        return state.mergeIn(
          ['simulations', 'english', Number(simulation_id)],
          fromJS(action.error.body)
        );
      }

      return state;
    }
    case RESET_USER_SEED:
      return state.set('availableTimes', { available: [], seed: null });

    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
