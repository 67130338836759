import {
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Set password action
 *
 * @param{String} token
 * @param{String} password
 * @returns {{types: *[], promise: Request }}
 */

export default function setPassword(token, password) {
  return {
    types: [SET_PASSWORD_REQUEST, SET_PASSWORD_SUCCESS, SET_PASSWORD_FAILURE],
    promise: api().post('/api/v1/users/set-password/', {
      token,
      password
    })
  };
}
