import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from '../ChooseFocus/styles.css';
import '../ChooseFocus/styles.css';

const SummaryModal = (props) => {
  const { isOpen, onClose } = props;

  return (
    <div
      className={classNames(styles.modal, isOpen && styles.open)}
      key="congratulations-modal"
    >
      <div className={styles.modal__box}>
        <button
          id="close_technical_support_x_button"
          name="close_technical_support_x_button"
          className={styles['close-button']}
          onClick={onClose}
          aria-label="Close window"
        />

        <h6 style={{ marginTop: 25 }}>
          <FormattedMessage id="TECHNICAL_SUPPORT_SUMMARY" />
        </h6>

        <button
          id="close_technical_support_button"
          name="close_technical_support_button"
          className={styles.white}
          onClick={onClose}
          style={{ marginTop: 20 }}
        >
          <FormattedMessage id="CLOSE" />
        </button>
      </div>
    </div>
  );
};

SummaryModal.propTypes = {
  isOpen: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SummaryModal;
