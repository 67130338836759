// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Overview
 * @class
 */
@connect(
  (state) => ({
    currentFocus: state.development.get('currentFocus'),
    locale: state.app.get('locale')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class Overview extends Component {
  // Prop Types
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    currentFocus: PropTypes.object.isRequired,
    locale: PropTypes.string
  };

  // Context types
  static contextTypes = {
    changeHeading: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  /**
   * Component did mount
   */
  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.context.changeHeading(
        this.context.intl.formatMessage({ id: 'GETTING_STARTED' })
      );
    }, 50);
  }

  /**
   * Get video url
   * @returns {*}
   */
  getVideoUrl = () => {
    const { locale } = this.props;
    return `https://www.youtube.com/embed/GeJWofpO8qQ?rel=0&cc_load_policy=1&hl=${locale}`;
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { currentFocus, transitionTo } = this.props;

    return (
      <div className={styles.overview}>
        <p>
          <FormattedMessage id="DEVELOPMENT_OVERVIEW_INFO" />
        </p>

        <iframe
          style={{
            marginTop: 50,
            maxWidth: '100%'
          }}
          width="400"
          height="225"
          src={this.getVideoUrl()}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />

        <button
          id="overview_next_button"
          name="overview_next_button"
          className={styles.button}
          onClick={() => {
            if (currentFocus.size > 0) {
              transitionTo('/development/app');
            } else {
              transitionTo('/development/choose-focus');
            }
          }}
        >
          <FormattedMessage id="NEXT" />
        </button>
      </div>
    );
  }
}
