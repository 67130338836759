// Vendor
import moment from 'moment';

/**
 * Fet remaining time
 * @param to
 */
export default function getRemainingTime(to) {
  const a = moment(to).zone(to);
  const b = moment().zone(to);

  const days = a.diff(b, 'days');
  const hours = a.subtract(days, 'days').diff(b, 'h');
  const minutes = a.subtract(hours, 'h').diff(b, 'm');
  const seconds = a.subtract(minutes, 'm').diff(b, 's');

  return {
    days,
    hours,
    minutes,
    seconds
  };
}
