import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.css';
import './styles.css';

class Affix extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    offset: PropTypes.number,
    children: PropTypes.any.isRequired,
    top: PropTypes.number.isRequired
  };

  static defaultProps = {
    className: '',
    offset: 0
  };

  state = {
    affix: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { affix } = this.state;
    const { offset, top } = this.props;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (!affix && scrollTop >= offset - top) {
      this.setState({
        affix: true
      });
    }

    if (affix && scrollTop < offset - top) {
      this.setState({
        affix: false
      });
    }
  };

  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames(className, this.state.affix && styles.affix)}>
        {children}
      </div>
    );
  }
}

export default Affix;
