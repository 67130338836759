import {
  SCORE_ASSIGNMENT_REQUEST,
  SCORE_ASSIGNMENT_SUCCESS,
  SCORE_ASSIGNMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Score assignment action
 *
 * @param {Number} id
 * @param {Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function scoreAssignment(id, data) {
  return {
    types: [
      SCORE_ASSIGNMENT_REQUEST,
      SCORE_ASSIGNMENT_SUCCESS,
      SCORE_ASSIGNMENT_FAILURE
    ],
    promise: api().post(`/api/v1/users/${id}/assignments/score/`, data),
    data
  };
}
