import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { getProgressValues } from 'helpers';
import { ProgressCircle } from '..';

import styles from './styles.css';
import './styles.css';

const Box = (props) => (
  <div
    id={`box_indicator_${props.focus.getIn(['indicator', 'id'])}`}
    className={styles.box}
    onClick={props.onClick}
    key="box"
  >
    <div
      className={classNames(
        styles.box__heading,
        styles[
          getProgressValues(
            props.focus.has('tbis_est_perc') &&
              props.focus.get('tbis_est_perc') !== 0
              ? props.focus.get('tbis_est_perc')
              : props.focus.get('tbis_perc')
          ).position
        ]
      )}
    >
      <ProgressCircle
        value={
          props.focus
            ? props.focus.has('tbis_est_perc') &&
              props.focus.get('tbis_est_perc') !== 0
              ? props.focus.get('tbis_est_perc')
              : props.focus.get('tbis_perc')
            : null
        }
        focus={props.focus}
      />
      <h5 title={props.focus.getIn(['behavior', 'name'])}>
        {props.focus.getIn(['behavior', 'name'])}
      </h5>
      <br />
      <h6>
        <FormattedMessage id="EXERCISE" /> {props.focus.get('focus_index')}
      </h6>
    </div>
    <div className={styles.box__body}>
      <p
        dangerouslySetInnerHTML={{
          __html: props.focus.getIn(['indicator', 'development_suggestion'])
        }}
      />
    </div>
    <div className={styles.box__footer}>
      <p>
        <strong>{Math.round(props.focus.get('dli'))}</strong>{' '}
        <FormattedMessage id="DAYS_PRACTICE_LEFT" />
        {props.focus.has('last_practice') && (
          <small>
            {' '}
            (
            <FormattedMessage id="LAST_TIME_PRACTICED" /> -{' '}
            {moment(props.focus.get('last_practice')).format('MMM DD')})
          </small>
        )}
      </p>
    </div>
  </div>
);

Box.propTypes = {
  onClick: PropTypes.func.isRequired,
  focus: PropTypes.object.isRequired
};

export default Box;
