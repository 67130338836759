// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

// Components
import { Col, InputGroup, Row } from 'reactstrap';

// Actions
import { renderInput } from '../actions';

// Const
const nameField = 'name';

const PartnerBasic = (props) => {
  const { users, owner, loading } = props;

  return (
    <Row>
      <Col xs={12}>
        <h5>
          <FormattedMessage id="BASIC_INFORMATION" />
        </h5>
      </Col>
      <Col xs={5}>
        <InputGroup>
          <label htmlFor={nameField}>
            <FormattedMessage id={`FORM_FIELDS.partner_${nameField}`} /> (
            <FormattedMessage id="REQUIRED" />)
          </label>
          <Field name={nameField} component={renderInput} type="text" />
        </InputGroup>

        {owner && (
          <InputGroup>
            <label htmlFor="owner">
              <FormattedMessage id="FORM_FIELDS.partner_owner" />
            </label>
            <Field
              loading={loading}
              name="owner_id"
              component={renderInput}
              type="select"
              valueKey="id"
              optionKey="fullname"
              options={users.filter(
                (u) =>
                  props.partner &&
                  u.get('partner_id') === props.partner.get('id') &&
                  !u.has('client_id') &&
                  u.get('roles').indexOf('admin') > -1
              )}
              noEmpty={!loading}
            />
          </InputGroup>
        )}
      </Col>
    </Row>
  );
};

// Prop Types
PartnerBasic.propTypes = {
  owner: PropTypes.object,
  users: PropTypes.object,
  partner: PropTypes.object,
  loading: PropTypes.bool
};

export default connect((state) => ({
  partner: state.partners.getIn([
    'allPartners',
    Number(state.router.route.vars.id)
  ]),
  users: state.users.get('allUsers').toArray(),
  loading: state.users.get('usersLoading')
}))(PartnerBasic);
