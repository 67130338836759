/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change, formValueSelector } from 'redux-form';
import Immutable, { Map } from 'immutable';

// Styles
import { Button, Col, Row } from 'reactstrap';
import { roleRedirect } from 'helpers';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Actions
import getBlocks from './getBlocks';
import { getUserFields } from '../../actions';
import { sortItems } from '../../../helpers';

// Const
const form = 'editUser';

/**
 * Edit user form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    route: state.router.route,
    isLoading: state.users.get('createUserLoading'),
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    loggedUser: state.auth.get('loggedUser'),
    user:
      state.users.getIn(['allUsers', Number(state.router.route.vars.id)]) ||
      Map(),
    codeList: state.app.get('codeList'),
    inviteNow: formValueSelector(form)(state, 'invite_now'),
    selectedSimulation: formValueSelector(form)(
      state,
      'active_simulation.simulation_id'
    ),
    selectedLanguage: formValueSelector(form)(
      state,
      'active_simulation.language'
    )
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class EditUserForm extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    change: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    permissions: PropTypes.array,
    codeList: PropTypes.object,
    inviteNow: PropTypes.bool,
    selectedSimulation: PropTypes.bool,
    selectedLanguage: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    selectedRole: PropTypes.string.isRequired,
    preferredTimesError: PropTypes.object
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { route } = this.props;
    this.updateForm(this.props.user);

    const element = document.getElementById('calendar');
    if (element && route.query.calendar) {
      window.scrollTo(0, element.offsetTop + 100);
    }
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.size > 0) {
      if (!Immutable.is(nextProps.user, this.props.user)) {
        this.updateForm(nextProps.user);
      }
    }
  }

  /**
   * Update form
   */
  updateForm = (user) => {
    const { change, codeList } = this.props;
    getUserFields(form, user, change, {
      debriefs: user.get('debriefs_enableable'),
      simulations: user.get('simulations_enableable'),
      languages: sortItems(codeList.get('language_published'), {
        key: 'name',
        asc: true
      }).toArray()
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      handleSubmit,
      loggedUser,
      isLoading,
      user,
      permissions,
      inviteNow,
      selectedSimulation,
      selectedLanguage,
      transitionTo,
      selectedRole,
      preferredTimesError
    } = this.props;

    if (user.size === 0) {
      return <div />;
    }

    const isDisabled =
      selectedRole === 'participant' &&
      inviteNow &&
      (!selectedSimulation || !selectedLanguage);

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        {getBlocks(permissions, {
          user,
          form,
          loggedUser,
          selectedRole,
          preferredTimesError
        })}

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="cancel_edit_profile"
              name="cancel_edit_profile"
              type="button"
              color="secondary"
              onClick={() => transitionTo(roleRedirect(loggedUser))}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_edit_profile"
              name="submit_edit_profile"
              type="submit"
              color="primary"
              disabled={
                isLoading ||
                isDisabled ||
                user
                  .getIn(['permissions_debug', 'disabled_mutual_blocks'])
                  .indexOf('reset_password') > -1
              }
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
