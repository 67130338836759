import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import { InputGroup, Col, Row, Label } from 'reactstrap';
import { validate } from './validate';

import styles from '../../styles.css';
import classNames from 'classnames';

import { renderInput } from '../../actions';

const form = 'exerciseForm';

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    behaviors: state.behaviors.has('filtered')
      ? state.behaviors.get('filtered').toArray()
      : [],
    formErrors: state.app.get('formErrors') || {},
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    loggedUser: state.auth.get('loggedUser')
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Exercise extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    behaviors: PropTypes.array,
    readOnly: PropTypes.bool,
    formErrors: PropTypes.object.isRequired,
    version: PropTypes.object,
    simulation: PropTypes.object,
    loggedUser: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { edit } = this.props;

    this.state = {
      selected:
        edit && edit.has('behaviors') ? edit.get('behaviors').toJS() : []
    };
  }

  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'name', edit.get('name'));
      change(form, 'description', edit.get('description'));
      change(form, 'behaviors', edit.get('behaviors'));
    }
  }

  handleChange = (behavior) => {
    const { change } = this.props;
    const { selected } = this.state;
    let updated = selected;
    const index = selected.find((b) => b.id === behavior.get('id'));

    if (index) {
      updated = selected.filter((b) => b.id !== index.id);
    } else {
      updated.push({
        id: behavior.get('id'),
        name: behavior.get('name'),
        display_as_default: behavior.get('display_as_default') || false
      });
    }

    this.setState({ selected: updated });
    change(form, 'behaviors', updated);
  };

  handleChangeDisplay = (behavior) => {
    const { change } = this.props;
    const { selected } = this.state;

    selected.find(
      (b) => b.id === behavior.get('id')
    ).display_as_default = !selected.find((b) => b.id === behavior.get('id'))
      .display_as_default;

    this.setState({ selected });
    change(form, 'behaviors', selected);
  };

  renderBlock = (
    heading,
    behaviors,
    selected,
    disabled,
    skillsLibraryIsPinsight
  ) => (
    <InputGroup className={styles['heading-modal-group']}>
      <h6>
        <FormattedMessage id={heading} /> (
        <FormattedMessage id="REQUIRED" />)
      </h6>
      <Row>
        <Col
          xs={6}
          className={classNames(
            styles['modal-exercises-heading'],
            !skillsLibraryIsPinsight &&
              styles['modal-exercises-heading-partner']
          )}
        >
          <FormattedMessage id="FORM_FIELDS.behavior_name" />
          {skillsLibraryIsPinsight ? (
            <FormattedMessage id="FORM_FIELDS.display_as_default" />
          ) : null}
        </Col>
        <Col
          xs={6}
          className={classNames(
            styles['modal-exercises-heading'],
            !skillsLibraryIsPinsight &&
              styles['modal-exercises-heading-partner']
          )}
        >
          <FormattedMessage id="FORM_FIELDS.behavior_name" />
          {skillsLibraryIsPinsight ? (
            <FormattedMessage id="FORM_FIELDS.display_as_default" />
          ) : null}
        </Col>
        {behaviors
          .sort((a, b) => {
            if (a.get('name') > b.get('name')) {
              return 1;
            }
            if (a.get('name') < b.get('name')) {
              return -1;
            }

            return 0;
          })
          .map((behavior, index) => {
            const selectedIds = [];
            selected.map((i) => selectedIds.push(i.id));

            return (
              <Col xs={6} key={index}>
                <div className={styles['exercise-behavior-item']}>
                  <Label id={`behavior_input_label_${behavior.get('id')}`}>
                    <input
                      id={`behavior_input_${behavior.get('id')}`}
                      name={`behavior_input_${behavior.get('id')}`}
                      type="checkbox"
                      onChange={() => this.handleChange(behavior)}
                      checked={selectedIds.indexOf(behavior.get('id')) > -1}
                      disabled={this.props.readOnly || disabled}
                    />
                    {behavior.get('name')}
                  </Label>
                  {skillsLibraryIsPinsight ? (
                    <Label
                      id={`behavior_switch_label_${behavior.get('id')}`}
                      className={styles['default-behavior-switch']}
                    >
                      <input
                        id={`behavior_switch_${behavior.get('id')}`}
                        name={`behavior_switch_${behavior.get('id')}`}
                        type="checkbox"
                        onChange={() => this.handleChangeDisplay(behavior)}
                        checked={
                          selectedIds.indexOf(behavior.get('id')) > -1 &&
                          selected.find((b) => b.id === behavior.get('id'))
                            .display_as_default
                        }
                        disabled={
                          !selected.find((b) => b.id === behavior.get('id')) ||
                          this.props.readOnly ||
                          disabled
                        }
                      />
                      <div className={styles['switch-slider']} />
                    </Label>
                  ) : null}
                </div>
              </Col>
            );
          })}
      </Row>
    </InputGroup>
  );

  render() {
    const {
      handleSubmit,
      behaviors,
      readOnly,
      formErrors,
      version,
      simulation
    } = this.props;
    const { selected } = this.state;

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published')) {
        return true;
      }

      if (version.get('was_published')) {
        switch (field) {
          case 'name':
          case 'description':
            return false;
          default:
            return true;
        }
      }

      return false;
    };

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.exercise_name" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="name"
              component={renderInput}
              type="text"
              disabled={readOnly || isFieldDisabled('name')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.exercise_description" /> (
              <FormattedMessage id="REQUIRED" />)
            </h6>
            <Field
              name="description"
              component={renderInput}
              type="textarea"
              disabled={readOnly || isFieldDisabled('description')}
            />
          </InputGroup>
          {this.renderBlock(
            'FORM_FIELDS.behaviors',
            behaviors,
            selected,
            isFieldDisabled('behaviors'),
            simulation.getIn(['skills_library', 'is_default_pinsight_library'])
          )}
          {formErrors &&
            formErrors.behaviors &&
            formErrors.behaviors.errors &&
            formErrors.behaviors.errors.map((error, index) => (
              <div className={styles.form__error} key={index}>
                <FormattedMessage id={error} />
              </div>
            ))}
        </form>
      </div>
    );
  }
}
