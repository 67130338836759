import {
  EDIT_ASSESSMENT_BEHAVIOR_REQUEST,
  EDIT_ASSESSMENT_BEHAVIOR_SUCCESS,
  EDIT_ASSESSMENT_BEHAVIOR_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Edit assessment behavior action
 *
 * @param{Number} id
 * @param{Number} behaviorId
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function editAssessmentBehavior(id, behaviorId, data) {
  const result = data;

  if (!result.language) {
    result.language = 'english'; // eslint-disable-line
  }

  return {
    types: [
      EDIT_ASSESSMENT_BEHAVIOR_REQUEST,
      EDIT_ASSESSMENT_BEHAVIOR_SUCCESS,
      EDIT_ASSESSMENT_BEHAVIOR_FAILURE
    ],
    promise: api().put(
      `/api/v1/assessment/${id}/behaviors/${behaviorId}/`,
      data
    ),
    language: result.language,
    id,
    behaviorId,
    data
  };
}
