import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { deleteEntity, deleteUser } from 'actions/users';

import modals from '../../views/modals.css';
import '../../views/modals.css';
import styles from '../styles.css';
import '../styles.css';

@connect(undefined, (dispatch) => {
  const { transitionTo } = actions;
  return bindActionCreators(
    { deleteEntity, deleteUser, transitionTo },
    dispatch
  );
})
export default class DeleteBlock extends Component {
  static propTypes = {
    deleteEntity: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    entity: PropTypes.string.isRequired,
    transitionTo: PropTypes.func.isRequired,
    userDeletion: PropTypes.bool
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired
  };

  del = () => {
    const {
      deleteEntity,
      deleteUser,
      user,
      entity,
      transitionTo,
      userDeletion
    } = this.props;
    const { addAlert, removeModal } = this.context;

    if (userDeletion) {
      deleteUser(user.get('id')).then((action) => {
        if (!action.error) {
          transitionTo('/users');
          removeModal();
          addAlert({
            color: 'success',
            content: (
              <FormattedMessage
                id={`${entity.toUpperCase()}_DELETED_SUCCESSFULLY`}
              />
            )
          });
        }
      });
    } else {
      deleteEntity(user.get('id'), entity).then((action) => {
        if (!action.error) {
          transitionTo('/users', { tab: entity === 'client' ? '3' : '2' });
          removeModal();
          addAlert({
            color: 'success',
            content: (
              <FormattedMessage
                id={`${entity.toUpperCase()}_DELETED_SUCCESSFULLY`}
              />
            )
          });
        }
      });
    }
  };

  showModal() {
    const { entity } = this.props;
    const { addModal, removeModal } = this.context;

    addModal(
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id={`DELETE_${entity.toUpperCase()}`} />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <FormattedHTMLMessage
            id="DELETE_ENTITY_WARNING"
            values={{ entity }}
          />
        </ModalBody>

        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_delete_user"
                name="cancel_delete_user"
                color="secondary"
                onClick={removeModal}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_delete_user"
                name="submit_delete_user"
                color="danger"
                onClick={this.del}
                className={modals['footer-button']}
              >
                <FormattedHTMLMessage
                  id="YES_DELETE_ENTITY"
                  values={{ entity }}
                />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { user, entity } = this.props;

    return (
      <Row>
        <div>
          <Col xs={12}>
            <h5>
              <FormattedMessage id={`DELETE_${entity.toUpperCase()}`} />
            </h5>
          </Col>
          <Col xs={10} />
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="delete_button"
              name="delete_button"
              color="danger"
              type="button"
              onClick={this.showModal.bind(this)}
              disabled={
                !user.get('is_deletable') ||
                (user.hasIn(['permissions_debug', 'disabled_mutual_blocks']) &&
                  user
                    .getIn(['permissions_debug', 'disabled_mutual_blocks'])
                    .indexOf('delete_user') > -1)
              }
            >
              <FormattedMessage id="DELETE" />
            </Button>
          </Col>
        </div>
      </Row>
    );
  }
}
