/**
 * Set cookie
 * @param cname
 * @param cvalue
 * @param exdays
 */
/* eslint-disable */
export default function setCookie(cname, cvalue, exdays, secure) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname +
    '=' +
    cvalue +
    '; ' +
    (secure ? 'secure; ' : ' ') +
    expires +
    ';path=/';
}
