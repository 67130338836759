import { modules } from 'constants/fieldsData';
import fieldInputs from './fieldInputs';

// Const

const getPartnerFields = (form, user, change, helpers) => {
  const data = [
    { name: 'name', value: user.get('name') },
    { name: 'status', value: user.get('status') },
    { name: 'owner_id', value: user.get('owner').get('id') },
    { name: 'brand_under_children', value: user.get('brand_under_children') }
  ];

  modules.map((module, index) =>
    data.push({
      name: `modules[${index}]`,
      value: user
        .get('modules')
        .find((mod) => mod.get('id') === module.get('id'))
    })
  );

  helpers.simulations.map((simulation) =>
    data.push({
      name: `simulations[${simulation.get('id')}]`,
      value: !!user
        .get('simulations')
        .find((sim) => sim.get('id') === simulation.get('id'))
    })
  );

  helpers.languages.map((language, index) =>
    data.push({
      name: `languages[${index}]`,
      value: user.get('languages').find((lan) => lan === language.get('name'))
    })
  );

  fieldInputs(form, data, change);
};

export default getPartnerFields;
