import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import classNames from 'classnames';
import { Col, InputGroup, Row } from 'reactstrap';
import { validate } from './validate';

import styles from '../../styles.css';

import { renderInput } from '../../actions';

const form = 'contactForm';
const photoField = 'profile_picture';
const fields = [
  { key: 'name', translation: 'contact_name', required: true },
  { key: 'company', translation: 'company', required: false },
  { key: 'position', translation: 'position', required: false }
];

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    formErrors: state.app.get('formErrors') || {},
    allowedTypes: state.app.hasIn(['codeList', 'allowed-ext-profile'])
      ? state.app.getIn(['codeList', 'allowed-ext-profile']).toArray()
      : []
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Contact extends Component {
  static propTypes = {
    allowedTypes: PropTypes.array.isRequired,
    formErrors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string,
    readOnly: PropTypes.bool,
    version: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      formatError: false,
      sizeError: false
    };
  }

  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      fields.map((field) => change(form, field.key, edit.get(field.key)));
    }
  }

  onDrop(files, field) {
    const { allowedTypes, change } = this.props;

    this.setState({ formatError: false, sizeError: false });

    if (
      !allowedTypes.find(
        (t) => files[0]?.name?.toLowerCase().indexOf(t.get('name')) > -1
      )
    ) {
      this.setState({ formatError: true });
    } else if (files[0].size > 2000000) {
      this.setState({ sizeError: true });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        change(form, field, reader.result);
        this.setState({ [field]: files[0] });
        return true;
      };
    }
  }

  removePhoto = (field) => {
    const { change, form } = this.props;
    change(form, field, ' ');
    this.setState({ [field]: 'removed', formatError: false });
  };

  render() {
    const { formatError, sizeError } = this.state;
    const {
      allowedTypes,
      formErrors,
      edit,
      handleSubmit,
      readOnly,
      version
    } = this.props;
    let profilePhoto;

    if (this.state[photoField]) {
      profilePhoto = this.state[photoField].preview;
    } else if (edit) {
      if (edit.has('profile_picture')) {
        profilePhoto = `${process.env.REACT_APP_ASSETSURL}${edit.get(
          'profile_picture'
        )}`;
      }
    }

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published')) {
        return true;
      }

      if (version.get('was_published')) {
        switch (field) {
          case 'name':
          case 'company':
          case 'position':
            return false;
          default:
            return true;
        }
      }

      return false;
    };

    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={4}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.profile_photo" />
            </h6>
            <div
              className={classNames(
                styles['pic-div'],
                styles.small,
                (formatError || sizeError) && styles.error
              )}
            >
              <DropZone
                id="profile_photo_dropzone"
                className={classNames(
                  styles['drop-zone'],
                  styles['profile-photo'],
                  readOnly && styles.disabled
                )}
                style={
                  profilePhoto && { backgroundImage: `url(${profilePhoto})` }
                }
                ref={(d) => (this.dropZone = d)}
                onDrop={(e) =>
                  readOnly ? () => false : this.onDrop(e, photoField)
                }
                disabled={readOnly}
                accept="image/png, image/jpeg"
              />
              {profilePhoto && version && !version.get('was_published') && (
                <button
                  id="delete_profile_photo_button"
                  name="delete_profile_photo_button"
                  type="button"
                  onClick={() => this.removePhoto(photoField)}
                  className={styles['piclink-remove']}
                  disabled={readOnly}
                >
                  <FormattedMessage id="DELETE_PHOTO" />
                </button>
              )}
              <button
                id="add_change_profile_photo_button"
                name="add_change_profile_photo_button"
                type="button"
                className={styles['piclink-add']}
                disabled={readOnly}
                onClick={() => this.dropZone.open()}
              >
                <FormattedMessage
                  id={profilePhoto ? 'CHANGE_PHOTO' : 'ADD_PHOTO'}
                />
              </button>
            </div>
            {formatError && (
              <div className={styles.form__error}>
                <FormattedMessage
                  id="ERRORS.FILE_TYPE_NOT_ALLOWED"
                  values={{
                    allowed_types: allowedTypes
                      .map((t) => t.get('name'))
                      .join(', ')
                  }}
                />
              </div>
            )}
            {sizeError && (
              <div className={classNames(styles.form__error, styles.centered)}>
                <FormattedMessage
                  id="ERRORS.large_photo"
                  values={{ max: '2' }}
                />
              </div>
            )}
            {formErrors[photoField] &&
              formErrors[photoField].errors.map((error) => (
                <div className={styles.form__error}>
                  {error.indexOf('ERRORS') > -1 ? (
                    <FormattedMessage
                      id={error}
                      values={{
                        allowed_types: allowedTypes
                          .map((t) => t.get('name'))
                          .join(', ')
                      }}
                    />
                  ) : (
                    error
                  )}
                </div>
              ))}
          </Col>
          <Col xs={8}>
            {fields.map((field) => (
              <InputGroup className={styles['heading-modal-group']}>
                <h6>
                  <FormattedMessage id={`FORM_FIELDS.${field.translation}`} />
                  {field.required && (
                    <span>
                      {' '}
                      (
                      <FormattedMessage id="REQUIRED" />)
                    </span>
                  )}
                </h6>
                <Field
                  name={field.key}
                  component={renderInput}
                  type="text"
                  disabled={readOnly || isFieldDisabled(field.key)}
                />
              </InputGroup>
            ))}
          </Col>
        </Row>
      </form>
    );
  }
}
