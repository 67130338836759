import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function fetchTags(query, partner, client, hierarchy) {
  const string = `?q=${query || ''}&partner=${
    partner && partner !== ' ' ? partner : ''
  }&client=${client && client !== ' ' ? client : ''}&hierarchy=${
    hierarchy ? 'true' : 'false'
  }`;

  return {
    types: [FETCH_TAGS_REQUEST, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE],
    promise: api().get(`/api/v1/tags/${string}`),
    partner,
    client
  };
}
