import { Map, fromJS } from 'immutable';
import {
  LIST_FOCUSES_REQUEST,
  LIST_FOCUSES_SUCCESS,
  LIST_FOCUSES_FAILURE,
  LIST_PRIORITIES_REQUEST,
  LIST_PRIORITIES_SUCCESS,
  LIST_PRIORITIES_FAILURE,
  UPDATE_PRIORITIES_REQUEST,
  UPDATE_PRIORITIES_SUCCESS,
  UPDATE_PRIORITIES_FAILURE,
  GET_CURRENT_FOCUS_SUCCESS,
  SET_WEEKLY_FOCUS_SUCCESS,
  LIST_DAILY_EXERCISES_REQUEST,
  LIST_DAILY_EXERCISES_SUCCESS,
  LIST_DAILY_EXERCISES_FAILURE,
  UPDATE_DAILY_EXERCISE_SUCCESS,
  LIST_IMPROVEMENT_REQUEST,
  LIST_IMPROVEMENT_SUCCESS,
  LIST_IMPROVEMENT_FAILURE,
  POST_TICKET_REQUEST,
  POST_TICKET_SUCCESS,
  POST_TICKET_FAILURE,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  focuses: Map(),
  exercises: Map(),
  currentFocus: Map(),
  improvement: Map(),
  priorities: Map(),
  recommendedPriorities: [],
  rearrangedPriorities: []
});

export default function development(state = initialState, action) {
  switch (action.type) {
    case LIST_FOCUSES_REQUEST:
      return state.set('focusesLoading', true);
    case LIST_FOCUSES_SUCCESS: {
      const result = action.result.body;

      return state
        .set('focusesLoading', false)
        .set('focuses', Map(result.map((r) => [r.indicator.id, fromJS(r)])))
        .set('focusesFailure', false);
    }
    case LIST_FOCUSES_FAILURE:
      return state.set('focusesLoading', false).set('focusesFailure', true);
    case UPDATE_PRIORITIES_REQUEST:
    case LIST_PRIORITIES_REQUEST:
      return state.set('prioritiesLoading', true);
    case UPDATE_PRIORITIES_SUCCESS:
    case LIST_PRIORITIES_SUCCESS: {
      const result = action.result.body;
      const { list, recommended, rearranged } = result;

      return state
        .set('prioritiesLoading', false)
        .set('priorities', Map(list.map((b) => [b.id, fromJS(b)])))
        .set('recommendedPriorities', recommended)
        .set('rearrangedPriorities', rearranged)
        .set('prioritiesFailure', false);
    }
    case UPDATE_PRIORITIES_FAILURE:
    case LIST_PRIORITIES_FAILURE:
      return state
        .set('prioritiesLoading', false)
        .set('prioritiesFailure', true);
    case GET_CURRENT_FOCUS_SUCCESS:
      return state.set('currentFocus', fromJS(action.result.body));
    case SET_WEEKLY_FOCUS_SUCCESS:
      return state.set('currentFocus', fromJS(action.result.body));
    case LIST_DAILY_EXERCISES_REQUEST:
      return state.set('exercisesLoading', true);
    case LIST_DAILY_EXERCISES_SUCCESS: {
      const result = action.result.body.data;

      return state
        .set('exercisesLoading', false)
        .set('exercises', Map(result.map((r) => [r.id, fromJS(r)])));
    }
    case LIST_DAILY_EXERCISES_FAILURE:
      return state.set('exercisesLoading', false);

    case UPDATE_DAILY_EXERCISE_SUCCESS:
      return state.setIn(
        ['exercises', action.result.body.id],
        fromJS(action.result.body)
      );

    case LIST_IMPROVEMENT_REQUEST:
      return state.set('improvementLoading', true);
    case LIST_IMPROVEMENT_SUCCESS: {
      const result = action.result.body;

      return state
        .set('improvementLoading', false)
        .set('improvement', Map(fromJS(result)));
    }
    case LIST_IMPROVEMENT_FAILURE:
      return state.set('improvementLoading', false);
    case POST_TICKET_REQUEST:
      return state.set('postTicketLoading', true);
    case POST_TICKET_SUCCESS:
      return state.set('postTicketLoading', false);
    case POST_TICKET_FAILURE:
      return state.set('postTicketLoading', false);
    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
