import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import classNames from 'classnames';

import { getProgressValues } from 'helpers';

import styles from './styles.css';
import './styles.css';

const ProgressCircle = (props) => {
  const { block, value, focus, defaultColor } = props;

  return (
    <div
      className={classNames(styles['progress-circle'], block && styles.block)}
    >
      <CircularProgressbar
        key="progress-circle"
        value={Math.round(value)}
        className={classNames(
          styles.svg,
          focus &&
            (defaultColor
              ? styles.DEFAULT
              : styles[
                  getProgressValues(
                    focus.tbis_perc ? focus.tbis_perc : focus.get('tbis_perc')
                  ).position
                ])
        )}
        text={`${Math.round(value)}%`}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        classForPercentage={() =>
          focus
            ? defaultColor
              ? styles.DEFAULT
              : styles[
                  getProgressValues(
                    focus.tbis_perc ? focus.tbis_perc : focus.get('tbis_perc')
                  ).position
                ]
            : null
        }
      />
    </div>
  );
};

ProgressCircle.propTypes = {
  block: PropTypes.bool,
  value: PropTypes.number.isRequired,
  focus: PropTypes.object.isRequired,
  defaultColor: PropTypes.bool
};

export default ProgressCircle;
