import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './styles.css';
import './styles.css';

const IndicatorGraph = (props) => {
  const { facet, betterIf, noValues, noMargin } = props;

  const getGraph = () => {
    switch (betterIf) {
      case 'low':
      case 'high':
      default:
        return (
          <div className={noMargin ? styles.graphNoMargin : styles.graph}>
            <div
              className={styles.indicator}
              style={{ left: `calc(${(facet.get('fp') || 0) * 100}% - 25px)` }}
            />
            <div className={styles.blue} />
            <div className={styles.yellow} />
            <div className={styles.green} />
            <div className={styles.blue} />
            {noValues ? null : (
              <>
                <div
                  key="text-left"
                  className={classNames(styles.info, styles['text-left'])}
                >
                  <FormattedMessage id={facet.get('level_1')} />
                </div>
                <div className={styles.info}>
                  <FormattedMessage id={facet.get('level_2')} />
                </div>
                <div className={styles.info}>
                  <FormattedMessage id={facet.get('level_3')} />
                </div>
                <div
                  key="text-right"
                  className={classNames(styles.info, styles['text-right'])}
                >
                  <FormattedMessage id={facet.get('level_4')} />
                </div>
              </>
            )}
          </div>
        );
    }
  };

  return <div>{getGraph()}</div>;
};

IndicatorGraph.propTypes = {
  facet: PropTypes.object.isRequired,
  betterIf: PropTypes.string
};

export default IndicatorGraph;
