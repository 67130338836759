import {
  UPDATE_PRIORITIES_REQUEST,
  UPDATE_PRIORITIES_SUCCESS,
  UPDATE_PRIORITIES_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Update priorities action
 *
 * @param {Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function updatePriorities(data) {
  return {
    types: [
      UPDATE_PRIORITIES_REQUEST,
      UPDATE_PRIORITIES_SUCCESS,
      UPDATE_PRIORITIES_FAILURE
    ],
    promise: api().put('/api/v1/development/focus/priorities/', data),
    data
  };
}
