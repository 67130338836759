import { fromJS } from 'immutable';

import getCurrentAssessmentId from './getCurrentAssessmentId';
import { getSelected } from './index';

export default (props, _selectedUsers) => {
  const { allData, fetchAnalytics, fetchDetailAnalyticsData, route } = props;
  const selectedUsers = _selectedUsers || getSelected(route);
  const fromHT = Number(route.query.ht) === 1;
  const promises = [];
  const reportIdsToLoad = [];

  return new Promise((resolve) => {
    selectedUsers.map((user) => {
      if (!allData.hasIn([Number(user), 'facets'])) {
        return reportIdsToLoad.push(user);
      }

      return false;
    });

    if (reportIdsToLoad.length > 0) {
      promises.push(
        fetchDetailAnalyticsData({ report_id: reportIdsToLoad.join(',') })
      );
    }

    Promise.all(promises).then((res) => {
      let metaFetchRequired = false;

      if (route.query.filtered !== 1) {
        res.map((response) => {
          if (
            !allData.find(
              (r) => r.get('id') === Number(response.data.report_id)
            ) ||
            allData.find(
              (r) =>
                r.get('id') === Number(response.data.report_id) &&
                !r.has('folders')
            ) ||
            fromHT
          ) {
            return (metaFetchRequired = true);
          }

          return false;
        });
      }

      const requiredMetaDataAssessments = [];

      res[0]?.result?.body?.map((r) => {
        const assessmentId = getCurrentAssessmentId(fromJS(r));

        if (requiredMetaDataAssessments.indexOf(assessmentId) < 0) {
          requiredMetaDataAssessments.push(assessmentId);
        }
      });

      if (metaFetchRequired === true) {
        fetchAnalytics({
          assessments: requiredMetaDataAssessments.join(',')
        });
      }

      const updatedSelects = Array.from(
        new Set(selectedUsers.map((i) => Number(i)))
      );

      resolve(updatedSelects);
    });
  });
};
