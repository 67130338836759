import {
  FETCH_ASSIGNMENT_REQUEST,
  FETCH_ASSIGNMENT_SUCCESS,
  FETCH_ASSIGNMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch assignment action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchAssignment(id) {
  return {
    types: [
      FETCH_ASSIGNMENT_REQUEST,
      FETCH_ASSIGNMENT_SUCCESS,
      FETCH_ASSIGNMENT_FAILURE
    ],
    promise: api().get(`/api/v1/users/assignments/${id}/`),
    id
  };
}
