import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Contacts action
 *
 * @param{Object} body
 * @returns {{types: *[], promise: Request }}
 */

export default function contacts(body) {
  let types;
  let promise;
  const { type } = body;
  const { simulationId } = body;
  const { data } = body;
  const { id } = body;

  if (!data.language) {
    data.language = 'english'; // eslint-disable-line
  }

  switch (type) {
    case 'create':
      types = [
        CREATE_CONTACT_REQUEST,
        CREATE_CONTACT_SUCCESS,
        CREATE_CONTACT_FAILURE
      ];
      promise = api().post(
        `/api/v1/simulations/${simulationId}/contacts/`,
        data
      );
      break;
    case 'edit':
      types = [
        EDIT_CONTACT_REQUEST,
        EDIT_CONTACT_SUCCESS,
        EDIT_CONTACT_FAILURE
      ];
      promise = api().put(
        `/api/v1/simulations/${simulationId}/contacts/${id}/`,
        data
      );
      break;
    case 'delete':
      types = [
        DELETE_CONTACT_REQUEST,
        DELETE_CONTACT_SUCCESS,
        DELETE_CONTACT_FAILURE
      ];
      promise = api().del(
        `/api/v1/simulations/${simulationId}/contacts/${id}/`
      );
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    simulationId,
    id,
    language: data.language
  };
}
