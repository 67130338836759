import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button } from 'reactstrap';

import styles from './styles.css';
import './styles.css';

const NotSupported = (props) => (
  <div className={styles['not-supported']}>
    <h2>
      <FormattedMessage id="WERE_SORRY" />
    </h2>
    <h3>
      <FormattedMessage
        id={`DEVELOPMENT_NOT_SUPPORTED_${
          props.route.query.type || 'not-enabled'
        }`}
      />
    </h3>

    <Button
      color="primary"
      size="lg"
      style={{ marginTop: 30, width: 110 }}
      onClick={() => (window.location = '/')}
    >
      <img src="/assets/img/refresh.png" alt="refresh" width="30" height="30" />
    </Button>
  </div>
);

NotSupported.propTypes = {
  route: PropTypes.object.isRequired
};

export default connect((state) => ({
  route: state.router.route
}))(NotSupported);
