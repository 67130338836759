import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

import { editSimulation, fetchSimulation } from 'actions/simulations';

import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainSimulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    mainDebrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ]),
    simulation: state.simulations.hasIn([
      'simulations',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.simulations.getIn([
          'simulations',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.simulations.getIn([
          'simulations',
          'english',
          Number(state.router.route.vars.id)
        ]),
    debrief: state.debriefs.hasIn([
      'debriefs',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.debriefs.getIn([
          'debriefs',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.debriefs.getIn([
          'debriefs',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) =>
    bindActionCreators({ editSimulation, fetchSimulation }, dispatch)
)
export default class BasicInformationTranslateTable extends Component {
  static propTypes = {
    id: PropTypes.number,
    simulation: PropTypes.object,
    mainSimulation: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    editSimulation: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    debrief: PropTypes.object,
    mainDebrief: PropTypes.object.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  getTable = () => {
    const {
      simulation,
      mainSimulation,
      hasError,
      disabled,
      debrief,
      mainDebrief
    } = this.props;

    const mainSessionData = mainSimulation || mainDebrief;

    return {
      data: [mainSessionData],
      customClassName: 'trans-2-table',
      fields: [
        {
          key: 'original_simulation_name',
          sortable: false,
          locked: true,
          renderField: (item) => item.get('name')
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            const session = simulation || debrief;
            const defaultValue = session.get('name') || null;

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={item.get('name')}
                  defaultValue={defaultValue}
                  onSubmit={this.handleSubmit}
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  handleSubmit = (data) => {
    const {
      id,
      editSimulation,
      version,
      fetchSimulation,
      debrief
    } = this.props;
    const { addAlert, intl } = this.context;

    const translated = {
      name: data.translation,
      language: version,
      isDebrief: Boolean(debrief)
    };

    editSimulation(id, translated).then((action) => {
      if (action.error && action.error.body) {
        if (typeof action.error.body === 'string') {
          fetchSimulation(id);

          return addAlert({
            color: 'danger',
            content: intl.formatMessage({ id: action.error.body })
          });
        }

        Object.keys(translated).map((key) => {
          if (action.error.body[key] && action.error.body[key].errors) {
            return addAlert({
              color: 'danger',
              content: action.error.body[key].errors
                .map((error) => intl.formatMessage({ id: error }))
                .join(', ')
                .trim()
            });
          }

          return false;
        });
      }

      return false;
    });
  };

  render() {
    const session = this.props.simulation || this.props.debrief;

    if (!session) {
      return <div />;
    }

    return (
      <div>
        <BuildTable table={this.getTable()} />
      </div>
    );
  }
}
