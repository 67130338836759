import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { sortRoles } from 'helpers';
import { getStageProgress } from '.';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';

import { defaultValue } from 'constants/fieldsData';
import { isEmptyOrSpaces, sortItems } from '../../../../../helpers';

export default function renderField(item, field, helpers) {
  switch (field.key) {
    case 'sso_enabled': {
      let ssoEnabled = false;
      const tabId = Number(helpers.tab);

      if (tabId === 3) {
        ssoEnabled = item.getIn(['owner', 'sso_enabled']);
      } else if (tabId === 5 || tabId === 6) {
        ssoEnabled = item.get('sso_enabled');
      }

      return (
        <td>
          <span
            className={
              ssoEnabled ? styles['checkmark-yes'] : styles['checkmark-no']
            }
            dangerouslySetInnerHTML={{
              __html: ssoEnabled ? '&#10003;' : '&#10005;'
            }}
          />
        </td>
      );
    }
    case 'checkbox': {
      const simulationId = helpers.pastSimulation
        ? helpers.pastSimulation.get('id')
        : item.getIn(['active_simulation', 'id']);

      const checked = helpers.selectedRows.included.indexOf(simulationId) > -1;
      const excluded = helpers.selectedRows.excluded.indexOf(simulationId) > -1;

      return (
        <td>
          <input
            type="checkbox"
            onChange={helpers.onSelect}
            checked={(helpers.selectedRows.all && !excluded) || checked}
          />
        </td>
      );
    }
    case 'name': {
      let name = item.get('name');
      const { query } = helpers;
      const index = name.toLowerCase().indexOf(query.toLowerCase());

      if (index >= 0) {
        name =
          // eslint-disable-next-line
          name.substring(0, index) +
          "<span style='color: #e89c30; font-size: inherit;'>" +
          name.substring(index, index + query.length) +
          '</span>' +
          name.substring(index + query.length, name.length);
      }

      return <td dangerouslySetInnerHTML={{ __html: name }} />;
    }
    case 'firstname': {
      const fullName = item.get('name')
        ? item.get('name')
        : `${item.get('firstname') || ''} ${item.get('surname') || ''}`;
      let name = fullName;
      let mail = item.get('email') || '';
      const profilePhoto = item.get('profile_photo');
      const { query } = helpers;

      const index = name.toLowerCase().indexOf(query.toLowerCase());
      const mailIndex = mail.toLowerCase().indexOf(query.toLowerCase());
      if (index >= 0) {
        name =
          // eslint-disable-next-line
          name.substring(0, index) +
          "<span style='color: #e89c30; font-size: inherit;'>" +
          name.substring(index, index + query.length) +
          '</span>' +
          name.substring(index + query.length, name.length);
      }

      if (mailIndex >= 0) {
        mail =
          // eslint-disable-next-line
          mail.substring(0, mailIndex) +
          "<span style='color: #e89c30; font-size: inherit;'>" +
          mail.substring(mailIndex, mailIndex + query.length) +
          '</span>' +
          mail.substring(mailIndex + query.length, mail.length);
      }

      const profilePhotoLink = profilePhoto
        ? `${process.env.REACT_APP_ASSETSURL}${profilePhoto}`
        : '/assets/img/profile-photo-placeholder.gif'; // eslint-disable-line
      const identifyingNumberViewPermission =
        helpers.permissions &&
        helpers.permissions.find(
          (p) => p.indexOf('user_profile_eid_view') > -1
        );

      return (
        <td
          style={{
            position: 'relative',
            minWidth: helpers.loggedUser.get('isPartner')
              ? '270px'
              : helpers.loggedUser.get('isClient')
              ? '320px'
              : '230px',
            padding: helpers.pastSimulation
              ? '.75rem .75rem .75rem 3.8rem'
              : '.75rem .75rem .75rem 2.3rem'
          }}
        >
          {helpers.expandable && (
            <button
              className={styles['expand-button']}
              onClick={helpers.onExpand}
            >
              {helpers.expanded ? '-' : '+'}
            </button>
          )}
          <div
            style={{ backgroundImage: `url(${profilePhotoLink})` }}
            className={styles['user-avatar']}
          />
          <div
            className={classNames(
              styles['user-name-email'],
              helpers.pastSimulation && styles.past,
              helpers.loggedUser.get('isPartner') && styles['partner-view'],
              helpers.loggedUser.get('isClient') && styles['client-view']
            )}
          >
            <strong
              title={fullName}
              dangerouslySetInnerHTML={{ __html: name }}
            />
            <br />
            {mail ? (
              <span
                key="user-email"
                className={styles.email}
                title={item?.get('email') || ''}
                dangerouslySetInnerHTML={{ __html: mail }}
              />
            ) : (
              'undefined'
            )}
          </div>

          {identifyingNumberViewPermission && item.has('eid') && (
            <div className={styles.tags}>
              <span
                className={classNames(
                  styles['user-tag'],
                  styles.red,
                  query === item.get('eid') && styles['with-filter']
                )}
              >
                {item.get('eid')}
              </span>
            </div>
          )}
          <div className={styles.tags}>
            {item.has('tags') &&
              item
                .get('tags')
                .map((t) => (
                  <span
                    className={classNames(
                      styles['user-tag'],
                      helpers.filterData.tags.map((t) => t.name).indexOf(t) >
                        -1 && styles['with-filter']
                    )}
                  >
                    {t}
                  </span>
                ))}
          </div>
        </td>
      );
    }
    case 'modules': {
      const selected = item.get('modules') ? item.get('modules').toArray() : [];

      return (
        <td>
          {selected.map((module, i) => (
            <div key={i}>
              <FormattedMessage
                id={`MODULE_VALUES.${module.get('name').toLowerCase()}`}
              />
            </div>
          ))}
        </td>
      );
    }
    case 'simulations': {
      const selected = item.get('simulations')
        ? sortItems(item.get('simulations'), {
            key: 'name',
            asc: true
          }).toArray()
        : [];
      return (
        <td>
          {selected.map(
            (simulation, i) =>
              !simulation.get('is_debrief') && (
                <div key={i}>{simulation.get('name')}</div>
              )
          )}
        </td>
      );
    }
    case 'debriefs': {
      const selected = item.get('debriefs')
        ? sortItems(item.get('debriefs'), {
            key: 'name',
            asc: true
          }).toArray()
        : [];
      return (
        <td>
          {selected.map((debrief, i) => (
            <div key={i}>{debrief.get('name')}</div>
          ))}
        </td>
      );
    }
    case 'language': {
      const selected = item.get('languages')
        ? item.get('languages').sort().toArray()
        : [];

      return (
        <td>
          {selected.map((language, i) => (
            <div key={i}>
              <FormattedMessage id={`LANGUAGE_VALUES.${language}`} />
            </div>
          ))}
        </td>
      );
    }
    case 'owner': {
      const value = item.get(field.key);
      const result = `${value.get('firstname')} ${value.get('surname')}`;

      return <td>{result}</td>;
    }
    case 'partner': {
      let result;
      let yell = false;
      const { query } = helpers;
      const value = item.get(field.key);

      if (!value) {
        yell =
          helpers.filterData.partners?.length > 0 &&
          helpers.filterData.partners?.indexOf(defaultValue.toLowerCase());
        result = 'Pinsight';
      } else {
        yell =
          helpers.filterData.partners?.indexOf(value.get('id')?.toString()) >
          -1;
        result = value.get('name');
      }

      const index = result.toLowerCase().indexOf(query.toLowerCase());

      if (index >= 0) {
        result =
          // eslint-disable-next-line
          result.substring(0, index) +
          "<span style='color: #e89c30; font-size: inherit;'>" +
          result.substring(index, index + query.length) +
          '</span>' +
          result.substring(index + query.length, result.length);
      }

      return (
        <td
          style={{ color: yell ? '#e89c30' : 'inherit', minWidth: 100 }}
          dangerouslySetInnerHTML={{ __html: result }}
        />
      );
    }
    case 'client': {
      let result;
      let yell = false;
      const { query } = helpers;
      const value = item.get(field.key);

      if (!value) {
        yell =
          helpers.filterData.clients?.length > 0 &&
          helpers.filterData.clients?.indexOf(defaultValue.toLowerCase()) > -1;
        result = 'N/A';
      } else {
        yell =
          helpers.filterData.clients?.indexOf(value.get('id').toString()) > -1;
        result = value.get('name');
      }

      const index = result.toLowerCase().indexOf(query.toLowerCase());

      if (index >= 0) {
        result =
          // eslint-disable-next-line
          result.substring(0, index) +
          "<span style='color: #e89c30; font-size: inherit;'>" +
          result.substring(index, index + query.length) +
          '</span>' +
          result.substring(index + query.length, result.length);
      }

      return (
        <td
          style={{ color: yell ? '#e89c30' : 'inherit', minWidth: 90 }}
          dangerouslySetInnerHTML={{ __html: result }}
        />
      );
    }
    case 'shared_with_stakeholders': {
      const simulation =
        helpers.pastSimulation || item.get('active_simulation');
      const fullName = (stakeholder) =>
        isEmptyOrSpaces(stakeholder.get('firstname')) ||
        isEmptyOrSpaces(stakeholder.get('surname'))
          ? stakeholder.get('email')
          : `${stakeholder.get('firstname')} ${stakeholder.get('surname')}`;

      return (
        <td style={{ whiteSpace: 'nowrap' }}>
          {simulation
            ?.getIn(['sharing', 'stakeholders'])
            ?.map((stakeholder) => (
              <span
                title={fullName(stakeholder)}
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {fullName(stakeholder)}
              </span>
            ))}
          {simulation?.getIn(['sharing', 'stakeholders']).size === 0 && 'N/A'}
        </td>
      );
    }
    case 'service': {
      const simulation =
        helpers.pastSimulation || item.get('active_simulation');
      let active = null;
      let id = null;

      if (simulation) {
        active = simulation.has('assessment_name')
          ? simulation.get('assessment_name')
          : simulation.get('simulation_name');

        if (simulation.has('simulation_id')) {
          if (simulation.has('assessment_id')) {
            id = `${simulation.get('simulation_id')}-${simulation.get(
              'assessment_id'
            )}`;
          } else {
            id = simulation.get('simulation_id').toString();
          }
        }
      }

      return (
        <td>
          {simulation && (
            <span
              style={{
                color:
                  id && helpers.filterData.assessments.indexOf(id) > -1
                    ? '#e89c30'
                    : 'inherit'
              }}
            >
              {active || null}
              <br />
              [
              <FormattedMessage
                id={`LANGUAGE_VALUES.${simulation.get('language')}`}
              />
              ]
            </span>
          )}
        </td>
      );
    }
    case 'stage': {
      const userStages = item.has('stages') ? item.get('stages') : null;
      let stages = [];
      let data = null;

      const isActiveSimPsychometricsOnly =
        item.has('active_simulation') &&
        item.getIn(['active_simulation', 'is_psychometrics_only']);
      const isPastSimPsychometricsOnly =
        helpers.pastSimulation &&
        helpers.pastSimulation.has('is_psychometrics_only') &&
        helpers.pastSimulation.get('is_psychometrics_only');
      const isDebrief =
        item.has('active_simulation') &&
        item.getIn(['active_simulation', 'is_debrief']);

      data = helpers.pastSimulation
        ? {
            progress: isPastSimPsychometricsOnly ? 4 : 7,
            subProgress: 16,
            color: '95BC0D',
            info: (
              <FormattedMessage
                id="STAGES.report1"
                values={{
                  simulation: helpers.pastSimulation.has('assessment_name')
                    ? helpers.pastSimulation.get('assessment_name')
                    : helpers.pastSimulation.get('simulation_name')
                }}
              />
            )
          }
        : getStageProgress(
            userStages,
            item.has('active_simulation')
              ? item.hasIn(['active_simulation', 'assessment_name'])
                ? item.getIn(['active_simulation', 'assessment_name'])
                : item.getIn(['active_simulation', 'simulation_name'])
              : null,
            item,
            isActiveSimPsychometricsOnly,
            isDebrief
          );
      stages =
        isActiveSimPsychometricsOnly || isPastSimPsychometricsOnly
          ? [1, 2, 3, 4]
          : [1, 2, 3, 4, 5, 6, 7];

      if (isDebrief) {
        stages = [1, 2, 3, 4, 5];
      }

      return (
        <td>
          {data.info}
          <ul style={{ paddingLeft: '0px' }}>
            {stages.map((stage) => (
              <li
                style={{
                  display: 'inline-block',
                  width: '25px',
                  height: '3px',
                  backgroundColor:
                    data.progress >= stage ? `#${data.color}` : '#CCCCCC',
                  margin: '0 1px'
                }}
              />
            ))}
          </ul>
          {item.get('modules').contains('Development') &&
            !isPastSimPsychometricsOnly &&
            !isActiveSimPsychometricsOnly && (
              <span
                key="app-enabled-indicator"
                className={classNames(styles['table-indicator'], styles.green)}
              >
                <FormattedMessage id="APP_ENABLED" />
              </span>
            )}
          {((helpers.pastSimulation != null &&
            helpers.pastSimulation.getIn(['sharing', 'is_enabled_self'])) ||
            (helpers.pastSimulation == null &&
              item.getIn([
                'active_simulation',
                'sharing',
                'is_enabled_self'
              ]))) && (
            <span
              key="report-shared-indicator"
              className={classNames(styles['table-indicator'], styles.blue)}
            >
              <FormattedMessage id="REPORT_SHARED_WITH_PARTICIPANT" />
            </span>
          )}
        </td>
      );
    }
    case 'assignments': {
      const assignments = item.get('simulation')
        ? item.get('simulation').get('assigments')
        : [];

      return (
        <td>
          {assignments.map((assignment, i) => {
            const assessor = assignment.get('assessor');
            const name = assessor ? (
              assessor.get('firstname')
            ) : (
              <FormattedMessage id="UNASSIGNED" />
            );

            return (
              <span key={i}>
                {assignment.get('name')}
                {moment(assignment.get('scheduled_at'))
                  .zone(assignment.get('scheduled_at'))
                  .format(' h:mm a')}
                : {name}
                <br />
              </span>
            );
          })}
        </td>
      );
    }
    case 'role': {
      return (
        <td>
          {item.get('is_owner') && (
            <span style={{ textTransform: 'capitalize' }}>
              <FormattedMessage id="ROLE_VALUES.owner" />
              <br />
            </span>
          )}
          {sortRoles(item.get('roles')).map((role) => (
            <span style={{ textTransform: 'capitalize' }}>
              <FormattedMessage id={`ROLE_VALUES.${role}`} /> <br />
            </span>
          ))}
        </td>
      );
    }
    case 'is_owner': {
      return (
        <td>
          <span
            className={
              item.get('is_owner')
                ? styles['checkmark-yes']
                : styles['checkmark-no']
            }
            dangerouslySetInnerHTML={{
              __html: item.get('is_owner') ? '&#10003;' : '&#10005;'
            }}
          />
        </td>
      );
    }
    case 'status': {
      return (
        <td>
          {item.get('status') ? (
            <FormattedMessage id={`STATUS_VALUES.${item.get('status')}`} />
          ) : (
            ''
          )}
        </td>
      );
    }
    case 'scheduled': {
      const data = helpers.pastSimulation || item;

      return (
        <td>
          {data.has('scheduled_at') ? (
            <span>
              {moment(data.getIn(['scheduled_at', 'start_time']))
                .zone(data.getIn(['scheduled_at', 'start_time']))
                .format('DD MMM YYYY')}
              <br />
              at{' '}
              {moment(data.getIn(['scheduled_at', 'start_time']))
                .zone(data.getIn(['scheduled_at', 'start_time']))
                .format('hh:mm A')}
            </span>
          ) : (
            'N/A'
          )}
        </td>
      );
    }
    default:
      return (
        <td style={{ textTransform: 'capitalize' }}>{item.get(field.key)}</td>
      );
  }
}
