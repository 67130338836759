import {
  UPDATE_DAILY_EXERCISE_REQUEST,
  UPDATE_DAILY_EXERCISE_SUCCESS,
  UPDATE_DAILY_EXERCISE_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Update daily exercises action
 *
 * @param {Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function updateDailyExercise(data) {
  return {
    types: [
      UPDATE_DAILY_EXERCISE_REQUEST,
      UPDATE_DAILY_EXERCISE_SUCCESS,
      UPDATE_DAILY_EXERCISE_FAILURE
    ],
    promise: api().post('/api/v1/development/exercises/', data),
    data
  };
}
