// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, formValueSelector } from 'redux-form';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { DownloadPDFForm } from 'forms';

// Styles
import modals from '../../../modals.css';
import '../../../modals.css';

/**
 * Download PDF modal
 * @class
 */
@connect(
  (state) => ({
    language: formValueSelector('downloadPDFForm')(state, 'language'),
    version: formValueSelector('downloadPDFForm')(state, 'version')
  }),
  (dispatch) => bindActionCreators({ submit }, dispatch)
)
export default class DownloadPDFModal extends Component {
  // Prop Types
  static propTypes = {
    submit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    language: PropTypes.string,
    version: PropTypes.string,
    multipleDownload: PropTypes.bool
  };

  // Context types
  static contextTypes = {
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Submit form
   */
  submitForm = () => {
    const { submit } = this.props;
    submit('downloadPDFForm');
  };

  /**
   * Handle submit
   * @param formData
   */
  handleSubmit = (formData) => {
    const { onSubmit, data } = this.props;
    onSubmit(formData, data);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { data, language, version, multipleDownload } = this.props;
    const { removeModal } = this.context;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id="DOWNLOAD_REPORTS" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <DownloadPDFForm
            data={data}
            onSubmit={this.handleSubmit}
            multipleDownload={multipleDownload}
          />
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_download_pdf_modal"
                name="cancel_download_pdf_modal"
                color="secondary"
                onClick={removeModal}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_download_pdf_modal"
                name="submit_download_pdf_modal"
                color="primary"
                onClick={this.submitForm}
                className={modals['footer-button']}
                disabled={
                  !language || language === ' ' || !version || version === ' '
                }
              >
                <FormattedMessage id="DOWNLOAD" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
