import {
  LIST_CODELISTS_REQUEST,
  LIST_CODELISTS_SUCCESS,
  LIST_CODELISTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List codelists action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function listCodelists() {
  return {
    types: [
      LIST_CODELISTS_REQUEST,
      LIST_CODELISTS_SUCCESS,
      LIST_CODELISTS_FAILURE
    ],
    promise: api().get('/api/v1/codelists/')
  };
}
