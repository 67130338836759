/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, change } from 'redux-form';
import { Map } from 'immutable';

// Styles
import { Button, Col, Row } from 'reactstrap';
import { fetchUsers } from 'actions/users';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components
import {
  ClientBasic,
  Branding,
  AccountOwner,
  TimeAndLanguage,
  ModulesSimulationsLanguages,
  Status,
  DeleteBlock
} from '../../blocks';

// Actions
import { getClientFields } from '../../actions';
import { sortItems } from '../../../helpers';

// Const
const form = 'createClient';

/**
 * Create user form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    partners: state.partners.get('allPartners'),
    isLoading: state.clients.get('createClientLoading'),
    editMode: state.router.route.attrs.edit,
    client:
      state.clients.getIn(['allClients', Number(state.router.route.vars.id)]) ||
      Map(),
    codeList: state.app.get('codeList'),
    permissions: state.app.has('permissions')
      ? state.app.get('permissions').toArray()
      : [],
    simulations: state.simulations.hasIn(['simulations', 'english'])
      ? state.simulations.getIn(['simulations', 'english']).toArray()
      : [],
    usersTotal: state.users.get('usersTotal'),
    loggedUser: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, fetchUsers, transitionTo }, dispatch);
  }
)
export default class CreateClientForm extends Component {
  // Prop types
  static propTypes = {
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    partners: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    editMode: PropTypes.bool,
    client: PropTypes.object,
    codeList: PropTypes.object,
    simulations: PropTypes.object,
    permissions: PropTypes.array,
    message: PropTypes.string,
    usersTotal: PropTypes.number.isRequired
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const {
      change,
      client,
      editMode,
      codeList,
      fetchUsers,
      usersTotal
    } = this.props;

    change(form, 'owner.status', 'active');

    if (editMode) {
      getClientFields(form, client, change, {
        debriefs: client.get('debriefs_enableable'),
        simulations: client.get('simulations_enableable'),
        languages: sortItems(codeList.get('language_published'), {
          key: 'name',
          asc: true
        }).toArray()
      });
      fetchUsers(0, usersTotal, { client_id: client.get('id') }, 'admin');
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      handleSubmit,
      isLoading,
      editMode,
      client,
      message,
      permissions,
      transitionTo,
      loggedUser
    } = this.props;
    const buttonMessage = editMode ? 'SAVE' : message;
    const showBranding =
      permissions.indexOf('client_profile_brand_header_add_edit') > -1;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Basic informations */}
        <ClientBasic owner={editMode ? client.get('owner') : null} />

        {/* Branding */}
        {showBranding && <Branding user={client} form={form} />}

        {/* Checkboxes */}
        <ModulesSimulationsLanguages form={form} user={client} />

        {!editMode && (
          <div>
            {/* Account owner */}
            <AccountOwner
              form={form}
              user={client}
              subOwner
              header="ACCOUNT_OWNER"
              profileNotRequired
            />

            {/* Time and language */}
            <TimeAndLanguage form={form} subOwner />

            {/* Status */}
            <Status disabled subOwner />
          </div>
        )}

        {/* Status */}
        {editMode &&
          !loggedUser.get('isPartner') &&
          !loggedUser.get('isClient') && <Status enabled status />}

        {/* Delete block */}
        {editMode && <DeleteBlock user={client} entity="client" />}

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="back_to_users"
              name="back_to_users"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/users')}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_create_client"
              name="submit_create_client"
              type="submit"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : buttonMessage} />
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
