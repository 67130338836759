import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from 'constants/actionTypes';
import { getCookie, isMobile } from 'helpers';
import api from 'api';
import { AUTH_TOKEN, MFA_REMEMBER } from '../../constants/cookies';

/**
 * Login action
 *
 * @param{Object} data
 * @param{String} token
 * @returns {{types: *[], promise: Request }}
 */

export default function login(data, token) {
  let body;
  let invitation = false;
  if (data) {
    body = {
      email: data.email,
      password: data.password,
      mfa_otp: data.mfa_otp,
      mfa_remember: data.mfa_remember,
      mfa_remember_cookie: getCookie(MFA_REMEMBER)
    };
  } else if (token) {
    body = {
      token
    };
    invitation = true;
  } else {
    body = {
      token: getCookie(AUTH_TOKEN)
    };
  }

  if (isMobile()) {
    body.is_persistent = true;
  }

  return {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: api()
      .post('/api/v1/users/login/', body)
      .then(
        (loginResponse) =>
          new Promise((resolve, reject) => {
            // eslint-disable-line no-new
            if (loginResponse.body && loginResponse.body.token) {
              api.defaultHeaders.Authorization = `Bearer ${loginResponse.body.token}`;
            }

            api()
              .get('/api/v1/users/me/')
              .then((getMeResponse) => {
                // eslint-disable-next-line no-new
                const authData = loginResponse.body;
                const userData = getMeResponse.body;

                resolve({
                  body: Object.assign(authData, userData, {
                    cookie_duration: 7
                  })
                });
              }, reject);
          })
      ),
    body,
    invitation
  };
}
