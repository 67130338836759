import {
  DELETE_ASSESSMENT_REQUEST,
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_ASSESSMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Delete assessment action
 *
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function deleteAssessment(data) {
  return {
    types: [
      DELETE_ASSESSMENT_REQUEST,
      DELETE_ASSESSMENT_SUCCESS,
      DELETE_ASSESSMENT_FAILURE
    ],
    promise: api().del(`/api/v1/assessment/${data.id}/`),
    id: data.id,
    language: data.language || 'english'
  };
}
