import {
  FETCH_DEBRIEFS_REQUEST,
  FETCH_DEBRIEFS_SUCCESS,
  FETCH_DEBRIEFS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch debriefs action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchDebriefs(offset, limit, query, language) {
  const string = `?q=${query ? encodeURIComponent(query) : ''}&offset=${
    offset || 0
  }&limit=all&language=${
    language || 'english'
  }&fields=id,name,lenght,partner,client,customized,language,languages,published,partners&onlyDebriefs=1`; // eslint-disable-line
  return {
    types: [
      FETCH_DEBRIEFS_REQUEST,
      FETCH_DEBRIEFS_SUCCESS,
      FETCH_DEBRIEFS_FAILURE
    ],
    promise: api().get(`/api/v1/simulations/${string}`),
    language: language || 'english'
  };
}
