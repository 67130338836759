// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// Components
import { BuildTable } from 'components';
import { TranslationRow } from 'forms/components';

// Actions
import { indicators as indicatorsAction } from 'actions/behaviors';

// Styles
import styles from '../../../../../../../../components/BuildTable/styles.css';
import '../../../../../../../../components/BuildTable/styles.css';

/**
 * Contacts translate table
 * @class
 */
@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    mainBehavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    behavior: state.behaviors.hasIn([
      'behaviors',
      ownProps.version,
      Number(state.router.route.vars.id)
    ])
      ? state.behaviors.getIn([
          'behaviors',
          ownProps.version,
          Number(state.router.route.vars.id)
        ])
      : state.behaviors.getIn([
          'behaviors',
          'english',
          Number(state.router.route.vars.id)
        ]),
    route: state.router.route
  }),
  (dispatch) => bindActionCreators({ indicatorsAction }, dispatch)
)
export default class IndicatorsTranslateTable extends Component {
  // PropTypes
  static propTypes = {
    id: PropTypes.number,
    behavior: PropTypes.object,
    mainBehavior: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    indicatorsAction: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool
  };

  // Context types
  static contextTypes = {
    removeModal: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired
  };

  /**
   * Get table
   * @returns {{data: Array, fields: *[]}}
   */
  getTable = (indicator) => {
    const { behavior, mainBehavior, hasError, disabled } = this.props;
    let data = [];

    if (indicator) {
      data = [
        { key: 'name', value: indicator.get('name') },
        {
          key: 'development_suggestion',
          value: indicator.get('development_suggestion'),
          modal: true
        },
        { key: 'more_info', value: indicator.get('more_info'), modal: true }
      ];
    }

    return {
      data,
      customClassName: 'trans-3-table',
      fields: [
        {
          key: 'empty',
          dark: true,
          sortable: false,
          renderField: (item) => (
            <FormattedMessage id={`FORM_FIELDS.${item.key}`} />
          )
        },
        {
          key: 'original_name',
          sortable: false,
          locked: true,
          renderField: (item) => (
            <span dangerouslySetInnerHTML={{ __html: item.value }} />
          )
        },
        {
          key: 'translation',
          sortable: false,
          ownData: true,
          renderField: (item) => {
            let defaultValue = null;
            let translatedIndicator;
            let mainIndicator = mainBehavior
              .get('indicators')
              .find((i) => i.get('id') === indicator.get('id'));

            if (behavior.has('indicators')) {
              translatedIndicator = behavior
                .get('indicators')
                .find((i) => i.get('id') === indicator.get('id'));
              mainIndicator = mainBehavior
                .get('indicators')
                .find((i) => i.get('id') === indicator.get('id'));

              if (translatedIndicator) {
                defaultValue = translatedIndicator.get(item.key) || null;
              }
            }

            return (
              <td
                className={classNames(
                  hasError && !defaultValue && styles.error
                )}
              >
                <TranslationRow
                  id={`${item.key}_${item.value}`}
                  modalData={
                    item.modal
                      ? {
                          root: mainIndicator,
                          item: item.key
                        }
                      : false
                  }
                  defaultValue={defaultValue}
                  onSubmit={(e) =>
                    this.handleSubmit(
                      e,
                      item,
                      translatedIndicator || mainIndicator
                    )
                  }
                  disabled={disabled}
                />
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * Handle submit
   * @param data
   * @param field
   * @param indicator
   */
  handleSubmit = (data, field, indicator) => {
    const { addAlert, removeModal } = this.context;
    const { id, indicatorsAction, version } = this.props;

    const translated = {
      language: version
    };
    translated[field.key] = data.translation;

    indicatorsAction({
      type: 'edit',
      behaviorId: id,
      id: indicator.get('id'),
      data: translated
    }).then((action) => {
      if (action.error) {
        window.scrollTo(0, 0);
        addAlert({
          color: 'danger',
          content: (
            <FormattedMessage id={action.error.body || 'ERROR OCCURRED'} />
          )
        });
      } else {
        removeModal();
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { behavior, mainBehavior } = this.props;

    if (!behavior) {
      return <div />;
    }

    return (
      <div>
        {mainBehavior.has('indicators') &&
        mainBehavior.get('indicators').size > 0 ? (
          mainBehavior
            .get('indicators')
            .toArray()
            .map((indicator, i) => (
              <BuildTable
                table={this.getTable(indicator)}
                tableKey={i + 1}
                key={i}
                total={mainBehavior.get('indicators').size}
              />
            ))
        ) : (
          <BuildTable table={this.getTable(null)} tableKey={1} />
        )}
      </div>
    );
  }
}
