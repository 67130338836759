import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { formValueSelector } from 'redux-form';
import api from 'api';

import { formatError, sortItems } from 'helpers';

import { Alert, Button } from 'reactstrap';

import { modules } from 'constants/fieldsData';
import { getCreateUserData } from '../actions';
import { getShareReportsAccessFormData } from '../../../../forms/Global/ShareReportsAccessForm';

import styles from '../styles.css';
import '../styles.css';

@connect(
  (state) => ({
    attributes: state.router.route.attrs,
    simulations: state.simulations.hasIn(['simulations', 'english'])
      ? sortItems(state.simulations.getIn(['simulations', 'english']), {
          key: 'name',
          asc: true
        }).toArray()
      : [],
    languages: state.app.hasIn(['codeList', 'language_published'])
      ? sortItems(state.app.getIn(['codeList', 'language_published']), {
          key: 'name',
          asc: true
        }).toArray()
      : [],
    formErrors: state.app.get('formErrors') || {},
    hasProfilePhoto:
      formValueSelector('createUser')(state, 'profile_photo') &&
      formValueSelector('createUser')(state, 'profile_photo') !== ' ',
    availableTimes: state.simulations.get('availableTimes'),
    shareReportsAccessFormData: getShareReportsAccessFormData(state)
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class Create extends Component {
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    attributes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    simulations: PropTypes.object,
    languages: PropTypes.object,
    formErrors: PropTypes.object,
    hasProfilePhoto: PropTypes.bool,
    availableTimes: PropTypes.array,
    shareReportsAccessFormData: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  getLink = () => {
    const { attributes } = this.props;
    if (
      attributes.role === 'participant' ||
      attributes.role === 'stakeholder' ||
      attributes.role === 'admin'
    ) {
      return (
        <Link
          to={`/users/${attributes.role}/invite`}
          id={`invite_${attributes.role}_at_once_link`}
        >
          <Button
            color="link"
            className={styles['link-many-participants']}
            id={`invite_${attributes.role}_at_once_button`}
            name={`invite_${attributes.role}_at_once_button`}
          >
            <FormattedMessage
              id={`INVITE_MANY_${attributes.role.toUpperCase()}S_AT_ONCE`}
            />
          </Button>
        </Link>
      );
    }

    return false;
  };

  handleSubmit = (user) => {
    const {
      transitionTo,
      attributes,
      dispatch,
      languages,
      availableTimes,
      shareReportsAccessFormData
    } = this.props;
    const { addAlert } = this.context;
    const data = getCreateUserData(attributes.role, this.handleSubmit);
    const { action } = data;
    if (attributes.role !== 'partner' && attributes.role !== 'client') {
      user.roles = [attributes.role]; // eslint-disable-line
    }
    const updatedUser = {};
    Object.keys(user).map((key) => (updatedUser[key] = user[key]));

    if (updatedUser.scheduled_at != null && availableTimes != null) {
      updatedUser.seed = availableTimes.seed;
    }

    if (attributes.role === 'participant') {
      updatedUser.sharing = [shareReportsAccessFormData];
    }

    if (updatedUser.simulations) {
      const result = [];

      updatedUser.simulations.map((simulation, index) => {
        if (simulation) {
          return result.push(index);
        }
        return false;
      });
      updatedUser.simulations = result; // eslint-disable-line
    }

    if (updatedUser.languages) {
      const result = [];

      updatedUser.languages.map((language, i) => {
        if (language) {
          return result.push(languages[i].get('name'));
        }
        return false;
      });
      updatedUser.languages = result; // eslint-disable-line
    }

    if (updatedUser.modules) {
      const result = [];

      updatedUser.modules.map((module, i) => {
        if (module) {
          return result.push(modules[i].get('id'));
        }
        return false;
      });
      updatedUser.modules = result; // eslint-disable-line
    }

    if (updatedUser.days) {
      const promises = [];

      updatedUser.days.map((day, i) => {
        const slot_id = updatedUser.ids ? updatedUser.ids[i] : null;

        return promises.push(
          api().put(`/api/v1/timeslots/${slot_id ? `${slot_id}/` : ''}`, {
            from_time: updatedUser['preferred-from'][i],
            to_time: updatedUser['preferred-to'][i],
            from_day_of_week: i + 1,
            to_day_of_week: i + 1,
            status: day ? 'available' : 'unavailable'
          })
        );
      });

      Promise.all(promises);
    }

    if (updatedUser.from && updatedUser.to) {
      const promises = [];
      const slot_id = updatedUser.unavailable_id;

      promises.push(
        api().put(`/api/v1/timeslots/${slot_id ? `${slot_id}/` : ''}`, {
          from_date: updatedUser.from,
          to_date: updatedUser.to,
          status: 'unavailable'
        })
      );

      Promise.all(promises);
    }

    const message =
      attributes.role === 'participant' && !updatedUser.invite_now
        ? 'USER_SUCCESSFULLY_CREATED'
        : data.success_message;
    return dispatch(action(updatedUser)).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id={message} />
        });
        transitionTo('/users', { tab: data.tab });
      } else if (action.error.body.errors) {
        const { errors } = action.error.body;

        if (errors) {
          addAlert({
            color: 'danger',
            content: formatError(errors)
          });
        }
      } else {
        const error = action.error.body;
        if (error.profile_photo || (error.owner && error.owner.profile_photo)) {
          window.scrollTo(0, 0);
        } else {
          const form = this.refs.form.offsetTop;
          window.scrollTo(0, form);
        }
      }
    });
  };

  render() {
    const { attributes, hasProfilePhoto, formErrors } = this.props;
    const data = getCreateUserData(attributes.role, this.handleSubmit);

    return (
      <div>
        <Alert
          color="danger"
          isOpen={
            !!(
              !hasProfilePhoto &&
              (formErrors.profile_photo ||
                (formErrors.owner && formErrors.owner.profile_photo))
            )
          }
        >
          <FormattedHTMLMessage id="REQUIRED_PHOTO_ERROR" />
        </Alert>
        <div className={styles['link-back']}>
          <Link to="/users" id="back_to_users">
            &larr; <FormattedMessage id="BACK_TO_USERS" />
          </Link>
        </div>
        {this.getLink()}
        <h1>
          <FormattedMessage id={data.heading} />
        </h1>

        <div ref="form">{data.component}</div>
      </div>
    );
  }
}
