import {
  FETCH_SIMULATION_DOCUMENTS_REQUEST,
  FETCH_SIMULATION_DOCUMENTS_SUCCESS,
  FETCH_SIMULATION_DOCUMENTS_FAILURE,
  FETCH_DEBRIEF_DOCUMENTS_REQUEST,
  FETCH_DEBRIEF_DOCUMENTS_SUCCESS,
  FETCH_DEBRIEF_DOCUMENTS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch simulations documents action
 *
 * @param{String} id
 * @param{String} sort
 * @param{Number} offset
 * @param{Number} limit
 * @param{String} language
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchSimulationDocuments(
  id,
  sort,
  offset,
  limit,
  language,
  isDebrief = false
) {
  const types = [];
  if (isDebrief) {
    types.push(
      FETCH_DEBRIEF_DOCUMENTS_REQUEST,
      FETCH_DEBRIEF_DOCUMENTS_SUCCESS,
      FETCH_DEBRIEF_DOCUMENTS_FAILURE
    );
  } else {
    types.push(
      FETCH_SIMULATION_DOCUMENTS_REQUEST,
      FETCH_SIMULATION_DOCUMENTS_SUCCESS,
      FETCH_SIMULATION_DOCUMENTS_FAILURE
    );
  }

  const string = `?sort=${sort || ''}&language=${language || 'english'}`;
  return {
    types,
    promise: api().get(`/api/v1/simulations/${id}/documents/${string}`),
    id,
    language: language || 'english'
  };
}
