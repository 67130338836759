// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { submit } from 'redux-form';

// Components
import { Button } from 'reactstrap';
import ReactModal from 'react-modal-resizable-draggable';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { AUTH_TOKEN } from '../../../../../../constants/cookies';
import { getCookie } from '../../../../../../helpers';

/**
 * Share report
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser')
  }),
  (dispatch) => bindActionCreators({ submit }, dispatch)
)
export default class TwilioVideoModal extends Component {
  // Prop types
  static propTypes = {
    user: PropTypes.object,
    room: PropTypes.object.isRequired,
    videoCall: PropTypes.object.isRequired,
    isDebrief: PropTypes.object.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    this.state = {
      defaultSettings: {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        showControls: false,
        subscribeToAudio: true,
        audioVolume: 100,
        publishAudio: true,
        publishVideo: true,
        participantIsSharing: false
      },
      publisher: null,
      session: null,
      callSession: null,
      sound: new Audio('/assets/files/call.mp3'),
      hasError: false,
      track: null,
      intervalId: null,
      highPriorityTrack: null,
      isDialing: true,
      networkQuality: 0,
      screenTrackStream: null,
      isSharingScreen: false
    };
  }

  componentDidMount() {
    const { sound } = this.state;
    createLocalVideoTrack().then((track) => {
      this.setState({ track });
      const localMediaContainer = document.getElementById(
        'twilio-video-publisher'
      );
      localMediaContainer.appendChild(track.attach());

      sound.play();
      // Play calling sound
      sound.addEventListener(
        'ended',
        () => {
          this.state.sound.currentTime = 0;
          this.state.sound.play();
        },
        false
      );
    });

    // Attach the Participant's Media to a <div> element.
    this.props.room.on('participantConnected', (participant) => {
      // eslint-disable-next-line no-console
      console.info(`Participant "${participant.identity}" connected`);
      this.setState({ isDialing: false });

      // stop ringing
      this.state.sound.pause();

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          const track = publication.track;
          document
            .getElementById('twilio-video-subscriber')
            .appendChild(track.attach());
        }
      });

      // track published, used here to set priority to the track in on trackSubscribed event
      participant.on('trackPublished', (remoteTrackPublication) => {
        if (remoteTrackPublication.trackName === 'participant-screen-share') {
          this.setState({ participantIsSharing: true });
        }
        remoteTrackPublication.on(
          'subscribed',
          (subscribedTrackPublication) => {
            subscribedTrackPublication.setPriority(
              remoteTrackPublication.publishPriority
            );
          }
        );
      });

      participant.on('trackSubscribed', (track) => {
        const element = document.createElement('div');
        let className = 'normal-priority';

        if (track.priority === 'high') {
          className = 'high-priority';
          this.state.highPriorityTrack = true;
        }
        element.classList.add(className);
        element.appendChild(track.attach());
        document.getElementById('twilio-video-subscriber').appendChild(element);
      });

      participant.on('trackUnsubscribed', (track) => {
        if (track.priority === 'high') {
          this.state.highPriorityTrack = null;
        }
        if (track.name === 'participant-screen-share') {
          this.setState({ participantIsSharing: false });
        }
        track.detach().forEach((element) => {
          element.remove();
        });
      });
    });

    const printNetworkQualityStats = (
      networkQualityLevel,
      networkQualityStats
    ) => {
      // Print in console the networkQualityLevel using bars
      this.setState({ networkQuality: networkQualityLevel });
      if (networkQualityStats) {
        // Print in console the networkQualityStats, which is non-null only if Network Quality
        // verbosity is 2 (moderate) or greater
        // eslint-disable-next-line no-console
        console.log('Network Quality statistics:', networkQualityStats);
      }
    };

    this.props.room.localParticipant.on(
      'networkQualityLevelChanged',
      printNetworkQualityStats
    );

    this.props.room.once('participantDisconnected', (participant) => {
      // eslint-disable-next-line no-console
      console.info(
        `Participant "${participant.identity}" has disconnected from the Room`
      );
      this.context.removeModal();
    });

    window.addEventListener('beforeunload', () => {
      // To disconnect from a Room
      this.props.room.disconnect();

      // Detach the local media elements
      this.props.room.localParticipant.tracks.forEach((publication) => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element) => element.remove());
      });

      // remove local video track
      this.state.track.disable();
      this.state.track.stop();

      fetch(
        `${process.env.REACT_APP_APIURL}/api/v1/twilio/call-end/${this.props.videoCall.id}/`,
        {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
          },
          mode: 'cors'
        }
      );
      this.context.removeModal();
    });

    window.addEventListener('pagehide', () => {
      // To disconnect from a Room
      this.props.room.disconnect();

      // Detach the local media elements
      this.props.room.localParticipant.tracks.forEach((publication) => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element) => element.remove());
      });

      // remove local video track
      this.state.track.disable();
      this.state.track.stop();

      fetch(
        `${process.env.REACT_APP_APIURL}/api/v1/twilio/call-end/${this.props.videoCall.id}/`,
        {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
          },
          mode: 'cors'
        }
      );
      this.context.removeModal();
    });

    this.state.intervalId = setInterval(() => {
      const modal = document.getElementsByClassName('flexible-modal')[0];

      if (!modal) {
        return;
      }

      if (!modal.style.top || !modal.style.left) {
        return;
      }

      // eslint-disable-next-line radix
      const topPos = parseInt(
        modal.style.top.substring(0, modal.style.top.length - 2)
      );
      // eslint-disable-next-line radix
      const leftPos = parseInt(
        modal.style.left.substring(0, modal.style.left.length - 2)
      );

      if (topPos < 0) {
        document.getElementsByClassName('flexible-modal')[0].style.top = 0;
      }

      if (leftPos < 0) {
        document.getElementsByClassName('flexible-modal')[0].style.left = 0;
      }

      // eslint-disable-next-line radix
      const modalWidth = parseInt(
        modal.style.width.substring(0, modal.style.width.length - 2)
      );
      if (leftPos + modalWidth > document.documentElement.clientWidth) {
        document.getElementsByClassName('flexible-modal')[0].style.left = `${
          document.documentElement.clientWidth - modalWidth
        }px`;
      }

      // eslint-disable-next-line radix
      const modalHeight = parseInt(
        modal.style.height.substring(0, modal.style.height.length - 2)
      );
      if (topPos + modalHeight > document.documentElement.clientHeight) {
        document.getElementsByClassName('flexible-modal')[0].style.top = `${
          document.documentElement.clientHeight - modalHeight
        }px`;
      }

      // change layout of video call.. TODO this be placed better but directly in render function it was not working
      if (this.state.highPriorityTrack) {
        document
          .getElementById('video-body')
          .classList.add('high-priority-layout');
      } else {
        document
          .getElementById('video-body')
          .classList.remove('high-priority-layout');
      }
    }, 1000);
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    // stop screen share if sharing
    this.stopShareScreen();

    // To disconnect from a Room
    this.props.room.disconnect();

    // Detach the local media elements
    this.props.room.localParticipant.tracks.forEach((publication) => {
      const attachedElements = publication.track.detach();
      attachedElements.forEach((element) => element.remove());
    });

    // remove local video track
    this.state.track.disable();
    this.state.track.stop();

    // stop ringing
    this.state.sound.pause();

    fetch(
      `${process.env.REACT_APP_APIURL}/api/v1/twilio/call-end/${this.props.videoCall.id}/`,
      {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
        },
        mode: 'cors'
      }
    );
    this.context.removeModal();

    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  async startCapture(displayMediaOptions) {
    let captureStream = null;

    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error: ${err}`);
    }
    return captureStream;
  }

  shareScreen() {
    this.startCapture({})
      .then((stream) => {
        const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
          logLevel: 'off',
          name: 'participant-screen-share' // TODO
        });
        screenTrack?.on('stopped', (screenTrackStream) => {
          this.props.room.localParticipant.unpublishTrack(screenTrackStream);
          screenTrackStream?.stop();
          this.setState({ isSharingScreen: false });
          this.setState({ screenTrackStream: null });
        });
        this.props.room.localParticipant.publishTrack(screenTrack, {
          priority: 'high'
        });
        this.setState({ screenTrackStream: screenTrack });
        this.setState({ isSharingScreen: true });
        fetch(
          `${process.env.REACT_APP_APIURL}/api/v1/twilio/call-mark-screen-share/${this.props.videoCall.id}/`,
          {
            method: 'put',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
            },
            mode: 'cors'
          }
        );
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.warn(e));
  }

  stopShareScreen() {
    if (this.props.room && this.state.screenTrackStream) {
      this.setState({ isSharingScreen: false });
      this.props.room.localParticipant.unpublishTrack(
        this.state.screenTrackStream
      );
      this.state.screenTrackStream?.stop();
      this.setState({ screenTrackStream: null });
    }
  }

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { removeModal } = this.context;
    return (
      <ReactModal
        isOpen
        toggle={() => false}
        initWidth={640}
        initHeight={537}
        minWidth={209}
        minHeight={209}
        className={modals['draggable-video-call-modal']}
      >
        <h6 className={modals['draggable-window']}> Drag here</h6>
        <div className="body" id="video-body">
          {this.state.isDialing && (
            <div style={{ padding: '30px' }}>
              <h5>
                <FormattedMessage
                  id="CALL_IS_CONNECTING"
                  defaultMessage="Please wait, call is connecting...."
                />
              </h5>
              <br />
              <p>
                <FormattedMessage
                  id="IF_DOES_NOT_ANSWER"
                  defaultMessage="If the participant does not answer, click the End Call button in
                the role-play window before you try again."
                />
              </p>
              <br />
              <p>
                <FormattedMessage
                  id="LOGOUT_REFRESH"
                  defaultMessage="    If you need to log out, refresh or close your browser, please
                first click the End Call button in the role-play window."
                />
              </p>
            </div>
          )}
          {!this.state.isDialing && (
            <span
              style={{
                position: 'absolute',
                top: '60px',
                left: '20px',
                zIndex: 99,
                backgroundColor: '#ffffff70',
                padding: '5px'
              }}
            >
              {this.state.networkQuality === 1 && (
                <div style={{ display: 'flex' }}>
                  <span className={modals['icon-0-signal']} />
                  <span style={{ lineHeight: '40px', paddingLeft: '5px' }}>
                    <FormattedMessage
                      id="POOR_NETWORK_QUALITY"
                      defaultMessage="Poor network quality"
                    />
                  </span>
                </div>
              )}
              {this.state.networkQuality === 2 && (
                <div style={{ display: 'flex' }}>
                  <span className={modals['icon-1-signal']} />
                  <span style={{ lineHeight: '40px', paddingLeft: '5px' }}>
                    <FormattedMessage
                      id="LOWER_NETWORK_QUALITY"
                      defaultMessage="Lower network quality detected"
                    />
                  </span>
                </div>
              )}
              {this.state.networkQuality === 3 && (
                <div style={{ display: 'flex' }}>
                  <span className={modals['icon-2-signal']} />
                  <span style={{ lineHeight: '40px', paddingLeft: '5px' }}>
                    <FormattedMessage
                      id="NORMAL_NETWORK_QUALITY"
                      defaultMessage="Normal network quality"
                    />
                  </span>
                </div>
              )}
              {this.state.networkQuality === 4 && (
                <div style={{ display: 'flex' }}>
                  <span className={modals['icon-3-signal']} />
                  <span style={{ lineHeight: '40px', paddingLeft: '5px' }}>
                    <FormattedMessage
                      id="GOOD_NETWORK_QUALITY"
                      defaultMessage="Good network quality"
                    />
                  </span>
                </div>
              )}
              {this.state.networkQuality === 5 && (
                <div style={{ display: 'flex' }}>
                  <span className={modals['icon-4-signal']} />
                  <span style={{ lineHeight: '40px', paddingLeft: '5px' }}>
                    <FormattedMessage
                      id="EXCELLENT_NETWORK_QUALITY"
                      defaultMessage="Excellent network quality"
                    />
                  </span>
                </div>
              )}
            </span>
          )}
          <div
            style={{ height: 400 }}
            id="twilio-video-subscriber"
            className={modals['subscriber-video-box']}
          />
          <div
            id="twilio-video-publisher"
            className={modals['publisher-video-box']}
          />
        </div>
        <Button
          id="end_call_button"
          name="end_call_button"
          color="danger"
          onClick={removeModal}
          className={modals['flexible-footer-button']}
        >
          <FormattedMessage id="END_CALL" />
        </Button>
        {this.props.isDebrief &&
          !this.state.isDialing &&
          !this.state.isSharingScreen && (
            <Button
              id="share_screen_button"
              name="share_screen_button"
              color="secondary"
              onClick={() => this.shareScreen()}
              className={modals['flexible-footer-button-share']}
              style={{ width: '130px' }}
              disabled={this.state.participantIsSharing}
            >
              <FormattedMessage id="SHARE_SCREEN" />
            </Button>
          )}
        {this.props.isDebrief &&
          !this.state.isDialing &&
          this.state.isSharingScreen && (
            <Button
              id="end_share_screen_button"
              name="end_share_screen_button"
              color="danger"
              onClick={() => this.stopShareScreen()}
              className={modals['flexible-footer-button-share']}
              style={{ width: '130px' }}
            >
              <FormattedMessage id="STOP_SHARING" />
            </Button>
          )}
      </ReactModal>
    );
  }
}
