/**
 * Stage redirect
 * @param{Object} user
 */
export default function stageRedirect(user) {
  const stages = Object.keys(user.stages).map((key) => user.stages[key]);
  const activeRole = user.active_role;
  const hasPsychometrics =
    user.active_simulation && user.active_simulation.has_psychometrics;
  const isDebrief = user.active_simulation && user.active_simulation.is_debrief;

  const stage = stages
    ? stages.find((item) => !item.completed && item.name !== 'invited3')
    : { name: 'other' };

  function toHomeRoute() {
    if (activeRole === 'admin') {
      return '/users';
    }
    if (activeRole === 'assessor') {
      return '/assignments';
    }
    if (activeRole === 'stakeholder') {
      return '/analytics';
    }

    return '/';
  }

  if (
    activeRole === 'participant' &&
    stages.find((item) => item.name === 'simulation1' && item.completed) &&
    stages.find((item) => item.name === 'simulation5' && !item.completed) &&
    stages.find((item) => item.name === 'invited2a_tc' && item.completed) &&
    stages.find((item) => item.name === 'invited2b_pp' && item.completed) &&
    stages.find((item) => item.name === 'invited3' && item.completed) &&
    stages.find((item) => item.name === 'invited4' && item.completed) &&
    stages.find((item) => item.name === 'invited5' && item.completed)
  ) {
    return '/auth/invite/prework/practice-mode';
  }

  if (
    activeRole === 'participant' &&
    stages.find((item) => item.name === 'invited2a_tc' && item.completed) &&
    stages.find((item) => item.name === 'invited2b_pp' && item.completed) &&
    stages.find((item) => item.name === 'invited6' && item.completed) &&
    stages.find((item) => item.name === 'simulation5' && item.completed)
  ) {
    if (
      stages.find((item) => item.name === 'report1' && item.completed) ||
      isDebrief
    ) {
      if (user.modules.indexOf('Development') > -1) {
        return '/development';
      }
      if (!user.has_shared_report && user.modules.indexOf('Development') < 0) {
        return '/dashboard';
      }

      return '/analytics';
    }

    if (user.has_shared_report) {
      return '/analytics';
    }

    if (
      stages.find((item) => item.name === 'pre-work2' && !item.completed) &&
      user.lat_start_time != null &&
      hasPsychometrics
    ) {
      return '/auth/invite/prework/lat';
    }

    return '/dashboard';
  }

  if (
    activeRole === 'participant' &&
    (stages.find((item) => item.name === 'scheduled1' && item.completed) ||
      stages.find(
        (item) => item.name === 'debrief_scheduled1' && item.completed
      )) &&
    stages.find((item) => item.name === 'invited2a_tc' && item.completed) &&
    stages.find((item) => item.name === 'invited2b_pp' && item.completed) &&
    stages.find((item) => item.name === 'invited6' && item.completed) &&
    stage.name !== 'invited3'
  ) {
    if (
      (stages.find((item) => item.name === 'pre-work2' && item.completed) &&
        hasPsychometrics) ||
      stages.find(
        (item) => item.name === 'debrief_scheduled1' && item.completed
      )
    ) {
      return '/auth/invite/prework/practice-mode';
    }

    if (
      stages.find((item) => item.name === 'pre-work1' && item.completed) &&
      hasPsychometrics
    ) {
      return '/auth/invite/prework/lat';
    }

    return '/auth/invite/prework';
  }

  switch (stage ? stage.name : null) {
    case 'invited1':
    case 'invited2a_tc':
    case 'invited2b_pp':
      return '/auth/invite/terms';
    case 'invited3':
    case 'invited4':
    case 'invited5':
      return '/auth/invite/setup';
    case 'invited6':
      if (activeRole === 'participant') {
        return '/auth/invite/eeo';
      }
      return toHomeRoute();
    case 'scheduled1':
    case 'debrief_scheduled1': {
      if (activeRole === 'participant' && user.active_simulation) {
        if (user.active_simulation.is_psychometrics_only) {
          return '/auth/invite/prework';
        }
        return '/auth/invite/schedule';
      }

      return toHomeRoute();
    }
    default: {
      return toHomeRoute();
    }
  }
}
