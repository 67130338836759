// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import api from 'api';

// Components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

// Styles
import modals from '../../../../../modals.css';
import '../../../../../modals.css';

// Actions
import {
  fetchUsers,
  scheduleAssessor,
  fetchUserStageHistory
} from 'actions/users';

const tabs = [
  { id: '1', key: 'AVAILABLE' },
  { id: '2', key: 'UNAVAILABLE' }
];

/**
 * Assess
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    participant: state.users.getIn([
      'allUsers',
      Number(state.router.route.vars.id)
    ]),
    participant_id: Number(state.router.route.vars.id),
    participant_history: state.users.getIn([
      'allUsers',
      Number(state.router.route.vars.id),
      'stageHistory'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchUsers, scheduleAssessor, fetchUserStageHistory },
      dispatch
    )
)
export default class EditAssessor extends Component {
  // Prop types
  static propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    scheduleAssessor: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    stage: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    participant_id: PropTypes.number,
    participant_history: PropTypes.object,
    fetchUserStageHistory: PropTypes.func.isRequired
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    this.state = {
      activeTab: '1',
      selected: null,
      available: [],
      unavailable: [],
      loadingAvailable: false,
      loadingUnavailable: false
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { stage } = this.props;

    // eslint-disable-next-line
    this.setState({
      loadingAvailable: true,
      loadingUnavailable: true
    });
    api()
      .get(`/api/v1/timeslots/${stage.getIn(['data', 'slot_id'])}/available/`)
      .then((action) => {
        // eslint-disable-next-line
        this.setState({
          loadingAvailable: false,
          available: action.body.sort((a, b) => {
            if (
              a.user.is_partners === b.user.is_partners &&
              a.user.is_participants_partner === b.user.is_participants_partner
            ) {
              if (a.user.firstname < b.user.firstname) {
                return -1;
              }
              if (a.user.firstname > b.user.firstname) {
                return 1;
              }

              return 0;
            }

            if (
              a.user.is_participants_partner ||
              b.user.is_participants_partner
            ) {
              return (
                a.user.is_participants_partner < b.user.is_participants_partner
              );
            }

            return (
              a.user.is_partners > b.user.is_partners ||
              a.user.is_participants_partner > b.user.is_participants_partner
            );
          })
        });

        api()
          .get(
            `/api/v1/timeslots/${stage.getIn(['data', 'slot_id'])}/unavailable/`
          )
          .then((sAction) => {
            // eslint-disable-next-line
            this.setState({
              loadingUnavailable: false,
              unavailable: sAction.body
                .filter((i) => !action.body.find((a) => a.user.id === i.id))
                .sort((a, b) => {
                  if (
                    a.is_partners === b.is_partners &&
                    a.is_participants_partner === b.is_participants_partner
                  ) {
                    if (a.firstname < b.firstname) {
                      return -1;
                    }
                    if (a.firstname > b.firstname) {
                      return 1;
                    }

                    return 0;
                  }

                  if (a.is_participants_partner || b.is_participants_partner) {
                    return (
                      a.is_participants_partner < b.is_participants_partner
                    );
                  }

                  return (
                    a.is_partners > b.is_partners ||
                    a.is_participants_partner > b.is_participants_partner
                  );
                })
            });
          });
      });
  }

  /**
   * Get tab content
   * @param tab
   */
  getTabContent = (tab) => {
    const {
      loadingAvailable,
      loadingUnavailable,
      available,
      unavailable
    } = this.state;
    const result = tab.id === '1' ? available : unavailable;

    if (tab.id === '1') {
      return loadingAvailable ? (
        <div>
          <FormattedMessage id="LOADING" />
        </div>
      ) : (
        <div>
          {result.map((item) => (
            <div style={{ paddingLeft: 20 }}>
              <Label htmlFor="assessor">
                <Input
                  name="assessor"
                  type="radio"
                  value={item.user.id}
                  onChange={() => this.setState({ selected: item.user.id })}
                  style={{ marginRight: 10 }}
                />
                <span
                  className={classNames(
                    item.user.is_partners && modals.unavailable,
                    item.user.is_participants_partner && modals.available
                  )}
                >
                  {`${item.user.firstname} ${item.user.surname}`}
                </span>
              </Label>
            </div>
          ))}
        </div>
      );
    }

    return loadingUnavailable ? (
      <div>
        <FormattedMessage id="LOADING" />
      </div>
    ) : (
      <div>
        {result.map((item) => (
          <div style={{ paddingLeft: 20 }}>
            <Label htmlFor="assessor">
              <Input
                name="assessor"
                type="radio"
                value={item.id}
                onChange={() => this.setState({ selected: item.id })}
                style={{ marginRight: 10 }}
              />
              <span
                className={classNames(
                  item.is_partners && modals.unavailable,
                  item.is_participants_partner && modals.available
                )}
              >
                {`${item.firstname} ${item.surname}`}
                {item.holidays && (
                  <span className={modals.unavailable}>
                    {' '}
                    (
                    <FormattedMessage id="HOLIDAY" />)
                  </span>
                )}
              </span>
            </Label>
          </div>
        ))}
      </div>
    );
  };

  /**
   * Submit
   */
  submit = () => {
    // Submit action
    const {
      scheduleAssessor,
      stage,
      participant_id,
      fetchUserStageHistory
    } = this.props;
    const { removeModal, addAlert, intl } = this.context;
    const { selected } = this.state;

    const data = {
      assessor_id: selected,
      slot_id: stage.getIn(['data', 'slot_id'])
    };

    scheduleAssessor(participant_id, data).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SUCCESSFULLY_EDITED" />
        });
        fetchUserStageHistory(participant_id);
      } else {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({
            id: JSON.stringify(action.error.message)
          })
        });
      }
      window.scrollTo(0, 0);
      removeModal();
    });
  };

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { stage } = this.props;
    const { activeTab, selected } = this.state;
    const { removeModal } = this.context;
    let message = '';

    if (stage.get('name') === 'USEREVENT.roleplay_planned') {
      const contact = stage.getIn(['data', 'contact']);
      const startTime = stage.getIn(['data', 'time_from']);

      message = (
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.roleplay_planned"
          values={{
            rp: stage.getIn(['data', 'roleplay']) || 'Roleplay',
            name: contact,
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />
      );
    } else if (stage.get('name') === 'USEREVENT.scoring_planned') {
      const startTime = stage.getIn(['data', 'score_by']);

      message = (
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.scoring_planned"
          values={{
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />
      );
    } else if (stage.get('name') === 'USEREVENT.debrief_call_planed') {
      const contact = stage.getIn(['data', 'contact']);
      const startTime = stage.getIn(['data', 'time_from']);
      message = (
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.debrief_call_planed"
          values={{
            rp: stage.getIn(['data', 'roleplay']) || 'Roleplay',
            name: contact,
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />
      );
    }

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id="EDIT_ASSESSOR_MANUALLY" /> <br />
          <small>({message})</small>
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <Nav tabs>
            {tabs.map((t) => (
              <NavItem>
                <NavLink
                  id={`tab-${t.id}`}
                  className={classNames({ active: activeTab === t.id })}
                  onClick={() => this.setState({ activeTab: t.id })}
                >
                  <FormattedMessage id={t.key} />
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {tabs.map((t) => (
              <TabPane tabId={t.id} style={{ marginTop: 50 }}>
                <Row>
                  <Col sm="12">{this.getTabContent(t)}</Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={5} className={modals['modal-button-col']}>
              <Button
                id="cancel_edit_assessor"
                name="cancel_edit_assessor"
                color="secondary"
                onClick={removeModal}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CANCEL" />
              </Button>{' '}
            </Col>
            <Col xs={7} className={modals['modal-button-col']}>
              <Button
                id="submit_edit_assessor"
                name="submit_edit_assessor"
                disabled={!selected}
                color="primary"
                onClick={this.submit}
                className={modals['footer-button']}
              >
                <FormattedMessage id="CONFIRM" />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
