import {
  CREATE_SIMULATION_REQUEST,
  CREATE_SIMULATION_SUCCESS,
  CREATE_SIMULATION_FAILURE,
  CREATE_DEBRIEF_REQUEST,
  CREATE_DEBRIEF_SUCCESS,
  CREATE_DEBRIEF_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Copy simulation action
 *
 * @param{String} id
 * @returns {{types: *[], promise: Request }}
 */

export default function copySimulation(id, isDebrief) {
  const types = [];

  if (isDebrief) {
    types.push(
      CREATE_DEBRIEF_REQUEST,
      CREATE_DEBRIEF_SUCCESS,
      CREATE_DEBRIEF_FAILURE
    );
  } else {
    types.push(
      CREATE_SIMULATION_REQUEST,
      CREATE_SIMULATION_SUCCESS,
      CREATE_SIMULATION_FAILURE
    );
  }

  return {
    types,
    promise: api().get(`/api/v1/simulations/${id}/copy/`),
    id,
    language: 'english',
    isDebrief
  };
}
