import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'reactstrap';
import { Affix, Timer, Loader } from 'components';

import { fetchLAT, saveLATAnswer, logout } from 'actions/auth';
import { updateUserStage } from 'actions/users';

import styles from './styles.css';
import './styles.css';
import '../../../../../../modals.css';
import ConfirmModal from './ConfirmModal';
import FinishModal from './FinishModal';

const TEST_DURATION = 600000;
const LARGE_IMAGES = [8, 9, 12, 13, 14, 15, 16];

@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    LAT: state.auth.get('LAT'),
    latStartTime: state.auth.get('latStartTime'),
    latRemainingTime: state.auth.get('latRemainingTime'),
    fetchLATStatus: state.auth.get('fetchLATStatus')
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchLAT, saveLATAnswer, updateUserStage, logout },
      dispatch
    )
)
class LearningAptitudeTest extends React.Component {
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  static propTypes = {
    user: PropTypes.object.isRequired,
    latStartTime: PropTypes.string.isRequired,
    LAT: PropTypes.object.isRequired,
    fetchLAT: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    saveLATAnswer: PropTypes.func.isRequired,
    updateUserStage: PropTypes.func.isRequired,
    fetchLATStatus: PropTypes.string,
    logout: PropTypes.func.isRequired,
    latRemainingTime: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      instructions: user.has('lat_start_time'),
      remainingTime: user.has('lat_start_time') ? this.getRemainingTime() : null
    };
  }

  componentDidMount() {
    const { fetchLAT } = this.props;
    const { instructions } = this.state;

    if (instructions === true) {
      fetchLAT().then(() => {
        this.setState({
          remainingTime: this.getRemainingTime()
        });
      });
    }

    window.scrollTo(0, 0);
  }

  onSubmit = () => {
    const { addModal } = this.context;
    const { user, updateUserStage } = this.props;
    const isPsychometricsOnly =
      user.has('active_simulation') &&
      user.getIn(['active_simulation', 'is_psychometrics_only']);

    if (
      user.has('stages') &&
      user.getIn(['stages', 'pre-work2', 'completed']) === false
    ) {
      updateUserStage(user.get('id'), {
        stage: 'pre-work2',
        completed: true
      }).then(() => {
        if (isPsychometricsOnly) {
          updateUserStage(user.get('id'), {
            stage: 'simulation5',
            completed: true
          }).then(() => (window.location = '/dashboard'));
        }
      });
    }

    if (!isPsychometricsOnly) {
      addModal(<FinishModal />);
    }
  };

  getRemainingTime = () => this.props.latRemainingTime * 1000;

  render() {
    const { allAlert, addModal, removeModal, intl } = this.context;
    const { instructions, remainingTime } = this.state;
    const { fetchLAT, saveLATAnswer, fetchLATStatus, LAT, logout } = this.props;

    if (!instructions) {
      return (
        <div
          className={classNames(styles['lat-test'], styles.instructions)}
          key="instructions"
        >
          <h1>
            <FormattedMessage id="LEARNING_APTITUDE_INSTRUCTIONS" />
          </h1>

          <p>
            <FormattedHTMLMessage id="LAT_INFO" />
          </p>
          <p>
            <FormattedHTMLMessage id="LAT_TIMER_INFO" />
          </p>

          <p className={styles.strong}>
            <FormattedMessage id="HERE_IS_EXAMPLE" />:
          </p>

          <div className={classNames(styles['lat-test'], styles.example)}>
            <div className={styles.question}>
              <div className={styles['question-block']}>
                <h5>
                  <FormattedMessage id="QUESTION" />
                </h5>
                <img
                  width="520"
                  height="auto"
                  src="/assets/img/example_question.png"
                  alt="example"
                />
              </div>
              <div className={styles['answer-block']}>
                <h5>
                  <FormattedMessage id="ANSWER" />
                </h5>
                <img
                  width="570"
                  height="auto"
                  src="/assets/img/example_answer.png"
                  alt="example"
                />
              </div>
            </div>
          </div>

          <Button
            title={intl.formatMessage({ id: 'LOGOUT_AND_COME_BACK_LATER' })}
            color="secondary"
            size="lg"
            onClick={logout}
          >
            <FormattedMessage id="LOGOUT_AND_COME_BACK_LATER" />
          </Button>
          <Button
            title={intl.formatMessage({ id: 'START_THE_TEST' })}
            color="primary-dark"
            size="lg"
            onClick={() => {
              addModal(
                <ConfirmModal
                  onSubmit={() => {
                    fetchLAT().then(() => {
                      this.setState({
                        instructions: true,
                        remainingTime: this.getRemainingTime()
                      });

                      removeModal();
                      window.scrollTo(0, 0);
                    });
                  }}
                  onCancel={logout}
                  intl={intl}
                />
              );
            }}
          >
            <FormattedMessage id="START_THE_TEST" />
          </Button>
        </div>
      );
    }

    return (
      <div className={styles['lat-test']}>
        <h1>
          <FormattedMessage id="LEARNING_APTITUDE_TEST" />
        </h1>

        <Affix offset={100} top={20} className={styles['countdown-affix']}>
          <Timer
            duration={TEST_DURATION}
            remainingTime={remainingTime}
            onFinish={this.onSubmit}
          />
        </Affix>

        {fetchLATStatus === 'pending' && <Loader />}
        {LAT.toArray().map((q, index) => (
          <div
            className={classNames(
              styles.question,
              LARGE_IMAGES.indexOf(Number(q.get('ref'))) > -1
                ? styles.large
                : null
            )}
            key={q.get('ref')}
          >
            <h4>{index + 1}.</h4>

            <div style={{ textAlign: 'center' }}>
              <div
                className={styles['question-block']}
                key={`question-block-${q.get('ref')}`}
              >
                <h5>
                  <FormattedMessage id="QUESTION" />
                </h5>
                <img
                  src={`/assets/img/questions/${Number(q.get('ref'))}.png`}
                  alt={`question-${q.get('ref')}`}
                  style={Number(q.get('ref')) === 14 ? { width: 410 } : {}}
                />
              </div>

              <div
                className={styles['answer-block']}
                key={`answer-block-${q.get('ref')}`}
              >
                <h5>
                  <FormattedMessage id="ANSWER" />
                </h5>

                {q.get('answers').map((a) => (
                  <label
                    htmlFor={`answer-${q.get('ref')}-${a.get('ref')}`}
                    className={styles.answer}
                    key={a.get('ref')}
                  >
                    <img
                      src={`/assets/img/answers/${Number(q.get('ref'))}-${a.get(
                        'ref'
                      )}.png`}
                      alt={`answer-${q.get('ref')}-${a.get('ref')}`}
                    />

                    <input
                      id={`answer-${q.get('ref')}-${a.get('ref')}`}
                      name={`answer-${q.get('ref')}`}
                      checked={
                        q.has('user_answer') &&
                        q.getIn(['user_answer', 'ref']) === a.get('ref')
                      }
                      value={a.get('ref')}
                      type="radio"
                      onChange={() =>
                        saveLATAnswer({
                          question: q.get('ref'),
                          answer: a.get('ref')
                        }).then((response) => {
                          if (response.error && response.error.body) {
                            switch (response.error.body.message) {
                              case 'ERRORS.LAT_TEST_ALREADY_COMPLETED':
                              case 'ERRORS.LAT_TEST_LOCKED':
                                this.setState({
                                  remainingTime: 0
                                });
                                break;
                              default:
                                allAlert({
                                  color: 'danger',
                                  content: (
                                    <FormattedMessage
                                      id={response.error.body.message}
                                    />
                                  )
                                });
                                break;
                            }
                          }
                        })
                      }
                    />
                  </label>
                ))}
              </div>
            </div>
          </div>
        ))}

        <Button
          className={styles['submit-button']}
          color="primary-dark"
          type="button"
          size="lg"
          onClick={() => {
            this.setState({
              remainingTime: 0
            });
          }}
        >
          <FormattedMessage id="FINISH" />
        </Button>
      </div>
    );
  }
}

export default LearningAptitudeTest;
