const { detect } = require('detect-browser');

const browser = detect();

export default function checkSupport() {
  const isFirefox = browser && browser.name === 'firefox';
  const isChrome = browser && browser.name === 'chrome';
  const isEdge = browser && browser.name === 'edge-chromium';

  if (window.platform != null) {
    return true;
  }

  let validVersion = true;

  if (isFirefox) {
    const mainVersion = Number(browser.version.split('.')[0]);

    if (mainVersion < 52) {
      validVersion = false;
    }
  }

  return !!((isChrome || isFirefox || isEdge) && validVersion);
}
