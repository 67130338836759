import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'components';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import styles from './styles.css';
import './styles.css';

import {
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Label,
  Input
} from 'reactstrap';

import { editUser } from 'actions/users';
import { sortRoles } from 'helpers';

const { detect } = require('detect-browser');

const browser = detect();

@connect(
  (state) => ({
    direction: state.app.get('direction'),
    loggedUser: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      {
        transitionTo,
        editUser
      },
      dispatch
    );
  }
)
export default class Dropdown extends Component {
  static defaultProps = {
    titleValues: {}
  };

  static propTypes = {
    title: PropTypes.any,
    titleValues: PropTypes.object,
    items: PropTypes.array.isRequired,
    img: PropTypes.string,
    translate: PropTypes.bool,
    noItemsTranslate: PropTypes.bool,
    user: PropTypes.object,
    transitionTo: PropTypes.func.isRequired,
    customClassName: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    plus: PropTypes.bool,
    direction: PropTypes.string.isRequired,
    loggedUser: PropTypes.object,
    editUser: PropTypes.func.isRequired,
    small: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updatingRole: false
    };

    this.toggle = this.toggle.bind(this);
    this.getDropdownToggle = this.getDropdownToggle.bind(this);
  }

  getDropdownToggle() {
    const {
      translate,
      title,
      titleValues,
      user,
      disabled,
      direction
    } = this.props;

    if (user) {
      return (
        <DropdownToggle
          id="user_dropdown"
          caret
          className={styles['user-dropdown']}
        >
          <div
            className={styles['user-avatar']}
            style={{ backgroundImage: `url(${user.img})` }}
          />
          <div
            className="float-xs-right"
            style={
              direction === 'rtl' ? { marginLeft: 15, textAlign: 'right' } : {}
            }
          >
            <span className={styles['user-dropdown__name']}>
              {user.name} <br />
              <small>{user.email}</small>
            </span>
          </div>
        </DropdownToggle>
      );
    }

    return (
      <DropdownToggle id={title} caret disabled={disabled}>
        {translate ? (
          <FormattedMessage id={title} values={titleValues} />
        ) : (
          title
        )}
      </DropdownToggle>
    );
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  changeUserRole = (role) => {
    const { loggedUser, editUser } = this.props;

    this.setState({ updatingRole: true });
    editUser(loggedUser.get('id'), { active_role: role }).then((action) => {
      if (!action.error) {
        this.setState({ updatingRole: false });
        window.location = '/';
      } else {
        this.setState({ updatingRole: false });
      }
    });
  };

  render() {
    const {
      items,
      user,
      loggedUser,
      customClassName,
      style,
      plus,
      small,
      noItemsTranslate
    } = this.props;
    const { updatingRole } = this.state;

    return (
      <ButtonDropdown
        isOpen={this.state.open}
        toggle={this.toggle}
        className={customClassName}
        style={style}
      >
        {this.getDropdownToggle()}
        <DropdownMenu
          className={classNames(
            styles['dropdown-menu'],
            small && styles.small,
            styles[browser.name]
          )}
        >
          {user && (
            <div className={styles['roles-block']}>
              <DropdownItem header>
                <FormattedMessage id="ACTIVE_ROLE" />
              </DropdownItem>
              {sortRoles(loggedUser.get('roles')).map((role, index) => (
                <div key={index} role="menuitem">
                  <Label htmlFor={`role${index}`}>
                    <Input
                      name="role"
                      id={`role${index}`}
                      type="radio"
                      checked={role === loggedUser.get('active_role')}
                      onChange={() => this.changeUserRole(role)}
                    />
                    <FormattedMessage id={`ROLE_VALUES.${role}`} />
                  </Label>
                </div>
              ))}
              {updatingRole && (
                <div>
                  <Label>
                    <FormattedMessage id="LOADING" />
                  </Label>
                </div>
              )}
              <DropdownItem divider />
            </div>
          )}
          {items.map((item, i) => (
            <span
              key={i}
              className={styles['dropdown-item-btn']}
              onClick={(e) => {
                this.toggle();
                if (!item.disabled) {
                  if (item.action) {
                    item.action();
                  } else {
                    e.preventDefault();
                  }
                }
              }}
            >
              {item.action ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  id={noItemsTranslate ? `item-${i}-${item.order}` : item.title}
                  color="link"
                  className={classNames(item.disabled && styles.disabled)}
                >
                  {plus && '+ '}
                  {noItemsTranslate ? (
                    item.title
                  ) : (
                    <FormattedMessage id={item.title} />
                  )}
                </a>
              ) : (
                <Link
                  id={noItemsTranslate ? `item-${i}-${item.order}` : item.title}
                  to={item.path}
                  query={item.query || {}}
                  type="no-decoration"
                >
                  {plus && '+ '}
                  {noItemsTranslate ? (
                    item.title
                  ) : (
                    <FormattedMessage id={item.title} />
                  )}
                </Link>
              )}
            </span>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
