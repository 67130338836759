/**
 * Multiple on enter handler
 * @param actionCreators
 * @returns {Function}
 */
export default function multipleOnEnterHandler(actionCreators) {
  const creators = [];

  for (let i = 0; i < actionCreators.length; i++) {
    creators.push(actionCreators[i]);
  }

  return (currentRoute, nextRoute, router, dispatch, getState) => {
    const state = getState();
    const promises = [];

    creators.map((creator) => {
      if (
        state[creator.state].get(creator.object).size === 0 ||
        (creator.state === 'users' &&
          state[creator.state].get(creator.object).size < 2)
      ) {
        if (creator.withId) {
          const id = state.auth.get('loggedUser').get('id');
          if (id) {
            return promises.push(dispatch(creator.action(id)));
          }
        }

        return promises.push(dispatch(creator.action()));
      }

      return promises.push(Promise.resolve());
    });

    return Promise.all(promises);
  };
}
