// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { formValueSelector } from 'redux-form';

// Components
import { Col, InputGroup, Row } from 'reactstrap';
import { timezones } from 'constants/timezones';
import SearchSelection from '../components/SearchSelection';
// const langField = 'language';

// Const
const timeField = 'timezone';

/**
 * Time and language
 * @param props
 * @constructor
 */
const TimeAndLanguage = (props) => {
  let utc_list = [];

  timezones.map((zone) => {
    const zones = zone.get('utc') ? zone.get('utc').toArray() : [];
    return (utc_list = utc_list.concat(zones));
  });

  return (
    <Row>
      <Col xs={12}>
        {/* <h5><FormattedMessage id="TIME_ZONE" /> & <FormattedMessage id="LANGUAGE" /></h5> */}
        <h5>
          <FormattedMessage id="TIME_ZONE" />
        </h5>
      </Col>
      <Col xs={5}>
        <InputGroup>
          <label htmlFor={timeField} id="timezone-label">
            <FormattedMessage id={`FORM_FIELDS.${timeField}`} /> (
            <FormattedMessage id="REQUIRED" />)
          </label>
          <SearchSelection
            name={props.subOwner ? `owner.${timeField}` : timeField}
            defaultValue={props.timezone}
            form={props.form}
            data={utc_list.sort()}
            constant
            placeholder="PLEASE_SELECT_TIMEZONE"
            translatePlaceholder
            subOwner={props.subOwner}
            disabled={props.disabled || props.scheduledAt != null}
          />
        </InputGroup>
        {/*
                <InputGroup>
                    <label htmlFor={langField}>
                        <FormattedMessage id={`FORM_FIELDS.${langField}`} />
                        {' '}(<FormattedMessage id="REQUIRED" />)
                    </label>
                    <SearchSelection
                        name={props.subOwner ? `owner.${langField}` : langField}
                        defaultValue={props.language}
                        form={props.form}
                        data={props.languages.sort()}
                        translationKey="LANGUAGE_VALUES"
                        showValue="name"
                        selectValue="name"
                        placeholder="PLEASE_SELECT_LANGUAGE"
                        translatePlaceholder
                        subOwner={props.subOwner}
                        disabled={props.disabled}
                    />
                </InputGroup>
                */}
      </Col>
    </Row>
  );
};

// Prop types
TimeAndLanguage.propTypes = {
  form: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  language: PropTypes.string,
  languages: PropTypes.object,
  subOwner: PropTypes.bool,
  disabled: PropTypes.bool,
  scheduledAt: PropTypes.object
};

export default connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.form);
  return {
    timezone:
      selector(state, ownProps.subOwner ? 'owner.timezone' : 'timezone') || '',
    language:
      selector(state, ownProps.subOwner ? 'owner.language' : 'language') || '',
    languages: state.app.hasIn(['codeList', 'language_published'])
      ? state.app.getIn(['codeList', 'language_published']).toArray()
      : [], // eslint-disable-line
    scheduledAt: formValueSelector(ownProps.form)(state, 'scheduled_at')
  };
})(TimeAndLanguage);
