import { Map, OrderedMap, fromJS } from 'immutable';
import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  CREATE_PARTNER_REQUEST,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAILURE,
  GET_PARTNER_REQUEST,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAILURE,
  EDIT_PARTNER_REQUEST,
  EDIT_PARTNER_SUCCESS,
  EDIT_PARTNER_FAILURE,
  DELETE_ENTITY_REQUEST,
  DELETE_ENTITY_SUCCESS,
  DELETE_ENTITY_FAILURE,
  LOGOUT_SUCCESS,
  RESET_USERS_DATA
} from 'constants/actionTypes';

const initialState = Map({
  partners: OrderedMap(),
  allPartners: OrderedMap(),
  partnersMeta: Map()
});

export default function partners(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNERS_REQUEST:
      return state.set('partnersLoading', true);
    case FETCH_PARTNERS_SUCCESS: {
      const { filtered } = action.result.body;
      const partners = action.result.body.data;

      return state
        .set('partnersLoading', false)
        .setIn(
          ['partners'],
          OrderedMap(partners.map((partner) => [partner.id, fromJS(partner)]))
        )
        .mergeIn(
          ['allPartners'],
          Map(partners.map((partner) => [partner.id, fromJS(partner)]))
        )
        .set('partnersTotal', filtered);
    }
    case FETCH_PARTNERS_FAILURE:
      return state.set('partnersLoading', false).set('error', action.error);
    case CREATE_PARTNER_REQUEST:
      return state.set('createPartnerLoading', true);
    case CREATE_PARTNER_SUCCESS: {
      const partner = action.result.body;
      return state
        .set('createPartnerLoading', false)
        .setIn(['partners', partner.id], fromJS(partner))
        .setIn(['allPartners', partner.id], fromJS(partner));
    }
    case CREATE_PARTNER_FAILURE:
      return state
        .set('createPartnerLoading', false)
        .set('error', action.error);

    case GET_PARTNER_REQUEST:
      return state.set('getPartnerLoading', true);
    case GET_PARTNER_SUCCESS: {
      const id = Number(action.id);
      const partner = action.result.body;

      return state
        .set('getPartnerLoading', false)
        .setIn(['partners', id], fromJS(partner))
        .setIn(['allPartners', id], fromJS(partner));
    }
    case GET_PARTNER_FAILURE:
      return state.set('getPartnerLoading', false).set('error', action.error);

    case EDIT_PARTNER_REQUEST:
      return state.set('createPartnerLoading', true);
    case EDIT_PARTNER_SUCCESS: {
      const id = Number(action.id);
      const partner = action.result.body;

      return state.withMutations((newState) => {
        newState
          .set('createPartnerLoading', false)
          .mergeIn(['partners', id], fromJS(partner))
          .mergeIn(['allPartners', id], fromJS(partner));
      });
    }
    case EDIT_PARTNER_FAILURE:
      return state
        .set('createPartnerLoading', false)
        .set('error', action.error);
    case DELETE_ENTITY_REQUEST:
      return state.set('deleteEntityLoading', true);
    case DELETE_ENTITY_SUCCESS: {
      const id = Number(action.id);
      const { entity } = action;
      if (entity !== 'partner') {
        return state;
      }

      return state
        .set('deleteEntityLoading', false)
        .deleteIn(['partners', id])
        .deleteIn(['allPartners', id])
        .set('partnersTotal', state.get('partnersTotal') - 1);
    }
    case DELETE_ENTITY_FAILURE:
      return state.set('deleteEntityLoading', false).set('error', action.error);
    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    case RESET_USERS_DATA:
      return state.set('partners', state.get('allPartners'));
    default:
      return state;
  }
}
