import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  reduxForm,
  Field,
  FieldArray,
  formValueSelector,
  change
} from 'redux-form';
import { InputGroup } from 'reactstrap';
import { validate } from './validate';

import styles from '../../styles.css';

import { renderInput } from '../../actions';

const form = 'strategyForm';

const renderBehaviors = ({ fields, behaviors, usedBehaviors, disabled }) => (
  <div className={styles['strategy-behaviors-block']}>
    {fields.map((behavior, index) => (
      <div key={index}>
        <div className={styles.field}>
          <Field
            name={behavior}
            type="select"
            component={renderInput}
            label="Behavior"
            valueKey="behavior_id"
            optionKey="label"
            options={behaviors}
            disabledValues={usedBehaviors.map((b) => Number(b))}
            disabled={disabled}
            placeholder="PLEASE_SELECT_BEHAVIOR"
            translatePlaceholder
          />
        </div>
        <button
          id={`remove_behavior_${index}`}
          name={`remove_behavior_${index}`}
          className={styles['remove-button']}
          type="button"
          onClick={() => fields.remove(index)}
          disabled={disabled}
        />
      </div>
    ))}
    <button
      id="add_behavior"
      name="add_behavior"
      className={styles['add-button']}
      type="button"
      onClick={() => fields.push({})}
      disabled={disabled}
    >
      +
    </button>
  </div>
);

renderBehaviors.propTypes = {
  fields: PropTypes.array,
  behaviors: PropTypes.array,
  usedBehaviors: PropTypes.array
};

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    assessment: state.assessments.getIn([
      'assessments',
      'english',
      Number(state.router.route.vars.id)
    ]),
    usedBehaviors: formValueSelector(form)(state, 'behaviors') || [],
    formErrors: state.app.get('formErrors') || {},
    name: formValueSelector(form)(state, 'name'),
    description: formValueSelector(form)(state, 'description')
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)
export default class Strategy extends Component {
  static contextTypes = {
    intl: PropTypes.object
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    edit: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    assessment: PropTypes.object.isRequired,
    usedBehaviors: PropTypes.array,
    formErrors: PropTypes.object.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  };

  componentDidMount() {
    const { edit, change } = this.props;

    if (edit) {
      change(form, 'name', edit.get('name'));
      change(form, 'description', edit.get('description'));
      change(form, 'behaviors', edit.get('behaviors').toArray());
    }
  }

  render() {
    const {
      handleSubmit,
      assessment,
      usedBehaviors,
      formErrors,
      name,
      description
    } = this.props;
    const { intl } = this.context;

    const version =
      assessment &&
      assessment.has('languages') &&
      assessment.get('languages').find((l) => l.get('language') === 'english');

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published')) {
        return true;
      }

      if (version.get('was_published')) {
        switch (field) {
          case 'name':
          case 'description':
            return false;
          default:
            return true;
        }
      }

      return false;
    };

    const getCharInfoStyles = (value) => ({
      color: value?.length > 255 ? '#ff2418' : 'initial',
      float: 'right',
      position: 'relative',
      top: 3
    });

    return (
      <div className={styles['auth-wrapper']}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.strategy_name" />
              <span>
                {' '}
                (
                <FormattedMessage id="REQUIRED" />)
              </span>
              <small style={getCharInfoStyles(name)}>
                (
                {intl.formatMessage(
                  { id: 'X_CHARACTERS_LEFT' },
                  { value: 255 - (name?.length || 0) }
                )}
                )
              </small>
            </h6>
            <Field
              name="name"
              component={renderInput}
              type="text"
              disabled={isFieldDisabled('name')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.description" />
              <small style={getCharInfoStyles(description)}>
                (
                {intl.formatMessage(
                  { id: 'X_CHARACTERS_LEFT' },
                  { value: 255 - (description?.length || 0) }
                )}
                )
              </small>
            </h6>
            <Field
              name="description"
              component={renderInput}
              type="textarea"
              disabled={isFieldDisabled('description')}
            />
          </InputGroup>
          <InputGroup className={styles['heading-modal-group']}>
            <h6>
              <FormattedMessage id="FORM_FIELDS.behaviors" />
              <span>
                {' '}
                (
                <FormattedMessage id="REQUIRED" />)
              </span>
            </h6>
            <FieldArray
              name="behaviors"
              component={renderBehaviors}
              behaviors={
                assessment.has('behaviors')
                  ? assessment
                      .get('behaviors')
                      .filter((b) => b.get('display_as_default'))
                      .toArray()
                  : []
              }
              usedBehaviors={usedBehaviors}
              disabled={isFieldDisabled('behaviors')}
            />
            {formErrors.behaviors && (
              <div className={styles.form__error}>
                {formErrors.behaviors.errors.map((e) => (
                  <div>
                    <FormattedMessage id={e} />
                  </div>
                ))}
              </div>
            )}
          </InputGroup>
        </form>
      </div>
    );
  }
}
