import {
  CREATE_PARTNER_REQUEST,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Create partner action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function createPartner(data) {
  return {
    types: [
      CREATE_PARTNER_REQUEST,
      CREATE_PARTNER_SUCCESS,
      CREATE_PARTNER_FAILURE
    ],
    promise: api().post('/api/v1/partners/', data)
  };
}
