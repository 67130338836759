// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as LinkComponent } from 'routex/lib/react';

// Styles
import styles from './styles.css';
import './styles.css';

const Link = (props) => {
  let linkClass = 'default';

  switch (props.type) {
    case 'default':
      linkClass = 'default';
      break;
    case 'inherit':
      linkClass = 'inherit';
      break;
    case 'no-decoration':
      linkClass = 'no-decoration';
      break;
    default:
      break;
  }

  return (
    <LinkComponent
      id={props.id}
      to={props.to}
      style={props.style}
      className={classNames(styles[linkClass], props.className)}
      query={props.query}
    >
      {props.children}
    </LinkComponent>
  );
};

Link.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  to: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  query: PropTypes.object,
  className: PropTypes.string
};

export default Link;
