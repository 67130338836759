import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from 'reactstrap';
import { ShareParticipantsForm } from 'forms';
import { shareReport } from 'actions/analytics';

import modals from '../../../../../modals.css';
import '../../../../../modals.css';
import styles from './styles.css';
import './styles.css';

@connect(
  (state, ownProps) => ({
    users:
      state.analytics.getIn([
        'reportUsers',
        ownProps.participants[0].get('id')
      ]) || Map(),
    sharingLoading: state.analytics.get('sharingLoading'),
    usersLoading: state.analytics.get('reportUsersLoading'),
    analyticsShareUsers: state.analytics.has('reportUsers')
      ? state.analytics.get('reportUsers')
      : Map(),
    recipients:
      formValueSelector('shareParticipants')(state, 'participants') || [],
    shareWith: formValueSelector('shareParticipants')(state, 'share_with'),
    shareWithParticipant: formValueSelector('shareParticipants')(
      state,
      'share_with_participant'
    )
  }),
  (dispatch) =>
    bindActionCreators(
      {
        shareReport
      },
      dispatch
    )
)
export default class ShareModal extends Component {
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  constructor(...args) {
    super(...args);
    this.state = {
      organization: ''
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.users.size > 0) {
      this.setClient(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.users.size !== nextProps.users.size) {
      this.setClient(nextProps);
    }
  }

  setClient = (props) => {
    const current = this.props.participants[0];
    const user = props.users.find(
      (u) => u.get('id') === current.getIn(['user', 'id'])
    );

    if (user) {
      if (user.has('client')) {
        this.setState({ organization: user.getIn(['client', 'name']) });
      } else if (user.has('partner')) {
        this.setState({ organization: user.getIn(['partner', 'name']) });
      } else {
        this.setState({ organization: 'Pinsight' });
      }
    }
  };

  render() {
    const {
      submitAction,
      participants,
      sharingLoading,
      usersLoading
    } = this.props;
    const { organization } = this.state;
    const { removeModal } = this.context;
    const current = participants[0];
    const profilePhotoLink = current.hasIn(['user', 'profile_photo'])
      ? `${process.env.REACT_APP_ASSETSURL}${current.getIn([
          'user',
          'profile_photo'
        ])}`
      : '/assets/img/profile-photo-placeholder.gif';
    const fullName = `${current.getIn(['user', 'firstname'])} ${current.getIn([
      'user',
      'surname'
    ])}`;

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={removeModal} style={{ textAlign: 'center' }}>
          <FormattedMessage id="SHARE_PARTICIPANTS_RESULTS" />
        </ModalHeader>
        <ModalBody className={modals['modal-body']}>
          <div className={styles['share-body']}>
            <div
              className={styles['profile-photo']}
              style={{
                backgroundImage: `url(${profilePhotoLink})`
              }}
            />

            <h5>{fullName}</h5>
            {organization && <p>{organization}</p>}
            <p>
              {current.get('assessment_name')},{' '}
              {moment(current.get('date')).format('DD MMM YYYY')}
            </p>

            <div className={styles['share-with-block']}>
              <Row>
                <ShareParticipantsForm report={current} />
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <Row>
            <Col
              xs={12}
              className={modals['modal-button-col']}
              style={{ paddingRight: 15 }}
            >
              <Button
                id="submit_share_modal"
                name="submit_share_modal"
                color="primary"
                onClick={() => submitAction(this.props, this.context)}
                className={modals['footer-button']}
                disabled={sharingLoading || usersLoading}
              >
                <FormattedMessage id={sharingLoading ? 'LOADING' : 'SAVE'} />
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
