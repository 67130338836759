import {
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Create client action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function createClient(data) {
  const reduced = data;

  if (reduced.partner_id === 'null' || reduced.partner_id === ' ') {
    reduced.partner_id = null;
  }

  return {
    types: [
      CREATE_CLIENT_REQUEST,
      CREATE_CLIENT_SUCCESS,
      CREATE_CLIENT_FAILURE
    ],
    promise: api().post('/api/v1/clients/', reduced)
  };
}
