// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// Components
import { Alert, Row, Button, Col } from 'reactstrap';
import Select from './Select';

// Styles
import styles from './styles.css';
import formStyles from '../../../../../../../components/BuildTable/styles.css';
import './styles.css';
import '../../../../../../../components/BuildTable/styles.css';

// Actions
import { listAvailableTimes } from 'actions/simulations';

// Const
const size = 35;

/**
 * Schedule
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    scheduleLoading: state.simulations.get('scheduleLoading'),
    availableTimes: state.simulations.get('availableTimes') || {
      available: []
    },
    timesLoading: state.simulations.get('timesLoading'),
    locale: state.app.get('locale'),
    direction: state.app.get('direction')
  }),
  (dispatch) => bindActionCreators({ listAvailableTimes }, dispatch)
)
export default class Calendar extends Component {
  // Prop types
  static propTypes = {
    user: PropTypes.object.isRequired,
    onSchedule: PropTypes.func.isRequired,
    scheduleLoading: PropTypes.bool,
    listAvailableTimes: PropTypes.func.isRequired,
    availableTimes: PropTypes.object,
    timesLoading: PropTypes.bool,
    locale: PropTypes.string,
    direction: PropTypes.string.isRequired,
    manualSchedule: PropTypes.bool,
    selectedSimulation: PropTypes.object,
    onSelect: PropTypes.func,
    timezone: PropTypes.string,
    seed: PropTypes.number,
    partner: PropTypes.number,
    reschedule: PropTypes.bool
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      open: false,
      today: moment().format('DD')
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const {
      user,
      listAvailableTimes,
      locale,
      manualSchedule,
      selectedSimulation,
      timezone,
      seed,
      partner,
      reschedule
    } = this.props;

    moment.locale(locale === 'zh' ? 'zh-cn' : locale, {
      week: {
        dow: locale === 'he' ? 6 : 1,
        doy: locale === 'he' ? 12 : 6
      }
    });

    if (user.has('active_simulation') || manualSchedule) {
      if (manualSchedule) {
        listAvailableTimes(
          selectedSimulation.id,
          null,
          null,
          null,
          null,
          null,
          selectedSimulation.language,
          timezone,
          seed,
          partner,
          reschedule
        );
      } else {
        listAvailableTimes(user.get('active_simulation').get('simulation_id'));
      }
    } else {
      window.location = '/';
    }
  }

  /**
   * Get available times
   * @param day
   * @returns {Array}
   */
  getAvailableTimes = (day) => {
    const { availableTimes } = this.props;
    const available = [];

    availableTimes.available.map((time) => {
      const current = day.zone(time.from).format('YYYY-MM-DD');
      const compare = moment(time.from).zone(time.from).format('YYYY-MM-DD');

      if (current === compare) {
        return available.push(
          moment(time.from).locale('en').zone(time.from).format('hh:mm A')
        );
      }

      return false;
    });

    return available;
  };

  /**
   * Schedule
   */
  schedule = () => {
    const { onSchedule } = this.props;
    const { selected } = this.state;

    if (this.props.locale === 'ar-sa') {
      selected.day.locale('en');
    }

    const data = {
      start_time: `${selected.day.format('YYYY-MM-DD')} ${selected.time}`
    };

    onSchedule(data);
  };

  /**
   * Render day
   * @param day
   * @param i
   * @returns {XML}
   */
  renderDay = (day, i) => {
    const { availableTimes, locale, onSelect } = this.props;
    const { selected } = this.state;
    const number = i + 1;
    const isInactive = !availableTimes.available.find(
      (t) => moment(t.from).format('YYYYDDMM') === day.format('YYYYDDMM')
    );
    let active = false;

    if (selected.index === i) {
      active = true;
    }

    return (
      <div
        id={`day_${i}`}
        onClick={
          !isInactive
            ? () => {
                selected.time = selected.index !== i ? null : selected.time;
                selected.day = day;
                selected.index = i;

                this.setState({ selected });

                if (typeof onSelect === 'function') {
                  onSelect(selected);
                }
              }
            : () => false
        }
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            if (!isInactive) {
              selected.time = selected.index !== i ? null : selected.time;
              selected.day = day;
              selected.index = i;

              this.setState({ selected });

              if (typeof onSelect === 'function') {
                onSelect(selected);
              }
            }
          }
        }}
        className={classNames(
          styles.day,
          (number % 7 === 0 || (number + 1) % 7 === 0) && styles.weekend,
          isInactive && styles.disabled,
          active && styles.active,
          active && selected.time && styles.selected
        )}
      >
        {active && selected.time && (
          <span
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
            id={`close_${i}_button`}
            onClick={() => {
              selected.time = null;
              this.setState({ selected });

              if (typeof onSelect === 'function') {
                onSelect(selected);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                selected.time = null;
                this.setState({ selected });

                if (typeof onSelect === 'function') {
                  onSelect(selected);
                }
              }
            }}
            className={styles['close-button']}
          >
            &#10060;
          </span>
        )}
        <span className={styles.number}>
          <span>
            {day.format('D')}
            {day.format('D') === '1' && (
              <small> {day.lang(locale).format('MMMM')}</small>
            )}
          </span>
        </span>
        {!isInactive && (
          <Select
            id={`select_${i}`}
            key={i}
            options={this.getAvailableTimes(day)}
            active={active && selected.time}
            selected={selected && selected.index === i ? selected : {}}
            onSelect={(option) => {
              selected.time = option;
              this.setState({ selected });
              if (typeof onSelect === 'function') {
                onSelect(selected);
              }
            }}
            day={day}
          />
        )}
      </div>
    );
  };

  /**
   * Schedule
   * @returns {XML}
   */
  render() {
    const { selected } = this.state;
    const {
      scheduleLoading,
      user,
      timesLoading,
      availableTimes,
      locale,
      direction,
      manualSchedule,
      timezone
    } = this.props;
    const days = [];
    const week = [];
    for (let i = 0; i < size; i++) {
      if (availableTimes.available.length > 0) {
        days.push(
          moment(availableTimes.calendar_start)
            .zone(availableTimes.calendar_start)
            .weekday(locale === 'he' ? i + 1 : i)
        );
      }
    }
    for (let j = 0; j < 7; j++) {
      week.push(
        moment()
          .lang(locale)
          .day(locale === 'he' ? j : j + 1)
      );
    }

    if (timesLoading) {
      return (
        <div className={styles['loading-text']}>
          <h3>
            <FormattedMessage id="SCHEDULE_LOADING_TEXT" />
          </h3>
          <FormattedMessage id="LOADING" />
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        {availableTimes.available.length === 0 ? (
          <Alert color="info">
            <FormattedMessage id="NO_AVAILABLE_TIME_INFO" />
          </Alert>
        ) : (
          <div>
            <h2>
              {moment(availableTimes.calendar_start)
                .lang(locale)
                .format('MMMM YYYY')}
              <span
                className={styles['timezone-info']}
                style={direction === 'rtl' ? { float: 'left' } : {}}
              >
                <FormattedMessage
                  id={
                    manualSchedule
                      ? 'PARTICIPANT_TIMEZONE_INFO'
                      : 'TIMEZONE_INFO'
                  }
                  values={{
                    timezone: manualSchedule ? timezone : user.get('timezone')
                  }}
                />{' '}
                {days[0] && moment(days[0]).format('(UTC Z)')}
              </span>
            </h2>
            <div className={styles.calendar}>
              {week.map((day, i) => (
                <div key={i} className={styles['day-heading']}>
                  {day.format('dddd')}
                </div>
              ))}
              {days.map((day, i) => this.renderDay(day, i))}
            </div>

            {!manualSchedule && (
              <Row className={formStyles['form-buttons']}>
                <Col xs={3} className={formStyles['btn-col']}>
                  <Button
                    id="schedule_button"
                    name="schedule_button"
                    type="submit"
                    color="primary-dark"
                    onClick={this.schedule}
                    disabled={!selected.time || !selected.day}
                  >
                    <FormattedMessage
                      id={scheduleLoading ? 'LOADING' : 'SCHEDULE'}
                    />
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}
