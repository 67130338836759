// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'js-debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// Components
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Form,
  Input
} from 'reactstrap';
import { BuildTable, Link } from 'components';

// Styles
import styles from '../../../../components/BuildTable/styles.css';
import '../../../../components/BuildTable/styles.css';

// Actions
import { getSimulationsTable } from './actions';
import {
  fetchSimulations,
  copySimulation,
  fetchDebriefs
} from 'actions/simulations';

// Constants
const allSimulationsTabs = [
  { id: '1', title: 'simulations' },
  { id: '2', title: 'debriefs' }
];

/**
 * Edit simulation table
 * @class
 */
@connect(
  (state) => ({
    simulations: state.simulations.hasIn(['simulations', 'english'])
      ? state.simulations.getIn(['simulations', 'english']).toArray()
      : [],
    filteredSimulations: state.simulations.has('filtered')
      ? state.simulations.get('filtered').toArray()
      : [],
    simulationsTotal: state.simulations.get('simulationsTotal'),
    route: state.router.route,
    loggedUser: state.auth.get('loggedUser'),
    debriefs: state.debriefs.hasIn(['debriefs', 'english'])
      ? state.debriefs.getIn(['debriefs', 'english']).toArray()
      : [],
    filteredDebriefs: state.debriefs.has('filtered')
      ? state.debriefs.get('filtered').toArray()
      : [],
    debriefsTotal: state.debriefs.get('debriefsTotal')
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchSimulations, copySimulation, fetchDebriefs },
      dispatch
    )
)
export default class SimulationsTable extends Component {
  // PropTypes
  static propTypes = {
    simulations: PropTypes.object,
    filteredSimulations: PropTypes.object,
    fetchSimulations: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    copySimulation: PropTypes.object.isRequired,
    simulationsTotal: PropTypes.number,
    loggedUser: PropTypes.object,
    debriefs: PropTypes.object,
    filteredDebriefs: PropTypes.object,
    fetchDebriefs: PropTypes.func.isRequired
  };

  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.tab ? route.query.tab.toString() : '1',
      query: ''
    };
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.tab !== nextProps.route.query.tab) {
      this.toggle(nextProps.route.query.tab);
    }
  }

  /**
   * Get tab content
   * @param tab
   */
  getTabContent = (tab) => {
    const { query } = this.state;
    const { intl } = this.context;
    const {
      simulations,
      copySimulation,
      fetchSimulations,
      simulationsTotal,
      filteredSimulations,
      loggedUser,
      debriefs,
      fetchDebriefs,
      filteredDebriefs,
      debriefsTotal
    } = this.props;

    switch (tab.id) {
      case '1':
        return (
          <BuildTable
            table={getSimulationsTable(
              (query ? filteredSimulations : simulations).filter(
                (simulation) => !simulation.get('is_psychometrics_only')
              ),
              this.context,
              copySimulation,
              tab.title,
              {
                total: simulationsTotal,
                loadMore: fetchSimulations,
                itemsCount: 15,
                query,
                intl,
                loggedUser
              }
            )}
          />
        );
      case '2':
        return (
          <BuildTable
            table={getSimulationsTable(
              (query ? filteredDebriefs : debriefs).filter(
                (debrief) => !debrief.get('is_psychometrics_only')
              ),
              this.context,
              copySimulation,
              tab.title,
              {
                total: debriefsTotal,
                loadMore: fetchDebriefs,
                itemsCount: 15,
                query,
                intl,
                loggedUser
              }
            )}
          />
        );
      default:
        return (
          <BuildTable
            table={getSimulationsTable(
              simulations,
              this.context,
              copySimulation,
              tab.title
            )}
          />
        );
    }
  };

  /**
   * Get action
   */
  getAction = (tab) => {
    const { activeTab } = this.state;
    const { fetchSimulations, fetchDebriefs } = this.props;

    switch (tab || activeTab) {
      case '1':
        return fetchSimulations;
      case '2':
        return fetchDebriefs;
      default:
        return fetchSimulations;
    }
  };

  /**
   * Toggle
   * @param tab
   */
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        query: ''
      });
      this.input.value = '';
    }
  };

  /**
   * Find items
   * @param e
   */
  findItems = (e) => {
    const { value } = e.target;

    this.setState({ query: value });
    debounce('search', 700, () => this.fetchData(value));
  };

  /**
   * Fetch data
   * @param value
   */
  fetchData = (value) => {
    const action = this.getAction();
    action(0, 15, value);
  };

  getSimulationTabs = () => {
    const { loggedUser } = this.props;

    if (loggedUser.get('isPartner')) {
      return allSimulationsTabs.filter((t) => t.title === 'simulations');
    }

    return allSimulationsTabs;
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { activeTab, query } = this.state;
    const { intl } = this.context;
    const simulationsTabs = this.getSimulationTabs();

    return (
      <div>
        <Nav tabs>
          {simulationsTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.title}`}
                to="/simulations"
                query={{ tab: tab.id.toString() }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`SIMULATIONS_TABS.${tab.title}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}

          <Form
            onSubmit={(e) => e.preventDefault()}
            className={[styles['search-input'], styles['simulations-search']]}
          >
            <Input
              id="search"
              name="search"
              type="text"
              placeholder={intl.formatMessage({ id: 'SEARCH' })}
              onChange={this.findItems}
              getRef={(input) => (this.input = input)}
            />
            <button
              id="submit_search"
              name="submit_search"
              type="button"
              className={classNames(
                styles['search-input__button'],
                query && styles.close
              )}
              onClick={
                !query
                  ? () => this.input.focus()
                  : () => {
                      this.findItems({ target: { value: '' } });
                      this.input.value = '';
                    }
              }
            />
          </Form>
        </Nav>

        <TabContent activeTab={activeTab}>
          {simulationsTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">{this.getTabContent(tab)}</Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
