import React from 'react';
import moment from 'moment';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import styles from '../styles.css';

const getNarrative = (props) => {
  const { data, route } = props;
  const current = route.query.current;
  const report = data.find((r) => r.get('id') === Number(current)) || Map();

  return (
    <div className={styles.narrative}>
      {current && report.get('has_narrative') && (
        <Alert className={styles['analytics-infobox']} color="info" isOpen>
          <FormattedMessage
            id="NARRATIVE_INFO"
            values={{
              name: report.getIn(['narrative', 'author_name']),
              company: report.getIn(['narrative', 'author_company']),
              date: report.hasIn(['narrative', 'updated_at'])
                ? moment(report.getIn(['narrative', 'updated_at']))
                    .utcOffset(report.getIn(['narrative', 'updated_at']))
                    .format('DD MMM YYYY hh:mm A')
                : null
            }}
          />
        </Alert>
      )}

      <div
        style={{ marginTop: 50 }}
        dangerouslySetInnerHTML={{
          __html: report.getIn(['narrative', 'narrative'])
        }}
      />
    </div>
  );
};

export default connect((state) => ({
  route: state.router.route,
  updateNarrativeLoading: state.analytics.get('updateNarrativeLoading')
}))(
  React.memo(getNarrative, (prevProps, nextProps) => {
    const prevReport =
      prevProps?.data?.find(
        (r) => r.get('id') === Number(prevProps?.route?.query?.current)
      ) || Map();
    const nextReport =
      nextProps?.data?.find(
        (r) => r.get('id') === Number(nextProps?.route?.query?.current)
      ) || Map();

    return (
      prevProps?.route?.query?.current === nextProps?.route?.query?.current &&
      prevReport.equals(nextReport)
    );
  })
);
