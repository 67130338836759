/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, formValueSelector } from 'redux-form';

// Styles
import { InputGroup, Label, Table } from 'reactstrap';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components

// Actions
import { renderInput } from '../../actions';

// Const
const form = 'shareReportForm';

/**
 * Assess modal form
 * @class
 */
let ShareReportForm = (props) => {
  const {
    handleSubmit,
    shareAssessment,
    users,
    simulations,
    selectedUsers
  } = props;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h6>Simulation</h6>
      {simulations.map((simulation) => (
        <InputGroup>
          <Label htmlFor={simulation.get('name')}>
            <Field
              name={simulation.get('name')}
              component={renderInput}
              type="checkbox"
            />
            {simulation.get('name')}
          </Label>
        </InputGroup>
      ))}

      <InputGroup>
        <Label htmlFor="share_assessment">
          <Field
            name="share_assessment"
            component={renderInput}
            type="checkbox"
          />
          <FormattedMessage id="SHARE_ASSESSMENT_REPORT_INFO" />
        </Label>
      </InputGroup>
      <InputGroup>
        <Label htmlFor="share_assessment">
          <FormattedMessage id="SHARE_ASSESSMENT_REPORT_INFO" />
        </Label>
        <Field
          disabled={!shareAssessment}
          name="email_address"
          component={renderInput}
          type="email"
        />
      </InputGroup>
      <Table>
        <tr>
          <th />
          <th>
            <FormattedMessage id="NAME" />
          </th>
          <th>
            <FormattedMessage id="EMAIL" />
          </th>
        </tr>
        {users.map((user, index) => (
          <tr>
            <td>
              <Field
                name={`users[${index}]`}
                component={renderInput}
                type="checkbox"
                checked={selectedUsers.indexOf(user.get('id')) > -1}
              />
            </td>
            <td>{`${user.get('firstname')} ${user.get('surname')}`}</td>
            <td>{user.get('email')}</td>
          </tr>
        ))}
      </Table>
    </form>
  );
};

// Prop types
ShareReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  shareAssessment: PropTypes.bool,
  users: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  simulations: PropTypes.array.isRequired
};

const selector = formValueSelector(form);
ShareReportForm = connect((state) => ({
  shareAssessment: selector(state, 'share_assessment'),
  selectedUsers: selector(state, 'users') || [],
  users: state.users.has('users') ? state.users.get('users').toArray() : [],
  simulations: state.simulations.hasIn(['simulations', 'english'])
    ? state.simulations.getIn(['simulations', 'english']).toArray()
    : []
}))(ShareReportForm);

// Decorate with redux-form
export default reduxForm({
  form,
  validate
})(ShareReportForm);
