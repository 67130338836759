import React from 'react';

// Styles
import styles from './styles.css';
import './styles.css';

// Const
const style = {
  height: 40,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
  flexGrow: 1
};

const boxStyle = {
  HIGH: {
    color: '#000',
    backgroundColor: '#2FBC88'
  },
  MEDIUM: {
    color: '#000',
    backgroundColor: '#F9CB36'
  },
  LOW: {
    color: '#FFF',
    backgroundColor: '#001C50'
  }
};

const PriorityCard = (props) => {
  const { text, progress } = props;

  return (
    <div className={styles.box} style={{ ...style, ...boxStyle[progress] }}>
      {text}
    </div>
  );
};

export default PriorityCard;
