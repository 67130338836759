import { Map, OrderedMap, fromJS } from 'immutable';
import { fromJSOrdered } from 'helpers';
import {
  ALLOWED_SIMULATIONS_REQUEST,
  ALLOWED_SIMULATIONS_SUCCESS,
  ALLOWED_SIMULATIONS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  MASS_TAGS_UPDATE_SUCCESS,
  MASS_TPA_SUCCESS,
  MASS_DEBRIEF_INVITATION_SUCCESS,
  FETCH_FILTER_DATA_REQUEST,
  FETCH_FILTER_DATA_SUCCESS,
  FETCH_FILTER_DATA_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  INVITE_USERS_REQUEST,
  INVITE_USERS_SUCCESS,
  INVITE_USERS_FAILURE,
  FETCH_TIMESLOTS_REQUEST,
  FETCH_TIMESLOTS_SUCCESS,
  FETCH_TIMESLOTS_FAILURE,
  CREATE_PARTNER_SUCCESS,
  CREATE_CLIENT_SUCCESS,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_STAGE_HISTORY_REQUEST,
  FETCH_STAGE_HISTORY_SUCCESS,
  FETCH_STAGE_HISTORY_FAILURE,
  RESCHEDULE_USER_REQUEST,
  RESCHEDULE_USER_SUCCESS,
  RESCHEDULE_USER_FAILURE,
  SCHEDULE_SUCCESS,
  LOGIN_SUCCESS,
  GET_ME_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_USERS_DATA,
  SET_USERS_DATA_FROM_CACHE
} from 'constants/actionTypes';
import { historyData } from 'constants/historyData';
import updateStateAfterMassAction from './actions/updateStateAfterMassAction';
import { MASS_REPORTS_ACCESS_SUCCESS } from '../../constants/actionTypes';
import { DETAIL_FETCH_ERROR } from '../../constants/errors';

const initialState = Map({
  users: OrderedMap(),
  allUsers: OrderedMap(),
  allowedSimulations: OrderedMap(),
  usersFilteredData: OrderedMap(),
  usersMeta: Map(),
  historyData: Map(historyData.map((item) => [item.id, fromJSOrdered(item)])),
  notifications: Map()
});

export default function users(state = initialState, action) {
  switch (action.type) {
    case ALLOWED_SIMULATIONS_REQUEST:
      return state.set('allowedSimulationsLoading', true);
    case ALLOWED_SIMULATIONS_SUCCESS: {
      const simulations = action.result.body;

      return state
        .set('allowedSimulationsLoading', false)
        .setIn(
          ['allowedSimulations'],
          OrderedMap(
            simulations.map((s) => [
              `${s.simulation_id}${s.assessment_id || 0}`,
              fromJSOrdered(s)
            ])
          )
        );
    }
    case ALLOWED_SIMULATIONS_FAILURE:
      return state.set('allowedSimulationsLoading', false);
    case FETCH_USERS_REQUEST:
      return state.set('usersLoading', true);
    case FETCH_USERS_SUCCESS: {
      const {
        filtered,
        total,
        filteredReports,
        totalReports
      } = action.result.body;
      const users = action.result.body.data;
      const { role } = action;

      return state
        .set('usersLoading', false)
        .setIn(
          ['users'],
          OrderedMap(users.map((user) => [user.id, fromJSOrdered(user)]))
        )
        .setIn(
          ['usersFilteredData', action.string],
          OrderedMap(users.map((user) => [user.id, fromJSOrdered(user)]))
        )
        .mergeDeepIn(
          ['allUsers'],
          OrderedMap(users.map((user) => [user.id, fromJSOrdered(user)]))
        )
        .set('filteredReports', filteredReports)
        .set('totalReports', totalReports)
        .set(`${role || 'users'}Filtered`, filtered)
        .set(
          `${role || 'users'}Total`,
          state.has(`${role || 'users'}Total`)
            ? state.get(`${role || 'users'}Total`)
            : role == null
            ? total
            : filtered
        );
    }
    case FETCH_USERS_FAILURE:
      return state.set('usersLoading', false).set('error', action.error);
    case MASS_TAGS_UPDATE_SUCCESS: {
      const { selected, tags } = action;
      return updateStateAfterMassAction(state, 'tags', selected, tags);
    }
    case MASS_TPA_SUCCESS: {
      const { selected, is_tpa_enabled } = action;
      return updateStateAfterMassAction(
        state,
        'modules',
        selected,
        is_tpa_enabled
      );
    }

    case MASS_DEBRIEF_INVITATION_SUCCESS: {
      const { selected, debriefID } = action;
      return updateStateAfterMassAction(
        state,
        'debrief_invitation',
        selected,
        debriefID
      );
    }

    case MASS_REPORTS_ACCESS_SUCCESS: {
      const { selected, sharing, enable, allStakeholders } = action;
      const selectedActiveSimulations = Object.create(selected);
      const selectedPastSimulations = Object.create(selected);

      selectedActiveSimulations.include = [];
      selectedPastSimulations.include = [];
      selectedActiveSimulations.exclude = [];
      selectedPastSimulations.exclude = [];

      if (selected.filter) {
        selected.exclude.forEach((id) => {
          if (
            state
              .get('allUsers')
              .find((u) => u.getIn(['active_simulation', 'id']) === id)
          ) {
            selectedActiveSimulations.exclude.push(id);
          } else {
            selectedPastSimulations.exclude.push(id);
          }
        });
      } else {
        selected.include.forEach((id) => {
          if (
            state
              .get('allUsers')
              .find((u) => u.getIn(['active_simulation', 'id']) === id)
          ) {
            selectedActiveSimulations.include.push(id);
          } else {
            selectedPastSimulations.include.push(id);
          }
        });
      }

      const updatedState = updateStateAfterMassAction(
        state,
        'active_simulation',
        selectedActiveSimulations,
        {
          sharing,
          enable,
          allStakeholders
        }
      );

      return updateStateAfterMassAction(
        updatedState,
        'past_simulations',
        selectedPastSimulations,
        {
          sharing,
          enable,
          allStakeholders
        }
      );
    }
    case FETCH_FILTER_DATA_REQUEST:
      return state.set('filterDataLoading', true);
    case FETCH_FILTER_DATA_SUCCESS: {
      const filterData = action.result.body;

      return state
        .set('filterDataLoading', false)
        .set('filterData', fromJS(filterData));
    }
    case FETCH_FILTER_DATA_FAILURE:
      return state.set('filterDataLoading', false).set('error', action.error);
    case CREATE_USER_REQUEST:
      return state.set('createUserLoading', true);
    case CREATE_USER_SUCCESS: {
      const user = action.result.body;
      return state
        .set('createUserLoading', false)
        .set('usersFilteredData', OrderedMap())
        .setIn(['users', user.id], fromJSOrdered(user))
        .setIn(['allUsers', user.id], fromJSOrdered(user));
    }
    case CREATE_USER_FAILURE:
      return state.set('createUserLoading', false).set('error', action.error);

    case DELETE_USER_REQUEST:
      return state.set('deleteUserLoading', true);
    case DELETE_USER_SUCCESS: {
      const id = Number(action.id);

      return state
        .set('deleteUserLoading', false)
        .set('usersFilteredData', OrderedMap())
        .deleteIn(['users', id])
        .deleteIn(['allUsers', id]);
    }
    case DELETE_USER_FAILURE:
      return state.set('deleteUserLoading', false).set('error', action.error);

    case GET_USER_REQUEST:
      return state.set('getUserLoading', true);
    case GET_USER_SUCCESS:
    case LOGIN_SUCCESS:
    case GET_ME_SUCCESS: {
      const id = Number(action.result.body.id);
      const user = action.result.body;
      const { allowMerge } = action;

      if (allowMerge) {
        return state
          .set('getUserLoading', false)
          .mergeIn(['users', id], fromJSOrdered(user))
          .mergeIn(['allUsers', id], fromJSOrdered(user));
      }

      return state
        .set('getUserLoading', false)
        .setIn(['users', id], fromJSOrdered(user))
        .setIn(['allUsers', id], fromJSOrdered(user));
    }
    case GET_USER_FAILURE:
      return state.set('getUserLoading', false).set('error', action.error);

    case EDIT_USER_REQUEST:
      return state.set('createUserLoading', true);
    case EDIT_USER_SUCCESS: {
      const id = Number(action.id);
      const user = action.result.body;

      if (!user.stakeholder) {
        user.stakeholder = false;
      }

      if (user.client_id == null) {
        user.client = null;
        user.client_id = null;
      }

      if (user.partner_id == null) {
        user.partner = null;
        user.partner_id = null;
      }

      return state
        .set('createUserLoading', false)
        .set('usersFilteredData', OrderedMap())
        .mergeIn(['users', id], fromJSOrdered(user))
        .mergeIn(['allUsers', id], fromJSOrdered(user));
    }
    case EDIT_USER_FAILURE:
      return state.set('createUserLoading', false).set('error', action.error);
    case INVITE_USERS_REQUEST:
      return state.set('inviteUsersLoading', true);
    case INVITE_USERS_SUCCESS: {
      const newValidUsers = action.result.body.valid;

      return state
        .mergeDeepIn(
          ['users'],
          Map(newValidUsers.map((u) => [u.id, fromJSOrdered(u)]))
        )
        .mergeDeepIn(
          ['allUsers'],
          Map(newValidUsers.map((u) => [u.id, fromJSOrdered(u)]))
        )
        .set('usersFilteredData', OrderedMap())
        .set('inviteUsersLoading', false);
    }
    case INVITE_USERS_FAILURE:
      if (action.error.status === 403) {
        return (window.location = `/users?${DETAIL_FETCH_ERROR}=${action.error.body}`);
      }
      return state.set('inviteUsersLoading', false);
    case FETCH_TIMESLOTS_REQUEST:
      return state.set('fetchTimeslotsLoading', true);
    case FETCH_TIMESLOTS_SUCCESS: {
      const { id } = action;
      const timeslots = action.result.body;
      const { preferred } = timeslots;
      const { unavailable } = timeslots;

      return state
        .set('fetchTimeslotsLoading', false)
        .setIn(
          ['allUsers', id, 'timeslots', 'preferred'],
          Map(
            Object.keys(preferred).map((key) => [
              key,
              fromJSOrdered(preferred[key])
            ])
          )
        )
        .setIn(
          ['allUsers', id, 'timeslots', 'unavailable'],
          fromJSOrdered(unavailable)
        );
    }
    case FETCH_TIMESLOTS_FAILURE:
      return state.set('fetchTimeslotsLoading', false);
    case CREATE_PARTNER_SUCCESS: {
      const user = action.result.body.owner;
      return state
        .set('usersFilteredData', OrderedMap())
        .setIn(['users', user.id], fromJSOrdered(user))
        .setIn(['allUsers', user.id], fromJSOrdered(user));
    }
    case CREATE_CLIENT_SUCCESS: {
      const user = action.result.body.owner;
      return state
        .set('usersFilteredData', OrderedMap())
        .setIn(['users', user.id], fromJSOrdered(user))
        .setIn(['allUsers', user.id], fromJSOrdered(user));
    }

    case FETCH_NOTIFICATIONS_REQUEST:
      return state.set('notificationsLoading', true);
    case FETCH_NOTIFICATIONS_SUCCESS: {
      const notifications = action.result.body.data;

      return state
        .set('notificationsLoading', false)
        .set(
          'notifications',
          Map(notifications.map((notif) => [notif.id, fromJSOrdered(notif)]))
        );
    }
    case FETCH_NOTIFICATIONS_FAILURE:
      return state
        .set('notificationsLoading', false)
        .set('notificationsError', action.error);
    case FETCH_STAGE_HISTORY_REQUEST:
      return state.set('historyStageLoading', true);
    case FETCH_STAGE_HISTORY_SUCCESS: {
      const id = Number(action.id);
      const stageHistory = action.result.body.data;
      const total = Number(action.result.body.filtered);

      return state
        .set('historyStageLoading', false)
        .set('historyStageTotal', total)
        .mergeIn(
          ['users', id, 'stageHistory'],
          Map(stageHistory.map((i) => [i.id, fromJSOrdered(i)]))
        )
        .mergeIn(
          ['allUsers', id, 'stageHistory'],
          Map(stageHistory.map((i) => [i.id, fromJSOrdered(i)]))
        );
    }
    case FETCH_STAGE_HISTORY_FAILURE:
      return state.set('historyStageLoading', false);
    case RESCHEDULE_USER_REQUEST:
      return state.set('rescheduleLoading', true);
    case RESCHEDULE_USER_SUCCESS:
      return state.set('rescheduleLoading', false);
    case RESCHEDULE_USER_FAILURE:
      return state.set('rescheduleLoading', false);

    case SCHEDULE_SUCCESS: {
      const { id } = action;
      if (action.canceling) {
        return state
          .deleteIn(['users', id, 'active_simulation'])
          .deleteIn(['allUsers', id, 'active_simulation']);
      }

      return state;
    }

    case RESET_USERS_DATA:
      return state.set('usersFilteredData', OrderedMap());

    case SET_USERS_DATA_FROM_CACHE:
      return state.setIn(['users'], action.data);

    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
