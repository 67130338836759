import React from 'react';

import { isMobile } from 'helpers';

class AppRedirect extends React.Component {
  componentDidMount() {
    // Desktop browsers
    if (!isMobile()) {
      window.location = '/development';
    }
  }

  redirect = () => {
    window.location = process.env.REACT_APP_TPA_REDIRECT;
  };

  render() {
    if (!isMobile()) {
      return <div />;
    }

    return (
      <div
        style={{
          backgroundColor: '#001c50',
          display: 'flex',
          flexAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          flex: 1,
          minHeight: 'calc(100vh - 150px)'
        }}
      >
        <img
          src="/assets/img/pinsight_logo_white.png"
          alt=""
          width="100"
          height="auto"
        />

        <button
          id="tpa-button"
          onClick={this.redirect}
          style={{ marginTop: 20, color: '#fff' }}
        >
          OPEN APP
        </button>
      </div>
    );
  }
}

export default AppRedirect;
