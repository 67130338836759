import {
  CREATE_SIMULATION_INSTRUCTION_REQUEST,
  CREATE_SIMULATION_INSTRUCTION_SUCCESS,
  CREATE_SIMULATION_INSTRUCTION_FAILURE,
  EDIT_SIMULATION_INSTRUCTION_REQUEST,
  EDIT_SIMULATION_INSTRUCTION_SUCCESS,
  EDIT_SIMULATION_INSTRUCTION_FAILURE,
  DELETE_SIMULATION_INSTRUCTION_REQUEST,
  DELETE_SIMULATION_INSTRUCTION_SUCCESS,
  DELETE_SIMULATION_INSTRUCTION_FAILURE,
  DELETE_DEBRIEF_INSTRUCTION_REQUEST,
  DELETE_DEBRIEF_INSTRUCTION_SUCCESS,
  CREATE_DEBRIEF_INSTRUCTION_REQUEST,
  CREATE_DEBRIEF_INSTRUCTION_SUCCESS,
  CREATE_DEBRIEF_INSTRUCTION_FAILURE,
  EDIT_DEBRIEF_INSTRUCTION_REQUEST,
  EDIT_DEBRIEF_INSTRUCTION_SUCCESS,
  EDIT_DEBRIEF_INSTRUCTION_FAILURE,
  DELETE_DEBRIEF_INSTRUCTION_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Instructions action
 *
 * @param{Object} body
 * @param{String} fileType
 * @returns {{types: *[], promise: Request }}
 */

export default function instructions(body, fileType) {
  let promise;
  const types = [];
  const { id, type, simulationId, data, isDebrief } = body;

  data.type = fileType; // eslint-disable-line
  if (!data.language) {
    data.language = 'english'; // eslint-disable-line
  }
  if (!data.prework) {
    data.prework = false; // eslint-disable-line
  }

  switch (type) {
    case 'create':
      if (isDebrief) {
        types.push(
          CREATE_DEBRIEF_INSTRUCTION_REQUEST,
          CREATE_DEBRIEF_INSTRUCTION_SUCCESS,
          CREATE_DEBRIEF_INSTRUCTION_FAILURE
        );
      } else {
        types.push(
          CREATE_SIMULATION_INSTRUCTION_REQUEST,
          CREATE_SIMULATION_INSTRUCTION_SUCCESS,
          CREATE_SIMULATION_INSTRUCTION_FAILURE
        );
      }
      promise = api().post(
        `/api/v1/simulations/${simulationId}/documents/`,
        data
      );
      break;
    case 'edit':
      if (isDebrief) {
        types.push(
          EDIT_DEBRIEF_INSTRUCTION_REQUEST,
          EDIT_DEBRIEF_INSTRUCTION_SUCCESS,
          EDIT_DEBRIEF_INSTRUCTION_FAILURE
        );
      } else {
        types.push(
          EDIT_SIMULATION_INSTRUCTION_REQUEST,
          EDIT_SIMULATION_INSTRUCTION_SUCCESS,
          EDIT_SIMULATION_INSTRUCTION_FAILURE
        );
      }
      promise = api().put(
        `/api/v1/simulations/${simulationId}/documents/${id}/`,
        data
      );
      break;
    case 'delete':
      if (isDebrief) {
        types.push(
          DELETE_DEBRIEF_INSTRUCTION_REQUEST,
          DELETE_DEBRIEF_INSTRUCTION_SUCCESS,
          DELETE_DEBRIEF_INSTRUCTION_FAILURE
        );
      } else {
        types.push(
          DELETE_SIMULATION_INSTRUCTION_REQUEST,
          DELETE_SIMULATION_INSTRUCTION_SUCCESS,
          DELETE_SIMULATION_INSTRUCTION_FAILURE
        );
      }
      promise = api().del(
        `/api/v1/simulations/${simulationId}/documents/${id}/?language=${data.language}`
      );
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    simulationId,
    id,
    language: data.language,
    isDebrief
  };
}
