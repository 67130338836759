import {
  TWILIO_CONNECT_REQUEST,
  TWILIO_CONNECT_SUCCESS,
  TWILIO_CONNECT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Tokbox connect action
 *
 * @returns {{promise}}
 */

export default function twilioConnect(assessorId, userId = '') {
  let url = `/api/v1/twilio/authenticate/?identity=${assessorId}`;

  if (userId !== '') {
    url = `/api/v1/twilio/authenticate/?identity=${assessorId}&participantId=${userId}`;
  }
  return {
    types: [
      TWILIO_CONNECT_REQUEST,
      TWILIO_CONNECT_SUCCESS,
      TWILIO_CONNECT_FAILURE
    ],
    promise: api().get(url)
  };
}
