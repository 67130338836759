import {
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function createAssessment(data, id) {
  const reduced = {};
  Object.keys(data).map((item) => (reduced[item] = data[item]));

  if (!reduced.language) {
    reduced.language = 'english';
  }

  if (reduced.simulation === ' ') {
    reduced.simulation = null;
  }

  if (reduced.partner_id === 'null') {
    reduced.partner_id = null;
    reduced.client_id = null;
  }

  if (reduced.client_id === 'null') {
    reduced.client_id = null;
  }

  if (id != null) {
    delete reduced.simulation;
    delete reduced.language;

    if (reduced.partner_id == null) {
      reduced.partner_id = null;
    }

    if (reduced.client_id == null) {
      reduced.client_id = null;
    }
  }

  return {
    types: [
      CREATE_ASSESSMENT_REQUEST,
      CREATE_ASSESSMENT_SUCCESS,
      CREATE_ASSESSMENT_FAILURE
    ],
    promise: api().post(
      `/api/v1/assessment/${id != null ? `${id}/copy/` : ''}`,
      reduced
    )
  };
}
