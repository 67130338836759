/* eslint-disable newline-per-chained-call */
// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import moment from 'moment';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Modal } from 'reactstrap';
import {
  App,
  NotSupported,
  Overview,
  TopBar,
  ChooseFocus,
  MyPriorities,
  MoreInfo,
  TechnicalSupport
} from './components';
import NotSupportedBrowserModal from 'views/Auth/Invitation/steps/ITCheck/NotSupported';

// Actions
import {
  getCurrentFocus,
  listWeeklyFocuses,
  listImprovement
} from 'actions/development';
import { checkSupport, isMobile } from 'helpers';
import { isInIframe } from '../../helpers/isInIframeCheck';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Development
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    focuses: state.development.get('focuses'),
    route: state.router.route,
    currentFocus: state.development.get('currentFocus'),
    improvement: state.development.get('improvement'),
    exercises: state.development.get('exercises')
  }),
  (dispatch) => {
    const transitionTo = actions.transitionTo;
    return bindActionCreators(
      { transitionTo, getCurrentFocus, listWeeklyFocuses, listImprovement },
      dispatch
    );
  }
)
export default class Development extends Component {
  // Prop Types
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    children: PropTypes.any,
    user: PropTypes.object.isRequired,
    getCurrentFocus: PropTypes.func.isRequired,
    focuses: PropTypes.object.isRequired,
    listWeeklyFocuses: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    currentFocus: PropTypes.object.isRequired,
    improvement: PropTypes.func.isRequired,
    exercises: PropTypes.func.isRequired,
    listImprovement: PropTypes.func.isRequired
  };

  // Context types
  static childContextTypes = {
    changeHeading: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    this.state = {
      ready: false,
      notSupported: false,
      notSupportedBrowser: false,
      loggedDay: moment(),
      lastImprovementFetch: null
    };

    this.changeHeading = this.changeHeading.bind(this);
  }

  /**
   * Get child context
   * @returns {*{}}
   */
  getChildContext() {
    return {
      changeHeading: this.changeHeading
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { user, focuses, transitionTo, listWeeklyFocuses } = this.props;

    const type =
      user.has('stages') &&
      user.getIn(['stages', 'simulation5', 'completed']) === true
        ? 'lha-not-enabled'
        : 'simulation-on-mobile-not-allowed';

    this.dataRefreshInterval = setInterval(() => {
      this.checkData();
    }, 1000);

    if (focuses.size === 0) {
      listWeeklyFocuses().then((action) => {
        if (action.error && action.error.status === 403) {
          transitionTo('/not-supported', { type });
          this.setState({ notSupported: true });
        } else {
          this.getFocus();
        }
      });
    } else {
      this.getFocus();
    }

    setTimeout(() => {
      if (!isMobile() && !checkSupport()) {
        this.setState({ notSupportedBrowser: true });
      }
    }, 1000);

    this.midnightInterval = setInterval(() => {
      this.checkDay();
    }, 1000);

    document.body.style.paddingBottom = 0;
    document.getElementById('LOADER').style.display = 'none';
  }

  /**
   * Component will mount
   */
  componentWillUnmount() {
    document.body.style.paddingBottom = '6rem';
    clearInterval(this.midnightInterval);
  }

  /**
   * Get focus
   */
  getFocus = () => {
    const { user, transitionTo, getCurrentFocus, route } = this.props;

    this.setState({ ready: true });
    getCurrentFocus().then((action) => {
      if (!action.error) {
        if (
          route &&
          route.pathname &&
          route.pathname.indexOf('app') < 0 &&
          route.pathname.indexOf('overview') < 0
        ) {
          transitionTo('/development/app');
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.has('stages') &&
          user.getIn(['stages', 'development1', 'completed']) === true
        ) {
          if (route.pathname.indexOf('app') > -1) {
            // Force redirect if current focus not found and user is in app
            window.location = '/development/choose-focus';
          } else {
            // Transition if user is not inside app
            transitionTo('/development/choose-focus');
          }
        } else {
          transitionTo('/development/overview');
        }
      }
    });
  };

  /**
   * Check data
   */
  checkData = () => {
    const { currentFocus, listImprovement, exercises } = this.props;
    const { lastImprovementFetch } = this.state;
    const yesterdayExercise = exercises.find(
      (e) =>
        moment(e.get('practiced_at')).format('YYYYDDMM') ===
        moment()
          .utc()
          .utcOffset(currentFocus.get('date_start'))
          .subtract(1, 'day')
          .format('YYYYDDMM')
    );

    if (
      yesterdayExercise &&
      yesterdayExercise.get('is_tracked') &&
      (lastImprovementFetch == null ||
        lastImprovementFetch !== moment().format('YYYYDDMM'))
    ) {
      listImprovement().then((action) => {
        this.setState({ lastImprovementFetch: moment().format('YYYYDDMM') });

        const lastData = moment(action.result.body.last_improvement)
          .utc()
          .utcOffset(currentFocus.get('date_start'))
          .startOf('day');

        if (
          lastData.format('YYYYDDMM') ===
          moment()
            .utc()
            .utcOffset(currentFocus.get('date_start'))
            .format('YYYYDDMM')
        ) {
          this.showScreen();
        }
      });
    } else {
      this.showScreen();
    }
  };

  /**
   * Show screen
   */
  showScreen = () => {
    document.getElementById('LOADER').style.display = 'none';
    clearInterval(this.dataRefreshInterval);
  };

  /**
   * Check day
   */
  checkDay = () => {
    const { currentFocus } = this.props;
    const { loggedDay } = this.state;

    if (currentFocus.has('date_start')) {
      if (
        loggedDay
          .utc()
          .utcOffset(currentFocus.get('date_start'))
          .format('DD') !==
        moment().utc().utcOffset(currentFocus.get('date_start')).format('DD')
      ) {
        location.reload();
      }
    }
  };

  /**
   * Change heading
   * @param heading
   */
  changeHeading(heading) {
    if (this.refs.topBar) {
      this.refs.topBar.getWrappedInstance().changeHeading(heading);
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { children } = this.props;
    const { ready, notSupported, notSupportedBrowser } = this.state;
    const isFirefox = typeof InstallTrigger !== 'undefined';

    return (
      <div
        className={classNames(styles.development, isMobile() && styles.mobile)}
      >
        {(ready || notSupported) &&
          document.getElementById('LOADER').style.display !== 'block' && (
            <div
              className={classNames(
                !isMobile() && styles['mobile-wrapper'],
                !isMobile() && isInIframe && styles.inSimulator
              )}
            >
              <div
                className={classNames(
                  !isMobile() && styles['mobile-wrapper-inner'],
                  isFirefox && styles.firefox
                )}
              >
                {!notSupported && (
                  <div>
                    <Helmet title="Pinsight - Development" />
                    <TopBar ref="topBar" />
                  </div>
                )}
                <div
                  key="content"
                  className={classNames(
                    styles.content,
                    isMobile() && styles.mobile,
                    window.platform != null && styles.nativeApp,
                    window.platform != null && styles[window.platform]
                  )}
                >
                  {children}
                </div>
              </div>
            </div>
          )}
        {notSupportedBrowser && (
          <Modal isOpen toggle={() => false}>
            <div style={{ padding: 50 }}>
              <NotSupportedBrowserModal />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

Development.NotSupported = NotSupported;
Development.Overview = Overview;
Development.ChooseFocus = ChooseFocus;
Development.App = App;
Development.MyPriorities = MyPriorities;
Development.MoreInfo = MoreInfo;
Development.TechnicalSupport = TechnicalSupport;
