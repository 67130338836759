import {
  TOKBOX_CONNECT_REQUEST,
  TOKBOX_CONNECT_SUCCESS,
  TOKBOX_CONNECT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Tokbox connect action
 *
 * @param{Number} participantId
 * @param{Number} assignmentId
 * @returns {{types: *[], promise: Request }}
 */

export default function tokboxConnect(participantId, assignmentId) {
  return {
    types: [
      TOKBOX_CONNECT_REQUEST,
      TOKBOX_CONNECT_SUCCESS,
      TOKBOX_CONNECT_FAILURE
    ],
    promise: api().get(
      `/api/v1/users/${participantId}/video-call-connect/${assignmentId}/`
    ),
    participantId,
    assignmentId
  };
}
