/**
 * Role redirect
 * @param user
 * @param invitation
 * @returns {*}
 */
export default function roleRedirect(user, invitation) {
  const role = user.get('active_role');

  if (role === 'admin') {
    return '/users';
  }

  if (role === 'assessor') {
    return '/assignments';
  }

  if (
    role === 'participant' &&
    user.getIn(['stages', 'invited6', 'completed']) === false
  ) {
    return '/auth/invite/eeo';
  }

  if (role === 'participant' && user.has('active_simulation') && invitation) {
    return '/auth/invite/schedule';
  }

  if (role === 'participant') {
    if (
      (user.has('stages') &&
        user.getIn(['stages', 'report1', 'completed']) === true &&
        user.get('has_shared_report')) ||
      (user.has('stages') &&
        user.getIn(['stages', 'simulation5', 'completed']) === true &&
        user.has('active_simulation') &&
        user.getIn(['active_simulation', 'is_debrief']) &&
        user.get('has_shared_report'))
    ) {
      return '/analytics';
    }

    if (user.has('active_simulation')) {
      return '/dashboard';
    }

    return '/';
  }

  if (role === 'stakeholder') {
    return '/analytics';
  }

  return '/';
}
