// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import { Button } from 'reactstrap';
import { FormattedTranslationModal } from 'views/Simulations/components/EditBehaviorTable/modals';
import TranslateRowForm from './TranslateRowForm';

// styles
import styles from './styles.css';
import './styles.css';

/**
 * Translation row
 * @class
 */
export default class TranslationRow extends Component {
  // Context types
  static contextTypes = {
    addModal: PropTypes.func.isRequired
  };

  static defaultProps = {
    id: 'default'
  };

  // Prop types
  static propTypes = {
    id: PropTypes.string,
    translationKey: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    modalData: PropTypes.object
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  /**
   * Open form
   */
  openForm = () => {
    this.setState({ open: true });
    setTimeout(() => {
      window.addEventListener('click', this.clickHandler, false);
      window.addEventListener('keydown', this.keyDownHandler, false);
    }, 100);
  };

  /**
   * Close form
   */
  closeForm = () => {
    this.setState({ open: false });
    window.removeEventListener('click', this.clickHandler, false);
    window.removeEventListener('keydown', this.keyDownHandler, false);
  };

  /**
   * Click handler
   * @param e
   */
  clickHandler = (e) => {
    if (this.refs.translateRow) {
      if (!this.refs.translateRow.contains(e.target)) {
        this.closeForm();
      }
    }
  };

  /**
   * Key down handler
   * @param e
   */
  keyDownHandler = (e) => {
    switch (e.keyCode) {
      case 27:
        return this.closeForm();
      default:
        return false;
    }
  };

  /**
   * Handle submit
   * @param data
   */
  handleSubmit = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
    this.closeForm();
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { addModal } = this.context;
    const { open } = this.state;
    const {
      id,
      defaultValue,
      disabled,
      onEdit,
      translationKey,
      modalData
    } = this.props;
    let content;

    if (defaultValue && !open) {
      content = (
        <span>
          <span
            className={styles['content-span']}
            dangerouslySetInnerHTML={{ __html: defaultValue }}
          />
          {!disabled && (
            <Button
              color="link"
              className={styles['edit-button']}
              onClick={
                modalData
                  ? () =>
                      addModal(
                        <FormattedTranslationModal
                          modalData={modalData}
                          defaultValue={defaultValue}
                          onSubmit={this.handleSubmit}
                        />
                      )
                  : onEdit || this.openForm
              }
            >
              <FormattedMessage id="EDIT" />
            </Button>
          )}
        </span>
      );
    } else if (!open) {
      content = (
        <Button
          id={`${translationKey || 'ADD_TRANSLATION'}-${id}`}
          type="button"
          color="link"
          onClick={
            modalData
              ? () =>
                  addModal(
                    <FormattedTranslationModal
                      modalData={modalData}
                      defaultValue={defaultValue}
                      onSubmit={this.handleSubmit}
                    />
                  )
              : onEdit || this.openForm
          }
          disabled={disabled}
        >
          + <FormattedMessage id={translationKey || 'ADD_TRANSLATION'} />
        </Button>
      );
    } else {
      content = (
        <span ref="translateRow">
          <TranslateRowForm
            id={id}
            onSubmit={this.handleSubmit}
            onClose={this.closeForm}
            defaultValue={defaultValue}
          />
        </span>
      );
    }

    return content;
  }
}
