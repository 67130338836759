import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../../styles.css';

/**
 * Share user box
 * @param props
 * @constructor
 */
const ShareUserBox = (props) => {
  const { user } = props;
  const profilePhoto = user?.get('profile_photo')
    ? `${process.env.REACT_APP_ASSETSURL}${user.get('profile_photo')}`
    : '/assets/img/profile-photo-placeholder.gif';

  const fullName =
    user.has('firstname') &&
    user.get('firstname') !== '' &&
    user.get('firstname') !== ' '
      ? `${user.get('firstname')} ${user.get('surname')}`
      : user.get('email');

  return (
    <div className={styles['share-user-box']}>
      <div className={styles.left}>
        <div
          className={styles['profile-photo']}
          style={{
            backgroundImage: `url(${profilePhoto})`
          }}
        />
      </div>
      <div className={styles.right}>
        <p title={fullName}>{fullName}</p>
      </div>
    </div>
  );
};

// Prop types
ShareUserBox.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired
};

// Connect
export default connect((state) => ({
  users: state.users.get('allUsers')
}))(ShareUserBox);
