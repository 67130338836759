import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Form, FormGroup, Row, Col, Label } from 'reactstrap';
import { ToolTip } from 'components';
import { renderInput } from '../../actions';
import { validate } from './validate';

import styles from '../../styles.css';
import '../../styles.css';

export const form = 'editAssessmentAutomation';

const Automation = (props, context) => {
  const { assessment, handleSubmit, disabled, change } = props;
  const isPsychometricsOnly = assessment.getIn([
    'simulation',
    'is_psychometrics_only'
  ]);

  useEffect(() => {
    change(
      form,
      'report_sharing',
      assessment.getIn(['automation', 'report', 'enabled']) || false
    );
    change(
      form,
      'enabling_tpa',
      assessment.getIn(['automation', 'tpa', 'enabled']) || false
    );
    change(
      form,
      'report_sharing_delay',
      assessment.getIn(['automation', 'report', 'delay']) || 0
    );
    change(
      form,
      'enabling_tpa_delay',
      assessment.getIn(['automation', 'tpa', 'delay']) || 0
    );
  }, []);

  const rows = [
    { key: 'report_sharing', message: 'AUTOMATE_REPORT_SHARING' },
    { key: 'enabling_tpa', message: 'AUTOMATE_ENABLING_TPA' }
  ];

  if (isPsychometricsOnly) {
    rows.pop();
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={{ size: 8, offset: 4 }}>
            <Label style={{ fontWeight: 'initial', fontSize: 14 }}>
              {context.intl.formatMessage({ id: 'DELAY_BY_DAYS' })}
            </Label>
          </Col>
        </Row>
        {rows.map((row) => (
          <Row>
            <FormGroup>
              <Col xs={4}>
                <Label
                  htmlFor={row.key}
                  style={{ marginTop: 10, fontWeight: 'initial', fontSize: 14 }}
                >
                  <Field
                    name={row.key}
                    component={renderInput}
                    type="checkbox"
                    disabled={disabled}
                    style={{ marginBottom: 5 }}
                  />
                  {context.intl.formatMessage({ id: row.message })}
                </Label>
              </Col>
              <Col xs={8}>
                <Field
                  name={`${row.key}_delay`}
                  component={renderInput}
                  type="number"
                  min={0}
                  max={99}
                  style={{ display: 'inline-block', width: 70, marginLeft: 17 }}
                  disabled={disabled || !props[row.key]}
                  errorsStyle={{
                    width: 600,
                    position: 'absolute',
                    left: 0,
                    top: 38
                  }}
                />
                <ToolTip
                  index={row.key}
                  hint={<FormattedMessage id={`${row.message}_HINT`} />}
                  style={{ marginLeft: 10, position: 'relative', bottom: -2 }}
                />
              </Col>
            </FormGroup>
          </Row>
        ))}
      </Form>
    </div>
  );
};

Automation.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default reduxForm({
  form,
  onChange: (values, dispatch, props) => {
    props.submit();
  },
  validate
})(
  connect(
    (state) => ({
      report_sharing: formValueSelector(form)(state, 'report_sharing'),
      enabling_tpa: formValueSelector(form)(state, 'enabling_tpa')
    }),
    (dispatch) => bindActionCreators({ change }, dispatch)
  )(Automation)
);
