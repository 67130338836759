// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TWILIO_CONNECT_SUCCESS } from '../../../../../../constants/actionTypes';
import { Device } from '@twilio/voice-sdk';

// Styles
import '../../../../../modals.css';
import modals from '../../../../../modals.css';
import api from '../../../../../../api';
// eslint-disable-next-line no-unused-vars
let globalDevice = {};

// Actions
export default class PhoneCall extends Component {
  // Prop types
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    users: PropTypes.object,
    submit: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    extendSimulationTime: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    helpers: PropTypes.object,
    assessorId: PropTypes.number,
    assignmentId: PropTypes.number
  };

  // Context types
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      callInProgress: false,
      canCall: null
    };
  }

  UNSAFE_componentWillMount() {
    // check if calls are allowed
    if (!this.props.assessorId) {
      this.setState({
        canCall: false
      });
    } else {
      api()
        .get(`/api/v1/twilio/can-make-phone-call/${this.props.assessorId}/`)
        .then((response) => {
          this.setState({
            canCall: response.body
          });
        });
    }
  }

  registerEndCall(callId) {
    this.setState({
      callInProgress: false
    });
    api().post(`/api/v1/twilio/register-end-call/`, { callId });
  }

  handleCall = (phoneNumber) => {
    const helpers = this.props.helpers;
    const self = this;
    helpers
      .twilioStartPhoneCall(this.props.assessorId, this.props.userId)
      .then((response) => {
        if (response.type === TWILIO_CONNECT_SUCCESS) {
          // get the phone number to connect the call to
          const params = {
            To: phoneNumber,
            UserId: this.props.userId,
            assignmentId: this.props.assignmentId
          };

          // Setup Twilio.Device
          const device = new Device(response.result.body, {
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
            codecPreferences: ['opus', 'pcmu'],
            // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
            // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
            // a second time and sending the tone twice. This will be default in 2.0.
            fakeLocalDTMF: true,
            // Use `enableRingingState` to enable the device to emit the `ringing`
            // state. The TwiML backend also needs to have the attribute
            // `answerOnBridge` also set to true in the `Dial` verb. This option
            // changes the behavior of the SDK to consider a call `ringing` starting
            // from the connection to the TwiML backend to when the recipient of
            // the `Dial` verb answers.
            enableRingingState: true,
            debug: true,
            logLevel: 'trace'
          });
          globalDevice = device;

          device.on('connect', function () {
            self.setState({
              callInProgress: true
            });
          });

          if (device) {
            device.connect({ params }).then((call) => {
              let callId = 0;

              call.on('ringing', function () {
                const newParams = {
                  ...params,
                  callSid: call.parameters.CallSid
                };

                helpers
                  .twilioRegisterPhoneCall(newParams)
                  .then((registeredCall) => {
                    callId = registeredCall.result.body.id;
                  });

                self.setState({
                  callInProgress: true
                });
              });
              call.on('cancel', function () {
                self.registerEndCall(callId);
              });
              call.on('disconnect', function () {
                self.registerEndCall(callId);
              });

              device.on('disconnect', function () {
                self.registerEndCall(callId);
              });

              device.on('error', function (error) {
                // eslint-disable-next-line no-console
                console.info(`Twilio.Device Error: ${error.message}`);
                self.registerEndCall(callId);
              });
            });
          }
        } else {
          helpers.context.addAlert({
            color: 'danger',
            content: <FormattedMessage id="ERROR_OCCURRED" />
          });
        }
      })
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  };

  showPhoneNumber(phone) {
    if (phone && !this.state.callInProgress) {
      return true;
    }
    return false;
  }

  hangUp() {
    globalDevice.disconnectAll();
    this.setState({
      callInProgress: false
    });
  }

  /**
   * render
   * @returns {XML}
   */
  render() {
    const { onClose } = this.props;
    const user = this.props.user;
    const phone1 = user.get('tel_mobile');
    const phone2 = user.get('tel_secondary');

    if (this.state.canCall === null) {
      return (
        <Modal isOpen toggle={() => false}>
          <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
            <FormattedMessage id="PHONE_CALL" />
          </ModalHeader>
          <ModalBody>
            <div className={modals['loader-wrapper']}>
              <div className={modals['lds-ring']}>
                <div />
                <div />
                <div />
              </div>
            </div>
          </ModalBody>
        </Modal>
      );
    }

    if (this.state.canCall === false) {
      return (
        <Modal isOpen toggle={() => false}>
          <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
            <FormattedMessage id="PHONE_CALL" />
          </ModalHeader>
          <ModalBody>
            <div>
              <p style={{ 'text-align': 'center' }}>
                <FormattedMessage id="FEATURE_NOT_ALLOWED" />
              </p>
            </div>
          </ModalBody>
        </Modal>
      );
    }

    return (
      <Modal isOpen toggle={() => false}>
        <ModalHeader toggle={onClose} style={{ textAlign: 'center' }}>
          <FormattedMessage id="PHONE_CALL" />
        </ModalHeader>
        <ModalBody>
          <div>
            <div style={{ 'text-align': 'center' }}>
              {this.state.callInProgress && (
                <>
                  <div className={modals['loader-wrapper']}>
                    <span>
                      <FormattedMessage id="DIALING" />
                    </span>
                    <div className={modals['lds-facebook']}>
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      this.hangUp();
                    }}
                    style={{ margin: '10px' }}
                  >
                    <FormattedMessage id="END_CALL" />
                  </Button>
                </>
              )}
              {this.showPhoneNumber(phone1) && (
                <Button
                  onClick={() => {
                    this.handleCall(phone1);
                  }}
                  style={{ margin: '10px' }}
                >
                  <span>{phone1}</span>
                  <br />
                  <span style={{ 'font-size': '10px' }}>
                    <FormattedMessage id="FORM_FIELDS.tel_mobile" />
                  </span>
                </Button>
              )}
              {this.showPhoneNumber(phone2) && (
                <Button
                  onClick={() => {
                    this.handleCall(phone2);
                  }}
                  style={{ margin: '10px' }}
                >
                  <span>{phone2}</span>
                  <br />
                  <span style={{ 'font-size': '10px' }}>
                    <FormattedMessage id="FORM_FIELDS.tel_secondary" />
                  </span>
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
