import {
  FETCH_EMAIL_ATTACHMENT_REQUEST,
  FETCH_EMAIL_ATTACHMENT_SUCCESS,
  FETCH_EMAIL_ATTACHMENT_FAILURE,
  CREATE_EMAIL_ATTACHMENT_REQUEST,
  CREATE_EMAIL_ATTACHMENT_SUCCESS,
  CREATE_EMAIL_ATTACHMENT_FAILURE,
  EDIT_EMAIL_ATTACHMENT_REQUEST,
  EDIT_EMAIL_ATTACHMENT_SUCCESS,
  EDIT_EMAIL_ATTACHMENT_FAILURE,
  DELETE_EMAIL_ATTACHMENT_REQUEST,
  DELETE_EMAIL_ATTACHMENT_SUCCESS,
  DELETE_EMAIL_ATTACHMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Email attachment action
 *
 * @param{Object} body
 * @returns {{types: *[], promise: Request }}
 */

export default function emailAttachment(body) {
  let types;
  let promise;
  const { type } = body;
  const { simulationId } = body;
  const { attachmentId } = body;
  const { data } = body;
  const { id } = body;

  if (!data.language) {
    data.language = 'english'; // eslint-disable-line
  }

  switch (type) {
    case 'fetch':
      types = [
        FETCH_EMAIL_ATTACHMENT_REQUEST,
        FETCH_EMAIL_ATTACHMENT_SUCCESS,
        FETCH_EMAIL_ATTACHMENT_FAILURE
      ];
      promise = api().get(
        `/api/v1/simulations/${simulationId}/emails/${id}/attachments/?language=${
          data.language || 'english'
        }`
      );
      break;
    case 'create':
      types = [
        CREATE_EMAIL_ATTACHMENT_REQUEST,
        CREATE_EMAIL_ATTACHMENT_SUCCESS,
        CREATE_EMAIL_ATTACHMENT_FAILURE
      ];
      promise = api().post(
        `/api/v1/simulations/${simulationId}/emails/${id}/attachments/`,
        data
      );
      break;
    case 'edit':
      types = [
        EDIT_EMAIL_ATTACHMENT_REQUEST,
        EDIT_EMAIL_ATTACHMENT_SUCCESS,
        EDIT_EMAIL_ATTACHMENT_FAILURE
      ];
      promise = api().put(
        `/api/v1/simulations/${simulationId}/emails/${id}/attachments/${attachmentId}/`,
        data
      );
      break;
    case 'delete':
      types = [
        DELETE_EMAIL_ATTACHMENT_REQUEST,
        DELETE_EMAIL_ATTACHMENT_SUCCESS,
        DELETE_EMAIL_ATTACHMENT_FAILURE
      ];
      promise = api().del(
        `/api/v1/simulations/${simulationId}/emails/${id}/attachments/${attachmentId}/?language=${data.language}`
      );
      break;
    default:
      break;
  }

  return {
    types,
    promise,
    simulationId,
    attachmentId,
    id,
    language: data.language
  };
}
