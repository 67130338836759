import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getProgressValues } from 'helpers';
import styles from '../styles.css';

const getOverallPerformance = (props) => {
  const { data, route } = props;
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();
  const firstName = report?.get('user')?.get('firstname');
  const overallPerformancePercentile = report
    .get('simulation')
    .get('default')
    .get('operf');
  const overallPerformance = Math.round(overallPerformancePercentile * 100);
  const { color, position } = getProgressValues(overallPerformance);

  return (
    <div style={{ marginBottom: '45px' }}>
      <div className={styles['ticked-bar']}>
        <div
          className={styles['ticked-bar']}
          style={{
            width: `${overallPerformance}%`,
            backgroundColor: color
          }}
        />
        <div className={styles.ticks}>
          <div className={styles.tick}>
            <div
              className={styles['tick-text']}
              style={{
                display: position === 'LOW' ? 'block' : 'none'
              }}
            >
              <FormattedMessage id={position} />
            </div>
          </div>
          <div className={styles.tick}>
            <div
              className={styles['tick-text']}
              style={{
                display: position === 'MEDIUM' ? 'block' : 'none'
              }}
            >
              <FormattedMessage id={position} />
            </div>
          </div>
          <div className={styles.tick}>
            <div
              className={styles['tick-text']}
              style={{
                display: position === 'HIGH' ? 'block' : 'none'
              }}
            >
              <FormattedMessage id={position} />
            </div>
          </div>
        </div>
      </div>
      <p style={{ marginTop: '40px', fontSize: '0.8rem' }}>
        <FormattedMessage
          id={`OVERALL_PERFORMANCE.${position}`}
          values={{ firstName }}
        />
      </p>
    </div>
  );
};

export default connect((state) => ({
  route: state.router.route
}))(
  React.memo(
    getOverallPerformance,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
