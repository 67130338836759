import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { ToolTip } from '../../../../../../components';

import {
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Loader, Link } from 'components';
import {
  BasicInformationTranslateTable,
  InstructionsTranslateTable,
  EventsTranslateTable,
  ContactsTranslateTable,
  EmailsTranslateTable,
  ExercisesTranslateTable
} from './components';
import { SubmitPublishModal } from '../../modals';

import styles from '../../../../../../components/BuildTable/styles.css';
import '../../../../../../components/BuildTable/styles.css';

import {
  fetchSimulation,
  editSimulation,
  fetchSimulationDocuments,
  fetchSimulationEvents,
  fetchSimulationContacts,
  fetchSimulationEmails,
  fetchSimulationExercises
} from 'actions/simulations';
import { DEBRIEF, SIMULATION } from '../../../../../../constants/stringTypes';

const editSimulationTabs = [
  { id: '1', title: 'basic_information' },
  { id: '2', title: 'instructions' },
  { id: '3', title: 'files' },
  { id: '4', title: 'video_calls' },
  { id: '5', title: 'contacts' },
  { id: '6', title: 'inbox' },
  { id: '7', title: 'exercises' }
];

@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    id: Number(state.router.route.vars.id),
    route: state.router.route,
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    debrief: state.debriefs.getIn([
      'debriefs',
      'english',
      Number(state.router.route.vars.id)
    ]),
    isLoading:
      state.simulations.get('simulationsLoading') ||
      state.simulations.get('simulationDocumentsLoading') ||
      state.simulations.get('simulationEventsLoading') ||
      state.simulations.get('simulationContactsLoading') ||
      state.simulations.get('simulationEmailsLoading') ||
      state.simulations.get('simulationExercisesLoading') ||
      state.debriefs.get('debriefsLoading') ||
      state.behaviors.get('behaviorsLoading'),
    simulations: state.simulations.get('simulations'),
    debriefs: state.debriefs.get('debriefs')
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      {
        fetchSimulation,
        editSimulation,
        fetchSimulationDocuments,
        fetchSimulationEvents,
        fetchSimulationContacts,
        fetchSimulationEmails,
        fetchSimulationExercises,
        transitionTo
      },
      dispatch
    );
  }
)
class Translate extends Component {
  static propTypes = {
    id: PropTypes.number,
    transitionTo: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    fetchSimulation: PropTypes.func.isRequired,
    editSimulation: PropTypes.func.isRequired,
    fetchSimulationDocuments: PropTypes.func.isRequired,
    fetchSimulationEvents: PropTypes.func.isRequired,
    fetchSimulationExercises: PropTypes.func.isRequired,
    fetchSimulationEmails: PropTypes.func.isRequired,
    fetchSimulationContacts: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    simulations: PropTypes.object,
    simulation: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    debrief: PropTypes.object,
    debriefs: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      activeTab: route.query.subTab || '1',
      hasError: false,
      ready: false
    };
  }

  componentDidMount() {
    const {
      id,
      version,
      simulation,
      simulations,
      fetchSimulation,
      fetchSimulationDocuments,
      fetchSimulationEvents,
      fetchSimulationContacts,
      fetchSimulationEmails,
      fetchSimulationExercises,
      debrief
    } = this.props;
    const promises = [];
    const session = simulation || debrief;

    if (!session.has('documents')) {
      if (debrief) {
        promises.push(
          fetchSimulationDocuments(id, null, null, null, null, true)
        );
      } else {
        promises.push(fetchSimulationDocuments(id));
      }
    }
    if (simulation) {
      if (!simulation.has('events')) {
        promises.push(fetchSimulationEvents(id));
      }
      if (!simulation.has('exercises')) {
        promises.push(fetchSimulationExercises(id));
      }
      if (!simulation.has('contacts')) {
        promises.push(fetchSimulationContacts(id));
      }
      if (!simulation.has('emails')) {
        promises.push(fetchSimulationEmails(id));
      }
    }

    if (!session.hasIn([version, id])) {
      if (debrief) {
        promises.push(fetchSimulation(id, true, version));
      } else {
        promises.push(fetchSimulation(id, null, version));
      }
    }

    if (!session.has([version, id, 'documents'])) {
      if (debrief) {
        promises.push(
          fetchSimulationDocuments(id, null, null, null, version, true)
        );
      } else {
        promises.push(fetchSimulationDocuments(id, null, null, null, version));
      }
    }
    if (simulation) {
      if (!simulations.hasIn([version, id, 'events'])) {
        promises.push(fetchSimulationEvents(id, null, null, null, version));
      }
      if (!simulations.hasIn([version, id, 'exercises'])) {
        promises.push(fetchSimulationExercises(id, null, null, null, version));
      }
      if (!simulations.hasIn([version, id, 'contacts'])) {
        promises.push(fetchSimulationContacts(id, null, null, null, version));
      }
      if (!simulations.hasIn([version, id, 'emails'])) {
        promises.push(fetchSimulationEmails(id, null, null, null, version));
      }
    }

    Promise.all(promises).then(() => this.setState({ ready: true }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.route.query.subTab !== nextProps.route.query.subTab) {
      this.toggle(nextProps.route.query.subTab || '1');
    }
  }

  getTabContent = (tab) => {
    const { version, simulation, loggedUser, debrief } = this.props;
    const { hasError } = this.state;
    let sessionVersion = null;

    if (simulation) {
      sessionVersion =
        simulation.has('languages') &&
        simulation.get('languages').find((l) => l.get('language') === version);
    } else {
      sessionVersion =
        debrief.has('languages') &&
        debrief.get('languages').find((l) => l.get('language') === version);
    }

    const published = sessionVersion && sessionVersion.get('published');
    const locked =
      sessionVersion &&
      sessionVersion.get('locked') &&
      sessionVersion.getIn(['locked_by', 'id']) !== loggedUser.get('id');

    switch (tab) {
      case '1':
        return (
          <BasicInformationTranslateTable
            disabled={published || locked}
            hasError={hasError}
            version={version}
          />
        );
      case '2':
        return (
          <InstructionsTranslateTable
            disabled={published || locked}
            hasError={hasError}
            type="instruction"
            version={version}
          />
        );
      case '3':
        return (
          <InstructionsTranslateTable
            disabled={published}
            hasError={hasError}
            type="document"
            version={version}
          />
        );
      case '4':
        return (
          <EventsTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '5':
        return (
          <ContactsTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '6':
        return (
          <EmailsTranslateTable
            disabled={published}
            hasError={hasError}
            version={version}
          />
        );
      case '7':
        return (
          <ExercisesTranslateTable
            disabled={published || locked}
            hasError={hasError}
            version={version}
          />
        );
      default:
        return (
          <BasicInformationTranslateTable
            disabled={published || locked}
            hasError={hasError}
            version={version}
          />
        );
    }
  };

  getErrorTab = (string) => {
    this.setState({ hasError: true });

    if (string.indexOf('instruction') > -1) {
      return '2';
    }
    if (string.indexOf('document') > -1) {
      return '3';
    }
    if (string.indexOf('event') > -1) {
      return '4';
    }
    if (string.indexOf('contact') > -1) {
      return '5';
    }
    if (string.indexOf('email') > -1) {
      return '6';
    }
    if (string.indexOf('exercise') > -1) {
      return '7';
    }

    return '1';
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  submitPublish = () => {
    const {
      editSimulation,
      id,
      version,
      simulations,
      debriefs,
      debrief
    } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    let sessionVersion;

    if (debrief) {
      sessionVersion = debriefs.hasIn(['english', id, 'languages'])
        ? debriefs
            .getIn(['english', id, 'languages'])
            .find((lang) => lang.get('language') === version)
        : false;
    } else {
      sessionVersion = simulations.hasIn(['english', id, 'languages'])
        ? simulations
            .getIn(['english', id, 'languages'])
            .find((lang) => lang.get('language') === version)
        : false;
    }

    const published = sessionVersion && sessionVersion.get('published');

    const errorFields = ['name', 'translation'];

    editSimulation(id, {
      language: version,
      published: !published,
      isDebrief: Boolean(debrief)
    }).then((action) => {
      if (action.error && action.error.body) {
        errorFields.map((field) => {
          if (action.error.body[field] && action.error.body[field].errors) {
            const string = action.error.body[field].errors
              .map((err) => intl.formatMessage({ id: err }))
              .join(', ')
              .trim();
            this.setState({ activeTab: this.getErrorTab(string) });

            return addAlert({
              color: 'danger',
              content: string
            });
          }

          return false;
        });
      } else if (action.error) {
        addAlert({
          color: 'danger',
          content: JSON.stringify(action.error.body)
        });
      } else {
        addAlert({
          color: 'success',
          content: (
            <FormattedMessage
              id={`SUCCESSFULLY_${!published ? 'PUBLISHED' : 'UNPUBLISHED'}`}
              values={{
                item: intl.formatMessage({ id: debrief ? DEBRIEF : SIMULATION })
              }}
            />
          )
        });
      }
      removeModal();
    });
  };

  render() {
    const {
      isLoading,
      id,
      version,
      simulations,
      route,
      loggedUser,
      debrief,
      debriefs
    } = this.props;
    const { addModal } = this.context;
    const { activeTab, ready } = this.state;

    let sessionVersion;

    if (debrief) {
      sessionVersion = debriefs.hasIn(['english', id, 'languages'])
        ? debriefs
            .getIn(['english', id, 'languages'])
            .find((lang) => lang.get('language') === version)
        : false;
    } else {
      sessionVersion = simulations.hasIn(['english', id, 'languages'])
        ? simulations
            .getIn(['english', id, 'languages'])
            .find((lang) => lang.get('language') === version)
        : false;
    }

    const published = sessionVersion && sessionVersion.get('published');

    return (
      <div className={styles['simulation-subtabs-div']}>
        <Nav tabs>
          {(debrief
            ? editSimulationTabs.filter(
                (tab) =>
                  tab.title === 'basic_information' ||
                  tab.title === 'instructions' ||
                  tab.title === 'files'
              )
            : editSimulationTabs
          ).map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab_${tab.title}_${version}`}
                to={`/simulations/edit/${id}`}
                query={{
                  tab: route.query.tab || '1',
                  subTab: tab.id.toString()
                }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`EDIT_SIMULATION_TABS.${tab.title}`} />
                  {tab.title === 'video_calls' && (
                    <ToolTip
                      id="VIDEO_CALLS_JIT"
                      index="VIDEO_CALLS_JIT"
                      hint={this.props.intl.formatMessage({
                        id: 'VIDEO_CALL_SCHEDULE_NOT_VISIBILE_TO_PARTICIPANTS'
                      })}
                    />
                  )}
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {sessionVersion.get('locked') && (
            <span key="locked-message" className={styles['locked-message']}>
              <FormattedMessage
                id={'SIMULATION_IS_BEING_EDITED_BY'}
                values={{
                  name:
                    sessionVersion.getIn(['locked_by', 'id']) ===
                    loggedUser.get('id')
                      ? 'you'
                      : sessionVersion.getIn(['locked_by', 'name'])
                }}
              />
            </span>
          )}
          {published && (
            <span className={styles['published-message']}>
              <FormattedMessage
                id={
                  sessionVersion.has('published_by')
                    ? 'PUBLISHED_BY'
                    : 'PUBLISHED_ON'
                }
                values={{
                  admin: sessionVersion.getIn(['published_by', 'name']),
                  date: moment(sessionVersion.get('published_at')).format(
                    'DD MMM YYYY hh:mm A'
                  )
                }}
              />
            </span>
          )}
          <div
            key="switcher"
            className={classNames(
              styles['custom-switcher'],
              sessionVersion.get('published') &&
                !sessionVersion.get('is_unpublishable') &&
                styles.disabled,
              sessionVersion.get('published') && styles.bold
            )}
          >
            <FormattedMessage
              id={sessionVersion.get('published') ? 'PUBLISHED' : 'UNPUBLISHED'}
            />
            <Label
              id={`input_label_publish_${version}`}
              className={styles['custom-switch']}
            >
              <input
                id={`input_publish_${version}`}
                name={`input_publish_${version}`}
                type="checkbox"
                disabled={
                  sessionVersion.get('published') &&
                  !sessionVersion.get('is_unpublishable')
                }
                onChange={() =>
                  addModal(
                    <SubmitPublishModal
                      action={this.submitPublish}
                      publish={published}
                      lockedBy={
                        sessionVersion.get('locked') &&
                        sessionVersion.getIn(['locked_by', 'id']) !==
                          loggedUser.get('id')
                          ? sessionVersion.getIn(['locked_by', 'name'])
                          : null
                      }
                    />
                  )
                }
                checked={published}
              />
              <div className={styles['switch-slider']} />
            </Label>
          </div>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className={styles['simulation-subtabs']}
        >
          {(debrief
            ? editSimulationTabs.filter(
                (tab) =>
                  tab.title === 'basic_information' ||
                  tab.title === 'instructions' ||
                  tab.title === 'files'
              )
            : editSimulationTabs
          ).map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {activeTab === tab.id &&
                    (isLoading || !ready ? (
                      <Loader />
                    ) : (
                      this.getTabContent(activeTab)
                    ))}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}

export default injectIntl(Translate);
