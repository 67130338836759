// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components';
import { FormattedMessage } from 'react-intl';

// Components
import { Button } from 'reactstrap';
import { SimulationsTable } from '../../components';

// Styles
import styles from '../styles.css';
import '../styles.css';

/**
 * Preview
 * @constructor
 */
const Preview = (props) => (
  <div>
    <div className={`clearfix ${styles['sims-preview-head']}`}>
      <h1 className="float-xs-left">
        <FormattedMessage id="SIMULATIONS" />
      </h1>

      <div className={`float-xs-right ${styles['create-buttons']}`}>
        <Link
          id="create_simulation_link"
          to="/simulations/create"
          query={{ tab: props.route.query.tab || '1' }}
        >
          <Button
            id="create_simulation_button"
            name="create_simulation_button"
            color="primary"
          >
            <FormattedMessage id="SIMULATION_ITEMS.create_simulation" />
          </Button>
        </Link>
      </div>
    </div>

    <SimulationsTable />
  </div>
);

// Prop Types
Preview.propTypes = {
  route: PropTypes.object.isRequired,
  loggedUser: PropTypes.object
};

export default connect((state) => ({
  route: state.router.route,
  loggedUser: state.auth.get('loggedUser')
}))(Preview);
