import {
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Resend invitation action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */

export default function resendInvitation(id) {
  return {
    types: [
      RESEND_INVITATION_REQUEST,
      RESEND_INVITATION_SUCCESS,
      RESEND_INVITATION_FAILURE
    ],
    promise: api().get(`/api/v1/users/${id}/invite/`)
  };
}
