import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { Alert, Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getProgressValues } from 'helpers';
import { IndicatorGraph } from '../../../components';

import styles from '../styles.css';

const getBehaviors = (props, context) => {
  const { data, route } = props;
  const current = Number(route.query.current);
  const report = data.find((r) => r.get('id') === Number(current));
  const hasPsychometrics = report?.get('has_psychometrics');

  const behaviors = report
    ?.getIn(['simulation', 'default', 'behaviors'])
    ?.sort((a, b) => {
      if (a.get('tbis_perc') > b.get('tbis_perc')) {
        return -1;
      }
      if (a.get('tbis_perc') < b.get('tbis_perc')) {
        return 1;
      }

      return 0;
    })
    ?.toArray();
  const [count, setCount] = useState({
    prev: 0,
    next: 1
  });
  const [hasMore, setHasMore] = useState(true);
  const [currentList, setCurrentList] = useState(
    behaviors?.slice(count.prev, count.next)
  );

  useEffect(() => {
    setCount({
      prev: 0,
      next: 1
    });

    setHasMore(true);

    setCurrentList(behaviors?.slice(0, 1));
  }, [current]);

  const getConfig = (indicator) => {
    const data = [];
    const categories = [];
    const labels = [
      context.intl.formatMessage({ id: 'WEAK' }),
      '',
      '',
      context.intl.formatMessage({ id: 'STRONG' })
    ];

    indicator
      .get('exercises')
      .toArray()
      .map((e) => {
        categories.push(e.get('name'));
        return data.push({
          name: e.get('name'),
          y: e.get('score'),
          labels: {
            name: e.get('name')
          }
        });
      });

    return {
      chart: {
        plotBackgroundImage: 'assets/img/behavior.png?v=2',
        width: 400,
        height: 240,
        animation: false
      },
      title: {
        text: ''
      },
      yAxis: {
        gridLineColor: '#9a9a9a',
        title: {
          text: ''
        },
        allowDecimals: false,
        labels: {
          enabled: true,
          formatter() {
            // eslint-disable-next-line
            return labels[this.value];
          }
        },
        min: 0,
        max: 3
      },
      xAxis: {
        type: 'linear',
        allowDecimals: false,
        labels: {
          enabled: true,
          formatter() {
            // eslint-disable-next-line
            return data[this.value].name;
          },
          autoRotation: [0]
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Score',
          showInLegend: false,
          data,
          color: 'black',
          animation: 0,
          labels: {
            enabled: false
          }
        }
      ]
    };
  };

  const getFacetValue = (facet, value) => {
    if (value < 10) {
      return facet.get('level_1');
    }
    if (value >= 10 && value <= 50) {
      return facet.get('level_2');
    }
    if (value >= 50 && value <= 90) {
      return facet.get('level_3');
    }

    return facet.get('level_4');
  };

  const getFacetsInfo = (report, behavior) => {
    const notGreen = [];
    const green = [];
    const finalValues = [];

    behavior.get('facets').map((facet) => {
      const current = report
        .get('facets')
        .find((f) => f.get('id') === facet.get('id'));
      const value = (current.get('fp') || 0) * 100;

      if (facet.get('better_if') === 'low') {
        if (value < 10 || value > 50) {
          return notGreen.push(getFacetValue(facet, value));
        }
      }

      if (facet.get('better_if') === 'high') {
        if (value < 50 || value > 90) {
          return notGreen.push(getFacetValue(facet, value));
        }
      }

      return green.push(getFacetValue(facet, value));
    });

    return {
      allInGreen: notGreen.length === 0,
      both: green.length > 0 && notGreen.length > 0,
      notGreen,
      green,
      finalValues
    };
  };

  const getFacetMessage = (report, behavior) => {
    const facetsData = getFacetsInfo(report, behavior);
    let type = 'UNDESIRED';
    let position = 'HIGH';
    if (facetsData.allInGreen) {
      type = 'ALL_DESIRED';
    }
    if (facetsData.both) {
      type = 'MIXED_DESIRED';
    }
    if (behavior.get('tbis_perc') < 67) {
      position = 'LOW';
    }

    if (type === 'MIXED_DESIRED') {
      return (
        <p>
          <FormattedHTMLMessage
            id={`FACET_INFO_${type}_${position}`}
            values={{
              behavior: behavior.get('name'),
              desiredFacets: facetsData.green
                .map((id) => context.intl.formatMessage({ id }))
                .join('; '),
              undesiredFacets: facetsData.notGreen
                .map((id) => context.intl.formatMessage({ id }))
                .join('; '),
              color: 'black'
            }}
          />
        </p>
      );
    }

    return (
      <p>
        <FormattedHTMLMessage
          id={`FACET_INFO_${type}_${position}`}
          values={{
            behavior: behavior.get('name'),
            facets: facetsData.green
              .concat(facetsData.notGreen)
              .map((id) => context.intl.formatMessage({ id }))
              .join('; '),
            color: 'black'
          }}
        />
      </p>
    );
  };

  const getMoreData = () => {
    if (currentList.length === behaviors.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setCurrentList(
        currentList.concat(behaviors.slice(count.prev + 1, count.next + 1))
      );
    }, 100);

    setCount((prevState) => ({
      prev: prevState.prev + 1,
      next: prevState.next + 1
    }));
  };

  return (
    <div>
      <Alert color="info" isOpen className={styles['analytics-infobox']}>
        <FormattedMessage id="BEHAVIORS_INFO" />
      </Alert>

      {current && currentList ? (
        <Row>
          <InfiniteScroll
            key={current}
            dataLength={currentList.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <h5>
                  <FormattedMessage id="LOADING" />
                </h5>
              </div>
            }
          >
            {currentList &&
              currentList.map((behavior, index) => {
                const rowData = getProgressValues(behavior.get('tbis_perc'));

                return (
                  <Row
                    className={`${styles['behavior-section']} html2pdf__page-break`}
                    key={`${index}-${behavior.get('id')}`}
                  >
                    <Col xs={12}>
                      <div
                        className={styles['overall-block']}
                        style={{
                          border: `1px solid ${rowData.color}`
                        }}
                      >
                        <div className={styles.left}>
                          <h6>{behavior.get('name')}</h6>
                        </div>
                        <div
                          className={classNames(styles.right, styles.flex)}
                          style={{
                            backgroundColor: rowData.color,
                            color:
                              rowData.colorString === 'blue' ? 'white' : 'black'
                          }}
                        >
                          <FormattedHTMLMessage
                            id={`DEVELOPMENT_STATUS.${rowData.position}`}
                          />
                        </div>
                      </div>

                      <p className={styles['summary-message']}>
                        <FormattedMessage
                          id="BEHAVIOR_PERC_MESSAGE"
                          values={{
                            value: Math.round(behavior.get('tbis_perc'))
                          }}
                        />{' '}
                        <FormattedHTMLMessage
                          id={`BEHAVIOR_SUMMARY_INFO_${rowData.position}`}
                          values={{
                            name: behavior.get('name'),
                            definition: behavior.get('definition'),
                            color: 'black'
                          }}
                        />
                      </p>
                    </Col>

                    <Col xs={12}>
                      <div
                        className={`${styles['grey-box']} html2pdf__page-break`}
                      >
                        <h4>
                          <FormattedMessage id="BEHAVIORS_IN_EXERCISES" />
                        </h4>
                      </div>
                      <Alert style={{ margin: '30px 0' }} color="info" isOpen>
                        <FormattedHTMLMessage id="BEHAVIORS_IN_EXERCISES_HINT" />
                      </Alert>

                      <div className="html2pdf__page-break">
                        {behavior.get('indicators').map((indicator, index) => (
                          <Col xs={6} key={index}>
                            <div className={styles['behaviors-graph']}>
                              <p>{indicator.get('name')}</p>
                              <div className={styles.graph} dir="ltr">
                                <ReactHighcharts
                                  config={getConfig(indicator)}
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div
                        className={`${styles['exercises-preview-block']} html2pdf__page-break`}
                      >
                        <h5>
                          <FormattedMessage id="BEHAVIORAL_OBSERVATIONS_HEADING" />
                          :
                        </h5>
                        {behavior
                          .get('indicators')
                          .first()
                          .get('exercises')
                          .map((e, index) => (
                            <div key={index}>
                              <h6>{e.get('name')}</h6>
                              <p>{e.get('description')}</p>
                            </div>
                          ))}
                      </div>
                    </Col>

                    {hasPsychometrics &&
                      behavior.has('facets') &&
                      behavior.get('facets').size > 0 && (
                        <Col xs={12}>
                          <div
                            className={`${styles['grey-box']} html2pdf__page-break`}
                          >
                            <h4>
                              <FormattedMessage id="INFLUENCING_FACTORS" />
                            </h4>
                          </div>

                          <Alert
                            style={{ margin: '30px 0' }}
                            color="info"
                            isOpen
                          >
                            <FormattedHTMLMessage id="INFLUENCING_FACTORS_HINT" />
                          </Alert>

                          {getFacetMessage(report, behavior)}

                          <div className={styles.facets}>
                            {behavior.get('facets').map((facet, index) => (
                              <Row className={styles['facet-row']} key={index}>
                                <Col xs={2}>
                                  <h6>
                                    <FormattedMessage id={facet.get('name')} />
                                  </h6>
                                </Col>
                                <Col xs={10}>
                                  <IndicatorGraph
                                    facet={report
                                      .get('facets')
                                      .find(
                                        (f) => f.get('id') === facet.get('id')
                                      )}
                                    betterIf={facet.get('better_if')}
                                  />
                                </Col>
                              </Row>
                            ))}
                          </div>

                          <div>
                            <div className={styles['grey-box']}>
                              {behavior.get('facets').map((facet, index) => (
                                <p key={index}>
                                  <strong>
                                    <FormattedMessage id={facet.get('name')} />
                                  </strong>{' '}
                                  -{' '}
                                  <FormattedMessage
                                    id={facet.get('definition')}
                                  />{' '}
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                      )}
                  </Row>
                );
              })}
          </InfiniteScroll>
        </Row>
      ) : null}
    </div>
  );
};

getBehaviors.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default React.memo(
  connect((state) => ({
    locale: state.app.get('locale'),
    route: state.router.route
  }))(
    React.memo(
      getBehaviors,
      (prevProps, nextProps) =>
        prevProps?.route?.query?.current === nextProps?.route?.query?.current
    )
  )
);
