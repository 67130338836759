import request from 'superagent-bluebird-promise';
import {
  DOWNLOAD_ASSIGNMENTS_REQUEST,
  DOWNLOAD_ASSIGNMENTS_SUCCESS,
  DOWNLOAD_ASSIGNMENTS_FAILURE
} from 'constants/actionTypes';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from '../../constants/cookies';

/**
 * Download assignments action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function downloadAssignments() {
  return {
    types: [
      DOWNLOAD_ASSIGNMENTS_REQUEST,
      DOWNLOAD_ASSIGNMENTS_SUCCESS,
      DOWNLOAD_ASSIGNMENTS_FAILURE
    ],
    promise: request
      .get(
        `${process.env.REACT_APP_APIURL}/api/v1/users/assignments/export/xlsx/`
      )
      .set('Authorization', `Bearer ${getCookie(AUTH_TOKEN)}`)
      .responseType('arraybuffer')
  };
}
