import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from '../IndicatorGraph/styles.css';
import '../IndicatorGraph/styles.css';

const ClimateGraph = (props) => {
  const { facet } = props;

  return (
    <div className={styles.graph}>
      {/* centered min / max line */}
      <div
        className={styles['centered-line']}
        style={{
          left: `calc(${facet.get('min')}% + 1px)`,
          width: `calc(${facet.get('max') - facet.get('min')}% - 3px)`
        }}
      />
      {/* 1SD below / above M rectangle */}
      <div
        className={styles['sd-rectangle']}
        style={{
          left: `calc(${facet.get('sd_below')}%)`,
          width: `calc(${facet.get('sd_above') - facet.get('sd_below')}%)`
        }}
      />
      {/* team average pointer */}
      <div
        className={styles['team-average']}
        style={{
          left: `calc(${facet.get('m')}%)`
        }}
      />

      <div className={styles.blue}>{facet.get('levels').get(0)}</div>
      <div className={styles.yellow}>{facet.get('levels').get(1)}</div>
      <div className={styles.green}>{facet.get('levels').get(2)}</div>
      <div className={styles.blue}>{facet.get('levels').get(3)}</div>
      <div
        key="text-left"
        className={classNames(styles.info, styles['text-left'])}
      >
        <FormattedMessage id={facet.get('level_1')} />
      </div>
      <div className={styles.info}>
        <FormattedMessage id={facet.get('level_2')} />
      </div>
      <div className={styles.info}>
        <FormattedMessage id={facet.get('level_3')} />
      </div>
      <div
        key="text-right"
        className={classNames(styles.info, styles['text-right'])}
      >
        <FormattedMessage id={facet.get('level_4')} />
      </div>
    </div>
  );
};

ClimateGraph.propTypes = {
  facet: PropTypes.object.isRequired
};

export default ClimateGraph;
