import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch notifications action
 *
 * @param{String} role
 * @param{Number} offset
 * @param{Number} limit
 * @param{String} query
 * @param{String} sortBy
 * @param{String} entity
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchNotifications(
  role,
  offset,
  limit,
  query,
  sortBy,
  entity
) {
  const string = `?q=${query || ''}&offset=${
    offset || 0
  }&limit=${15}&sort=+ord${sortBy || ''}&role=${role || ''}&entity=${
    entity || ''
  }`; // eslint-disable-line
  return {
    types: [
      FETCH_NOTIFICATIONS_REQUEST,
      FETCH_NOTIFICATIONS_SUCCESS,
      FETCH_NOTIFICATIONS_FAILURE
    ],
    promise: api().get(`/api/v1/notifications/${string}`)
  };
}
