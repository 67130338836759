import { Map } from 'immutable';

import {
  SET_VIRTUAL_BUTTON,
  SET_SHOW_IFRAME,
  SET_IFRAME_SRC
} from 'constants/actionTypes';

const initialState = Map({
  activeVirtualButton: 0,
  showIframe: false
});

export default function virtualNavigation(state = initialState, action) {
  switch (action.type) {
    case SET_VIRTUAL_BUTTON:
      return state.set('activeVirtualButton', action.activeButton);
    case SET_SHOW_IFRAME:
      return state.set('showIframe', action.showIframe);
    case SET_IFRAME_SRC:
      return state.set('iframeSrc', action.iframeSrc);
    default:
      return state;
  }
}
