import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionButtons, ConfirmDelete, Link } from 'components';
import { Button } from 'reactstrap';

import { emails } from 'actions/simulations';

import styles from '../../../../../components/BuildTable/styles.css';
import '../../../../../components/BuildTable/styles.css';
import { getCookie } from '../../../../../helpers';
import { AUTH_TOKEN } from '../../../../../constants/cookies';
import fileDownload from 'js-file-download';

export default function getEmailsTable(
  simulation,
  context,
  readOnly,
  transitionTo
) {
  const version = simulation
    .get('languages')
    .find((l) => l.get('language') === 'english');
  const disabled = version && version.get('published');

  const fields = [
    {
      key: 'subject',
      sortable: true,
      renderField: (item) => item.get('subject')
    },
    {
      key: 'from',
      sortable: false,
      renderField: (item) =>
        simulation.hasIn(['contacts', item.get('sender')])
          ? simulation.getIn(['contacts', item.get('sender')]).get('name')
          : ''
    },
    {
      key: 'pre-work',
      sortable: false,
      renderField: (item) => (
        <span
          className={
            item.get('prework')
              ? styles['checkmark-yes']
              : styles['checkmark-no']
          }
          dangerouslySetInnerHTML={{
            __html: item.get('prework') ? '&#10003;' : '&#10005;'
          }}
        />
      )
    },
    {
      key: 'attachment',
      sortable: false,
      renderField: (item) => {
        if (item.has('attachments')) {
          return item
            .get('attachments')
            .toArray()
            .map((att) => (
              <span>
                <span
                  style={{
                    display: 'inline-block',
                    'font-weight': 'bold',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    fetch(
                      `${process.env.REACT_APP_APIURL}/api/v1/assets${att.get(
                        'path'
                      )}`,
                      {
                        method: 'get',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`
                        },
                        mode: 'cors'
                      }
                    )
                      .then((response) => response.blob())
                      .then((blob) => {
                        fileDownload(blob, att.get('filename'));
                      })
                      .catch((reason) => {
                        // eslint-disable-next-line no-console
                        console.warn(reason);
                        alert('There was an error when downloading the file');
                      });
                    return true;
                  }}
                >
                  {att.get('filename')}
                </span>
                <br />
              </span>
            ));
        }

        return false;
      }
    },
    {
      key: 'send_at_from_start_date',
      sortable: false,
      renderField: (item) => item.get('date') || '00:00'
    }
  ];

  if (disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <Button
          type="button"
          color="link"
          onClick={() => {
            transitionTo(
              `/simulations/edit/${simulation.get('id')}/email/${item.get(
                'id'
              )}`,
              { readOnly: true }
            );
          }}
        >
          <FormattedMessage id="VIEW" />
        </Button>
      )
    });
  }

  if (!disabled) {
    fields.push({
      key: 'actions',
      sortable: false,
      renderField: (item) => (
        <ActionButtons
          id={item.get('id')}
          edit={() => {
            transitionTo(
              `/simulations/edit/${simulation.get('id')}/email/${item.get(
                'id'
              )}`
            );
          }}
          editDisabled={readOnly}
          delete={
            readOnly
              ? null
              : () => {
                  context.addModal(
                    <ConfirmDelete
                      action={emails}
                      data={{
                        type: 'delete',
                        simulationId: simulation.get('id'),
                        data: {},
                        id: item.get('id')
                      }}
                      onClose={context.removeModal}
                    />
                  );
                }
          }
        />
      )
    });
  }

  return {
    data: simulation.has('emails') ? simulation.get('emails').toArray() : [],
    customClassName: 'emails-table',
    fields,
    button:
      disabled || readOnly ? (
        <Button
          id="new_email_button"
          name="new_email_button"
          className={styles['add-button']}
          disabled
        >
          <FormattedMessage id="NEW_EMAIL" />
        </Button>
      ) : (
        <Link
          id="new_email_link"
          to={`/simulations/edit/${simulation.get('id')}/email`}
          query={{ isNew: true }}
        >
          <Button
            id="new_email_button"
            name="new_email_button"
            className={styles['add-button']}
          >
            <FormattedMessage id="NEW_EMAIL" />
          </Button>
        </Link>
      )
  };
}
