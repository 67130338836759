import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { actions as routexActions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import api from 'api';

import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  TabContent,
  TabPane
} from 'reactstrap';
import {
  ActionButtons,
  Dropdown,
  Loader,
  Link,
  ConfirmModal
} from 'components';
import Filter from '../Filter';
import MassActionTagsModal from './modals/MassActionTagsModal';

import { getTableData, renderField, getUserTableTabs } from './actions';
import {
  downloadAssignments,
  downloadUsersTable,
  fetchUsers,
  fetchPartners,
  fetchClients,
  setUsersDataFromCache,
  massResendInvitation,
  massTagsUpdate,
  massTPA,
  massReportsAccess,
  massDebriefInvitation
} from 'actions/users';
import {
  getUsersFetchString,
  handleBufferDownload,
  getTagsSearchString
} from 'helpers';

import { modules } from 'constants/fieldsData';
import {
  FETCH_USERS_FAILURE,
  FETCH_CLIENTS_FAILURE,
  FETCH_PARTNERS_FAILURE
} from 'constants/actionTypes';

import styles from '../../../../components/BuildTable/styles.css';
import '../../../../components/BuildTable/styles.css';

import { massActionTagsForm } from './modals/MassActionTagsModal/TagsForm';
import { ResultsAccess } from './modals';
import {
  getShareReportsAccessFormData,
  shareReportAccessFormOption1,
  shareReportAccessFormOption2
} from '../../../../forms/Global/ShareReportsAccessForm';
import { ToolTip } from '../../../../components';
import InviteToDebriefModal from './modals/InviteToDebriefModal/InviteToDebriefModal';

const pageSize = 25;

export const TAB_ALL = 1;
export const TAB_PARTNERS = 2;
export const TAB_CLIENTS = 3;
export const TAB_ASSESSORS = 4;
export const TAB_STAKEHOLDERS = 5;
export const TAB_ADMINS = 6;
export const TAB_PARTICIPANTS = 7;
export const TAB_SUPERUSERS = 8;

@connect((state) => ({
  users: state.users.get('users'),
  usersTotal: state.users.get('usersTotal'),
  filteredReports: state.users.get('filteredReports'),
  totalReports: state.users.get('totalReports'),
  assessorTotal: state.users.get('assessorTotal'),
  stakeholderTotal: state.users.get('stakeholderTotal'),
  adminTotal: state.users.get('adminTotal'),
  participantTotal: state.users.get('participantTotal'),
  superuserTotal: state.users.get('superuserTotal'),
  usersFiltered: state.users.get('usersFiltered'),
  assessorFiltered: state.users.get('assessorFiltered'),
  stakeholderFiltered: state.users.get('stakeholderFiltered'),
  adminFiltered: state.users.get('adminFiltered'),
  participantFiltered: state.users.get('participantFiltered'),
  allUsers: state.users.get('allUsers'),
  usersFilteredData: state.users.get('usersFilteredData'),
  usersLoading: state.users.get('usersLoading'),
  partners: state.partners.get('partners'),
  partnersTotal: state.partners.get('partnersTotal'),
  allPartners: state.partners.get('allPartners'),
  partnersLoading: state.partners.get('partnersLoading'),
  clients: state.clients.get('clients'),
  clientsTotal: state.clients.get('clientsTotal'),
  allClients: state.clients.get('allClients'),
  clientsLoading: state.clients.get('clientsLoading'),
  permissions: state.app.get('permissions'),
  simulations: state.simulations.hasIn(['simulations', 'english'])
    ? state.simulations.getIn(['simulations', 'english']).toArray()
    : [],
  languages: state.app.hasIn(['codeList', 'language_published'])
    ? state.app.getIn(['codeList', 'language_published']).toArray()
    : [],
  loggedUser: state.auth.get('loggedUser'),
  route: state.router.route,
  filterData: {
    search: formValueSelector('filter')(state, 'search'),
    tags: formValueSelector('filter')(state, 'tags') || [],
    partners: formValueSelector('filter')(state, 'partners') || [],
    clients: formValueSelector('filter')(state, 'clients') || [],
    assessments: formValueSelector('filter')(state, 'assessments') || [],
    from: formValueSelector('filter')(state, 'from'),
    to: formValueSelector('filter')(state, 'to'),
    stakeholders: formValueSelector('filter')(state, 'stakeholders')
  },
  tagsModalFormResult: {
    add: formValueSelector(massActionTagsForm)(state, 'addedTags') || [],
    remove: formValueSelector(massActionTagsForm)(state, 'removedTags') || []
  },
  shareReportsAccessFormData: getShareReportsAccessFormData(state),
  allStakeholders: state.analytics.get('shareableStakeholders')?.toArray() || []
}))
export default class UsersTable extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired,
    allUsers: PropTypes.object.isRequired,
    usersLoading: PropTypes.bool,
    partners: PropTypes.object.isRequired,
    allPartners: PropTypes.object.isRequired,
    partnersLoading: PropTypes.bool,
    clients: PropTypes.object.isRequired,
    allClients: PropTypes.object.isRequired,
    clientsLoading: PropTypes.bool,
    permissions: PropTypes.object,
    simulations: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    usersTotal: PropTypes.number,
    assessorTotal: PropTypes.number,
    stakeholderTotal: PropTypes.number,
    adminTotal: PropTypes.number,
    participantTotal: PropTypes.number,
    partnersTotal: PropTypes.number,
    clientsTotal: PropTypes.number,
    filteredReports: PropTypes.number,
    totalReports: PropTypes.number,
    loggedUser: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    filterData: PropTypes.object,
    usersFilteredData: PropTypes.object.isRequired,
    tagsModalFormResult: PropTypes.object.isRequired,
    shareReportsAccessFormData: PropTypes.object,
    allStakeholders: PropTypes.array
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  selectAllCheckbox = {};

  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      currentPage: 1,
      activeTab:
        route.query.tab || sessionStorage.getItem('activeTab') || TAB_ALL,
      sortBy: { key: null, asc: false },
      requestUrls: [],
      expanded: [],
      selectedRows: {
        all: false,
        included: [],
        excluded: []
      },
      disabled: false
    };
  }

  UNSAFE_componentWillMount() {
    const tab =
      this.props.route.query.tab ||
      sessionStorage.getItem('activeTab') ||
      TAB_ALL;
    const action = this.getAction(tab);

    action(0, TAB_ALL, {}, 'assessor').then(() => this.toggle(tab || TAB_ALL));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { route, filterData } = this.props;
    const { currentPage } = this.state;

    if (route.query.tab !== nextProps.route.query.tab) {
      this.toggle(nextProps.route.query.tab);
      this.setState({
        currentPage: 1,
        selectedRows: {
          all: false,
          included: [],
          excluded: []
        }
      });
    }

    Object.keys(filterData).map((key) => {
      if (
        filterData[key] !== nextProps.filterData[key] &&
        (filterData[key] != null || nextProps.filterData[key] != null)
      ) {
        // Ignore compare of 2 empty arrays (tags filter)
        if (
          typeof filterData[key] === 'object' &&
          filterData[key] != null &&
          filterData[key].length === 0 &&
          (nextProps.filterData[key] == null ||
            nextProps.filterData[key].length === 0)
        ) {
          return false;
        }

        if (currentPage !== 1) {
          return this.setState({ currentPage: 1 });
        }

        return false;
      }

      return false;
    });
  }

  getAction = (tab) => {
    const { activeTab } = this.state;
    const { dispatch } = this.props;
    const actions = bindActionCreators(
      {
        fetchUsers,
        fetchPartners,
        fetchClients
      },
      dispatch
    );

    switch (Number(tab) || Number(activeTab)) {
      case TAB_ALL:
        return actions.fetchUsers;
      case TAB_PARTNERS:
        return actions.fetchPartners;
      case TAB_CLIENTS:
        return actions.fetchClients;
      default:
        return actions.fetchUsers;
    }
  };

  getRole = (tab) => {
    switch (Number(tab)) {
      case TAB_PARTNERS:
        return 'partner';
      case TAB_CLIENTS:
        return 'client';
      case TAB_ASSESSORS:
        return 'assessor';
      case TAB_STAKEHOLDERS:
        return 'stakeholder';
      case TAB_ADMINS:
        return 'admin';
      case TAB_PARTICIPANTS:
        return 'participant';
      case TAB_SUPERUSERS:
        return 'superuser';
      default:
        return null;
    }
  };

  getModSimLan = (user) => {
    const { loggedUser, simulations, languages } = this.props;
    let userLanguages = [];
    let userSimulations = [];
    let userModules = [];

    if (loggedUser.get('isPartner')) {
      const partner = user.get('partner');
      if (partner) {
        if (partner.has('languages')) {
          partner.get('languages').map((lang) => {
            const current = languages.find((l) => l.get('name') === lang);
            if (current) {
              return userLanguages.push(current);
            }

            return false;
          });
        }

        if (partner.has('simulations')) {
          partner.get('simulations').map((sim) => {
            const current = simulations.find(
              (s) => s.get('id') === sim.get('id')
            );
            if (current) {
              return userSimulations.push(current);
            }

            return false;
          });
        }

        if (partner.has('modules')) {
          partner.get('modules').map((mod) => {
            const current = modules.find(
              (m) =>
                m.get('name') ===
                (typeof mod === 'string'
                  ? mod.toLowerCase()
                  : mod.get('name').toLowerCase())
            );
            if (current) {
              return userModules.push(current);
            }

            return false;
          });
        }
      }
    } else {
      userLanguages = this.props.languages;
      userSimulations = this.props.simulations;
      userModules = modules;
    }

    return {
      userLanguages,
      userSimulations,
      allSimulations: simulations,
      userModules
    };
  };

  getActionButton = (finalCount, filteredReports) => {
    const { activeTab, sortBy } = this.state;
    const { loggedUser, dispatch, permissions } = this.props;
    const sortString =
      sortBy.key == null ? null : `${sortBy.asc ? '+' : '-'}${sortBy.key}`;
    const { filterData } = this.props;
    const tabRole = this.getRole(activeTab);
    const buttons = [];

    const downloadTableDataButton = (
      <Button
        style={{ marginLeft: 15 }}
        color="primary"
        disabled={this.state.disabled}
        onClick={() => {
          if (this.state.disabled) {
            return;
          }
          this.setState({ disabled: true });
          dispatch(
            downloadUsersTable({
              role: tabRole,
              sortBy: sortString,
              filter: filterData
            })
          )
            .then((action) => {
              if (!action.error) {
                handleBufferDownload(
                  action,
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                );
              }

              this.setState({ disabled: false });
            })
            .catch((error) => {
              this.setState({ disabled: false });
              // eslint-disable-next-line no-console
              console.log(error);
            });
        }}
      >
        Download Table Data
      </Button>
    );

    const disabledTableDataButton = (
      <Button style={{ marginLeft: 15 }} color="primary" disabled>
        Download Table Data{' '}
        <ToolTip
          id="PARTICIPANT_JIT"
          index="PARTICIPANT_JIT"
          hint="To activate this button, please narrow down your search results to max. 250 participants and max. 250 reports."
        />
      </Button>
    );
    if (
      [
        TAB_ALL,
        TAB_PARTNERS,
        TAB_CLIENTS,
        TAB_ASSESSORS,
        TAB_STAKEHOLDERS,
        TAB_ADMINS,
        TAB_PARTICIPANTS
      ].indexOf(Number(activeTab)) !== -1
    ) {
      if (loggedUser.get('active_role') === 'admin') {
        if (parseInt(activeTab, 10) === TAB_PARTICIPANTS) {
          if (
            (!finalCount && this.props.participantTotal > 250) ||
            (finalCount && finalCount > 250) ||
            (filteredReports && filteredReports > 250)
          ) {
            buttons.push(disabledTableDataButton);
          } else {
            buttons.push(downloadTableDataButton);
          }
        } else {
          buttons.push(downloadTableDataButton);
        }
      }
    }

    if (
      Number(activeTab) === TAB_ASSESSORS &&
      permissions.find((permission) => permission === 'assignments_export_self')
    ) {
      buttons.push(
        <Button
          style={{ marginLeft: 15 }}
          color="primary"
          onClick={() => {
            dispatch(downloadAssignments()).then((action) => {
              if (!action.error) {
                handleBufferDownload(
                  action,
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                );
              }
            });
          }}
        >
          <FormattedMessage id="DOWNLOAD_ASSIGNMENTS" />
        </Button>
      );
    }

    return buttons || false;
  };

  toggle = (tab) => {
    const tabRole = this.getRole(tab);
    const action = this.getAction(tab);

    this.checkCacheData(
      action,
      {
        offset: 0,
        limit: pageSize,
        filter: {},
        role: tabRole,
        forceReload: tabRole === 'participant'
      },
      () => {
        if (this.state.activeTab !== tab) {
          sessionStorage.setItem('activeTab', tab);
          this.setState({
            activeTab: tab,
            sortBy: { key: null, asc: false }
          });
        }
      }
    );
  };

  reloadTableData = (tab) => {
    const { filterData } = this.props;
    const tabRole = this.getRole(tab);
    const action = this.getAction(tab);

    this.checkCacheData(
      action,
      {
        offset: 0,
        limit: pageSize,
        filter: filterData,
        role: tabRole,
        forceReload: tabRole === 'participant'
      },
      () => {
        if (this.state.activeTab !== tab) {
          sessionStorage.setItem('activeTab', tab);
          this.setState({
            activeTab: tab,
            sortBy: { key: null, asc: false }
          });
        }
      }
    );
  };

  sortItems = (field) => {
    const { filterData } = this.props;
    const { activeTab, sortBy } = this.state;
    const action = this.getAction(activeTab);

    const tabRole = this.getRole(activeTab);
    const updatedSortBy = {
      key: !sortBy.asc && sortBy.key === field.key ? null : field.key,
      asc: sortBy.key === field.key ? !sortBy.asc : true
    };

    // Set sort by state
    this.setState({
      sortBy: updatedSortBy
    });

    const sortString =
      updatedSortBy.key == null
        ? null
        : `${updatedSortBy.asc ? '+' : '-'}${updatedSortBy.key}`;

    this.checkCacheData(
      action,
      {
        offset: 0,
        limit: pageSize,
        filter: filterData,
        role: tabRole,
        sortBy: sortString
      },
      () => {
        this.setState({ currentPage: 1 });
      }
    );
  };

  isUsedFilter = (filterData) => {
    const { search, tags, partners, clients, assessments } = filterData;

    return (
      search != null ||
      tags.length > 0 ||
      (partners != null && partners.length !== 0) ||
      (clients != null && clients.length !== 0) ||
      (assessments != null && assessments.length !== 0)
    );
  };

  updateCurrentPage = (currentPage) => {
    const { filterData } = this.props;
    const { activeTab, sortBy } = this.state;
    const tabRole = this.getRole(activeTab);
    const action = this.getAction(activeTab);

    const fromIndex = (currentPage - 1) * pageSize;
    const sortString =
      sortBy.key == null ? null : `${sortBy.asc ? '+' : '-'}${sortBy.key}`;

    const offsetTop = ReactDOM.findDOMNode(this.table).offsetTop + 120;
    window.scrollTo(0, offsetTop);

    this.checkCacheData(
      action,
      {
        offset: fromIndex,
        limit: pageSize,
        filter: filterData,
        role: tabRole,
        sortBy: sortString
      },
      (data) => {
        if (
          filterData.assessments != null &&
          filterData.assessments.length !== 0
        ) {
          this.setState({ expanded: data });
        }

        this.setState({ currentPage });
      }
    );
  };

  checkCacheData = (action, args, callback) => {
    const { requestUrls } = this.state;
    const { dispatch, usersFilteredData } = this.props;
    const { offset, limit, filter, role, sortBy, forceReload } = args;

    const fetchString = getUsersFetchString(
      offset,
      limit,
      filter,
      role,
      sortBy
    );
    const cacheData = usersFilteredData.get(fetchString);

    if (!requestUrls.find((url) => url === fetchString)) {
      requestUrls.push(fetchString);
      this.setState({ requestUrls });
    }

    api.pendingRequests.map((r) => {
      if (requestUrls.find((url) => r.url.indexOf(url) > -1)) {
        r.abort();

        dispatch({ type: FETCH_USERS_FAILURE, error: 'Aborted' });
        dispatch({ type: FETCH_CLIENTS_FAILURE, error: 'Aborted' });
        dispatch({ type: FETCH_PARTNERS_FAILURE, error: 'Aborted' });
      }

      return true;
    });

    if (
      cacheData == null ||
      role === 'partner' ||
      role === 'client' ||
      forceReload
    ) {
      action(offset, limit, filter, role, sortBy).then((response) =>
        callback(response.result.body.data.map((i) => i.id))
      );
    } else {
      dispatch(setUsersDataFromCache(cacheData));
      callback(cacheData.toArray().map((i) => i.get('id')));
    }
  };

  selectRow = (e, item, field, pastSimulationId) => {
    const { filteredReports } = this.props;
    const included = this.state.selectedRows.included;
    const excluded = this.state.selectedRows.excluded;

    if (item === 'all') {
      if (e) {
        this.setState({
          selectedRows: {
            all:
              included.length > 0 || excluded.length > 0
                ? true
                : e.target.checked,
            included: [],
            excluded: []
          }
        });
      } else {
        this.setState({
          selectedRows: {
            all: false,
            included: [],
            excluded: []
          }
        });
      }

      this.selectAllCheckbox.indeterminate = false;
    } else {
      const rowId = pastSimulationId || item.getIn(['active_simulation', 'id']);

      if (this.state.selectedRows.all === true) {
        if (excluded.indexOf(rowId) > -1) {
          excluded.splice(excluded.indexOf(rowId), 1);
        } else {
          excluded.push(rowId);
        }

        this.setState({
          selectedRows: {
            all: true,
            included: [],
            excluded
          }
        });

        this.selectAllCheckbox.indeterminate =
          excluded.length !== filteredReports && excluded.length !== 0;
      } else {
        if (included.indexOf(rowId) > -1) {
          included.splice(included.indexOf(rowId), 1);
        } else {
          included.push(rowId);
        }

        this.setState({
          selectedRows: {
            all: false,
            included,
            excluded: []
          }
        });

        this.selectAllCheckbox.indeterminate =
          included.length !== 0 && included.length !== filteredReports;
      }
    }
  };

  massActionCallback = () => {
    const { addAlert, removeModal } = this.context;

    addAlert({
      color: 'success',
      content: <FormattedMessage id="SUCCESSFULLY_SAVED" />,
      style: {
        position: 'fixed',
        top: 10,
        width: 1110,
        zIndex: 99999
      }
    });
    removeModal();
  };

  massActionCallbackError = (message) => {
    const { addAlert, removeModal } = this.context;

    addAlert({
      color: 'danger',
      content: <FormattedHTMLMessage id={message} />,
      style: {
        position: 'fixed',
        top: 10,
        width: 1110,
        zIndex: 99999
      }
    });
    removeModal();
  };

  massActionDebriefCallback = (successful) => {
    const { addAlert, removeModal } = this.context;

    addAlert({
      color: 'success',
      content: (
        <FormattedMessage
          id={'DEBRIEF_SUCCESS_CALLBACK'}
          values={{ success: successful.length }}
        />
      ),
      style: {
        position: 'fixed',
        top: 10,
        width: 1110,
        zIndex: 99999
      }
    });
    removeModal();
  };

  massActionDebriefCallbackError = (successful, failed) => {
    const { addAlert, removeModal } = this.context;
    const invitationFailedEmails = failed.join(', ');

    addAlert({
      color: 'danger',
      content: (
        <FormattedHTMLMessage
          id={'DEBRIEF_ERROR_CALLBACK'}
          values={{
            success: successful.length,
            failure: failed.length,
            invitationFailedEmails
          }}
        />
      ),
      style: {
        position: 'fixed',
        top: 10,
        width: 1110,
        zIndex: 99999
      }
    });
    removeModal();
  };

  showTPAModal = (actionData, enable) => {
    const { addModal, removeModal } = this.context;
    const { dispatch } = this.props;

    addModal(
      <ConfirmModal
        confirmMessage="CONFIRM"
        header={<FormattedMessage id="ARE_YOU_SURE" />}
        centerBody
        message={
          <FormattedHTMLMessage
            id={`MASS_ACTION.TPA.${
              enable ? 'ENABLE' : 'DISABLE'
            }_CONFIRM_MESSAGE`}
          />
        }
        submitAction={() =>
          dispatch(massTPA(actionData, enable)).then(this.massActionCallback)
        }
        onClose={removeModal}
      />
    );
  };

  getSelectedParticipantsCount = (selected, count) => {
    let participantsCount = 0;
    const participantsArray = [];

    const getUserSimulationIDs = (u) =>
      [u.getIn(['active_simulation', 'id'])].concat(
        u
          .get('past_simulations')
          ?.map((ps) => ps.get('id'))
          ?.toArray()
      );

    if (selected.all) {
      if (selected.excluded.length === 0) {
        participantsCount = count;
      } else {
        this.props.users
          .filter((u) => u.get('roles').includes('participant'))
          .map((u) => {
            if (!participantsArray.includes(u.get('id'))) {
              const userSimulationIDs = getUserSimulationIDs(u);

              if (
                userSimulationIDs.every((id) => selected.excluded.includes(id))
              ) {
                participantsArray.push(u.get('id'));
              }
            }

            return true;
          });

        participantsCount = count - participantsArray.length;
      }
    } else {
      this.props.users
        .filter((u) => u.get('roles').includes('participant'))
        .map((u) => {
          if (!participantsArray.includes(u.get('id'))) {
            const userSimulationIDs = getUserSimulationIDs(u);

            if (
              userSimulationIDs.find((id) => selected.included.includes(id))
            ) {
              participantsArray.push(u.get('id'));
            }
          }

          return true;
        });

      participantsCount = participantsArray.length;
    }

    return participantsCount;
  };

  renderTable = (tab) => {
    const {
      dispatch,
      users,
      partners,
      clients,
      loggedUser,
      filterData,
      clientsTotal,
      partnersTotal,
      usersLoading,
      partnersLoading,
      clientsLoading,
      permissions,
      filteredReports
    } = this.props;
    const { sortBy, currentPage, expanded, selectedRows } = this.state;
    const { addModal, removeModal } = this.context;
    const tabRole = this.getRole(tab.id);
    const isLoading = usersLoading || partnersLoading || clientsLoading;
    let finalCount = 0;

    if (Number(tab.id) === TAB_PARTNERS) {
      finalCount = partnersTotal;
    } else if (Number(tab.id) === TAB_CLIENTS) {
      finalCount = clientsTotal;
    } else {
      finalCount = this.props[
        `${tabRole || 'users'}${
          !this.isUsedFilter(filterData)
            ? `${tabRole === 'participant' ? 'Filtered' : 'Total'}`
            : 'Filtered'
        }`
      ];
    }

    const data = { users, partners, clients };

    // Get table items
    const table = getTableData(tab.id, data, loggedUser);

    // Map functions
    const action = table.action;
    const transitionTo = routexActions.transitionTo;
    const actions = bindActionCreators(
      {
        action,
        transitionTo
      },
      dispatch
    );

    const tableData = table.data.slice(0, pageSize);
    const pagesCount = Math.ceil(finalCount / pageSize);

    const actionData = selectedRows.all
      ? {
          filter: {
            assessments: filterData.assessments,
            clients: filterData.clients,
            from: filterData.from,
            partners: filterData.partners,
            q: filterData.search,
            tags: getTagsSearchString(filterData.tags, false),
            stakeholders: filterData.stakeholders
          },
          exclude: selectedRows.excluded
        }
      : { include: selectedRows.included };
    const dropdownItemStyle = {
      display: 'inline-block',
      marginRight: 2,
      textAlign: 'center',
      width: 11
    };

    const massActions = [
      {
        key: 'MASS_ACTION.TAGS',
        action: () =>
          addModal(
            <MassActionTagsModal
              actionData={actionData}
              onSubmit={() => {
                dispatch(
                  massTagsUpdate(actionData, this.props.tagsModalFormResult)
                ).then(this.massActionCallback);
              }}
            />
          )
      },
      {
        key: 'MASS_ACTION.RESEND_INVITATIONS',
        action: () =>
          addModal(
            <ConfirmModal
              confirmMessage="CONFIRM"
              header={<FormattedMessage id="MASS_ACTION.RESEND_INVITATIONS" />}
              centerBody
              message={
                <FormattedHTMLMessage id="MASS_ACTION.RESEND_INVITATIONS.CONFIRM_MESSAGE" />
              }
              submitAction={() =>
                dispatch(massResendInvitation(actionData)).then(
                  this.massActionCallback
                )
              }
              onClose={removeModal}
            />
          )
      },
      {
        key: 'MASS_ACTION.RESULT_ACCESS',
        dropdownItems: [
          {
            order: 1,
            title: (
              <span>
                <span style={dropdownItemStyle}>+</span>
                <FormattedMessage id="MASS_ACTION.RESULT_ACCESS.SHARE" />
              </span>
            ),
            action: () =>
              addModal(
                <ResultsAccess
                  actionData={actionData}
                  type="share"
                  onSubmit={() => {
                    dispatch(
                      massReportsAccess(
                        actionData,
                        this.props.shareReportsAccessFormData,
                        true,
                        this.props.allStakeholders
                      )
                    ).then((action) => {
                      if (action.result.body && action.result.body.failed) {
                        return this.massActionCallbackError(
                          action.result.body.failed.error
                        );
                      }

                      if (action.error) {
                        return this.massActionCallbackError(action.error.body);
                      }
                      return this.massActionCallback();
                    });
                  }}
                  selectedRows={this.getSelectedParticipantsCount(
                    selectedRows,
                    finalCount
                  )}
                />
              )
          },
          {
            order: 2,
            title: (
              <span>
                <span style={dropdownItemStyle}>-</span>
                <FormattedMessage id="MASS_ACTION.RESULT_ACCESS.REMOVE" />
              </span>
            ),
            action: () =>
              addModal(
                <ResultsAccess
                  actionData={actionData}
                  type="remove"
                  onSubmit={() => {
                    dispatch(
                      massReportsAccess(
                        actionData,
                        {
                          is_disabled_self: this.props
                            .shareReportsAccessFormData[
                            shareReportAccessFormOption1
                          ],
                          is_disabled_stakeholders: this.props
                            .shareReportsAccessFormData[
                            shareReportAccessFormOption2
                          ],
                          stakeholders: this.props.allStakeholders
                            ?.filter(
                              (s) =>
                                this.props.shareReportsAccessFormData.stakeholders.indexOf(
                                  s.get('id')
                                ) < 0
                            )
                            ?.map((s) => s.get('id'))
                        },
                        false,
                        this.props.allStakeholders
                      )
                    ).then(this.massActionCallback);
                  }}
                  selectedRows={this.getSelectedParticipantsCount(
                    selectedRows,
                    finalCount
                  )}
                />
              )
          }
        ]
      },
      {
        key: 'MASS_ACTION.APP',
        dropdownItems: [
          {
            order: 1,
            title: (
              <span>
                <span style={dropdownItemStyle}>+</span>
                <FormattedMessage id="MASS_ACTION.APP.ENABLE" />
              </span>
            ),
            action: () => this.showTPAModal(actionData, true)
          },
          {
            order: 2,
            title: (
              <span>
                <span style={dropdownItemStyle}>-</span>
                <FormattedMessage id="MASS_ACTION.APP.DISABLE" />
              </span>
            ),
            action: () => this.showTPAModal(actionData, false)
          }
        ]
      }
    ];

    if (!loggedUser.get('partner_id')) {
      massActions.push({
        key: 'MASS_ACTION.INVITE_TO_DEBRIEF',
        action: () =>
          addModal(
            <InviteToDebriefModal
              actionData={actionData}
              onSubmit={(id) => {
                if (id >= 0) {
                  dispatch(massDebriefInvitation(actionData, id))
                    .then((action) => {
                      const { successful, failed } = action.result.body;
                      if (action.result.body.failed.length > 0) {
                        return this.massActionDebriefCallbackError(
                          successful,
                          failed
                        );
                      }
                      return this.massActionDebriefCallback(successful);
                    })
                    .finally(() => {
                      this.selectRow(null, 'all', null, null);
                      this.reloadTableData(this.state.activeTab);
                    });
                }
              }}
            />
          )
      });
    }

    const disabledParticipantsMassActions =
      (!selectedRows.all && selectedRows.included.length === 0) ||
      filteredReports === selectedRows.excluded.length;
    const headerHeight = tabRole === 'participant' ? 67 : 47;

    return (
      <div ref={(node) => (this.table = node)}>
        {tabRole !== 'participant' && (
          <h6 className={styles['users-count']}>
            Total: {finalCount}{' '}
            <FormattedMessage
              id={`USERS_TABLE_TABS.${
                tab.title === 'all' ? 'users' : tab.title
              }`}
            />
            {this.getActionButton(finalCount, filteredReports)}
          </h6>
        )}
        <span className={styles['download-table-data-button']}>
          {tabRole === 'participant' &&
            this.props.participantTotal > 0 &&
            this.getActionButton(finalCount, filteredReports)}
        </span>
        {tabRole === 'participant' && (
          <div key="mass-actions" className={styles['mass-actions']}>
            {disabledParticipantsMassActions ? (
              <FormattedMessage
                id="PARTICIPANTS_MASS_ACTIONS_INFO"
                values={{
                  state: 'Total:',
                  participants: finalCount,
                  reports: filteredReports
                }}
              />
            ) : (
              <FormattedMessage
                id="PARTICIPANTS_MASS_ACTIONS_INFO"
                values={{
                  state: 'Selected:',
                  participants: this.getSelectedParticipantsCount(
                    selectedRows,
                    finalCount
                  ),
                  reports: selectedRows.all
                    ? filteredReports - selectedRows.excluded.length
                    : selectedRows.included.length
                }}
              />
            )}
            {' - '}
            <FormattedMessage id="MASS_ACTIONS" />:
            {massActions.map((action) =>
              action.dropdownItems ? (
                <Dropdown
                  small
                  disabled={disabledParticipantsMassActions}
                  title={action.key}
                  translate
                  noItemsTranslate
                  items={action.dropdownItems}
                />
              ) : (
                <Button
                  disabled={disabledParticipantsMassActions}
                  color="secondary"
                  onClick={action.action}
                >
                  <FormattedMessage id={action.key} />
                </Button>
              )
            )}
          </div>
        )}
        <Table
          key="main-users-table"
          style={{ position: 'relative', minHeight: isLoading ? 200 : 'auto' }}
          bordered
          striped
          className={[
            styles.table,
            typeof table.customClassName === 'object'
              ? table.customClassName.map((className) => styles[className])
              : table.customClassName != null
              ? styles[`${table.customClassName}`]
              : ''
          ]}
        >
          <thead>
            <tr>
              {table.fields.map((field, i) => (
                <th
                  key={i}
                  id={`toggle-sort-${field.key}`}
                  onClick={
                    field.sortable ? () => this.sortItems(field) : () => false
                  }
                >
                  {field.key === 'checkbox' ? (
                    <input
                      ref={(item) => (this.selectAllCheckbox = item)}
                      type="checkbox"
                      disabled={filteredReports === 0}
                      checked={
                        filteredReports !== 0 &&
                        ((this.state.selectedRows.all &&
                          filteredReports >
                            this.state.selectedRows.excluded.length) ||
                          filteredReports ===
                            this.state.selectedRows.included.length)
                      }
                      onChange={(e) => this.selectRow(e, 'all', field, false)}
                    />
                  ) : (
                    <FormattedMessage id={`TABLE_HEADING.${field.key}`} />
                  )}
                  {field.sortable && (
                    <span className={styles.table__carrots}>
                      <span
                        className={classNames(
                          styles.desc,
                          sortBy.key === field.key &&
                            !sortBy.asc &&
                            styles.active
                        )}
                      >
                        &#9650;
                      </span>
                      <span
                        className={classNames(
                          styles.asc,
                          sortBy.key === field.key &&
                            sortBy.asc &&
                            styles.active
                        )}
                      >
                        &#9660;
                      </span>
                    </span>
                  )}
                </th>
              ))}
              <th>
                <FormattedMessage
                  id={table.settings.action ? 'ACTIONS' : 'EDIT'}
                />
              </th>
            </tr>
          </thead>
          {isLoading && (
            <div
              style={{
                width: 1108,
                position: 'absolute',
                height: `calc(100% - ${headerHeight}px)`,
                background: '#fff',
                zIndex: 9,
                top: headerHeight
              }}
            >
              <Loader />
            </div>
          )}
          <tbody>
            {tableData.map((item) => {
              const canEdit =
                item &&
                item.get('permissions') &&
                item.get('permissions').indexOf('edit') > -1;
              const data = this.getModSimLan(item);
              const expandable =
                tabRole === 'participant' &&
                item.has('past_simulations') &&
                item.get('past_simulations').size > 0;
              return (
                <React.Fragment>
                  <tr
                    key={item.get('id')}
                    className={classNames(
                      item.get('status') === 'suspended' && styles['alert-row'],
                      item.get('completed') && styles.completed,
                      item.get('pending') && styles.pending,
                      item.get('incompleted') && styles.incompleted
                    )}
                  >
                    {table.fields.map((field) =>
                      renderField(item, field, {
                        simulations: data.userSimulations,
                        allSimulations: data.allSimulations,
                        languages: data.userLanguages,
                        modules: data.userModules,
                        query: filterData.search || '',
                        action: actions.action,
                        canEdit,
                        filterData,
                        permissions,
                        expandable,
                        expanded: expanded.indexOf(item.get('id')) > -1,
                        onExpand: () => {
                          if (expanded.indexOf(item.get('id')) > -1) {
                            expanded.splice(
                              expanded.indexOf(item.get('id')),
                              1
                            );
                          } else expanded.push(item.get('id'));

                          return this.setState({
                            expanded
                          });
                        },
                        selectedRows,
                        onSelect: (e) => this.selectRow(e, item, field, false),
                        loggedUser,
                        tab: tab.id
                      })
                    )}
                    <td>
                      {canEdit && (
                        <ActionButtons
                          id={`user-${item.get('id')}`}
                          editLink={`/users${table.editPath}/edit/${item.get(
                            'id'
                          )}`}
                          editQuery={{ role: tab.title, fromTab: tab.id }}
                        />
                      )}
                    </td>
                  </tr>
                  {expandable &&
                    expanded.indexOf(item.get('id')) > -1 &&
                    item.get('past_simulations').map((pastSimulation) => (
                      <tr
                        key={`past-${pastSimulation.get('id')}-${item.get(
                          'id'
                        )}`}
                        className={classNames(
                          item.get('status') === 'suspended' &&
                            styles['alert-row'],
                          styles.completed
                        )}
                      >
                        {table.fields.map((field) =>
                          renderField(item, field, {
                            simulations: data.userSimulations,
                            allSimulations: data.allSimulations,
                            languages: data.userLanguages,
                            modules: data.userModules,
                            query: filterData.search || '',
                            action: actions.action,
                            canEdit,
                            filterData,
                            permissions,
                            pastSimulation,
                            selectedRows,
                            onSelect: (e) =>
                              this.selectRow(
                                e,
                                item,
                                field,
                                pastSimulation.get('id')
                              ),
                            loggedUser
                          })
                        )}
                        <td>
                          {canEdit && (
                            <ActionButtons
                              id={`user-${item.get('id')}`}
                              editLink={`/users${
                                table.editPath
                              }/edit/${item.get('id')}`}
                              editQuery={{ role: tab.title, fromTab: tab.id }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>

        {pagesCount > 1 && (
          <Pagination aria-label="Page navigation example">
            <PaginationItem>
              <PaginationLink
                id="pagination_link_prev"
                previous
                onClick={() =>
                  currentPage > 1
                    ? this.updateCurrentPage(currentPage - 1)
                    : null
                }
              />
            </PaginationItem>

            {(() => {
              const pages = [];

              for (let i = 1; i <= pagesCount; i++) {
                pages.push(i);
              }

              return pages.map((page) => (
                <PaginationItem active={page === currentPage}>
                  <PaginationLink
                    id={`pagination_link_page_${page}`}
                    onClick={() =>
                      page === currentPage ? null : this.updateCurrentPage(page)
                    }
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ));
            })()}

            <PaginationItem>
              <PaginationLink
                id="pagination_link_next"
                next
                onClick={() =>
                  currentPage < Math.ceil(finalCount / pageSize)
                    ? this.updateCurrentPage(currentPage + 1)
                    : null
                }
              />
            </PaginationItem>
          </Pagination>
        )}
      </div>
    );
  };

  render() {
    const { permissions, allUsers, filterData } = this.props;
    const { activeTab } = this.state;
    const userTableTabs = getUserTableTabs(permissions, allUsers);
    const tabRole = this.getRole(activeTab);

    return (
      <div>
        <Nav tabs>
          {userTableTabs.map((tab) => (
            <NavItem key={tab.id} style={{ ':hover': { cursor: 'pointer' } }}>
              <Link
                id={`tab-link-${tab.title}`}
                to="/users"
                query={{ tab: tab.id.toString() }}
                type="inherit"
              >
                <NavLink
                  className={classNames(
                    { active: activeTab === tab.id },
                    styles['nav-link']
                  )}
                >
                  <FormattedMessage id={`USERS_TABLE_TABS.${tab.title}`} />
                </NavLink>
              </Link>
            </NavItem>
          ))}
          {tabRole !== 'participant' && (
            <Filter
              tab={activeTab}
              tabRole={tabRole}
              action={this.getAction(activeTab)}
              resetSortData={() => {
                this.setState({
                  sortBy: { key: null, asc: false },
                  selectedRows: { all: false, included: [], excluded: [] }
                });

                if (this.selectAllCheckbox != null) {
                  this.selectAllCheckbox.indeterminate = false;
                }
              }}
            />
          )}
        </Nav>

        {tabRole === 'participant' && (
          <Row>
            <Filter
              tab={activeTab}
              tabRole={tabRole}
              action={this.getAction(activeTab)}
              role={this.getRole(activeTab)}
              resetSortData={() => {
                this.setState({
                  sortBy: { key: null, asc: false },
                  selectedRows: { all: false, included: [], excluded: [] }
                });

                if (this.selectAllCheckbox != null) {
                  this.selectAllCheckbox.indeterminate = false;
                }
              }}
              callback={(response) => {
                if (
                  filterData.assessments != null &&
                  filterData.assessments.length !== 0
                ) {
                  const expanded = response.result.body.data.map((i) => i.id);
                  this.setState({ expanded });
                }
              }}
            />
          </Row>
        )}

        <TabContent activeTab={activeTab}>
          {userTableTabs.map((tab) => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col sm="12">
                  {activeTab === tab.id && this.renderTable(tab)}
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}
