import { modules } from 'constants/fieldsData';
import fieldInputs from './fieldInputs';

// Const

const getClientFields = (form, user, change, helpers) => {
  const data = [
    { name: 'partner_id', value: user.get('partner_id') },
    { name: 'name', value: user.get('name') },
    { name: 'status', value: user.get('status') },
    { name: 'owner_id', value: user.get('owner').get('id') }
  ];

  modules.map((module, index) =>
    data.push({
      name: `modules[${index}]`,
      value: user
        .get('modules')
        .find((mod) => mod.get('id') === module.get('id'))
    })
  );

  helpers.simulations.map((simulation) =>
    data.push({
      name: `simulations[${simulation.get('id')}]`,
      value: !!user
        .get('simulations')
        .find((sim) => sim.get('id') === simulation.get('id'))
    })
  );

  if (helpers.debriefs) {
    helpers.debriefs.map((debrief) =>
      data.push({
        name: `debriefs[${debrief.get('id')}]`,
        value: !!user
          .get('debriefs')
          .find((sim) => sim.get('id') === debrief.get('id'))
      })
    );
  }

  helpers.languages.map((language, index) =>
    data.push({
      name: `languages[${index}]`,
      value: user.get('languages').find((lan) => lan === language.get('name'))
    })
  );

  fieldInputs(form, data, change);
};

export default getClientFields;
