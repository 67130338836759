/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';

// Styles
import styles from '../../../styles.css';
import '../../../styles.css';

// Validation
import { validate } from './validate';

// Components
import { Button, Form, FormGroup, Label, Col, Row } from 'reactstrap';

// Actions
import { renderInput } from '../../../actions';

// Const
const form = 'editBehaviorBasicInformation';

/**
 * Edit basic information form
 * @class
 */
@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    behaviorsLoading: state.app.get('simulationsLoading'),
    behavior: state.behaviors.getIn([
      'behaviors',
      'english',
      Number(state.router.route.vars.id)
    ]),
    isLoading:
      state.behaviors.get('editBehaviorLoading') ||
      state.behaviors.get('createBehaviorLoading'),
    developability: state.app.getIn(['codeList', 'developability']).toArray()
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ change, transitionTo }, dispatch);
  }
)
export default class BasicInformation extends Component {
  // Prop types
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    behavior: PropTypes.object,
    behaviorsLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    developability: PropTypes.array.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  // Context types
  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { change, behavior } = this.props;

    if (behavior) {
      change(form, 'name', behavior.get('name'));
      change(form, 'developability', behavior.get('developability'));
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { intl } = this.context;
    const {
      handleSubmit,
      isLoading,
      developability,
      transitionTo,
      behavior
    } = this.props;
    let disabled = false;

    if (behavior && behavior.has('languages')) {
      disabled = behavior
        .get('languages')
        .find((l) => l.get('language') === 'english')
        .get('published');
    }

    const message = behavior ? 'SAVE' : 'CREATE_BEHAVIOR';

    return (
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={5}>
            <FormGroup>
              <Label htmlFor="name">
                <FormattedMessage id="FORM_FIELDS.behavior_name" />
              </Label>
              <Field
                name="name"
                component={renderInput}
                type="text"
                placeholder={intl.formatMessage({ id: 'NEW_BEHAVIOR' })}
                disabled={disabled}
              />
            </FormGroup>
            <FormGroup className={styles['developability-select']}>
              <Label htmlFor="developability">
                <FormattedMessage id="FORM_FIELDS.developability" />
              </Label>
              <Field
                name="developability"
                component={renderInput}
                type="select"
                valueKey="name"
                optionKey="name"
                options={developability}
                disabled={disabled}
                placeholder="PLEASE_SELECT_DEVELOPABILITY"
                translatePlaceholder
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className={styles['form-buttons']}>
          <Col xs={2} className={styles['btn-col']}>
            <Button
              id="cancel_behavior_basic_information"
              name="cancel_behavior_basic_information"
              type="button"
              color="secondary"
              onClick={() => transitionTo('/simulations', { tab: '2' })}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          <Col xs={3} className={styles['btn-col']}>
            <Button
              id="submit_behavior_basic_information"
              name="submit_behavior_basic_information"
              type="button"
              color="primary"
              onClick={handleSubmit}
              disabled={disabled}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : message} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
