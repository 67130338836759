/* eslint-disable no-else-return */
// Vendor
import React from 'react';
import moment from 'moment';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Map, fromJS } from 'immutable';
import AvailabilityBadge from '../AvailabilityBadge';

/**
 * Get history description
 * @param{Object} item
 * @param{Object} intl
 * @param{Object} user
 */
export default function getHistoryDescription(item, intl, user) {
  const stage = item.get('stage') || Map(fromJS({ name: item.get('name') }));
  const simulation = item.hasIn(['data', 'simulation'])
    ? item.getIn(['data', 'simulation'])
    : Map();
  const by = item.hasIn(['data', 'invited_by'])
    ? item.getIn(['data', 'invited_by'])
    : Map();
  if (stage.get('name') === 'invited1') {
    if (simulation.size > 0 && by.has('name')) {
      return (
        <span>
          <FormattedMessage
            id={`STAGES.${item
              .get('stage')
              .get('name')}_WITH_SIMULATION_AND_NAME`}
            values={{
              by: by.get('name') || 'Undefined',
              simulation: simulation.get('name'),
              language: intl.formatMessage({
                id: `LANGUAGE_VALUES.${simulation.get('language')}`
              })
            }}
          />
        </span>
      );
    }
    if (simulation.size > 0) {
      return (
        <span>
          <FormattedMessage
            id={`STAGES.${item.get('stage').get('name')}_WITH_SIMULATION`}
            values={{
              simulation: simulation.get('name'),
              language: intl.formatMessage({
                id: `LANGUAGE_VALUES.${simulation.get('language')}`
              })
            }}
          />
        </span>
      );
    }
    if (by.has('name')) {
      return (
        <span>
          <FormattedMessage
            id={`STAGES.${item.get('stage').get('name')}_WITH_NAME`}
            values={{
              by: by.get('name') || 'Undefined'
            }}
          />
        </span>
      );
    }

    return <FormattedMessage id={`STAGES.${stage.get('name')}`} />;
  }
  if (stage.get('name') === 'scheduled1') {
    const startTime = item.getIn(['data', 'starttime']);

    return (
      <span>
        <FormattedMessage
          id={
            item.getIn(['data', 'type']) === 'admin'
              ? 'SIMULATION_SCHEDULED_FOR_DATE_BY'
              : 'SIMULATION_SCHEDULED_FOR_DATE'
          }
          values={{
            startTime: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A'),
            name: item.getIn(['data', 'scheduled_by', 'name'])
          }}
        />
      </span>
    );
  }
  if (stage.get('name') === 'debrief_scheduled1') {
    const startTime = item.getIn(['data', 'starttime']);
    if (simulation.size > 0) {
      return (
        <span>
          <FormattedMessage
            id={
              item.getIn(['data', 'type']) === 'admin'
                ? 'DEBRIEF_SCHEDULED_FOR_DATE_BY'
                : 'DEBRIEF_SCHEDULED_FOR_DATE'
            }
            values={{
              startTime: moment(startTime)
                .utcOffset(startTime)
                .format('DD. MMM YYYY hh:mm A'),
              name: item.getIn(['data', 'scheduled_by', 'name']),
              debriefName: simulation.get('name')
            }}
          />
        </span>
      );
    }
  }
  if (stage.get('name') === 'USEREVENT.roleplay_planned') {
    const contact = item.getIn(['data', 'contact']);
    const startTime = item.getIn(['data', 'time_from']);
    return (
      <div>
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.roleplay_planned"
          values={{
            rp: item.getIn(['data', 'roleplay']) || 'Roleplay',
            name: contact,
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />
        {item.hasIn(['data', 'assessor']) && (
          <span className="float-xs-right">
            [{item.getIn(['data', 'assessor', 'firstname'])}{' '}
            {item.getIn(['data', 'assessor', 'surname'])}]
          </span>
        )}
        {item.hasIn(['data', 'role_play_status']) && (
          <span className="float-xs-right">
            <AvailabilityBadge
              badge={item.getIn(['data', 'role_play_status'])}
            />
          </span>
        )}
      </div>
    );
  }
  if (stage.get('name') === 'USEREVENT.debrief_call_planed') {
    const contact = item.getIn(['data', 'contact']);
    const startTime = item.getIn(['data', 'time_from']);
    return (
      <div>
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.debrief_call_planed"
          values={{
            rp: item.getIn(['data', 'roleplay']) || 'Roleplay',
            name: contact,
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />
        {item.hasIn(['data', 'assessor']) && (
          <span className="float-xs-right">
            [{item.getIn(['data', 'assessor', 'firstname'])}{' '}
            {item.getIn(['data', 'assessor', 'surname'])}]
          </span>
        )}
        {item.hasIn(['data', 'role_play_status']) && (
          <span className="float-xs-right">
            <AvailabilityBadge
              badge={item.getIn(['data', 'role_play_status'])}
            />
          </span>
        )}
      </div>
    );
  }
  if (stage.get('name') === 'USEREVENT.roleplay_scored') {
    return (
      <div>
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.roleplay_scored"
          values={{
            rp: item.getIn(['data', 'roleplay']) || 'Roleplay'
          }}
        />
      </div>
    );
  }
  if (stage.get('name') === 'USEREVENT.scoring_planned') {
    const startTime = item.getIn(['data', 'score_by']);

    return (
      <div>
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.scoring_planned"
          values={{
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />

        {item.hasIn(['data', 'assessor']) && (
          <span className="float-xs-right">
            [{item.getIn(['data', 'assessor', 'firstname'])}{' '}
            {item.getIn(['data', 'assessor', 'surname'])}]
          </span>
        )}
      </div>
    );
  }
  if (stage.get('name') === 'USEREVENT.assessor_invited') {
    const name = `${item.getIn(['data', 'assessor', 'firstname'])}${' '}
                            ${item.getIn(['data', 'assessor', 'surname'])}`;
    const startTime = item.getIn(['data', 'time_from']);

    return (
      <span>
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.assessor_invited"
          values={{
            name,
            time: moment(startTime)
              .utcOffset(startTime)
              .format('DD. MMM YYYY hh:mm A')
          }}
        />
      </span>
    );
  }
  if (stage.get('name') === 'USEREVENT.resend_invitation') {
    const name = `${item.getIn(['data', 'invited_by', 'name'])}`;

    return (
      <span>
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.resend_invitation"
          values={{ name }}
        />
      </span>
    );
  }
  if (stage.get('name') === 'USEREVENT.reschedule_invite') {
    if (item.hasIn(['data', 'triggered_by'])) {
      const name = `
                ${item.getIn(['data', 'triggered_by', 'firstname'])}
                ${' '}${item.getIn(['data', 'triggered_by', 'surname'])}
            `;

      return (
        <FormattedHTMLMessage
          id="STAGES.USEREVENT.reschedule_invite_with_name"
          values={{
            name
          }}
        />
      );
    }

    return <FormattedHTMLMessage id="STAGES.USEREVENT.reschedule_invite" />;
  }
  if (stage.get('name') === 'simulation4') {
    const minutes = `${item.getIn(['data', 'minutes'])}`;
    const extendedBy = item.getIn(['data', 'extended_by', 'name']);
    if (item.getIn(['data', 'isDebrief'])) {
      return (
        <div>
          <FormattedMessage
            id={`STAGES.${stage.get('name')}_debrief`}
            values={{ minutes }}
          />
          {extendedBy != null && (
            <span className="float-xs-right">[{extendedBy}]</span>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <FormattedMessage
            id={`STAGES.${stage.get('name')}`}
            values={{ minutes }}
          />
          {extendedBy != null && (
            <span className="float-xs-right">[{extendedBy}]</span>
          )}
        </div>
      );
    }
  }
  if (stage.get('name') === 'USEREVENT.development_invitation') {
    const name = `${item.getIn(['data', 'invited_by', 'name'])}`;
    return (
      <FormattedMessage id={`STAGES.${stage.get('name')}`} values={{ name }} />
    );
  }
  if (
    stage.get('name') === 'simulation1' &&
    item.getIn(['data', 'isDebrief'])
  ) {
    return <FormattedMessage id="STAGES.simulation1_debrief" />;
  }
  if (
    stage.get('name') === 'simulation5' &&
    item.getIn(['data', 'isDebrief'])
  ) {
    return <FormattedMessage id="STAGES.simulation5_debrief" />;
  }
  if (stage.get('name') === 'USEREVENT.development_invitation_resend') {
    const name = `${item.getIn(['data', 'invited_by', 'name'])}`;
    return (
      <FormattedMessage id={`STAGES.${stage.get('name')}`} values={{ name }} />
    );
  } else if (stage.get('name') === 'USEREVENT.development_focus_selected') {
    const behavior = `${item.getIn(['data', 'behavior', 'name'])}`;
    return (
      <FormattedMessage
        id={`STAGES.${stage.get('name')}`}
        values={{ behavior }}
      />
    );
  } else if (stage.get('name') === 'USEREVENT.report_shared_participant') {
    const assessmentName = `${
      item.hasIn(['data', 'simulation', 'name'])
        ? item.getIn(['data', 'simulation', 'name'])
        : intl.formatMessage({ id: 'TABLE_HEADING.simulation' })
    }`;
    const sharedBy = `${item.getIn(['data', 'shared_by', 'name'])}`;

    return (
      <div>
        <FormattedMessage
          id={`STAGES.${stage.get('name')}`}
          values={{ assessmentName }}
        />
        <span className="float-xs-right">[{sharedBy}]</span>
      </div>
    );
  } else if (stage.get('name') === 'USEREVENT.report_shared_stakeholder') {
    const assessmentName = `${
      item.hasIn(['data', 'simulation', 'name'])
        ? item.getIn(['data', 'simulation', 'name'])
        : intl.formatMessage({ id: 'TABLE_HEADING.simulation' })
    }`;
    const sharedBy = `${item.getIn(['data', 'shared_by', 'name'])}`;
    const sharedWith = `${item.getIn(['data', 'shared_with', 'name'])}`;

    return (
      <div>
        <FormattedMessage
          id={`STAGES.${stage.get('name')}`}
          values={{ assessmentName, sharedWith }}
        />
        <span className="float-xs-right">[{sharedBy}]</span>
      </div>
    );
  } else if (stage.get('name') === 'invited6') {
    return (
      <div>
        <FormattedMessage
          id={`STAGES.${stage.get('name')}_${
            user.get('eeo_filled') === true ? 'completed' : 'not_completed'
          }`}
          values={{ name: `${user.get('firstname')} ${user.get('surname')}` }}
        />
      </div>
    );
  } else if (
    stage.get('name') === 'scoring3' ||
    stage.get('name') === 'report1'
  ) {
    return (
      <FormattedMessage
        id={`STAGES.${stage.get('name')}`}
        values={{ simulation: item.getIn(['data', 'name']) }}
      />
    );
  } else if (stage.get('name') === 'USEREVENT.report_narrative_updated') {
    return (
      <FormattedMessage
        id={`STAGES.${stage.get('name')}`}
        values={{
          name: item.hasIn(['data', 'author_name'])
            ? item.getIn(['data', 'author_name'])
            : 'undefined',
          date: item.hasIn(['data', 'updated_at'])
            ? moment(item.getIn(['data', 'updated_at']))
                .zone(item.getIn(['data', 'updated_at']))
                .format('DD MMM YYYY hh:mm A')
            : 'undefined'
        }}
      />
    );
  } else if (stage.get('name') === 'USEREVENT.rescheduled_manually') {
    return (
      <FormattedMessage
        id={`STAGES.${stage.get('name')}`}
        values={{ name: item.getIn(['data', 'scheduled_by', 'name']) }}
      />
    );
  }
  return <FormattedMessage id={`STAGES.${stage.get('name')}`} />;
}
