// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Alert } from 'reactstrap';

/**
 * Alert system
 * @class
 */
@connect(
  (state) => ({
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  },
  null,
  { withRef: true }
)
export default class AlertSystem extends Component {
  // Prop types
  static propTypes = {
    route: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   * @param context
   */
  constructor(props, context) {
    super(props, context);
    this.state = {
      alerts: []
    };
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { alerts } = this.state;

    if (this.props.route !== nextProps.route) {
      alerts.map((alert, i) => {
        if (alert.color === 'danger') {
          return alerts.splice(i, 1);
        }
        return false;
      });

      this.setState({ alerts });
    }
  }

  /**
   * Add alert
   * @param alert
   */
  addAlert(alert) {
    const { transitionTo } = this.props;
    const { alerts } = this.state;

    this.setState({
      alerts: this.state.alerts.concat([alert])
    });

    if (alert.color === 'success' && !alert.stay) {
      setTimeout(() => {
        this.removeAlert.bind(this, this.state.alerts.indexOf(alert))();
        if (alert.redirect) {
          transitionTo(alert.redirect);
        }
      }, 5000);
    }
    if (alert.color === 'danger') {
      alerts.map((alert, i) => {
        if (alert.color === 'danger') {
          return alerts.splice(i, 1);
        }

        return false;
      });
    }

    this.setState({
      alerts: alerts.concat([alert])
    });
  }

  /**
   * Remove alert
   */
  removeAlert(i) {
    const { alerts } = this.state;
    alerts.splice(i, 1);
    this.setState({ alerts });
  }

  /**
   * Remove all alerts
   */
  removeAllAlerts() {
    this.setState({ alerts: [] });
  }

  /**
   * Close alert
   * @param alert
   * @param i
   */
  closeAlert = (alert, i) => {
    const { transitionTo } = this.props;

    this.removeAlert.bind(this, i)();
    if (alert.redirect) {
      transitionTo(alert.redirect);
    }
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { alerts } = this.state;
    const style = {
      position: 'relative',
      width: '100%',
      margin: '10px 0'
    };

    return (
      <div style={style}>
        {alerts.map((alert, i) => (
          <span
            key={i}
            style={{
              position: 'relative',
              zIndex: `'{${999 + i}}'`,
              margin: '10px 0'
            }}
          >
            <Alert
              toggle={() => this.closeAlert(alert, i)}
              color={alert.color}
              style={alert.style}
            >
              {typeof alert.content === 'string'
                ? alert.content.replace(/['"]+/g, '')
                : alert.content}
            </Alert>
          </span>
        ))}
      </div>
    );
  }
}
