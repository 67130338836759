import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';

import { Button, InputGroup, Label, Col, Row } from 'reactstrap';
import { ActionButtons, BuildTable, ConfirmDelete } from 'components';
import { Attachment } from 'views/Simulations/components/EditSimulationTable/modals';

import { emailAttachment } from 'actions/simulations';
import { renderInput, renderDateInput } from '../../actions';
import styles from '../../styles.css';
import { validate } from './validate';
import { ToolTip } from '../../../components';

const form = 'emailForm';
const fields = ['subject', 'date', 'sender', 'prework', 'text'];

@reduxForm({
  form,
  validate
})
@connect(
  (state) => ({
    simulations: state.simulations.get('simulations'),
    email: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id),
      'emails',
      Number(state.router.route.vars.emailId)
        ]), // eslint-disable-line
    simulation: state.simulations.getIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id)
    ]),
    id: Number(state.router.route.vars.id),
    currentId: Number(state.router.route.vars.emailId),
    contacts: state.simulations.hasIn([
      'simulations',
      'english',
      Number(state.router.route.vars.id),
      'contacts'
    ])
      ? state.simulations
          .getIn([
            'simulations',
            'english',
            Number(state.router.route.vars.id),
            'contacts'
          ])
          .toArray()
      : [],
    isLoading: state.simulations.get('emailLoading'),
    formErrors: state.app.get('formErrors') || {},
    readOnly: state.router.route.query.readOnly
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { change, emailAttachment, transitionTo },
      dispatch
    );
  }
)
export default class Email extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    simulations: PropTypes.object.isRequired,
    simulation: PropTypes.object.isRequired,
    id: PropTypes.number,
    currentId: PropTypes.number,
    emailAttachment: PropTypes.func.isRequired,
    email: PropTypes.object,
    contacts: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    transitionTo: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isNew: PropTypes.bool
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue()
    };
  }

  componentDidMount() {
    const { emailAttachment, id, currentId } = this.props;
    emailAttachment({
      type: 'fetch',
      simulationId: id,
      id: currentId,
      data: { language: 'english' }
    });
    this.setFormData();
  }

  onChange = (value) => {
    const { change } = this.props;

    this.setState({
      value
    });
    change(
      form,
      'text',
      value.toString('html') === '<p><br></p>' ? ' ' : value.toString('html')
    );
  };

  setFormData = () => {
    const { email, change } = this.props;

    if (email) {
      fields.map((field) => change(form, field, email.get(field)));

      if (!email.get('text') || email.get('text') === '<p><br></p>') {
        change(form, 'text', ' ');
      }

      // Set editor value
      this.setState({
        value: RichTextEditor.createValueFromString(email.get('text'), 'html')
      });
    } else {
      change(form, 'date', '000 00:00');
    }
  };

  getAttachmentTable = () => {
    const { email, id, readOnly, simulation } = this.props;
    const { addModal, removeModal } = this.context;

    const version = simulation
      .get('languages')
      .find((l) => l.get('language') === 'english');

    return {
      data:
        email && email.has('attachments')
          ? email.get('attachments').toArray()
          : [],
      customClassName: 'attachments-table',
      fields: [
        {
          key: 'filename',
          sortable: true,
          renderField: (item) => item.get('filename')
        },
        {
          key: 'actions',
          sortable: false,
          renderField: (item) => (
            <span>
              <ActionButtons
                id={item.get('id')}
                secureDownload={item.get('path')}
                secureDownloadName={item.get('filename')}
                edit={
                  !readOnly && version.get('was_published')
                    ? () => {
                        addModal(
                          <Attachment
                            editId={item.get('id')}
                            onClose={removeModal}
                          />
                        );
                      }
                    : null
                }
                editKey="REPLACE"
                delete={
                  readOnly || version.get('was_published')
                    ? false
                    : () => {
                        addModal(
                          <ConfirmDelete
                            action={emailAttachment}
                            data={{
                              type: 'delete',
                              simulationId: id,
                              id: email.get('id'),
                              data: {},
                              attachmentId: item.get('id')
                            }}
                            onClose={removeModal}
                          />
                        );
                      }
                }
              />
            </span>
          )
        }
      ],
      button: (
        <Button
          id="new_attachment_button"
          name="new_attachment_button"
          type="button"
          onClick={() => {
            addModal(<Attachment onClose={removeModal} />);
          }}
          disabled={readOnly}
        >
          <FormattedMessage id="NEW_ATTACHMENT" />
        </Button>
      )
    };
  };

  render() {
    const { value } = this.state;
    const {
      handleSubmit,
      id,
      currentId,
      contacts,
      isLoading,
      transitionTo,
      formErrors,
      readOnly,
      simulation
    } = this.props;
    let isDefined = false;

    if (currentId) {
      isDefined = true;
    }

    const textError = formErrors.text;
    const version = simulation
      .get('languages')
      .find((l) => l.get('language') === 'english');

    const isFieldDisabled = (field) => {
      if (!version) {
        return false;
      }

      if (version.get('published')) {
        return true;
      }

      if (version.get('was_published') && !this.props.isNew) {
        switch (field) {
          case 'subject':
          case 'text':
            return false;
          default:
            return true;
        }
      }

      return false;
    };

    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col xs={12}>
            <h5>
              <FormattedMessage id="BASIC_INFORMATION" />
            </h5>
          </Col>
          <Col xs={5}>
            <InputGroup>
              <Label>
                <FormattedMessage id="FORM_FIELDS.subject" /> (
                <FormattedMessage id="REQUIRED" />)
              </Label>
              <Field
                name="subject"
                component={renderInput}
                type="text"
                disabled={readOnly || isFieldDisabled('subject')}
              />
            </InputGroup>
            <InputGroup>
              <Label>
                <FormattedMessage id="FORM_FIELDS.from" /> (
                <FormattedMessage id="REQUIRED" />)
              </Label>
              <Field
                name="sender"
                component={renderInput}
                type="select"
                options={contacts}
                valueKey="id"
                optionKey="name"
                placeholder="PLEASE_SELECT_CONTACT"
                translatePlaceholder
                disabled={readOnly || isFieldDisabled('sender')}
              />
            </InputGroup>
            <InputGroup className={styles['special-input-email-delay']}>
              <Label>
                <FormattedMessage id="FORM_FIELDS.send_at" />
              </Label>
              <Field
                name="date"
                component={renderDateInput}
                d
                h
                m
                disabled={readOnly || isFieldDisabled('date')}
              />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <InputGroup className={styles['heading-checkbox-label-tooltip']}>
              <Label>
                <Field
                  name="prework"
                  component={renderInput}
                  type="checkbox"
                  disabled={readOnly || isFieldDisabled('prework')}
                />
                <FormattedMessage id="FORM_FIELDS.email_prework_info" />
                <ToolTip
                  id="EMAIL_PREWORK_INFO_JIT"
                  index="EMAIL_PREWORK_INFO_JIT"
                  hint={<FormattedMessage id="EMAIL_PREWORK_INFO_JIT" />}
                />
              </Label>
            </InputGroup>
          </Col>
          {isDefined && (
            <Col xs={12}>
              <h5>
                <FormattedMessage id="ATTACHMENT" />
              </h5>
              <BuildTable table={this.getAttachmentTable()} />
            </Col>
          )}
          <Col xs={12}>
            <h5>
              <FormattedMessage id="TEXT" /> (
              <FormattedMessage id="REQUIRED" />)
            </h5>
          </Col>
          <Col xs={10}>
            <InputGroup id="email_text">
              <RichTextEditor
                value={value}
                onChange={this.onChange}
                className={classNames(
                  styles.rte,
                  textError && styles.error,
                  readOnly && styles.disabled
                )}
                disabled={readOnly || isFieldDisabled('text')}
              />
              {textError && (
                <div className={styles.form__error}>
                  {textError.errors.map((e) => (
                    <div>
                      <FormattedMessage id={e} />
                    </div>
                  ))}
                </div>
              )}
            </InputGroup>
          </Col>
        </Row>
        <Row className={styles['form-buttons']}>
          <Col xs={readOnly ? 4 : 2} className={styles['btn-col']}>
            <Button
              id="cancel_email_form"
              name="cancel_email_form"
              type="button"
              color="secondary"
              onClick={() =>
                transitionTo(`/simulations/edit/${id}`, { subTab: '6' })
              }
            >
              <FormattedMessage id="CANCEL" />
            </Button>
          </Col>
          {!readOnly && (
            <Col xs={3} className={styles['btn-col']}>
              <Button
                id="submit_email_form"
                name="submit_email_form"
                type="button"
                color="primary"
                onClick={handleSubmit}
              >
                <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
              </Button>
            </Col>
          )}
        </Row>
      </form>
    );
  }
}
