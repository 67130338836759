import { Map, OrderedMap, fromJS } from 'immutable';
import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  DELETE_ENTITY_REQUEST,
  DELETE_ENTITY_SUCCESS,
  DELETE_ENTITY_FAILURE,
  LOGOUT_SUCCESS,
  RESET_USERS_DATA
} from 'constants/actionTypes';

const initialState = Map({
  clients: OrderedMap(),
  allClients: OrderedMap(),
  clientsMeta: Map()
});

export default function clients(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLIENTS_REQUEST:
      return state.set('clientsLoading', true);
    case FETCH_CLIENTS_SUCCESS: {
      const { filtered } = action.result.body;
      const clients = action.result.body.data;

      return state
        .set('clientsLoading', false)
        .setIn(
          ['clients'],
          OrderedMap(clients.map((client) => [client.id, fromJS(client)]))
        )
        .mergeIn(
          ['allClients'],
          Map(clients.map((client) => [client.id, fromJS(client)]))
        )
        .set('clientsTotal', filtered);
    }
    case FETCH_CLIENTS_FAILURE:
      return state.set('clientsLoading', false).set('error', action.error);
    case CREATE_CLIENT_REQUEST:
      return state.set('createClientLoading', true);
    case CREATE_CLIENT_SUCCESS: {
      const client = action.result.body;
      return state
        .set('createClientLoading', false)
        .setIn(['clients', client.id], fromJS(client))
        .setIn(['allClients', client.id], fromJS(client));
    }
    case CREATE_CLIENT_FAILURE:
      return state.set('createClientLoading', false).set('error', action.error);

    case GET_CLIENT_REQUEST:
      return state.set('getPartnerLoading', true);
    case GET_CLIENT_SUCCESS: {
      const id = Number(action.id);
      const client = action.result.body;

      return state
        .set('getPartnerLoading', false)
        .setIn(['clients', id], fromJS(client))
        .setIn(['allClients', id], fromJS(client));
    }
    case GET_CLIENT_FAILURE:
      return state.set('getPartnerLoading', false).set('error', action.error);

    case EDIT_CLIENT_REQUEST:
      return state.set('createClientLoading', true);
    case EDIT_CLIENT_SUCCESS: {
      const id = Number(action.id);
      const client = action.result.body;

      return state.withMutations((state) => {
        state
          .set('createClientLoading', false)
          .setIn(['clients', id], fromJS(client))
          .setIn(['allClients', id], fromJS(client));
      });
    }
    case EDIT_CLIENT_FAILURE:
      return state.set('createClientLoading', false).set('error', action.error);
    case DELETE_ENTITY_REQUEST:
      return state.set('deleteEntityLoading', true);
    case DELETE_ENTITY_SUCCESS: {
      const id = Number(action.id);
      const { entity } = action;
      if (entity !== 'client') {
        return state;
      }

      return state
        .set('deleteEntityLoading', false)
        .deleteIn(['clients', id])
        .deleteIn(['allClients', id])
        .set('clientsTotal', state.get('clientsTotal') - 1);
    }
    case DELETE_ENTITY_FAILURE:
      return state.set('deleteEntityLoading', false).set('error', action.error);

    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    case RESET_USERS_DATA:
      return state.set('clients', state.get('allClients'));
    default:
      return state;
  }
}
