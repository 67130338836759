import {
  FETCH_FACETS_REQUEST,
  FETCH_FACETS_SUCCESS,
  FETCH_FACETS_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch facets action
 *
 * @param{String} language
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchFacets(language) {
  return {
    types: [FETCH_FACETS_REQUEST, FETCH_FACETS_SUCCESS, FETCH_FACETS_FAILURE],
    promise: api().get(`/api/v1/facets/?language=${language || 'english'}`),
    language: language || 'english'
  };
}
