import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Map, fromJS } from 'immutable';
import { formValueSelector, getFormValues } from 'redux-form';
import api from 'api';

import { Alert, Button, Input } from 'reactstrap';
import { Assess as AssessModal } from 'views/Users/components/UsersTable/modals';

import { editUser, fetchTimeslots } from 'actions/users';
import { formatError, roleRedirect, sortRoles } from 'helpers';
import { modules, timeOptions } from 'constants/fieldsData';
import { getEditUserData } from '../actions';

import { ChangeRoleModal } from '../../components';

import styles from '../styles.css';
import '../styles.css';
import { sortItems } from '../../../../helpers';
import { form as shareResultsAccessForm } from '../../../../forms/Global/ShareReportsAccessForm';
import { getSerializedFormValuesUsingSimulationIds } from '../../../../forms/Global/ShareReportsAccessForm/getSerializedFormValuesUsingSimulationIds';

@connect(
  (state) => ({
    route: state.router.route,
    user:
      state.users.getIn(['allUsers', Number(state.router.route.vars.id)]) ||
      Map(),
    loggedUser: state.auth.get('loggedUser'),
    client:
      state.clients.getIn(['allClients', Number(state.router.route.vars.id)]) ||
      Map(),
    partner:
      state.partners.getIn([
        'allPartners',
        Number(state.router.route.vars.id)
      ]) || Map(),
    id: Number(state.router.route.vars.id),
    permissions: state.app.get('permissions') || [],
    role: state.router.route.attrs.role,
    simulations: state.simulations.hasIn(['simulations', 'english'])
      ? sortItems(state.simulations.getIn(['simulations', 'english']), {
          key: 'name',
          asc: true
        }).toArray()
      : [],
    languages: state.app.hasIn(['codeList', 'language_published'])
      ? sortItems(state.app.getIn(['codeList', 'language_published']), {
          key: 'name',
          asc: true
        }).toArray()
      : [],
    formErrors: state.app.get('formErrors') || {},
    hasProfilePhoto:
      formValueSelector('editUser')(state, 'profile_photo') &&
      formValueSelector('editUser')(state, 'profile_photo') !== ' ',
    oldPreferredMap: state.users.hasIn([
      'allUsers',
      Number(state.router.route.vars.id),
      'timeslots'
    ])
      ? state.users.getIn([
          'allUsers',
          Number(state.router.route.vars.id),
          'timeslots'
        ])
      : Map(),
    shareResultsAccessFormValues: getFormValues(shareResultsAccessForm)(state)
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators(
      { editUser, transitionTo, fetchTimeslots },
      dispatch
    );
  }
)
export default class Edit extends Component {
  static propTypes = {
    route: PropTypes.object,
    transitionTo: PropTypes.func.isRequired,
    user: PropTypes.object,
    client: PropTypes.object,
    partner: PropTypes.object,
    id: PropTypes.string.isRequired,
    editUser: PropTypes.func.isRequired,
    permissions: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.string,
    simulations: PropTypes.object,
    languages: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    hasProfilePhoto: PropTypes.bool,
    formErrors: PropTypes.object,
    oldPreferredMap: PropTypes.object,
    fetchTimeslots: PropTypes.func.isRequired,
    shareResultsAccessFormValues: PropTypes.object
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    removeAllAlerts: PropTypes.func.isRequired,
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(...args) {
    super(...args);
    const { user, route } = this.props;

    let selectedRole = user.has('roles_editable')
      ? sortRoles(user.get('roles_editable')).first()
      : null;

    if (this.props.route.query.profile) {
      selectedRole = user.get('active_role');
    } else if (route.query.role && user.has('roles_editable')) {
      user.get('roles_editable').map((role) => {
        if (route.query.role.indexOf(role) > -1) {
          return (selectedRole = role);
        }

        return false;
      });
    }

    this.state = { selectedRole, preferredTimesError: {} };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldRoles = this.props.user.has('roles')
      ? this.props.user.get('roles').join()
      : null;
    const newRoles = nextProps.user.has('roles')
      ? nextProps.user.get('roles').join()
      : null;

    if (
      newRoles &&
      oldRoles !== newRoles &&
      oldRoles.split().length >= newRoles.split().length
    ) {
      this.setState({
        selectedRole: sortRoles(nextProps.user.get('roles_editable')).first()
      });
    }
  }

  onSuccess = () => {
    const { addAlert, removeAllAlerts } = this.context;
    const { loggedUser, transitionTo } = this.props;

    removeAllAlerts();
    addAlert({
      color: 'success',
      content: <FormattedMessage id="SUCCESSFULLY_UPDATED" />
    });

    transitionTo(roleRedirect(loggedUser));
  };

  preferredHasChanged = (oldPreferredMap, nextPreferred, nextUnavailable) => {
    const prevPreferredMap = oldPreferredMap.has('preferred')
      ? oldPreferredMap.get('preferred').filter((p) => p.size !== 0)
      : Map();
    const prevUnavailable = oldPreferredMap.has('unavailable')
      ? oldPreferredMap.get('unavailable') || Map()
      : Map();
    const nextPreferredMap = Map(
      Object.keys(nextPreferred).map((key) => [key, fromJS(nextPreferred[key])])
    );

    const from =
      nextUnavailable.from == null ? undefined : nextUnavailable.from;
    const to = nextUnavailable.to == null ? undefined : nextUnavailable.to;

    const hasChanged =
      !prevPreferredMap.equals(nextPreferredMap) ||
      prevUnavailable.get('from') !== from ||
      prevUnavailable.get('to') !== to;

    return !!hasChanged;
  };

  validateFields = (preferred) => {
    let isOk = true;
    const preferredTimesError = {};

    Object.keys(preferred).map((day) =>
      preferred[day].map((value) => {
        if (
          timeOptions.find((v) => v.get('name') === value.from) == null ||
          timeOptions.find((v) => v.get('name') === value.to) == null ||
          timeOptions.find((v) => v.get('name') === value.from).get('id') >=
            timeOptions.find((v) => v.get('name') === value.to).get('id')
        ) {
          preferredTimesError[day] = value;
          return (isOk = false);
        }

        return false;
      })
    );

    this.setState({ preferredTimesError });
    return isOk;
  };

  handleSubmit = (user) => {
    const {
      id,
      dispatch,
      languages,
      loggedUser,
      oldPreferredMap,
      fetchTimeslots,
      shareResultsAccessFormValues
    } = this.props;
    const { addAlert, removeAllAlerts } = this.context;

    // Setup prev data
    let previousData;
    switch (this.props.role) {
      case 'partner':
        previousData = this.props.partner;
        break;
      case 'client':
        previousData = this.props.client;
        break;
      default:
        previousData = this.props.user;
        break;
    }

    const data = getEditUserData(this.props.role, this.handleSubmit);
    const { action } = data;
    const updatedUser = {};
    Object.keys(user).map((key) => (updatedUser[key] = user[key]));

    if (
      this.props.user?.get('roles')?.contains('participant') &&
      shareResultsAccessFormValues
    ) {
      updatedUser.sharing = getSerializedFormValuesUsingSimulationIds(
        this.props.user,
        shareResultsAccessFormValues
      );
    }

    if (updatedUser.debriefs) {
      const result = [];

      updatedUser.debriefs.map((debrief, index) => {
        if (debrief) {
          return result.push(index);
        }
        return false;
      });
      updatedUser.debriefs = result; // eslint-disable-line
    }

    if (updatedUser.simulations) {
      const result = [];

      updatedUser.simulations.map((simulation, index) => {
        if (simulation) {
          return result.push(index);
        }
        return false;
      });
      updatedUser.simulations = result; // eslint-disable-line
    }

    if (updatedUser.simulations && updatedUser.debriefs) {
      updatedUser.simulations = updatedUser.simulations.concat(
        updatedUser.debriefs
      );
    } else if (updatedUser.debriefs) {
      updatedUser.simulations = updatedUser.debriefs;
    }

    delete updatedUser.debriefs;

    if (updatedUser.languages) {
      const result = [];

      updatedUser.languages.map((language, i) => {
        if (language) {
          return result.push(languages[i].get('name'));
        }
        return false;
      });
      updatedUser.languages = result; // eslint-disable-line
    }

    if (updatedUser.modules) {
      const result = [];

      updatedUser.modules.map((module, i) => {
        if (module) {
          return result.push(modules[i].get('id'));
        }
        return false;
      });
      updatedUser.modules = result; // eslint-disable-line
    }

    return dispatch(action(id, updatedUser, previousData)).then((action) => {
      if (!action.error) {
        // Only for logged user
        if (id === loggedUser.get('id')) {
          const preferred = updatedUser.preferred || {};
          const unavailable = {
            from: updatedUser.from && updatedUser.to ? updatedUser.from : null,
            to: updatedUser.from && updatedUser.to ? updatedUser.to : null
          };
          const unavailableSlotId = updatedUser.unavailable_id;

          if (unavailableSlotId != null) {
            unavailable.id = unavailableSlotId;
          }

          // Remove empty values
          Object.keys(preferred).map(
            (key) =>
              (preferred[key] = preferred[key].filter(
                (value) => Object.keys(value).length !== 0
              ))
          );

          // Update preferred schedule times only if is updated
          if (
            this.preferredHasChanged(oldPreferredMap, preferred, unavailable)
          ) {
            if (!this.validateFields(preferred)) {
              const calendar = document.getElementById('calendar');
              return window.scrollTo(0, calendar.offsetTop + 100);
            }

            const body = {
              preferred
            };

            if (
              unavailable.id != null ||
              (unavailable.id == null &&
                unavailable.from != null &&
                unavailable.to != null)
            ) {
              body.unavailable = unavailable;
            }

            api()
              .put(`/api/v1/users/${id}/timeslots/preferred/`, body)
              .then((result) => {
                try {
                  if (result.body.is_merged === true) {
                    fetchTimeslots(id);

                    addAlert({
                      color: 'info',
                      content: (
                        <div>
                          <FormattedHTMLMessage id="PLEASE_CHECK_CALENDAR_MESSAGE_BEFORE_LINK" />
                          <Button
                            color="link"
                            style={{
                              padding: 0,
                              margin: 0,
                              verticalAlign: 'baseline'
                            }}
                            onClick={() => {
                              const calendar = document.getElementById(
                                'calendar'
                              );
                              removeAllAlerts();
                              return window.scrollTo(
                                0,
                                calendar.offsetTop + 200
                              );
                            }}
                          >
                            <FormattedMessage id="HERE" />
                          </Button>
                          <FormattedHTMLMessage id="PLEASE_CHECK_CALENDAR_MESSAGE_AFTER_LINK" />
                        </div>
                      )
                    });

                    window.scrollTo(0, 0);
                  } else {
                    this.onSuccess();
                  }
                } catch (e) {
                  addAlert({
                    color: 'danger',
                    content: JSON.stringify(e)
                  });
                }
              })
              .catch((error) => {
                addAlert({
                  color: 'info',
                  content: `Schedule calendar update error - ${error.body.message}`
                });
              });
          } else {
            this.onSuccess();
          }
        } else {
          // End of only logged user
          this.onSuccess();
        }
      } else {
        const { errors } = action.error.body;
        if (errors) {
          addAlert({
            color: 'danger',
            content: formatError(errors)
          });
        } else {
          const error = action.error.body;
          if (
            error.profile_photo ||
            (error.owner && error.owner.profile_photo)
          ) {
            window.scrollTo(0, 0);
          } else {
            const form = this.refs.form.offsetTop;
            window.scrollTo(0, form);
          }
        }
      }

      return true;
    });
  };

  changeRolesModal() {
    const { addModal, removeModal } = this.context;

    addModal(<ChangeRoleModal onClose={removeModal} />);
  }

  render() {
    const {
      user,
      client,
      partner,
      permissions,
      role,
      loggedUser,
      route,
      hasProfilePhoto,
      formErrors
    } = this.props;
    const { addModal, removeModal, intl } = this.context;
    const { selectedRole, preferredTimesError } = this.state;
    const data = getEditUserData(
      this.props.role,
      this.handleSubmit,
      selectedRole,
      preferredTimesError
    );
    let allowChangeRole = false;
    let header;

    if (role !== 'partner' && role !== 'client') {
      if (user.size === 0) {
        return <div />;
      }
    }

    if (role === 'client') {
      header = client.get('name');
    } else if (role === 'partner') {
      header = partner.get('name');
    } else {
      if (
        permissions.indexOf(
          `users_change_roles_link_view_link_${user
            .getIn(['permissions_debug', 'relationship'])
            .first()}`
        ) > -1 &&
        user
          .getIn(['permissions_debug', 'disabled_mutual_blocks'])
          .indexOf('users_change_roles') === -1
      ) {
        allowChangeRole = true;
      }

      header = (
        <span>
          {`${user.get('firstname') || ''} ${user.get('surname') || ''}`}
          {!route.query.profile && (
            <Input
              className={styles['roles-select']}
              type="select"
              name="role"
              id="role"
              value={selectedRole}
              onChange={(e) => this.setState({ selectedRole: e.target.value })}
            >
              {sortRoles(user.get('roles_editable')).map((role, index) => (
                <option value={role} key={index}>
                  {intl.formatMessage({ id: `ROLE_VALUES.${role}` })}
                </option>
              ))}
            </Input>
          )}
          {allowChangeRole && (
            <Button
              id="add_remove_role_button"
              name="add_remove_role_button"
              disabled={user.get('status') === 'suspended'}
              color="link"
              onClick={this.changeRolesModal.bind(this)}
              className={styles['change-roles']}
            >
              <FormattedMessage id="CHANGE_ROLES" />
            </Button>
          )}
          {selectedRole === 'participant' &&
            user.get('id') !== loggedUser.get('id') && (
              <Button
                id="new_assessment_button"
                name="new_assessment_button"
                disabled={
                  user.get('status') === 'suspended' ||
                  (user.get('active_simulation') &&
                    user.getIn(['active_simulation', 'is_debrief']) === false &&
                    user.getIn(['stages', 'report1', 'completed']) === false)
                }
                color="primary"
                onClick={() =>
                  addModal(
                    <AssessModal
                      onClose={removeModal}
                      userId={user.get('id')}
                    />
                  )
                }
                className="float-xs-right"
              >
                + <FormattedMessage id="NEW_ASSESSMENT" />
              </Button>
            )}
        </span>
      );
    }

    const isParticipant =
      loggedUser.has('roles') &&
      loggedUser.get('roles').size === 1 &&
      loggedUser.get('active_role') === 'participant';
    const isInProfile = route.query.profile;

    return (
      <div>
        <Alert
          color="danger"
          isOpen={
            !!(
              !hasProfilePhoto &&
              (formErrors.profile_photo ||
                (formErrors.owner && formErrors.owner.profile_photo))
            )
          }
        >
          <FormattedHTMLMessage id="REQUIRED_PHOTO_ERROR" />
        </Alert>
        {isParticipant || isInProfile ? (
          <div className={styles['no-link-back']} />
        ) : (
          <div className={styles['link-back']}>
            <Link
              id="back_to_users"
              to="/users"
              query={
                route.query.fromTab != null
                  ? {
                      tab: route.query.fromTab
                    }
                  : {}
              }
            >
              &larr; <FormattedMessage id="BACK_TO_USERS" />
            </Link>
          </div>
        )}
        <h1>{header}</h1>
        <div ref="form">{data.component}</div>
      </div>
    );
  }
}
