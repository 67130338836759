// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Alert, Col, InputGroup, Row } from 'reactstrap';

// Const
import {
  EEOFields,
  demographicForm,
  gender,
  races,
  industries,
  educationsStatuses,
  levels
} from 'constants/fieldsData';

// Styles
import styles from '../styles.css';
import '../styles.css';

// Actions
import { renderInput } from '../actions';

/**
 * Demographic block
 * @class
 */
class DemographicBlock extends Component {
  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change, consent } = this.props;
    const prevValues = this.getFormValues(this.props);
    const nextValues = this.getFormValues(nextProps);

    if (
      consent === true &&
      prevValues.length > 0 &&
      (nextValues.length === 0 || nextValues.every((v) => v === ' '))
    ) {
      change(demographicForm, 'consent', false);
    }
  }

  /**
   * Get form values
   * @param props
   * @returns {*[]}
   */
  getFormValues = (props) => {
    const { formData } = props;
    return Object.keys(formData).map((key) => formData[key]);
  };

  /**
   * Render
   * @returns {*}
   */
  render() {
    const { formData, loggedUser } = this.props;

    const values = this.getFormValues(this.props);
    const consentDisabled =
      values.length === 0 || values.every((v) => v === ' ');
    const isFromEU = loggedUser.get('region') === 'EU';

    return (
      <div className={styles.demographic}>
        <Row>
          <Col xs={12}>
            <h5>
              <FormattedMessage id="DEMOGRAPHIC_QUESTIONS" />
            </h5>
            <Alert color="info">
              <FormattedMessage id="DEMOGRAPHIC_INFO" />
            </Alert>
          </Col>
          <Col xs={12}>
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="age">
                  <FormattedMessage id="FORM_FIELDS.what_is_your_age" />
                </label>
                <Field name="age" component={renderInput} type="number" />
                {formData.age !== ' ' &&
                  (formData?.age < 16 || formData?.age > 100) && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '0.75rem',
                        marginTop: '-10px'
                      }}
                    >
                      <FormattedMessage id="EEO_ERROR_AGE" />
                    </span>
                  )}
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="gender">
                  <FormattedMessage id="FORM_FIELDS.gender" />
                </label>
                <Field
                  name="gender"
                  component={renderInput}
                  type="select"
                  valueKey="name"
                  optionKey="name"
                  options={gender}
                  translationKey
                  noOrder
                />
              </InputGroup>
            </Col>
            {!isFromEU ? (
              <Col xs={6}>
                <InputGroup>
                  <label htmlFor="race">
                    <FormattedMessage id="FORM_FIELDS.race" />
                  </label>
                  <Field
                    name="race"
                    component={renderInput}
                    type="select"
                    valueKey="name"
                    optionKey="name"
                    options={races}
                    translationKey
                    noOrder
                  />
                </InputGroup>
              </Col>
            ) : null}
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="education">
                  <FormattedMessage id="FORM_FIELDS.education" />
                </label>
                <Field
                  name="education"
                  component={renderInput}
                  type="select"
                  valueKey="name"
                  optionKey="name"
                  options={educationsStatuses}
                  translationKey
                  noOrder
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="experience_total">
                  <FormattedMessage id="FORM_FIELDS.experience_total" />
                </label>
                <Field
                  name="experience_total"
                  component={renderInput}
                  type="number"
                  value={formData.experience_total}
                />
                {formData?.experience_total !== ' ' &&
                  formData?.experience_total < 0 && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '0.75rem',
                        marginTop: '-10px'
                      }}
                    >
                      <FormattedMessage id="EEO_ERROR_TOTAL_EXPERIENCE_NEG" />
                    </span>
                  )}
                {formData?.experience_total !== ' ' &&
                  formData?.experience_total > 100 && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '0.75rem',
                        marginTop: '-10px'
                      }}
                    >
                      <FormattedMessage id="EEO_ERROR_TOTAL_EXPERIENCE_VALID" />
                    </span>
                  )}
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="work_industry">
                  <FormattedMessage id="FORM_FIELDS.work_industry" />
                </label>
                <Field
                  name="work_industry"
                  component={renderInput}
                  type="select"
                  valueKey="name"
                  optionKey="name"
                  options={industries}
                  translationKey
                  noOrder
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="experience_manager">
                  <FormattedMessage id="FORM_FIELDS.experience_manager" />
                </label>
                <Field
                  name="experience_manager"
                  component={renderInput}
                  type="number"
                  value={formData.experience_manager}
                />
                {formData?.experience_manager !== ' ' &&
                  formData?.experience_manager < 0 && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '0.75rem',
                        marginTop: '-10px'
                      }}
                    >
                      <FormattedMessage id="EEO_ERROR_MANAGER_EXPERIENCE_NEG" />
                    </span>
                  )}
                {formData?.experience_manager !== ' ' &&
                  formData?.experience_manager > 100 && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '0.75rem',
                        marginTop: '-10px'
                      }}
                    >
                      <FormattedMessage id="EEO_ERROR_MANAGER_EXPERIENCE_VALID" />
                    </span>
                  )}
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <label htmlFor="work_responsibility">
                  <FormattedMessage id="FORM_FIELDS.work_responsibility" />
                </label>
                <Field
                  name="work_responsibility"
                  component={renderInput}
                  type="select"
                  valueKey="name"
                  optionKey="name"
                  options={levels}
                  translationKey
                  noOrder
                />
              </InputGroup>
            </Col>
          </Col>
          <Col className={styles.consent} xs={12}>
            <InputGroup>
              <div className={styles.checkbox}>
                <Field
                  disabled={consentDisabled}
                  name="consent"
                  component={renderInput}
                  type="checkbox"
                />
              </div>
              <label htmlFor="consent">
                <FormattedMessage id="EEO_CONSENT_TEXT" />
              </label>
            </InputGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

DemographicBlock.propTypes = {
  loggedUser: PropTypes.object,
  consent: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    consent: formValueSelector(demographicForm)(state, 'consent'),
    formData: formValueSelector(demographicForm)(state, ...EEOFields)
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)(DemographicBlock);
