import React from 'react';
import PropTypes from 'prop-types';

export default class Wrapper extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  componentDidMount() {
    document.getElementById('LOADER').style.display = 'none';
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
