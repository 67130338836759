/**
 * Normalize error
 * @param error
 * @returns {string}
 */
export default function normalizeError(error) {
  if (error.body) {
    if (error.body.message === 'password_expired') {
      return error.body.message;
    }
    if (error.body.message === 'locked_out') {
      return error.body.message;
    }

    switch (error.status) {
      case 401:
        return 'incorrect_data';
      case 500:
        return 'server_error';
      case 503:
        return 'too_many_attempts';
      default:
        return 'error';
    }
  } else if (error.status) {
    switch (error.status) {
      case 503:
        return 'too_many_attempts';
      default:
        return 'error';
    }
  }

  switch (error) {
    default:
      return 'error';
  }
}
