import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { StageProgress } from 'components';

import { getMe } from 'actions/auth';
import { schedule } from 'actions/simulations';
import { Calendar, RescheduleRow } from './components';

import styles from './styles.css';
import './styles.css';

const stages = [
  {
    title: <FormattedMessage id="CREATE_PASSWORD" />,
    bottom: null,
    active: false,
    finished: true,
    inDebrief: false
  },
  {
    title: <FormattedMessage id="CREATE_PROFILE" />,
    bottom: null,
    active: false,
    finished: true,
    inDebrief: false
  },
  {
    title: <FormattedMessage id="SCHEDULE" />,
    bottom: false,
    active: true,
    finished: false,
    inDebrief: true
  }
];

@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    isDebrief:
      state.auth.hasIn(['loggedUser', 'active_simulation', 'is_debrief']) &&
      state.auth.getIn(['loggedUser', 'active_simulation', 'is_debrief'])
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ schedule, getMe, transitionTo }, dispatch);
  }
)
export default class Schedule extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    schedule: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    getMe: PropTypes.func.isRequired,
    isDebrief: PropTypes.bool.isRequired
  };

  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { user } = this.props;
    const isScheduled =
      user.getIn(['stages', 'scheduled1', 'completed']) === true ||
      user.getIn(['stages', 'debrief_scheduled1', 'completed']) === true;

    this.state = {
      step: isScheduled ? 2 : 1
    };
  }

  onReschedule = () => {
    const { schedule, user, getMe, transitionTo } = this.props;
    const { addAlert, removeModal, intl } = this.context;

    removeModal();
    schedule(user.get('id'), null, true).then((action) => {
      window.scrollTo(0, 0);
      if (!action.error) {
        this.setState({ step: 1 });
        getMe(true).then((action) => {
          if (!action.error) {
            transitionTo('/auth/invite/schedule');
          }
        });
      } else {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });
      }
    });
  };

  onSchedule = (data) => {
    const { schedule, user, getMe, transitionTo } = this.props;
    const { reschedule } = this.state;
    const { addAlert, intl } = this.context;

    schedule(user.get('id'), data, reschedule).then((action) => {
      window.scrollTo(0, 0);
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="SCHEDULED_SUCCESSFULLY" />
        });
        getMe().then((action) => {
          if (!action.error) {
            transitionTo('/auth/invite/prework');
          }
        });
      } else {
        addAlert({
          color: 'danger',
          content: intl.formatMessage({ id: action.error.body })
        });
      }
    });
  };

  getSimulationDuration = (activeSimulationLength) => {
    const { intl } = this.context;

    const days = parseInt(activeSimulationLength.substring(0, 3), 10);
    const hours = parseInt(activeSimulationLength.substring(4, 6), 10);
    const minutes = parseInt(activeSimulationLength.substring(7, 9), 10);

    return `${
      days === 0
        ? ''
        : `${days}-${intl.formatMessage({
            id: 'UNIVERSAL_DURATION_DAYS'
          })} `
    }${
      hours === 0
        ? ''
        : `${hours}-${intl.formatMessage({
            id: 'UNIVERSAL_DURATION_HOURS'
          })} `
    }${
      minutes === 0
        ? ''
        : `${minutes}-${intl.formatMessage({
            id: 'UNIVERSAL_DURATION_MINUTES'
          })}`
    }`.trim();
  };

  render() {
    const { step } = this.state;
    const { user, isDebrief } = this.props;
    const activeSimulationLength = user.has('active_simulation')
      ? user.get('active_simulation').get('length')
      : 'Undefined';

    return (
      <div className={styles['schedule-block']}>
        {step === 1 && (
          <StageProgress
            stages={
              // If session is debrief, show only filtered stages
              isDebrief ? stages.filter((stage) => stage.inDebrief) : stages
            }
          />
        )}

        <h1>
          <FormattedMessage id={'SCHEDULE_YOUR_SESSION'} />
        </h1>
        <p className={styles['schedule-info']}>
          <FormattedHTMLMessage
            id={step === 2 ? 'RESCHEDULE_INFO' : 'SCHEDULE_INFO'}
            values={{
              duration: this.getSimulationDuration(activeSimulationLength)
            }}
          />
        </p>

        {step === 2 ? (
          <RescheduleRow onReschedule={this.onReschedule} />
        ) : (
          <Calendar onSchedule={this.onSchedule} />
        )}
      </div>
    );
  }
}
