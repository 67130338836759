import {
  GET_TEST_REQUEST,
  GET_TEST_SUCCESS,
  GET_TEST_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get test action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */
export default function getTest(id) {
  return {
    types: [GET_TEST_REQUEST, GET_TEST_SUCCESS, GET_TEST_FAILURE],
    promise: api().get(`/api/v1/users/${id}/personality-tests/`)
  };
}
