import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Get user action
 *
 * @param{Number} id
 * @param{Boolean} allowMerge
 * @returns {{types: *[], promise: Request }}
 */

export default function getUser(id, allowMerge) {
  return {
    types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: api().get(`/api/v1/users/${id}/`),
    id,
    allowMerge
  };
}
