import request from 'superagent-bluebird-promise';
import {
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  DOWNLOAD_PDF_FAILURE
} from 'constants/actionTypes';
import { getCookie } from 'helpers';
import { AUTH_TOKEN } from '../../constants/cookies';

/**
 * Download pdf action
 *
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function downloadPdf(data, isDebrief = false) {
  let promise = request
    .post(`${process.env.REACT_APP_APIURL}/api/v1/reports/download/`)
    .set('Authorization', `Bearer ${getCookie(AUTH_TOKEN)}`)
    .responseType('arraybuffer')
    .send(data);

  if (isDebrief) {
    promise = request
      .post(`${process.env.REACT_APP_APIURL}/api/v1/reports/download-debrief/`)
      .set('Authorization', `Bearer ${getCookie(AUTH_TOKEN)}`)
      .responseType('arraybuffer')
      .send(data);
  }

  return {
    types: [DOWNLOAD_PDF_REQUEST, DOWNLOAD_PDF_SUCCESS, DOWNLOAD_PDF_FAILURE],
    promise
  };
}
