import {
  FETCH_STAGE_HISTORY_REQUEST,
  FETCH_STAGE_HISTORY_SUCCESS,
  FETCH_STAGE_HISTORY_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Fetch user stage history action
 *
 * @param{Number} id
 * @param{Number} offset
 * @param{Number} limit
 * @param{Object} addons
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchUserStageHistory(id, offset, limit, addons) {
  let types = [
    FETCH_STAGE_HISTORY_REQUEST,
    FETCH_STAGE_HISTORY_SUCCESS,
    FETCH_STAGE_HISTORY_FAILURE
  ];

  if (addons && addons.backgroundRefresh) {
    types = ['r', FETCH_STAGE_HISTORY_SUCCESS, 'f'];
  }

  return {
    types,
    promise: api().get(
      `/api/v1/users/${id}/stage/history/?offset=${offset || 0}&limit=all`
    ),
    id
  };
}
