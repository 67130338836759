// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Field } from 'redux-form';

// Styles
import { Col, InputGroup, Label, Row } from 'reactstrap';
import styles from '../styles.css';

// Components

// Actions
import { renderInput } from '../actions';

// Const
const addressesField = 'email';

/**
 * Multiple address
 * @param props
 * @constructor
 */
const MultipleAddress = (props) => (
  <Row>
    <Col xs={12}>
      <h5>
        <FormattedMessage id={`${props.formKey}_ADDRESSES`} />
      </h5>
    </Col>
    <Col xs={8}>
      <InputGroup>
        <Label htmlFor={addressesField}>
          <FormattedMessage id="FORM_FIELDS.multiple_addresses" />
        </Label>
        <Field name={addressesField} component={renderInput} type="textarea" />
      </InputGroup>
      <span className={styles.form__hint}>
        <FormattedHTMLMessage id="PARTICIPANTS_ADDRESSES_HINT" />
      </span>
    </Col>
  </Row>
);

// Prop types
MultipleAddress.propTypes = {
  formKey: PropTypes.string.isRequired
};

export default MultipleAddress;
