// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Stage progress
 * @class
 */
const StageProgress = (props) => (
  <div className={classNames('stage-progress', styles['stage-progress'])}>
    {props.stages.map((stage, i) => (
      <div
        key={i}
        className={classNames(
          'stage-progress__item',
          styles['stage-progress__item'],
          stage.active && styles.active,
          stage.finished && styles.finished
        )}
      >
        <div
          className={classNames(
            'stage-progress__item__title',
            styles['stage-progress__item__title']
          )}
        >
          {stage.title}
        </div>
        {stage.bottom && (
          <div
            className={classNames(
              'stage-progress__item__bottom',
              styles['stage-progress__item__bottom']
            )}
          >
            {stage.bottom}
          </div>
        )}
      </div>
    ))}
  </div>
);

StageProgress.propTypes = {
  stages: PropTypes.array.isRequired
};

export default StageProgress;
