export default (nextData, prevData, key) => {
  if (prevData != null) {
    if (key === 'modules' || key === 'simulations') {
      let prevArray = null;

      if (typeof prevData.get(key).toArray()[0] === 'string') {
        prevArray = prevData.get(key);
      } else {
        prevArray = prevData.get(key).map((m) => m.get('id'));
      }

      return (
        JSON.stringify(nextData[key].sort()) ===
        JSON.stringify(prevArray.sort())
      );
    }
    if (key === 'owner_id') {
      return nextData[key] === prevData.getIn(['owner', 'id']);
    }
    if (key === 'owner') {
      return nextData[key].status === prevData.getIn(['owner', 'status']);
    }

    return JSON.stringify(nextData[key]) === JSON.stringify(prevData.get(key));
  }

  return false;
};
