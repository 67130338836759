import { Map } from 'immutable';
import { fromJSOrdered } from 'helpers';
import {
  FETCH_ASSESSMENTS_REQUEST,
  FETCH_ASSESSMENTS_SUCCESS,
  FETCH_ASSESSMENTS_FAILURE,
  FETCH_ASSESSMENT_REQUEST,
  FETCH_ASSESSMENT_SUCCESS,
  FETCH_ASSESSMENT_FAILURE,
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAILURE,
  EDIT_ASSESSMENT_REQUEST,
  EDIT_ASSESSMENT_SUCCESS,
  EDIT_ASSESSMENT_FAILURE,
  EDIT_ASSESSMENT_BEHAVIOR_REQUEST,
  EDIT_ASSESSMENT_BEHAVIOR_SUCCESS,
  EDIT_ASSESSMENT_BEHAVIOR_FAILURE,
  FETCH_ASSESSMENT_STRATEGIES_REQUEST,
  FETCH_ASSESSMENT_STRATEGIES_SUCCESS,
  FETCH_ASSESSMENT_STRATEGIES_FAILURE,
  CREATE_STRATEGY_REQUEST,
  CREATE_STRATEGY_SUCCESS,
  CREATE_STRATEGY_FAILURE,
  EDIT_STRATEGY_REQUEST,
  EDIT_STRATEGY_SUCCESS,
  EDIT_STRATEGY_FAILURE,
  DELETE_STRATEGY_SUCCESS,
  DELETE_ASSESSMENT_SUCCESS,
  LOGOUT_SUCCESS
} from 'constants/actionTypes';

const initialState = Map({
  assessments: Map()
});

export default function assessments(state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSESSMENTS_REQUEST:
      return state.set('assessmentsLoading', true);
    case FETCH_ASSESSMENTS_SUCCESS: {
      const assessments = action.result.body.data;

      if (action.query) {
        return state
          .set('assessmentsLoading', false)
          .set('assessmentsFiltered', action.result.body.filtered)
          .set('assessmentsTotal', action.result.body.total)
          .setIn(
            ['assessments', 'english'],
            Map(assessments.map((a) => [a.id, fromJSOrdered(a)]))
          );
      }

      return state
        .set('assessmentsLoading', false)
        .set('assessmentsFiltered', action.result.body.filtered)
        .set('assessmentsTotal', action.result.body.total)
        .mergeDeepIn(
          ['assessments', 'english'],
          Map(assessments.map((a) => [a.id, fromJSOrdered(a)]))
        );
    }
    case FETCH_ASSESSMENTS_FAILURE:
      return state.set('assessmentsLoading', false);
    case FETCH_ASSESSMENT_REQUEST:
      return state.set('assessmentsLoading', true);
    case FETCH_ASSESSMENT_SUCCESS: {
      const id = Number(action.id);
      const assessment = action.result.body;
      const language = action.language || 'english';

      return state
        .set('assessmentsLoading', false)
        .mergeDeepIn(['assessments', language, id], fromJSOrdered(assessment));
    }
    case FETCH_ASSESSMENT_FAILURE:
      return state.set('assessmentsLoading', false);
    case CREATE_ASSESSMENT_REQUEST:
      return state.set('createAssessmentLoading', true);
    case CREATE_ASSESSMENT_SUCCESS: {
      const assessment = action.result.body;

      return state
        .set('createAssessmentLoading', false)
        .set('assessmentsFiltered', state.get('assessmentsFiltered') + 1)
        .set('assessmentsTotal', state.get('assessmentsTotal') + 1)
        .mergeIn(
          ['assessments', assessment.language, assessment.id],
          fromJSOrdered(assessment)
        );
    }
    case CREATE_ASSESSMENT_FAILURE:
      return state.set('createAssessmentLoading', false);
    case EDIT_ASSESSMENT_REQUEST:
      return state.set('editAssessmentLoading', true);
    case EDIT_ASSESSMENT_SUCCESS: {
      const assessment = action.result.body;
      const id = Number(action.id);
      const { language } = action;

      return state.withMutations((newState) => {
        if (language === 'english') {
          newState.setIn(
            ['assessments', language, id, 'languages'],
            fromJSOrdered(assessment.languages)
          );
        }

        newState
          .set('editAssessmentLoading', false)
          .mergeIn(['assessments', language, id], fromJSOrdered(assessment))
          .setIn(
            ['assessments', 'english', id, 'languages'],
            fromJSOrdered(assessment.languages)
          );
      });
    }
    case EDIT_ASSESSMENT_FAILURE:
      return state.set('editAssessmentLoading', false);

    case EDIT_ASSESSMENT_BEHAVIOR_REQUEST:
      return state.set('editAssessmentLoading', true);
    case EDIT_ASSESSMENT_BEHAVIOR_SUCCESS: {
      const language = action.language || 'english';
      const id = Number(action.id);
      const behaviorId = Number(action.behaviorId);

      const oldBehaviors =
        state.hasIn(['assessments', language, id, 'behaviors']) &&
        state.getIn(['assessments', language, id, 'behaviors']).size > 0
          ? state.getIn(['assessments', language, id, 'behaviors']).toJS()
          : [{ behavior_id: behaviorId, ...action.data }];

      oldBehaviors.map((behavior, index) => {
        if (behavior.behavior_id === behaviorId) {
          return Object.keys(action.data).map(
            (key) => (oldBehaviors[index][key] = action.data[key])
          );
        }

        return false;
      });

      return state
        .set('editAssessmentLoading', false)
        .setIn(
          ['assessments', language, id, 'behaviors'],
          fromJSOrdered(oldBehaviors)
        );
    }
    case EDIT_ASSESSMENT_BEHAVIOR_FAILURE:
      return state.set('editAssessmentLoading', false);

    case DELETE_ASSESSMENT_SUCCESS: {
      const id = Number(action.id);
      const language = action.language || 'english';

      return state
        .deleteIn(['assessments', language, id])
        .set('assessmentsFiltered', state.get('assessmentsFiltered') - 1)
        .set('assessmentsTotal', state.get('assessmentsTotal') - 1);
    }

    case FETCH_ASSESSMENT_STRATEGIES_REQUEST:
      return state.set('assessmentStrategiesLoading', true);
    case FETCH_ASSESSMENT_STRATEGIES_SUCCESS: {
      const id = Number(action.id);
      const strategies = action.result.body.data;
      const { language } = action;

      return state
        .set('assessmentStrategiesLoading', false)
        .setIn(
          ['assessments', language, id, 'strategies'],
          Map(
            strategies.map((strategy) => [strategy.id, fromJSOrdered(strategy)])
          )
        );
    }
    case FETCH_ASSESSMENT_STRATEGIES_FAILURE:
      return state.set('assessmentStrategiesLoading', false);
    case CREATE_STRATEGY_REQUEST:
      return state.set('strategiesLoading', true);
    case CREATE_STRATEGY_SUCCESS: {
      const strategy = action.result.body;
      const assessmentId = Number(action.assessmentId);
      const id = strategy ? strategy.id : 0;
      const { language } = action;

      return state
        .set('strategiesLoading', false)
        .setIn(
          ['assessments', language, assessmentId, 'strategies', id],
          fromJSOrdered(strategy)
        );
    }
    case CREATE_STRATEGY_FAILURE:
      return state.set('strategiesLoading', false);

    case EDIT_STRATEGY_REQUEST:
      return state.set('strategiesLoading', true);
    case EDIT_STRATEGY_SUCCESS: {
      const strategy = action.result.body;
      const assessmentId = Number(action.assessmentId);
      const id = Number(action.id);
      const { language } = action;

      return state
        .set('strategiesLoading', false)
        .setIn(
          ['assessments', language, assessmentId, 'strategies', id],
          fromJSOrdered(strategy)
        );
    }
    case EDIT_STRATEGY_FAILURE:
      return state.set('strategiesLoading', false);

    case DELETE_STRATEGY_SUCCESS: {
      const assessmentId = Number(action.assessmentId);
      const id = Number(action.id);
      const { language } = action;

      return state.deleteIn([
        'assessments',
        language,
        assessmentId,
        'strategies',
        id
      ]);
    }

    case LOGOUT_SUCCESS:
      return (state = initialState); // eslint-disable-line
    default:
      return state;
  }
}
