// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { change } from 'redux-form';
import { Map } from 'immutable';

// Styles

// Components
import { Col, Row } from 'reactstrap';

// Actions
import { fetchNotifications } from 'actions/users';
import styles from '../styles.css';

/**
 * Notification block
 * @class
 */
@connect(
  (state) => ({
    user:
      state.users.getIn(['allUsers', Number(state.router.route.vars.id)]) ||
      Map(),
    notifications: state.users.has('notifications')
      ? state.users.get('notifications').toArray()
      : [],
    notificationTypes: state.app
      .getIn(['codeList', 'notification_type'])
      .toArray()
  }),
  (dispatch) => bindActionCreators({ fetchNotifications, change }, dispatch)
)
export default class NotificationBlock extends Component {
  // Prop types
  static propTypes = {
    user: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    notificationTypes: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    selectedRole: PropTypes.string.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.state = {
      selected: user.has('notifications')
        ? user.get('notifications').toJS()
        : []
    };
  }

  /**
   * Component will mount
   */
  UNSAFE_componentWillMount() {
    const { fetchNotifications, user } = this.props;
    let role = user.get('roles').join(',');
    let entity = '';
    if (user.get('partner_id')) {
      entity = 'partner';
    }
    if (user.get('client_id')) {
      entity = 'client';
    }
    if (user.get('roles').size === 0) {
      role = 'stakeholder';
    }
    fetchNotifications(role, null, null, null, null, entity);
  }

  /**
   * Component will receive props
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchNotifications, user } = this.props;
    if (
      nextProps.user.has('roles') &&
      user.get('roles') !== nextProps.user.get('roles')
    ) {
      let role = nextProps.user.get('roles').join(',');
      let entity = '';
      if (user.get('partner_id')) {
        entity = 'partner';
      }
      if (user.get('client_id')) {
        entity = 'client';
      }
      if (user.get('roles').size === 0) {
        role = 'stakeholder';
      }
      fetchNotifications(role, null, null, null, null, entity);
    }
  }

  /**
   * Handle change
   * @param notification
   * @param type
   */
  handleChange = (notification, type) => {
    const { change, form } = this.props;
    const { selected } = this.state;

    const current = {
      name: notification.get('name'),
      time_offset: notification.get('time_offset'),
      type
    };
    let index = -1;

    selected.find((item, i) => {
      if (item.name === notification.get('name') && item.type === type) {
        return (index = i);
      }

      return false;
    });

    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(current);
    }
    this.setState({ selected });

    change(form, 'notifications', selected);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { selected } = this.state;
    const {
      notifications,
      notificationTypes,
      disabled,
      selectedRole
    } = this.props;

    const filteredNotifications = notifications.filter(
      (n) => n.get('role') === selectedRole
    );
    if (filteredNotifications.length === 0) {
      return <div />;
    }

    return (
      <Row className={styles['notification-block']}>
        <Col xs={12}>
          <table>
            <tbody>
              <tr>
                <th>
                  <h5>
                    <FormattedMessage id="EMAIL_NOTIFICATION_HEADING" />
                  </h5>
                </th>
                {notificationTypes.map((type, i) =>
                  type.get('name') !== 'sms' ? (
                    <th key={i}>
                      <FormattedMessage id={type.get('name')} />
                    </th>
                  ) : (
                    ' '
                  )
                )}
              </tr>

              {filteredNotifications.map((notification, i) => (
                <tr key={i}>
                  <td>
                    <FormattedMessage id={notification.get('name')} />
                  </td>
                  {notificationTypes.map((type, j) =>
                    type.get('name') !== 'sms' ? (
                      <td key={j}>
                        <input
                          id={`notification-${type.get('name')}-${i}`}
                          name={`notification-${type.get('name')}-${i}`}
                          disabled={disabled}
                          type="checkbox"
                          onChange={() => {
                            this.handleChange(notification, type.get('name'));
                          }}
                          checked={
                            selected.find(
                              (n) =>
                                n.name === notification.get('name') &&
                                n.type === type.get('name')
                            ) || false
                          }
                        />
                      </td>
                    ) : (
                      ' '
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  }
}
