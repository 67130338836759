/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';

export default (state, key, selected, data) => {
  const users = state
    .get('allUsers')
    .filter((u) => u.get('roles').contains('participant'));

  const changeSharingValue = (value) => {
    if (data.enable === true && data.sharing.is_enabled_self === true) {
      value.sharing.is_enabled_self = true;
    } else if (data.sharing.is_disabled_self === true) {
      value.sharing.is_enabled_self = false;
    }

    if (
      data.sharing.is_enabled_stakeholders === true ||
      data.sharing.is_disabled_stakeholders === true
    ) {
      value.sharing.stakeholders = data.enable
        ? value.sharing.stakeholders.concat(
            data.sharing?.stakeholders
              .filter(
                (id) => !value.sharing.stakeholders.find((s) => s.id === id)
              )
              .map((id) =>
                data.allStakeholders.find((s) => s.get('id') === id)
              ) || []
          )
        : value.sharing?.stakeholders.filter(
            (s) => data.sharing.stakeholders.indexOf(s.id) < 0
          );
    }

    return value;
  };

  const updateValue = (oldValue) => {
    if (oldValue && key === 'active_simulation') {
      const newValue = changeSharingValue(oldValue);
      return fromJS(newValue);
    }

    if (oldValue && key === 'past_simulations') {
      oldValue.forEach((ps, index) => {
        if (selected.filter) {
          if (selected.exclude.indexOf(ps.id) < 0) {
            changeSharingValue(oldValue[index]);
          }
        } else if (selected.include.indexOf(ps.id) > -1) {
          changeSharingValue(oldValue[index]);
        }
      });

      return fromJS(oldValue);
    }

    if (oldValue && key === 'modules') {
      if (data === true) {
        if (oldValue.indexOf('Development') > -1) {
          return fromJS(oldValue);
        }

        return fromJS(oldValue.concat(['Development']));
      }

      if (oldValue.indexOf('Development') < 0) {
        return fromJS(oldValue);
      }

      oldValue.splice(oldValue.indexOf('Development'), 1);
      return fromJS(oldValue);
    }

    return fromJS(
      oldValue
        ?.filter((t) => data.remove.indexOf(t) < 0)
        ?.concat(
          data.add.filter(
            (newValue) =>
              oldValue
                .map((v) => v.toLowerCase())
                .indexOf(newValue.toLowerCase()) < 0
          )
        )
        ?.sort()
    );
  };

  return state.withMutations((newState) => {
    users
      .filter((u) => {
        const userSimulationIDs =
          key === 'past_simulations'
            ? u?.get('past_simulations')?.map((ps) => ps.get('id')) || []
            : [u?.getIn(['active_simulation', 'id'])];
        let founded = false;

        if (selected.filter) {
          userSimulationIDs.map((usid) => {
            if (selected.exclude.indexOf(usid) < 0) {
              founded = true;
              return true;
            }

            return false;
          });
        } else {
          userSimulationIDs.map((usid) => {
            if (selected.include.indexOf(usid) > -1) {
              founded = true;
              return true;
            }

            return false;
          });
        }

        return founded && u.get('status') !== 'suspended';
      })
      .map((user) => {
        const cacheStrings = state.get('usersFilteredData').keySeq().toArray();

        newState
          .updateIn(['users', user.get('id'), key], (value) =>
            updateValue(value?.toJS())
          )
          .updateIn(['allUsers', user.get('id'), key], (value) =>
            updateValue(value?.toJS())
          );

        cacheStrings.map((string) =>
          newState.updateIn(
            ['usersFilteredData', string, user.get('id'), key],
            (value) => updateValue(value?.toJS())
          )
        );

        return true;
      });

    return newState;
  });
};
