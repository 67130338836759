import api from 'api';
import {
  IS_IN_CALL_REQUEST,
  IS_IN_CALL_SUCCESS,
  IS_IN_CALL_FAILURE
} from 'constants/actionTypes';

/**
 * Fetch if user is in a call right now
 *
 * @param {Number} id of an user
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchIsInCall(userId, assessorId) {
  return {
    types: [IS_IN_CALL_REQUEST, IS_IN_CALL_SUCCESS, IS_IN_CALL_FAILURE],
    promise: api().get(`/api/v1/twilio/is-in-call/${userId}/${assessorId}`)
  };
}
