import {
  RESEND_DEVELOPMENT_INVITATION_REQUEST,
  RESEND_DEVELOPMENT_INVITATION_SUCCESS,
  RESEND_DEVELOPMENT_INVITATION_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Resend development invitation action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function resendDevelopmentInvitation(id) {
  return {
    types: [
      RESEND_DEVELOPMENT_INVITATION_REQUEST,
      RESEND_DEVELOPMENT_INVITATION_SUCCESS,
      RESEND_DEVELOPMENT_INVITATION_FAILURE
    ],
    promise: api().get(`/api/v1/users/${id}/development/invite/`)
  };
}
