import {
  EDIT_ASSESSMENT_REQUEST,
  EDIT_ASSESSMENT_SUCCESS,
  EDIT_ASSESSMENT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Edit assessment action
 *
 * @param{Number} id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function editAssessment(id, data) {
  const reduced = {};
  Object.keys(data).map((item) => (reduced[item] = data[item]));

  if (!reduced.language) {
    reduced.language = 'english';
  }

  if (reduced.simulation === ' ') {
    reduced.simulation = null;
  }

  if (reduced.partner_id === 'null') {
    reduced.partner_id = null;
    reduced.client_id = null;
  }

  if (reduced.client_id === 'null') {
    reduced.client_id = null;
  }

  return {
    types: [
      EDIT_ASSESSMENT_REQUEST,
      EDIT_ASSESSMENT_SUCCESS,
      EDIT_ASSESSMENT_FAILURE
    ],
    promise: api().put(`/api/v1/assessment/${id}/`, reduced),
    language: reduced.language,
    id
  };
}
