import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { Button } from 'reactstrap';
import { EmailDetail } from '../components';

import styles from '../styles.css';

export default function getLogsTable(data, context, emails, rtl) {
  return {
    data: data.toArray(),
    getRowIndicator: (item) => (item.get('is_practice') ? 'completed' : null),
    fields: [
      {
        key: 'date',
        sortable: true,
        renderField: (item) =>
          moment(item.get('created_at'))
            .utcOffset(item.get('created_at'))
            .format('DD MMM YYYY hh:mm:ss A')
      },
      {
        key: 'log-type',
        sortable: true,
        renderField: (item) => item.get('type')
      },
      {
        key: 'log-data',
        sortable: false,
        renderField: (item) => {
          const data =
            item.has('data') && item.get('data') != null
              ? item.get('data').toJS()
              : null;
          let mail = false;

          if (data == null) {
            return <span />;
          }

          return Object.keys(data).map((key, index) => {
            if (key === 'mail' || key === 'email') {
              mail = Number(data[key]);
            }

            return (
              <span title={data[key]} className={styles['log-data-field']}>
                <FormattedMessage id={key} />:{data[key]} <br />
                {mail && index + 1 === Object.keys(data).length && (
                  <Button
                    id={`open_log_${item.get('id')}`}
                    name={`open_log_${item.get('id')}`}
                    style={{ padding: 0 }}
                    size="sm"
                    color="link"
                    onClick={() =>
                      context.addModal(
                        <EmailDetail
                          addModal={context.addModal}
                          email={emails.find(
                            (e) => Number(e.get('id')) === mail
                          )}
                          rtl={rtl}
                        />
                      )
                    }
                  >
                    <FormattedMessage id="OPEN" />
                  </Button>
                )}
              </span>
            );
          });
        }
      }
    ]
  };
}
