// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// Components
import { SetNewPasswordForm } from 'forms';

// Actions
import { setPassword } from 'actions/auth';
import { getParameterByName } from 'routes/actions';

/**
 * Set new password
 * @class
 */
@connect(undefined, (dispatch) => {
  const { transitionTo } = actions;
  return bindActionCreators({ setPassword, transitionTo }, dispatch);
})
export default class SetNewPassword extends Component {
  // PropTypes
  static propTypes = {
    setPassword: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  // ContextTypes
  static contextTypes = {
    addAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  handleSubmit = (data) => {
    const { setPassword, transitionTo } = this.props;
    const { addAlert } = this.context;
    const token = getParameterByName('token');

    setPassword(token, data.password).then((action) => {
      if (!action.error) {
        addAlert({
          color: 'success',
          content: <FormattedMessage id="PASSWORD_CHANGED_SUCCESSFULLY" />
        });

        if (getParameterByName('lang')) {
          transitionTo('/auth/login', { lang: getParameterByName('lang') });
        } else {
          transitionTo('/auth/login');
        }
      }
    });
  };

  render() {
    return (
      <div>
        <Helmet title="Pinsight - Set new password" />
        <SetNewPasswordForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}
