import {
  LIST_DAILY_EXERCISES_REQUEST,
  LIST_DAILY_EXERCISES_SUCCESS,
  LIST_DAILY_EXERCISES_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * List daily exercises action
 *
 * @param {Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function listDailyExercises(data) {
  const string = `from=${data.from || ''}&to=${data.to || ''}`;
  return {
    types: [
      LIST_DAILY_EXERCISES_REQUEST,
      LIST_DAILY_EXERCISES_SUCCESS,
      LIST_DAILY_EXERCISES_FAILURE
    ],
    promise: api().get(`/api/v1/development/exercises/?${string}`)
  };
}
