// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Button, Label, Row, Col } from 'reactstrap';

// Actions
import { getMe } from 'actions/auth';
import { updateUserStage } from 'actions/users';
import { stageRedirect, isMobile } from 'helpers';

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * Terms
 * @class
 */
@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    locale: state.app.get('locale'),
    direction: state.app.get('direction')
  }),
  (dispatch) => {
    const transitionTo = actions.transitionTo;
    return bindActionCreators(
      { transitionTo, updateUserStage, getMe },
      dispatch
    );
  }
)
export default class Terms extends Component {
  // Prop types
  static propTypes = {
    transitionTo: PropTypes.func.isRequired,
    updateUserStage: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    getMe: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const { loggedUser } = this.props;
    const step =
      loggedUser.has('stages') &&
      loggedUser.getIn(['stages', 'invited2a_tc', 'completed']) === true
        ? 2
        : 1;

    this.state = {
      acceptTC: false,
      acceptPP: false,
      isLoading: false,
      step
    };
  }

  /**
   * Next stage
   */
  nextStage = () => {
    const { loggedUser, updateUserStage } = this.props;

    this.setState({ isLoading: true });

    updateUserStage(loggedUser.get('id'), {
      stage: 'invited2a_tc',
      completed: true
    }).then((action) => {
      const updatedUser = loggedUser.toJS();
      updatedUser.stages.invited2a_tc.completed = true;

      if (!action.error) {
        window.scrollTo(0, 0);
        this.setState({ isLoading: false, step: 2 });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  redirect = () => {
    const { transitionTo, loggedUser, updateUserStage, getMe } = this.props;

    this.setState({ isLoading: true });

    updateUserStage(loggedUser.get('id'), {
      stage: 'invited2b_pp',
      completed: true
    }).then((action) => {
      if (!action.error) {
        getMe().then((action) => {
          const user = action.result.body;
          transitionTo(stageRedirect(user));
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { acceptTC, acceptPP, isLoading, step } = this.state;
    const { locale, direction } = this.props;

    return step === 2 ? (
      <div className={isMobile() ? styles['is-mobile'] : null}>
        <h1>
          <FormattedMessage id="PRIVACY_POLICY" />
          {!isMobile() && (
            <a
              id="print_pp_link"
              href={`/assets/files/pp_${locale}.pdf?v=6`}
              target="_blank"
            >
              <Button
                id="print_pp_button"
                name="print_pp_button"
                color="primary-dark"
                style={{ float: direction === 'rtl' ? 'left' : 'right' }}
              >
                <FormattedMessage id="PRINT" />
              </Button>
            </a>
          )}
        </h1>
        <p style={{ marginTop: '2em' }}>
          <FormattedMessage id="PRIVACY_POLICY_INFO" />
        </p>

        {isMobile() ? (
          <a href={`/assets/files/pp_${locale}.pdf?v=6`} target="_blank">
            <FormattedMessage id="DOWNLOAD" />
          </a>
        ) : (
          <iframe
            style={{ width: '100%', minHeight: '590px', marginTop: '1em' }}
            src={`/assets/files/pp_${locale}.pdf?v=6`}
            frameBorder="0"
          />
        )}

        <Row>
          <Col xs={12} className={styles['terms-checkbox']}>
            <Label check>
              <input
                id="accept_privacy_policy"
                name="accept_privacy_policy"
                type="checkbox"
                checked={acceptPP}
                onChange={() => this.setState({ acceptPP: !acceptPP })}
                style={direction === 'rtl' ? { float: 'left' } : {}}
              />
              <FormattedMessage id="ACCEPT_PRIVACY" />
            </Label>
          </Col>
        </Row>

        <Row>
          <Col xs={4} className={styles['submit-box']}>
            <Button
              id="privacy_next"
              name="privacy_next"
              color="primary-dark"
              disabled={!acceptPP || isLoading}
              onClick={this.redirect}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'NEXT'} />
            </Button>
          </Col>
        </Row>
      </div>
    ) : (
      <div>
        <h1>
          <FormattedMessage id="TERMS_AND_CONDITIONS" />
          {!isMobile() && (
            <a
              id="print_tc_link"
              href={`/assets/files/tc_${locale}.pdf?v=4`}
              target="_blank"
            >
              <Button
                id="print_tc_button"
                name="print_tc_button"
                color="primary-dark"
                style={{ float: direction === 'rtl' ? 'left' : 'right' }}
              >
                <FormattedMessage id="PRINT" />
              </Button>
            </a>
          )}
        </h1>
        <iframe
          style={{ width: '100%', minHeight: '590px', marginTop: '2em' }}
          src={`/assets/files/tc_${locale}.pdf?v=4`}
          frameBorder="0"
        />

        <Row>
          <Col xs={12} className={styles['terms-checkbox']}>
            <Label check>
              <input
                id="accept_terms"
                name="accept_terms"
                type="checkbox"
                checked={acceptTC}
                onChange={() => this.setState({ acceptTC: !acceptTC })}
                style={direction === 'rtl' ? { float: 'left' } : {}}
              />
              <FormattedMessage id="ACCEPT_CONDITIONS" />
            </Label>
          </Col>
        </Row>

        <Row>
          <Col xs={4} className={styles['submit-box']}>
            <Button
              id="terms_next"
              name="terms_next"
              color="primary-dark"
              disabled={!acceptTC || isLoading}
              onClick={this.nextStage}
            >
              <FormattedMessage id={isLoading ? 'LOADING' : 'NEXT'} />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
