/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

// Styles
import { InputGroup } from 'reactstrap';
import styles from '../../styles.css';

// Validation
import { validate } from './validate';

// Components

// Actions
import { renderInput } from '../../actions';

// Const
const form = 'extendTimeModalForm';

/**
 * Extend time modal form
 * @class
 */
const ExtendTimeModalForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <InputGroup>
        <label htmlFor="assessment">
          <FormattedMessage id="FORM_FIELDS.extend_time" />
          (+ <FormattedMessage id="TIME.minutes" />)
        </label>
        <Field name="minutes" component={renderInput} type="number" />
      </InputGroup>
    </form>
  );
};

// Prop types
ExtendTimeModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

// Decorate with redux-form
export default reduxForm({
  form,
  validate
})(ExtendTimeModalForm);
