import {
  DELETE_SIMULATION_REQUEST,
  DELETE_SIMULATION_SUCCESS,
  DELETE_SIMULATION_FAILURE,
  DELETE_DEBRIEF_REQUEST,
  DELETE_DEBRIEF_SUCCESS,
  DELETE_DEBRIEF_FAILURE
} from 'constants/actionTypes';
import api from 'api';

/**
 * Delete simulation action
 *
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function deleteSimulation(data) {
  const result = data;
  const types = [];

  if (!result.isDebrief) {
    result.isDebrief = 0;
    types.push(
      DELETE_SIMULATION_REQUEST,
      DELETE_SIMULATION_SUCCESS,
      DELETE_SIMULATION_FAILURE
    );
  } else {
    types.push(
      DELETE_DEBRIEF_REQUEST,
      DELETE_DEBRIEF_SUCCESS,
      DELETE_DEBRIEF_FAILURE
    );
  }

  return {
    types,
    promise: api().del(`/api/v1/simulations/${data.id}/`),
    id: data.id,
    isDebrief: result.isDebrief
  };
}
