// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { arrayPush, change, formValueSelector, FieldArray } from 'redux-form';
import { Map } from 'immutable';

// Components
import { Col, Row } from 'reactstrap';
import { DatePicker } from 'components';

// Actions
import { fetchTimeslots } from 'actions/users';
import { renderTimeslotSelects } from './arrayFields';

// Const
import { days } from '../../constants/fieldsData';

// Styles
import styles from '../styles.css';
import '../styles.css';

/**
 * Preferred schedule block
 * @class
 */
@connect(
  (state, ownProps) => ({
    id: Number(state.router.route.vars.id),
    timeslots: state.users.hasIn([
      'allUsers',
      Number(state.router.route.vars.id)
    ])
      ? state.users
          .getIn(['allUsers', Number(state.router.route.vars.id)])
          .get('timeslots')
      : Map(),
    user: state.auth.get('loggedUser'),
    currentUser: state.users.hasIn([
      'allUsers',
      Number(state.router.route.vars.id)
    ])
      ? state.users.getIn(['allUsers', Number(state.router.route.vars.id)])
      : Map(),
    from: formValueSelector(ownProps.form)(state, 'from'),
    to: formValueSelector(ownProps.form)(state, 'to'),
    preferred: formValueSelector(ownProps.form)(state, 'preferred') || {}
  }),
  (dispatch) =>
    bindActionCreators({ arrayPush, change, fetchTimeslots }, dispatch)
)
export default class PreferredSchedule extends Component {
  // Prop types
  static propTypes = {
    arrayPush: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    fetchTimeslots: PropTypes.func.isRequired,
    timeslots: PropTypes.object,
    id: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    user: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    from: PropTypes.string,
    to: PropTypes.string,
    preferred: PropTypes.object,
    preferredTimesError: PropTypes.object
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { id, fetchTimeslots } = this.props;

    fetchTimeslots(id);
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change, form, arrayPush } = this.props;

    if (this.props.timeslots !== nextProps.timeslots) {
      const preferred = nextProps.timeslots.get('preferred');
      const unavailable = nextProps.timeslots.get('unavailable');
      change(form, 'preferred', {});

      days.map((day) => {
        if (preferred != null && preferred.has(day.get('name'))) {
          const currentDayValues = preferred.get(day.get('name'));

          return currentDayValues.map((value) =>
            arrayPush(form, `preferred[${day.get('name')}]`, {
              from: value.get('from'),
              to: value.get('to'),
              id: value.get('id')
            })
          );
        }

        return false;
      });

      if (
        unavailable != null &&
        unavailable.has('from') &&
        unavailable.has('to')
      ) {
        change(form, 'unavailable_id', unavailable.get('id'));
        change(form, 'from', unavailable.get('from'));
        change(form, 'to', unavailable.get('to'));
      }
    }
  }

  /**
   * Handle date picker change
   * @param date
   * @param key
   */
  handleChange(date, key) {
    const { change, form } = this.props;

    change(form, key, date);
  }

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const {
      disabled,
      currentUser,
      from,
      to,
      preferred,
      preferredTimesError
    } = this.props;

    return (
      <Row id="calendar">
        <Col xs={12}>
          <h5>
            <FormattedMessage id="PREFERRED_SCHEDULE" />
          </h5>
        </Col>
        <Col xs={12} className={styles['preferred-schedule-desc']}>
          <p>
            <FormattedMessage
              id="PREFERRED_SCHEDULE_INFO"
              values={{ timezone: currentUser.get('timezone') }}
            />
          </p>
        </Col>
        <Col xs={12} className={styles['preferred-flex-wrapper']}>
          {days.map((day, i) => {
            const disabledValues = [];
            let hasEmptyValue = false;
            const currentDayValues = preferred[day.get('name')] || [];

            currentDayValues.map((value) => {
              if (
                !value ||
                !value.from ||
                !value.to ||
                value.from === ' ' ||
                value.to === ' '
              ) {
                return (hasEmptyValue = true);
              }

              return false;
            });

            return (
              <Col xs={4} key={i} className={styles['schedule-day']}>
                <span className={styles['day-name']}>
                  <strong>{day.get('name')}</strong>
                </span>

                {disabled && currentDayValues.length === 0 && <div>N/A</div>}
                <FieldArray
                  name={`preferred.${day.get('name')}`}
                  component={renderTimeslotSelects}
                  disabled={disabled}
                  addDisabled={hasEmptyValue}
                  disabledValues={disabledValues}
                  preferredTimesError={preferredTimesError}
                  day={day}
                />
              </Col>
            );
          })}
        </Col>
        <Col xs={12}>
          <p>
            <FormattedMessage id="PREFERRED_SCHEDULE_HINT" />
          </p>
        </Col>

        <Col xs={12} className={styles['preferred-schedule-holiday']}>
          <p>
            <FormattedMessage id="PREFERRED_SCHEDULE_HOLIDAY" />
          </p>
          <div className={styles['holiday-dates']}>
            <div>
              <DatePicker
                id="from-date"
                key="from-date"
                value={from}
                maxDate={to}
                onChange={(e) => {
                  this.handleChange(e, 'from');
                }}
                disabled={disabled}
              />
            </div>
            <span className={styles['holiday-span']}> to </span>
            <div>
              <DatePicker
                id="to-date"
                key="to-date"
                value={to}
                minDate={from}
                onChange={(e) => {
                  this.handleChange(e, 'to');
                }}
                disabled={disabled}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
