import { SET_PROGRESS } from 'constants/actionTypes';

/**
 * Set progress action
 *
 * @param{Number} title
 * @param{Number} percentage
 * @returns {{types: *[], promise: Request }}
 */

export default function setProgress(title, percentage) {
  return {
    type: SET_PROGRESS,
    title,
    percentage
  };
}
