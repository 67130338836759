import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Field, formValueSelector, arrayPush, change } from 'redux-form';
import { Map, fromJS } from 'immutable';

import { Col, InputGroup, Row } from 'reactstrap';

import { fetchAllowedSimulations } from 'actions/users';
import { renderInput } from '../actions';
import {
  form as shareReportAccessForm,
  shareReportAccessFormStakeholders
} from '../Global/ShareReportsAccessForm';

@connect(
  (state, ownProps) => ({
    user: state.auth.get('loggedUser'),
    languages: state.app.hasIn(['codeList', 'language_published'])
      ? state.app.getIn(['codeList', 'language_published']).toArray()
      : [],
    selectedSimulation: Number(
      formValueSelector(ownProps.form)(state, 'active_simulation.simulation_id')
    ),
    selectedAssessment:
      Number(
        formValueSelector(ownProps.form)(
          state,
          'active_simulation.assessment_id'
        )
      ) || 0,
    partner: Number(formValueSelector(ownProps.form)(state, 'partner_id')),
    client: Number(formValueSelector(ownProps.form)(state, 'client_id')),
    partners: state.partners.get('allPartners'),
    clients: state.clients.get('allClients'),
    scheduledAt: formValueSelector(ownProps.form)(state, 'scheduled_at')
  }),
  (dispatch) =>
    bindActionCreators({ fetchAllowedSimulations, arrayPush, change }, dispatch)
)
export default class SimulationAndLanguage extends Component {
  static propTypes = {
    selectedSimulation: PropTypes.number,
    selectedAssessment: PropTypes.number,
    partner: PropTypes.number,
    client: PropTypes.number,
    languages: PropTypes.array.isRequired,
    user: PropTypes.object,
    modal: PropTypes.bool,
    selectedUser: PropTypes.object,
    fetchAllowedSimulations: PropTypes.func.isRequired,
    arrayPush: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    partners: PropTypes.object.isRequired,
    clients: PropTypes.object.isRequired,
    scheduledAt: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      simulations: Map(),
      loading: false
    };
  }

  componentDidMount() {
    this.getData(this.props);
    this.props.change(this.props.form, 'active_simulation', {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      form,
      arrayPush,
      change,
      selectedSimulation,
      selectedAssessment,
      partner,
      client
    } = this.props;
    const simulationHasChanged =
      (selectedSimulation &&
        selectedSimulation !== nextProps.selectedSimulation) ||
      (selectedAssessment &&
        selectedAssessment !== nextProps.selectedAssessment);

    if (simulationHasChanged) {
      change(form, 'active_simulation.language', null);
      change(form, 'scheduled_at', null);
      change(shareReportAccessForm, shareReportAccessFormStakeholders, []);
      setTimeout(() => {
        arrayPush(shareReportAccessForm, shareReportAccessFormStakeholders, {});
      }, 10);
    }

    if (
      simulationHasChanged ||
      ((partner || nextProps.partner) && partner !== nextProps.partner) ||
      ((client || nextProps.client) && client !== nextProps.client)
    ) {
      this.getData(nextProps);
    }

    if (
      ((partner || nextProps.partner) && partner !== nextProps.partner) ||
      ((client || nextProps.client) && client !== nextProps.client)
    ) {
      change(form, 'active_simulation', {});
      change(form, 'scheduled_at', null);
    }
  }

  getData = (data) => {
    const { user, selectedUser } = this.props;
    const { partner, client } = data;

    if (selectedUser) {
      if (
        selectedUser.has('client_id') &&
        selectedUser.get('client_id') != null
      ) {
        this.fetchData(null, selectedUser.get('client_id'));
      } else if (
        selectedUser.has('partner_id') &&
        selectedUser.get('partner_id') != null
      ) {
        this.fetchData(selectedUser.get('partner_id'), null);
      } else {
        this.fetchData();
      }
    } else if (client && partner) {
      this.fetchData(partner, client);
    } else if (client && client !== 'null') {
      this.fetchData(null, client);
    } else if (partner && partner !== 'null') {
      this.fetchData(partner, null);
    } else if (user.get('isClient')) {
      this.fetchData(null, user.get('client_id'));
    } else if (user.get('isPartner')) {
      this.fetchData(user.get('partner_id'), null);
    } else {
      this.fetchData();
    }
  };

  fetchData = (partner, client) => {
    const { fetchAllowedSimulations, selectedUser } = this.props;
    this.setState({ loading: true });

    fetchAllowedSimulations(
      partner,
      client,
      null,
      null,
      selectedUser ? selectedUser.get('id') : null
    ).then((action) => {
      if (!action.error) {
        const simulations = action.result.body;

        this.setState({
          simulations: Map(
            simulations.map((s, index) => {
              const assessmentId = s.assessment_id || 0;
              const simulationId = s.simulation_id;
              s.id = `${simulationId}${assessmentId}`; // eslint-disable-line
              return [index, fromJS(s)];
            })
          )
        });
      }
      this.setState({ loading: false });
    });
  };

  /**
   * Is allowed language
   * @param l
   */
  isAllowedLanguage = (l) => {
    const { partners, clients, partner, client } = this.props;

    if (client != null && !isNaN(client) && clients.has('client')) {
      return clients.getIn([client, 'languages']).contains(l.get('name'));
    }
    if (partner != null && !isNaN(partner) && partners.has(partner)) {
      return partners.getIn([partner, 'languages']).contains(l.get('name'));
    }

    return true;
  };

  render() {
    const {
      modal,
      selectedSimulation,
      selectedAssessment,
      languages,
      disabled,
      form,
      change,
      scheduledAt
    } = this.props;
    const { loading } = this.state;
    const { simulations: allSimulations } = this.state;
    const simulations = allSimulations.filter(
      (simulation) => !simulation.get('is_debrief')
    );

    const selected = simulations.find(
      (s) => s.get('id') === `${selectedSimulation}${selectedAssessment}`
    );
    let filteredLanguages = [];
    if (selectedSimulation && simulations && selected) {
      filteredLanguages = languages.filter(
        (l) =>
          selected.get('languages').contains(l.get('name')) &&
          this.isAllowedLanguage(l)
      );
    }

    return (
      <Row>
        <Col xs={12}>
          <Col
            xs={modal ? '12' : '5'}
            style={{ marginTop: modal ? '0px' : '20px' }}
          >
            <InputGroup>
              <label htmlFor="simulation">
                <FormattedMessage id="FORM_FIELDS.simulation" />
              </label>
              <Field
                loading={loading}
                name="active_simulation.id"
                onChange={(e) => {
                  if (!e.target) {
                    return;
                  }

                  const value = Number(e.target.value);
                  const simulation = simulations.find(
                    (s) => s.get('id') === value.toString()
                  );

                  if (value === 0) {
                    change(form, 'active_simulation.simulation_id', null);
                    change(form, 'active_simulation.assessment_id', null);
                    change(form, 'active_simulation.language', null);

                    return;
                  }

                  if (simulation) {
                    change(
                      form,
                      'active_simulation.simulation_id',
                      simulation.get('simulation_id')
                    );
                  }

                  if (simulation && simulation.has('assessment_id')) {
                    change(
                      form,
                      'active_simulation.assessment_id',
                      simulation.get('assessment_id')
                    );
                  } else {
                    change(form, 'active_simulation.assessment_id', null);
                  }
                }}
                value={selected ? selected.get('name') : ''}
                component={renderInput}
                type="select"
                valueKey="id"
                optionKey="name"
                options={simulations.toArray()}
                placeholder="PLEASE_SELECT_SIMULATION"
                disabled={disabled || scheduledAt != null}
                translatePlaceholder
              />
            </InputGroup>
            <InputGroup>
              <label htmlFor="language">
                <FormattedMessage id="FORM_FIELDS.language" />
              </label>
              <Field
                loading={loading}
                name="active_simulation.language"
                component={renderInput}
                type="select"
                valueKey="name"
                optionKey="name"
                options={filteredLanguages}
                translationKey="LANGUAGE_VALUES"
                placeholder="PLEASE_SELECT_LANGUAGE"
                disabled={
                  disabled || !selectedSimulation || scheduledAt != null
                }
                translatePlaceholder
              />
            </InputGroup>
          </Col>
        </Col>
      </Row>
    );
  }
}
