export const menuItems = [
  { title: 'development', path: '/development' },
  { title: 'analytics', path: '/analytics' },
  { title: 'assignments', path: '/assignments' },
  { title: 'simulations', path: '/simulations', query: { tab: '1' } },
  { title: 'users', path: '/users', query: { tab: '1' } },
  { title: 'customize', path: '/customize' },
  { title: 'dashboard', path: '/dashboard' },
  { title: 'skills', path: '/v2/skills' },
  { title: 'insights', path: '/v2/insights' }
];
