// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Components
import { InputGroup } from 'reactstrap';

// Styles
import styles from '../../styles.css';

// Const
const form = 'emailBodyForm';

/**
 * Email body
 * @class
 */
@reduxForm({
  form
})
@connect(undefined, (dispatch) =>
  bindActionCreators(
    {
      change
    },
    dispatch
  )
)
export default class EmailBody extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue()
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const { defaultValue, change } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line
      this.setState({
        value: RichTextEditor.createValueFromString(defaultValue, 'html')
      });
      change(form, 'translation', defaultValue.toString('html'));
    }
  }

  /**
   * On change
   * @param value
   */
  onChange = (value) => {
    const { change } = this.props;

    this.setState({
      value
    });
    change(form, 'translation', value.toString('html'));
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { handleSubmit } = this.props;
    const { value } = this.state;

    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <InputGroup className={styles['heading-modal-group']}>
          <h6>
            <FormattedMessage id="TEXT" />
          </h6>
          <RichTextEditor
            value={value}
            onChange={this.onChange}
            className={styles.rte}
          />
        </InputGroup>
      </form>
    );
  }
}
