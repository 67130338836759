// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Styles
import styles from './styles.css';
import './styles.css';

// Actions
import { logout } from 'actions/auth';
import { isMobile, roleRedirect } from 'helpers';
import { isInIframe } from '../../../../helpers/isInIframeCheck';

/**
 * Top Bar
 * @class
 */
@connect(
  (state) => ({
    user: state.auth.get('loggedUser'),
    currentFocus: state.development.get('currentFocus'),
    router: state.router
  }),
  (dispatch) => {
    const transitionTo = actions.transitionTo;
    return bindActionCreators({ logout, transitionTo }, dispatch);
  },
  null,
  { withRef: true }
)
export default class TopBar extends Component {
  // Prop Types
  static propTypes = {
    currentFocus: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    router: PropTypes.object
  };

  /**
   * Constructor
   * @param args
   */
  constructor(...args) {
    super(...args);
    this.state = {
      heading: 'Loading...',
      isOpen: false
    };
  }

  /**
   * Change heading
   * @param heading
   */
  changeHeading = (heading) => {
    this.setState({
      heading
    });
  };

  /**
   * Open menu
   */
  openMenu = () => {
    this.setState({ isOpen: true });
    setTimeout(() => {
      window.addEventListener('click', this.clickHandler, false);
    }, 100);
  };

  /**
   * Click handler
   * @param e
   */
  clickHandler = (e) => {
    if (this.refs['menu-bar'] && !this.refs['menu-bar'].contains(e.target)) {
      this.closeMenu();
    }
  };

  /**
   * Close menu
   */
  closeMenu = () => {
    this.setState({ isOpen: false });
    window.removeEventListener('click', this.clickHandler, false);
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { currentFocus, logout, transitionTo, user, router } = this.props;
    const { heading, isOpen } = this.state;

    const navigationItems =
      currentFocus.size > 0
        ? [
            {
              key: 'GETTING_STARTED',
              action: () => transitionTo('/development/overview')
            },
            {
              key: 'MY_PRIORITIES',
              action: () => transitionTo('/development/my-priorities')
            },
            {
              key: 'MY_DAILY_EXERCISE',
              action: () => transitionTo('/development/app', { view: 0 })
            },
            {
              key: 'MY_PROGRESS',
              action: () => transitionTo('/development/app', { view: 1 })
            },
            {
              key: 'MY_IMPROVEMENT',
              action: () => transitionTo('/development/app', { view: 2 })
            }
          ]
        : [
            {
              key: 'GETTING_STARTED',
              action: () => transitionTo('/development/overview')
            },
            {
              key: 'MY_PRIORITIES',
              action: () => transitionTo('/development/my-priorities')
            },
            {
              key: 'CHOOSE_WEEKLY_FOCUS',
              action: () => transitionTo('/development/choose-focus')
            }
          ];

    if (!isMobile()) {
      let backLink = 'HOME';

      if (user.get('has_shared_report')) {
        backLink = 'ANALYTICS';
      }

      if (!isInIframe) {
        navigationItems.push({
          key: backLink,
          action: () => {
            document.getElementById('LOADER').style.display = 'block';

            return transitionTo(roleRedirect(user)).then(() => {
              document.getElementById('LOADER').style.display = 'none';
            });
          }
        });
      }
    }

    navigationItems.push({
      key: 'CONTACT_SUPPORT',
      action: () =>
        transitionTo('/development/technical-support', {
          backLink: router.route.pathname,
          backLinkQuery: router.route.query
        })
    });

    navigationItems.push({
      key: 'LOGOUT',
      disabled: isInIframe, // TODO better would be to disable this button while debrief is running
      action: () => logout()
    });

    return (
      <div
        key="top-bar"
        className={classNames(
          styles['top-bar'],
          isMobile() && styles.mobile,
          window.platform != null && styles.nativeApp,
          window.platform != null && styles[window.platform]
        )}
      >
        {router.route &&
        router.route.query &&
        router.route.query.backLink != null ? (
          <button
            id="menu_back_button"
            name="menu_back_button"
            key="menu_back_button"
            className={classNames(styles['burger-button'], styles.back)}
            onClick={() =>
              transitionTo(
                router.route.query.backLink,
                router.route.query.backLinkQuery
                  ? router.route.query.backLinkQuery
                  : {}
              )
            }
          >
            <img src="/assets/img/arrow-back-white.png" alt="back-arrow" />
          </button>
        ) : (
          <button
            id="menu_burger_button"
            name="menu_burger_button"
            key="menu_burger_button"
            className={styles['burger-button']}
            onClick={this.openMenu}
          >
            <img src="/assets/img/hamburger.png" alt="hamburger" />
          </button>
        )}
        <h4>{heading}</h4>

        <div
          className={classNames(
            styles['menu-bar'],
            isOpen && styles.open,
            !isMobile() && styles.absolute
          )}
          ref="menu-bar"
        >
          <button
            id="menu_close_button"
            name="menu_close_button"
            className={styles['menu-bar__close-button']}
            onClick={this.closeMenu}
            aria-label="Close window"
          />
          <span
            className={styles['user-photo']}
            style={{
              background: `url(${
                user.get('profile_photo')
                  ? `${process.env.REACT_APP_ASSETSURL}${user.get(
                      'profile_photo'
                    )}`
                  : '/assets/img/profile-photo-placeholder.gif'
              }) no-repeat center center`,
              backgroundSize: 'cover'
            }}
          />
          <h6>
            {user.get('firstname')} {user.get('surname')}
          </h6>

          <ul>
            {navigationItems.map((item, i) => (
              <li
                id={`menu_item_${item.key}`}
                key={i}
                className={classNames(item.disabled && styles.disabled)}
                onClick={() => {
                  if (!item.disabled) {
                    item.action();
                    this.closeMenu();
                  }
                }}
              >
                <FormattedMessage id={item.key} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
