// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Modal } from 'reactstrap';
import NotSupported from 'views/Auth/Invitation/steps/ITCheck/NotSupported';

// Actions
import { checkSupport, isMobile } from 'helpers';
import { connect } from 'react-redux';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';

/**
 * Wrapper
 * @class
 */
@connect(
  (state) => ({
    loggedUser: state.auth.get('loggedUser'),
    logoutLoading: state.auth.get('logoutLoading')
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class Wrapper extends Component {
  static propTypes = {
    children: PropTypes.any,
    transitionTo: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    logoutLoading: PropTypes.bool
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { transitionTo, loggedUser, logoutLoading } = this.props;
    const { addModal } = this.context;

    if (window.platform == null) {
      if (isMobile() && loggedUser && loggedUser.size > 0 && !logoutLoading) {
        transitionTo('/mobile-not-supported', { type: 'desktop-required' });
      }

      setTimeout(() => {
        if (!checkSupport()) {
          addModal(
            <Modal isOpen toggle={() => false}>
              <div style={{ padding: 50 }}>
                <NotSupported />
              </div>
            </Modal>
          );
        }
      }, 1000);
    } else if (
      loggedUser &&
      loggedUser.size > 0 &&
      loggedUser.has('modules') &&
      !loggedUser.get('modules').contains('Development')
    ) {
      transitionTo('/mobile-not-supported', { type: 'desktop-required' });
    }
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}
