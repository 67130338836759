import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { IndicatorGraph } from '../../../components';

import styles from '../styles.css';
import '../styles.css';

const getProminentPersonality = (props) => {
  const { data, route } = props;
  const current = Number(route.query.current);
  const report =
    data.find((r) => r.get('id') === Number(current)) || data.first();
  let showOnlyOneAssess = false;
  // const isParticipant = user.get('active_role') === 'participant';
  if (
    (report.has('simulations_count') && report.get('simulations_count') > 1) ||
    !report.has('simulations_count')
  ) {
    showOnlyOneAssess = true;
  }

  const numberOfProminentPersonalitiesToShow = 3;
  // remove avid learner id = 9
  const facets = report.get('facets').filter((facet) => facet.get('id') !== 9);
  // calculate percentile
  const prominentPersonalities = facets.map((facet) => {
    // get the furthest away from 0.5
    const percentile = Math.abs(facet.get('fp') - 0.5);
    return { id: facet.get('id'), percentile, facet };
  });
  // sort by percentile
  const prominentPersonalitiesSorted = prominentPersonalities
    .sortBy((item) => item.percentile)
    .reverse();
  // get N the most prominent scores
  let prominentPersonalitiesToDisplay = prominentPersonalitiesSorted.slice(
    0,
    numberOfProminentPersonalitiesToShow
  );
  // return just facets
  prominentPersonalitiesToDisplay = prominentPersonalitiesToDisplay.map(
    (facet) => {
      return facet.facet;
    }
  );

  return (
    <div className={styles.personality}>
      {showOnlyOneAssess && (
        <div className="text-center">
          (<FormattedMessage id="ONLY_ONE_ASSESS_INFO" />)
        </div>
      )}
      {current ? (
        <div className={styles.facets}>
          {prominentPersonalitiesToDisplay.map((facet, index) => (
            <div key={index} className="html2pdf__avoid">
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px 0'
                }}
                className={styles['facet-row']}
              >
                <Col
                  xs={2}
                  style={{ float: 'none', display: 'flex', width: 'auto' }}
                >
                  <h6 style={{ marginBottom: '15px', fontSize: '1rem' }}>
                    <FormattedMessage id={facet.get('name')} />
                  </h6>
                </Col>
                <Col xs={10} style={{ margin: '0 auto' }}>
                  <IndicatorGraph
                    facet={report
                      .get('facets')
                      .find((f) => f.get('id') === facet.get('id'))}
                    betterIf={facet.get('better_if')}
                    noValues
                    noMargin
                  />
                </Col>
              </Row>
              <div>
                <p style={{ fontSize: '0.8rem' }}>
                  {(facet.get('fp') || 0) * 100 < 50 ? (
                    <FormattedMessage
                      id={`PROMINENT_PERSONALITY.${facet
                        .get('name')
                        .toUpperCase()}.LOW`}
                    />
                  ) : (
                    <FormattedMessage
                      id={`PROMINENT_PERSONALITY.${facet
                        .get('name')
                        .toUpperCase()}.HIGH`}
                    />
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default connect((state) => ({
  locale: state.app.get('locale'),
  route: state.router.route,
  user: state.auth.get('loggedUser')
}))(
  React.memo(
    getProminentPersonality,
    (prevProps, nextProps) =>
      prevProps?.route?.query?.current === nextProps?.route?.query?.current
  )
);
