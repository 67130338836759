// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';
import { actions } from 'routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { isMobile, animateScrollTo } from 'helpers';
import { MyDailyExercise, MyImprovement, MyProgress } from './components';

// Actions

// Styles
import styles from './styles.css';
import './styles.css';

/**
 * App
 * @class
 */
@connect(
  (state) => ({
    route: state.router.route
  }),
  (dispatch) => {
    const { transitionTo } = actions;
    return bindActionCreators({ transitionTo }, dispatch);
  }
)
export default class App extends Component {
  // Context types
  static contextTypes = {
    intl: PropTypes.object.isRequired,
    changeHeading: PropTypes.func.isRequired
  };

  // Prop Types
  static propTypes = {
    route: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
  };

  // State
  state = {
    active: 1
  };

  /**
   * Component did mount
   */
  UNSAFE_componentWillMount() {
    setTimeout(() => {
      const view = Number(this.props.route.query.view) || 0;
      this.setHeading(view);
      this.slider.slickGoTo(view);
    }, 100);
  }

  /**
   * Component will receive props
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.route &&
      (nextProps.route.query.view ||
        Number(nextProps.route.query.view) === 0) &&
      this.props.route.query.view !== nextProps.route.query.view
    ) {
      const view = Number(nextProps.route.query.view);
      this.setHeading(view);
      this.slider.slickGoTo(view);
    }
  }

  /**
   * Set heading
   * @param value
   */
  setHeading = (value) => {
    const { transitionTo } = this.props;
    const { intl, changeHeading } = this.context;

    animateScrollTo(document.body, 0, 0);
    this.setState({ active: value });

    switch (value) {
      case 0:
        changeHeading(intl.formatMessage({ id: 'MY_DAILY_EXERCISE' }));
        transitionTo('/development/app', { view: 0, scroll: 0 });
        break;
      case 1:
        changeHeading(intl.formatMessage({ id: 'MY_PROGRESS' }));
        transitionTo('/development/app', { view: 1, scroll: 0 });
        break;
      case 2:
        changeHeading(intl.formatMessage({ id: 'MY_IMPROVEMENT' }));
        transitionTo('/development/app', { view: 2, scroll: 0 });
        break;
      default:
        changeHeading(intl.formatMessage({ id: 'MY_DAILY_EXERCISE' }));
        transitionTo('/development/app', { view: 0, scroll: 0 });
        break;
    }
  };

  /**
   * Render
   * @returns {XML}
   */
  render() {
    const { active } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const slides = [0, 1, 2];

    return (
      <div
        onKeyDown={(e) => {
          if (e.keyCode === 9) {
            e.preventDefault();
          }
        }}
      >
        <Slider
          ref={(node) => (this.slider = node)}
          className={classNames(styles.slider, !isMobile() && styles.desktop)}
          afterChange={(e) => this.setHeading(e)}
          adaptiveHeight
          {...settings}
        >
          <div style={isMobile() ? { minHeight: 'calc(100vh - 100px)' } : {}}>
            <MyDailyExercise />
          </div>
          <div style={isMobile() ? { minHeight: 'calc(100vh - 100px)' } : {}}>
            <MyProgress />
          </div>
          <div style={isMobile() ? { minHeight: 'calc(100vh - 100px)' } : {}}>
            <MyImprovement />
          </div>
        </Slider>

        <ul className={classNames(styles.dots, !isMobile() && styles.desktop)}>
          {slides.map((slide) => (
            <li key={slide}>
              <button
                id={`button_slide_to_${slide}`}
                name={`button_slide_to_${slide}`}
                onClick={() => this.slider.slickGoTo(slide)}
                className={classNames(active === slide && styles.active)}
                aria-label={`Button slide to ${slide}`}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
