import request from 'superagent-bluebird-promise';
import {
  DOWNLOAD_USERS_TABLE_REQUEST,
  DOWNLOAD_USERS_TABLE_SUCCESS,
  DOWNLOAD_USERS_TABLE_FAILURE
} from 'constants/actionTypes';
import { getCookie, getUsersFetchString } from 'helpers';
import { AUTH_TOKEN } from '../../constants/cookies';

/**
 * Download users table action
 *
 * @returns {{types: *[], promise: Request }}
 */
export default function downloadUsersTable({ role, sortBy, filter }) {
  let queryString = getUsersFetchString(0, 'ALL', filter, role, sortBy);
  queryString += `&user_type=${role || ''}`;

  return {
    types: [
      DOWNLOAD_USERS_TABLE_REQUEST,
      DOWNLOAD_USERS_TABLE_SUCCESS,
      DOWNLOAD_USERS_TABLE_FAILURE
    ],
    promise: request
      .get(
        `${process.env.REACT_APP_APIURL}/api/v1/users/export/xlsx/${queryString}`
      )
      .set('Authorization', `Bearer ${getCookie(AUTH_TOKEN)}`)
      .responseType('arraybuffer')
  };
}
