import {
  FETCH_LAT_REQUEST,
  FETCH_LAT_SUCCESS,
  FETCH_LAT_FAILURE
} from 'constants/actionTypes';
import api from 'api';

export default function fetchLAT() {
  return {
    types: [FETCH_LAT_REQUEST, FETCH_LAT_SUCCESS, FETCH_LAT_FAILURE],
    promise: api().get(`/api/v1/users/lat/`)
  };
}
