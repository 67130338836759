import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { formValueSelector, change } from 'redux-form';
import { Map } from 'immutable';

import { Col, InputGroup, Row } from 'reactstrap';
import { ReactTags } from 'components';

import { fetchTags } from 'actions/users';
import styles from '../styles.css';

const tagsInput = 'tags';

@connect(
  (state, ownProps) => ({
    user: state.auth.get('loggedUser'),
    currentUser: state.users.hasIn([
      'allUsers',
      Number(state.router.route.vars.id)
    ])
      ? state.users.getIn(['allUsers', Number(state.router.route.vars.id)])
      : Map(),
    tags: formValueSelector(ownProps.form)(state, 'tags') || [],
    partnerId: formValueSelector(ownProps.form)(state, 'partner_id'),
    clientId: formValueSelector(ownProps.form)(state, 'client_id'),
    formErrors: state.app.get('formErrors') || {}
  }),
  (dispatch) => bindActionCreators({ change, fetchTags }, dispatch)
)
export default class TagsBlock extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    currentUser: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    tags: PropTypes.array.isRequired,
    fetchTags: PropTypes.func.isRequired,
    partnerId: PropTypes.number,
    clientId: PropTypes.number
  };

  constructor(...args) {
    super(...args);

    this.state = {
      suggestions: []
    };
  }

  componentDidMount() {
    const { change, currentUser, form, partnerId, clientId } = this.props;

    if (currentUser.size !== 0) {
      change(form, 'tags', currentUser.get('tags').toArray());
    } else {
      this.updateTagsSuggestions(partnerId, clientId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { partnerId, clientId } = nextProps;

    if (
      this.props.partnerId !== partnerId ||
      this.props.clientId !== clientId
    ) {
      this.updateTagsSuggestions(partnerId, clientId);
    }
  }

  updateTagsSuggestions = (partnerId, clientId) => {
    const { fetchTags } = this.props;

    fetchTags(null, partnerId, clientId, true).then((req) =>
      this.setState({ suggestions: req.result.body.map((t) => ({ name: t })) })
    );
  };

  handleDelete = (i, name) => {
    const tags = this.props.tags.slice(0);
    tags.splice(this.props.tags.indexOf(name), 1);
    this.props.change(this.props.form, 'tags', tags);
  };

  handleAddition = (tag, index) => {
    if (this.props.tags.indexOf(tag) < 0) {
      const tags = this.props.tags;
      tags.splice(index, 0, tag);
      this.props.change(this.props.form, 'tags', tags);
    }
  };

  render() {
    const { currentUser, disabled, tags, formErrors } = this.props;

    return (
      <Row>
        <Col xs={5} style={{ marginTop: 30 }}>
          <InputGroup>
            <label htmlFor={tagsInput}>
              <FormattedMessage id={`FORM_FIELDS.${tagsInput}`} />
            </label>
            <div>
              <ReactTags
                autofocus={false}
                disabled={disabled}
                tags={tags
                  .filter((t) => {
                    if (currentUser.size === 0) {
                      return true;
                    }

                    return currentUser.get('tags').indexOf(t) < 0;
                  })
                  .map((t) => ({ name: t }))}
                suggestions={this.state.suggestions.filter(
                  (t) => tags.indexOf(t.name) < 0
                )}
                handleDelete={(index, tag) =>
                  this.handleDelete(index, tag.name)
                }
                handleAddition={(tag, index) =>
                  this.handleAddition(tag.name, index)
                }
                onCreateNew={(tag, index) => this.handleAddition(tag, index)}
                allowNew
                minQueryLength={0}
              />
            </div>
            <div>
              {tags
                .filter((t) => {
                  if (currentUser.size === 0) {
                    return false;
                  }

                  return currentUser.get('tags').indexOf(t) > -1;
                })
                .map((tag, index) => (
                  <span
                    className={classNames(
                      'react-tags__selected-tag',
                      disabled && 'disabled'
                    )}
                    title="Click to remove tag"
                    style={{
                      backgroundColor: disabled ? '#eceeef' : '#fff',
                      borderRadius: 5,
                      padding: '4px 10px',
                      lineHeight: '20px',
                      fontSize: 12,
                      marginTop: 10,
                      marginRight: 10
                    }}
                  >
                    <span
                      className="react-tags__selected-tag-name"
                      style={{ fontSize: 12 }}
                    >
                      {tag}
                    </span>
                    <button
                      id={`tag_button_${tag}`}
                      type="button"
                      name={`tag_button_${tag}`}
                      onClick={() => this.handleDelete(index, tag)}
                      disabled={disabled}
                    >
                      &#x2715;
                    </button>
                  </span>
                ))}
            </div>
          </InputGroup>
          {formErrors[tagsInput] &&
            formErrors[tagsInput].errors.map((error) => (
              <div className={styles.form__error}>
                <FormattedMessage id={error} />
              </div>
            ))}
        </Col>
      </Row>
    );
  }
}
