// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { actions } from 'routex';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { ConfirmModal } from 'components';
import { DemographicQuestionnaireForm } from 'forms';

// Actions
import { getMe } from 'actions/auth';
import { editUser, updateUserStage } from 'actions/users';
import { stageRedirect } from 'helpers';

/**
 * Demographic
 * @param props
 * @param context
 * @returns {*}
 * @constructor
 */
const Demographic = (props, context) => {
  const { getMe, editUser, user, transitionTo, updateUserStage } = props;
  const { addAlert, addModal, removeModal } = context;

  /**
   * Set eeo stage
   * @param{number} filled
   */
  const setEEOStage = (filled) => {
    updateUserStage(user.get('id'), {
      stage: 'invited6',
      completed: true
    }).then(() => {
      getMe(true).then((action) => {
        const updatedUser = action.result.body;

        addAlert({
          color: 'success',
          content: (
            <FormattedMessage
              id={filled === 0 ? 'EEO_SKIPPED' : 'EEO_SUCCESSFULLY_SAVED'}
            />
          )
        });

        transitionTo(stageRedirect(updatedUser));
      });
    });
  };

  /**
   * On submit
   * @param data
   */
  const onSubmit = (data) => {
    const objectKeysLength = Object.keys(data).length;

    if (objectKeysLength === 0 || (data.consent && objectKeysLength === 1)) {
      return setEEOStage(0);
    }

    return editUser(user.get('id'), data).then((action) => {
      if (!action.error) {
        setEEOStage(1);
      } else {
        const error = action.error.body;

        window.scrollTo(0, 0);
        addAlert({
          color: 'danger',
          content: error
        });
      }
    });
  };

  return (
    <DemographicQuestionnaireForm
      onSubmit={(data) => {
        if (
          Object.keys(data).length === 0 ||
          Object.keys(data)
            .map((key) => data[key])
            .every((v) => v === ' ' || v === false)
        ) {
          onSubmit({});
        } else if (!data.consent) {
          addModal(
            <ConfirmModal
              confirmMessage="DELETE_DATA_AND_CONTINUE"
              header={
                <FormattedMessage id="ARE_YOU_SURE_YOU_WANT_TO_CONTINUE" />
              }
              message={<FormattedMessage id="EEO_MODAL_CONFIRM_MESSAGE" />}
              centerBody
              submitAction={() => {
                onSubmit({});
                removeModal();
              }}
              onClose={removeModal}
            />
          );
        } else {
          onSubmit(data);
        }
      }}
    />
  );
};

// Context types
Demographic.contextTypes = {
  addAlert: PropTypes.func.isRequired,
  addModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired
};

// PropTypes
Demographic.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserStage: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  transitionTo: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    user: state.auth.get('loggedUser')
  }),
  (dispatch) => {
    const { transitionTo } = actions;

    return bindActionCreators(
      { getMe, transitionTo, updateUserStage, editUser },
      dispatch
    );
  }
)(Demographic);
